import { createSlice } from '@reduxjs/toolkit';
import { getCountryFromClientNumber } from '../../../../common/functions/utils';
import { ShipmentDetailsSlice } from '../../common/models/ReduxSlices';
import { GetShipmentDetails } from '../actions/shipmentDetails';

const initialState: ShipmentDetailsSlice = {
    isLoading: false,
    error: undefined,
    shipmentDetails: null,
    country: ''
};

const shipmentDetailsSlice = createSlice({
    name: 'shipmentDetails',
    initialState,
    reducers: {
        resetShipmentDetails() {
            return { ...initialState };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetShipmentDetails.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetShipmentDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.ClientCaptionNumber) {
                    state.country = getCountryFromClientNumber(action.payload.ClientCaptionNumber);
                }
                state.shipmentDetails = action.payload;
            })
            .addCase(GetShipmentDetails.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const { resetShipmentDetails } = shipmentDetailsSlice.actions;
export default shipmentDetailsSlice.reducer;
