import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubscriptionsByUserAndClientSlice } from '../../common/models/ReduxSlices';
import { UpdateSubscriptionsByUserAndClient } from '../actions/subscriptions';

const initialState: SubscriptionsByUserAndClientSlice = {
    customerNumbers: [],
    subscriptionsByUserAndClient: {
        isLoading: false,
        error: undefined,
        ResponseCode: null,
        ResponseMessage: '',
        RequestedAction: '',
        SubscriptionStatusCode: '',
        SubscriptionIds: []
    }
};

const UpdateSubscriptionsByUserAndClientSlice = createSlice({
    name: 'subscriptionsByUserAndClient',
    initialState,
    reducers: {
        setCustomerNumbers(state, action: PayloadAction<string[]>) {
            state.customerNumbers = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(UpdateSubscriptionsByUserAndClient.pending, (state) => {
                state.subscriptionsByUserAndClient.isLoading = true;
            })
            .addCase(UpdateSubscriptionsByUserAndClient.fulfilled, (state, action) => {
                state.subscriptionsByUserAndClient = action.payload;
                state.subscriptionsByUserAndClient.isLoading = false;
            })
            .addCase(UpdateSubscriptionsByUserAndClient.rejected, (state, action) => {
                state.subscriptionsByUserAndClient.isLoading = false;
                state.subscriptionsByUserAndClient.error = action.error.message;
            });
    }
});

export const { setCustomerNumbers } = UpdateSubscriptionsByUserAndClientSlice.actions;

export default UpdateSubscriptionsByUserAndClientSlice.reducer;
