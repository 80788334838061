import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestAnUpdateSlice } from '../../common/models/ReduxSlices';
import type { RequestAnUpdateModel } from '../../common/models/RequestAnUpdateModel';
import { GetCaseNumber } from '../actions/requestAnUpdate';

const initialState: RequestAnUpdateSlice = {
    caseNumber: '',
    requestAnUpdateModel: {
        RequestType: '',
        Description: '',
        FirstName: '',
        LastName: '',
        Email: '',
        PhoneNumber: '',
        ClientType: '',
        UserID: 0
    },
    isLoading: false,
    error: undefined
};

const requestAnUpdateSlice = createSlice({
    name: 'requestAnUpdate',
    initialState,
    reducers: {
        setRequestAnUpdateModel(state, action: PayloadAction<RequestAnUpdateModel>) {
            return {
                ...state,
                requestAnUpdateModel: action.payload
            };
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(GetCaseNumber.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetCaseNumber.fulfilled, (state, action) => {
                state.isLoading = false;
                state.caseNumber = action.payload;
            })
            .addCase(GetCaseNumber.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const { setRequestAnUpdateModel } = requestAnUpdateSlice.actions;

export default requestAnUpdateSlice.reducer;
