import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidPhoneNumber } from 'libphonenumber-js';
import type { ChangeEvent, FormEvent } from 'react';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useReducer, useState } from 'react';
import Button from '../../../../common/features/Button';
import FormDatePicker from '../../../../common/features/FormDatePicker';
import FormInputField from '../../../../common/features/FormInputField';
import Select from '../../../../common/features/Select';
import useToast from '../../../../common/features/Toast/useToast';
import { dateFormatValidator, dateIsAfterValidator, emailValidator, inputHasValueValidator } from '../../../../common/functions/validators';
import useInputValidation from '../../../../common/hooks/useInputValidation';
import useMaskedInputValidation from '../../../../common/hooks/useMaskedInputValidation';
import useTranslation from '../../../../common/hooks/useTranslation';
import { useLazyValidateNewTeamMemberQuery } from '../../redux/api/teamApi';
import type { EditTeamMemberData, TeamMemberData, User } from '../models';
import AddNewUserTable from './AddNewUserTable';
import TeamSectionWrapper from './TeamSectionWrapper';

type Action = { type: 'ADD_TEAM_MEMBER'; payload: User } | { type: 'REMOVE_TEAM_MEMBER'; payload: number };

type State = {
    newTeamMembers: User[];
    changedProperties: string[];
};

function reducer(state: State, action: Action) {
    const { type, payload } = action;

    switch (type) {
        case 'ADD_TEAM_MEMBER':
            const changedProperties = [...state.newTeamMembers, payload].flatMap((user, index) =>
                Object.entries(user)
                    .filter(([fieldName, fieldValue]) => fieldValue && fieldName !== 'Roles' && fieldName !== 'UserRole')
                    .map(([fieldName]) => `Users.${index}.${fieldName}`)
            );

            return { newTeamMembers: [...state.newTeamMembers, payload], changedProperties };
        case 'REMOVE_TEAM_MEMBER':
            const filteredTeamMembers = state.newTeamMembers.filter((_, index) => index !== action.payload);

            return { ...state, newTeamMembers: filteredTeamMembers };
        default:
            return state;
    }
}

interface UserDetailsProps {
    isLoading: boolean;
    data: TeamMemberData | undefined;
}

export type UserDetailsActions = {
    getData: () => EditTeamMemberData;
    validate: () => boolean;
};

const UserDetails = forwardRef<UserDetailsActions, UserDetailsProps>(function UserDetails({ data, isLoading }, ref) {
    const [state, dispatch] = useReducer(reducer, { newTeamMembers: [], changedProperties: [] });

    const translate = useTranslation();

    const userRoles = useMemo(() => {
        if (data?.Roles) {
            const dataRoles = data?.Roles?.map((item) => {
                return { value: item.Text ? item.Text : '', label: '' };
            });
            return [{ value: '', label: translate('SelectUserRole_Label') }, ...dataRoles];
        } else {
            return [{ value: '', label: translate('SelectUserRole_Label') }];
        }
    }, [data?.Roles, translate]);

    const [formIsSubmitted, setFormIsSubmitted] = useState(false);
    const [emailExists, setEmailExists] = useState(false);

    const {
        value: firstName,
        handleChange: firstNameChangeHandler,
        handleBlur: firstNameBlurHandler,
        handleReset: firstNameResetHandler,
        isValid: firstNameIsValid,
        hasError: firstNameHasError
    } = useInputValidation({ validators: [inputHasValueValidator], validateOnSubmit: formIsSubmitted });
    const {
        value: lastName,
        handleChange: lastNameChangeHandler,
        handleBlur: lastNameBlurHandler,
        handleReset: lastNameResetHandler,
        isValid: lastNameIsValid,
        hasError: lastNameHasError
    } = useInputValidation({ validators: [inputHasValueValidator], validateOnSubmit: formIsSubmitted });
    const {
        value: email,
        handleChange: emailChangeHandler,
        handleBlur: emailBlurHandler,
        handleReset: emailResetHandler,
        isValid: emailIsValid,
        hasError: emailHasError,
        errorIndex: emailErrorIndex
    } = useInputValidation({
        validators: [inputHasValueValidator, emailValidator, () => !emailExists],
        validateOnSubmit: formIsSubmitted
    });
    const {
        value: company,
        handleChange: companyChangeHandler,
        handleBlur: companyBlurHandler,
        handleReset: companyResetHandler,
        isValid: companyIsValid,
        hasError: companyHasError
    } = useInputValidation({ validators: [inputHasValueValidator], validateOnSubmit: formIsSubmitted });
    const {
        value: phoneNumber,
        handleChange: phoneNumberChangeHandler,
        handleBlur: phoneNumberBlurHandler,
        handleReset: phoneNumberResetHandler,
        hasError: phoneNumberHasError,
        isValid: phoneNumberIsValid,
        errorIndex: phoneNumberErrorIndex
    } = useMaskedInputValidation({
        validators: [inputHasValueValidator, (value) => isValidPhoneNumber(value, 'US')],
        validateOnSubmit: formIsSubmitted,
        inputMask: '(###) ###-#### x######'
    });
    const {
        value: mobileNumber,
        handleChange: mobileNumberChangeHandler,
        handleBlur: mobileNumberBlurHandler,
        handleReset: mobileNumberResetHandler,
        hasError: mobileNumberHasError,
        isValid: mobileNumberIsValid
    } = useMaskedInputValidation({
        validators: [(value) => isValidPhoneNumber(value, 'US')],
        required: false,
        inputMask: '(###) ###-####'
    });
    const {
        value: date,
        handleChange: dateChangeHandler,
        handleBlur: dateBlurHandler,
        handleReset: dateResetHandler,
        handlePicker: datePickerHandler,
        hasError: dateHasError,
        isValid: dateIsValid
    } = useInputValidation({
        validators: [dateFormatValidator, dateIsAfterValidator],
        required: false
    });
    const {
        value: selectedRole,
        handleReset: roleResetHandler,
        handlePicker: roleChangeHandler,
        hasError: roleHasError,
        isValid: roleIsValid
    } = useInputValidation({
        validators: [inputHasValueValidator],
        validateOnSubmit: formIsSubmitted
    });

    const handleRemoveUser = useCallback(
        (payload: number) => {
            dispatch({ type: 'REMOVE_TEAM_MEMBER', payload });
        },
        [dispatch]
    );

    const handleAddUser = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setFormIsSubmitted(true);
        // Handle required and optional parameters, "isValid" for required, "hasError" for optional
        if (
            firstNameIsValid &&
            lastNameIsValid &&
            emailIsValid &&
            companyIsValid &&
            phoneNumberIsValid &&
            roleIsValid &&
            !mobileNumberHasError &&
            !dateHasError
        ) {
            firstNameResetHandler();
            lastNameResetHandler();
            emailResetHandler();
            companyResetHandler();
            phoneNumberResetHandler();
            mobileNumberResetHandler();
            dateResetHandler();
            roleResetHandler();

            const roles = data?.Roles?.map((item) => {
                if (item?.Text === selectedRole) {
                    return { ...item, IsSelected: true };
                } else {
                    return { ...item, IsSelected: false };
                }
            });
            dispatch({
                type: 'ADD_TEAM_MEMBER',
                payload: {
                    FirstName: firstName.trim(),
                    LastName: lastName.trim(),
                    Email: email.trim(),
                    CompanyName: company.trim(),
                    Phone: phoneNumber.trim(),
                    MobileNumber: mobileNumber.trim(),
                    UserRole: selectedRole,
                    Roles: roles,
                    ExpirationDate: date.trim()
                }
            });

            setFormIsSubmitted(false);
        }
    };

    useImperativeHandle(ref, () => {
        return {
            getData() {
                const { ParentServices, ...rest } = data!;

                return { ...rest, ChangedProperties: state.changedProperties, Users: state.newTeamMembers };
            },
            validate() {
                return state.newTeamMembers.length > 0;
            }
        };
    });

    const toast = useToast();

    const [validateNewTeamMember, { isFetching: emailValidationInProgress }] = useLazyValidateNewTeamMemberQuery();

    const handleEmailValidation = async (e: ChangeEvent<HTMLInputElement>) => {
        emailBlurHandler(e);

        if (!emailIsValid) return;

        if (state.newTeamMembers.some((item) => item.Email === email.trim())) {
            setEmailExists(true);
            return;
        }

        try {
            const response = await validateNewTeamMember({ Email: email.trim() }).unwrap();

            setEmailExists(response);
        } catch (error) {
            toast.send({ type: 'error', message: translate('SomethingWentWrong_Label') });
        }
    };

    return (
        <TeamSectionWrapper title={translate('AddUsersAddUserDetails_Label')} isLoading={isLoading}>
            <div className='container-fluid p-0'>
                <form className='container-fluid p-0' onSubmit={handleAddUser}>
                    <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3'>
                        <div className='col order-md-1'>
                            <div className='form-group'>
                                <FormInputField
                                    label={translate('FirstName_Label')}
                                    value={firstName}
                                    onChange={firstNameChangeHandler}
                                    onBlur={firstNameBlurHandler}
                                    isValid={firstNameIsValid}
                                    hasError={firstNameHasError}
                                />
                                {firstNameHasError && <div className='error-message'>{translate('RequiredFirstName_Label')}</div>}
                            </div>
                        </div>
                        <div className='col order-md-2'>
                            <div className='form-group'>
                                <FormInputField
                                    label={translate('LastName_Label')}
                                    value={lastName}
                                    onChange={lastNameChangeHandler}
                                    onBlur={lastNameBlurHandler}
                                    isValid={lastNameIsValid}
                                    hasError={lastNameHasError}
                                />
                                {lastNameHasError && <div className='error-message'>{translate('RequiredLastName_Label')}</div>}
                            </div>
                        </div>
                        <div className='col order-md-4'>
                            <div className='form-group'>
                                <FormInputField
                                    label={translate('Email_Label')}
                                    value={email}
                                    onChange={(e) => {
                                        emailChangeHandler(e);
                                        setEmailExists(false);
                                    }}
                                    onBlur={handleEmailValidation}
                                    isValid={emailIsValid}
                                    hasError={emailHasError}
                                    validationInProgress={emailValidationInProgress}
                                    autoComplete='email'
                                />
                                {emailHasError && emailErrorIndex === 0 && (
                                    <div className='error-message'>{translate('RequiredEmail_Label')}</div>
                                )}
                                {emailHasError && emailErrorIndex === 1 && (
                                    <div className='error-message'>{translate('InvalidEmail_Label')}</div>
                                )}
                                {emailHasError && emailErrorIndex === 2 && (
                                    <div className='error-message'>{translate('AlreadyRegisteredEmail_Label')}</div>
                                )}
                            </div>
                        </div>
                        <div className='col order-md-5'>
                            <div className='form-group'>
                                <FormInputField
                                    label={translate('PhoneNumber_Label')}
                                    value={phoneNumber}
                                    onChange={phoneNumberChangeHandler}
                                    onBlur={phoneNumberBlurHandler}
                                    isValid={phoneNumberIsValid}
                                    hasError={phoneNumberHasError}
                                />
                                {phoneNumberHasError && phoneNumberErrorIndex === 0 && (
                                    <div className='error-message'>{translate('RequiredPhoneNumber_Label')}</div>
                                )}
                                {phoneNumberHasError && phoneNumberErrorIndex === 1 && (
                                    <div className='error-message'>{translate('InvalidPhoneNumber_Label')}</div>
                                )}
                            </div>
                        </div>
                        <div className='col order-md-6'>
                            <div className='form-group'>
                                <FormInputField
                                    label={translate('MobileNumber_Label')}
                                    value={mobileNumber}
                                    onChange={mobileNumberChangeHandler}
                                    onBlur={mobileNumberBlurHandler}
                                    isValid={mobileNumberIsValid}
                                    hasError={mobileNumberHasError}
                                />
                                {mobileNumberHasError && <div className='error-message'>{translate('InvalidMobileNumber_Label')}</div>}
                            </div>
                        </div>
                        <div className='col order-md-7'>
                            <div className='form-group'>
                                <FormInputField
                                    label={translate('Company_Label')}
                                    value={company}
                                    onChange={companyChangeHandler}
                                    onBlur={companyBlurHandler}
                                    isValid={companyIsValid}
                                    hasError={companyHasError}
                                />
                                {companyHasError && <div className='error-message'>{translate('RequiredCompany_Label')}</div>}
                            </div>
                        </div>
                        <div className='col order-md-8'>
                            <div className='form-group'>
                                <Select
                                    label={translate('UserRole_Label')}
                                    options={userRoles}
                                    onChange={roleChangeHandler}
                                    value={selectedRole}
                                    isValid={roleIsValid}
                                    hasError={roleHasError}
                                />
                                {roleHasError && <div className='error-message'>{translate('RequiredRole_Label')}</div>}
                            </div>
                        </div>
                        <div className='col order-md-9'>
                            <div className='form-group'>
                                <FormDatePicker
                                    label={translate('ExpirationDate_Label')}
                                    noBorder
                                    noHeader
                                    value={date}
                                    onChange={dateChangeHandler}
                                    onDateChange={datePickerHandler}
                                    onBlur={dateBlurHandler}
                                    dateFormat='MM-DD-YYYY'
                                    hasError={dateHasError}
                                    isValid={dateIsValid}
                                    placeholder={translate('NoExpirationDate_Label')}
                                />
                                {dateHasError && <div className='error-message'>{translate('InvalidDate_Label')}</div>}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 mt-2'>
                            <Button type='submit' variant='tertiary-blue' disabled={emailValidationInProgress}>
                                <FontAwesomeIcon icon={faUserPlus} className={'mr-1'} />
                                {translate('CreateUser_Label')}
                            </Button>
                        </div>
                    </div>
                </form>
                <AddNewUserTable data={state.newTeamMembers} onRemoveUser={handleRemoveUser} />
            </div>
        </TeamSectionWrapper>
    );
});

export default UserDetails;
