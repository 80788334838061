import 'bootstrap/dist/css/bootstrap.min.css';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import { closeMenuModal } from '../../utils/menuModalHandler.js';
import MenuSection from './MenuSection';

class MenuSectionContainer extends Component {
    state = { showDetails: true };

    toggleDetails = () => {
        this.setState((prevState) => {
            return { showDetails: !prevState.showDetails };
        });
    };

    showDetailsIconClass() {
        let elementClass = ['fa'];
        if (this.state.showDetails) {
            elementClass.push('fa-caret-left fa-lg');
        } else {
            elementClass.push('fa-caret-down fa-lg');
        }
        return elementClass.join(' ');
    }

    render() {
        let menuSectionTitle = (
            <div className='col-sm-11 menu-section-title'>{this.props.translations.Phrases[this.props.section.Title]}</div>
        );

        if (this.props.section.Title === 'MyAccountTitle_Label' && this.props.notices === true && this.state.showDetails === false) {
            menuSectionTitle = (
                <div className='col-sm-11'>
                    {this.props.translations.Phrases[this.props.section.Title]}&nbsp;&nbsp;&nbsp;
                    <Link to='/Notices' onClick={() => this.props.onCloseModal()}>
                        <i className='fa fa-bell'></i>
                    </Link>
                </div>
            );
        }

        let menuSection = this.state.showDetails ? (
            <MenuSection key={this.props.section.Title} section={this.props.section} notices={this.props.notices} />
        ) : null;

        if (this.props.section.Title === 'HelpTitle_Label') {
            menuSection = !this.state.showDetails ? (
                <MenuSection key={this.props.section.Title} section={this.props.section} notices={this.props.notices} />
            ) : null;
        }

        let className = this.state.showDetails ? 'fa fa-caret-down fa-lg' : 'fa fa-caret-left fa-lg';

        if (this.props.section.Title === 'HelpTitle_Label') {
            className = !this.state.showDetails ? 'fa fa-caret-down fa-lg' : 'fa fa-caret-left fa-lg';
        }
        return (
            <ul>
                {menuSectionTitle}
                <li className='menu-group-header' onClick={this.toggleDetails}>
                    <span className={className}></span>
                </li>
                {menuSection}
            </ul>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseModal: () => {
            closeMenuModal();
            dispatch(actions.closeModal());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuSectionContainer);
