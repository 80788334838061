import { useCallback, useEffect, useRef, useState } from 'react';
import { ColumnSettings } from '../models/GridPreferences';
import useElementDimensions from './useElementDimensions';

interface UseGridColumnMinWidthProps {
    columns?: null | ColumnSettings[];
    adjustPadding?: number;
    columnMin?: number;
    scrollbarWidth?: number;
}

const useGridColumnMinWidth = ({ columns, adjustPadding = 0, columnMin = 4, scrollbarWidth = 19 }: UseGridColumnMinWidthProps) => {
    const [applyMinWidth, setApplyMinWidth] = useState(false);
    const minGridWidth = useRef(0);
    const { width: gridWidth } = useElementDimensions('.navbar-and-stage-wrapper');

    useEffect(() => {
        if (!columns) return;
        minGridWidth.current = columns.reduce((previousValue, currentValue) => (previousValue += currentValue.Width), 0);
    }, [columns]);

    useEffect(() => {
        if (columns) {
            setApplyMinWidth(gridWidth < minGridWidth.current);
        }
    }, [gridWidth, columns]);

    const setWidth = useCallback(
        (minWidth: number | undefined = 0) => {
            if (!columns) return 0;
            let width = applyMinWidth ? minWidth : minWidth + (gridWidth - scrollbarWidth - minGridWidth.current) / columns.length;
            return width < columnMin ? width : (width -= adjustPadding);
        },
        [applyMinWidth, columns, gridWidth, adjustPadding, columnMin, scrollbarWidth]
    );

    return { setWidth };
};

export default useGridColumnMinWidth;
