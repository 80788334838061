import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { EllipsisSlice } from '../../common/models/ReduxSlices';
import { GetCustomsInfoID } from '../actions/ellipsis';

const initialState: EllipsisSlice = {
    ellipsisData: { customsInfoID: undefined, isLoading: false, error: undefined, entryNo: undefined }
};

const ellipsisSlice = createSlice({
    name: 'ellipsisSlice',
    initialState,
    reducers: {
        setEllipsisEntryNo(state, action: PayloadAction<string>) {
            state.ellipsisData.entryNo = action.payload;
        },
        setInitialEllipsisState(state) {
            state.ellipsisData = initialState.ellipsisData;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetCustomsInfoID.pending, (state, action) => {
                state.ellipsisData.isLoading = true;
                state.ellipsisData.error = undefined;
                if (action.meta.arg.IsForEllipsis) {
                    state.ellipsisData.entryNo = action.meta.arg.DocumentEntryNumber;
                }
            })
            .addCase(GetCustomsInfoID.fulfilled, (state, action) => {
                state.ellipsisData.isLoading = false;
                state.ellipsisData.customsInfoID = action.payload;
            })
            .addCase(GetCustomsInfoID.rejected, (state, action) => {
                state.ellipsisData.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.ellipsisData.error = action.payload;
                } else {
                    state.ellipsisData.error = action.error.message;
                }
            });
    }
});

export const { setInitialEllipsisState, setEllipsisEntryNo } = ellipsisSlice.actions;

export default ellipsisSlice.reducer;
