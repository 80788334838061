import { RefObject } from 'react';

interface FilterBarHasFixedWidthProps {
    scrollableDivRef: RefObject<HTMLDivElement> | undefined;
    filtersHaveSelected: boolean;
}

const hasFilterBarFixedWidth = ({ scrollableDivRef, filtersHaveSelected }: FilterBarHasFixedWidthProps) => {
    if (scrollableDivRef && scrollableDivRef.current) {
        // check whether the filter bar is close to the edge of the screen
        const filterBarRect = scrollableDivRef.current.getBoundingClientRect();
        let lastChild: any;
        lastChild = Array.from(scrollableDivRef.current.children).reverse()[0];
        const filterBarExtent = filterBarRect.x + lastChild.offsetLeft + lastChild.offsetWidth;
        const clearButtonWidth = !filtersHaveSelected ? window.innerWidth * 0.1 : 0;
        const hasReachedMaxDynamicWidth = filterBarExtent >= window.innerWidth * 0.9 + clearButtonWidth;

        return hasReachedMaxDynamicWidth;
    }
};

export default hasFilterBarFixedWidth;
