import { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import ClientServicesGrid from './ClientServicesGrid';

class ClientServicesByCountry extends Component {
    toggleCountryServicesEvent = () => {
        this.props.onToggleCountryServices(this.props.countryIndex, this.props.area);
    };

    render() {
        const parents = this.props.country.ParentServices.map((parent, index) => {
            return (
                <ClientServicesGrid
                    pendingUser={this.props.pendingUser}
                    parentService={parent}
                    key={index + parent.Name}
                    parentIndex={index}
                    countryIndex={this.props.countryIndex}
                    readonly={this.props.country.IsReadonly || this.props.readonly}
                    area={this.props.area}
                    userRoles={this.props.userRoles}
                    isRoleDropdownChanged={this.props.isRoleDropdownChanged}
                    selectedClients={this.props.selectedClients}
                />
            );
        });

        const toggleIconClass = this.props.country.IsHidden ? 'fa fa-caret-right' : 'fa fa-caret-down';

        const content = this.props.country.IsHidden ? '' : <div>{parents}</div>;

        return (
            <div>
                <div>
                    <h3 onClick={this.toggleCountryServicesEvent} className='bold'>
                        <i className={toggleIconClass}></i> {this.props.country.CountryCode.toUpperCase()} Clients
                    </h3>
                </div>
                {content}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onToggleCountryServices: (countryIndex, area) => dispatch(actionCreators.toggleCountryServices(countryIndex, area))
    };
};
export default connect(null, mapDispatchToProps)(ClientServicesByCountry);
