import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactDOM from 'react-dom';
import Spinner from '../../../../common/features/Spinner';

interface FixedFooterWhiteProps {
    count: number;
    countLabel: string;
    primaryLabel?: string;
    primaryIcon?: any;
    isPending: boolean;
    primaryDisabled?: boolean;
    secondaryLabel?: string;
    secondaryIcon?: any;
    message?: string;
    primaryOnClick?: () => void;
    secondaryOnClick?: () => void;
}

const FixedFooterWhite: React.FC<FixedFooterWhiteProps> = ({
    count,
    countLabel,
    primaryLabel,
    primaryIcon,
    primaryOnClick,
    isPending,
    primaryDisabled = false,
    secondaryLabel,
    secondaryIcon,
    secondaryOnClick,
    message = ''
}) => {
    const content = (
        <div id='FixedFooter' className='bg-white shadow-lg'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col'>
                        <div className='d-lg-flex justify-content-between'>
                            <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-md-end py-3 align-items-center'>
                                <div className='my-1 mr-md-3'>
                                    <span className='font-weight-bold'>{count}</span> {countLabel}
                                </div>
                            </div>
                            <div className='d-flex flex-column flex-md-row align-items-md-center align-items-stretch justify-content-md-end py-3'>
                                {secondaryLabel && (
                                    <button type='button' className='btn btn-tertiary-blue btn-lg m-1 flex-fill' onClick={secondaryOnClick}>
                                        {secondaryIcon && <FontAwesomeIcon icon={secondaryIcon} className='mr-1' />}
                                        {secondaryLabel}
                                    </button>
                                )}
                                {message?.length! > 1 && (
                                    <div className='d-flex justify-content-center align-items-center text-danger mr-2 mb-2'>{message}</div>
                                )}
                                {primaryLabel && (
                                    <button
                                        type='button'
                                        className='btn btn-primary btn-lg m-1 flex-fill d-flex align-items-center justify-content-center'
                                        onClick={primaryOnClick}
                                        disabled={primaryDisabled || isPending}
                                    >
                                        {isPending && <Spinner size='small' className='mr-2' color='white' />}
                                        {primaryIcon && <FontAwesomeIcon icon={primaryIcon} className='mr-1' />}
                                        {primaryLabel}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const fixedFooterContainingElement = document.querySelector('.navbar-and-stage-wrapper');

    if (fixedFooterContainingElement) return ReactDOM.createPortal(content, fixedFooterContainingElement);

    return content;
};

export default FixedFooterWhite;
