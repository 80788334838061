import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ClientService } from '../models';

interface ClientsAndPermissionsTableNumberCellProps extends GridCellProps {
    data: ClientService[];
}

export default function ClientsAndPermissionsTableNumberCell({ dataIndex, data }: ClientsAndPermissionsTableNumberCellProps) {
    return <td>{data[dataIndex].Number + '-' + data[dataIndex].CaptionNumber}</td>;
}
