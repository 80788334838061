import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchSlice } from '../../common/models/ReduxSlices';

const initialState: SearchSlice = {
    tags: []
};

const advancedSearchFiltersSlice = createSlice({
    name: 'advancedSearchFilters',
    initialState,
    reducers: {
        setSearchFilter(state, action: PayloadAction<string[]>) {
            state.tags = action.payload;
        },
        resetSearchFilter() {
            return { ...initialState };
        }
    }
});

export const { setSearchFilter, resetSearchFilter } = advancedSearchFiltersSlice.actions;

export default advancedSearchFiltersSlice.reducer;
