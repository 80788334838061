import { SortDescriptor } from '@progress/kendo-data-query';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../../axios-portal';
import { API_CLEARANCES_PATH, API_SEARCH_SHIPMENT_PATH } from '../../../../common/constants/constants-portal';
import { transformData } from '../../common/functions/clearancesFunctions';
import { DocumentsIndex } from '../../common/models/Documents';
import { ShipmentListData } from '../../common/models/ResponseData';
import { ColumnFilters } from '../../common/models/ShipmentGridSettings';
import { GET_DOCUMENTS_INDEX, GET_SHIPMENT_LIST } from './actionTypes';

export const GetShipmentList = createAsyncThunk(
    GET_SHIPMENT_LIST,
    async (
        payload: {
            Status: null | string;
            Country: string | null | undefined;
            ClientCaptionNumbers: string[] | undefined;
            PageSize: number;
            OrderBy: SortDescriptor[] | null;
            StartRow: number;
            ColumnFilters: ColumnFilters;
            DateRange: {
                dateRangeSelected: string;
                customDateRange:
                    | {
                          type: string;
                          date: string;
                      }[]
                    | undefined;
            };
            AdvancedSearchFilters: {
                Tags: string[];
            };
            ClearancesCreatedOnDate: string;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post<ShipmentListData>(`${API_CLEARANCES_PATH}/clearanceList`, payload);
            return transformData(response.data);
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const GetDocumentsIndex = createAsyncThunk(GET_DOCUMENTS_INDEX, async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get<DocumentsIndex>(`${API_SEARCH_SHIPMENT_PATH}/Index`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});
