import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';
import { initializeGTM } from '../../../utils/gtm';
import {
    API_HYDRATION_PATH,
    LanguageCodes,
    PortalRoutes,
    shipmentDataSourceConstants,
    Translations
} from '../../common/constants/constants-portal';
import AlertsDrawer from '../../common/features/Alerts/AlertsDrawer';
import AlertsToasts from '../../common/features/Alerts/AlertsToasts';
import ErrorPage from '../../common/features/ErrorPage';
import HeaderNav from '../../common/features/HeaderNav/HeaderNav';
import type { SidebarActions } from '../../common/features/Sidebar/Sidebar';
import Sidebar from '../../common/features/Sidebar/Sidebar';
import Spinner from '../../common/features/Spinner';
import { isFetchBaseQueryError } from '../../common/functions/utils';
import useAlerts from '../../common/hooks/useAlerts';
import useLocalStorageItem from '../../common/hooks/useLocalStorageItem';
import usePageLoad from '../../common/hooks/usePageLoad';
import useTranslationHydration from '../../common/hooks/useTranslationHydration';
import { ChangeLanguage, ChangeLanguageHydration, GetTranslations } from '../../redux/actions/translations';
import { useHydrationQuery } from '../../redux/api/hydrationApi';
import { resetChooser } from '../../redux/reducers/clientSelectionReducer';
import { setLanguageSelected } from '../../redux/reducers/languageSlice';
import Clearances from './Clearances';
import { clearancesGridSettingsConstants, StatusFilter } from './common/constants/constants-clearances';
import { GetUserClearancesGridSetting } from './redux/actions/clearancesGridPreferences';
import { useGetShipmentDataSourceQuery } from './redux/api/clearancesApi';
import { useClearancesDispatch, useClearancesSelector } from './redux/hooks';
import { resetAdvancedSearchSettings } from './redux/reducers/advancedSearchSettingsSlice';
import { restartCadCustomsInfoId, setCadCustomsInfoId } from './redux/reducers/cadDetailsSlice';
import { setDefaultGridColumns } from './redux/reducers/clearancesGridPreferencesSlice';
import { resetDateRange, setDateRangeSelected } from './redux/reducers/dateRangeSlice';
import { resetSearchFilter } from './redux/reducers/searchSlice';
import { resetShipmentGridSettings, setColumnFilters } from './redux/reducers/shipmentGridSettingsSlice';
import { resetStatus, setStatusSelected } from './redux/reducers/shipmentStatusSlice';

const App = () => {
    const { isLoading: translationsLoading, error: translationsError, translations } = useClearancesSelector((state) => state.translations);
    const { viewImages, hideMenu } = useClearancesSelector((state) => state.viewImages);
    const { languageSelected } = useClearancesSelector((state) => state.language);
    const { initialClientSelection } = useClearancesSelector((state) => state.clientSelection);
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);
    const { cadCustomsInfoId } = useClearancesSelector((state) => state.cadDetails);
    const dispatch = useClearancesDispatch();

    const location = useLocation();
    const history = useHistory();

    const { noticesIsLoading, noticesError } = useAlerts({ languageCode: translations?.LanguageCode });
    const [showAlerts, setShowAlerts] = useState(false);
    const pageLoading = usePageLoad();
    const { value: preApplyStatus } = useLocalStorageItem('preApplyStatus');
    const sidebar = useRef<SidebarActions>(null);

    const { data: user, isFetching: userIsLoading, error: userError } = useHydrationQuery(undefined, { refetchOnMountOrArgChange: true });
    const { data: shipmentDataSource, isFetching: isShipmentDataSourceLoading } = useGetShipmentDataSourceQuery('ca', {
        refetchOnMountOrArgChange: true
    });

    const errorStatus =
        (isFetchBaseQueryError(userError) && userError.status) ||
        (isFetchBaseQueryError(translationsError) && translationsError.status) ||
        (isFetchBaseQueryError(noticesError) && noticesError.status) ||
        undefined;

    // Set pre applied statuses
    useEffect(() => {
        if (preApplyStatus) {
            const index = Object.values(StatusFilter).findIndex((item) => item.id === preApplyStatus);
            dispatch(setStatusSelected([index]));

            dispatch(
                setColumnFilters({
                    ClearanceStatus: [StatusFilter[index].label.replace('_Label', '')]
                })
            );
            dispatch(setDateRangeSelected(0));
            localStorage.setItem('preApplyStatus', '');
        }
    }, [preApplyStatus, dispatch]);

    // Redirect to CAD or CAD Details
    useEffect(() => {
        if (isShipmentDataSourceLoading) return;

        // Check if url contains customsInfoId
        const match = location.pathname.match(/\/Clearances\/(\d+)/);

        if (shipmentDataSource === shipmentDataSourceConstants.us && cadCustomsInfoId) {
            dispatch(restartCadCustomsInfoId());
        }

        //  If url contains customsInfoId and shipment data source is CA, allow redirection to CAD Details
        if (match && shipmentDataSource !== shipmentDataSourceConstants.us) {
            dispatch(setCadCustomsInfoId(match[1]));
            let origin;
            if (location.search.includes('documents')) {
                origin = 'documents';
            } else if (location.search.includes('cad')) {
                origin = 'cad';
            }

            origin
                ? history.push(`${PortalRoutes.clearances}/${match[1]}?origin=${origin}`)
                : history.push(`${PortalRoutes.clearances}/${match[1]}`);
        } else if (shipmentDataSource === shipmentDataSourceConstants.ca) {
            history.push(PortalRoutes.completeEntryData);
        }
    }, [cadCustomsInfoId, dispatch, history, isShipmentDataSourceLoading, location.pathname, location.search, shipmentDataSource]);

    // When user is loaded get the translations related to the user's language code (runs only once)
    useEffect(() => {
        if (user?.LanguageCode) {
            // CHANGE THIS: Get the translations for the current page
            dispatch(GetTranslations({ languageCode: user?.LanguageCode, phraseGroup: Translations.PhraseGroups.Clearances }));
        }
    }, [dispatch, user?.LanguageCode]);

    // Get user grid preferences
    useEffect(() => {
        if (!country?.Code) {
            dispatch(setDefaultGridColumns());
        } else {
            dispatch(
                GetUserClearancesGridSetting(
                    country.Code === 'us'
                        ? { area: clearancesGridSettingsConstants['us'] }
                        : { area: clearancesGridSettingsConstants['ca'] }
                )
            );
        }
    }, [dispatch, country?.Code]);

    useEffect(() => {
        if (viewImages.length > 0 || hideMenu) {
            sidebar.current?.close();
        }
    }, [viewImages, hideMenu]);

    useEffect(() => {
        return () => {
            dispatch(resetChooser());
        };
    }, [dispatch]);

    const toggleSidebar = useCallback(() => {
        sidebar.current?.toggleOpen();
    }, []);

    // Toggle the Alerts Drawer visibility when:
    // - Clicking the "close" button icon on the Alert's drawer
    // - Clicking the overlay
    const toggleAlerts = useCallback(() => {
        setShowAlerts((showAlerts) => !showAlerts);
    }, []);

    // On language change do:
    // - Change language for the user
    // - Get new translations
    // - Get notices (look at parent App.tsx)
    // - Reset all filters
    const changeLanguage = useCallback(
        (languageIndex: number) => {
            // Check if the language is not already selected before changing it
            if (languageSelected?.code !== LanguageCodes[languageIndex].code) {
                // CHANGE THIS: Change language for the current page (Clearances, Team, Profile...)
                dispatch(
                    ChangeLanguage({
                        localization: API_HYDRATION_PATH,
                        languageCode: LanguageCodes[languageIndex].code,
                        phraseGroup: Translations.PhraseGroups.Clearances
                    })
                );
                dispatch(
                    ChangeLanguageHydration({
                        localization: API_HYDRATION_PATH,
                        languageCode: LanguageCodes[languageIndex].code,
                        phraseGroup: Translations.PhraseGroups.Hydration
                    })
                );
                dispatch(setLanguageSelected(languageIndex));
                // CHANGE THIS: Clearances specific resets
                dispatch(resetDateRange());
                dispatch(resetStatus());
                dispatch(resetSearchFilter());
                dispatch(resetAdvancedSearchSettings());
                dispatch(resetShipmentGridSettings());

                // Initialize GTM for Portal
                if (user) {
                    initializeGTM(user.UserId, LanguageCodes[languageIndex].code, true);
                }
            }
        },
        [dispatch, languageSelected?.code, user]
    );

    const translate = useTranslationHydration();
    const pageName = translate('Clearances_Label');
    const pageTitle = pageName.startsWith('Untranslated') ? 'Portal' : `${pageName} | Portal`;

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            {/* Status: pending */}
            {(userIsLoading ||
                pageLoading ||
                translationsLoading ||
                isShipmentDataSourceLoading ||
                (shipmentDataSource === shipmentDataSourceConstants.ca && !cadCustomsInfoId)) && (
                <div className='vw-100 vh-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}
            {/* Status: fulfilled */}
            {!userIsLoading &&
                !pageLoading &&
                !translationsLoading &&
                !userError &&
                !translationsError &&
                !isShipmentDataSourceLoading &&
                (shipmentDataSource !== shipmentDataSourceConstants.ca || cadCustomsInfoId) && (
                    <div className='d-flex'>
                        {/* Sidebar */}
                        <Sidebar ref={sidebar} onLanguageChange={changeLanguage} />
                        <div className='navbar-and-stage-wrapper d-flex flex-column p-0'>
                            {/* Header navigation */}
                            <header>
                                <HeaderNav onToggleSidebar={toggleSidebar} onToggleAlerts={toggleAlerts} />
                            </header>
                            {/* Alerts drawer */}
                            {showAlerts && <AlertsDrawer onToggleAlerts={toggleAlerts} />}
                            {/* Alerts toasts */}
                            {!noticesIsLoading && !noticesError && <AlertsToasts />}
                            {/* CHANGE THIS: Current page (Clearances, Team, Profile...) */}
                            <Clearances />
                        </div>
                    </div>
                )}
            {/* Status: rejected */}
            {errorStatus && (
                <div className='vw-100 vh-100 d-flex align-items-center justify-content-center'>
                    <ErrorPage errorCode={errorStatus} withTranslations={!!translations} />
                </div>
            )}
        </>
    );
};

export default App;
