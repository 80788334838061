import type { DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import type { GridColumnMenuFilterUIProps, GridColumnMenuProps } from '@progress/kendo-react-grid';
import { GridColumnMenuCheckboxFilter, GridColumnMenuFilter, GridColumnMenuSort } from '@progress/kendo-react-grid';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { TEAM_MEMBER_STATUS_LABELS } from '../../../common/constants/constants-team';
import { useUserTeamQuery } from '../../../redux/api/teamApi';

export const ColumnMenuSearchText: React.FC<GridColumnMenuProps> = (props) => {
    return (
        <div>
            <GridColumnMenuSort {...props} />
            <GridColumnMenuFilter {...props} filterUI={CustomTextFilterUI} expanded={true} />
        </div>
    );
};

export const ColumnMenuCheckboxFilter: React.FC<GridColumnMenuProps> = (props) => {
    const { data: team } = useUserTeamQuery();

    const translate = useTranslation();

    let data;

    switch (props.column.field) {
        case 'statusTranslated':
            data = team?.TeamMembers?.map((member) => {
                return {
                    statusTranslated: member.Status ? translate(TEAM_MEMBER_STATUS_LABELS[member.Status]) : '',
                    value: member.Status
                };
            });
            break;
        default:
            break;
    }

    return (
        <div>
            <GridColumnMenuSort {...props} />
            {data && <GridColumnMenuCheckboxFilter {...props} data={data} expanded={true} searchBox={() => null} uniqueData={true} />}
        </div>
    );
};

const CustomTextFilterUI: React.FC<GridColumnMenuFilterUIProps> = (props) => {
    const [inputValue, setInputValue] = useState(props.firstFilterProps.value || '');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { firstFilterProps } = props;
        const value = event.target.value;

        firstFilterProps.onChange({
            value,
            operator: 'contains',
            syntheticEvent: event
        });

        setInputValue(value);
    };

    return (
        <div>
            <input className='form-control' id='columnfilter' name='columnSearch' type='text' value={inputValue} onChange={handleChange} />
        </div>
    );
};

export const ColumnMenuDateFilter: React.FC<GridColumnMenuProps> = (props) => {
    return (
        <div>
            <GridColumnMenuSort {...props} />
            <GridColumnMenuFilter {...props} filterUI={CustomDateFilterUI} expanded={true} />
        </div>
    );
};

export const CustomDateFilterUI: React.FC<GridColumnMenuFilterUIProps> = (props) => {
    const { firstFilterProps, secondFilterProps } = props;
    const startDate =
        firstFilterProps.operator === 'gte' || firstFilterProps.operator === 'contains' ? firstFilterProps.value : secondFilterProps.value;
    const endDate = firstFilterProps.operator === 'lte' ? firstFilterProps.value : secondFilterProps.value;

    const handleStartDateChange = (event: DatePickerChangeEvent) => {
        const { firstFilterProps, secondFilterProps } = props;
        const value = event.value?.toISOString();

        if (firstFilterProps.operator === 'lte') {
            secondFilterProps.onChange({
                value,
                operator: 'gte',
                syntheticEvent: event.syntheticEvent
            });
        } else {
            firstFilterProps.onChange({
                value,
                operator: 'gte',
                syntheticEvent: event.syntheticEvent
            });
        }
    };

    const handleEndDateChange = (event: DatePickerChangeEvent) => {
        const { firstFilterProps, secondFilterProps } = props;
        const value = event.value ? dayjs(event.value).endOf('day').toISOString() : null;

        if (firstFilterProps.operator === 'lte') {
            firstFilterProps.onChange({
                value,
                operator: 'lte',
                syntheticEvent: event.syntheticEvent
            });
        } else {
            secondFilterProps.onChange({
                value,
                operator: 'lte',
                syntheticEvent: event.syntheticEvent
            });
        }
    };

    return (
        <div>
            <div className='m-1 p-1'>
                <label>Start date</label>
                <DatePicker
                    name='startDate'
                    onChange={handleStartDateChange}
                    value={startDate ? new Date(startDate) : undefined}
                    max={endDate ? new Date(endDate) : undefined}
                />
            </div>
            <div className='m-1 p-1'>
                <label>End date</label>
                <DatePicker
                    name='endDate'
                    onChange={handleEndDateChange}
                    value={endDate ? new Date(endDate) : undefined}
                    min={startDate ? new Date(startDate) : undefined}
                />
            </div>
        </div>
    );
};
