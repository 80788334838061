import { createGenericGridSettingsSlice, genericGridSettingsInitialState } from '../../../../redux/reducers/genericGridSettingsSlice';

const profileGridSettingsSlice = createGenericGridSettingsSlice({
    name: 'profileGridSettings',
    initialState: genericGridSettingsInitialState,
    reducers: {}
});

export const {
    setPageSelected,
    setDataState,
    setTotalItems,
    setSelectedState,
    setHeaderCheckbox,
    setCurrentDataItems,
    setSort,
    resetDataState,
    resetSelectedState
} = profileGridSettingsSlice.actions;

export default profileGridSettingsSlice.reducer;
