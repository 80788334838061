import $ from 'jquery';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actionCreators from '../../store/actions/index';

class Error extends Component {
    componentDidMount() {
        $('#overlay').show();
        this.props.onInitTranslations('Error.UnauthorizedError');
    }

    componentDidUpdate() {
        if (document.title.length === 2) {
            if (this.props.location.pathname.includes('Error')) {
                document.title = this.props.translations.Phrases['Page_Title'];
            } else {
                document.title = this.props.translations.Phrases['Page_Not_Found_Page_Title'];
            }
            $('#overlay').hide();
        }
    }

    render() {
        if (this.props.translations.PhraseGroupCode === 'Error.UnauthorizedError') {
            const value = this.props.location.pathname.includes('Error');
            const header = value
                ? this.props.translations.Phrases['Page_Header']
                : this.props.translations.Phrases['Page_Not_Found_Header'];
            const text = value
                ? this.props.translations.Phrases['Text_Unauthorized']
                : this.props.translations.Phrases['Page_Not_Found_Text'];
            return (
                <section className='page-wrapper page-error'>
                    <div className='error-icon'>
                        <i className='fa fa-ban'></i>
                    </div>
                    <span className='error-header text-danger'>{header}</span>
                    <div>
                        <span className='error-text'>{text}</span>
                        <span className='error-text'>
                            <i className='fa fa-arrow-circle-left'></i>
                            <button className='anchor-button' onClick={this.props.history.goBack}>
                                {this.props.translations.Phrases['BackToPreviousPage_Label']}
                            </button>
                        </span>
                    </div>
                </section>
            );
        } else {
            return <section className='page-wrapper page-error'></section>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Error));
