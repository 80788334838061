import { Component } from 'react';
import { connect } from 'react-redux';
import CheckBox from '../../common/CheckBox/CheckBox';
import * as actions from '../../store/actions/index';

class ClientServiceRow extends Component {
    render() {
        const features = this.props.availableFeatures.map((f, index) => {
            const feature = this.props.clientService.Features.find((x) => {
                return x.FeatureId === f.FeatureId;
            });
            const featureIndex = this.props.clientService.Features.indexOf(feature);

            if (!feature) {
                return <td key={'features-' + index}></td>;
            }

            const name =
                'ParentServices.' +
                this.props.parentIndex +
                '.ClientServices.' +
                this.props.clientIndex +
                '.Features.' +
                featureIndex +
                '.IsSelected';

            const disabled = this.props.readonly || feature.IsPending;

            return (
                <td key={'features-' + index} className='text-center'>
                    <CheckBox embed name={name} checked={feature.IsSelected} onChecked={this.props.onFeatureChecked} disabled={disabled} />
                </td>
            );
        });

        return (
            <tr>
                <td className='bold'>{this.props.clientService.Number + this.props.clientService.CaptionNumber}</td>
                <td>{this.props.clientService.Name}</td>
                {features}
            </tr>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFeatureChecked: (checked, name) => dispatch(actions.checkFeature(checked, name))
    };
};

export default connect(null, mapDispatchToProps)(ClientServiceRow);
