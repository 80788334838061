export const maskInput = (value: string, mask: string) => {
    let index = 0;
    // Allow only numbers
    value = value.replace(/\D/g, '');
    if (!value) {
        return '';
    }
    // Loop through the "mask" string and replace "#" with actual values coming from the input
    for (let i = 0; i < value.length; i++) {
        index = mask.indexOf('#');
        mask = mask.replace('#', value[i]);
    }
    // Check if all "#" are replaced or not and return new string accordingly
    return mask.indexOf('#') !== -1 ? mask.slice(0, index + 1) : mask;
};
