import { API_PRODUCT_REPORT_PATH } from '../../../../common/constants/constants-portal';
import { apiSlice } from '../../../../redux/api/apiSlice';

interface DownloadProductReportBody {
    [key: string]: string[];
}

export const productReportApi = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        downloadProductReport: build.mutation<string, DownloadProductReportBody>({
            query: (body) => ({
                url: `${API_PRODUCT_REPORT_PATH}`,
                method: 'POST',
                body: body.clientNumbers
            })
        })
    })
});

export const { useDownloadProductReportMutation } = productReportApi;
