import { Component } from 'react';
import { connect } from 'react-redux';
import MoreDetailsIcon from '../../../common/Icons/MoreDetailsIcon';
import Searchbox from '../../../common/Searchbox/Searchbox';
import * as actionCreators from '../../../store/actions/index';

class OboSalesforceContactControl extends Component {
    state = {
        searchBoxValue: ''
    };

    componentDidUpdate(prevProps) {
        if (
            this.props.model.ChangedProperties !== prevProps.model.ChangedProperties ||
            this.props.model.User.Email !== prevProps.model.User.Email
        ) {
            this.props.validateOnBehalfOf(this.props.model);
        }
    }

    resetUser = () => {
        this.props.resetUser();
    };

    onSelectSalesforceContact = (contact) => {
        this.props.selectedContact();
        this.props.selectSalesforceContact(contact);
    };

    getUserDetails = (user) => {
        return [
            { label: this.props.translations.Phrases['Email_Label'], value: user.Email },
            { label: this.props.translations.Phrases['Phone_Label'], value: user.Phone },
            { label: this.props.translations.Phrases['Account_Label'], value: user.Account },
            { label: this.props.translations.Phrases['ContactId_Label'], value: user.ContactId },
            { label: this.props.translations.Phrases['Address_Label'], value: user.Address }
        ];
    };

    formatResultBuilder = (i) => {
        const address = i.Address || '';
        const addressArray = address.split(',');
        let ContactAddress = <div></div>;
        if (addressArray.length > 1) {
            ContactAddress = (
                <div className='data-row'>
                    <div className='result-label'> Address: </div>
                    <div className='result-value'>
                        <div>{addressArray[0]}</div>
                        <div>
                            {addressArray[1]}, {addressArray[2]}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className='search-results'>
                <div>
                    <strong> {i.FullName} </strong>
                </div>
                <div className='data-row'>
                    <div className='result-label'>Email:</div>
                    <div className='result-value'>{i.Email}</div>
                </div>
                <div className='data-row'>
                    <div className='result-label'>Phone:</div>
                    <div className='result-value'>{i.Phone}</div>
                </div>
                <div className='data-row'>
                    <div className='result-label'>Account:</div>
                    <div className='result-value'>{i.Account}</div>
                </div>
                <div className='data-row'>
                    <div className='result-label'> ContactId: </div>
                    <div className='result-value'>{i.SalesforceId} </div>
                </div>
                {ContactAddress}
            </div>
        );
    };

    searchBoxValueChanged = (val) => {
        this.setState({
            searchBoxValue: val
        });
    };

    render() {
        const userDetails = this.getUserDetails(this.props.viewModel.User);
        let resetButton = (
            <div className='pull-right'>
                <span className='btn btn-link' onClick={this.resetUser}>
                    {this.props.translations.Phrases['Reset_Label']}
                </span>
            </div>
        );
        if (this.props.viewModel.IsOnBehalfOf) {
            resetButton = (
                <div className='pull-right'>
                    <span className='btn btn-link' onClick={this.resetUser}>
                        {this.props.translations.Phrases['Clear_Label']}
                    </span>
                </div>
            );
        }
        const resetUser = this.props.viewModel.UserChanged ? resetButton : '';

        let userNameAndEmail = '';
        if (this.props.viewModel.User && this.props.viewModel.User.SalesforceId) {
            if (this.props.viewModel.IsOnBehalfOf) {
                userNameAndEmail = (
                    <div className='selected-user-box'>
                        <div className='pull-left'>
                            <div className='text-wrap'>
                                <strong>{this.props.viewModel.User.FullName}</strong>
                            </div>
                            <div className='text-wrap'>{this.props.viewModel.User.Email}</div>
                        </div>
                        {resetUser}
                    </div>
                );
            } else {
                userNameAndEmail = (
                    <div className='selected-user-box'>
                        <div className='pull-left'>
                            <div className='text-wrap'>
                                <strong>{this.props.viewModel.User.FullName}</strong>
                                <MoreDetailsIcon items={userDetails} />
                            </div>
                            <div className='text-wrap'>{this.props.viewModel.User.Email}</div>
                        </div>
                        {resetUser}
                    </div>
                );
            }
        }

        return (
            <Searchbox
                label={this.props.translations.Phrases['SFContactLookup_Label']}
                placeholder={this.props.translations.Phrases['TypeHereToSearch_Label']}
                name='User.Email'
                value={this.state.searchBoxValue}
                onValueChanged={this.searchBoxValueChanged}
                url='/ClientAdmin/GetSalesforceContacts'
                formatResults={this.formatResultBuilder}
                minChars={4}
                valMsg={
                    this.props.validationMessages.User
                        ? this.props.translations.Phrases[this.props.validationMessages.User.SalesforceId] ||
                          this.props.translations.Phrases[this.props.validationMessages.User.Email] ||
                          this.props.translations.Phrases[this.props.validationMessages.User.FirstName] ||
                          this.props.translations.Phrases[this.props.validationMessages.User.LastName]
                        : ''
                }
                onSelectItem={this.onSelectSalesforceContact}
                disabled={this.props.disabled}
            >
                {userNameAndEmail}
            </Searchbox>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        model: state.onBehalfOf.model,
        valMsg: state.onBehalfOf.validationMessages
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectSalesforceContact: (contact) => dispatch(actionCreators.selectSalesforceContactCA(contact)),
        validateOnBehalfOf: (model) => dispatch(actionCreators.validateOnBehalfOf(model)),
        resetUser: () => dispatch(actionCreators.resetUser())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OboSalesforceContactControl);
