import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios-portal';
import { API_CLEARANCES_PATH } from '../../common/constants/constants-portal';
import { GetCustomsInfoIdPayload } from '../../common/models/EllipsisModels';
import { GET_CUSTOMS_INFO_ID } from './actionTypes';

export const GetCustomsInfoID = createAsyncThunk(GET_CUSTOMS_INFO_ID, async (payload: GetCustomsInfoIdPayload, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${API_CLEARANCES_PATH}/GetCustomsInfoIdByEntryNumber`, {
            ColumnFilters: {
                DocumentEntryNumber: payload.DocumentEntryNumber
            },
            DateRange: {
                DateRangeSelected: null
            },
            ClientCaptionNumbers: payload.ClientNumbers
        });

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});
