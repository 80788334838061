import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useCallback, useEffect, useState } from 'react';
import { MILESTONES_CA, MILESTONES_US } from '../../../../../common/constants/constants-portal';
import Checkbox from '../../../../../common/features/Checkbox';
import FormInputField from '../../../../../common/features/FormInputField';
import NotificationsCheckboxes from '../../../../../common/features/NotificationsCheckboxes';
import Spinner from '../../../../../common/features/Spinner';
import { getCountryFromClientNumber } from '../../../../../common/functions/utils';
import { inputHasValueValidator } from '../../../../../common/functions/validators';
import useMaskedInputValidation from '../../../../../common/hooks/useMaskedInputValidation';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { Notifications } from '../../../../../common/models/ResponseData';
import {
    GetSubscriptionsWithClientNumbers,
    MultipleUnsubscribe,
    UpdateSubscriptionsByUserAndClient
} from '../../../redux/actions/subscriptions';
import { useProfileDispatch, useProfileSelector } from '../../../redux/hooks';
import { setCustomerNumbers } from '../../../redux/reducers/subscriptionsByUserAndClientSlice';
import { resetSubscriptionUpdate, resetUnsubscribe } from '../../../redux/reducers/subscriptionsSlice';

interface MultiSelectedModalProps {
    email: string | null | undefined;
    selectedClientsNumber: number | null | undefined;
    setModal: (value: boolean) => void;
    notificationsCA: any;
    notificationsUS: any;
    setNotificationsUS: (value: any) => void;
    setNotificationsCA: (value: any) => void;
    setLoadingUpdatedGrid: (value: boolean) => void;
    setGridSubscriptions: (value: any) => void;
    selectedSubscriptions: any;
    gridSubscriptions: any;
}

export const MultiSelectModal: React.FC<MultiSelectedModalProps> = ({
    email,
    selectedClientsNumber,
    setModal,
    setNotificationsUS,
    setNotificationsCA,
    notificationsCA,
    notificationsUS,
    selectedSubscriptions,
    setLoadingUpdatedGrid,
    setGridSubscriptions,
    gridSubscriptions
}) => {
    const [isEmailChecked, setIsEmailChecked] = useState(false);
    const {
        value: mobileNumber,
        handleChange: mobileNumberChangeHandler,
        handleBlur: mobileNumberBlurHandler,
        hasError: mobileNumberHasError,
        isValid: mobileNumberIsValid
    } = useMaskedInputValidation({
        required: false,
        initial: '',
        validators: [inputHasValueValidator, (value: string) => isValidPhoneNumber(value, 'US')],
        inputMask: '(###) ###-####'
    });

    const dispatch = useProfileDispatch();
    const translate = useTranslation();

    const { unsubscribe } = useProfileSelector((state) => state.subscriptions);
    const { subscriptionsByUserAndClient } = useProfileSelector((state) => state.updateSubscriptionsByUserAndClient);
    const CustomerNumbersArray = selectedSubscriptions.map((customerNumber: any) => customerNumber.CustomerNumber);

    useEffect(() => {
        dispatch(setCustomerNumbers(CustomerNumbersArray));
    }, [dispatch]);

    useEffect(() => {
        setNotificationsCA(MILESTONES_CA);
        setNotificationsUS(MILESTONES_US);
    }, [setNotificationsCA, setNotificationsUS]);

    useEffect(() => {
        dispatch(resetSubscriptionUpdate());
        dispatch(resetUnsubscribe());
    }, [dispatch]);

    const getReqNotifications = (notifications: any[]): Notifications[] => {
        return Object.keys(notifications).map((key: any) => ({
            Name: key,
            Value: notifications[key]
        }));
    };
    const checkNotifications = [...getReqNotifications(notificationsCA), ...getReqNotifications(notificationsUS)].every(
        (item) => !item.Value
    );

    const hasCountry = useCallback(
        (c: 'ca' | 'us') => {
            if (!selectedSubscriptions) return false;
            return (
                CustomerNumbersArray.filter((number: string) => {
                    const country = getCountryFromClientNumber(number);
                    return country === c;
                }).length > 0
            );
        },
        [selectedSubscriptions, CustomerNumbersArray]
    );

    const handleSubmit = async () => {
        setLoadingUpdatedGrid(false);
        if (gridSubscriptions) {
            setGridSubscriptions(
                gridSubscriptions.map((item: any) => ({
                    ...item,

                    isNew: false
                }))
            );
        }
        let subscriptionIdCustomerNumbers: any[] = [];
        selectedSubscriptions.forEach((item: any) => {
            subscriptionIdCustomerNumbers.push({
                SubscriptionId: item.SubscriptionId,
                CustomerNumber: item.CustomerNumber,
                DocID: item.DocID
            });
        });

        await dispatch(
            UpdateSubscriptionsByUserAndClient({
                SubscriptionIdCustomerNumberDyads: subscriptionIdCustomerNumbers,
                Language: selectedSubscriptions[0].Language,
                UserId: selectedSubscriptions[0].UserId,
                FullName: null,
                Role: null,
                Email: isEmailChecked ? email : null,
                Phone: mobileNumber ? mobileNumber : null,
                MilestoneNotifications_CA: getReqNotifications(notificationsCA),
                MilestoneNotifications_US: getReqNotifications(notificationsUS)
            })
        );

        await setModal(false);
        if (unsubscribe.removedSubscribersAmount) {
            dispatch(MultipleUnsubscribe({ subscriptionIds: [] }));
        }
        await dispatch(
            GetSubscriptionsWithClientNumbers({ ProfileEmail: isEmailChecked ? email : undefined, ProfileMobileNumber: mobileNumber })
        );

        setLoadingUpdatedGrid(true);
    };

    const updateNotificationsUS = (key: string, value: boolean) => {
        setNotificationsUS({ ...notificationsUS, [key]: value });
    };
    const updateNotificationsCA = (key: string, value: boolean) => {
        setNotificationsCA({ ...notificationsCA, [key]: value });
    };

    const handleCheckbox = (key: string, value: boolean) => {
        setIsEmailChecked(value);
    };

    return (
        <>
            {subscriptionsByUserAndClient.isLoading && (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}
            <div className='modal-dialog  modal-dialog-centered modal-dialog-scrollable manage-notifications'>
                <div className='modal-content'>
                    {/* Heading */}
                    <div className='modal-header'>
                        <h3 className='modal-title' style={{ fontWeight: 'bold' }}>
                            {translate('ManageNotificationsSelectedClients_Label', selectedClientsNumber)}
                        </h3>
                        <button type='button' className='modal-close btn btn-tertiary-blue btn-lg' onClick={() => setModal(false)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    {/* Body */}
                    <>
                        <div className='modal-body d-flex flex-column align-items-start'>
                            <div id='scrollTable' style={{ width: '100%' }}>
                                <div className='text-secondary mb-4' style={{ fontWeight: 'bold' }}>
                                    {translate('SelectMilestonesNotifiedOn_Label')}
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                    {hasCountry('us') && (
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            <NotificationsCheckboxes
                                                handleChange={updateNotificationsUS}
                                                setNotificationSettings={setNotificationsUS}
                                                notificationSettings={notificationsUS}
                                                country={[{ id: 'US' }]}
                                                addSubscriberModal={false}
                                                onHoldTitle={translate('ClearancesOnHoldTitle_Label')}
                                                onHoldLabel={translate('OnHold_Label')}
                                            />
                                        </div>
                                    )}
                                    {hasCountry('ca') && (
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                            <NotificationsCheckboxes
                                                handleChange={updateNotificationsCA}
                                                setNotificationSettings={setNotificationsCA}
                                                notificationSettings={notificationsCA}
                                                country={[{ id: 'CA' }]}
                                                addSubscriberModal={false}
                                                onHoldTitle={translate('ClearancesOnHoldTitle_Label')}
                                                onHoldLabel={translate('OnHold_Label')}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className='mr-4'>
                                    <p style={{ fontWeight: 'bold' }}>{translate('HowReceiveNotifications_Label')}</p>
                                    <form className='checkboxes'>
                                        <div className='custom-control custom-checkbox mb-1'>
                                            <Checkbox
                                                id={'email'}
                                                name={'Email'}
                                                inputStyle={{ width: '1.25rem', height: '1.32813rem' }}
                                                divStyle={{ paddingBottom: '1vh' }}
                                                onChange={handleCheckbox}
                                                isChecked={isEmailChecked}
                                            >
                                                {email}
                                            </Checkbox>
                                        </div>
                                        <FormInputField
                                            value={mobileNumber}
                                            onChange={mobileNumberChangeHandler}
                                            onBlur={mobileNumberBlurHandler}
                                            isValid={mobileNumberIsValid}
                                            hasError={mobileNumberHasError}
                                            placeholder={translate('EnterMobileNumber_Label')}
                                            label={''}
                                            showLabel={false}
                                        />
                                        <div className='small'>{translate('StandardMessageAndDataRates_Label')}</div>
                                        {mobileNumberHasError && (
                                            <div className='error-message'>{translate('InvalidMobileNumber_Label')}</div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* Footer */}
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-ghost btn-lg' onClick={() => setModal(false)}>
                                {translate('Cancel_Label')}
                            </button>
                            <button
                                type='button'
                                className='btn btn-primary btn-lg'
                                onClick={handleSubmit}
                                disabled={(mobileNumberIsValid || isEmailChecked) && !checkNotifications ? false : true}
                            >
                                {translate('SaveSettings_Label')}
                            </button>
                        </div>
                    </>
                </div>
            </div>
        </>
    );
};
