import { ShipmentHeader } from '../../../common/models/ShipmentHeader';

const formatNumber = (val: number) => {
    return val.toLocaleString('en-US', { minimumFractionDigits: 2 });
};

export const reorderHeaderDetails = (d: ShipmentHeader): Partial<ShipmentHeader> => {
    const status = () => {
        let retValue;

        if (d?.ISFAccepted) retValue = 'ISF Accepted';
        if (d?.DocsReceived) retValue = 'Documents received';
        if (d?.CustomsCertified) retValue = 'Customs certified';
        if (d?.CustomsReleased) retValue = 'Customs released';
        if (d?.EntrySummaryAccepted) retValue = 'Entry summary accepted';

        return retValue;
    };

    return {
        EntryNumber: d?.EntryNumber,
        EntryType: d?.EntryType,
        Status: status(),
        CustomerRefNo: d?.CustomerRefNo,
        FileNo: d?.FileNo,
        DocsReceived: d?.DocsReceived,
        ISFAccepted: d?.ISFAccepted,
        CustomsCertified: d?.CustomsCertified,
        CustomsReleased: d?.CustomsReleased,
        EntrySummaryAccepted: d?.EntrySummaryAccepted,
        LiquidationDate: d?.LiquidationDate,
        TotalEnteredDutyValue: ('$' + formatNumber(d?.TotalEnteredDutyValue)) as any,
        TotalDuty: ('$' + formatNumber(d?.TotalDuty)) as any,
        TotalHMF: ('$' + formatNumber(d?.TotalHMF)) as any,
        TotalMPF: ('$' + formatNumber(d?.TotalMPF)) as any,
        TotalEntryDutiesAndFees: ('$' + formatNumber(d?.TotalEntryDutiesAndFees)) as any,
        PieceCount: d?.PieceCount,
        CountryOfOrigin: d?.CountryOfOrigin,
        CountryOfOriginCode: d?.CountryOfOriginCode,
        ImporterName: d?.ImporterName,
        ImporterNumber: d?.ImporterNumber,
        ModeTrans: d?.ModeTrans,
        CarrierSCAC: d?.CarrierSCAC,
        TransitBondNo: d?.TransitBondNo,
        HouseBill: d?.HouseBill,
        HouseSCAC: d?.HouseSCAC,
        MasterBill: d?.MasterBill,
        ContainerNumber: d?.ContainerNumber,
        VesselNumber: d?.VesselNumber,
        VoyageNo: d?.VoyageNo,
        ConsigneeName: d?.ConsigneeName,
        ConsigneeNumber: d?.ConsigneeNumber,
        DestinationState: d?.DestinationState,
        EntryCodePort: d?.EntryCodePort,
        EntryNamePort: d?.EntryNamePort,
        UnlandingCodePort: d?.UnlandingCodePort,
        UnlandingNamePort: d?.UnlandingNamePort,
        FilerCode: d?.FilerCode,
        ClientNumber: d?.ClientNumber,
        BillInvoiceNo: d?.BillInvoiceNo,
        BillInvoiceSuffix: d?.BillInvoiceSuffix
    };
};
