import { getter, process } from '@progress/kendo-data-query';
import { GridColumn as Column, Grid, GridCellProps, GridDataStateChangeEvent, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import GridLoadingOverlay from '../../../common/features/Grid/GridLoadingOverlay';
import RowWithButton from '../../../common/features/Grid/RowWithButton';
import useGridLanguage from '../../../common/hooks/useGridLanguage';
import useGridSelection from '../../../common/hooks/useGridSelection';
import useTranslation from '../../../common/hooks/useTranslation';
import { ContactsModals } from '../../../common/models/ContactsModals';
import { Contact, SelectedContact } from '../common/models/Contact';
import { RemoveContacts } from '../redux/actions/contactsManagement';
import { useContactsDispatch, useContactsSelector } from '../redux/hooks';
import {
    resetSelectedState,
    setCurrentDataItems,
    setDataState,
    setHeaderCheckbox,
    setPageSelected,
    setSelectedState,
    setTotalItems
} from '../redux/reducers/contactsGridSettingsSlice';
import DeleteContactModal from './components/DeleteContact/DeleteContactModal';
import DeleteContactsFooter from './components/DeleteContact/DeleteContactsFooter';
import EditContactModal from './components/EditContact/EditContactModal';
import FormattedGridCell from './components/FormatedGridCell';
import './custom-table.css';

interface ContactsTableProps {
    filteredContacts: Contact[];
    modal: ContactsModals;
    toggleModal: (prop: keyof ContactsModals, next?: boolean) => void;
}

const SELECTED_FIELD = 'selected';
const DATA_ITEM_KEY = 'RowId';
const idGetter = getter(DATA_ITEM_KEY);

const ContactsTable: React.FC<ContactsTableProps> = ({ filteredContacts, modal, toggleModal }) => {
    const { dataState, currentDataItems, selectedState, headerCheckbox, totalItems, pageable } = useContactsSelector(
        (state) => state.contactsGridSettings
    );
    const { languageSelected: selectedLanguage } = useContactsSelector((state) => state.language);
    const { addNewContacts, removeContacts, editedContact, isLoading, error, teamsAdmins } = useContactsSelector((state) => state.contacts);
    const { user } = useContactsSelector((state) => state.hydration);
    const [selectedContacts, setSelectedContacts] = useState<SelectedContact[]>([]);
    const [gridContacts, setGridContacts] = useState<any[]>([]);
    const [contactToEdit, setContactToEdit] = useState<Contact | undefined>(undefined);
    const gridLanguage = useGridLanguage(selectedLanguage);
    const translate = useTranslation();
    const dispatch = useContactsDispatch();
    const gridRef = useRef<any>();
    const { handleHeaderSelectionChange, handleSelectionChange } = useGridSelection({
        DATA_ITEM_KEY,
        SELECTED_FIELD,
        idGetter,
        selectedState,
        currentDataItems,
        onSelectionChange: (value) => dispatch(setSelectedState(value)),
        onHeaderSelectionChange: useCallback((value) => dispatch(setHeaderCheckbox(value)), [dispatch]),
        onReset: useCallback(() => dispatch(resetSelectedState()), [dispatch])
    });

    // Transform the "contacts" prop just to have the "selected" key for each contact/row
    useEffect(() => {
        if (filteredContacts) {
            setGridContacts(filteredContacts.map((item) => ({ ...item, [SELECTED_FIELD]: false })));
        }
    }, [filteredContacts]);

    // Update the total items that are responsible to show/hide pagination
    useEffect(() => {
        if (!gridRef.current) return;
        dispatch(setTotalItems(gridRef.current.props.data.total));
        dispatch(setCurrentDataItems(gridRef.current.props.data.data));
    }, [dataState, gridContacts, dispatch]);

    // Reset the selection on every grid reload
    useEffect(() => {
        if (!isLoading && !error) {
            dispatch(resetSelectedState());
        }
    }, [isLoading, error, dispatch]);

    // Array of selected ContactIds
    useEffect(() => {
        setSelectedContacts(
            Object.keys(selectedState)
                .filter((id) => selectedState[id])
                .map((id) => ({ ...gridContacts.find((item) => item[DATA_ITEM_KEY] === parseInt(id)) }))
        );
    }, [gridContacts, selectedState]);

    const handleRemoveContact = useCallback(
        (id?: null | number) => {
            if (id) setSelectedContacts(gridContacts.filter((item) => item[DATA_ITEM_KEY] === id));
            toggleModal('remove');
        },
        [gridContacts, toggleModal]
    );
    const handleEditContact = useCallback(
        (id?: null | number) => {
            if (id) {
                setSelectedContacts(gridContacts.filter((item) => item[DATA_ITEM_KEY] === id));
                setContactToEdit(gridContacts.filter((item) => item[DATA_ITEM_KEY] === id)[0]);
            }
            toggleModal('edit');
        },
        [gridContacts, toggleModal]
    );
    const GridCell = useCallback(
        (props: GridCellProps) => {
            return (
                <FormattedGridCell
                    {...props}
                    isLocked={props.dataItem[SELECTED_FIELD]}
                    isAdmin={(user?.Roles!.includes('TeamManagement_Create') || user?.Roles!.includes('ExternalContact_Create')) ?? false}
                    handleRemoveContact={handleRemoveContact}
                    handleEditContact={handleEditContact}
                />
            );
        },
        [handleEditContact, handleRemoveContact, user?.Roles]
    );

    const handleRemoveContacts = () => {
        dispatch(RemoveContacts({ contactIds: selectedContacts.map((item) => item.ExternalContactID!) }));
        // Set the grid page to 1
        dispatch(setPageSelected(1));
        toggleModal('remove', false);
    };

    const HeaderCell = useCallback(
        (props: GridHeaderCellProps) => {
            return (
                <div style={{ textAlign: 'center' }}>
                    <Checkbox value={headerCheckbox} onChange={props.selectionChange} />
                </div>
            );
        },
        [headerCheckbox]
    );

    const dataStateChange = (e: GridDataStateChangeEvent) => {
        dispatch(setDataState(e.dataState));
    };
    const handleTagEvent = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'Add_Contact_Contacts_CPEvt',
                location: 'Profile'
            }
        });
    };

    return (
        <>
            {/* Only admin can add new contact */}
            {(user?.Roles!.includes('TeamManagement_Create') || user?.Roles!.includes('ExternalContact_Create')) && (
                <RowWithButton
                    disabled={(!teamsAdmins.data.length && user?.InternalUser) || (teamsAdmins.isLoading && user?.InternalUser)}
                    isLoading={teamsAdmins.isLoading && user?.InternalUser}
                    extraRowClasses='ml-2'
                    element={'.k-widget.k-grid.contacts-grid-table'}
                    onClick={() => {
                        toggleModal('add', true);
                        handleTagEvent();
                    }}
                >
                    {translate('AddContact_Label')}
                </RowWithButton>
            )}

            <LocalizationProvider language={gridLanguage.language}>
                <IntlProvider locale={gridLanguage.locale}>
                    <div className='d-flex position-relative flex-fill'>
                        <Grid
                            data={process(
                                gridContacts &&
                                    gridContacts.map((item) => ({
                                        ...item,
                                        [SELECTED_FIELD]: selectedState[idGetter(item)]
                                    })),
                                dataState
                            )}
                            className='contacts-grid-table flex-fill'
                            total={filteredContacts ? filteredContacts.length : 0}
                            pageable={totalItems < 10 ? false : pageable}
                            selectedField={SELECTED_FIELD}
                            style={{ zIndex: 0 }}
                            sortable={{
                                allowUnsort: false
                            }}
                            ref={gridRef}
                            selectable={{
                                enabled: false,
                                drag: false,
                                cell: false,
                                mode: 'multiple'
                            }}
                            onHeaderSelectionChange={handleHeaderSelectionChange}
                            onSelectionChange={handleSelectionChange}
                            onDataStateChange={dataStateChange}
                            {...dataState}
                        >
                            <Column field={SELECTED_FIELD} cell={GridCell} headerCell={HeaderCell} width={50} />
                            <Column field='FullName' title={translate('Name_Label')} cell={GridCell} width={250} />
                            <Column field='Email' title={translate('Email_Label')} cell={GridCell} width={250} />
                            <Column field='MobileNumber' title={translate('MobileNumber_Label')} cell={GridCell} width={250} />
                            <Column field='RoleLabel' title={translate('Role_Label')} cell={GridCell} width={150} />
                            <Column field='CarrierCodes' title={translate('CarrierCode_Label')} cell={GridCell} width={250} />
                            <Column field='Language' title={translate('Language_Label')} cell={GridCell} width={150} />
                            <Column field='CreatedByUsername' title={translate('CreatedBy_Label')} cell={GridCell} width={250} />
                            <Column field='ModifiedByUsername' title={translate('ModifiedBy_Label')} cell={GridCell} width={250} />
                            {user?.InternalUser && (
                                <Column field='TeamAdminUserFullName' title={translate('AdminCompany_Label')} cell={GridCell} width={250} />
                            )}
                            <Column field='Actions' cell={GridCell} sortable={false} locked={true} width='80%' />
                        </Grid>
                        {(isLoading || addNewContacts.isLoading || removeContacts.isLoading || editedContact.isLoading) &&
                            filteredContacts &&
                            filteredContacts.length > 0 && <GridLoadingOverlay />}
                    </div>
                </IntlProvider>
            </LocalizationProvider>

            {modal.edit && <EditContactModal toggleModal={toggleModal} modal={modal} selectedContact={contactToEdit!} />}

            {modal.remove && (
                <DeleteContactModal contacts={selectedContacts} toggleModal={toggleModal} handleSubmit={handleRemoveContacts} />
            )}

            {/* Delete contacts footer */}
            {Object.entries(selectedState).filter(([key, value]) => value === true).length > 0 && !isLoading && (
                <DeleteContactsFooter
                    amountLabel={translate('NumberOfRemoveSelected_Label', selectedContacts.length)}
                    primaryButtonLabel={translate('RemoveSelected_Label')}
                    disablePrimaryButton={!user?.Roles?.includes('TeamManagement_Edit') && !user?.Roles?.includes('ExternalContact_Edit')}
                    onPrimary={() => toggleModal('remove')}
                />
            )}
        </>
    );
};
export default ContactsTable;
