import produce from 'immer';
import { formatTranslated, getSearchTypesBySelectedServices, pluck, sortBy } from '../../utils/mlp-utils';
import * as actionTypes from '../actions/imaging/imagingActionTypes';
import { filters as Constants } from './../../constants/mlp-constants';

const initialState = {
    model: {
        ChangedProperties: [],
        ClientServiceSelection: {
            ClientServices: [],
            Countries: [],
            HasUnrestrictedAccess: false,
            MaxSelectedServicesPerCountry: 0,
            ServicesLimitReached: false
        },
        Filters: [],
        ImageGroupings: [],
        MaxSelectedDocuments: 0,
        NumberOfResultsToLoad: 0,
        OnlyShowLatest: false,
        QuickSearchValue: null,
        ReturnFields: [],
        SearchTypes: [],
        TotalLoadedResults: 0,
        TotalEntries: 0,
        SearchResults: null,
        GridModel: []
    },
    shouldInitModel: false,
    shouldSubmitImageSearch: false,
    shouldUpdateGridModel: false,
    type: 'load'
};

const setImageModel = (state, action) => {
    return action.model;
};

const setImages = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.model;
        if (!draft.model.SearchResults) {
            draft.model.SearchResults = [];
        }
        draft.shouldInitModel = true;
    });
};

const initImageModel = (state, action) => {
    return produce(state, (draft) => {
        draft.model.Filters.forEach((f, i) => {
            f.FilterIndex = i;
            if (f.Options) {
                f.Options.forEach((y) => {
                    y.TranslatedName = action.translations.Phrases[y.Name] || y.Name;
                });
                if (f.Options[0] && f.Options[0].SortValue) {
                    sortBy(f.Options, 'SortValue');
                } else {
                    sortBy(f.Options, 'TranslatedName');
                }
            }
            initPrimaryDateFilter(f);
            initMainSearchFilter(f);
        });
        let availableSearchTypes = getSearchTypesBySelectedServices(draft.model);
        if (availableSearchTypes !== undefined && availableSearchTypes.length > 0) {
            availableSearchTypes[0].IsSelected = true;

            draft.model.ImageGroupings.forEach((x) => {
                x.IsSelected = false;
                x.IsHidden = x.SearchTypeCode !== availableSearchTypes[0].Code;
                x.CalculatedValue = x.SearchTypeCode + '-' + x.Name;
                x.TranslatedName = formatTranslated(x.Name, action.translations.Phrases);
            });
            let imageGrouping = draft.model.ImageGroupings.find((x) => x.SearchTypeCode === availableSearchTypes[0].Code);
            imageGrouping.IsSelected = true;
        }
        draft.shouldInitModel = false;
        draft.shouldSubmitImageSearch = true;
    });
};

const resetSubmitImageSearchFlag = (state) => {
    return produce(state, (draft) => {
        draft.shouldSubmitImageSearch = false;
    });
};

const afterSubmitImageSearch = (state, action) => {
    return produce(state, (draft) => {
        if (draft.type === 'filter') {
            draft.model.SearchResults = action.payload.results;
        } else {
            Array.prototype.push.apply(draft.model.SearchResults, action.payload.results);
        }
        draft.model.TotalLoadedResults = draft.model.SearchResults.length;
        draft.model.TotalResults = action.payload.totalResults;
        draft.model.TotalEntries = action.payload.totalEntries;
        // draft.shouldSubmitImageSearch = false;
        draft.shouldUpdateGridModel = true;
    });
};

const updateGridModel = (state, action) => {
    return produce(state, (draft) => {
        draft.model.GridModel = action.gridModel;
        draft.shouldUpdateGridModel = false;
    });
};

const clearImageFilters = (state) => {
    return produce(state, (draft) => {
        draft.model.TotalLoadedResults = 0;
        draft.model.TotalEntries = 0;
        draft.model.SearchResults = [];
        draft.model.Filters.forEach((f) => {
            f.Value = undefined;
            f.RangeStart = undefined;
            f.RangeEnd = undefined;
            f.Options.forEach((o) => {
                o.IsSelected = false;
            });
            initPrimaryDateFilter(f);
            initMainSearchFilter(f);
        });
        draft.shouldSubmitImageSearch = true;
    });
};

const initPrimaryDateFilter = (filter) => {
    if (filter.ControlName === 'PrimaryDateFilter' && filter.Options.length > 0) {
        filter.Options[0].IsSelected = true;
        filter.Attribute = filter.Options[0].Value;
        filter.AttributeDataType = filter.Options[0].DataType;
    }
};

const initMainSearchFilter = (filter) => {
    if (filter.ControlName === 'MainSearchFilter' && filter.Options.length > 0) {
        filter.Attribute = Constants.ALL_TYPES;
        filter.AttributeDataType = 'string';
    }
};

const updateImageClientServices = (state, action) => {
    return produce(state, (draft) => {
        const oldSelectedCountry = state.model.ClientServiceSelection.Countries.find((country) => country.IsSelected);
        draft.model.ClientServiceSelection = action.data;
        const newSelectedCountry = draft.model.ClientServiceSelection.Countries.find((country) => country.IsSelected);

        draft.model.TotalLoadedResults = 0;
        draft.model.TotalEntries = 0;
        draft.model.SearchResults = [];
        draft.shouldSubmitImageSearch = true;

        if (oldSelectedCountry.Code !== newSelectedCountry.Code) {
            draft.model.SearchTypes.forEach((type) => {
                type.IsSelected = false;
            });
            let availableSearchTypes = getSearchTypesBySelectedServices(draft.model);
            if (availableSearchTypes !== undefined && availableSearchTypes.length > 0) {
                availableSearchTypes[0].IsSelected = true;
                draft.model.ImageGroupings.forEach((x) => {
                    x.IsSelected = false;
                    x.IsHidden = x.SearchTypeCode !== availableSearchTypes[0].Code;
                });
                let grouping = draft.model.ImageGroupings.find((x) => x.SearchTypeCode === availableSearchTypes[0].Code);
                grouping.IsSelected = true;
            } else {
                draft.shouldSubmitImageSearch = false;
            }
        } else {
            let isSearchTypeSelected = draft.model.SearchTypes.find((x) => x.IsSelected === true);
            if (!isSearchTypeSelected) {
                let availableSearchTypes = getSearchTypesBySelectedServices(draft.model);
                if (availableSearchTypes !== undefined && availableSearchTypes.length > 0) {
                    availableSearchTypes[0].IsSelected = true;
                    draft.model.ImageGroupings.forEach((x) => {
                        x.IsSelected = false;
                        x.IsHidden = x.SearchTypeCode !== availableSearchTypes[0].Code;
                    });
                    let grouping = draft.model.ImageGroupings.find((x) => x.SearchTypeCode === availableSearchTypes[0].Code);
                    grouping.IsSelected = true;
                } else {
                    draft.shouldSubmitImageSearch = false;
                }
            }
        }
    });
};

const clearImageClientServices = (state) => {
    return produce(state, (draft) => {
        draft.model.ClientServiceSelection = {
            ClientServices: [],
            Countries: [],
            HasUnrestrictedAccess: false,
            MaxSelectedServicesPerCountry: 0,
            ServicesLimitReached: false
        };
        draft.model.SearchResults = null;
    });
};

const selectImageSearchType = (state, action) => {
    return produce(state, (draft) => {
        draft.model.SearchTypes.forEach((x) => {
            x.IsSelected = x.Code === action.code;
        });
        draft.model.ImageGroupings.forEach((x) => {
            x.IsSelected = false;
            x.IsHidden = x.SearchTypeCode !== action.code;
        });
        let grouping = draft.model.ImageGroupings.find((x) => x.SearchTypeCode === action.code);
        grouping.IsSelected = true;
    });
};

const selectImageGroupByOption = (state, action) => {
    return produce(state, (draft) => {
        if (action.payload.oldIndex !== -1) {
            draft.model.ImageGroupings[action.payload.oldIndex].IsSelected = false;
        }
        draft.model.ImageGroupings[action.payload.newIndex].IsSelected = true;
        draft.shouldSubmitImageSearch = true;
        draft.type = 'load';
    });
};

const selectImageDocs = (state, action) => {
    return produce(state, (draft) => {
        let counter = pluck(draft.model.GridModel, 'documents')
            .flat()
            .filter((x) => x.isSelected === true).length;
        draft.model.GridModel.forEach((x, i) => {
            if (action.payload.groupIndex >= 0 && i !== action.payload.groupIndex) {
                return;
            }

            x.documents.forEach((y, j) => {
                if (action.payload.docIndex >= 0 && j !== action.payload.docIndex) {
                    return;
                }

                if (counter < draft.model.MaxSelectedDocuments && action.payload.isSelected) {
                    if (!y.isSelected) counter++;
                    y.isSelected = true;
                } else if (!action.payload.isSelected) {
                    y.isSelected = false;
                }
            });

            x.isSelected = action.payload.isSelected;
            if (x.documents.some((x) => x.isSelected)) {
                x.isSelected = true;
            }
        });
    });
};

const imageFilterOptionSelect = (state, action) => {
    return produce(state, (draft) => {
        if (action.payload.oldIndex !== -1) {
            draft.model.Filters[action.payload.filterIndex].Options[action.payload.oldIndex].IsSelected = false;
        }
        if (action.payload.newIndex !== -1) {
            draft.model.Filters[action.payload.filterIndex].Options[action.payload.newIndex].IsSelected = true;
        } else {
            draft.model.Filters[action.payload.filterIndex].Options.forEach((x) => {
                x.IsSelected = false;
            });
            initMainSearchFilter(draft.model.Filters[action.payload.filterIndex]);
        }
    });
};

const imageFilterValueChange = (state, action) => {
    return produce(state, (draft) => {
        draft.model.Filters[action.payload.filterIndex][action.payload.property] = action.payload.value;
    });
};

const imageDocTypeFilterValueChange = (state, action) => {
    return produce(state, (draft) => {
        draft.model.Filters[action.payload.filterIndex][action.payload.property1][action.payload.optionIndex][action.payload.property2] =
            action.payload.value;
    });
};

const imageDocTypeFilterSelectAll = (state, action) => {
    return produce(state, (draft) => {
        draft.model.Filters[action.payload.filterIndex].Options.forEach((option) => {
            option.IsSelected = action.payload.value;
        });
    });
};

const imageMotFilterValueChange = (state, action) => {
    return produce(state, (draft) => {
        draft.model.Filters[action.payload.filterIndex].Options[action.payload.optionIndex].IsSelected = action.payload.value;
    });
};

const imageMotFilterSelectAll = (state, action) => {
    return produce(state, (draft) => {
        draft.model.Filters[action.payload.filterIndex].Options.forEach((option) => {
            option.IsSelected = action.payload.value;
        });
    });
};

const filterImages = (state) => {
    return produce(state, (draft) => {
        draft.model.TotalLoadedResults = 0;
        draft.model.SearchResults = [];
        draft.type = 'filter';
        draft.shouldSubmitImageSearch = true;
    });
};

const loadImages = (state) => {
    return produce(state, (draft) => {
        draft.type = 'load';
        draft.shouldSubmitImageSearch = true;
    });
};

const setDropdownYears = (state, action) => {
    return produce(state, (draft) => {
        const yearFilterOptions = draft.model.Filters.find((element) => element.ControlName === 'YearFilter').Options;
        if (!yearFilterOptions || yearFilterOptions.length === 0) {
            draft.model.Filters.find((element) => element.ControlName === 'YearFilter').Options = action.payload.years;
        }
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_IMAGES:
            return setImages(state, action);
        case actionTypes.SET_IMAGE_MODEL:
            return setImageModel(state, action);
        case actionTypes.RESET_SUBMIT_IMAGE_SEARCH_FLAG:
            return resetSubmitImageSearchFlag(state);
        case actionTypes.SUBMIT_IMAGE_SEARCH:
            return afterSubmitImageSearch(state, action);
        case actionTypes.INIT_IMAGE_MODEL:
            return initImageModel(state, action);
        case actionTypes.UPDATE_GRID_MODEL:
            return updateGridModel(state, action);
        case actionTypes.CLEAR_IMAGE_FILTERS:
            return clearImageFilters(state);
        case actionTypes.UPDATE_IMAGE_CLIENT_SERVICES:
            return updateImageClientServices(state, action);
        case actionTypes.CLEAR_IMAGE_CLIENT_SERVICES:
            return clearImageClientServices(state);
        case actionTypes.SELECT_IMAGE_SEARCH_TYPE:
            return selectImageSearchType(state, action);
        case actionTypes.SELECT_IMAGE_GROUP_BY_OPTION:
            return selectImageGroupByOption(state, action);
        case actionTypes.IMAGE_MOT_FILTER_VALUE_CHANGE:
            return imageMotFilterValueChange(state, action);
        case actionTypes.MOT_FILTER_SELECT_ALL:
            return imageMotFilterSelectAll(state, action);
        case actionTypes.SELECT_IMAGE_DOCS:
            return selectImageDocs(state, action);
        case actionTypes.IMAGE_FILTER_OPTION_SELECT:
            return imageFilterOptionSelect(state, action);
        case actionTypes.IMAGE_FILTER_VALUE_CHANGE:
            return imageFilterValueChange(state, action);
        case actionTypes.IMAGE_DOC_TYPE_FILTER_VALUE_CHANGE:
            return imageDocTypeFilterValueChange(state, action);
        case actionTypes.IMAGE_DOC_TYPE_FILTER_SELECT_ALL:
            return imageDocTypeFilterSelectAll(state, action);
        case actionTypes.FILTER_IMAGES:
            return filterImages(state);
        case actionTypes.LOAD_IMAGES:
            return loadImages(state);
        case actionTypes.SET_DROPDOWN_YEARS:
            return setDropdownYears(state, action);
        case actionTypes.RESET_IMAGE_STATE:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
