import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { TabNavigationIds } from '../../../../../common/constants/constants-portal';
import { useDebounce } from '../../../../../common/hooks/useDebounce';
import { parseDateTimeET } from '../../functions/parser';
import classes from './ClearanceAlertCard.module.css';

const TYPES = {
    info: 'alert-info',
    success: 'alert-success',
    warning: 'alert-warning',
    danger: 'alert-danger',
    neutral: 'alert-dark'
} as const;
const TIMEOUT = 250;

export type ClearanceAlertType = keyof typeof TYPES;
type ClearanceAlertCardProps = (
    | {
          isExpandable: true;
          expandableButtonLabel: string;
          children: React.ReactNode;
          linkHref?: undefined;
          linkLabel?: undefined;
          cardId?: undefined;
      }
    | {
          isExpandable?: false;
          expandableButtonLabel?: undefined;
          children?: undefined;
          linkHref: string;
          linkLabel: string;
          cardId: string;
      }
) & { title: React.ReactNode; icon: IconDefinition; date: string | null; type?: ClearanceAlertType };

export default function ClearanceAlertCard({
    isExpandable,
    expandableButtonLabel,
    icon,
    title,
    date,
    children,
    type = 'info',
    cardId: cardIdProp,
    linkHref,
    linkLabel
}: ClearanceAlertCardProps) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded((prevState) => !prevState);
    };

    const debounce = useDebounce(TIMEOUT);

    const handleClick = (id: string, e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        const detailsTab = document.getElementById(`${TabNavigationIds.Clearances[0]}-tab`);
        const cardId = document.getElementById(id);

        if (detailsTab && cardId) {
            detailsTab.click();

            debounce(() => {
                cardId.scrollIntoView();
            });
        }
    };

    return (
        <div className={`alert ${TYPES[type]} my-2 ${classes.container}`}>
            <div className='row'>
                <div className='col-sm-7 col-8 d-flex'>
                    <FontAwesomeIcon icon={icon} className='mr-3 mt-1' />
                    <div>{title}</div>
                </div>
                <p className='col-sm-3 col-4 d-flex align-items-md-center mb-0'>{parseDateTimeET(date)}</p>
                <div className='d-sm-none d-block w-100' />
                <div className='col col-sm-2 d-flex justify-content-sm-end justify-content-center align-items-md-center align-items-top'>
                    {isExpandable ? (
                        <button
                            type='button'
                            className={`d-flex align-items-center text-link font-weight-normal p-0 m-0 ${classes['btn-expand']}`}
                            onClick={toggleExpand}
                        >
                            <span className='mr-2'>{expandableButtonLabel}</span>
                            <FontAwesomeIcon icon={faCaretDown} className={isExpanded ? classes.expanded : undefined} />
                        </button>
                    ) : (
                        <Link
                            className='text-link font-weight-normal m-0 text-right'
                            to={`#${linkHref}`}
                            onClick={handleClick.bind(null, cardIdProp)}
                        >
                            {linkLabel}
                        </Link>
                    )}
                </div>
            </div>
            {isExpandable && isExpanded && <div className='text-lii-text mt-4 mb-0'>{children}</div>}
        </div>
    );
}
