import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { LanguageCodes } from '../../constants/mlp-constants';
import * as actionCreators from '../../store/actions/index';

class SuccessfullSubmit extends Component {
    componentDidMount() {
        const langCode = this.props.location.state.lang ? this.props.location.state.lang : LanguageCodes.English;
        this.props.onInitTranslationsAndSetFlag(this.props.location.state.phraseGroup, langCode);
    }
    componentDidUpdate = () => {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
    };

    componentWillUnmount() {
        this.props.setTranslationsUnavailableFlag(false);
    }

    render() {
        if (
            this.props.translations &&
            (this.props.translations.PhraseGroupCode === 'AccessManagement.AccessManagement.EditSuccessful' ||
                this.props.translations.PhraseGroupCode === 'AccessManagement.AccessManagement.TransferSuccessful' ||
                this.props.translations.PhraseGroupCode === 'AccessManagement.AccessManagement.EditSuccessfulClientAdmin' ||
                this.props.translations.PhraseGroupCode === 'ClientAdmin.Signup.SuccessfulFeatures' ||
                this.props.translations.PhraseGroupCode === 'ClientAdmin.Signup.SuccessfulSignup' ||
                this.props.translations.PhraseGroupCode === 'ClientAdmin.Signup.SuccessfulRequestGrant' ||
                this.props.translations.PhraseGroupCode === 'ClientAdmin.Signup.SuccessfulRequestRevoke' ||
                this.props.translations.PhraseGroupCode === 'ClientAdmin.Signup.TeamMemberSuccessfulFeatures' ||
                this.props.translations.PhraseGroupCode === 'SystemAdmin.ManageUser.SuccessfulSubmit' ||
                this.props.translations.PhraseGroupCode === 'TeamManagement.TeamManagement.SuccessfulSubmit' ||
                this.props.translations.PhraseGroupCode === 'SystemAdmin.ManageUser.Index')
        ) {
            return (
                <div>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.props.translations.Phrases['Page_Title']}</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className='page-wrapper'>
                        <div className='page-top-row'>
                            <div className='page-header-section'>
                                <nav className='mlp-breadcrumb'>
                                    <Link to='/Dashboard' className='breadcrumb-parent'>
                                        {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                    </Link>
                                    <i className='fa fa-caret-right'></i>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_2']}
                                </nav>
                                <h1 className='bold-title'>{this.props.translations.Phrases['Page_Header']}</h1>
                                <h3 className='subheader'>{this.props.translations.Phrases['Page_Sub_Header']}</h3>
                                <h4 className='subheader'>{this.props.translations.Phrases['Process_Message']} </h4>
                            </div>
                            <div className='back-link'>
                                <Link to={this.props.location.state.backToPath}>
                                    {this.props.translations.Phrases[this.props.location.state.backToLabel]}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            if (this.props.isTranslationsUnavailable)
                return (
                    <div className='page-wrapper'>
                        <div className='page-top-row'>
                            <div className='page-header-section'>
                                <h2 className='subheader'>
                                    Your request was successfully processed, please continue with My Livingston from the menu.
                                </h2>
                            </div>
                        </div>
                    </div>
                );
            else return <div className='page-wrapper'></div>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        isTranslationsUnavailable: state.translations.isTranslationsUnavailable
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslationsAndSetFlag: (phraseGroup, langCode) =>
            dispatch(actionCreators.initTranslationsWithSetFlag(phraseGroup, langCode)),
        setTranslationsUnavailableFlag: (flag) => dispatch(actionCreators.setTranslationsUnavailableFlag(flag))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SuccessfullSubmit));
