import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contact, TeamsAdminByClientServices } from '../../common/models/Contact';
import { ContactsSlice } from '../../common/models/ReduxSlices';
import { AddNewContacts, GetContactsData, GetListOfAdmins, RemoveContacts, UpdateContact } from '../actions/contactsManagement';

const emptyContact: Contact = {
    FullName: '',
    Email: '',
    MobileNumber: '',
    RoleLabel: '',
    Language: '',
    RowId: 0,
    CarrierCodes: ''
};
const initialEditState: Contact = {
    FullName: '',
    Email: '',
    MobileNumber: '',
    RoleLabel: '',
    Language: '',
    CarrierCodes: '',
    RowId: 1,
    ExternalContactID: 0,
    IsFullNameValid: true,
    IsEmailValid: true,
    IsMobileNumberValid: true,
    IsRoleLabelValid: true,
    IsLanguageValid: true,
    IsEditedRoleEmpty: true
};

const initialState: ContactsSlice = {
    contacts: [],
    newContacts: [emptyContact],
    editContact: initialEditState,
    addNewContacts: {
        data: null,
        newContactsAmount: null,
        isLoading: false,
        error: undefined
    },
    removeContacts: {
        data: null,
        removedContactsAmount: null,
        isLoading: false,
        error: undefined
    },
    editedContact: {
        data: null,
        editContactsAmount: null,
        isLoading: false,
        error: undefined
    },
    teamsAdmins: {
        data: [],
        isLoading: false,
        error: undefined
    },
    formIsSubmitted: false,
    isLoading: true,
    error: undefined
};

const contactsSlice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        setTeamsAdmins(state, action: PayloadAction<TeamsAdminByClientServices[]>) {
            state.teamsAdmins.data = [...action.payload];
        },
        setNewContactAdmin(state, action: PayloadAction<{ id: number; value: TeamsAdminByClientServices }>) {
            const val = action.payload.value;
            state.newContacts = state.newContacts.map((contact) =>
                contact.RowId === action.payload.id
                    ? {
                          ...contact,
                          TeamId: val.TeamId,
                          TeamAdminUserCompanyName: val.TeamAdminUserCompanyName,
                          TeamAdminUserEmail: val.TeamAdminUserEmail,
                          TeamAdminUserFullName: val.TeamAdminUserFullName,
                          TeamAdminUserId: val.TeamAdminUserId
                      }
                    : contact
            );
        },
        setNewContactFullName(state, action: PayloadAction<{ id: number; value: string; isValid: boolean }>) {
            state.newContacts = state.newContacts.map((contact) =>
                contact.RowId === action.payload.id
                    ? { ...contact, FullName: action.payload.value, IsFullNameValid: action.payload.isValid }
                    : contact
            );
        },
        setNewContactEmail(state, action: PayloadAction<{ id: number; value: string; isValid: boolean }>) {
            state.newContacts = state.newContacts.map((contact) =>
                contact.RowId === action.payload.id
                    ? { ...contact, Email: action.payload.value, IsEmailValid: action.payload.isValid }
                    : contact
            );
        },
        setNewContactMobileNumber(state, action: PayloadAction<{ id: number; value: string; isValid: boolean }>) {
            state.newContacts = state.newContacts.map((contact) =>
                contact.RowId === action.payload.id
                    ? { ...contact, MobileNumber: action.payload.value, IsMobileNumberValid: action.payload.isValid }
                    : contact
            );
        },
        setNewContactRole(state, action: PayloadAction<{ id: number; value: string }>) {
            state.newContacts = state.newContacts.map((contact) =>
                contact.RowId === action.payload.id
                    ? action.payload.value.length
                        ? { ...contact, RoleLabel: action.payload.value + '_Label', IsRoleLabelValid: true }
                        : { ...contact, RoleLabel: '', IsRoleLabelValid: false }
                    : contact
            );
        },
        setNewContactLanguage(state, action: PayloadAction<{ id: number; value: string }>) {
            state.newContacts = state.newContacts.map((contact) =>
                contact.RowId === action.payload.id
                    ? action.payload.value !== '-'
                        ? { ...contact, Language: action.payload.value, IsLanguageValid: true }
                        : { ...contact, Language: '', IsLanguageValid: false }
                    : contact
            );
        },
        setNewContactCarrierNumber(state, action: PayloadAction<{ id: number; value: string; isValid: boolean }>) {
            state.newContacts = state.newContacts.map((contact) =>
                contact.RowId === action.payload.id
                    ? { ...contact, CarrierCodes: action.payload.value, IsCarrierCodesValid: action.payload.isValid }
                    : contact
            );
        },
        addNewContact(state) {
            state.newContacts.push({ ...emptyContact, RowId: Math.random() * 1000 + 1 });
        },
        removeNewContact(state, action: PayloadAction<number>) {
            if (state.newContacts.length === 1) {
                state.newContacts = initialState.newContacts;
                return;
            }
            state.newContacts = state.newContacts.filter((contact) => contact.RowId !== action.payload);
        },
        resetNewContacts(state) {
            state.newContacts = initialState.newContacts;
        },
        setFormIsSubmitted(state, action: PayloadAction<{ value: boolean }>) {
            state.formIsSubmitted = action.payload.value;
        },
        resetAddNewContacts(state) {
            state.addNewContacts = initialState.addNewContacts;
        },

        clearNewContacts: (state) => {
            if (state.contacts.length) {
                state.contacts = [
                    ...state.contacts.map((el) => ({
                        ...el,
                        isNew: false
                    }))
                ];
            }
        },
        resetRemoveContacts(state) {
            state.removeContacts = initialState.removeContacts;
        },
        resetEditContacts(state) {
            state.editedContact = initialState.editedContact;
        },
        setInitialEditContact(state, action: PayloadAction<Contact>) {
            state.editContact = { ...state.editContact, ...action.payload };
        },
        setEditContactsValues(state, action: PayloadAction<{ value: string; name: string; isValid: boolean | null }>) {
            if (action.payload.name === 'RoleLabel') {
                state.editContact = {
                    ...state.editContact,
                    [action.payload.name]: action.payload.value + '_Label',
                    IsRoleLabelValid: action.payload.value.length ? true : false,
                    CarrierCodes: action.payload.value.includes('Carrier') ? state.editContact.CarrierCodes : ''
                };
            } else if (action.payload.name === 'Language') {
                state.editContact = {
                    ...state.editContact,
                    [action.payload.name]: action.payload.value,
                    IsLanguageValid: action.payload.value !== '-' ? true : false
                };
            } else {
                state.editContact = {
                    ...state.editContact,
                    [action.payload.name]: action.payload.value,
                    ['Is' + action.payload.name + 'Valid']: action.payload.isValid
                };
            }
        },
        setEditedRoleChecker(state, action: PayloadAction<boolean>) {
            state.editContact.IsEditedRoleEmpty = action.payload.valueOf();
        },
        setExternalContactID(state, action: PayloadAction<number | undefined | null>) {
            state.editContact = { ...state.editContact, ExternalContactID: action.payload };
        }
    },

    extraReducers: (builder) => {
        builder
            // GET CONTACTS
            .addCase(GetContactsData.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetContactsData.fulfilled, (state, action) => {
                state.isLoading = false;
                if (state.contacts.length) {
                    const newCont = [
                        ...action.payload.map((el: Contact) => ({
                            ...el,
                            isNew: state.contacts.find((oldEl) => oldEl.Email === el.Email) ? false : true
                        }))
                    ];

                    state.contacts = newCont;
                } else {
                    state.contacts = action.payload;
                }
            })
            .addCase(GetContactsData.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            // ADD NEW CONTACT(S)
            .addCase(AddNewContacts.pending, (state, action) => {
                state.removeContacts = initialState.removeContacts;
                state.editedContact = initialState.editedContact;
                state.addNewContacts.isLoading = true;
                state.addNewContacts.error = undefined;
                state.addNewContacts.newContactsAmount = action.meta.arg.newContacts.length;
            })
            .addCase(AddNewContacts.fulfilled, (state, action) => {
                state.addNewContacts.isLoading = false;
                state.addNewContacts.data = action.payload!;
            })
            .addCase(AddNewContacts.rejected, (state, action) => {
                state.addNewContacts.isLoading = false;
                state.addNewContacts.newContactsAmount = initialState.addNewContacts.newContactsAmount;
                if (typeof action.payload === 'number') {
                    state.addNewContacts.error = action.payload;
                } else {
                    state.addNewContacts.error = action.error.message;
                }
            })
            // DELETE CONTACT(S)
            .addCase(RemoveContacts.pending, (state, action) => {
                state.addNewContacts = initialState.addNewContacts;
                state.editedContact = initialState.editedContact;
                state.removeContacts.isLoading = true;
                state.removeContacts.error = undefined;
                state.removeContacts.removedContactsAmount = action.meta.arg.contactIds.length;
            })
            .addCase(RemoveContacts.fulfilled, (state, action) => {
                state.removeContacts.isLoading = false;
                state.removeContacts.data = action.payload!;
            })
            .addCase(RemoveContacts.rejected, (state, action) => {
                state.removeContacts.isLoading = false;
                state.removeContacts.removedContactsAmount = initialState.removeContacts.removedContactsAmount;
                if (typeof action.payload === 'number') {
                    state.removeContacts.error = action.payload;
                } else {
                    state.removeContacts.error = action.error.message;
                }
            })
            // EDIT CONTACT
            .addCase(UpdateContact.pending, (state, action) => {
                state.addNewContacts = initialState.addNewContacts;
                state.removeContacts = initialState.removeContacts;
                state.editedContact.isLoading = true;
                state.editedContact.error = undefined;
                // state.editedContact.editContactsAmount = action.meta.arg.contactToBeUpdated.length;
            })
            .addCase(UpdateContact.fulfilled, (state, action) => {
                state.editedContact.isLoading = false;
                state.editedContact.data = action.payload!;
            })
            .addCase(UpdateContact.rejected, (state, action) => {
                state.editedContact.isLoading = false;
                state.editedContact.editContactsAmount = initialState.editedContact.editContactsAmount;
                if (typeof action.payload === 'number') {
                    state.removeContacts.error = action.payload;
                } else {
                    state.removeContacts.error = action.error.message;
                }
            })
            // GET TEAMS ADMINS
            .addCase(GetListOfAdmins.pending, (state, action) => {
                state.teamsAdmins.isLoading = true;
                state.teamsAdmins.error = undefined;
            })
            .addCase(GetListOfAdmins.fulfilled, (state, action) => {
                state.teamsAdmins.isLoading = false;
                state.teamsAdmins.data = action.payload!;
            })
            .addCase(GetListOfAdmins.rejected, (state, action) => {
                state.teamsAdmins.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.teamsAdmins.error = action.payload;
                } else {
                    state.teamsAdmins.error = action.error.message;
                }
            });
    }
});

export const {
    setTeamsAdmins,
    setNewContactAdmin,
    setNewContactFullName,
    setNewContactEmail,
    setNewContactMobileNumber,
    setNewContactRole,
    setNewContactLanguage,
    addNewContact,
    removeNewContact,
    resetNewContacts,
    setFormIsSubmitted,
    resetAddNewContacts,
    clearNewContacts,
    resetRemoveContacts,
    setEditContactsValues,
    setExternalContactID,
    setInitialEditContact,
    resetEditContacts,
    setEditedRoleChecker,
    setNewContactCarrierNumber
} = contactsSlice.actions;

export default contactsSlice.reducer;
