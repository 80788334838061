import { useEffect, useRef, useState } from 'react';
import DropdownMenuButton from '../../../../../common/features/DropdownMenuButton';
import useDropdownToggle from '../../../../../common/hooks/useDropdownToggle';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { DateRangeDocumentsFilter, DateTypeCompleteEntryDataPage } from '../../../../documents/common/constants/constants-documents';
import { useCompleteEntryDataDispatch } from '../../../redux/hooks';
import { updateShouldSubmitSearch } from '../../../redux/reducers/completeEntryDataSlice';
import { setAppliedType, updateIsDateOpen } from '../../../redux/reducers/dateFilterSlice';
import DateRangeFilter from './DateRangeFilter';

interface Props {
    setDate: (dateValue: string) => void;
    setType: (dateType: string) => void;
    propDateLabel: (dateType: string) => void;
    setEndDateCa: (dateType: string) => void;
    setStartDateCa: (dateType: string) => void;
    setappliedDate: (dateValue: string) => void;
    setappliedType: (dateValue: string) => void;
    applyButtonFunction: (attribute: string, selectedOption: string, selectedValue: string, endDate: string, startDate: string) => void;
    date: string;
    type: string;
    dateDropdownLabel: string;
    startDateCa: string;
    endDateCa: string;
    appliedDate: string;
    appliedType: string;
}

const DateDocumentsFilter: React.FC<Props> = ({
    setDate,
    setType,
    applyButtonFunction,
    propDateLabel,
    date,
    type,
    dateDropdownLabel,
    startDateCa,
    endDateCa,
    setEndDateCa,
    setStartDateCa,
    appliedDate,
    appliedType,
    setappliedDate,
    setappliedType
}) => {
    const translate = useTranslation();
    const dropdownMenuRef = useRef(null);
    const dropdownNestedMenuRef = useRef(null);
    const dropdownDateRangeRef = useRef<HTMLDivElement>(null);
    const dispatch = useCompleteEntryDataDispatch();

    const { isOpen, handleToggle } = useDropdownToggle(dropdownMenuRef);
    const { isOpen: nestedIsOpen, handleToggle: nestedHandleToggle } = useDropdownToggle(dropdownNestedMenuRef);
    const { isOpen: dateRangeIsOpen, handleToggle: dateRangeHandleToggle } = useDropdownToggle(dropdownDateRangeRef);
    const [title, setTitle] = useState(translate('Date_Label'));

    const [dateFilterParam, setDateFilterParam] = useState('');
    //This useEffect sets title to type dropdown
    useEffect(() => {
        const appliedDateValue = DateRangeDocumentsFilter.find((x) => x.id === date)?.label ?? '';

        if (date.includes('-')) {
            // date = "-01-01-2023" => title = "- 01/01/2023"
            if (date[0] === '-') {
                setTitle('- ' + date.substring(1).replaceAll('-', '/'));
            }
            // date = "01-01-2023-" => title = "01/01/2023 -"
            else if (date[date.length - 1] === '-') {
                setTitle(date.substring(0, date.length - 1).replaceAll('-', '/') + ' -');
            }
            // date = "01-01-2023-02-02-2023" => title = "01/01/2023 - 02/02/2023"
            else {
                let dateComponents = date.split('-');
                let formattedDate1 = `${dateComponents[0]}/${dateComponents[1]}/${dateComponents[2]}`;
                let formattedDate2 = `${dateComponents[3]}/${dateComponents[4]}/${dateComponents[5]}`;

                setTitle(`${formattedDate1} - ${formattedDate2}`);
            }
        } else {
            setTitle(appliedDate === '' ? translate('Date_Label') : translate(appliedDateValue));
        }
    }, [appliedDate]);

    //This use effect setsDate to previously assigned value so that value stays disabled upon cancel(also on outside click resets values)
    useEffect(() => {
        if (appliedDate !== '') setDate(appliedDate);
        else setDate('');
        if (appliedType !== '') setType(appliedType);
        else setType('');
    }, [isOpen]);

    return (
        <>
            <DropdownMenuButton
                label={title}
                isOpen={isOpen}
                ref={isOpen && !nestedIsOpen && !dateRangeIsOpen ? dropdownMenuRef : null}
                onClick={handleToggle}
                btnClassName='btn btn-tertiary  btn-sm dropdown-toggle ml-1'
            >
                <div className='card shadow-none'>
                    <div className='card-body px-4 py-3 border-bottom font-weight-bold'>{translate('FilterByDate_Label')}</div>
                </div>
                <div className='card shadow-none'>
                    <div className='card-body px-4 py-3'>
                        <DropdownMenuButton
                            label={
                                type !== ''
                                    ? translate(type)
                                    : appliedType !== ''
                                    ? translate(appliedType)
                                    : translate('SelectDateByType_Label')
                            }
                            isOpen={nestedIsOpen}
                            disabled={false}
                            onClick={() => {
                                nestedHandleToggle();
                            }}
                            ref={dropdownNestedMenuRef}
                            btnClassName={'btn-sm dropdown-toggle mb-2 btn-block'}
                            style={{ minWidth: '155px' }}
                            childrenDivClassName=''
                        >
                            {DateTypeCompleteEntryDataPage.map((item, index) => (
                                <div
                                    className={`dropdown-item`}
                                    key={item.label}
                                    onClick={() => {
                                        nestedHandleToggle();
                                        setType(item.label);
                                        applyButtonFunction(
                                            'Date',
                                            type.replaceAll('_Label', '').replaceAll(' ', ''),
                                            dateFilterParam,
                                            endDateCa,
                                            startDateCa
                                        );
                                    }}
                                >
                                    {translate(item.label)}
                                </div>
                            ))}
                        </DropdownMenuButton>
                        <DateRangeFilter
                            title={title}
                            appliedDate={appliedDate}
                            appliedType={appliedType}
                            setappliedType={setAppliedType}
                            setappliedDate={setappliedDate}
                            dateFilterParam={dateFilterParam}
                            setDateFilterParam={setDateFilterParam}
                            btnClass='btn-block'
                            onClick={dateRangeHandleToggle}
                            ref={dropdownDateRangeRef}
                            isOpen={dateRangeIsOpen}
                            handleToggle={dateRangeHandleToggle}
                            setDate={setDate}
                            PropDateValue={setDate}
                            date={date}
                            type={type}
                            applyButtonFunction={applyButtonFunction}
                            startDateCa={startDateCa}
                            endDateCa={endDateCa}
                            setEndDateCa={setEndDateCa}
                            setStartDateCa={setStartDateCa}
                            dateDropdownLabel={dateDropdownLabel}
                            propDateLabel={propDateLabel}
                        />
                    </div>
                </div>
                <div className='card-footer d-flex px-4 py-3'>
                    <button
                        type='button'
                        className='btn btn-secondary cancel-custom-date-range mr-1 flex-fill text-center'
                        onClick={() => {
                            handleToggle();
                            setType('');
                            setDate('');
                            setStartDateCa('');
                            setEndDateCa('');
                        }}
                    >
                        {translate('Cancel_Label')}
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary apply-custom-date-range ml-1 flex-fill text-center'
                        disabled={
                            (type === '' && appliedType === '') ||
                            (date === '' && appliedDate === '') ||
                            (appliedDate === date && appliedType === type)
                        }
                        onClick={() => {
                            handleToggle();
                            dispatch(updateIsDateOpen(true));
                            dispatch(updateShouldSubmitSearch(true));
                            setappliedDate(date);
                            setappliedType(type);
                        }}
                    >
                        {translate('Apply_Label')}
                    </button>
                </div>
            </DropdownMenuButton>
        </>
    );
};
export default DateDocumentsFilter;
