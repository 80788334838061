export const SET_USER_DETAIL = 'SET_USER_DETAIL';
export const SET_CLIENT_SERVICE_CLIENT_ADMIN = 'SET_CLIENT_SERVICE_CLIENT_ADMIN';
export const AFTER_ADD_CLIENT_SERVICE_CLIENT_ADMIN = 'AFTER_ADD_CLIENT_SERVICE_CLIENT_ADMIN';
export const AM_TOGGLE_COUNTRY_SERVICES = 'AM_TOGGLE_COUNTRY_SERVICES';
export const AM_REMOVE_PARENT_SERVICE = 'AM_REMOVE_PARENT_SERVICE';
export const AM_UPDATE_FEATURE = 'AM_UPDATE_FEATURE';
export const AM_REMOVE_CLIENT_SERVICE = 'AM_REMOVE_CLIENT_SERVICE';
export const AM_REMOVE_FEATURE = 'AM_REMOVE_FEATURE';
export const AM_ADD_FEATURE = 'AM_ADD_FEATURE';
export const AM_SELECT_ALL_FEATURES_CA = 'AM_SELECT_ALL_FEATURES_CA';
export const AM_AFTER_SHOW_TEAM_MEMBERS = 'AM_AFTER_SHOW_TEAM_MEMBERS';
export const HANDLE_UPDATE = 'HANDLE_UPDATE';
export const SET_VALIDATION_MESSAGES_CLIENT_ADMIN = 'SET_VALIDATION_MESSAGES_CLIENT_ADMIN';
export const SET_USER_STATUS_CLIENT_ADMIN = 'SET_USER_STATUS_CLIENT_ADMIN';
export const RESET_STATE_ACCCESS_MANAGEMENT = 'RESET_STATE_ACCCESS_MANAGEMENT';
export const AFTER_GET_CLIENT_STATUS = 'AFTER_GET_CLIENT_STATUS';
