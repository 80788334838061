import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import Button from '../../../../../common/features/Button';
import ModalWrapper from '../../../../../common/features/Wrappers/ModalWrapper';
import { isAddContactFormValid } from '../../../../../common/functions/validators';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ContactsModals } from '../../../../../common/models/ContactsModals';
import { Contact } from '../../../common/models/Contact';
import { useContactsDispatch, useContactsSelector } from '../../../redux/hooks';
import { setExternalContactID, setInitialEditContact } from '../../../redux/reducers/contactsSlice';
import ContactInfoForm from '../AddContact/ContactInfoForm';

interface EditContactsModalProps {
    modal: ContactsModals;
    selectedContact: Contact;
    toggleModal: (prop: keyof ContactsModals, next?: boolean) => void;
}

const EditContactModal: React.FC<EditContactsModalProps> = ({ modal, toggleModal, selectedContact }) => {
    const { editContact } = useContactsSelector((state) => state.contacts);
    const translate = useTranslation();
    const dispatch = useContactsDispatch();

    useEffect(() => {
        if (selectedContact) {
            dispatch(
                setInitialEditContact({
                    ...selectedContact,
                    CarrierCodes: selectedContact.RoleLabel.includes('Carrier') ? selectedContact.CarrierCodes : ''
                })
            );
            dispatch(setExternalContactID(selectedContact?.ExternalContactID));
        }
    }, [dispatch, selectedContact]);

    const openConfirmModal = () => {
        if (isAddContactFormValid([editContact])) {
            toggleModal('confirm', true);
        }
    };

    return (
        <>
            <ModalWrapper id='AddContactsModal' onEscape={() => toggleModal('cancelAdd', true)}>
                <div className='modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable'>
                    <div className='modal-content'>
                        {/* MODAL HEADER */}
                        <div className='modal-header'>
                            <div className='mr-4'>
                                <h3 className='mb-0'>{translate('EditContact_Label')}</h3>
                                <h4 className='font-weight-medium mb-0'>
                                    {!modal.edit ? translate('AddContactMessage_Label') : translate('EditContactMessage_Label')}
                                </h4>
                            </div>
                            <Button variant='tertiary' className='btn-lg' onClick={() => toggleModal('cancelAdd', true)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                        </div>

                        <ContactInfoForm handleSubmit={openConfirmModal} modal={modal} selectedContact={selectedContact} />
                    </div>
                </div>
            </ModalWrapper>
        </>
    );
};

export default EditContactModal;
