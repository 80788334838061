import { createGenericStatusFilterSlice } from '../../../../redux/reducers/genericStatusFilterSlice';
import { STATUS_FILTER_OPTIONS } from '../../common/constants/constants-team';

const teamStatusFilterSlice = createGenericStatusFilterSlice({
    name: 'teamStatusFilter',
    initialState: { statuses: STATUS_FILTER_OPTIONS, selectedStatus: STATUS_FILTER_OPTIONS[0] },
    reducers: {}
});

export const { setSelectedStatus } = teamStatusFilterSlice.actions;

export default teamStatusFilterSlice.reducer;
