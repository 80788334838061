import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShipmentListSlice } from '../../common/models/ReduxSlices';
import { ShipmentListItem } from '../../common/models/ShipmentList';
import { GetDocumentsIndex, GetShipmentList } from '../actions/shipmentList';

const initialState: ShipmentListSlice = {
    totalSelected: 0,
    totalInProgress: 0,
    totalOnHold: 0,
    totalCompleted: 0,
    shipmentList: [],
    allShipmentList: [],
    allSelectedEntries: [],
    documentsIndex: null,
    docsIndexIsLoading: false,
    isLoading: false,
    error: undefined
};

const shipmentListSlice = createSlice({
    name: 'shipmentList',
    initialState,
    reducers: {
        updateStatusesAmounts(state, action: PayloadAction<number>) {
            state.totalSelected = action.payload;
        },
        resetCheckedEntries(state, action: PayloadAction) {
            state.allSelectedEntries = [];
        },

        updateAllShipmentSelection(
            state,
            action: PayloadAction<{ selectedEntries: ShipmentListItem[]; headerChecked: boolean; maxDownloadItems: number | undefined }>
        ) {
            state.allSelectedEntries = action.payload.selectedEntries.reduce((acc, obj: ShipmentListItem) => {
                if (
                    action.payload.maxDownloadItems &&
                    acc &&
                    acc?.length >= action.payload.maxDownloadItems &&
                    !action.payload.headerChecked
                ) {
                    return state.allSelectedEntries;
                } else if (obj.selected) {
                    //@ts-ignore
                    if (acc.findIndex((item) => item === obj.EntryNumber) === -1 || acc.length === 0) acc.push(obj.EntryNumber);
                } else {
                    const index = acc?.findIndex((item) => item === obj.EntryNumber);

                    if ((index && index !== -1) || index === 0) {
                        acc?.splice(index, 1);
                    }
                }

                return acc;
            }, state.allSelectedEntries);
        },
        addShipmentSelection(state, action: PayloadAction<string>) {
            if (state.allSelectedEntries) {
                state.allSelectedEntries = [...state.allSelectedEntries, action.payload];
            }
        },
        removeShipmentSelection(state, action: PayloadAction<string>) {
            if (state.allSelectedEntries) {
                state.allSelectedEntries = state.allSelectedEntries.filter((item) => item !== action.payload);
            }
        }
        // headerShipmenrSelection(state, action: PayloadAction<string[]>) {
        //     if (state.selectedEntries) {
        //         state.selectedEntries = action.payload;
        //     }
        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetShipmentList.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetShipmentList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.totalInProgress = action.payload.totalInProgress ? action.payload.totalInProgress : 0;
                state.totalOnHold = action.payload.totalOnHold ? action.payload.totalOnHold : 0;
                state.totalCompleted = action.payload.totalCompleted ? action.payload.totalCompleted : 0;
                state.shipmentList = action.payload.shipmentList
                    ? action.payload.shipmentList.map((dataItem: ShipmentListItem) => Object.assign({ selected: false }, dataItem))
                    : [];
                const allShipmentListArray = action.payload.shipmentList
                    ? [
                          ...state.allShipmentList,
                          ...action.payload.shipmentList.map((dataItem: ShipmentListItem) => Object.assign({ selected: false }, dataItem))
                      ]
                    : [];
                if (allShipmentListArray) {
                    state.allShipmentList = allShipmentListArray.reduce((accumulator, current) => {
                        if (!accumulator.find((item: ShipmentListItem) => item.EntryNumber === current.EntryNumber)) {
                            //@ts-ignore
                            accumulator.push(current);
                        }
                        return accumulator;
                    }, []);
                }
            })
            .addCase(GetShipmentList.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            // GET INDEX OBJ FOR DOCUMENTS
            .addCase(GetDocumentsIndex.pending, (state, action) => {
                state.docsIndexIsLoading = true;
                state.error = undefined;
            })
            .addCase(GetDocumentsIndex.fulfilled, (state, action) => {
                state.docsIndexIsLoading = false;
                state.documentsIndex = action.payload;
            })
            .addCase(GetDocumentsIndex.rejected, (state, action) => {
                state.docsIndexIsLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const { updateStatusesAmounts, updateAllShipmentSelection, addShipmentSelection, removeShipmentSelection, resetCheckedEntries } =
    shipmentListSlice.actions;
export default shipmentListSlice.reducer;
