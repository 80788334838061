const initialState = {
    model: {
        topRowLeft: [
            {
                text: '1-800-837-1063',
                url: ''
            },
            {
                text: 'LiveChat_Label',
                url: '#',
                id: 'menu-item-23650'
            },
            {
                text: 'ContactUs_Label',
                url: 'http://www.livingstonintl.com/contact-us/',
                id: 'menu-item-23651',
                newTab: true
            }
        ],
        topRowRight: [
            {
                text: 'ShipmentTracker_Label',
                url: 'http://www.livingstonintl.com/shipment-tracker/',
                id: 'menu-item-34',
                newTab: true
            }
        ],
        topRowBottom: [
            {
                text: 'Services_Label',
                url: 'http://www.livingstonintl.com/services/',
                id: 'menu-item-24271',
                items: [
                    {
                        text: 'Customs Brokerage',
                        url: 'http://www.livingstonintl.com/services/customs-brokerage/',
                        id: 'menu-item-24340',
                        displayCarot: true,
                        items: [
                            {
                                text: 'US Customs Brokerage',
                                url: 'http://www.livingstonintl.com/services/customs-brokerage/us-customs-brokerage/',
                                id: 'menu-item-24065'
                            },
                            {
                                text: 'Canadian Customs Brokerage',
                                url: 'http://www.livingstonintl.com/services/customs-brokerage/canadian-customs-brokerage/',
                                id: 'menu-item-24064'
                            },
                            {
                                text: 'Subscibe To Insight',
                                url: 'http://www.livingstonintl.com/services/customs-brokerage/subscribe-to-insight/',
                                id: 'menu-item-25452'
                            }
                        ]
                    },
                    {
                        text: 'Global Trade Management',
                        url: 'http://www.livingstonintl.com/services/global-trade-management/',
                        id: 'menu-item-24078',
                        displayCarot: true,
                        items: [
                            {
                                text: 'Import Operations Solutions',
                                url: 'http://www.livingstonintl.com/services/global-trade-management/import-operations-solutions/',
                                id: 'menu-item-24081'
                            },
                            {
                                text: 'Export Operations Solutions',
                                url: 'http://www.livingstonintl.com/services/global-trade-management/export-operations-solutions/',
                                id: 'menu-item-24080'
                            },
                            {
                                text: 'Global Product Data Management',
                                url: 'http://www.livingstonintl.com/services/global-trade-management/global-product-data-management/',
                                id: 'menu-item-24085'
                            },
                            {
                                text: 'U.S./Mexico Border Solution ',
                                url: 'http://www.livingstonintl.com/services/global-trade-management/u-s-mexico-border-solutions/',
                                id: 'menu-item-24084'
                            }
                        ]
                    },
                    {
                        text: 'Trade Consulting',
                        url: 'http://www.livingstonintl.com/services/trade-consulting/',
                        id: 'menu-item-24087',
                        items: [
                            {
                                text: 'International Trade Compliance',
                                id: 'menu-item-24360',
                                url: 'http://www.livingstonintl.com/services/trade-consulting/international-trade-compliance/'
                            },
                            {
                                text: 'International Trade Agreements',
                                id: 'menu-item-24131',
                                url: 'http://www.livingstonintl.com/services/trade-consulting/international-trade-agreements/'
                            },
                            {
                                text: 'Duty and Tax Recovery Services',
                                url: 'http://www.livingstonintl.com/services/trade-consulting/duty-and-tax-recovery-services/',
                                id: 'menu-item-24130'
                            },
                            {
                                text: 'Supply Chain Safety and Security',
                                url: 'http://www.livingstonintl.com/services/trade-consulting/supply-chain-safety-and-security/',
                                id: 'menu-item-24362'
                            },
                            {
                                text: 'Meet Out Team',
                                id: 'menu-item-24133',
                                url: 'http://www.livingstonintl.com/services/trade-consulting/meet-our-team/'
                            }
                        ]
                    },
                    {
                        text: 'Freight Forwarding',
                        id: 'menu-item-24128',
                        url: 'http://www.livingstonintl.com/services/freight-forwarding/'
                    },
                    {
                        text: 'Business Process Services',
                        id: 'menu-item-24290',
                        url: 'http://www.livingstonintl.com/services/business-process-services/'
                    },
                    {
                        text: 'Trade Software',
                        id: 'menu-item-24123',
                        url: 'http://www.livingstonintl.com/services/tradesphere/'
                    },
                    {
                        text: 'Vehicle Transportation Services',
                        id: 'menu-item-24122',
                        url: 'http://www.livingstonintl.com/services/vehicle-transportation-services/'
                    }
                ]
            },

            {
                text: 'Resources_Label',
                id: 'menu-item-24003',
                url: 'http://www.livingstonintl.com/resources/',
                items: [
                    {
                        text: 'Shipping',
                        id: 'menu-item-24058',
                        url: 'http://www.livingstonintl.com/resource-category/shipping/'
                    },
                    {
                        text: 'Managing Your Customs Process',
                        id: 'menu-item-24059',
                        url: 'http://www.livingstonintl.com/resource-category/managing-your-customs-process/'
                    },
                    {
                        text: 'Managing Compliance and Risks',
                        id: 'menu-item-24061',
                        url: 'http://www.livingstonintl.com/resource-category/managing-compliance-and-risk/'
                    },
                    {
                        text: 'Importing And Exporting',
                        id: 'menu-item-24057',
                        url: 'http://www.livingstonintl.com/resource-category/importing-and-exporting/'
                    },
                    {
                        text: 'Outsourcing Your Customs Process',
                        id: 'menu-item-24060',
                        url: 'http://www.livingstonintl.com/resource-category/outsourcing-your-customs-process/'
                    },
                    {
                        text: 'Webinars',
                        id: 'menu-item-24062',
                        url: 'http://www.livingstonintl.com/webinars/'
                    },
                    {
                        text: 'Forms',
                        id: 'menu-item-24005',
                        url: 'http://www.livingstonintl.com/forms/',
                        displayCarot: true,
                        items: [
                            {
                                text: 'U.S.',
                                id: 'menu-item-24011',
                                url: 'http://www.livingstonintl.com/form-category/u-s/'
                            },
                            {
                                text: 'Canada',
                                id: 'menu-item-24010',
                                url: 'http://www.livingstonintl.com/form-category/canada/'
                            }
                        ]
                    },
                    {
                        text: 'Subscriptions',
                        id: 'menu-item-25834',
                        url: 'http://www.livingstonintl.com/subscribe-to-new-posts/'
                    }
                ]
            },
            {
                text: 'TradeNews_Label',
                id: 'menu-item-24004',
                url: 'http://www.livingstonintl.com/trade-news/',
                items: [
                    {
                        text: 'U.S. Regulatory Updates',
                        id: 'menu-item-24013',
                        url: 'http://www.livingstonintl.com/category/u-s-regulatory-updates/'
                    },
                    {
                        text: 'Canada Regulatory Updates',
                        id: 'menu-item-24014',
                        url: 'http://www.livingstonintl.com/category/canada-regulatory-updates/'
                    },
                    {
                        text: 'Mexico Regulatory Updates',
                        id: 'menu-item-24016',
                        url: 'http://www.livingstonintl.com/category/mexico-regulatory-updates/'
                    },
                    {
                        text: 'International Regulatory Updates',
                        id: 'menu-item-24015',
                        url: 'http://www.livingstonintl.com/category/international-regulatory-updates/'
                    },
                    {
                        text: 'Freight And Transportation Updates',
                        id: 'menu-item-24017',
                        url: 'http://www.livingstonintl.com/category/freight-transportation-updates/'
                    },
                    {
                        text: 'Our Experts Speak',
                        id: 'menu-item-24012',
                        url: 'http://www.livingstonintl.com/category/our-experts-speak/'
                    }
                ]
            },
            {
                text: 'AboutLivingston_Label',
                id: 'menu-item-24008',
                url: 'http://www.livingstonintl.com/about-us/',
                items: [
                    {
                        text: 'Mergers And Acquisitions',
                        id: 'menu-item-24023',
                        url: 'http://www.livingstonintl.com/about-us/mergers-acquisitions/'
                    },
                    {
                        text: 'Press Releases',
                        id: 'menu-item-24022',
                        url: 'http://www.livingstonintl.com/about-us/press-releases/'
                    },
                    {
                        text: 'Investors',
                        id: 'menu-item-24021',
                        url: 'http://www.livingstonintl.com/about-us/investors/'
                    },
                    {
                        text: 'Vision, Values And Purpose',
                        id: 'menu-item-24018',
                        url: 'http://www.livingstonintl.com/about-us/vision-values-and-purpose/'
                    },
                    {
                        text: 'Executive Team',
                        id: 'menu-item-24020',
                        url: 'http://www.livingstonintl.com/about-us/executive-team/'
                    },
                    {
                        text: 'History',
                        id: 'menu-item-24019',
                        url: 'http://www.livingstonintl.com/about-us/history/'
                    },
                    {
                        text: 'Upcoming Trade Shows And Events',
                        id: 'menu-item-25396',
                        url: 'http://www.livingstonintl.com/about-us/events/'
                    }
                ]
            }
        ]
    }
};

const reducer = (state = initialState) => {
    return state;
};

export default reducer;
