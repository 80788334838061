import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { formatTranslated } from '../../../utils/mlp-utils';

class ImagingDropDown extends Component {
    static defaultProps = {
        large: false,
        transparent: false
    };

    static propTypes = {
        name: PropTypes.string,
        valueField: PropTypes.string,
        options: PropTypes.array,
        large: PropTypes.bool,
        transparent: PropTypes.bool,
        onItemChanged: PropTypes.func,
        customAction: PropTypes.func
    };

    selectTooltipRef = React.createRef();

    componentDidMount() {
        this.props.onInitTranslations('Imaging.Imaging.SearchResultDetails');
    }
    componentDidUpdate(prevProps) {
        const oldPropTranslation = formatTranslated('SelectViewMore', this.props.translations.Phrases);
        const currentPropTranslation = formatTranslated('SelectViewMore', prevProps.translations.Phrases);
        if (!oldPropTranslation.includes('Untranslated')) {
            $(ReactDOM.findDOMNode(this.selectTooltipRef.current)).tooltip({
                title: oldPropTranslation,
                placement: 'top'
            });
        }
        if (!currentPropTranslation.includes('Untranslated')) {
            $(ReactDOM.findDOMNode(this.selectTooltipRef.current)).tooltip({
                title: currentPropTranslation,
                placement: 'top'
            });
        }
    }

    updateSelection(value) {
        const oldIndex = this.props.options.findIndex((element) => element.isSelected === true);
        const newIndex = this.props.options.findIndex((element) => element[this.props.valueField] === value);

        this.props.onItemChanged(newIndex, oldIndex, value, this.props.name);

        if (this.props.customAction) {
            this.props.customAction();
        }
    }

    getButtonClass() {
        let classes = [];
        classes.push('button');
        if (this.props.large) {
            classes.push('large');
        }
        if (this.props.transparent) {
            classes.push('transparent');
        }
        return classes.join(' ');
    }

    getButtonLayout(options, selectedElement) {
        if (options && options.length > 1) {
            return (
                <div type='button' className={this.getButtonClass()} data-toggle='dropdown'>
                    <div className='row'>
                        <div className='col-10 text-left'>{selectedElement}</div>
                        <div className='col-2 text-right'>
                            <i ref={this.selectTooltipRef} className='fa fa-angle-down fa-lg'></i>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div type='button' className={this.getButtonClass() + ' single'}>
                <div className='row'>
                    <div className='col-10 text-left'>{selectedElement}</div>
                    <div className='col-2 text-right opacity-none'>
                        <i ref={this.selectTooltipRef} className='fa fa-angle-down fa-lg'></i>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let selectedValue = '';
        let selectedLabel = '';

        const selectedItem = this.props.options && this.props.options.find((o) => o.isSelected);
        if (selectedItem) {
            const selectedTitleAttr = selectedItem.metadata.find((md) => md.isTitleAttribute);
            selectedValue = selectedTitleAttr.value;
            selectedLabel = selectedTitleAttr.label;
        }

        const options =
            this.props.options &&
            this.props.options.map((item, i) => {
                const titleAttr = item.metadata.find((md) => md.isTitleAttribute);
                let title = '';
                if (titleAttr) {
                    title = titleAttr.value;
                }

                const optionItem = item.isSelected ? (
                    <button className='imaging-dropdown-btn'>
                        {title}
                        <i className='fa fa-check fa-lg'></i>
                    </button>
                ) : (
                    <button className='imaging-dropdown-btn'>{title}</button>
                );

                return (
                    <li
                        key={item[this.props.valueField] + '-' + i}
                        onClick={() => this.updateSelection(item[this.props.valueField])}
                        className='dropdown-menu-item'
                        value={item[this.props.valueField]}
                    >
                        {optionItem}
                    </li>
                );
            });

        const selectedElement = (
            <div className='dropdown-display'>
                <span className='dropdown-display-label'>{selectedLabel}</span>
                <span className='dropdown-display-value'>{selectedValue}</span>
            </div>
        );

        let button = this.getButtonLayout(options, selectedElement);

        return (
            <div className='dropdown'>
                {button}
                <ul className='dropdown-menu'>{options}</ul>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actions.initTranslations(phraseGroup))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImagingDropDown);
