import { FunctionComponent } from 'react';
import { ButtonProps, ButtonSize, ButtonType } from '../Models/Button.types';

export const Button: FunctionComponent<ButtonProps> = ({
    children,
    type = ButtonType.Primary,
    disabled = false,
    onClick,
    size = ButtonSize.Large,
    className = ''
}) => {
    return (
        <button type='button' className={`btn btn-${type} ${size} ${className}`} onClick={() => onClick && onClick()} disabled={disabled}>
            {children}
        </button>
    );
};
