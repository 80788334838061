import ReactDOM from 'react-dom';

interface DropdownWrapperProps {
    children: React.ReactNode;
}

const DropdownWrapper: React.FC<DropdownWrapperProps> = ({ children }) => {
    const parentContainer = document.querySelector('#portalDropdown') ?? document.body;

    return ReactDOM.createPortal(<>{children}</>, parentContainer);
};

export default DropdownWrapper;
