import { clearancesDownloadStatuses, MOTCodes, MOTMap, StatusFilter } from '../constants/constants-clearances';
import { ClearancesDownloadItem, ShipmentListItem } from '../models/ShipmentList';
import { parseDate } from './parser';

export const formatShipmentsForDownload = (shipments: ShipmentListItem[], country: 'us' | 'ca', returnAllFields: boolean) => {
    const formattedShipments = shipments.map((shipment) => {
        // common required fields
        let formattedShipment: ClearancesDownloadItem = {
            EntryNumber: shipment.EntryNumber!,
            ModifiedOn: parseDate(shipment.ModifiedOn!),
            ClearanceStatus: StatusFilter.find((status) => status.id === shipment.ClearanceStatus!)?.label!,
            ClientCaptionNumber: shipment.ClientCaptionNumber!,
            Milestone: shipment.Milestone!,
            ReferenceNumber: shipment.ReferenceNumber!,
            CarrierCode: shipment.CarrierCode!,
            ModeOfTransport: MOTMap[MOTCodes[shipment.ModeOfTransport.id! as keyof typeof MOTCodes]]?.label,
            BillOfLading: shipment.BillOfLading.MasterBill!
        };

        // additional required field for CA
        if (country === 'ca') {
            formattedShipment = {
                ...formattedShipment,
                CargoControlNumber: shipment.CargoControlNumber!
            };
        }

        // optional US fields
        if (returnAllFields && country === 'us') {
            formattedShipment = {
                ...formattedShipment,
                DocumentsReceived: parseDate(shipment.DocsReceivedDateTime!),
                CustomsCertified: parseDate(shipment.ReleaseAcceptedOn!),
                CustomsReleasedOn: parseDate(shipment.CustomsReleasedOn!),
                OneUsgReceivedOn: parseDate(shipment.OneUsgReceivedOn!),
                EntrySummaryAccepted: parseDate(shipment.EntryAcceptedOn!),
                FileNo: shipment.FileNo!,
                Carrier: shipment.CarrierName!,
                ShipperName: shipment.ShipperName!,
                EntryCodePort: shipment.CustomsPort!.split('-')[0],
                PortEntryName: shipment.CustomsPort!.split('-')[1],
                GrossWeight: shipment.GrossWeight!,
                ManifestQuantity: shipment.ManifestQuantity!,
                ShipmentValue: shipment.ShipmentValue!,
                ContainerNumbers: shipment.ContainerNumbers!.join(','),
                HouseBillOfLading: shipment.BillOfLading.HouseBill!,
                FDAStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.FDAStatus!)?.Value,
                TTBStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.TTBStatus!)?.Value,
                NMFSStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.NMFSStatus!)?.Value,
                NHTSAStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.NHTSAStatus!)?.Value,
                FWSStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.FWSStatus!)?.Value,
                FSISStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.FSISStatus!)?.Value,
                EPAStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.EPAStatus!)?.Value,
                DEAStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.DEAStatus!)?.Value,
                DDTCStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.DDTCStatus!)?.Value,
                CPSCStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.CPSCStatus!)?.Value,
                ATFStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.ATFStatus!)?.Value,
                APHISLaceyStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.APHISLaceyStatus!)?.Value,
                APHISCoreStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.APHISCoreStatus!)?.Value,
                FDAPNStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.FDAPNStatus!)?.Value,
                AMSStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.AMSStatus!)?.Value,
                OMCStatus: clearancesDownloadStatuses.find((status) => status.ABICode === shipment.OMCStatus!)?.Value
            };
        }

        // optional CA fields
        if (returnAllFields && country === 'ca') {
            formattedShipment = {
                ...formattedShipment,
                ReferenceNo2: shipment.ReferenceNumber2!,
                DocumentsReceived: parseDate(shipment.DocsReceivedDateTime!),
                CustomsCertified: parseDate(shipment.ReleaseAcceptedOn!),
                CustomsReleasedOn: parseDate(shipment.CustomsReleasedOn!),
                ReleaseSubmitted: parseDate(shipment.ReleaseSubmitted!),
                EntrySummaryAccepted: parseDate(shipment.EntryAcceptedOn!),
                FileNo: shipment.FileNo!,
                Carrier: shipment.CarrierName!,
                ShipperName: shipment.ShipperName!,
                EntryCodePort: shipment.CustomsPort!.split('-')[0],
                PortEntryName: shipment.CustomsPort!.split('-')[1],
                GrossWeight: shipment.GrossWeight!,
                ManifestQuantity: shipment.ManifestQuantity!,
                ShipmentValue: shipment.ShipmentValue!,
                ContainerNumbers: shipment.ContainerNumbers!.join(','),
                HouseBillOfLading: shipment.BillOfLading.HouseBill!
            };
        }

        return formattedShipment;
    });

    return formattedShipments;
};
