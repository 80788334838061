import { useEffect, useState } from 'react';
import { GridSubscriptions } from '../../pages/subscribers/common/models/EditSubscriber';

interface FilterProps {
    searchParam: string;
    subscriptionType: { value: string; label: string }[];
    gridSubscriptions: GridSubscriptions[];
}

const useClearanceNotificationsFilter = ({ searchParam, subscriptionType, gridSubscriptions }: FilterProps) => {
    const [filteredSubscriptions, setFilteredSubscriptions] = useState<GridSubscriptions[]>();

    useEffect(() => {
        if (gridSubscriptions.length) {
            const filtered: GridSubscriptions[] = [];
            if (searchParam && !subscriptionType.length) {
                const filtered = gridSubscriptions.filter((item) => {
                    if (
                        (item.CustomerNumber && item.CustomerNumber.includes(searchParam)) ||
                        (item.EntryNo && item.EntryNo.includes(searchParam))
                    ) {
                        return item;
                    }
                    return null;
                });
                setFilteredSubscriptions(filtered);
            } else if ((!searchParam && subscriptionType.length) || (searchParam && subscriptionType.length)) {
                gridSubscriptions.forEach((gridSub, i) => {
                    subscriptionType.forEach((subType) => {
                        if (!searchParam && subscriptionType.length) {
                            gridSub.SubscriptionType === subType.value && filtered.push(gridSubscriptions[i]);
                        } else if (searchParam && subscriptionType.length) {
                            gridSub &&
                                gridSub.SubscriptionType === subType.value &&
                                (gridSub.CustomerNumber?.includes(searchParam) || gridSub.EntryNo?.includes(searchParam)) &&
                                filtered.push(gridSubscriptions[i]);
                        }
                    });
                });
                setFilteredSubscriptions(filtered);
            } else {
                setFilteredSubscriptions(gridSubscriptions);
            }
        } else {
            setFilteredSubscriptions(gridSubscriptions);
        }
    }, [searchParam, subscriptionType, gridSubscriptions]);

    return { filteredSubscriptions, count: filteredSubscriptions?.length };
};

export default useClearanceNotificationsFilter;
