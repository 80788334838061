import $ from 'jquery';
import axios from '../../../axios-mlp';
import { global as GlobalConstants } from '../../../constants/mlp-constants';
import * as actionTypes from './batchEditUsersActionTypes';

export const getBatchJobEdit = (batchJobId) => {
    return (dispatch) => {
        let params = new URLSearchParams();
        params.append('batchJobId', batchJobId);

        axios.get('/BatchUser/GetBatchJobById', { params: params }).then((response) => {
            dispatch(afterGetBatchJob(response.data));
        });
    };
};
export const initGetBatchJobEdit = (batchJobId) => {
    return (dispatch) => {
        $('#overlay').show();
        let params = new URLSearchParams();
        params.append('batchJobId', batchJobId);

        axios
            .get('/BatchUser/Edit', { params: params })
            .then((response) => {
                dispatch(afterGetBatchJob(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const submitBatchEdit = (batchJob) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .post('/BatchUser/SubmitBatchEdit', batchJob)
            .then((response) => {
                dispatch(afterSubmitBatchEdit(response.data));
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};
export const cancelBatchJobEdit = (batchJobId) => {
    return (dispatch) => {
        $('#overlay').show();
        let params = new URLSearchParams();
        params.append('batchJobId', batchJobId);

        axios
            .post('/BatchUser/CancelBatchJob', null, { params: params })
            .then((response) => {
                dispatch(afterGetBatchJob(response.data));
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const updateChildFeature = (parentIndex, childIndex, roleId, isSelected) => {
    return {
        type: actionTypes.UPDATE_CHILD_FEATURE,
        payload: { parentIndex: parentIndex, childIndex: childIndex, roleId: roleId, isSelected: isSelected }
    };
};

export const removeChildUser = (parentIndex, childIndex) => {
    return {
        type: actionTypes.REMOVE_CHILD_USER,
        payload: { parentIndex: parentIndex, childIndex: childIndex }
    };
};

export const updateChildStatus = (parentIndex, childIndex, status) => {
    return {
        type: actionTypes.UPDATE_CHILD_STATUS,
        payload: { parentIndex: parentIndex, childIndex: childIndex, status: status }
    };
};

export const updateParentFeature = (parentIndex, roleId, isSelected) => {
    return {
        type: actionTypes.UPDATE_PARENT_FEATURE,
        payload: { parentIndex: parentIndex, roleId: roleId, isSelected: isSelected }
    };
};
export const removeParentUser = (parentIndex) => {
    return {
        type: actionTypes.REMOVE_PARENT_USER,
        payload: { parentIndex: parentIndex }
    };
};

export const updateParentStatus = (parentIndex, status) => {
    return {
        type: actionTypes.UPDATE_PARENT_STATUS,
        payload: { parentIndex: parentIndex, status: status }
    };
};
export const autoSelectChildStatuses = (parentIndex, children, statuses) => {
    return {
        type: actionTypes.UPDATE_PARENT_STATUS,
        payload: { parentIndex: parentIndex, children: children, statuses: statuses }
    };
};
export const setChildStatus = (parentIndex, childIndex, selectedStatus, isEnabled) => {
    return {
        type: actionTypes.SET_CHILD_STATUS,
        payload: { parentIndex: parentIndex, childIndex: childIndex, selectedStatus: selectedStatus, isEnabled: isEnabled }
    };
};

export const isMlpRole = (role) => {
    return role.Alias === GlobalConstants.MLP_FEATURE_CODE;
};

export const afterGetBatchJob = (model) => {
    return {
        type: actionTypes.AFTER_GET_BATCH_JOB,
        payload: { model: model }
    };
};
export const afterSubmitBatchEdit = (model) => {
    return {
        type: actionTypes.AFTER_SUBMIT_BATCH_JOB_EDIT,
        payload: { model: model }
    };
};

export const undoBatchJob = (model) => {
    return {
        type: actionTypes.UNDO_BATCH_JOB,
        payload: { model: model }
    };
};
export const toggleRole = (roleId, selected) => {
    return {
        type: actionTypes.TOGGLE_ROLE,
        payload: { roleId: roleId, selected: selected }
    };
};
export const updateAllStatuses = (status) => {
    return {
        type: actionTypes.UPDATE_ALL_STATUSES,
        payload: { status: status }
    };
};

export const clearStateBatchJobEdit = () => {
    return {
        type: actionTypes.CLEAR_STATE
    };
};
