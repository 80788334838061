import dayjs from 'dayjs';
import { format } from 'react-string-format';

export const getCountryFromSelectedServices = (countries) => {
    return countries.find((c) => c.IsSelected === true);
};

// Client Service Picker
export const getSelectedClientServices = (clientServiceSelectionModel) => {
    let selectedServices = [];
    if (clientServiceSelectionModel !== undefined) {
        const country = clientServiceSelectionModel.Countries.find((c) => {
            return c.IsSelected === true;
        });
        const countryServices = clientServiceSelectionModel.ClientServices.filter((cs) => cs.CountryCode === country.Code);
        for (let i = 0; i < countryServices.length; i++) {
            if (countryServices[i].IsSelected) selectedServices.push(countryServices[i]);
        }
    }
    return selectedServices;
};

export const setValueByPath = (obj, propPath, value) => {
    if (typeof propPath === 'string') {
        return setValueByPath(obj, propPath.split('.'), value);
    } else if (propPath.length === 1 && value !== undefined) {
        return (obj[propPath[0]] = value);
    } else if (propPath.length === 0) {
        return obj;
    } else {
        if (obj[propPath[0]] === undefined) obj[propPath[0]] = {};

        return setValueByPath(obj[propPath[0]], propPath.slice(1), value);
    }
};

export const groupBy = (xs, key) => {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const isNullOrWhitespace = (input) => {
    return !input || !input.trim();
};

//it has to be a function because of arguments object, arrow functions don't have arguments object
export const flattenObject = function (obj) {
    if (obj === null) obj = '';

    let prefix = arguments[1] || '',
        out = arguments[2] || {},
        name;
    for (name in obj) {
        if (obj.hasOwnProperty(name)) {
            typeof obj[name] === 'object' ? flattenObject(obj[name], prefix + name + '.', out) : (out[prefix + name] = obj[name]);
        }
    }
    return out;
};

//it has to be a function because of arguments object, arrow functions don't have arguments object
export const formatTranslated = function (toTranslate, mlpTranslations) {
    if (toTranslate === undefined || toTranslate === '') {
        return '';
    }
    const indexDouble = toTranslate.indexOf('@@');
    let translatedArr;
    let key;
    if (indexDouble >= 0) {
        translatedArr = toTranslate.split('@@');
        key = translatedArr[0];
    } else {
        translatedArr = Array.prototype.slice.call(arguments);
        key = translatedArr.shift();
    }

    if (mlpTranslations[key] === undefined) {
        return 'Untranslated:[' + key + ']';
    } else if (translatedArr.length === 3) {
        return format(mlpTranslations[key], translatedArr[1], translatedArr[2]);
    } else if (translatedArr.length === 2) {
        return format(mlpTranslations[key], translatedArr[1]);
    } else {
        return mlpTranslations[key];
    }
};

export const printPage = (url) => {
    // const root = window.location.protocol + '//' + document.location.host;
    // const viewerUrl = root + '/Static/PDFViewer/web/viewer.html?file=';
    // const fileUrl = encodeURIComponent(webAPIbaseURL + '/Imaging/PrintPage/?' + url);
    window.open(url, '_blank', 'noopener,noreferrer');
};

export const isVisible = (element, searchTypeCode) => {
    return element.SearchTypeCodes.length === 0 || element.SearchTypeCodes.indexOf(searchTypeCode) >= 0;
};

export const initValidationMessages = (model) => {
    let validationMessages = {};
    const obj = flattenObject(model);

    for (let i in obj) {
        setValueByPath(validationMessages, i, obj[i]);
    }

    return validationMessages;
};

// Imaging
export const getSearchTypesBySelectedServices = (vm) => {
    const selectedCountry = vm.ClientServiceSelection.Countries.find((country) => country.IsSelected === true);
    const selectedCountryCode = selectedCountry !== undefined ? selectedCountry.Code : '';

    const selectedServices = vm.ClientServiceSelection.ClientServices.filter(
        (service) => service.IsSelected === true && service.CountryCode === selectedCountryCode
    );

    // If no clients are selected, only users with unrestricted access should see the results
    if ((selectedServices === undefined || selectedServices.length <= 0) && !vm.ClientServiceSelection.HasUnrestrictedAccess) {
        return [];
    }

    return vm.SearchTypes.filter((type) => type.CountryCode === selectedCountryCode);
};

export const pluck = (array, key) => {
    return array.map((element) => element[key]);
};

export const sortBy = (array, property, order) => {
    return array.sort((a, b) => sortFunction(a[property], b[property], order));
};

export const sortByOrders = (array, properties, orders) => {
    return array.sort((a, b) => {
        let exp;
        orders.forEach((order, index) => {
            if (index === 0) {
                exp = sortFunction(a[properties[index]], b[properties[index]], order);
            } else {
                exp = exp || sortFunction(a[properties[index]], b[properties[index]], order);
            }
        });
        return exp;
    });
};

export const sortFunction = (value1, value2, order) => {
    if (isNumber(value1) && isNumber(value2)) {
        return order === 'DESC' ? value2 - value1 : value1 - value2;
    } else {
        // Treating undefined values as empty strings, because localeCompare function only sorts array of strings
        // const val1 = value1 ? value1 : '';
        // const val2 = value2 ? value2 : '';
        // localeCompare function is putting empty strings on the top of sorted array
        // return order === 'DESC' ? val2.localeCompare(val1) : val1.localeCompare(val2);

        // Treating empty strings as undefined values
        const val1 = value1 && value1.toString().trim() !== '' ? value1 : undefined;
        const val2 = value2 && value2.toString().trim() !== '' ? value2 : undefined;
        // Handling undefined values in sorted array, putting them always on the bottom of sorted array
        let exp = !val1 - !val2;
        return order === 'DESC' ? exp || -(val1 > val2) || +(val1 < val2) : exp || +(val1 > val2) || -(val1 < val2);
    }
};

export const isNumber = (value) => {
    return typeof value === 'number';
};

export const sortByFunc = (array, func) => {
    return array.sort((a, b) => {
        if (func(a) === undefined || func(b) === undefined) {
            return 0;
        } else {
            return (func(a) > func(b)) - (func(a) < func(b));
        }
    });
};

export const UTCtoLocalDateTime = (date) => {
    dayjs.locale(window.navigator.language || window.navigator.systemLanguage);
    if (!date) return 'N/A';
    return dayjs.utc(date).toDate().toLocaleString();
};

export const camelize = (str) => {
    return str.replace(/[-_](\w)/g, (match) => {
        return match.charAt(1).toUpperCase();
    });
};

export const camelToTitleCase = (str) => {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
        return str.toUpperCase();
    });
};
