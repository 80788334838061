import DOMPurify from 'dompurify';
import $ from 'jquery';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DefaultEditor } from 'react-simple-wysiwyg';
import toastr from 'toastr';
import DateTimePicker from '../../common/DateTimePicker/DateTimePicker';
import Dropdown from '../../common/Dropdown/Dropdown';
import TextBox from '../../common/Textbox/Textbox';
import * as actionCreators from '../../store/actions/index';
import { formatDate } from '../../utils/dateFormater';
import { isNullOrWhitespace } from '../../utils/mlp-utils';

class ManageNoticesArea extends Component {
    componentWillUnmount = () => {
        // this.props.onCloseManageNoticesArea();
    };

    onChangeEditorContent = (e) => {
        this.props.onChangeContent(e.target.value);
    };

    //This is where the notice data is being validated
    saveNotice = (selectedNotice) => {
        $('#overlay').show();
        const sanitizedContent = DOMPurify.sanitize(selectedNotice.Content);

        const dateFrom = formatDate(
            selectedNotice.EffectiveDatePartFrom,
            selectedNotice.EffectiveTimePartFrom,
            selectedNotice.EffectiveTimePartFromAmPm
        );

        const dateTo = formatDate(
            selectedNotice.EffectiveDatePartTo,
            selectedNotice.EffectiveTimePartTo,
            selectedNotice.EffectiveTimePartToAmPm
        );

        if (this.validateNotice(selectedNotice, dateFrom, dateTo)) {
            let notice = { ...selectedNotice };
            notice.ServiceProvider = this.props.serviceProviders.find((sp) => sp.Text === notice.ServiceProvider).Value;
            notice.EffectiveDateFrom = dateFrom;
            notice.EffectiveDateTo = dateTo;
            notice.Content = sanitizedContent;
            this.props.onNoticeSave(notice, this.props.translations.Phrases['NoticeSaved_Msg']);
        }
    };

    validateNotice = (notice, dateFrom, dateTo) => {
        let validationFailedMessage = '';
        //Check for null dates and times
        validationFailedMessage += this.validateDateRequired(
            notice.EffectiveDatePartFrom,
            notice.EffectiveTimePartFrom,
            this.props.translations.Phrases['Notices_Start']
        );
        validationFailedMessage += this.validateDateRequired(
            notice.EffectiveDatePartTo,
            notice.EffectiveTimePartTo,
            this.props.translations.Phrases['Notices_End']
        );

        var currentDate = new Date();

        if (dateTo < currentDate) {
            validationFailedMessage += this.props.translations.Phrases['Notices_EndDateValidationMsg'];
        }

        if (dateFrom < currentDate) {
            validationFailedMessage += this.props.translations.Phrases['Notices_StartDateValidationMsg'];
        }

        //Check service provider
        if (isNullOrWhitespace(notice.ServiceProvider) || notice.ServiceProvider === 'Select application') {
            validationFailedMessage += this.props.translations.Phrases['Notices_RequiredApplication'];
        }

        //Check for date from greater than Date To
        if (dateFrom > dateTo) {
            validationFailedMessage += this.props.translations.Phrases['Notices_StartEndDateValidationMsg'];
        }

        //Check for null content and title
        if (isNullOrWhitespace(notice.Content)) {
            validationFailedMessage += this.props.translations.Phrases['Notices_RequiredBodyText'];
        }

        if (isNullOrWhitespace(notice.Title)) {
            validationFailedMessage += this.props.translations.Phrases['Notices_RequiredTitle'];
        }

        //Check for null notice type
        if (isNullOrWhitespace(notice.NoticeType) || notice.NoticeType === 'Select notice type') {
            validationFailedMessage += this.props.translations.Phrases['Notices_RequiredNoticeType'];
        }

        //Start date and time can not be same as end date and time
        if (
            notice.EffectiveDatePartFrom === notice.EffectiveDatePartTo &&
            notice.EffectiveTimePartFrom === notice.EffectiveTimePartTo &&
            notice.EffectiveTimePartFromAmPm === notice.EffectiveTimePartToAmPm
        ) {
            validationFailedMessage += this.props.translations.Phrases['Notices_StartEndDateErrMsg'];
        }

        if (!isNullOrWhitespace(validationFailedMessage)) {
            toastr.options.timeOut = 10000;
            toastr.error(validationFailedMessage);
            $('#overlay').hide();
            toastr.options.timeOut = 5000;
            return false;
        } else {
            return true;
        }
    };

    validateDateRequired = (datePart, timePart, startEnd) => {
        let validationFailedMessage = '';
        if (!datePart) {
            validationFailedMessage += startEnd + this.props.translations.Phrases['Notices_RequiredDate'];
        }
        if (!timePart) {
            validationFailedMessage += startEnd + this.props.translations.Phrases['Notices_RequiredTime'];
        }
        return validationFailedMessage;
    };

    renderEndDate() {
        return (
            <DateTimePicker
                date={this.props.notice ? this.props.notice.EffectiveDatePartTo : ''}
                time={this.props.notice ? this.props.notice.EffectiveTimePartTo : ''}
                amPm={this.props.notice ? this.props.notice.EffectiveTimePartToAmPm : ''}
                dateLabel={this.props.translations.Phrases['EndDate_Label']}
                timeLabel={this.props.translations.Phrases['EndTime_Label']}
                datePlaceholder={this.props.translations.Phrases['Date_Format']}
                timePlaceholder={this.props.translations.Phrases['Time_Format']}
                dateName={'NoticesList.' + this.props.index + '.EffectiveDatePartTo'}
                timeName={'NoticesList.' + this.props.index + '.EffectiveTimePartTo'}
                amPmName={'NoticesList.' + this.props.index + '.EffectiveTimePartToAmPm'}
                onDateChanged={this.props.onChangeEffectiveDatePartTo}
                onTimeChanged={this.props.onChangeEffectiveTimePartTo}
                onAmPmChanged={this.props.onChangeEffectiveTimePartToAmPm}
                noBorder
                noHeader
            ></DateTimePicker>
        );
    }

    renderStartDate() {
        return (
            <DateTimePicker
                date={this.props.notice ? this.props.notice.EffectiveDatePartFrom : ''}
                time={this.props.notice ? this.props.notice.EffectiveTimePartFrom : ''}
                amPm={this.props.notice ? this.props.notice.EffectiveTimePartFromAmPm : ''}
                dateLabel={this.props.translations.Phrases['StartDate_Label']}
                timeLabel={this.props.translations.Phrases['StartTime_Label']}
                datePlaceholder={this.props.translations.Phrases['Date_Format']}
                timePlaceholder={this.props.translations.Phrases['Time_Format']}
                dateName={'NoticesList.' + this.props.index + '.EffectiveDatePartFrom'}
                timeName={'NoticesList.' + this.props.index + '.EffectiveTimePartFrom'}
                amPmName={'NoticesList.' + this.props.index + '.EffectiveTimePartFromAmPm'}
                onDateChanged={this.props.onChangeEffectiveDatePartFrom}
                onTimeChanged={this.props.onChangeEffectiveTimePartFrom}
                onAmPmChanged={this.props.onChangeEffectiveTimePartFromAmPm}
                noBorder
                noHeader
            ></DateTimePicker>
        );
    }

    renderServiceProviderDropdown() {
        const isReadonly = this.props.notice && this.props.notice.LookupKey ? true : false;

        return (
            <Dropdown
                name={'NoticesList.' + this.props.index + '.ServiceProvider'}
                options={this.props.serviceProviders}
                label={this.props.translations.Phrases['ManageNotices_Application']}
                readonly={isReadonly}
                onItemChanged={this.props.onSelectServiceProvider}
                valueField='Text'
                textField='Text'
            />
        );
    }

    renderNoticeTypeDropdown() {
        return (
            <Dropdown
                name={'NoticesList.' + this.props.index + '.NoticeType'}
                options={this.props.noticeTypes}
                label={this.props.translations.Phrases['ManageNotices_NoticeType']}
                onItemChanged={this.props.onSelectNoticeType}
                valueField='Value'
                textField='Text'
            />
        );
    }

    renderLanguageDropdown() {
        return (
            <Dropdown
                name={'NoticesList.' + this.props.index + '.Language'}
                options={this.props.languages}
                label={this.props.translations.Phrases['ManageNotices_Language']}
                onItemChanged={this.props.onSelectLanguage}
                valueField='Value'
                textField='Text'
            />
        );
    }

    renderNoticeTitle() {
        return (
            <TextBox
                value={this.props.notice ? this.props.notice.Title : ''}
                name={'NoticesList.' + this.props.index + '.Title'}
                label={this.props.translations.Phrases['ManageNotices_NoticeTitle']}
                onValueChanged={this.props.onChangeTitle}
            ></TextBox>
        );
    }

    renderNoticeContent() {
        return <DefaultEditor value={this.props.notice ? this.props.notice.Content : ''} onChange={this.onChangeEditorContent} />;
    }

    //Not OK
    renderButtonArea() {
        return (
            <div className='col-sm-12'>
                <button type='button' className='button white small' onClick={() => this.props.onCloseManageNoticesArea()}>
                    {this.props.translations.Phrases['ManageNotices_Cancel']}
                </button>
                <button type='button' className='button white small' onClick={() => this.props.onOpenNoticePreview(this.props.notice)}>
                    {this.props.translations.Phrases['ManageNotices_Preview']}
                </button>
                <button type='button' className='button blue small' onClick={() => this.saveNotice(this.props.notice)}>
                    {this.props.translations.Phrases['ManageNotices_Save']}
                </button>
            </div>
        );
    }

    getMainTitle() {
        return this.props.action === 'edit'
            ? this.props.translations.Phrases['ManageNotices_EditNoticeTitle']
            : this.props.translations.Phrases['ManageNotices_CreateNotice'];
    }

    render() {
        return (
            <React.Fragment>
                <div className='container'>
                    <div className='manage-notices-area'>
                        <div className='row'>
                            <div className='manage-notices-elements title-section col-sm-12'>{this.getMainTitle()}</div>
                        </div>
                        <div className='manage-notices-elements col-sm-6'>{this.renderStartDate()}</div>
                        <div className='manage-notices-elements col-sm-6'>{this.renderEndDate()}</div>
                        <div className='row'>
                            <div className='manage-notices-elements col-sm-6'>{this.renderServiceProviderDropdown()}</div>
                            <div className='manage-notices-elements col-sm-6'>{this.renderNoticeTypeDropdown()}</div>
                        </div>
                        <div className='row'>
                            <div className='manage-notices-elements col-sm-6'>{this.renderLanguageDropdown()}</div>
                        </div>
                        <div className='row'>
                            <div className='manage-notices-elements col-sm-12'>{this.renderNoticeTitle()}</div>
                        </div>
                        <div className='row'>
                            <div className='manage-notices-elements col-sm-12'>{this.renderNoticeContent()}</div>
                        </div>
                        <div className='button-area'>{this.renderButtonArea()}</div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isNoticePreviewOpen: state.notices.isNoticePreviewOpen,
        notice: state.notices.selectedNotice,
        index: state.notices.selectedIndex,
        action: state.notices.action,
        serviceProviders: state.notices.model.ServiceProvidersList,
        noticeTypes: state.notices.model.NoticeTypesList,
        languages: state.notices.model.LanguagesList,
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseManageNoticesArea: () => dispatch(actionCreators.closeManageNoticesArea()),
        onOpenNoticePreview: (notice) => dispatch(actionCreators.openNoticePreview(notice)),
        onSelectServiceProvider: (newIndex, oldIndex, newValue) =>
            dispatch(actionCreators.selectServiceProvider(newIndex, oldIndex, newValue)),
        onSelectNoticeType: (newIndex, oldIndex, newValue) => dispatch(actionCreators.selectNoticeType(newIndex, oldIndex, newValue)),
        onSelectLanguage: (newIndex, oldIndex, newValue) => dispatch(actionCreators.selectLanguage(newIndex, oldIndex, newValue)),
        onChangeEffectiveDatePartFrom: (date) => dispatch(actionCreators.changeEffectiveDatePartFrom(date)),
        onChangeEffectiveDatePartTo: (date) => dispatch(actionCreators.changeEffectiveDatePartTo(date)),
        onChangeEffectiveTimePartFrom: (time) => dispatch(actionCreators.changeEffectiveTimePartFrom(time)),
        onChangeEffectiveTimePartTo: (time) => dispatch(actionCreators.changeEffectiveTimePartTo(time)),
        onChangeEffectiveTimePartFromAmPm: (value) => dispatch(actionCreators.changeEffectiveTimePartFromAmPm(value)),
        onChangeEffectiveTimePartToAmPm: (value) => dispatch(actionCreators.changeEffectiveTimePartToAmPm(value)),
        onChangeTitle: (title) => dispatch(actionCreators.changeTitle(title)),
        onChangeContent: (content) => dispatch(actionCreators.changeContent(content)),
        onNoticeSave: (notice, successMessage) => {
            dispatch(actionCreators.saveNotice(notice, successMessage));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageNoticesArea);
