// Shipment Milestones
const USShipmentMilestones = {
    1: { eventKey: 'CusInfoShipmentCreated', label: 'DocumentsReceived_Label', milestoneEventKey: 'DocsReceivedDateTime' },
    2: { eventKey: 'CusInfoReleaseAccepted', label: 'CustomsCertified_Label', milestoneEventKey: 'ReleaseAcceptedOn' },
    3: { eventKey: 'CusInfoShipmentReleased', label: 'CustomsReleased_Label', milestoneEventKey: 'CustomsReleasedOn' },
    4: { eventKey: 'CusInfoONEUSGReceived', label: 'OneUsgReceived_Label', milestoneEventKey: 'OneUsgReceivedOn' },
    5: { eventKey: 'CusInfoEntryAccepted', label: 'EntrySummaryAccepted_Label', milestoneEventKey: 'EntryAcceptedOn' }
};

const CAShipmentMilestones = {
    1: { eventKey: 'CusInfoShipmentCreated', label: 'DocumentsReceived_Label', milestoneEventKey: 'DocsReceivedDateTime' },
    2: { eventKey: 'CusInfoReleaseSubmitted', label: 'ReleaseSubmitted_Label', milestoneEventKey: 'ReleaseSubmittedOn' },
    3: { eventKey: 'CusInfoReleaseAccepted', label: 'CustomsAccepted_Label', milestoneEventKey: 'ReleaseAcceptedOn' },
    4: { eventKey: 'CusInfoShipmentReleased', label: 'CustomsReleased_Label', milestoneEventKey: 'CustomsReleasedOn' },
    5: { eventKey: 'CusInfoEntryAccepted', label: 'EntryAccepted_Label', milestoneEventKey: 'EntryAcceptedOn' }
};

export const ShipmentMilestones = {
    Canada: CAShipmentMilestones,
    USA: USShipmentMilestones
};

export type CargoData = {
    EntryNumber: string;
    CargoControlNumber: string;
    ClearanceStatus: string;
    Milestone: string;
    customsInfoID: number | null | undefined;
};
