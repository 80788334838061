export const SET_NOTICES = 'SET_NOTICES';
export const ADD_NOTICE = 'ADD_NOTICE';
export const EDIT_NOTICE = 'EDIT_NOTICE';
export const CLOSE_MANAGE_NOTICES_AREA = 'CLOSE_MANAGE_NOTICES_AREA';
export const OPEN_DELETE_NOTICE_MODAL = 'OPEN_DELETE_NOTICE_MODAL';
export const CLOSE_DELETE_NOTICE_MODAL = 'CLOSE_DELETE_NOTICE_MODAL';
export const DELETE_NOTICE = 'DELETE_NOTICE';
export const OPEN_NOTICE_PREVIEW = 'OPEN_NOTICE_PREVIEW';
export const CLOSE_NOTICE_PREVIEW = 'CLOSE_NOTICE_PREVIEW';
export const SELECT_SERVICE_PROVIDER = 'SELECT_SERVICE_PROVIDER';
export const SELECT_NOTICE_TYPE = 'SELECT_NOTICE_TYPE';
export const SELECT_LANGUAGE = 'SELECT_LANGUAGE';
export const CHANGE_EFFECTIVE_DATE_PART_FROM = 'CHANGE_EFFECTIVE_DATE_PART_FROM';
export const CHANGE_EFFECTIVE_DATE_PART_TO = 'CHANGE_EFFECTIVE_DATE_PART_TO';
export const CHANGE_EFFECTIVE_TIME_PART_FROM = 'CHANGE_EFFECTIVE_TIME_PART_FROM';
export const CHANGE_EFFECTIVE_TIME_PART_TO = 'CHANGE_EFFECTIVE_TIME_PART_TO';
export const CHANGE_EFFECTIVE_TIME_PART_FROM_AM_PM = 'CHANGE_EFFECTIVE_TIME_PART_FROM_AM_PM';
export const CHANGE_EFFECTIVE_TIME_PART_TO_AM_PM = 'CHANGE_EFFECTIVE_TIME_PART_TO_AM_PM';
export const CHANGE_CONTENT = 'CHANGE_CONTENT';
export const CHANGE_TITLE = 'CHANGE_TITLE';
export const SAVE_NOTICE = 'SAVE_NOTICE';
export const RESET_NOTICES_STATE = 'RESET_NOTICES_STATE';
