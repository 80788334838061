import { Component, React } from 'react';
import { connect } from 'react-redux';
import { dashboard } from '../../constants/mlp-constants';

class WidgetContent extends Component {
    widgetIframeRef = React.createRef();

    openInNewWindow = (url) => {
        window.open(url);
    };

    onIframeLoad = () => {
        // used to prevent page scroll caused by focusing on iframe inputs when content is loaded
        // initial iframe display should be set to none
        // iframe needs a ref so the DOM element can be retrieved
        // set iframe display to block once it's content has loaded
        this.widgetIframeRef.current.getDOMNode().style.display = 'block';
    };
    componentWillUpdate = (nextProps, nextState) => {
        // force display none on each re-render, React will not update it automatically
        // only do this if widget params changed, meaning the frame needs to be reloaded

        if (this.props.widgetParams !== nextProps.widgetParams) {
            this.widgetIframeRef.current.getDOMNode().style.display = 'none';
        }
    };

    render() {
        const widget = this.props.data;
        let content = [];
        const widgetUrl = widget.Url + this.props.widgetParams;
        const alternateWidgetUrl = widget.PopOutUrl + this.props.widgetParams;

        let reportLink = <div />;
        let reportURL = <div />;
        switch (widget.RenderType) {
            case dashboard.RENDER_TYPE_TAB:
                reportLink = (
                    <div className='widget-popout' onClick={this.openInNewWindow.bind(this, alternateWidgetUrl)}>
                        <i className='fa fa-external-link-square fa-2x' />
                    </div>
                );
                break;
            case dashboard.RENDER_TYPE_LINK:
                reportURL = '/Reports/Reports/GetReport?widgetID=' + widget.Id;
                reportLink = (
                    <div className='widget-popout'>
                        <a className='fa fa-external-link-square fa-2x' href={reportURL}>
                            {' '}
                        </a>
                    </div>
                );
                break;
            case dashboard.RENDER_WIDGET_ONLY:
                reportURL = <div />;
                break;
            default:
                break;
        }

        content = (
            <iframe
                title='widgetIframe'
                src={widgetUrl}
                frameBorder='0'
                style={{ display: 'none' }}
                onLoad={this.onIframeLoad}
                ref={this.widgetIframeRef}
                className='iframe'
                sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
            ></iframe>
        );

        return (
            <div className='widget-content'>
                {content}
                {reportLink}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(WidgetContent);
