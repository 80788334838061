import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Modal extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        onRequestClose: PropTypes.func
    };

    static defaultProps = {
        isOpen: false
    };

    render() {
        return (
            <div
                className={this.props.isOpen ? 'modal modal-overlay d-block pointer' : 'modal fade d-none'}
                id='menu-expander'
                aria-hidden='false'
                onClick={this.props.onRequestClose}
                width='100%'
                height='100%'
            ></div>
        );
    }
}
export default Modal;
