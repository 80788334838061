import React, { Component } from 'react';
import { connect } from 'react-redux';
import IconCa from '../../assets/images/ca-icon.png';
import IconUs from '../../assets/images/us-icon.png';
import { clientChooser as Constants } from '../../constants/mlp-constants';
import ChooserContent from './ChooserContent';

class ChooserControl extends Component {
    state = {
        chooserIsOpen: false
    };

    updateClientServiceSelection = (data) => {
        this.props.onConfirm(data);
    };

    closeChooser = () => {
        this.setState({ chooserIsOpen: false });
    };

    openChooser = () => {
        if (this.props.data.Countries && this.props.data.Countries.length > 0) {
            this.setState({ chooserIsOpen: true });
        }
    };

    chooseCountryIcon = () => {
        if (!this.props.data.Countries || this.props.data.Countries.length <= 0) return '';
        const country = this.props.data.Countries.find((c) => c.IsSelected);

        switch (country.Code) {
            case Constants.COUNTRY_CODE_US:
                return IconUs;
            case Constants.COUNTRY_CODE_CA:
                return IconCa;
            default:
                return '';
        }
    };

    getDisplayText = () => {
        if (!this.props.data.Countries || this.props.data.Countries.length <= 0) return this.props.translations.Phrases['No_Clients_Label'];
        const country = this.props.data.Countries ? this.props.data.Countries.find((c) => c.IsSelected === true) : '';
        const countryServices = this.props.data.ClientServices
            ? this.props.data.ClientServices.filter((cs) => cs.CountryCode === country.Code)
            : '';
        const selectedCount = countryServices ? countryServices.filter((cs) => cs.IsSelected === true).length : 0;
        const countCountryServices = countryServices ? countryServices.length : '';
        return selectedCount + '/' + countCountryServices + ' ' + this.props.translations.Phrases['Clients_Selected_Label'];
    };
    render() {
        const chooserContent = (
            <ChooserContent
                isOpen={this.state.chooserIsOpen}
                closeModal={this.closeChooser}
                groupingAttribute={this.props.groupingAttribute}
                data={this.props.data}
                onRequestConfirm={this.updateClientServiceSelection}
            />
        );
        const chooserIconSrc = this.chooseCountryIcon();
        const chooserIcon = chooserIconSrc === '' ? '' : <img width='15' alt='' height='15' src={chooserIconSrc} />;
        const chooserDisplayText = this.getDisplayText();

        return (
            <div>
                <div id='client-nummber-chooser-container' onClick={this.openChooser}>
                    <span>{chooserIcon}</span>
                    <span>{chooserDisplayText}</span>
                </div>
                {this.state.chooserIsOpen && chooserContent}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};
export default connect(mapStateToProps)(ChooserControl);
