// Used to allow the user to scroll horizontally on table without scrolling all the way to the bottom to use scrollbar See example on AllClearances.js (Tenplates - Clearances - All clearances)
// If the table doesn't have a pagination bar add a <div id='empty-bottom-container'></div> element at the bottom of the page. See PortalSingleClearanceTabs.js (Tenplates - Clearances - Portal single clearance tabs) as an example

import { useEffect } from 'react';

export default function useKendoStickyScrollbar(tableRef: any) {
    // Hide sticky scrollbar when table scrollbar comes into view for tables with and without pagination
    useEffect(() => {
        if (!tableRef.current) {
            return;
        }

        const pagination = tableRef.current.querySelector('.k-pager-wrap');
        const stickyScrollbar = tableRef.current.querySelector('.sticky-scrollbar');
        const table = tableRef.current.querySelector('.k-grid-content');

        if (!table) {
            return;
        }

        //Hide/show sticky scrollbar when the tables scrollbar comes into view/moves out of view
        const observerCheck = (entries: any) => {
            if (entries[0].isIntersecting) {
                stickyScrollbar.style.visibility = 'hidden';
                stickyScrollbar.style.height = '0px';
            } else {
                // Sets the sticky scrollbar position to that of the tables position when the tables scrollbar goes out of view.
                stickyScrollbar.scrollLeft = table.scrollLeft;
                stickyScrollbar.style.visibility = 'visible';
                stickyScrollbar.style.height = 'auto';
            }
        };

        if (pagination) {
            const observer = new IntersectionObserver(observerCheck, { threshold: [0] });
            observer.observe(pagination);

            return () => {
                observer.unobserve(pagination);
            };
        } else {
            // Add 'empty-bottom-container' on bottom of page with table when table doesn't have pagination bar
            const emptyBottomContainer = document.getElementById('empty-bottom-container');
            if (!emptyBottomContainer) {
                return;
            }
            const observer = new IntersectionObserver(observerCheck, { threshold: [0] });

            observer.observe(emptyBottomContainer);

            return () => {
                observer.unobserve(emptyBottomContainer);
            };
        }
    }, [tableRef.current]);

    //Set Sticky scrollbar
    useEffect(() => {
        if (!tableRef.current) {
            return;
        }

        const stickyScrollbar = tableRef.current.querySelector('.sticky-scrollbar');
        const tableHeadercolumns = tableRef.current.querySelectorAll('.k-header');
        const table = tableRef.current.querySelector('.k-grid-content');

        if (!table) {
            return;
        }

        // Calc column resize to adjust sticky scrollbar size
        const resizeObserver = new ResizeObserver(() => {
            handleStickyScrollWidth();
        });
        tableHeadercolumns.forEach((el: any) => {
            resizeObserver.observe(el);
        });

        const onScrollStickyHandler = () => {
            table.scrollLeft = stickyScrollbar.scrollLeft;
        };

        // Sticky scrollbar listener - sets the table scrollbar to the position of sticky scrollbar
        stickyScrollbar.addEventListener('scroll', onScrollStickyHandler);

        // Set sticky scrollbar width
        const handleStickyScrollWidth = () => {
            if (!tableRef.current) {
                return;
            }

            const emptyElement = tableRef.current.querySelector('.empty-element');

            if (!emptyElement) {
                return;
            } else {
                emptyElement.style.width = `${table.scrollWidth}px`;
            }
        };

        handleStickyScrollWidth();

        // Update sticky scrollbar size on window resize
        window.addEventListener('resize', handleStickyScrollWidth);

        return () => {
            //Stop listners and observers
            window.removeEventListener('resize', handleStickyScrollWidth);
            stickyScrollbar.removeEventListener('scroll', onScrollStickyHandler);

            tableHeadercolumns.forEach((el: any) => {
                resizeObserver.unobserve(el);
            });
        };
    }, [tableRef.current]);

    return useKendoStickyScrollbar;
}
