import { useEffect } from 'react';
import Spinner from '../../../../../common/features/Spinner';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { DETAILS_LABELS } from '../../../common/constants/detailsLabelsMap';
import { configFilters } from '../../../common/functions/documentModelFunctions';
import { ShipmentHeader } from '../../../common/models/ShipmentHeader';
import { GetShipmentHeader } from '../../../redux/actions/clearanceDocuments';
import { GetDocumentsIndex } from '../../../redux/actions/shipmentList';
import { useClearancesDispatch, useClearancesSelector } from '../../../redux/hooks';
import { resetHeader } from '../../../redux/reducers/documentsSlice';
import NoDataResponse from '../lineDetails/NoDataResponse';
import { reorderHeaderDetails } from './reorderHeaderDetails';

interface HeaderDetailsProps {
    entryNo: string;
}

const HeaderDetails: React.FC<HeaderDetailsProps> = ({ entryNo }) => {
    const { headerDetails, isLoadingHeader } = useClearancesSelector((state) => state.clearanceDocuments);
    const { documentsIndex } = useClearancesSelector((state) => state.shipmentsWithTotals);
    const reorderedHeaderDetails = headerDetails ? reorderHeaderDetails(headerDetails) : null;
    const dispatch = useClearancesDispatch();
    const translate = useTranslation();

    useEffect(() => {
        if (!documentsIndex) {
            dispatch(GetDocumentsIndex());
        }
    }, [dispatch, documentsIndex]);

    useEffect(() => {
        if (!entryNo || !documentsIndex) return;

        dispatch(
            GetShipmentHeader({
                vm: {
                    ...documentsIndex,
                    Filters: configFilters(documentsIndex, [entryNo])!
                }
            })
        );
    }, [dispatch, documentsIndex, entryNo]);

    useEffect(() => {
        return () => {
            dispatch(resetHeader());
        };
    }, [dispatch]);

    // Loading state
    if (isLoadingHeader)
        return (
            <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                <Spinner />
            </div>
        );

    if (!isLoadingHeader && !headerDetails?.ClientNumber)
        return (
            <div className='container-fluid d-flex flex-column justify-content-center align-items-center flex-fill mb-5'>
                <NoDataResponse labelToDisply='HeaderNoDataFound_Label' />
            </div>
        );

    return (
        <div className='d-flex flex-column align-items-end overflow-auto'>
            <div className='table-responsive' id='scrollTable'>
                <table className='table w-100 header-details-table input-row-overflow'>
                    <thead>
                        <tr className={'border-bottom shadow-sm'}>
                            <th scope='col' className={'font-weight-bold border-right'}>
                                {translate('HeaderDetails_Label')}
                            </th>
                            <th scope='col'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {reorderedHeaderDetails &&
                            Object.keys(reorderedHeaderDetails).map((key) => (
                                <tr key={key}>
                                    <td className={'font-weight-bold bg-white border-right'}>{translate(DETAILS_LABELS?.[key])}</td>
                                    <td>
                                        {[null, ''].includes(reorderedHeaderDetails[key as keyof ShipmentHeader])
                                            ? '-'
                                            : reorderedHeaderDetails[key as keyof ShipmentHeader]}
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default HeaderDetails;
