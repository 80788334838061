interface LinearProgressBarProps {
    heightPixels: number;
    progressPercent: number;
    showPercent?: boolean;
    rounded?: boolean;
}

const LinearProgressBar: React.FC<LinearProgressBarProps> = ({ heightPixels, progressPercent, rounded = false, showPercent = false }) => {
    return (
        <div>
            <div className={`progress bg-info-light ${!rounded && 'rounded-0'} `} style={{ height: `${heightPixels}px` }}>
                <div className='progress-bar bg-primary' style={{ width: `${progressPercent}%` }}>
                    {showPercent && `${progressPercent}%`}
                </div>
            </div>
        </div>
    );
};

export default LinearProgressBar;
