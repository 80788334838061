import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { Document } from '../../../../../common/models/ClearanceDocuments';
import { DownloadDocuments } from '../../../../clearances/redux/actions/clearanceDocuments';
import { useDocumentsDispatch } from '../../../redux/hooks';

interface DataItem extends Document {
    selected?: boolean;
}

interface FormattedGridCellProps extends GridCellProps {
    dataItem: DataItem;
    entryNo: string;
    country: 'us' | 'ca';
    onCheckboxClick: (param: string) => void;
}

const FormattedGridCell: React.FC<FormattedGridCellProps> = ({
    field = '',
    dataItem,
    style,
    className,
    entryNo,
    country,
    onCheckboxClick
}) => {
    const dispatch = useDocumentsDispatch();
    let cellContent;

    const downloadButton = (document: Document) => {
        return (
            <button
                className='btn btn-tertiary btn-sm'
                type='button'
                onClick={() => dispatch(DownloadDocuments({ fileNames: { [entryNo]: [document.path] } }))}
                disabled={dataItem.selected}
            >
                <FontAwesomeIcon icon={faDownload} />
            </button>
        );
    };

    switch (field) {
        case 'documentType':
            break;
        case 'Version':
            if (country === 'ca') {
                cellContent = !dataItem.LIVERSION ? '-' : dataItem.LIVERSION;
            }
            break;
        case 'Download':
            cellContent = downloadButton(dataItem);
            break;
        case 'selected':
            cellContent = <Checkbox value={dataItem.selected} onChange={() => onCheckboxClick(dataItem.documentId!)} />;
            break;
        default:
            cellContent = null;
            break;
    }

    return (
        <td
            className={className}
            style={{
                ...style,
                backgroundColor: dataItem?.selected ? '#DDE9F9' : '',
                cursor: 'default',
                textOverflow: field === 'Download' ? 'unset' : 'ellipsis'
            }}
        >
            {cellContent}
        </td>
    );
};

export default FormattedGridCell;
