import { getter } from '@progress/kendo-data-query';
import { GridColumn as Column, Grid, GridCellProps, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import DownloadFooter from '../../../../../common/features/Grid/DownloadFooter';
import Spinner from '../../../../../common/features/Spinner';
import useGridLanguage from '../../../../../common/hooks/useGridLanguage';
import useTranslation from '../../../../../common/hooks/useTranslation';
import useTranslationHydration from '../../../../../common/hooks/useTranslationHydration';
import { Document } from '../../../../../common/models/ClearanceDocuments';
import { addViewImagesData } from '../../../../../redux/reducers/viewImagesSlice';
import { PartialGroupedDocuments } from '../../../../documents/common/models/ImagingModel';
import { DownloadDocuments, GetClearanceDocuments } from '../../../redux/actions/clearanceDocuments';
import { useClearancesDispatch, useClearancesSelector } from '../../../redux/hooks';
import { resetDocuments } from '../../../redux/reducers/documentsSlice';
import NoDataResponse from '../lineDetails/NoDataResponse';
import FormattedGridCell from './FormatedGridCell';

interface DocumentsGridProps {
    entryNo: string;
    dataState: ({ ['selected']: boolean } & Document)[];
    setDataState: React.Dispatch<React.SetStateAction<({ ['selected']: boolean } & Document)[]>>;
}

const DocumentsGrid: React.FC<DocumentsGridProps> = ({ entryNo, dataState, setDataState }) => {
    const DATA_ITEM_KEY: keyof Document = 'documentId';
    const idGetter = getter(DATA_ITEM_KEY);
    const SELECTED_FIELD = 'selected';
    const { documents, downloadData, isLoading, error } = useClearancesSelector((state) => state.clearanceDocuments);
    const { languageSelected: selectedLanguage } = useClearancesSelector((state) => state.language);
    const { shipmentDetails } = useClearancesSelector((state) => state.shipmentDetails);
    const { viewImages } = useClearancesSelector((state) => state.viewImages);
    const { initialClientSelection } = useClearancesSelector((state) => state.clientSelection);
    const gridLanguage = useGridLanguage(selectedLanguage);
    // Sets all checkboxes to false initially
    const [selectedState, setSelectedState] = useState<{ [key: string]: boolean }>({});
    const [headerCheckbox, setHeaderCheckbox] = useState(false);
    const [isFetched, setIsFetched] = useState(false);

    const dispatch = useClearancesDispatch();
    const translate = useTranslation();
    const translateHydration = useTranslationHydration();

    const country = initialClientSelection?.Countries.find((c) => c.IsSelected);

    const checkboxHandler = (id: string) => {
        setSelectedState((prevState) => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const GridCell = useCallback((props: GridCellProps) => {
        return <FormattedGridCell onCheckboxClick={checkboxHandler} country={country?.Code!} {...props} />;
    }, []);

    const HeaderCell = useCallback(
        (props: GridHeaderCellProps) => {
            const headerCheckboxHandler = () => {
                if (Object.keys(selectedState).filter((x) => selectedState[x] === false).length === 0) {
                    setSelectedState((prevState) =>
                        Object.keys(prevState).reduce(
                            (acc, curr) => ({
                                ...acc,
                                [curr]: false
                            }),
                            {}
                        )
                    );
                    setHeaderCheckbox(false);
                } else {
                    setSelectedState((prevState) =>
                        Object.keys(prevState).reduce(
                            (acc, curr) => ({
                                ...acc,
                                [curr]: prevState[curr] ? true : !prevState[curr]
                            }),
                            {}
                        )
                    );
                    setHeaderCheckbox(true);
                }
            };
            return <Checkbox value={headerCheckbox} onChange={headerCheckboxHandler} />;
        },
        [headerCheckbox, selectedState]
    );

    const downloadDocuments = () => {
        if (documents) {
            const selectedDocuments = documents.filter((document) => selectedState[document.documentId]);
            dispatch(
                DownloadDocuments({
                    fileNames: { [shipmentDetails?.EntryNumber || 'document']: selectedDocuments.map((document) => document.path) }
                })
            );
        }
    };

    const viewButtonClick = () => {
        if (!documents) return;

        const selectedDocuments = documents.filter((document) => selectedState[document.documentId]);

        dispatch(
            addViewImagesData({
                viewImages: [
                    {
                        entry: shipmentDetails?.EntryNumber || 'Entry',
                        documents: selectedDocuments?.map((d) => ({
                            _fileS3Path: d.path,
                            _metadataFormat: d.metadataFormat,
                            _imageDate: d.imageDate || '',
                            secondaryDescription: d?.USDOCUMENTTYPE ?? d?.LIDOCUMENTID,
                            version: d.LIVERSION || ''
                        }))
                    }
                ],
                openedLocation: 'documentsGrid'
            })
        );
    };

    useEffect(() => {
        const newSelectedState: { [key: string]: boolean } = {};
        documents?.forEach((document) => {
            newSelectedState[document.documentId] = false;
        });
        setSelectedState(newSelectedState);
    }, [documents]);

    useEffect(() => {
        if (documents && documents.length > 0) {
            setDataState(documents.map((item) => ({ ...item, [SELECTED_FIELD]: false })));
        }
    }, [documents]);

    useEffect(() => {
        setDataState(dataState.map((document) => ({ ...document, [SELECTED_FIELD]: selectedState[document.documentId] })));
    }, [selectedState]);

    useEffect(() => {
        if (Object.keys(selectedState).filter((x) => selectedState[x] === false).length === 0) {
            setHeaderCheckbox(true);
        } else {
            setHeaderCheckbox(false);
        }
    }, [selectedState, setSelectedState]);

    useEffect(() => {
        if (!isLoading && !error && !isFetched) {
            const filerCode = entryNo.substring(0, 4);
            const formattedEntryNo = entryNo.substring(4).replaceAll(/[^\w\s]/gi, ''); //this regex means all special characters
            dispatch(GetClearanceDocuments({ entryNo: filerCode + formattedEntryNo }));
            setIsFetched(true);
        }
    }, [isLoading, error, dispatch, isFetched, entryNo]);

    useEffect(() => {
        return () => {
            dispatch(resetDocuments());
        };
    }, [dispatch]);

    const isAnySelected = documents && documents.filter((document) => selectedState[document.documentId]).length >= 1;
    const selectedEntries = isAnySelected ? [entryNo] : [];

    const imagingData: PartialGroupedDocuments[] | null = documents
        ? [
              {
                  [entryNo]: documents.map((item) => ({
                      _pid: item.documentId,
                      //   _metadataFormat: item.documentType,
                      _imageDate: item.imageDate,
                      _metadataFormat: item.metadataFormat,
                      _fileS3Path: item.path
                  }))
              }
          ]
        : [];
    const handleDownloadDocumentsEvent = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'Doc_DNLD_EntryGrid_CLR_CPEvt',
                location: 'Clearances'
            }
        });
    };
    const handleTagEvent = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'Doc_Compare_EntryGrid_CLR_CPEvt',
                location: 'Clearances'
            }
        });
    };
    const handleViewDocumentsEvent = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'Doc_View_EntryGrid_CLR_CPEvt',
                location: 'Clearances'
            }
        });
    };

    if (!isLoading && !documents?.length) {
        return (
            <div className='container-fluid d-flex flex-column justify-content-center align-items-center flex-fill mb-5'>
                <NoDataResponse labelToDisply='NoDocumentsAvailable_Label' />
            </div>
        );
    }
    return (
        <>
            {!isLoading && (
                <LocalizationProvider language={gridLanguage.language}>
                    <IntlProvider locale={gridLanguage.locale}>
                        <Grid
                            data={
                                dataState &&
                                dataState.map((item) => ({
                                    ...item,
                                    [SELECTED_FIELD]: selectedState[idGetter(item)]
                                }))
                            }
                            className={country?.Code === 'ca' ? 'documents-table entry-documents-table flex-fill' : ''}
                            total={dataState ? dataState.length : 0}
                            dataItemKey={DATA_ITEM_KEY}
                            selectedField={SELECTED_FIELD}
                            selectable={{
                                enabled: false,
                                drag: false,
                                cell: false,
                                mode: 'multiple'
                            }}
                        >
                            <Column field={SELECTED_FIELD} cell={GridCell} headerCell={HeaderCell} />
                            <Column field='Document type' title={translate('DocumentType_Label')} cell={GridCell} width={450} />
                            {country?.Code === 'ca' && <Column field='Version' title={translate('Version_Label')} cell={GridCell} />}
                            <Column
                                field='Image date'
                                title={translate('ImageDate_Label')}
                                cell={GridCell}
                                width={country?.Code !== 'ca' ? '350' : ''}
                            />
                            <Column field='Actions' title={translate('Actions_Label')} cell={GridCell} />
                        </Grid>
                    </IntlProvider>
                </LocalizationProvider>
            )}

            {isLoading && (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}

            {Object.entries(selectedState).filter(([key, value]) => value === true).length > 0 && viewImages?.length === 0 && (
                <DownloadFooter
                    amountLabel={`${Object.entries(selectedState).filter(([key, value]) => value === true).length} ${translate(
                        'DocumentsSelected_Label'
                    )}`}
                    amountSelected={Object.entries(selectedState).filter(([key, value]) => value === true).length}
                    primaryButtonLabel={translate('DownloadSelected_Label')}
                    disablePrimaryButton={isLoading}
                    onPrimary={() => {
                        downloadDocuments();
                        handleDownloadDocumentsEvent();
                    }}
                    viewButtonLabel={translateHydration('ViewDocumentsButtonFooter_Label')}
                    onView={() => {
                        viewButtonClick();
                        handleViewDocumentsEvent();
                    }}
                    compareButtonLabel={translateHydration('CompareDocumentsButtonFooter_Label')}
                    handleTagEvent={handleTagEvent}
                />
            )}

            {/* Status alert for documents */}
            {/* <DocumentStatusAlert /> */}
        </>
    );
};

export default DocumentsGrid;
