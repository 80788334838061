import ReactDOM from 'react-dom';
import Button from '../../../../../common/features/Button';

interface DeleteContactsFooterProps {
    amountLabel: string;
    primaryButtonLabel: string;
    disablePrimaryButton?: boolean;
    onPrimary: () => void;
}

const DeleteContactsFooter: React.FC<DeleteContactsFooterProps> = ({
    amountLabel,
    primaryButtonLabel,
    disablePrimaryButton,
    onPrimary
}) => {
    const parentContainer = document.querySelector('.navbar-and-stage-wrapper') ?? document.body;

    return ReactDOM.createPortal(
        <div id='FixedFooter' className='bg-white shadow-lg'>
            <div className='container-fluid'>
                <div className='col'>
                    <div className='d-flex flex-column flex-md-row align-items-md-center align-items-stretch justify-content-md-end fixed-bar-without-sidebar py-3'>
                        <div className='helper-text mr-md-auto mb-3 mb-md-0 mt-md-0'>{amountLabel}</div>

                        <div className='d-flex flex-column flex-sm-row flex-shrink-0'>
                            <Button variant='danger' size='medium' onClick={onPrimary} disabled={disablePrimaryButton}>
                                {primaryButtonLabel}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        parentContainer
    );
};

export default DeleteContactsFooter;
