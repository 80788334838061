import { faBars, faBell, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import { usePortalDispatch, usePortalSelector } from '../../../redux/hooks';
import { readAlerts } from '../../../redux/reducers/alertsSlice';
import { HeaderNavItems, TabNavigationIds } from '../../constants/constants-portal';
import useDropdownToggle from '../../hooks/useDropdownToggle';
import useGTMEventTracking from '../../hooks/useGTMEventTracking';
import useTranslationHydration from '../../hooks/useTranslationHydration';
import Badge from '../Badge';
import Button from '../Button';
import DropdownMenuButton from '../DropdownMenuButton';
import { UserProfileImage } from '../UserProfileImage';
import HeaderNavItem from './HeaderNavItem';
import WaffleDraggableDropdown from './WaffleMenuDropdown/WaffleDraggableDropdown';

interface HeaderNavProps {
    onToggleSidebar: () => void;
    onToggleAlerts: () => void;
}

declare global {
    interface Window {
        dataLayer: any[];
        gtag: (key: string, action: string, options: { [key: string]: any }) => void;
    }
}

const HeaderNav: React.FC<HeaderNavProps> = ({ onToggleSidebar, onToggleAlerts }) => {
    const { user } = usePortalSelector((state) => state.hydration);
    const { unreadAlerts } = usePortalSelector((state) => state.alerts);
    const dispatch = usePortalDispatch();

    const translate = useTranslationHydration();

    const test = useRef(null);

    useGTMEventTracking('button_click', { location: 'MyComponent' }, test);

    const dropdownMenuRef = useRef(null);
    const { isOpen, handleToggle } = useDropdownToggle(dropdownMenuRef);

    const handleAlerts = () => {
        const alertsReadTime = new Date().toISOString();
        localStorage.setItem('alertsReadTime', alertsReadTime);
        dispatch(readAlerts(alertsReadTime));
        onToggleAlerts();
    };

    return (
        <nav className='navbar align-items-center navbar-light bg-white border-bottom' style={{ borderBottom: '1px solid #EFF2F4' }}>
            <Button variant='ghost-dark' onClick={onToggleSidebar}>
                <FontAwesomeIcon icon={faBars} />
            </Button>
            <div className='d-flex align-items-center justify-center'>
                <WaffleDraggableDropdown />
                {/* Header bell icon button */}
                <div className='position-relative'>
                    {unreadAlerts > 0 ? (
                        <Badge
                            variant='alert'
                            style={{ position: 'absolute', right: '-3px', top: '-3px', cursor: 'pointer', zIndex: 1 }}
                            onClick={handleAlerts}
                        >
                            {unreadAlerts}
                        </Badge>
                    ) : null}
                    <Button
                        variant='tertiary'
                        className='btn btn-tertiary rounded-circle image-40px position-relative mx-1'
                        onClick={handleAlerts}
                    >
                        <FontAwesomeIcon icon={faBell} />
                    </Button>
                </div>
                {/* Header navigation */}
                <DropdownMenuButton
                    childrenDivClassName='dropdown-menu dropdown-menu-right w-300px w-sm-400px'
                    btnClassNameSecondary='btn btn-tertiary dropdown-toggle d-flex flex-row align-items-center'
                    id='userManagementDropdown'
                    label={user?.UserName === undefined ? `My account` : user?.UserName}
                    isOpen={isOpen}
                    onClick={handleToggle}
                    ref={dropdownMenuRef}
                    inlineIcon={faUserCircle}
                >
                    <span className='dropdown-item-text profile-name d-flex justify-content-between align-items-center'>
                        <div className='mr-3'>
                            <UserProfileImage style={{ width: '25px', height: '25px' }} />
                        </div>
                        <div className='text-truncate'>{user?.Email}</div>
                    </span>
                    <div className='dropdown-divider'></div>
                    {HeaderNavItems.map((item) => {
                        if (item.id === 'editClientAdmin' || (user?.InternalUser && item.id === 'team')) return null;

                        return (
                            <HeaderNavItem
                                key={item.id}
                                icon={item?.icon}
                                title={translate(item.label)}
                                url={item.url}
                                onClick={() => {
                                    if (item.id === 'profile') localStorage.setItem('activeTab', TabNavigationIds.Profile[0]);
                                }}
                            />
                        );
                    })}
                </DropdownMenuButton>
            </div>
        </nav>
    );
};
export default HeaderNav;
