import { PayloadAction } from '@reduxjs/toolkit';
import { ColumnSettings } from '../../../../common/models/GridPreferences';
import {
    createGenericGridPreferencesSlice,
    genericGridPreferencesInitialState
} from '../../../../redux/reducers/genericGridPreferencesSlice';
import { GetUserImagingGridSetting, SaveUserImagingGridSetting } from '../actions/imagingGridPreferences';

const imagingGridPreferencesSlice = createGenericGridPreferencesSlice({
    name: 'imagingGridPreferences',
    initialState: genericGridPreferencesInitialState,
    reducers: {
        updateColumnOrder(state, action: PayloadAction<ColumnSettings[]>) {
            state.allColumns = action.payload.map((item, i) => {
                return { ...item, Order: i + 1 };
            });
            state.visibleColumns = state.allColumns?.filter((item: ColumnSettings) => {
                if (item.IsVisible) {
                    return item;
                }
                return null;
            });
        },
        updateColumnOrderGrid(state, action: PayloadAction<ColumnSettings[]>) {
            state.allColumns = [...action.payload].filter((item: ColumnSettings) => {
                if (item.Code !== 'selected' && item.Code !== 'Actions') {
                    return item;
                }
                return null;
            });
            state.visibleColumns = state.allColumns?.filter((item: ColumnSettings) => {
                if (item.IsVisible) {
                    return item;
                }
                return null;
            });
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetUserImagingGridSetting.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetUserImagingGridSetting.fulfilled, (state, action) => {
                state.isLoading = false;
                state.allColumns = action.payload;
                state.visibleColumns = action.payload.filter((column) => column.IsVisible === true);
                state.columnsWidths = action.payload.map((column) => {
                    return { Code: column.Code, Width: column.Width };
                });
            })
            .addCase(GetUserImagingGridSetting.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(SaveUserImagingGridSetting.pending, (state, action) => {
                state.areColumnsLoading = true;
                state.error = undefined;
            })
            .addCase(SaveUserImagingGridSetting.fulfilled, (state, action) => {
                state.areColumnsLoading = false;
                state.hasOwnColumnsOrder = action.payload;
            })
            .addCase(SaveUserImagingGridSetting.rejected, (state, action) => {
                state.areColumnsLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const { updateColumnOrder, updateColumnOrderGrid, resetGridPreferences } = imagingGridPreferencesSlice.actions;

export default imagingGridPreferencesSlice.reducer;
