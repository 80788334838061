import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import Button from '../../../../../common/features/Button';
import ModalWrapper from '../../../../../common/features/Wrappers/ModalWrapper';
import { isAddContactFormValid } from '../../../../../common/functions/validators';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ContactsModals } from '../../../../../common/models/ContactsModals';
import { AddNewContacts } from '../../../redux/actions/contactsManagement';
import { useContactsDispatch, useContactsSelector } from '../../../redux/hooks';
import { resetAddNewContacts, resetNewContacts, setFormIsSubmitted } from '../../../redux/reducers/contactsSlice';
import ContactInfoForm from './ContactInfoForm';

interface AddContactsModalProps {
    modal: ContactsModals;
    toggleModal: (prop: keyof ContactsModals, next?: boolean) => void;
}

const AddContactsModal: React.FC<AddContactsModalProps> = ({ modal, toggleModal }) => {
    const { newContacts } = useContactsSelector((state) => state.contacts);
    const { user } = useContactsSelector((state) => state.hydration);
    const translate = useTranslation();
    const dispatch = useContactsDispatch();

    // On initial load refresh redux objects "formIsSumbitted" and "addNewContactsData"
    useEffect(() => {
        dispatch(setFormIsSubmitted({ value: false }));
        dispatch(resetAddNewContacts());
    }, [dispatch]);

    // Reset "newSubscribers" on unmount
    useEffect(() => {
        return () => {
            dispatch(resetNewContacts());
        };
    }, [dispatch]);

    const handleSubmit = () => {
        dispatch(setFormIsSubmitted({ value: true }));

        if (isAddContactFormValid(newContacts, user?.InternalUser)) {
            dispatch(
                AddNewContacts({
                    newContacts: newContacts.map((item) => ({
                        ...item,
                        ExternalContactID: 0,
                        CarrierCodes: item.RoleLabel.includes('Carrier') ? item.CarrierCodes : null
                    }))
                })
            );
            toggleModal('add', false);
        }
    };

    return (
        <>
            <ModalWrapper id='AddContactsModal' onEscape={() => toggleModal('cancelAdd', true)}>
                <div className='modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable'>
                    <div className='modal-content'>
                        {/* MODAL HEADER */}
                        <div className='modal-header'>
                            <div className='mr-4'>
                                <h3 className='mb-0'>{translate('AddContact_Label')}</h3>
                                <h4 className='font-weight-medium mb-0'>{translate('AddContactMessage_Label')}</h4>
                            </div>
                            <Button variant='tertiary' className='btn-lg' onClick={() => toggleModal('cancelAdd', true)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </Button>
                        </div>

                        <ContactInfoForm handleSubmit={handleSubmit} modal={modal} />
                    </div>
                </div>
            </ModalWrapper>
        </>
    );
};

export default AddContactsModal;
