import { createSlice } from '@reduxjs/toolkit';
import { DocumentsSlice } from '../../common/models/ReduxSlices';
import {
    DownloadDocuments,
    ExportHeaderAndDetail,
    GetClearanceDocuments,
    GetLineDetails,
    GetShipmentHeader
} from '../actions/clearanceDocuments';

const initialState: DocumentsSlice = {
    lineDetails: null,
    headerDetails: null,
    documents: null,
    downloadData: null,
    isLoading: false,
    isLoadingDownload: false,
    isLoadingLineDetails: false,
    isLoadingHeader: false,
    error: undefined,
    renderCompareModal: false
};

const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        resetDownloadData(state) {
            state.downloadData = initialState.downloadData;
        },
        resetDocuments(state) {
            state.documents = initialState.documents;
        },
        resetHeader(state) {
            state.headerDetails = initialState.headerDetails;
        },
        resetLineDetails(state) {
            state.lineDetails = initialState.lineDetails;
        },
        setRenderCompareModal(state, action) {
            state.renderCompareModal = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // GET CLEARANCE DOCUMENTS
            .addCase(GetClearanceDocuments.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetClearanceDocuments.fulfilled, (state, action) => {
                state.isLoading = false;
                if (action.payload.images) {
                    state.documents = action.payload.images.map((item: any) => ({
                        documentId: item._pid,
                        documentType: item._metadataFormat,
                        imageDate: item._imageDate.split('T')[0].replaceAll('-', '/'),
                        metadataFormat: item._metadataFormat,
                        path: item._fileS3Path,
                        entryNumber: item._entryNumber,
                        transactionNumber: item._transactionNumber,
                        clientName: item._clientName_edm,
                        USBILLTO: item.USBILLTO,
                        LIVERSION: item.LIVERSION,
                        USDOCUMENTTYPE: item.USDOCUMENTTYPE,
                        LIDOCUMENTID: item.LIDOCUMENTID,
                        USCICONSOL: item.USCICONSOL
                    }));
                }
            })
            .addCase(GetClearanceDocuments.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            // GET LINE DETAILS
            .addCase(GetLineDetails.pending, (state) => {
                state.isLoadingLineDetails = true;
                state.error = undefined;
            })
            .addCase(GetLineDetails.fulfilled, (state, action) => {
                state.isLoadingLineDetails = false;
                state.lineDetails = action.payload?.shipmentDetails;
            })
            .addCase(GetLineDetails.rejected, (state, action) => {
                state.isLoadingLineDetails = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            // GET SHIPMENT HEADER
            .addCase(GetShipmentHeader.pending, (state) => {
                state.isLoadingHeader = true;
                state.error = undefined;
            })
            .addCase(GetShipmentHeader.fulfilled, (state, action) => {
                state.isLoadingHeader = false;
                state.headerDetails = action.payload.shipments;
            })
            .addCase(GetShipmentHeader.rejected, (state, action) => {
                state.isLoadingHeader = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            // DOWNLOAD DOCUMENTS
            .addCase(DownloadDocuments.pending, (state, action) => {
                state.isLoadingDownload = true;
                state.error = undefined;
            })
            .addCase(DownloadDocuments.fulfilled, (state, action) => {
                state.isLoadingDownload = false;
                state.downloadData = action.payload;
            })
            .addCase(DownloadDocuments.rejected, (state, action) => {
                state.isLoadingDownload = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            // EXPORT HEADER AND LINE DETAIL
            .addCase(ExportHeaderAndDetail.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(ExportHeaderAndDetail.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(ExportHeaderAndDetail.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const { resetDownloadData, resetDocuments, resetHeader, resetLineDetails, setRenderCompareModal } = documentsSlice.actions;

export default documentsSlice.reducer;
