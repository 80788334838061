import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { locationFilterType } from '../../common/constants/complete-entry-data-constants';
import { LocationFilterSlice } from '../../common/models/ReduxSlices';

const initialState: LocationFilterSlice = {
    locationTypeSelected: null,
    isLocationSelectOpen: false,
    isFirstLocationSelectOpen: false,
    checkedNameItems: [],
    checkedIdItems: [],
    selectedLocationLabels: [],
    applySelectLocation: false,
    appliiedCheckedItems: [],
    appliiedCheckedItemsNames: [],
    isLocationFiltered: false,
    applylocationFilter: [],
    finalApplyItems: [],
    appliedLocationTypeSelected: null
};
const locationFilterSlice = createSlice({
    name: 'locationFilterSlice',
    initialState,
    reducers: {
        setLocationTypeSelected(state, action: PayloadAction<number>) {
            const locationSelectedIndex = action.payload;
            const locationType = locationFilterType[locationSelectedIndex];
            if (state.locationTypeSelected?.attribute !== locationType.attribute && !state.isLocationFiltered) {
                state.checkedNameItems = [];
                state.checkedIdItems = [];
                state.selectedLocationLabels = [];
                state.applySelectLocation = false;
                state.appliiedCheckedItems = [];
                state.applylocationFilter = [];
            }
            if (state.locationTypeSelected?.attribute !== locationType.attribute && state.isLocationFiltered) {
                state.checkedNameItems = [];
                state.checkedIdItems = [];

                state.applylocationFilter = [];
                state.appliiedCheckedItemsNames = [];
            }
            state.locationTypeSelected = locationType;
        },
        setAppliedLocationType(state, action: PayloadAction<LocationFilterSlice['appliedLocationTypeSelected']>) {
            state.appliedLocationTypeSelected = action.payload;
        },
        setIsLocationSelectOpen(state, action: PayloadAction<boolean>) {
            state.isLocationSelectOpen = action.payload;
        },
        setIsFirstLocationSelectOpen(state, action: PayloadAction<boolean>) {
            state.isFirstLocationSelectOpen = action.payload;
        },
        toggleCheckbox: (state, action: PayloadAction<{ checkboxName: string; checkboxId: string }>) => {
            const checkboxName = action.payload.checkboxName;
            const checkboxId = action.payload.checkboxId;
            const index = state.checkedIdItems.indexOf(checkboxId);
            if (index === -1) {
                state.checkedIdItems.push(checkboxId);
                state.checkedNameItems.push(checkboxName);
            } else {
                state.checkedIdItems.splice(index, 1);
                state.checkedNameItems.splice(index, 1);
            }
        },
        setApplySelectLocation(state, action: PayloadAction<{ isApplied: boolean; appliedItems: string[]; appliedItemsNames: string[] }>) {
            state.applySelectLocation = action.payload.isApplied;
            state.appliiedCheckedItems = action.payload.appliedItems;
            state.appliiedCheckedItemsNames = action.payload.appliedItemsNames;
        },
        setSelectedLocationLabels(state, action: PayloadAction<{ checkedNameItems: string[]; checkedIdItems: string[] }>) {
            state.selectedLocationLabels = action.payload.checkedNameItems;
            state.finalApplyItems = action.payload.checkedIdItems;
            state.isLocationFiltered = true;
        },
        cancelSelectedItems(state) {
            state.applylocationFilter = state.appliiedCheckedItemsNames;
            state.checkedNameItems = state.selectedLocationLabels;
            state.checkedIdItems = state.finalApplyItems;
            state.appliiedCheckedItems = [];
            if (!state.isLocationFiltered) {
                state.appliiedCheckedItemsNames = [];
            }
            if (state.isLocationFiltered) {
                state.appliiedCheckedItemsNames = state.selectedLocationLabels;
                state.applylocationFilter = state.selectedLocationLabels;
            }
            if (state.appliedLocationTypeSelected !== undefined && !state.isFirstLocationSelectOpen) {
                state.locationTypeSelected = state.appliedLocationTypeSelected;
            }
            if (
                state.isLocationFiltered &&
                !state.isLocationSelectOpen &&
                state.locationTypeSelected?.attribute !== state.appliedLocationTypeSelected?.attribute
            ) {
                state.checkedNameItems = [];
                state.checkedIdItems = [];
            }
        },
        updatedLocationIsFiltered(state, action: PayloadAction<boolean>) {
            state.isLocationFiltered = action.payload;
        },

        updateApplyLocationFiltered(state, action: PayloadAction<{ checkboxName: string }>) {
            const { checkboxName } = action.payload;
            const index = state.applylocationFilter.indexOf(checkboxName);
            if (index === -1) {
                state.applylocationFilter.push(checkboxName);
            } else {
                state.applylocationFilter.splice(index, 1);
            }
        },
        resetApplylocationFilter(state) {
            state.applylocationFilter = initialState.applylocationFilter;
        },
        resetLocationFilters() {
            return { ...initialState };
        }
    }
});

export const {
    setLocationTypeSelected,
    toggleCheckbox,
    resetLocationFilters,
    resetApplylocationFilter,
    setIsLocationSelectOpen,
    setSelectedLocationLabels,
    setApplySelectLocation,
    cancelSelectedItems,
    updatedLocationIsFiltered,
    updateApplyLocationFiltered,
    setAppliedLocationType,
    setIsFirstLocationSelectOpen
} = locationFilterSlice.actions;

export default locationFilterSlice.reducer;
