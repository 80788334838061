import { memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PortalRoutes } from '../../common/constants/constants-portal';
import ContactsGrid from './screens/ContactsGrid';

const Contacts = memo(function Contacts() {
    return (
        <main className='main-stage-wrapper h-100 d-flex flex-column flex-fill position-relative'>
            <div className='d-flex flex-column flex-fill'>
                <Switch>
                    <Route path={[PortalRoutes.contacts, `/${PortalRoutes.contacts}`]} exact component={ContactsGrid} />
                </Switch>
            </div>
        </main>
    );
});

export default Contacts;
