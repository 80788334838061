export const SET_SEARCH_SHIPMENT = 'SET_SEARCH_SHIPMENT';
export const INIT_SEARCH_SHIPMENT_MODEL = 'INIT_SEARCH_SHIPMENT_MODEL';
export const SUBMIT_SEARCH_SHIPMENT = 'SUBMIT_SEARCH_SHIPMENT';
export const RESET_UPDATE_DATA_SOURCE_FLAG = 'RESET_UPDATE_DATA_SOURCE_FLAG';
export const CLEAR_SHIPMENT_FILTERS = 'CLEAR_SHIPMENT_FILTERS';
export const CLEAR_SHIPMENT_DATE_FILTERS = 'CLEAR_SHIPMENT_DATE_FILTERS';
export const UPDATE_SHIPMENT_CLIENT_SERVICES = 'UPDATE_SHIPMENT_CLIENT_SERVICES';
export const CLEAR_SHIPMENT_CLIENT_SERVICES = 'CLEAR_SHIPMENT_CLIENT_SERVICES';
export const SHIPMENT_FILTER_OPTION_SELECT = 'SHIPMENT_FILTER_OPTION_SELECT';
export const SHIPMENT_FILTER_VALUE_CHANGE = 'SHIPMENT_FILTER_VALUE_CHANGE';
export const FILTER_SHIPMENTS = 'FILTER_SHIPMENTS';
export const SET_SORT_PARAMS = 'SET_SORT_PARAMS';
export const SET_GRID_FILTERS = 'RESET_GRID_FILTERS';
export const LOAD_SHIPMENTS = 'LOAD_SHIPMENTS';
export const RESET_SUBMIT_SEARCH_SHIPMENT_FLAG = 'RESET_SUBMIT_SEARCH_SHIPMENT_FLAG';
export const RESET_INIT_SEARCH_SHIPMENT_FLAG = 'RESET_INIT_SEARCH_SHIPMENT_FLAG';
export const RESET_STATE_SEARCH_SHIPMENT = 'RESET_STATE_SEARCH_SHIPMENT';
