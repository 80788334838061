import { Component, React } from 'react';
import WidgetSelectorContent from './WidgetSelectorContent';

class WidgetSelector extends Component {
    state = {
        widgetSelectorIsOpen: false
    };

    openWidgetSelector = () => {
        this.setState({ widgetSelectorIsOpen: true });
    };

    closeWidgetSelector = () => {
        this.setState({ widgetSelectorIsOpen: false });
    };

    updateWidgetSelection = (data) => {
        this.props.onConfirm(data);
        this.closeWidgetSelector();
    };

    render() {
        return (
            <div className='col-md-12'>
                <div
                    id='widget-selector-container'
                    className='button white widget-button pull-right'
                    aria-label='widget selector'
                    onClick={this.openWidgetSelector}
                >
                    <span className='fa fa-cog' aria-hidden='true'></span>
                </div>
                <WidgetSelectorContent
                    data={this.props.data}
                    isOpen={this.state.widgetSelectorIsOpen}
                    closeModal={this.closeWidgetSelector}
                    onRequestConfirm={this.updateWidgetSelection}
                    countryCode={this.props.countryCode}
                />
            </div>
        );
    }
}

export default WidgetSelector;
