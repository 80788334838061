import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ChooserControl from '../../../common/ClientChooser/ChooserControl';
import CheckboxFilter from '../../../common/Filters/CheckboxFilter';
import DatePickerFilter from '../../../common/Filters/DatePickerFilter';
import DocTypeFilter from '../../../common/Filters/DocTypeFilter';
import MainSearchFilter from '../../../common/Filters/MainSearchFilter';
import MotFilter from '../../../common/Filters/MotFilter';
import PrimaryDateFilter from '../../../common/Filters/PrimaryDateFilter';
import TextFilter from '../../../common/Filters/TextFilter';
import YearFilter from '../../../common/Filters/YearFilter';
import { filters as Constants, imageDetailsPath, storageModelName } from '../../../constants/mlp-constants';
import * as actions from '../../../store/actions/index';
import { formatTranslated, getSearchTypesBySelectedServices, pluck } from '../../../utils/mlp-utils';
import { loadModelFromStorage, removeModelFromStorage, saveModelToStorage } from '../../../utils/modelStorage';
import SearchGridContainer from './SearchGridContainer';
import SearchHeader from './SearchHeader';

class IndexForm extends Component {
    state = {
        moreFiltersVisible: false,
        invalidTextFilters: []
    };

    postDelayTimer = null;
    detailPages = [
        imageDetailsPath.VIEWIMAGE_SEARCHSHIPMENTDETAIL,
        imageDetailsPath.DOCUMENTCOMPARE_VIEW,
        imageDetailsPath.DOCUMENT_VIEW,
        imageDetailsPath.DOWNLOAD_AND_PRINT
    ];

    selectedPids = [];

    componentDidMount() {
        this.props.onInitTranslations('Imaging.Imaging.Index');
        const localImageStatePlusSelectedItems = loadModelFromStorage(storageModelName.VIEWIMAGE, this.props.location, this.detailPages);

        if (localImageStatePlusSelectedItems != null) {
            if (localImageStatePlusSelectedItems.selectedPids != null) {
                this.selectedPids = localImageStatePlusSelectedItems.selectedPids;
            }
            this.props.onSetImageModel(localImageStatePlusSelectedItems.stateModel);
            this.onFilterImagesWithDelay();
        } else {
            this.props.onInitViewImages();
        }
    }

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
        if (this.props.shouldInitModel) {
            this.props.onInitModel(this.props.translations);
        }
        if (this.props.shouldSubmitImageSearch) {
            this.props.onSubmitImageSearch(this.props.model);
        }
        if (this.props.shouldUpdateGridModel) {
            this.props.onUpdateGridModel(this.props.model, this.props.translations, this.selectedPids);
            this.selectedPids = [];
        }
    }

    getSelectedPidsFromSearchGrid() {
        let allSelectedPids = [];

        const selectedGroupings = this.props.model.GridModel.filter((gridGrouping) => gridGrouping.isSelected === true);

        selectedGroupings.forEach((x) => {
            const selectedItems = x.documents.filter((gridGrouping) => gridGrouping.isSelected === true);
            const selectedPids = pluck(selectedItems, 'pid');
            allSelectedPids = allSelectedPids.concat(selectedPids);
        });

        return allSelectedPids;
    }

    componentWillUnmount() {
        if (this.detailPages.includes(this.props.history.location.pathname)) {
            this.selectedPids = this.getSelectedPidsFromSearchGrid();

            let imageStateModel = { ...this.props.model };
            imageStateModel.SearchResults = [];
            imageStateModel.GridModel = [];

            const stateModel = {
                model: imageStateModel,
                shouldInitModel: this.props.shouldInitModel,
                shouldSubmitImageSearch: this.props.shouldSubmitImageSearch,
                shouldUpdateGridModel: this.props.shouldUpdateGridModel,
                type: this.props.type
            };

            const statePlusSelectedPids = {
                stateModel: stateModel,
                selectedPids: this.selectedPids
            };

            saveModelToStorage(storageModelName.VIEWIMAGE, statePlusSelectedPids);
        } else {
            removeModelFromStorage(storageModelName.VIEWIMAGE);
        }
        this.props.onDropRequests([]);
        this.props.onClearImageState();
    }

    toggleMoreFilters = () => {
        this.setState((prevState) => {
            return { moreFiltersVisible: !prevState.moreFiltersVisible };
        });
    };

    getHeaderContent = (searchFilters) => {
        const availableSearchTypes = getSearchTypesBySelectedServices(this.props.model);
        if (availableSearchTypes === undefined || availableSearchTypes.length <= 0) {
            return (
                <div className='row'>
                    <div className='col-sm-12 text-center'>
                        <h3>There are no selected clients</h3>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <SearchHeader searchTypes={availableSearchTypes} quickSearchValue={this.props.model.QuickSearchValue} />
                {searchFilters[Constants.SEARCH_SECTION_MAIN]}
            </div>
        );
    };

    getInitialMainContent = () => {
        const availableSearchTypes = getSearchTypesBySelectedServices(this.props.model);
        if (availableSearchTypes === undefined || availableSearchTypes.length <= 0) {
            return <></>;
        }

        return (
            <div className='row'>
                <div className='col-sm-12 text-center'>
                    <h3>Please select a search type</h3>
                </div>
            </div>
        );
    };

    onFilterImagesWithDelay = () => {
        clearTimeout(this.postDelayTimer);
        this.postDelayTimer = setTimeout(() => {
            this.props.filterImages();
        }, 700);
    };

    onFilterImages = () => {
        if (this.state.invalidTextFilters.length === 0) {
            this.props.filterImages();
        }
    };

    setIsTextFiltersValid = (name, valueIsValid) => {
        let invalidTextFilters = [...this.state.invalidTextFilters];
        if (valueIsValid) {
            invalidTextFilters = invalidTextFilters.filter((x) => x !== name);
        } else {
            if (invalidTextFilters.indexOf(name) === -1) {
                invalidTextFilters.push(name);
            }
        }
        this.setState({ invalidTextFilters: invalidTextFilters });
    };

    render() {
        if (this.props.model.SearchResults && this.props.translations.PhraseGroupCode === 'Imaging.Imaging.Index') {
            let searchFilters = {};
            searchFilters[Constants.SEARCH_SECTION_MAIN] = [];
            searchFilters[Constants.SEARCH_SECTION_DATE] = [];
            searchFilters[Constants.SEARCH_SECTION_DOC_TYPE] = [];
            searchFilters[Constants.SEARCH_SECTION_OTHER] = [];

            const searchTypesForCurrentCountry = getSearchTypesBySelectedServices(this.props.model);
            let mainContent = this.getInitialMainContent();
            const selectedSearchType = this.props.model.SearchTypes.find((type) => type.IsSelected === true);
            const pageTitle = formatTranslated('Imaging_PageHeader', this.props.translations.Phrases).split(' ');

            if (selectedSearchType !== undefined) {
                this.props.model.Filters.forEach((f, i) => {
                    let component;
                    switch (f.ControlName) {
                        case 'YearFilter':
                            component = (
                                <div key={i}>
                                    <YearFilter
                                        filterControl={f}
                                        selectedSearchType={selectedSearchType}
                                        searchTypeCode={selectedSearchType.Code}
                                        onOptionSelect={this.props.onFilterOptionSelect}
                                        onValueChanged={this.props.onFilterValueChange}
                                        onSetDropdownYears={this.props.onSetDropdownYears}
                                        customAction={this.onFilterImages}
                                    />
                                </div>
                            );
                            break;
                        case 'TextFilter':
                            component = (
                                <div key={i}>
                                    <TextFilter
                                        filterControl={f}
                                        selectedSearchType={selectedSearchType}
                                        searchTypeCode={selectedSearchType.Code}
                                        onValueChanged={this.props.onFilterValueChange}
                                        customAction={this.onFilterImagesWithDelay}
                                        onValidate={this.setIsTextFiltersValid}
                                        invalidTextFilters={this.state.invalidTextFilters}
                                    />
                                </div>
                            );
                            break;
                        case 'YesNoFilter':
                            component = (
                                <div key={i}>
                                    <CheckboxFilter
                                        filterControl={f}
                                        selectedSearchType={selectedSearchType}
                                        searchTypeCode={selectedSearchType.Code}
                                        onValueChanged={this.props.onFilterValueChange}
                                        customAction={this.onFilterImages}
                                    />
                                </div>
                            );
                            break;
                        case 'DatePickerFilter':
                            component = (
                                <div key={i}>
                                    <DatePickerFilter
                                        filterControl={f}
                                        selectedSearchType={selectedSearchType}
                                        searchTypeCode={selectedSearchType.Code}
                                        onDateChanged={this.props.onFilterValueChange}
                                        customAction={this.onFilterImages}
                                    />
                                </div>
                            );
                            break;
                        case 'MainSearchFilter':
                            component = (
                                <div key={i}>
                                    <MainSearchFilter
                                        filterControl={f}
                                        selectedSearchType={selectedSearchType}
                                        searchTypeCode={selectedSearchType.Code}
                                        onOptionSelect={this.props.onFilterOptionSelect}
                                        onValueChanged={this.props.onFilterValueChange}
                                        customAction={this.onFilterImagesWithDelay}
                                    />
                                </div>
                            );
                            break;
                        case 'PrimaryDateFilter':
                            component = (
                                <div key={i}>
                                    <PrimaryDateFilter
                                        filterControl={f}
                                        selectedSearchType={selectedSearchType}
                                        searchTypeCode={selectedSearchType.Code}
                                        onOptionSelect={this.props.onFilterOptionSelect}
                                        onValueChanged={this.props.onFilterValueChange}
                                        customAction={this.onFilterImages}
                                    />
                                </div>
                            );
                            break;
                        case 'DocTypeFilter':
                            component = (
                                <div key={i}>
                                    <DocTypeFilter
                                        filterControl={f}
                                        selectedSearchType={selectedSearchType}
                                        searchTypeCode={selectedSearchType.Code}
                                        onValueChanged={this.props.onDocTypeFilterValueChange}
                                        onSelectAll={this.props.onDocTypeFilterSelectAll}
                                        customAction={this.onFilterImages}
                                    />
                                </div>
                            );
                            break;
                        case 'MotFilter':
                            component = (
                                <div key={i}>
                                    <MotFilter
                                        filterControl={f}
                                        selectedSearchType={selectedSearchType}
                                        searchTypeCode={selectedSearchType.Code}
                                        onValueChanged={this.props.onMotFilterValueChange}
                                        onSelectAll={this.props.onMotFilterSelectAll}
                                        customAction={this.onFilterImages}
                                    />
                                </div>
                            );
                            break;

                        default:
                            break;
                    }

                    if (searchFilters[f.Level] && component) {
                        searchFilters[f.Level].push(component);
                    }
                });

                const label = this.state.moreFiltersVisible
                    ? formatTranslated('LessFilters_Label', this.props.translations.Phrases) + ' -'
                    : formatTranslated('MoreFilters_Label', this.props.translations.Phrases) + ' +';
                const moreFilters = (
                    <div>
                        <div className='filter-section'>
                            <h4 onClick={this.toggleMoreFilters} className='header'>
                                {label}
                            </h4>
                        </div>
                        <div className={this.state.moreFiltersVisible ? 'd-block' : 'd-none'}>
                            {searchFilters[Constants.SEARCH_SECTION_OTHER]}
                        </div>
                    </div>
                );
                if (searchTypesForCurrentCountry !== undefined && searchTypesForCurrentCountry.length > 0) {
                    mainContent = (
                        <div className='row'>
                            <div className='col-md-3 col-sm-4 flex-img-filter-container'>
                                <div className='img-filter-container'>
                                    <div className='row header-row'>
                                        <h3 className='col-sm-5 row-content'>
                                            {formatTranslated('Filter_Label', this.props.translations.Phrases)}
                                        </h3>
                                        <div className='col-sm-7 text-right row-content'>
                                            <span className='btn btn-link' onClick={() => this.props.onClearFilters()}>
                                                {formatTranslated('Clear_Label', this.props.translations.Phrases)}
                                            </span>
                                        </div>
                                    </div>
                                    <hr />

                                    {searchFilters[Constants.SEARCH_SECTION_DATE]}
                                    {searchFilters[Constants.SEARCH_SECTION_DOC_TYPE]}
                                    {moreFilters || ''}
                                </div>
                            </div>
                            <div className='col-md-9 col-sm-8 flex-img-search-grid-container'>
                                <SearchGridContainer
                                    totalEntries={this.props.model.TotalEntries}
                                    totalResults={this.props.model.TotalResults}
                                    maxSelectedDocuments={this.props.model.MaxSelectedDocuments}
                                    totalLoadedResults={this.props.model.TotalLoadedResults}
                                    groupByOptions={this.props.model.ImageGroupings}
                                    gridModel={this.props.model.GridModel}
                                    showPaging={this.props.model.TotalLoadedResults < this.props.model.TotalResults}
                                    selectedSearchTypeCode={selectedSearchType.Code}
                                />
                            </div>
                        </div>
                    );
                }
            }

            return (
                <div id='imaging'>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.props.translations.Phrases['Page_Title']}</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className='page-wrapper img-page-wrapper'>
                        <div className='page-top-row'>
                            <div className='page-header-section'>
                                <nav className='mlp-breadcrumb'>
                                    <Link to='/Dashboard' className='breadcrumb-parent'>
                                        {formatTranslated('Imaging_NavLevel1', this.props.translations.Phrases)}
                                    </Link>

                                    <i className='fa fa-caret-right'></i>
                                    <span className='breadcrumb-parent'>
                                        {formatTranslated('Imaging_NavLevel2', this.props.translations.Phrases)}
                                    </span>
                                </nav>
                                <div>
                                    <div className='header-align'>
                                        <h1 className='pull-left bold-title'>
                                            <span>{pageTitle[0]}&nbsp;</span>
                                            <span className='gold-title'>{pageTitle[1]}</span>
                                        </h1>
                                    </div>
                                    <div className='header-align'>
                                        <div className='pull-right'>
                                            <ChooserControl
                                                data={this.props.model.ClientServiceSelection}
                                                groupingAttribute='Number'
                                                onConfirm={this.props.onUpdateClientServices}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='search-header clearfix'>{this.getHeaderContent(searchFilters)}</div>
                            </div>
                        </div>
                        <div id='gridTest'></div>
                        <div className='content-wrapper'>
                            <div className='row page-content'>
                                <div id='imaging-form' className='w-100'>
                                    {mainContent || ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div className='page-wrapper'></div>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        model: state.imaging.model,
        shouldInitModel: state.imaging.shouldInitModel,
        shouldSubmitImageSearch: state.imaging.shouldSubmitImageSearch,
        shouldUpdateGridModel: state.imaging.shouldUpdateGridModel
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actions.initTranslations(phraseGroup)),
        onInitViewImages: (translations) => dispatch(actions.initViewImages(translations)),
        onDropRequests: () => dispatch(actions.dropRequests()),
        onInitModel: (translations) => dispatch(actions.initImageModel(translations)),
        onSubmitImageSearch: (model) => dispatch(actions.submitImageSearch(model)),
        onUpdateGridModel: (model, translations, selectedPids) => dispatch(actions.updateGridModel(model, translations, selectedPids)),
        onClearFilters: () => dispatch(actions.clearImageFilters()),
        onUpdateClientServices: (data) => dispatch(actions.updateImageClientServices(data)),
        onFilterOptionSelect: (newIndex, oldIndex, name) => dispatch(actions.imageFilterOptionSelect(newIndex, oldIndex, name)),
        onFilterValueChange: (value, name) => {
            const splittedName = name.split('.');
            dispatch(actions.imageFilterValueChange(splittedName[2], value, splittedName[1]));
        },
        onDocTypeFilterValueChange: (value, name) => {
            const splittedName = name.split('.');
            dispatch(actions.imageDocTypeFilterValueChange(splittedName[2], value, splittedName[1], splittedName[4], splittedName[3]));
        },
        onDocTypeFilterSelectAll: (value, filterIndex) => {
            dispatch(actions.imageDocTypeFilterSelectAll(value, filterIndex));
        },
        filterImages: () => dispatch(actions.filterImages()),
        onSetImageModel: (model) => dispatch(actions.setImageModel(model)),
        onClearImageState: () => dispatch(actions.resetImageState()),
        onMotFilterValueChange: (value, filterIndex, optionIndex) => {
            dispatch(actions.imageMotFilterValueChange(value, filterIndex, optionIndex));
        },
        onMotFilterSelectAll: (value, filterIndex) => {
            dispatch(actions.motFilterSelectAll(value, filterIndex));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexForm);
