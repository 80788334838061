import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridCellProps } from '@progress/kendo-react-grid';
import { useHistory } from 'react-router-dom';
import { MILESTONE_CA_TOOLTIP_LABELS, MILESTONE_US_TOOLTIP_LABELS, TabNavigationIds } from '../../../common/constants/constants-portal';
import Badge from '../../../common/features/Badge';
import Tooltip from '../../../common/features/Tooltip/Tooltip';
import useTranslation from '../../../common/hooks/useTranslation';
import { useDashboardSelector } from '../redux/hooks';

interface FormattedGridCellProps extends GridCellProps {
    onClickFunction?(): void;
}
const FormattedGridCell: React.FC<FormattedGridCellProps> = ({ field = '', dataItem, style }) => {
    const { user } = useDashboardSelector((state) => state.hydration);
    const countryCode = user?.CountryCode;

    const history = useHistory();

    const translate = useTranslation();

    let cellContent;

    switch (field) {
        case 'EntryNumber':
            cellContent = dataItem[field];
            break;
        case 'CargoControlNumber':
            cellContent = dataItem[field];
            break;
        case 'BillOfLading':
            cellContent = dataItem[field]?.MasterBill;
            break;
        case 'ClearanceStatus':
            const status = dataItem[field];
            cellContent =
                status === 'IN_PROGRESS' ? (
                    <Badge type='info'>{translate('StatusInProgress_Label')}</Badge>
                ) : status === 'ON_HOLD' ? (
                    <Badge type='danger'>
                        <FontAwesomeIcon icon={faExclamationCircle} className='mr-1' />
                        {translate('StatusOnHold_Label')}
                    </Badge>
                ) : (
                    <Badge type='success'>{translate('StatusCompleted_Label')}</Badge>
                );
            break;
        case 'Milestone':
            const milestone = dataItem[field];
            const tooltip =
                milestone && countryCode
                    ? countryCode === 'ca'
                        ? MILESTONE_CA_TOOLTIP_LABELS[milestone as keyof typeof MILESTONE_CA_TOOLTIP_LABELS]
                        : MILESTONE_US_TOOLTIP_LABELS[milestone as keyof typeof MILESTONE_US_TOOLTIP_LABELS]
                    : undefined;

            cellContent =
                tooltip && milestone ? (
                    <Tooltip label={`${translate(tooltip)}`}>
                        <span>{translate(milestone)}</span>
                    </Tooltip>
                ) : null;
            break;
    }

    return (
        <td
            onClick={
                field === 'EntryNumber'
                    ? () => {
                          const customsInfoID = dataItem.CustomsInfoID;
                          localStorage.setItem('activeTab', TabNavigationIds.Clearances[0]);
                          history.push(`../Clearances/${customsInfoID}`);
                      }
                    : () => {}
            }
            style={{
                ...style,
                ...(field === 'EntryNumber' ? { paddingLeft: '34px' } : {})
            }}
        >
            {cellContent}
        </td>
    );
};

export { FormattedGridCell }; // Add this export statement
