import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { batchType } from '../../constants/mlp-constants';
import * as actionCreators from '../../store/actions/index';
import BatchJobsGrid from './BatchJobsGrid';

class SystemAdminIndexForm extends Component {
    componentDidMount = () => {
        this.props.onInitTranslations('SystemAdmin.SystemAdmin.Index');
        this.props.onInitSystemAdminIndex();
    };

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
    }

    render() {
        if (this.props.model && this.props.translations.PhraseGroupCode === 'SystemAdmin.SystemAdmin.Index') {
            return (
                <div id='page'>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.props.translations.Phrases['Page_Title']}</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className='page-wrapper'>
                        <div className='page-top-row'>
                            <div className='page-header-section'>
                                <nav className='mlp-breadcrumb'>
                                    <Link to='/Dashboard' className='breadcrumb-parent'>
                                        {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                    </Link>
                                    <i className='fa fa-caret-right'></i>
                                    <span>{this.props.translations.Phrases['Page_Header_Navigation_Level_2']}</span>
                                </nav>
                                <h1 className='bold-title'>{this.props.translations.Phrases['Page_Header']}</h1>
                            </div>
                        </div>
                        <div className='content-wrapper'>
                            <div className='row page-content'>
                                <div id='system-admin-form'>
                                    <div>
                                        <BatchJobsGrid
                                            model={this.props.model.filter((batch) => batch.BatchTypeCode === batchType.BatchCreate)}
                                            title={this.props.translations.Phrases['BatchCreate_Label']}
                                            actionText={this.props.translations.Phrases['StartNewBatchCreate_Label']}
                                            actionUrl={'/SystemAdmin/BatchUser'}
                                            type={batchType.BatchCreate}
                                        />

                                        <BatchJobsGrid
                                            model={this.props.model.filter((batch) => batch.BatchTypeCode === batchType.BatchEdit)}
                                            title={this.props.translations.Phrases['BatchEdit_Label']}
                                            actionText={this.props.translations.Phrases['StartNewBatchEdit_Label']}
                                            actionUrl={'/SystemAdmin/BatchUser/Edit'}
                                            type={batchType.BatchEdit}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div className='page-wrapper'></div>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        model: state.systemAdmin.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup)),
        onInitSystemAdminIndex: () => dispatch(actionCreators.initSystemAdminIndex())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SystemAdminIndexForm);
