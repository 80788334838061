import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ClearSearchInputButtonProps {
    inputValue: string;
    onClick: () => void;
}

const ClearSearchInputButton: React.FC<ClearSearchInputButtonProps> = ({ inputValue, onClick }) => {
    return (
        <span className={`main-desktop-form-clear form-clear ${inputValue.length > 0 ? '' : 'd-none'}`} onClick={onClick}>
            <FontAwesomeIcon icon={faTimesCircle} />
        </span>
    );
};

export default ClearSearchInputButton;
