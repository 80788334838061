import { useEffect, useState } from 'react';

const useClientDimensions = () => {
    const [result, setResult] = useState<{ width: number; height: number }>({
        width: document.body.clientWidth,
        height: document.body.clientHeight
    });

    useEffect(() => {
        const handleResize = () => {
            setResult({ width: document.body.clientWidth, height: document.body.clientHeight });
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return result;
};

export default useClientDimensions;
