import { useEffect, useState } from 'react';

/**
 * Hook that mimics the css's media query "mobile first" aproach.
 *
 * @param {number} minWidth - this is equivalent to "min-width" in css.
 * @param {number} maxWidth - optional - this is equivalent to "max-width" in css.
 * @returns {boolean} TRUE if one of the use cases is met, or FALSE if it's not
 * use cases:
 * useMediaQuery(1000) - minWidth
 * useMediaQuery(0,1000) - maxWidth
 * useMediaQuery(500,1000) - between minWidth and maxWidth
 */

const useMediaQuery = (minWidth: number, maxWidth?: number): boolean => {
    const [result, setResult] = useState(window.innerWidth > minWidth && (!maxWidth || window.innerWidth <= maxWidth));

    useEffect(() => {
        const handleResize = () => {
            const isWithinRange = window.innerWidth > minWidth && (!maxWidth || window.innerWidth <= maxWidth);
            setResult(isWithinRange);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [minWidth, maxWidth]);

    return result;
};

export default useMediaQuery;
