import { process } from '@progress/kendo-data-query';
import type { GridCellProps, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { GridColumn as Column, Grid } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import '@progress/kendo-theme-default/dist/all.css';
import { useCallback, useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router-dom';
import { PortalRoutes } from '../../../../../common/constants/constants-portal';
import GridLoadingOverlay from '../../../../../common/features/Grid/GridLoadingOverlay';
import RowWithButton from '../../../../../common/features/Grid/RowWithButton';
import useGridLanguage from '../../../../../common/hooks/useGridLanguage';
import useMediaQuery from '../../../../../common/hooks/useMediaQuery';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ADMIN_ROLES } from '../../../common/constants/constants-team';
import ChangeUserStatusModal from '../../../common/features/ChangeUserStatusModal';
import { useHighlightRows } from '../../../common/hooks/useHighlightRows';
import type { TeamData, TeamMember, TransformedTeamMember } from '../../../common/models';
import { useTeamDispatch, useTeamSelector } from '../../../redux/hooks';
import { setDataState, setTotalItems } from '../../../redux/reducers/teamGridSettingsSlice';
import { ColumnMenuCheckboxFilter, ColumnMenuDateFilter, ColumnMenuSearchText } from './ColumnFilters';
import FormattedGridCell from './FormattedGridCell';

interface TeamsTableProps {
    isFetching: boolean;
    userTeam: TeamData;
    teamMembers: TransformedTeamMember[];
}

const TeamsTable: React.FC<TeamsTableProps> = ({ isFetching, userTeam, teamMembers }) => {
    const { pageable, dataState, totalItems } = useTeamSelector((state) => state.teamGridSettings);
    const { user } = useTeamSelector((state) => state.hydration);
    const { showStatusModal } = useTeamSelector((state) => state.statusUser);
    const { languageSelected: selectedLanguage } = useTeamSelector((state) => state.language);
    const dispatch = useTeamDispatch();

    const translate = useTranslation();

    const history = useHistory<{ newTeamMembersAmount: number } | undefined>();
    const newTeamMembersAmount = history.location.state?.newTeamMembersAmount;

    useHighlightRows(newTeamMembersAmount);

    const gridRef = useRef<any>();
    const gridLanguage = useGridLanguage(selectedLanguage);
    const extraLargeSize = useMediaQuery(1890);

    // Update the total items that are responsible to show/hide pagination
    useEffect(() => {
        if (!gridRef.current) return;

        dispatch(setTotalItems(gridRef.current.props.data.total));
    }, [dataState, teamMembers.length, dispatch]);

    const dataStateChange = (e: GridDataStateChangeEvent) => {
        dispatch(setDataState(e.dataState));
    };

    const redirectToUserDetails = useCallback(
        (member: TeamMember) => {
            if (userTeam?.SuperiorId !== member?.UserId) {
                return history.push(`/Team/TeamMember?userId=${member?.UserId}`);
            }
        },
        [history, userTeam?.SuperiorId]
    );

    const handleTagEvent = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'Add_User_Team_CPEvt',
                location: 'Profile'
            }
        });
    };

    const customCell = useCallback(
        (props: GridCellProps) => <FormattedGridCell onRowClick={redirectToUserDetails} {...props} />,
        [redirectToUserDetails]
    );

    return (
        <>
            {ADMIN_ROLES.find((element) => element.id === userTeam?.UserRole) &&
                user?.Roles !== undefined &&
                (user.Roles.includes('TeamManagement_Edit') || user.Roles.includes('TeamManagement_Create')) && (
                    <RowWithButton
                        extraRowClasses='pl-2'
                        element={'.k-grid.teams-grid-table'}
                        onClick={() => {
                            handleTagEvent();
                            history.push(`${PortalRoutes.team}/CreateNewUser`);
                        }}
                    >
                        {translate('TeamTableAddNewUser_Label')}
                    </RowWithButton>
                )}
            <LocalizationProvider language={gridLanguage.language}>
                <IntlProvider locale={gridLanguage.locale}>
                    <div className='d-flex position-relative flex-fill'>
                        <Grid
                            className='teams-grid-table flex-fill'
                            data={process(teamMembers, dataState)}
                            pageable={totalItems < 10 ? false : pageable}
                            sortable={{
                                allowUnsort: false
                            }}
                            {...dataState}
                            onDataStateChange={dataStateChange}
                            ref={gridRef}
                            onRowClick={(event) => redirectToUserDetails(event.dataItem)}
                        >
                            <Column
                                field='FullName'
                                title={translate('Name_Label')}
                                columnMenu={ColumnMenuSearchText}
                                width={extraLargeSize ? 'calc(100% - 1470px)' : '300px'}
                            />
                            <Column
                                field='Email'
                                title={translate('Email_Label')}
                                columnMenu={ColumnMenuSearchText}
                                width={extraLargeSize ? 'calc(100% - 1470px)' : '400px'}
                            />
                            <Column
                                field='statusTranslated'
                                title={translate('Status_Label')}
                                cell={customCell}
                                columnMenu={ColumnMenuCheckboxFilter}
                                width={extraLargeSize ? 'calc(100% - 1470px)' : '280px'}
                            />
                            <Column
                                field='LastLogin'
                                title={translate('LastLogin_Label')}
                                cell={customCell}
                                columnMenu={ColumnMenuDateFilter}
                                width={extraLargeSize ? 'calc(100% - 1470px)' : '285px'}
                            />
                            <Column
                                field='ExpirationDate'
                                title={translate('Expires_Label')}
                                cell={customCell}
                                columnMenu={ColumnMenuDateFilter}
                                width={extraLargeSize ? 'calc(100% - 1470px)' : '255px'}
                            />
                            <Column field='Actions' title=' ' cell={customCell} sortable={false} locked width='50px' />
                        </Grid>
                        {isFetching && userTeam && userTeam.TeamMembers && userTeam.TeamMembers.length > 0 && <GridLoadingOverlay />}
                    </div>
                </IntlProvider>
            </LocalizationProvider>
            {showStatusModal && <ChangeUserStatusModal />}
        </>
    );
};

export default TeamsTable;
