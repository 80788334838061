import { createGenericGridSettingsSlice, genericGridSettingsInitialState } from '../../../../redux/reducers/genericGridSettingsSlice';

const clearancesSubscribersGridSettingsSlice = createGenericGridSettingsSlice({
    name: 'clearancesSubscribersGridSettings',
    initialState: genericGridSettingsInitialState,
    reducers: {}
});

export const {
    setPageSelected,
    setDataState,
    setTotalItems,
    setSelectedState,
    setHeaderCheckbox,
    setCurrentDataItems,
    setSort,
    resetDataState,
    resetSelectedState
} = clearancesSubscribersGridSettingsSlice.actions;

export default clearancesSubscribersGridSettingsSlice.reducer;
