import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
    var terms = localStorage.getItem('terms');

    return (
        <Route
            {...rest}
            render={(props) =>
                terms === 'False' ? (
                    <Redirect
                        to={{
                            pathname: '/Auth/TermsOfService',
                            state: { from: props.location }
                        }}
                    />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

export default PrivateRoute;
