import { SortDescriptor } from '@progress/kendo-data-query';
import { GridPagerSettings } from '@progress/kendo-react-grid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ImagingGridSettingsSlice } from '../../common/models/ReduxSlices';

let pagerSettings: GridPagerSettings = {
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [10, 20, 50],
    previousNext: true
};

const initialState: ImagingGridSettingsSlice = {
    columnSortFields: [{ field: 'ModifiedOn', dir: 'desc' }],
    pageSize: 200,
    totalRecords: 0,
    pageSelected: 1,
    dataState: { take: 20, skip: 0 },
    pageable: pagerSettings
};

const imagingGridSettingsSlice = createSlice({
    name: 'imagingGridSettingsSlice',
    initialState,
    reducers: {
        setSortFields(state, action: PayloadAction<SortDescriptor[]>) {
            state.columnSortFields = action.payload;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.pageSize = action.payload;
        },
        setTotalRecords(state, action: PayloadAction<number>) {
            state.totalRecords = action.payload;
        },
        setPageSelected(state, action: PayloadAction<number>) {
            state.pageSelected = action.payload;
        },
        setDataState(state, action: PayloadAction<any>) {
            state.dataState = action.payload;
        },
        setPagerSettings(state, action: PayloadAction<any>) {
            state.pageable = action.payload;
        },
        resetImagingGridSettings() {
            return { ...initialState };
        }
    }
});

export const { setSortFields, setPageSize, setTotalRecords, setPageSelected, setDataState, resetImagingGridSettings } =
    imagingGridSettingsSlice.actions;

export default imagingGridSettingsSlice.reducer;
