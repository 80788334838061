import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Aux from '../../core/_aux/_aux';
import InputBase from '../Inputbase/Inputbase';

class CustomDropDown extends Component {
    state = { dropDownVisible: false };
    areaRef = React.createRef();

    static defaultProps = {
        name: '',
        defaultOptionText: 'Select an option',
        valueField: 'Value',
        textField: 'Text',
        customArrow: 'fa fa-caret-down arrow',
        onlyCustomAction: false
    };

    static propTypes = {
        name: PropTypes.string,
        defaultOptionText: PropTypes.string,
        valueField: PropTypes.string,
        textField: PropTypes.string,
        customArrow: PropTypes.string,
        label: PropTypes.string,
        onlyCustomAction: PropTypes.bool,
        options: PropTypes.array,
        disabled: PropTypes.bool,
        customAction: PropTypes.func,
        onItemChanged: PropTypes.func
    };

    updateState = (event) => {
        const oldIndex = this.props.options.findIndex((element) => element.IsSelected === true);
        const newIndex = this.props.options.findIndex((element) => element[this.props.textField] === event.target.textContent);

        if (!this.props.onlyCustomAction) {
            this.props.onItemChanged(newIndex, oldIndex, this.props.name);
        }

        if (this.props.customAction) {
            this.props.customAction();
        }

        this.closeDropDown();
    };

    toggleDropDown = () => {
        if (this.props.disabled) return false;

        if (this.state.dropDownVisible) {
            this.closeDropDown();
        } else {
            this.openDropDown();
        }
    };

    openDropDown = () => {
        this.setState({ dropDownVisible: true });
    };

    closeDropDown = () => {
        this.setState({ dropDownVisible: false });
    };

    handleClick = (event) => {
        if (this.areaRef.current !== undefined && !this.areaRef.current.contains(event.target)) {
            this.closeDropDown();
        }
    };

    componentDidMount = () => {
        window.addEventListener('click', this.handleClick);
    };

    componentWillUnmount = () => {
        window.removeEventListener('click', this.handleClick);
    };

    render() {
        let selectedValue = '';

        if (this.props.options && this.props.options.length > 0 && this.props.textField) {
            let selectedOptions = this.props.options.find((o) => o.IsSelected === true);
            if (selectedOptions) {
                selectedValue = selectedOptions[this.props.textField];
            }
        }
        const options = this.props.options
            ? this.props.options.map((option) => {
                  return (
                      <div className='option-row pointer' key={option[this.props.valueField]} onClick={(event) => this.updateState(event)}>
                          {option[this.props.textField]}
                      </div>
                  );
              })
            : '';

        return (
            <Aux>
                <InputBase {...this.props}>
                    <div className='custom-dropdown' ref={this.areaRef}>
                        <div className='selected-text pointer' onClick={this.toggleDropDown}>
                            {selectedValue}
                            <i className={this.props.customArrow} />
                        </div>
                        <div className={this.state.dropDownVisible ? 'd-block options' : 'd-none'}>{options}</div>
                    </div>
                </InputBase>
            </Aux>
        );
    }
}

export default CustomDropDown;
