import { getSelectedState, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent } from '@progress/kendo-react-grid';
import { useCallback, useEffect } from 'react';

interface UseGridSelectionProps {
    DATA_ITEM_KEY: string;
    SELECTED_FIELD: string;
    idGetter: Function;
    selectedState: {
        [id: string]: boolean | number[];
    };
    currentDataItems?: any[];
    onSelectionChange: (payload: { [id: string]: boolean | number[] }) => void;
    onHeaderSelectionChange: (payload: boolean) => void;
    onReset: () => void;
}

const useGridSelection = ({
    DATA_ITEM_KEY,
    SELECTED_FIELD,
    idGetter,
    selectedState,
    currentDataItems,
    onSelectionChange,
    onHeaderSelectionChange,
    onReset
}: UseGridSelectionProps) => {
    const handleSelectionChange = useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState,
                dataItemKey: DATA_ITEM_KEY
            });
            onSelectionChange(newSelectedState);

            // Toggle header checkbox on row selection change
            // All selectableItems must be selected for header checkbox to be set to TRUE, else FALSE
            const selectableItems = event.dataItems.filter((item) => item?.IsEditable === true || item?.IsEditable === undefined);
            const selectedItems = Object.entries(newSelectedState)
                .filter(([key, value]) => value === true)
                .map(([key, value]) => Number(key));
            if (selectableItems.filter((item) => !selectedItems.includes(item[DATA_ITEM_KEY])).length === 0) {
                onHeaderSelectionChange(true);
            } else {
                onHeaderSelectionChange(false);
            }
        },
        [selectedState, DATA_ITEM_KEY, onSelectionChange, onHeaderSelectionChange]
    );

    const handleHeaderSelectionChange = useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            const checkboxElement: any = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState = {} as {
                [id: string]: boolean | number[];
            };
            event.dataItems.forEach((item) => {
                newSelectedState[idGetter(item)] = item?.IsEditable === true || item?.IsEditable === undefined ? checked : false;
            });
            onSelectionChange(newSelectedState);
            onHeaderSelectionChange(checked);
        },
        [idGetter, onSelectionChange, onHeaderSelectionChange]
    );

    // Toggle grid update on dataState change (on page change or on filter change)
    useEffect(() => {
        if (currentDataItems && currentDataItems.length > 0) {
            const selectableDataItems = currentDataItems.filter((item) => item?.IsEditable === true || item?.IsEditable === undefined);
            const selectedDataItems = currentDataItems.filter((item) => item[SELECTED_FIELD] === true);
            if (selectableDataItems.length === selectedDataItems.length) {
                onHeaderSelectionChange(true);
            } else {
                onHeaderSelectionChange(false);
            }
        }
    }, [currentDataItems, SELECTED_FIELD, onHeaderSelectionChange]);

    // Reset selectedState on unmount
    useEffect(() => {
        return () => {
            onReset();
        };
    }, [onReset]);

    return { handleSelectionChange, handleHeaderSelectionChange };
};

export default useGridSelection;
