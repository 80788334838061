import $ from 'jquery';
import axios from '../../../axios-mlp';
import * as actionTypes from './featureSetupActionTypes';

export const getFeatureSetup = (requestId, history) => {
    return (dispatch) => {
        $('#overlay').show();
        let params = new URLSearchParams();
        params.append('requestId', requestId);

        axios
            .get('/ClientAdmin/FeatureSetup', { params: params })
            .then((response) => {
                if (response.data === 'StepNotActionable') {
                    history.push({ pathname: '/ClientAdmin/Signup/StepFinished' });
                }
                dispatch(afterGetFeatureSetup(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const confirmEmail = (requestId, history) => {
    return (dispatch) => {
        $('#overlay').show();
        let params = new URLSearchParams();
        params.append('requestId', requestId);

        axios
            .get('/ClientAdmin/EmailConfirmed', { params: params })
            .then((response) => {
                if (response.data.Status === 'Success' && response.data.PasswordSetUrl) {
                    window.location.replace(response.data.PasswordSetUrl);
                } else if (response.data.Status === 'SuccessNoPassword') {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/Dashboard';
                } else if (
                    response.data.Status === 'Locked' ||
                    response.data.Status === 'AlreadyDone' ||
                    response.data.Status === 'InvalidRequest'
                ) {
                    dispatch(afterEmailConfirmation(response.data));
                } else {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const afterEmailConfirmation = (model) => {
    return {
        type: actionTypes.AFTER_EMAIL_CONFIRMATION,
        payload: { model: model }
    };
};

export const afterGetFeatureSetup = (model) => {
    return {
        type: actionTypes.AFTER_GET_FEATURE_SETUP,
        payload: { model: model }
    };
};

export const changeFeatureUsername = (value) => {
    return {
        type: actionTypes.CHANGE_FEATURE_USERNAME,
        payload: { value: value }
    };
};

export const featureSetupSetValidationMessages = (validationMessages) => {
    return {
        type: actionTypes.FEATURE_SETUP_SET_VALIDATION_MESSAGES,
        payload: { validationMessages: validationMessages }
    };
};

export const resetStateFeatureSetup = () => {
    return {
        type: actionTypes.RESET_STATE_FEATURE_SETUP
    };
};

export const resetEmailConfirmationState = () => {
    return {
        type: actionTypes.RESET_EMAIL_CONFIRMATION_STATE
    };
};
