import DOMPurify from 'dompurify';
import { useCallback } from 'react';
import ErrorPage from '../../../common/features/ErrorPage';
import Spinner from '../../../common/features/Spinner';
import { fetchHtmlFile } from '../../../common/functions/utils';
import { useFetch } from '../../../common/hooks/useFetch';
import useTranslation from '../../../common/hooks/useTranslation';
import CollapsableCard from '../common/components/CollapsableCard';

const createQuestions = (id: string, total: number) =>
    Array.from({ length: total }, (_, index) => ({
        question: `${id}-${String(index + 1).padStart(2, '0')}q`,
        answer: `${id}-${String(index + 1).padStart(2, '0')}a`
    }));

const SECTIONS = [
    {
        id: 'user-management',
        label: 'UserManagement_Label',
        questions: createQuestions('user-management', 5)
    },
    {
        id: 'client-access',
        label: 'ClientAccess_Label',
        questions: createQuestions('client-access', 4)
    },
    {
        id: 'payments',
        label: 'Payments_Label',
        questions: createQuestions('payments', 13)
    },
    {
        id: 'snow',
        label: 'SNOW_Label',
        questions: createQuestions('snow', 2)
    },
    {
        id: 'data-availability',
        label: 'DataAvailability_Label',
        questions: createQuestions('data-availability', 4)
    }
];

export default function FaqGuideContent() {
    const translate = useTranslation();

    const fetchFiles = useCallback(() => {
        return Promise.all(
            SECTIONS.map(async (section) => {
                const questionsWithContent = await Promise.all(
                    section.questions.map(async ({ question, answer }) => {
                        const questionFilePath = `/data/faq/en/${question}.html`;
                        const answerFilePath = `/data/faq/en/${answer}.html`;

                        const [questionContent, answerContent] = await Promise.all([
                            fetchHtmlFile(questionFilePath),
                            fetchHtmlFile(answerFilePath)
                        ]);

                        return {
                            question: questionContent,
                            answer: answerContent
                        };
                    })
                );

                return {
                    ...section,
                    questions: questionsWithContent
                };
            })
        );
    }, []);

    const { data, error, isFetching } = useFetch(fetchFiles);

    if (isFetching) {
        return (
            <div className='d-flex justify-content-center align-items-center'>
                <Spinner />
            </div>
        );
    }

    if (error) {
        return <ErrorPage errorCode={500} withTranslations />;
    }

    return (
        <>
            {data?.map(({ id, label, questions }) => (
                <section key={id} className='d-flex flex-column my-4' style={{ gap: '1rem' }}>
                    <h2 className='mt-4'>{translate(label)}</h2>
                    {questions.map(({ question, answer }, index) => (
                        <CollapsableCard key={index} title={question}>
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(answer) }} />
                        </CollapsableCard>
                    ))}
                </section>
            ))}
        </>
    );
}
