import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { memo } from 'react';
import CarrierIcon from '../../../../common/features/CarrierIcon';
import { MOTCodes, MOTMap } from '../../../clearances/common/constants/constants-clearances';
import { dateConvert, numberWithCommas } from '../../utils/complete-entry-data-functions';
import { ShipmentsCASS } from '../models/completeEntryDataModels';
import ActionButton from './ActionButton';

interface FormattedGridCellProps extends GridCellProps {
    dataItem: ShipmentsCASS;
    checkboxChange?: (payload: CheckboxChangeEvent) => void;
    selectedEntries?: string[];
}

const FormattedGridCell = memo(function FormattedGridCellProps({
    field = '',
    dataItem,
    checkboxChange,
    selectionChange,
    selectedEntries,
    className,
    style,
    ...rest
}: FormattedGridCellProps) {
    let cellContent: any;

    switch (field) {
        case 'selected':
            cellContent = (
                <Checkbox
                    name={dataItem.TransactionNumber}
                    disabled={false}
                    value={selectedEntries?.includes(dataItem.TransactionNumber)}
                    onChange={checkboxChange}
                />
            );
            break;
        case 'Mot':
            cellContent = <CarrierIcon mot={MOTCodes[dataItem.Mot as keyof typeof MOTCodes]} MOTMap={MOTMap} />;
            break;
        case 'Actions':
            cellContent = (
                <ActionButton
                    placement='left-start'
                    dataItem={dataItem}
                    disabled={selectedEntries?.includes(dataItem.TransactionNumber)!}
                    {...rest}
                />
            );
            break;
        case 'ReleaseDate':
            cellContent = dataItem?.ReleaseDate && dateConvert(dataItem.ReleaseDate);
            break;
        case 'CadAcceptDtTime':
            cellContent = dataItem?.CadAcceptDtTime && dateConvert(dataItem.CadAcceptDtTime);
            break;
        case 'AccountingDate':
            cellContent = dataItem?.AccountingDate && dateConvert(dataItem.AccountingDate);
            break;
        case 'PayDueDate':
            cellContent = dataItem?.PayDueDate && dateConvert(dataItem.PayDueDate);
            break;
        case 'GrossWeight':
            cellContent = dataItem?.GrossWeight && numberWithCommas(dataItem.GrossWeight);
            break;
        case 'FreightAmt':
            cellContent = dataItem?.FreightAmt && numberWithCommas(dataItem.FreightAmt);
            break;
        case 'InvSeqNo':
            cellContent = dataItem?.InvSeqNo && numberWithCommas(dataItem.InvSeqNo);
            break;
        case 'TotalInvAmount':
            cellContent =
                dataItem?.TotalInvAmount && dataItem.TotalInvAmount?.toString().includes('.')
                    ? numberWithCommas(dataItem.TotalInvAmount)
                    : dataItem?.TotalInvAmount && `${numberWithCommas(dataItem.TotalInvAmount)?.toString()}.00`;
            break;
        default:
            cellContent = dataItem[field as keyof typeof dataItem];
    }

    return (
        <td
            className={`${className} ${
                selectedEntries?.find((selected) => selected === dataItem?.TransactionNumber)
                    ? 'k-master-row k-state-selected'
                    : 'k-master-row'
            }`}
            style={{
                ...style,
                textAlign: field === 'selected' ? 'center' : 'left',
                whiteSpace: 'nowrap',
                paddingTop: '1.85vh',
                paddingBottom: '1.85vh',
                position: field === 'Actions' ? 'sticky' : 'static',
                backgroundColor: 'white',
                cursor: field === 'selected' || field === 'Actions' ? 'pointer' : 'default'
            }}
        >
            {cellContent}
        </td>
    );
});

export default FormattedGridCell;
