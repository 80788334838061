import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../../../redux/api/apiSlice';
import alertsReducer from '../../../redux/reducers/alertsSlice';
import clientSelectionReducer from '../../../redux/reducers/clientSelectionReducer';
import hydrationReducer from '../../../redux/reducers/hydrationSlice';
import languageReducer from '../../../redux/reducers/languageSlice';
import toastReducer from '../../../redux/reducers/toastSlice';
import translationsReducer from '../../../redux/reducers/translationsSlice';
import editSubscriptiosSubscribersSlice from './reducers/editSubscriptiosSubscribersSlice';
import searchFilterReducer from './reducers/searchFilterSlice';
import statusFilterReducer from './reducers/statusFilterSlice';
import subscribersGridSettingsReducer from './reducers/subscribersGridSettingsSlice';
import subscribersSearchFilterSlice from './reducers/subscribersSearchFilterSlice';
import subscribersReducer from './reducers/subscribersSlice';
import subscribersStatusFilterReducer from './reducers/subscribersStatusFilterSlice';

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        // common slices
        hydration: hydrationReducer,
        translations: translationsReducer,
        language: languageReducer,
        alerts: alertsReducer,
        clientSelection: clientSelectionReducer,
        toast: toastReducer,

        // page specific slices
        statusFilter: statusFilterReducer,
        searchFilter: searchFilterReducer,
        subscribersStatusFilter: subscribersStatusFilterReducer,
        subscribersSearchFilter: subscribersSearchFilterSlice,
        subscribersGridSettings: subscribersGridSettingsReducer,
        subscribers: subscribersReducer,
        editSubscriptionsSubscriber: editSubscriptiosSubscribersSlice
    },
    middleware: (gDM) => gDM().concat(apiSlice.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
