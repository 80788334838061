import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { format } from 'react-string-format';
import { batchStatusType } from '../../../constants/mlp-constants';
import * as actionCreators from '../../../store/actions/index';
import BatchJobCancelCompleteLobby from '../batchJobCancelCompleteLobby';
import BatchJobConfirmedSuccess from '../BatchJobConfirmedSuccess';
import BatchJobExpired from '../BatchJobExpired';
import BatchJobInProgress from '../BatchJobInProgress';
import UnexpectedProcessingError from '../UnexpectedProcessingError';
import BatchEditForm from './BatchEditForm';
import BatchUploadEditForm from './BatchUploadEditForm';

class BatchUserEditRouter extends Component {
    componentDidMount = () => {
        this.props.onInitTranslations('SystemAdmin.BatchUser.Edit');
        setInterval(this.initiateStatusCheck, 3000);
        if (this.props.location.search) {
            const batchJobId = this.props.location.search.split('=')[1];
            this.props.onInitGetBatchJobEdit(batchJobId);
        }
    };

    componentWillUnmount() {
        this.props.onClearState();
    }

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
    }

    initiateStatusCheck = () => {
        const batchJob = this.props.model;
        if (batchJob && batchJob.BatchJobId && batchJob.BatchJobId > 0) {
            switch (batchJob.BatchStatusCode) {
                case batchStatusType.New:
                case batchStatusType.InProgress:
                case batchStatusType.Submitted:
                case batchStatusType.Cancelling:
                case batchStatusType.Completing:
                case batchStatusType.Approved:
                case batchStatusType.Rejected:
                    this.props.onGetBatchJobEdit(this.props.model.BatchJobId);
                    break;
                default:
                    break;
            }
        }
    };

    showUploadPage = () => {
        this.props.onAfterGetBatchJobEdit(null);
    };

    render() {
        const batchJob = this.props.model;
        let componentByStatus = null;
        if (batchJob === undefined || batchJob === null || Object.keys(batchJob).length === 0) {
            componentByStatus = <BatchUploadEditForm />;
        } else {
            switch (batchJob.BatchStatusCode) {
                case batchStatusType.Submitted:
                case batchStatusType.InProgress:
                    componentByStatus = <BatchJobInProgress title={this.props.translations.Phrases['BatchEditProcessing_Label']} />;
                    break;

                case batchStatusType.FaultTerminated:
                    componentByStatus = (
                        <UnexpectedProcessingError
                            title={this.props.translations.Phrases['ProcessingError_Label']}
                            tryAgain={this.showUploadPage}
                            errorDetails={batchJob.Summary.ErrorDetails}
                            failedEmails={batchJob.Summary.FailedEmails}
                        />
                    );
                    break;

                case batchStatusType.ExpirationTerminated:
                    componentByStatus = <BatchJobExpired title={this.props.translations.Phrases['BatchUserEditExpired_Label']} />;
                    break;

                case batchStatusType.PendingApproval:
                    componentByStatus = <BatchEditForm />;
                    break;

                case batchStatusType.Cancelled:
                    componentByStatus = (
                        <BatchJobCancelCompleteLobby title={this.props.translations.Phrases['BatchUserEditCancelled_Label']} />
                    );
                    break;

                case batchStatusType.Cancelling:
                    componentByStatus = (
                        <BatchJobCancelCompleteLobby title={this.props.translations.Phrases['BatchUserEditCancelling_Label']} />
                    );
                    break;

                case batchStatusType.Completing:
                    componentByStatus = (
                        <BatchJobCancelCompleteLobby title={this.props.translations.Phrases['BatchUserEditCompleting_Label']} />
                    );
                    break;

                case batchStatusType.Completed:
                    const message =
                        batchJob.Summary.TotalRows > 0
                            ? format(this.props.translations.Phrases['SentMessagesToUsers_Label'], batchJob.Summary.TotalRows)
                            : '';
                    componentByStatus = (
                        <BatchJobConfirmedSuccess
                            title={this.props.translations.Phrases['BatchUserEditCompleted_Label']}
                            message={message}
                        />
                    );
                    break;

                default:
                    componentByStatus = '';
            }
        }
        return (
            <div id='page'>
                <HelmetProvider>
                    <Helmet>
                        <title>{this.props.translations.Phrases['Page_Title']}</title>
                    </Helmet>
                </HelmetProvider>
                <div className='page-wrapper'>
                    <div className='page-top-row'>
                        <div className='page-header-section'>
                            <nav className='mlp-breadcrumb'>
                                <Link to='/Dashboard' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                </Link>
                                <i className='fa fa-caret-right'></i>
                                <Link to='/SystemAdmin' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_2']}
                                </Link>
                                <i className='fa fa-caret-right'></i>
                                <span> {this.props.translations.Phrases['Page_Header_Navigation_Level_3']}</span>
                            </nav>
                            <h1 className='bold-title'>{this.props.translations.Phrases['Page_Header']}</h1>
                        </div>
                    </div>
                    <div className='content-wrapper'>
                        <div className='row page-content'>
                            <div id='batch-edit-user-form' className='w-100'>
                                {componentByStatus}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        model: state.batchEditUsers.model,
        showUploadPage: state.batchEditUsers.showUploadPage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup)),
        onInitGetBatchJobEdit: (batchJobId) => dispatch(actionCreators.initGetBatchJobEdit(batchJobId)),
        onGetBatchJobEdit: (batchJobId) => dispatch(actionCreators.getBatchJobEdit(batchJobId)),
        onClearState: () => dispatch(actionCreators.clearStateBatchJobEdit()),
        onAfterGetBatchJobEdit: (model) => dispatch(actionCreators.afterGetBatchJob(model))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchUserEditRouter);
