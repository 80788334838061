import { Component } from 'react';
import { connect } from 'react-redux';

class NestedNavigation extends Component {
    render() {
        if (this.props.data) {
            let navigationItems = this.props.data.map((item, index) => {
                let itemId = item.id;
                let cssClassItemWithChildren = `${this.props.itemCssClass} ${this.props.itemCssChildrenClass} lii_${itemId}`;
                let cssClassItem = `${this.props.itemCssClass} lii_${itemId}`;
                let itemCssClass = item.items ? cssClassItemWithChildren : cssClassItem;

                return (
                    <li key={index.toString()} id={itemId} className={itemCssClass}>
                        <a target='_blank' rel='noopener noreferrer' className='main' href={item.url}>
                            <span>{this.props.translations.Phrases[item.text]}</span>
                            <span className={item.displayCarot ? 'd-inline arrow' : 'd-none'}>
                                <i className='fa fa-caret-right'></i>
                            </span>
                        </a>
                    </li>
                );
            });

            return (
                <ul className={this.props.listCssClass}>
                    {navigationItems}
                    {this.props.children}
                </ul>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.headerModel
    };
};
export default connect(mapStateToProps)(NestedNavigation);
