import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { REACT_APP_ASSETS_URL_CSS } from '../../common/constants/constants-portal';
import ToastProvider from '../../common/features/Toast/ToastProvider';
import App from './App';
import store from './redux/store';

const Portal = () => {
    return (
        <Provider store={store}>
            <ToastProvider>
                <HelmetProvider>
                    <Helmet>
                        <link rel='stylesheet' href={REACT_APP_ASSETS_URL_CSS} />
                    </Helmet>
                    <App />
                </HelmetProvider>
            </ToastProvider>
        </Provider>
    );
};

export default Portal;
