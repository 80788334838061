import axios from 'axios';
import axiosInstance from '../../../axios-mlp';
import { LanguageCodes, webAPIbaseURL } from '../../../constants/mlp-constants';
import * as actionTypes from './translationsActionTypes';

export const setTranslations = (viewModel) => {
    return {
        type: actionTypes.SET_TRANSLATIONS,
        model: viewModel
    };
};

export const setHeaderTranslations = (viewModel) => {
    return {
        type: actionTypes.SET_HEADER_TRANSLATIONS,
        model: viewModel
    };
};

export const setTranslationsUnavailableFlag = (flag) => {
    return {
        type: actionTypes.SET_TRANSLATIONS_UNAVAILABLE,
        flag: flag
    };
};

export const initTranslations = (phraseGroup, langCode = LanguageCodes.English) => {
    return (dispatch) => {
        axiosInstance
            .get('/Translations/Index', { params: { phraseGroup: phraseGroup, languageCode: langCode } })
            .then((response) => {
                if (response.data.PhraseGroupCode === 'MLP.Header') dispatch(setHeaderTranslations(response.data));
                else dispatch(setTranslations(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            });
    };
};

export const initTranslationsWithSetFlag = (phraseGroup, langCode = LanguageCodes.English) => {
    return (dispatch) => {
        const axiosWithoutInterceptors = axios.create({
            baseURL: webAPIbaseURL
        });
        axiosWithoutInterceptors
            .get('/Translations/Index', { params: { phraseGroup: phraseGroup, languageCode: langCode } })
            .then((response) => {
                dispatch(setTranslations(response.data));
            })
            .catch((error) => {
                dispatch(setTranslationsUnavailableFlag(true));
            });
    };
};
