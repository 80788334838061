import useTranslation from '../../../../common/hooks/useTranslation';
import classes from './ClientsAndPermissionsTableHeaderCell.module.css';

interface ClientsAndPermissionsTableHeaderCellProps {
    adminRole: string | null | undefined;
    featureName: string;
    isDisabled?: boolean;
    selectAll: Record<string, boolean>;
    onToggleSelectAll: (featureName: string) => void;
}

const ClientsAndPermissionsTableHeaderCell: React.FC<ClientsAndPermissionsTableHeaderCellProps> = ({
    adminRole,
    featureName,
    isDisabled,
    selectAll,
    onToggleSelectAll
}) => {
    const translate = useTranslation();

    const label = !selectAll[featureName] ? 'SelectAll_Label' : 'DeselectAll_Label';

    return (
        <div className='d-flex flex-column align-items-center'>
            <div className='text-center pb-1'>
                {adminRole === 'CentralAdmin' && featureName === 'My Livingston Portal' ? 'Clearances' : featureName}
            </div>
            <button
                type='button'
                className={`btn p-0 table-column-select-all ${classes.btn}`}
                disabled={isDisabled}
                onClick={onToggleSelectAll.bind(null, featureName)}
            >
                {translate(label)}
            </button>
        </div>
    );
};

export default ClientsAndPermissionsTableHeaderCell;
