import axios from '../../../axios-mlp';
import * as actionTypes from './menuActionTypes';

export const setMenu = (viewModel) => {
    return {
        type: actionTypes.SET_MENU,
        model: viewModel
    };
};

export const initMenu = () => {
    return (dispatch) => {
        const token = localStorage.getItem('token');
        axios.get(`/Menu/GetMenu?token=${token}`).then((response) => {
            dispatch(setMenu(response.data));
        });
    };
};

export const openModal = () => {
    return {
        type: actionTypes.OPEN_MODAL
    };
};

export const closeModal = () => {
    return {
        type: actionTypes.CLOSE_MODAL
    };
};
