import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { NavLinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import classes from './HeaderNavItem.module.css';

interface HeaderNavItemProps extends Pick<NavLinkProps, 'target'> {
    url: string;
    title: string;
    icon: IconDefinition;
    onClick?: () => void;
}

const HeaderNavItem: React.FC<HeaderNavItemProps> = ({ url, title, icon, target, onClick }) => {
    return (
        <NavLink
            className={(isActive) =>
                `dropdown-item d-flex justify-content-between align-items-stretch ${isActive ? `active ${classes.active}` : ''}`
            }
            to={url}
            target={target}
            rel={target === '_blank' ? 'noopener noreferrer' : undefined}
            onClick={onClick}
        >
            <div className='mr-3 d-flex align-items-center'>
                <FontAwesomeIcon icon={icon} className='mr-3' />
                {title}
            </div>
        </NavLink>
    );
};

export default HeaderNavItem;
