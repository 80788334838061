import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ReactGA from 'react-ga';
import { format } from 'react-string-format';

dayjs.extend(relativeTime);

// helper for parsing Bill Of Lading
export function parseBillOfLading(billOfLading: null | undefined | { [key: string]: string | null | undefined }[], types: string[]) {
    let masterBills: string[] = [];
    let houseBills: { [key: string]: string } = {};

    if (types.includes('MasterBill')) {
        billOfLading?.forEach((bill: { [key: string]: string | null | undefined }) => {
            if (bill['MasterBill'] !== '' && bill['MasterBill']) masterBills.push(bill['MasterBill']);
        });
    }

    if (types.includes('HouseBill')) {
        billOfLading?.forEach((bill: { [key: string]: string | null | undefined }) => {
            if (bill['HouseBill'] !== '' && bill['HouseBill']) houseBills[bill['MasterBill'] + bill['HouseBill']] = bill['HouseBill'];
        });
    }

    return [...masterBills, ...Object.values(houseBills)].toString().replaceAll(',', ', ');
}

// helper for parsing datetime
export function parseDateTime(datetime: string | null | undefined) {
    return datetime ? dayjs(datetime).utc().local().format('MM/DD/YYYY hh:mm A') : '';
}
export function parseDateTimeET(datetime: string | null | undefined) {
    return datetime ? dayjs(datetime).format('MM/DD/YYYY hh:mm A') : '';
}
export function parseDate(datetime: string | null | undefined) {
    return datetime ? dayjs(datetime).utc().local().format('MM/DD/YYYY') : '';
}
// helper for building time string (alerts, notices)
export function buildTimeStringForAlert(datetime: string | null | undefined, languageCode: string = 'en') {
    // if datetime is null or undefined return an empty string
    if (!datetime) {
        return '';
    }

    let [number, timeUnit, ago] = dayjs(datetime).fromNow().split(' ');

    // check for seconds, minutes or hours
    // edge case: 'a few seconds ago'
    if (timeUnit === 'few' || timeUnit.includes('second') || timeUnit.includes('minute') || timeUnit.includes('hour')) {
        return languageCode === 'en' ? '1d ago' : 'il y a 1j';
    }

    // if dateTime is in the future because of the timezone difference return 1d ago
    if (number === 'in') {
        return languageCode === 'en' ? '1d ago' : 'il y a 1j';
    }

    // convert 'a' to '1' ex. a day ago
    if (number === 'a') number = '1';

    let timeUnitFirstChar = timeUnit.charAt(0);

    // convert days, weeks, and years to french (first characters differ from english)
    // 1d === 1j
    // 1w === 1sem
    // 1m === 1m
    // 1y === 1a
    if (languageCode === 'fr') {
        if (timeUnitFirstChar === 'd') {
            timeUnitFirstChar = 'j';
        } else if (timeUnitFirstChar === 'w') {
            timeUnitFirstChar = 'sem';
        } else if (timeUnitFirstChar === 'y') {
            timeUnitFirstChar = 'a';
        }
    }

    // return new concatenated string
    return languageCode === 'en' ? number + timeUnitFirstChar + ' ' + ago : 'il y a ' + number + timeUnitFirstChar + ' ';
}

// helper to track link redirects with GA
export function linkWithGA(link: any, category: any, label: any) {
    ReactGA.event({
        category: category,
        action: 'click',
        label: label
    });
}

// https://stackoverflow.com/questions/27699493/javascript-partially-applied-function-how-to-bind-only-the-2nd-parameter - modified
export const bind_args_from_n = function (fn: any, n: any, bound_arg: any) {
    return function (...args: any) {
        return fn(...args.slice(0, n - 1), bound_arg, ...args.slice(1));
    };
};

// Borrowed from MLP Web Client codebase
export const formatTranslated = function (toTranslate: any, mlpTranslations: any, ...subs: any) {
    if (toTranslate === undefined || toTranslate === '' || mlpTranslations === undefined) {
        return '';
    }

    const indexDouble = toTranslate.indexOf('@@');
    let translatedArr;
    let key;
    if (indexDouble >= 0) {
        translatedArr = toTranslate.split('@@');
        key = translatedArr[0];
    } else {
        translatedArr = Array.prototype.slice.call(arguments);
        key = translatedArr.shift();
    }

    if (mlpTranslations[key] === undefined) {
        return 'Untranslated:[' + key + ']';
    } else if (subs.length > 0) {
        return format(mlpTranslations[key].trim(), ...subs);
    } else if (translatedArr.length === 3) {
        return format(mlpTranslations[key].trim(), translatedArr[1], translatedArr[2]);
    } else if (translatedArr.length === 2) {
        return format(mlpTranslations[key].trim(), translatedArr[1]);
    } else {
        return mlpTranslations[key].trim();
    }
};

//Mask format phone number
export const maskFormater = function (e: any) {
    let x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return (e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : ''));
};

// Format the date as "Tuesday, July 12, 2022 at 10:14 AM"
export const createDateForMilestones = (date?: string | null, locale: string = 'en-US') => {
    if (!date) return '';
    return new Date(date).toLocaleDateString(locale, {
        weekday: 'long',
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
};
