import { Component } from 'react';
import { connect } from 'react-redux';
import { formatTranslated } from '../../../utils/mlp-utils';
import SearchGridRowDetailsDocumentGrid from './SearchGridRowDetailsDocumentGrid';
import SearchGridRowMoreDetails from './SearchGridRowMoreDetails';

class SearchGridRowDetails extends Component {
    state = {
        showDetails: false,
        showDocuments: true
    };

    selectDocuments = () => {
        this.setState({
            showDetails: false,
            showDocuments: true
        });
    };

    selectDetails = () => {
        this.setState({
            showDetails: true,
            showDocuments: false
        });
    };

    selectDocumentsClass = () => {
        let elementClass = ['imaging-nav-item'];
        if (this.state.showDocuments) {
            elementClass.push('selected');
        }
        return elementClass.join(' ');
    };

    selectDetailsClass = () => {
        let elementClass = ['imaging-nav-item'];
        if (this.state.showDetails) {
            elementClass.push('selected');
        }
        return elementClass.join(' ');
    };

    render() {
        const selection = this.state.showDocuments ? (
            <SearchGridRowDetailsDocumentGrid
                documents={this.props.entry.documents}
                groupIndex={this.props.groupIndex}
                selectedLimitReached={this.props.selectedLimitReached}
                entry={this.props.entry}
                gridModel={this.props.gridModel}
            />
        ) : (
            <SearchGridRowMoreDetails entry={this.props.entry} />
        );

        const moreDetails = this.props.entry.metadata.some((x) => x.isVisibleOnGridDetails) ? (
            <div className={this.selectDetailsClass()} onClick={this.selectDetails}>
                <div>
                    <span>{formatTranslated('MoreDetails_Label', this.props.translations.Phrases)}</span>
                </div>
            </div>
        ) : null;

        return (
            <div className='entry-details-container'>
                <div className='row imaging-nav-container'>
                    <div className={this.selectDocumentsClass()} onClick={this.selectDocuments}>
                        <div>
                            <span>{formatTranslated('Documents_Label', this.props.translations.Phrases)}</span>
                        </div>
                    </div>
                    {moreDetails}
                </div>
                <div className='col-sm-12'>{selection}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(SearchGridRowDetails);
