import $ from 'jquery';
import 'jquery-mask-plugin';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import InputBase from '../Inputbase/Inputbase';

class Textarea extends Component {
    textAreaRef = React.createRef();

    static defaultProps = {
        value: '',
        name: 'defaulttxt',
        type: 'text'
    };

    static propTypes = {
        customAction: PropTypes.func,
        onValueChanged: PropTypes.func,
        value: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        inputMask: PropTypes.string,
        rows: PropTypes.string,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        sanitize: PropTypes.bool,
        allowEnter: PropTypes.string
    };

    onBlur = (event) => {
        let output = event.target.value;
        if (this.props.sanitize) {
            output = output
                .replace(/<script[^>]*?>.*?<\/script>/gi, '')
                .replace(/<[/!]*?[^<>]*?>/gi, '')
                .replace(/<style[^>]*?>.*?<\/style>/gi, '')
                .replace(/<![\s\S]*?--[ \t\n\r]*>/gi, '');
        }
        if (this.props.customAction) {
            this.props.customAction(output, event.target.value);
        }
        this.props.onValueChanged(output, event.target.name);
    };

    handleChange = (event) => {
        this.props.onValueChanged(event.target.value, event.target.name);
    };

    componentDidMount = () => {
        if (this.props.inputMask) {
            this.setInputMask();
        }
    };

    setInputMask = () => {
        $(ReactDOM.findDOMNode(this.textAreaRef.current)).mask(this.props.inputMask);
    };

    render() {
        return (
            <InputBase {...this.props} allowEnter={this.props.allowEnter}>
                <textarea
                    ref={this.textAreaRef}
                    rows={this.props.rows || 2}
                    type={this.props.type}
                    name={this.props.name}
                    value={this.props.value}
                    onChange={this.handleChange}
                    className='form-control'
                    disabled={this.props.disabled}
                    readOnly={this.props.readonly}
                    onBlur={this.onBlur}
                ></textarea>

                {this.props.children}
            </InputBase>
        );
    }
}

export default Textarea;
