import produce from 'immer';
import { setValueByPath } from '../../utils/mlp-utils';
import * as actionTypes from '../actions/clientAdmin/clientAdminActionTypes';

const initialState = {
    signup: {
        Model: {},
        ValidationMessages: null
    }
};

const setSignup = (state, action) => {
    return produce(state, (draft) => {
        draft.signup.Model = action.model;
        let valMsgs = {};
        for (let prop in draft.signup.Model) {
            if (Object.prototype.hasOwnProperty.call(draft.signup.Model, prop)) {
                setValueByPath(valMsgs, prop, '');
            }
        }
        draft.signup.ValidationMessages = valMsgs;
    });
};

const signupModelChange = (state, action) => {
    return produce(state, (draft) => {
        for (let prop in draft.signup.Model) {
            if (Object.prototype.hasOwnProperty.call(draft.signup.Model, prop)) {
                if (prop === action.payload.key) {
                    draft.signup.Model[prop] = action.payload.value;
                    if (!draft.signup.Model.ChangedProperties.includes(action.payload.key)) {
                        draft.signup.Model.ChangedProperties.push(action.payload.key);
                    }
                    if (action.payload.key === 'ClientNumbers') {
                        draft.signup.Model.ClientNumbersNotKnown = action.payload.value.length > 0 ? false : true;
                    }
                    if (action.payload.key === 'ClientNumbersNotKnown') {
                        if (action.payload.value) draft.signup.Model.ClientNumbers = [];
                    }
                }
            }
        }
        draft.signup.Model.RequestedFeaturesLeave = false;
    });
};

const requestedFeatureChange = (state, action) => {
    return produce(state, (draft) => {
        const changedFeature = draft.signup.Model.RequestedFeatures.filter(
            (rf) => rf.RequestedFeatureId === action.payload.item.RequestedFeatureId
        )[0];
        if (changedFeature) changedFeature.IsSelected = action.payload.checked;
        if (!draft.signup.Model.ChangedProperties.includes('RequestedFeatures')) {
            draft.signup.Model.ChangedProperties.push('RequestedFeatures');
        }
        draft.signup.Model.RequestedFeaturesLeave = true;
    });
};

const setSignupValidationMessages = (state, action) => {
    return produce(state, (draft) => {
        for (let prop in draft.signup.ValidationMessages) {
            if (Object.prototype.hasOwnProperty.call(draft.signup.ValidationMessages, prop)) {
                if (action.newValidationMessages.hasOwnProperty(prop)) {
                    draft.signup.ValidationMessages[prop] = action.newValidationMessages[prop];
                } else {
                    draft.signup.ValidationMessages[prop] = '';
                }
            }
        }
    });
};

const setLanguageSelection = (state, action) => {
    return produce(state, (draft) => {
        if (draft) {
            draft.signup.Model.LanguageCode = action.selectedLanguage;
        }
    });
};

const resetStateClientAdmin = (state) => {
    return produce(state, (draft) => {
        if (draft) {
            draft.signup.Model = {};
            draft.signup.ValidationMessages = null;
        }
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SIGNUP:
            return setSignup(state, action);
        case actionTypes.CHANGE_SIGNUP_MODEL:
            return signupModelChange(state, action);
        case actionTypes.CHANGE_REQUESTED_FEATURES:
            return requestedFeatureChange(state, action);
        case actionTypes.SET_LANGUAGE_SELECTION:
            return setLanguageSelection(state, action);
        case actionTypes.SET_SIGNUP_VALIDATION_MESSAGES:
            return setSignupValidationMessages(state, action);
        case actionTypes.RESET_STATE_CLIENT_ADMIN:
            return resetStateClientAdmin(state);
        default:
            return state;
    }
};

export default reducer;
