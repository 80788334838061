// Labels for header and line details

export const DETAILS_LABELS: { [k: string]: string } = {
    // Line details

    CountryExportCode: 'CountryExportCode_Label',
    CountryExportDesc: 'CountryExportDesc_Label',
    ManufacturerID: 'ManufacturerID_Label',
    ManufacturerName: 'ManufacturerName_Label',
    SupplierCode: 'SupplierCode_Label',
    GrossWeight: 'GrossWeight_Label',
    DestinationCode: 'DestinationCode_Label',
    QtyComm: 'QtyComm_Label',
    PartNo: 'PartNo_Label',
    PartDesc: 'PartDesc_Label',
    CountryOriginCode: 'CountryOriginCode_Label',
    CountryOriginDesc: 'CountryOriginDesc_Label',
    HTSNumber: 'TariffNo_Label',
    HTSDesc: 'TariffDesc_Label',
    SPIIndicatator: 'SPIIndicatator_Label',
    DutyRate: 'RateAdvalorem_Label',
    ValueEnteredUS: 'ValueEntered_Label',
    Currency: 'Currency_Label',
    ExchangeRate: 'ExchangeRate_Label',
    ValueUS: 'ValueUS_Label',
    NonDutiableAmt: 'NonDutiableAmt_Label',
    MiscDiscount: 'MiscDiscount_Label',
    AdjToEnterValueAmt: 'AdjToEnterValueAmt_Label',
    CashDiscountAmt: 'CashDiscountAmt_Label',
    FreightAmt: 'FreightAmtIncl_Label',
    OtherAdjAmt: 'OtherAdjAmt_Label',
    LineEnteredValue: 'ValueEnteredUS_Label',
    LineDuty: 'DutyLineTot_Label',
    LineMPF: 'LineMPF_Label',
    LineProMPF: 'LineProMPF_Label',
    LineHMF: 'LineHMF_Label',
    LineOtherFees: 'LineOtherFees_Label',
    LineDutyAndFees: 'LineDutyAndFees_Label',
    LineQty: 'LineQty_Label',
    LineQty2: 'LineQty2_Label',
    LineQty3: 'LineQty3_Label',
    LineUOM: 'LineUOM_Label',
    LineUOM2: 'LineUOM2_Label',
    LineUOM3: 'LineUOM3_Label',
    ADADepRate: 'ADADepRate_Label',
    ADADutyAmt: 'ADADutyAmt_Label',
    CVDCaseNo: 'CVDCaseNo_Label',
    CVDDepRate: 'CVDDepRate_Label',
    CVDDutyAmt: 'CVDDutyAmt_Label',

    // Header details
    EntryNumber: 'EntryNumber_Label',
    EntryType: 'HeaderEntryType_Label',
    Status: 'Status_Label',
    CustomerRefNo: 'CustomerReferenceNumber_Label',
    FileNo: 'LivingstonFileNo_Label',
    DocsReceived: 'DocumentsRecievedDate_Label',
    ISFAccepted: 'ISFAcceptedDate_Label',
    CustomsCertified: 'CustomsCertifiedDate_Label',
    CustomsReleased: 'CustomsReleasedDate_Label',
    EntrySummaryAccepted: 'EntrySummaryAcceptedDate_Label',
    LiquidationDate: 'LiquidationDate_Label',
    TotalEnteredDutyValue: 'TotalEnteredDutyValue_Label',
    TotalDuty: 'TotalDuty_Label',
    TotalHMF: 'TotalHMF_Label',
    TotalMPF: 'TotalMPF_Label',
    TotalEntryDutiesAndFees: 'TotalEntryDuties_Lable',
    PieceCount: 'PieceCount_Label',
    CountryOfOrigin: 'CountryOriginDesc_Label',
    CountryOfOriginCode: 'CountryOriginCode_Label',
    ImporterName: 'ImporterName_Label',
    ImporterNumber: 'ImporterNumber_Label',
    ModeTrans: 'ModeOfTransport_Label',
    CarrierSCAC: 'CarrierSCAC_Label',
    TransitBondNo: 'TransitBondNo_Label',
    HouseBill: 'HouseBillOfLading_Label',
    HouseSCAC: 'HouseSCAC_Label',
    MasterBill: 'MasterBillOfLading_Label',
    ContainerNumber: 'ContainerNumber_Label',
    VesselNumber: 'VesselNumber_Label',
    VoyageNo: 'VoyageNumber_Label',
    ConsigneeName: 'ConsigneeName_Label',
    ConsigneeNumber: 'ConsigneeNumber_Label',
    DestinationState: 'DestinationState_Label',
    EntryCodePort: 'EntryCodePort_Label',
    EntryNamePort: 'EntryNamePort_Label',
    UnlandingCodePort: 'UnlandingCodePort_Label',
    UnlandingNamePort: 'UnlandingNamePort_Label',
    FilerCode: 'FilerCode_Label',
    ClientNumber: 'ClientNumber_Label',
    BillInvoiceNo: 'BillInvoiceNo_Label',
    BillInvoiceSuffix: 'BillInvoiceSuffix_Label'
};
