import { Component, React } from 'react';
import { connect } from 'react-redux';
import WidgetSelectorGridRow from './WidgetSelectorGridRow';

class WidgetSelectorGridSection extends Component {
    render() {
        let rows = this.props.widgets.map((val) => {
            return <WidgetSelectorGridRow key={val.Widget.Name} data={val} onWidgetSelect={this.props.onWidgetSelect} />;
        });

        return (
            <div>
                <div className='col-sm-12 widget-selector-menu_header'>
                    <h4>{this.props.title}</h4>
                </div>
                <div className='col-sm-12'>{rows}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(WidgetSelectorGridSection);
