import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { initializeGTM } from '../../../utils/gtm';
import { API_HYDRATION_PATH, LanguageCodes, Translations } from '../../common/constants/constants-portal';
import AlertsDrawer from '../../common/features/Alerts/AlertsDrawer';
import AlertsToasts from '../../common/features/Alerts/AlertsToasts';
import ErrorPage from '../../common/features/ErrorPage';
import HeaderNav from '../../common/features/HeaderNav/HeaderNav';
import type { SidebarActions } from '../../common/features/Sidebar/Sidebar';
import Sidebar from '../../common/features/Sidebar/Sidebar';
import Spinner from '../../common/features/Spinner';
import { isFetchBaseQueryError } from '../../common/functions/utils';
import useAlerts from '../../common/hooks/useAlerts';
import usePageLoad from '../../common/hooks/usePageLoad';
import useTranslationHydration from '../../common/hooks/useTranslationHydration';
import { ChangeLanguage, ChangeLanguageHydration, GetTranslations } from '../../redux/actions/translations';
import { useHydrationQuery } from '../../redux/api/hydrationApi';
import { resetChooser } from '../../redux/reducers/clientSelectionReducer';
import { setLanguageSelected } from '../../redux/reducers/languageSlice';
import Profile from './Profile';
import { useProfileDispatch, useProfileSelector } from './redux/hooks';

const App = () => {
    const { isLoading: translationsLoading, error: translationsError, translations } = useProfileSelector((state) => state.translations);
    const { languageSelected } = useProfileSelector((state) => state.language);
    const [showAlerts, setShowAlerts] = useState(false);
    const dispatch = useProfileDispatch();
    const { noticesIsLoading, noticesError } = useAlerts({ languageCode: translations?.LanguageCode });

    const pageLoading = usePageLoad();
    const sidebar = useRef<SidebarActions>(null);

    const { data: user, isFetching: userIsLoading, error: userError } = useHydrationQuery(undefined, { refetchOnMountOrArgChange: true });

    const errorStatus =
        (isFetchBaseQueryError(userError) && userError.status) ||
        (isFetchBaseQueryError(translationsError) && translationsError.status) ||
        (isFetchBaseQueryError(noticesError) && noticesError.status) ||
        undefined;

    // When user is loaded get the translations related to the user's language code (runs only once)
    useEffect(() => {
        if (user?.LanguageCode) {
            // CHANGE THIS: Get the translations for the current page
            dispatch(GetTranslations({ languageCode: user?.LanguageCode, phraseGroup: Translations.PhraseGroups.Profile }));
        }
    }, [dispatch, user?.LanguageCode]);

    const toggleSidebar = useCallback(() => {
        sidebar.current?.toggleOpen();
    }, []);

    // Toggle the Alerts Drawer visibility when:
    // - Clicking the "close" button icon on the Alert's drawer
    // - Clicking the overlay
    const toggleAlerts = useCallback(() => {
        setShowAlerts((showAlerts) => !showAlerts);
    }, []);

    // On language change do:
    // - Change language for the user
    // - Get new translations
    // - Get notices (look at parent App.tsx)
    // - Reset all filters
    const changeLanguage = useCallback(
        (languageIndex: number) => {
            // Check if the language is not already selected before changing it
            if (languageSelected?.code !== LanguageCodes[languageIndex].code) {
                // CHANGE THIS: Change language for the current page (Clearances, Team, Profile...)
                dispatch(
                    ChangeLanguage({
                        localization: API_HYDRATION_PATH,
                        languageCode: LanguageCodes[languageIndex].code,
                        phraseGroup: Translations.PhraseGroups.Profile
                    })
                );
                dispatch(
                    ChangeLanguageHydration({
                        localization: API_HYDRATION_PATH,
                        languageCode: LanguageCodes[languageIndex].code,
                        phraseGroup: Translations.PhraseGroups.Hydration
                    })
                );
                dispatch(setLanguageSelected(languageIndex));

                // Initialize GTM for Portal
                if (user) {
                    initializeGTM(user.UserId, LanguageCodes[languageIndex].code, true);
                }
            }
        },
        [dispatch, languageSelected?.code, user]
    );

    useEffect(() => {
        return () => {
            dispatch(resetChooser());
        };
    }, [dispatch]);

    const translate = useTranslationHydration();
    const pageName = translate('Profile_Label');
    const pageTitle = pageName.startsWith('Untranslated') ? 'Portal' : `${pageName} | Portal`;

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            {/* Status: pending */}
            {(userIsLoading || pageLoading || translationsLoading) && (
                <div className='vw-100 vh-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}
            {/* Status: fulfilled */}
            {!userIsLoading && !pageLoading && !translationsLoading && !userError && !translationsError && (
                <div className='d-flex'>
                    {/* Sidebar */}
                    <Sidebar ref={sidebar} onLanguageChange={changeLanguage} />
                    <div className='navbar-and-stage-wrapper d-flex flex-column p-0'>
                        {/* Header navigation */}
                        <header>
                            <HeaderNav onToggleSidebar={toggleSidebar} onToggleAlerts={toggleAlerts} />
                        </header>
                        {/* Alerts drawer */}
                        {showAlerts && <AlertsDrawer onToggleAlerts={toggleAlerts} />}
                        {/* Alerts toasts */}
                        {!noticesIsLoading && !noticesError && <AlertsToasts />}
                        {/* CHANGE THIS: Current page (Clearances, Team, Profile...) */}
                        <Profile />
                    </div>
                </div>
            )}
            {/* Status: rejected */}
            {errorStatus && (
                <div className='vw-100 vh-100 d-flex align-items-center justify-content-center'>
                    <ErrorPage errorCode={errorStatus} withTranslations={!!translations} />
                </div>
            )}
        </>
    );
};

export default App;
