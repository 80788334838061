import $ from 'jquery';
import axios from '../../../axios-mlp';
import { initValidationMessages } from '../../../utils/mlp-utils';
import * as actionTypes from './teamManagementActionTypes';

export const initCurrentUserTeamData = () => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .get('/TeamManagement/Index')
            .then((response) => {
                dispatch(setCurrentUserTeamData(response.data));
            })
            .catch((error) => {
                dispatch(setTeamMemberError(error.response.statusText));
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const cancelUser = (userId) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .post('/TeamManagement/CancelUser', null, { params: { userId: userId } })
            .then((response) => {
                dispatch(setTeamMemberStatus(userId, response.data));
            })
            .catch((error) => {
                dispatch(setTeamMemberError(error.response.statusText));
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const reactivateUser = (userId) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .post('/TeamManagement/ReactivateUser', null, { params: { userId: userId } })
            .then((response) => {
                dispatch(setTeamMemberStatus(userId, response.data));
            })
            .catch((error) => {
                dispatch(setTeamMemberError(error.response.statusText));
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const initNewTeamMember = () => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .get('/TeamManagement/NewTeamMember')
            .then((response) => {
                dispatch(setTeamMember(response.data));
            })
            .catch((error) => {
                dispatch(setTeamMemberError(error.response.statusText));
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const teamMemberDetails = (userId) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .get('/TeamManagement/TeamMemberDetails', { params: { teamMemberId: userId } })
            .then((response) => {
                dispatch(setTeamMember(response.data));
            })
            .catch((error) => {
                dispatch(setTeamMemberDetailsError(error.response.statusText));
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const cancelUserDetails = (userId) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .post('/TeamManagement/CancelUser', null, { params: { userId: userId } })
            .then((response) => {
                dispatch(setTeamMemberDetailsStatus(userId, response.data));
            })
            .catch((error) => {
                dispatch(setTeamMemberDetailsError(error.response.statusText));
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const reactivateUserDetails = (userId) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .post('/TeamManagement/ReactivateUser', null, { params: { userId: userId } })
            .then((response) => {
                dispatch(setTeamMemberDetailsStatus(userId, response.data));
            })
            .catch((error) => {
                dispatch(setTeamMemberDetailsError(error.response.statusText));
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const cloneTeamMember = (userId) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .get('/TeamManagement/CloneTeamMember', { params: { teamMemberId: userId } })
            .then((response) => {
                dispatch(setTeamMember(response.data));
            })
            .catch((error) => {
                dispatch(setTeamMemberError(error.response.statusText));
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const saveTeamMembers = () => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .post('/TeamManagement/NewTeamMember')
            .then((response) => {
                dispatch(setTeamMember(response.data));
            })
            .catch((error) => {
                dispatch(setTeamMemberError(error.response.statusText));
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const setCurrentUserTeamData = (model) => {
    return {
        type: actionTypes.SET_TEAM_DATA,
        model: model
    };
};

export const setTeamMemberStatus = (userId, value) => {
    return {
        type: actionTypes.SET_TEAM_MEMBER_STATUS,
        payload: { userId: userId, value: value }
    };
};

export const setTeamMemberDetailsStatus = (userId, value) => {
    return {
        type: actionTypes.SET_TEAM_MEMBER_DETAILS_STATUS,
        payload: { userId: userId, value: value }
    };
};

export const setTeamMemberError = (value) => {
    return {
        type: actionTypes.SET_TEAM_MEMBER_ERROR,
        payload: { value: value }
    };
};

export const setTeamMemberDetailsError = (value) => {
    return {
        type: actionTypes.SET_TEAM_MEMBER_DETAILS_ERROR,
        payload: { value: value }
    };
};

export const setTeamMember = (model) => {
    const validationMessages = initValidationMessages(model);
    return {
        type: actionTypes.SET_NEW_TEAM_MEMBER,
        model: model,
        validationMessages: validationMessages
    };
};

export const changeUserRole = (newIndex, oldIndex, newValue, userRoleName) => {
    const userIndex = userRoleName.split('.');
    return {
        type: actionTypes.CHANGE_USER_ROLE,
        payload: { newIndex: newIndex, oldIndex: oldIndex, newValue: newValue, userIndex: userIndex[1] }
    };
};

export const selectUser = (index, user) => {
    return {
        type: actionTypes.SELECT_USER,
        payload: { Index: index, User: user }
    };
};

export const updateEmail = (index, email) => {
    return {
        type: actionTypes.UPDATE_EMAIL,
        payload: { Index: index, Email: email }
    };
};

export const addNewUser = () => {
    return {
        type: actionTypes.ADD_NEW_USER
    };
};

export const removeUser = (index) => {
    return {
        type: actionTypes.REMOVE_USER,
        payload: { Index: index }
    };
};

export const checkFeature = (checked, name) => {
    const splittedName = name.split('.');
    return {
        type: actionTypes.CHECK_FEATURE,
        payload: {
            parentIndex: splittedName[1],
            clientIndex: splittedName[3],
            featureIndex: splittedName[5],
            checked: checked
        }
    };
};

export const selectAllFeatures = (parentIndex, featureId) => {
    return {
        type: actionTypes.SELECT_ALL_FEATURES,
        payload: { parentIndex: parentIndex, featureId: featureId }
    };
};

export const setValidationMessages = (validationMessages) => {
    return {
        type: actionTypes.SET_VALIDATION_MESSAGES,
        payload: { validationMessages: validationMessages }
    };
};

export const setValidationMessagesSubmit = (validationMessages) => {
    return {
        type: actionTypes.SET_VALIDATION_MESSAGES_SUBMIT,
        payload: { validationMessages: validationMessages }
    };
};

export const clearState = () => {
    return {
        type: actionTypes.CLEAR_STATE
    };
};

export const modelChange = (newValue, key) => {
    return {
        type: actionTypes.MODEL_CHANGE,
        payload: { value: newValue, key: key }
    };
};

export const resetStateTeamManagement = ()=>{
    return {
        type: actionTypes.RESET_TEAM_MANAGEMENT_STATE
    }
}
export const resetTeamMemberDetails = ()=>{
    return {
        type: actionTypes.RESET_TEAM_MANAGEMENT_DETAILS_STATE
    }
}