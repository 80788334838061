import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';

class LivingstonForm extends Component {
    componentDidMount() {
        this.props.onInitTranslations('Home.Livingston');
    }

    render() {
        return (
            <div className='page-wrapper'>
                <HelmetProvider>
                    <Helmet>
                        <title>Livingston</title>
                    </Helmet>
                </HelmetProvider>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LivingstonForm);
