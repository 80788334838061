import { Grid, GridEvent, GridPageChangeEvent } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { GroupedDocuments } from '../models/ImagingModel';

interface useScrollHandlerProps {
    imagingData: GroupedDocuments[];
    gridRef: React.RefObject<HTMLDivElement | Grid>;
    scrollPosition: number;
    shouldSubmitImageSearch: boolean;
    shouldScrollToLastPosition: boolean;
    isLoading: boolean;
    resultsLength: number;
    loadedResultsLength: number;
    saveUpdateScroll: (payload: boolean) => void;
}

const useScrollHandler = ({
    gridRef,
    isLoading,
    imagingData,
    resultsLength,
    loadedResultsLength,
    scrollPosition,
    shouldSubmitImageSearch,
    shouldScrollToLastPosition,
    saveUpdateScroll
}: useScrollHandlerProps) => {
    const [data, setData] = useState<GroupedDocuments[]>([]);
    const [skip, setSkip] = useState(0);

    useEffect(() => {
        if (imagingData?.length) {
            //setData(imagingData.slice(0, scrollPosition + 19));
            setData(imagingData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imagingData]);

    useEffect(() => {
        if (shouldScrollToLastPosition && !isLoading) {
            scrollTo(scrollPosition);
            saveUpdateScroll(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const pageChange = (event: GridPageChangeEvent) => {
        setSkip(event.page.skip);
    };

    const resetSkip = () => {
        setSkip(0);
    };

    const scrollHandler = (event: GridEvent) => {
        if (imagingData.length > 10) {
            const e = event.nativeEvent;
            if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
                const moreData = imagingData.slice(data.length, 20);
                if (moreData.length > 0) {
                    setData((oldData) => oldData.concat(moreData));
                } else if (
                    data.length === imagingData.length &&
                    loadedResultsLength < resultsLength &&
                    !shouldSubmitImageSearch &&
                    !isLoading
                ) {
                    saveUpdateScroll(true);
                }
            }
        }
    };

    const scrollTo = (index: number) => {
        //@ts-ignore
        gridRef.current && gridRef.current.scrollIntoView({ rowIndex: index });
    };

    return { data, skip, resetSkip, saveUpdateScroll, pageChange, scrollHandler };
};

export default useScrollHandler;
