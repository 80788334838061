import produce from 'immer';
import * as actionTypes from '../actions/menu/menuActionTypes';

const initialState = {
    model: {
        UserLoggedIn: false,
        TermsOfServiceAccepted: false,
        MenuSections: [],
        LiveChatSaleforceUrl: '',
        LiveChatSecureForceUrl: '',
        LiveChatAgentContentUrl: '',
        LiveChatAgentUrl: '',
        LiveChatServiceForceUrl: ''
    },
    isModalOpen: false
};

const setMenu = (state, action) => {
    return produce(state, (draft) => {
        draft.model.UserLoggedIn = action.model.UserLoggedIn;
        draft.model.TermsOfServiceAccepted = action.model.TermsOfServiceAccepted;
        draft.model.MenuSections = action.model.MenuSections;
        draft.model.LiveChatSaleforceUrl = action.model.LiveChatSaleforceUrl;
        draft.model.LiveChatSecureForceUrl = action.model.LiveChatSecureForceUrl;
        draft.model.LiveChatAgentContentUrl = action.model.LiveChatAgentContentUrl;
        draft.model.LiveChatAgentUrl = action.model.LiveChatAgentUrl;
        draft.model.LiveChatServiceForceUrl = action.model.LiveChatServiceForceUrl;
    });
};

const openModal = (state) => {
    return produce(state, (draft) => {
        draft.isModalOpen = true;
    });
};

const closeModal = (state) => {
    return produce(state, (draft) => {
        draft.isModalOpen = false;
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MENU:
            return setMenu(state, action);
        case actionTypes.OPEN_MODAL:
            return openModal(state);
        case actionTypes.CLOSE_MODAL:
            return closeModal(state);
        default:
            return state;
    }
};

export default reducer;
