import { getter } from '@progress/kendo-data-query';
import { GridColumn as Column, Grid, GridCellProps, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import '@progress/kendo-theme-default/dist/default-ocean-blue.scss';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';
import ErrorPage from '../../../../../common/features/ErrorPage';
import EmptyState from '../../../../../common/features/Grid/EmptyState';
import GridLoadingOverlay from '../../../../../common/features/Grid/GridLoadingOverlay';
import RowWithButton from '../../../../../common/features/Grid/RowWithButton';
import UnsubscribeFooter from '../../../../../common/features/Grid/UnsubscribeFooter';
import ConfrimationModal from '../../../../../common/features/Modal/ConfirmationModal';
import Spinner from '../../../../../common/features/Spinner';
import useGridLanguage from '../../../../../common/hooks/useGridLanguage';
import useGridSelection from '../../../../../common/hooks/useGridSelection';
import useGTMEventTracking from '../../../../../common/hooks/useGTMEventTracking';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { Subscription } from '../../../../../common/models/ClearanceSubscriptions';
import { SubscribersModals } from '../../../../../common/models/SubscribersModals';
import { GetClearanceSubscribers } from '../../../redux/actions/clearanceSubscribers';
import { useClearancesDispatch, useClearancesSelector } from '../../../redux/hooks';
import { resetSelectedState, setHeaderCheckbox, setSelectedState } from '../../../redux/reducers/clearanceSubscribersGridSettingsSlice';
import FormattedGridCell from './FormattedGridCell';
import AddSubscriberModal from './subscribe/AddSubscriberModal';
import SubscriptionStatusAlerts from './SubscriptionStatusAlerts';
import UnsubscribeModal from './unsubscribe/UnsubscribeModal';

const initialModals: SubscribersModals = {
    add: false,
    cancelAdd: false,
    remove: false
};

const DATA_ITEM_KEY: keyof Subscription = 'SubscriptionId';
const SELECTED_FIELD = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

const SubscribersGrid = memo(function SubscribersGrid() {
    const { shipmentDetails } = useClearancesSelector((state) => state.shipmentDetails);
    const { subscribers, isLoading, subscriptionUpdate, unsubscribe, subscribe, selfSubscription, selfSubscriptionUpdate, error } =
        useClearancesSelector((state) => state.clearanceSubscribers);
    const { languageSelected: selectedLanguage } = useClearancesSelector((state) => state.language);
    const { selectedState, headerCheckbox } = useClearancesSelector((state) => state.clearanceSubscribersGridSettings);

    const [modal, setModal] = useState<SubscribersModals>(initialModals);
    const [selectedSubscriberIds, setSelectedSubscriberIds] = useState<string[]>([]);
    const [dataState, setDataState] = useState<
        ({
            [SELECTED_FIELD]: boolean;
        } & Subscription)[]
    >();

    const dispatch = useClearancesDispatch();
    const translate = useTranslation();
    const gridLanguage = useGridLanguage(selectedLanguage);
    const { handleHeaderSelectionChange, handleSelectionChange } = useGridSelection({
        DATA_ITEM_KEY,
        SELECTED_FIELD,
        idGetter,
        selectedState,
        onSelectionChange: (value) => dispatch(setSelectedState(value)),
        onHeaderSelectionChange: useCallback((value) => dispatch(setHeaderCheckbox(value)), [dispatch]),
        onReset: useCallback(() => dispatch(resetSelectedState()), [dispatch])
    });
    const addSubscribersClearancesInitialRef = useRef(null);

    useGTMEventTracking('Add_Subsription_Entry_CLR_CPEvt', { location: 'Clearances' }, addSubscribersClearancesInitialRef);

    // Set data state for the grid
    useEffect(() => {
        if (subscribers) {
            setDataState(subscribers.map((item) => ({ ...item, [SELECTED_FIELD]: false })));
        }
    }, [subscribers]);

    // Refresh the grid after each "subscriptionUpdate", "subscribe", "unsubscribe" and "selfSubscriptionUpdate"
    useEffect(() => {
        if (
            !subscriptionUpdate.isLoading &&
            !subscriptionUpdate.error &&
            !subscribe.isLoading &&
            !subscribe.error &&
            !unsubscribe.isLoading &&
            !unsubscribe.error &&
            !selfSubscriptionUpdate.isLoading &&
            !selfSubscriptionUpdate.error &&
            shipmentDetails?.CustomsInfoID &&
            shipmentDetails.ClientCaptionNumber
        ) {
            dispatch(
                GetClearanceSubscribers({
                    customsInfoId: shipmentDetails.CustomsInfoID,
                    clientCaptionNumber: shipmentDetails.ClientCaptionNumber
                })
            );
        }
    }, [subscriptionUpdate.isLoading, subscriptionUpdate.error, subscribe.isLoading, subscribe.error, unsubscribe.isLoading, unsubscribe.error, selfSubscriptionUpdate.isLoading, selfSubscriptionUpdate.error, shipmentDetails?.ClientCaptionNumber, shipmentDetails?.CustomsInfoID, dispatch]);

    // Reset the selection on every grid reload
    useEffect(() => {
        if (!isLoading && !error) {
            dispatch(resetSelectedState());
        }
    }, [isLoading, error, dispatch]);

    // Array of selected SubscriptionIds
    useEffect(() => {
        setSelectedSubscriberIds(
            Object.entries(selectedState)
                .filter(([key, value]) => value === true)
                .map(([key, value]) => key)
        );
    }, [selectedState]);

    const toggleModal = useCallback(
        (prop: keyof SubscribersModals | Array<keyof SubscribersModals>, next?: boolean) => {
            if (typeof prop === 'string') {
                setModal({ ...modal, [prop]: next !== undefined ? next : !modal[prop] });
            } else {
                setModal(Object.assign({}, modal, ...prop.map((key) => ({ [key]: next }))));
            }
        },
        [modal]
    );

    const handleRemoveSubscriber = useCallback(
        (id?: null | number) => {
            if (id) setSelectedSubscriberIds([id.toString()]);
            toggleModal('remove');
        },
        [toggleModal]
    );

    const handleTagEvent = () => {
        TagManager.dataLayer({
            dataLayer: {
                event: 'addasubscriber_subscribers_entry_clearances',
                location: 'Clearances'
            }
        });
    };

    const GridCell = useCallback(
        (props: GridCellProps) => {
            return (
                <FormattedGridCell
                    {...props}
                    onRemoveSubscriber={handleRemoveSubscriber}
                    isLocked={props.dataItem[SELECTED_FIELD] || false}
                />
            );
        },
        [handleRemoveSubscriber]
    );

    const HeaderCell = useCallback(
        (props: GridHeaderCellProps) => {
            return <Checkbox value={headerCheckbox} onChange={props.selectionChange} />;
        },
        [headerCheckbox]
    );

    return (
        <>
            {/* Status: pending (only showing on initial load) */}
            {(isLoading ||
                unsubscribe.isLoading ||
                selfSubscription.isLoading ||
                selfSubscriptionUpdate.isLoading ||
                subscribe.isLoading) &&
                subscribers?.length === 0 && (
                    <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                        <Spinner />
                    </div>
                )}

            {modal.add && <AddSubscriberModal toggleModal={toggleModal} clearanceCustomerNo={shipmentDetails?.ClientCaptionNumber!} />}

            {modal.remove && (
                <UnsubscribeModal
                    clearanceNo={shipmentDetails?.EntryNumber}
                    subscriberIds={selectedSubscriberIds}
                    toggleModal={toggleModal}
                />
            )}

            {modal.cancelAdd && (
                <ConfrimationModal
                    title={translate('CancelAddingSucribers_Label')}
                    description={translate('LostProgress_Label')}
                    primaryButtonLabel={translate('YesCancel_Label')}
                    secondaryButtonLabel={translate('NotificationsGoBack_Label')}
                    onPrimary={() => toggleModal(['add', 'cancelAdd'], false)}
                    onSecondary={() => {
                        toggleModal('cancelAdd', false);
                        const modalElement = document.querySelector('#SteppedProgressModal') as HTMLDivElement;
                        modalElement.focus();
                    }}
                />
            )}

            {/* Status: fulfilled */}
            {subscribers && subscribers.length > 0 && !error && (
                <>
                    <RowWithButton
                        element='.k-grid.contacts-grid-table'
                        onClick={() => {
                            toggleModal('add', true);
                            handleTagEvent();
                        }}
                    >
                        {translate('AddSubscribersButtonGrid_Label')}{' '}
                    </RowWithButton>
                    <LocalizationProvider language={gridLanguage.language}>
                        <IntlProvider locale={gridLanguage.locale}>
                            <div className='d-flex position-relative flex-fill'>
                                <Grid
                                    data={
                                        dataState &&
                                        dataState.map((item) => ({
                                            ...item,
                                            [SELECTED_FIELD]: selectedState[idGetter(item)]
                                        }))
                                    }
                                    className='contacts-grid-table flex-fill'
                                    total={dataState ? dataState.length : 0}
                                    onSelectionChange={handleSelectionChange}
                                    onHeaderSelectionChange={handleHeaderSelectionChange}
                                    dataItemKey={DATA_ITEM_KEY}
                                    selectedField={SELECTED_FIELD}
                                    sortable={{
                                        allowUnsort: false
                                    }}
                                    selectable={{
                                        enabled: false,
                                        drag: false,
                                        cell: false,
                                        mode: 'multiple'
                                    }}
                                    style={{ zIndex: 0 }}
                                >
                                    <Column field={SELECTED_FIELD} cell={GridCell} headerCell={HeaderCell} width={50} />
                                    <Column field='FullName' title={translate('Name_Label')} cell={GridCell} width={200} />
                                    <Column field='Email' title={translate('Email_Label')} cell={GridCell} width={250} />
                                    <Column field='RoleName' title={translate('Role_Label')} cell={GridCell} width={200} />
                                    <Column
                                        field='Phone'
                                        title={translate('NotificationsMobileNumber_Label')}
                                        cell={GridCell}
                                        width={200}
                                    />
                                    <Column
                                        field='Milestones'
                                        title={translate('MilestoneSubscriptions_Label')}
                                        cell={GridCell}
                                        width={200}
                                    />
                                    <Column
                                        field='OnHoldNotifications'
                                        title={translate('OnHoldNotifications_Label')}
                                        cell={GridCell}
                                        width={200}
                                    />
                                    <Column field='CreatedByEmail' title={translate('CreatedBy_Label')} cell={GridCell} width={250} />
                                    <Column field='ModifiedByEmail' title={translate('ModifiedBy_Label')} cell={GridCell} width={250} />
                                    <Column field='Actions' title='Actions' cell={GridCell} locked width={200} />
                                </Grid>
                                {(isLoading ||
                                    subscriptionUpdate.isLoading ||
                                    unsubscribe.isLoading ||
                                    selfSubscription.isLoading ||
                                    selfSubscriptionUpdate.isLoading ||
                                    subscribe.isLoading) &&
                                    subscribers &&
                                    subscribers.length > 0 && <GridLoadingOverlay />}
                            </div>
                        </IntlProvider>
                    </LocalizationProvider>
                </>
            )}

            {/* No subsribers for this clearance */}
            {!isLoading &&
                !subscribe.isLoading &&
                !unsubscribe.isLoading &&
                !subscriptionUpdate.isLoading &&
                !selfSubscription.isLoading &&
                !selfSubscriptionUpdate.isLoading &&
                subscribers &&
                subscribers.length === 0 && (
                    <EmptyState
                        descriptionLabel={translate('NoSubscribersCurrently_Label')}
                        addButtonLabel={translate('AddASubscriberButton_Label')}
                        onAdd={() => toggleModal('add', true)}
                        ref={addSubscribersClearancesInitialRef}
                        isForClearances={true}
                    />
                )}

            {/* Status alerts for subscriptions */}
            <SubscriptionStatusAlerts />

            {/* Unsubscribe footer */}
            {Object.entries(selectedState).filter(([key, value]) => value === true).length > 0 &&
                !modal.remove &&
                !isLoading &&
                !subscribe.isLoading &&
                !unsubscribe.isLoading &&
                !subscriptionUpdate.isLoading &&
                !selfSubscription.isLoading &&
                !selfSubscriptionUpdate.isLoading && (
                    <UnsubscribeFooter
                        amountLabel={translate('NumberOfRemoveSelected_Label', selectedSubscriberIds.length)}
                        primaryButtonLabel={translate('RemoveSelected_Label')}
                        onPrimary={() => toggleModal('remove', true)}
                        selectedSubscribers={selectedSubscriberIds.length}
                        isForClearances={true}
                    />
                )}

            {/* Status: rejected */}
            {error && <ErrorPage errorCode={error} withTranslations />}
        </>
    );
});

export default SubscribersGrid;
