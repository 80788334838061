import produce from 'immer';
import { Side } from '../../constants/mlp-constants';
import * as actionTypes from '../actions/imaging/imagingActionTypes';

const initialState = {
    model: {
        ViewModel: {},
        EntriesToCompareLeft: {},
        EntriesToCompareRight: {},
        FlatDocumentModel: [],
        LevelOneSortOptions: [],
        LevelTwoSortOptions: [],
        SortedDocuments: []
    }
};

const setImageDetails = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.model;
        const selectedEntryLeft = draft.model.EntriesToCompareLeft.find((x) => x.isSelected);
        if (!selectedEntryLeft) {
            draft.model.EntriesToCompareLeft[0].isSelected = true;
        }
        const selectedEntryRight = draft.model.EntriesToCompareRight.find((x) => x.isSelected);
        if (!selectedEntryRight) {
            draft.model.EntriesToCompareRight[0].isSelected = true;
        }
    });
};

const setDownloadDocumentUrl = (state, action) => {
    return produce(state, (draft) => {
        draft.documentUrl = action.model;
    });
};

const sortOptionSelect = (state, action) => {
    return produce(state, (draft) => {
        let options = null;
        let optionsOther = null;
        if (action.payload.level === 1) {
            options = draft.model.LevelOneSortOptions;
            optionsOther = draft.model.LevelTwoSortOptions;
        } else {
            options = draft.model.LevelTwoSortOptions;
            optionsOther = draft.model.LevelOneSortOptions;
        }
        if (action.payload.oldIndex !== -1) {
            options[action.payload.oldIndex].IsSelected = false;
        }
        if (action.payload.newIndex !== -1) {
            options[action.payload.newIndex].IsSelected = true;
            //prevent select same sort column for both sort levels
            let otherSelectedOption = optionsOther.find((x) => x.IsSelected);
            if (otherSelectedOption && otherSelectedOption.Attribute === options[action.payload.newIndex].Attribute) {
                otherSelectedOption.IsSelected = false;
                optionsOther.filter((x) => x.Attribute !== options[action.payload.newIndex].Attribute)[0].IsSelected = true;
            }
        } else {
            options.forEach((x) => {
                x.IsSelected = false;
            });
        }
    });
};

const selectDocument = (state, action) => {
    return produce(state, (draft) => {
        let entries = null;
        if (action.payload.side && action.payload.side === Side.LEFT) {
            entries = draft.model.EntriesToCompareLeft;
        } else if (action.payload.side && action.payload.side === Side.RIGHT) {
            entries = draft.model.EntriesToCompareRight;
        } else {
            entries = draft.model.ViewModel;
        }

        let documents = null;
        if (action.payload.entryIndex !== -1) {
            documents = entries[action.payload.entryIndex].documents;
        } else {
            documents = entries[0].documents;
        }
        if (action.payload.oldIndex !== -1) {
            documents[action.payload.oldIndex].isSelected = false;
        }
        if (action.payload.newIndex !== -1) {
            documents[action.payload.newIndex].isSelected = true;
        }
    });
};

const selectEntry = (state, action) => {
    return produce(state, (draft) => {
        let entries = null;
        if (action.payload.side === Side.LEFT) {
            entries = draft.model.EntriesToCompareLeft;
        } else if (action.payload.side === Side.RIGHT) {
            entries = draft.model.EntriesToCompareRight;
        } else {
            entries = draft.model.ViewModel;
        }
        if (action.payload.oldIndex !== -1) {
            entries[action.payload.oldIndex].isSelected = false;
            entries[action.payload.oldIndex].documents.forEach((x) => {
                x.IsSelected = false;
            });
        }
        if (action.payload.newIndex !== -1) {
            entries[action.payload.newIndex].isSelected = true;
            let docs = entries[action.payload.newIndex].documents;
            docs[0].isSelected = true;
        }
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_IMAGE_DETAILS:
            return setImageDetails(state, action);
        case actionTypes.SORT_OPTION_SELECT:
            return sortOptionSelect(state, action);
        case actionTypes.SELECT_DOCUMENT:
            return selectDocument(state, action);
        case actionTypes.SET_DOWNLOAD_DOCUMENT_URL:
            return setDownloadDocumentUrl(state, action);
        case actionTypes.SELECT_ENTRY:
            return selectEntry(state, action);
        case actionTypes.RESET_IMAGE_DETAILS_STATE:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
