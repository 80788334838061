import produce from 'immer'
import * as actionTypes from '../actions/testform/testformActionTypes'

const initialState = {
    model: {
        TestString: 'Initial Value'
    }
}

const changeText = (state, action) => {
    return produce(state, (draft) => {
        draft.model.TestString = action.payload.value
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TEST:
            return initialState
        case actionTypes.CHANGE_TEXT:
            return changeText(state, action)
        default:
            return state
    }
}

export default reducer
