import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import useTranslation from '../../../../common/hooks/useTranslation';
import { useTeamSelector } from '../../redux/hooks';
import type { ClientService, ParentService, TeamMemberData } from '../models';
import ClientsAndPermissionsTable, { ClientsAndPermissionsTableActions } from './ClientsAndPermissionsTable';
import TeamSectionWrapper from './TeamSectionWrapper';

const isRequiredFeatureSelected = ({
    parentServices,
    featureName,
    teamMemberData
}: {
    parentServices: null | undefined | ParentService[];
    featureName: string;
    teamMemberData: TeamMemberData | undefined;
}) => {
    if (!parentServices?.length) return false;

    let servicesWithSelection: ClientService[] = [];
    let isSelected = true;

    if (teamMemberData?.AdminRole === 'CentralAdmin') {
        isSelected = parentServices.some((parentService) =>
            parentService.ClientServices.some((clientService) =>
                clientService.Features?.some((feature) => feature.Name?.toLowerCase() === featureName.toLowerCase() && feature.IsSelected)
            )
        );
    } else {
        parentServices.forEach((parentService) =>
            parentService.ClientServices.forEach((clientService) =>
                clientService.Features?.some((feature) => feature.IsSelected && servicesWithSelection.push(clientService))
            )
        );

        if (servicesWithSelection.length) {
            servicesWithSelection.forEach((clientService) => {
                for (let feature of clientService.Features) {
                    if (feature.Name?.toLowerCase() === featureName.toLowerCase() && !feature.IsSelected) {
                        isSelected = false;
                        break;
                    }
                }
            });
        }
        if (servicesWithSelection.length === 0) isSelected = false;
    }

    return isSelected;
};

interface ClientsAndPermissionsProps {
    isLoading: boolean;
    teamMemberData: TeamMemberData | undefined;
    parentServicesData: ParentService[] | undefined;
}

export type ClientsAndPermissionsActions = {
    getData: () => ParentService[];
    validate: (parentServices: ParentService[] | undefined) => boolean;
};

const ClientsAndPermissions = forwardRef<ClientsAndPermissionsActions, ClientsAndPermissionsProps>(function ClientsAndPermissions(
    { isLoading, teamMemberData, parentServicesData },
    ref
) {
    const { user } = useTeamSelector((state) => state.hydration);

    const isEditable = useMemo(() => user?.Roles?.includes('TeamManagement_Edit'), [user?.Roles]);

    const translate = useTranslation();

    const teamMember = teamMemberData?.Users?.[0];
    const isPendingStatus =
        teamMember?.Status === 'Pending' ||
        teamMember?.Status === 'PendingUserActivation' ||
        teamMember?.Status === 'PendingFeatureSetup' ||
        teamMember?.Status === 'EmailChangedConfirmRequired' ||
        teamMember?.Status === 'PendingUserConfirmRequired' ||
        teamMember?.Status === 'ActiveFeatureSetupInProgress';
    const isInactiveStatus =
        teamMember?.Status === 'Cancelled' ||
        teamMember?.Status === 'Deactivated' ||
        teamMember?.Status === 'Expired' ||
        teamMember?.Status === 'Hold';
    const isDisabled = !isEditable || isPendingStatus || isInactiveStatus;

    const tables = useRef<ClientsAndPermissionsTableActions[]>([]);

    useImperativeHandle(ref, () => {
        return {
            getData() {
                const parentServices = tables.current.map((table) => table.getData());
                return parentServices;
            },
            validate(parentServices: ParentService[] | undefined) {
                const isValid = isRequiredFeatureSelected({
                    parentServices,
                    featureName: 'my livingston portal',
                    teamMemberData
                });

                return isValid;
            }
        };
    });

    const setRefs = useCallback((index: number, el: ClientsAndPermissionsTableActions) => {
        tables.current[index] = el;
    }, []);

    return (
        <TeamSectionWrapper title={translate('ClientsAndPermissionsHeader_Label')} isLoading={isLoading}>
            <>
                {teamMemberData &&
                    parentServicesData?.map((parentService, index) => (
                        <ClientsAndPermissionsTable
                            key={index}
                            ref={setRefs.bind(null, index)}
                            teamMemberData={teamMemberData}
                            parentServiceData={parentService}
                            isDisabled={isDisabled}
                        />
                    ))}
            </>
        </TeamSectionWrapper>
    );
});

export default ClientsAndPermissions;
