import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

export const ClearancesActionButtonOptions = [
    { id: 'VIEW_DETAILS', label: 'ViewClearanceDetails_Label', icon: null },
    { id: 'HEADER_LINE', label: 'HeaderAndLineInfo_Label', icon: null, rightIcon: faFileDownload },
    { id: 'DOCUMENTS', label: 'Documents_Label', icon: null, rightIcon: faFileDownload }
] as const;

// Mode of Transportation Map
export const MOTMap = {
    AIRPLANE: { label: 'Air_Label', icon: 'airplane' },
    TRUCK: { label: 'Truck_Label', icon: 'truck' },
    SHIP: { label: 'Ocean_Label', icon: 'ship' },
    TRAIN: { label: 'Rail_Label', icon: 'train' },
    HAND_CARRY: { label: 'HandCarry_Label', icon: 'person carrying goods' },
    PIPELINE: {
        label: 'Pipeline_Label',
        icon: 'pipeline'
    },
    OTHER: { label: 'Other_Label', icon: '' }
};

export const MOTCodes = {
    '01': Object.keys(MOTMap)[0] as keyof typeof MOTMap,
    '1': Object.keys(MOTMap)[0] as keyof typeof MOTMap,
    '40': Object.keys(MOTMap)[0] as keyof typeof MOTMap,
    '41': Object.keys(MOTMap)[0] as keyof typeof MOTMap,
    '2': Object.keys(MOTMap)[1] as keyof typeof MOTMap,
    '02': Object.keys(MOTMap)[1] as keyof typeof MOTMap,
    '30': Object.keys(MOTMap)[1] as keyof typeof MOTMap,
    '31': Object.keys(MOTMap)[1] as keyof typeof MOTMap,
    '9': Object.keys(MOTMap)[2] as keyof typeof MOTMap,
    '09': Object.keys(MOTMap)[2] as keyof typeof MOTMap,
    '10': Object.keys(MOTMap)[2] as keyof typeof MOTMap,
    '11': Object.keys(MOTMap)[2] as keyof typeof MOTMap,
    '12': Object.keys(MOTMap)[2] as keyof typeof MOTMap,
    '6': Object.keys(MOTMap)[3] as keyof typeof MOTMap,
    '06': Object.keys(MOTMap)[3] as keyof typeof MOTMap,
    '20': Object.keys(MOTMap)[3] as keyof typeof MOTMap,
    '21': Object.keys(MOTMap)[3] as keyof typeof MOTMap,
    '8': Object.keys(MOTMap)[4] as keyof typeof MOTMap,
    '08': Object.keys(MOTMap)[4] as keyof typeof MOTMap,
    '33': Object.keys(MOTMap)[4] as keyof typeof MOTMap,
    '60': Object.keys(MOTMap)[4] as keyof typeof MOTMap,
    '7': Object.keys(MOTMap)[5] as keyof typeof MOTMap,
    '07': Object.keys(MOTMap)[5] as keyof typeof MOTMap,
    '70': Object.keys(MOTMap)[5] as keyof typeof MOTMap
};

// Shipment Milestones
const USShipmentMilestones = {
    1: { eventKey: 'CusInfoShipmentCreated', label: 'DocumentsReceived_Label', milestoneEventKey: 'DocsReceivedDateTime' },
    2: { eventKey: 'CusInfoReleaseAccepted', label: 'CustomsCertified_Label', milestoneEventKey: 'ReleaseAcceptedOn' },
    3: { eventKey: 'CusInfoShipmentReleased', label: 'CustomsReleased_Label', milestoneEventKey: 'CustomsReleasedOn' },
    4: { eventKey: 'CusInfoONEUSGReceived', label: 'OneUsgReceived_Label', milestoneEventKey: 'OneUsgReceivedOn' },
    5: { eventKey: 'CusInfoEntryAccepted', label: 'EntrySummaryAccepted_Label', milestoneEventKey: 'EntryAcceptedOn' }
};

const CAShipmentMilestones = {
    1: { eventKey: 'CusInfoShipmentCreated', label: 'DocumentsReceived_Label', milestoneEventKey: 'DocsReceivedDateTime' },
    2: { eventKey: 'CusInfoReleaseSubmitted', label: 'ReleaseSubmitted_Label', milestoneEventKey: 'ReleaseSubmittedOn' },
    3: { eventKey: 'CusInfoReleaseAccepted', label: 'CustomsAccepted_Label', milestoneEventKey: 'ReleaseAcceptedOn' },
    4: { eventKey: 'CusInfoShipmentReleased', label: 'CustomsReleased_Label', milestoneEventKey: 'CustomsReleasedOn' },
    5: { eventKey: 'CusInfoEntryAccepted', label: 'EntryAccepted_Label', milestoneEventKey: 'EntryAcceptedOn' }
};

export const ShipmentMilestones = {
    Canada: CAShipmentMilestones,
    USA: USShipmentMilestones
};

export const INITIAL_NOTIFICATIONS_SETTINGS = {
    Email: '',
    HasNotificationsForClearanceOnHold: false,
    HasNotificationsForClearanceOnHold_CA: false,
    HasNotificationsForCustomsAccepted_CA: false,
    HasNotificationsForCustomsCertified: false,
    HasNotificationsForCustomsReleased: false,
    HasNotificationsForCustomsReleased_CA: false,
    HasNotificationsForDocumentsReceived: false,
    HasNotificationsForDocumentsReceived_CA: false,
    HasNotificationsForEntryAccepted_CA: false,
    HasNotificationsForEntrySummaryAccepted: false,
    HasNotificationsForOneUsgReceived: false,
    HasNotificationsForReleaseSubmitted_CA: false,
    Phone: ''
};

// Filters

export const StatusFilter = [
    { id: 'IN_PROGRESS', label: 'StatusInProgress_Label', amount: 0 },
    { id: 'ON_HOLD', label: 'OnHold_Label', amount: 0 },
    { id: 'COMPLETED', label: 'StatusCompleted_Label', amount: 0 }
];

export const DateRangeFilter = [
    { id: 'LAST_30', label: 'DateRangeThirtyDays_Label' },
    { id: 'LAST_60', label: 'DateRangeSixtyDays_Label' },
    { id: 'LAST_90', label: 'DateRangeNinetyDays_Label' },
    { id: 'THIS_YEAR', label: 'DateRangeThisYear_Label' },
    { id: 'LAST_YEAR', label: 'DateRangeLastYear_Label' },
    { id: 'ALL_TIME', label: 'DateRangeAllTime_Label' },
    { id: 'CUSTOM', label: 'DateRangeCustom_Label' }
];

export const MOTFilter = [
    {
        id: Object.keys(MOTMap)[0] as keyof typeof MOTMap,
        label: MOTMap.AIRPLANE.label,
        isChecked: true
    },
    {
        id: Object.keys(MOTMap)[1] as keyof typeof MOTMap,
        label: MOTMap.TRUCK.label,
        isChecked: true
    },
    {
        id: Object.keys(MOTMap)[2] as keyof typeof MOTMap,
        label: MOTMap.SHIP.label,
        isChecked: true
    },
    {
        id: Object.keys(MOTMap)[3] as keyof typeof MOTMap,
        label: MOTMap.TRAIN.label,
        isChecked: true
    },
    {
        id: Object.keys(MOTMap)[4] as keyof typeof MOTMap,
        label: MOTMap.HAND_CARRY.label,
        isChecked: true
    },
    {
        id: Object.keys(MOTMap)[5] as keyof typeof MOTMap,
        label: MOTMap.PIPELINE.label,
        isChecked: true
    },
    {
        id: Object.keys(MOTMap)[6] as keyof typeof MOTMap,
        label: MOTMap.OTHER.label,
        isChecked: true
    }
];

export const DestinationLabels = {
    USA: 'USADestination_Label',
    Canada: 'CanadaDestination_Label'
};

export const DestinationFilter = [
    { label: DestinationLabels.USA, value: 'US' },
    { label: DestinationLabels.Canada, value: 'CA' }
];

export const MilestoneFilter = [
    { label: 'DocumentsReceived_Label', value: 1, destination: 'US' },
    { label: 'CustomsCertified_Label', value: 2, destination: 'US' },
    { label: 'CustomsReleased_Label', value: 3, destination: 'US' },
    { label: 'OneUsgReceived_Label', value: 4, destination: 'US' },
    { label: 'EntrySummaryAccepted_Label', value: 5, destination: 'US' },
    { label: 'DocumentsReceived_Label', value: 1, destination: 'CA' },
    { label: 'ReleaseSubmitted_Label', value: 2, destination: 'CA' },
    { label: 'CustomsAccepted_Label', value: 3, destination: 'CA' },
    { label: 'CustomsReleased_Label', value: 4, destination: 'CA' },
    { label: 'EntryAccepted_Label', value: 5, destination: 'CA' }
];

// empty phone number masked field
// used for validation checking
export const PHONE_NUMBER_EMPTY = '(___) ___-____';

// constant for export header and line details
export const SEARCH_SHIPMENT_VISIBLE_COLUMNS = [
    '_entryNumber',
    'EntryTypeDesc',
    'Status',
    'CustRefNo',
    'FileNo',
    'DocumentReceiveDt',
    'ISFAcceptDt',
    'CustomsCertDt',
    'CustomsReleaseDt',
    'EntSummAcceptDt',
    'LiquidationDt',
    'EnteredValueForDuty',
    'DutyTot',
    'MLPHMF',
    'MLPMPF',
    'DutyFeesTot',
    'PieceCount',
    'CountryOriginDesc',
    'CountryOriginCode',
    'ImporterName',
    'ImporterNo',
    'ModeTransportCode',
    'CarrierSCAC',
    'ITNo',
    'HouseBill',
    'ScacHouse',
    'MasterBill',
    'ContainerNo',
    'VesselNo',
    'VoyageNo',
    'ConsigneeName',
    'ConsigneeNo',
    'DestinationState',
    'PortEntryCode',
    'PortEntryDesc',
    'PortUnladingCode',
    'PortUnladingDesc',
    'FilerCode',
    'ClientNo',
    'BillingInvoiceNo',
    'InvoiceSuffix'
];

export const clearancesGridSettingsConstants = {
    us: 'Clearances_US',
    ca: 'Clearances_CA'
};

export const defaultGridColumns = [
    {
        GridSettingColumnID: 3,
        Code: 'ReferenceNumber',
        FieldName: 'ReferenceNumber',
        HeaderLabel: 'TableGridRefNo_Label',
        IsVisibleAlways: false,
        IsVisible: true,
        IsSortable: true,
        Order: 1,
        Width: 130
    },
    {
        GridSettingColumnID: 1,
        Code: 'ClientCaptionNumber',
        FieldName: 'ClientCaptionNumber',
        HeaderLabel: 'ClientNo_Label',
        IsVisibleAlways: false,
        IsVisible: true,
        IsSortable: false,
        Order: 2,
        Width: 140
    },
    {
        GridSettingColumnID: 2,
        Code: 'EntryNumber',
        FieldName: 'EntryNumber',
        HeaderLabel: 'EntryNo_Label',
        IsVisibleAlways: true,
        IsVisible: true,
        IsSortable: true,
        Order: 3,
        Width: 210
    },
    {
        GridSettingColumnID: 5,
        Code: 'BillOfLading',
        FieldName: 'BillOfLading',
        HeaderLabel: 'TableGridBolNo_Label',
        IsVisibleAlways: false,
        IsVisible: true,
        IsSortable: false,
        Order: 4,
        Width: 140
    },
    {
        GridSettingColumnID: 4,
        Code: 'CarrierCode',
        FieldName: 'CarrierCode',
        HeaderLabel: 'Carrier_Label',
        IsVisibleAlways: false,
        IsVisible: true,
        IsSortable: true,
        Order: 5,
        Width: 130
    },
    {
        GridSettingColumnID: 6,
        Code: 'Milestone',
        FieldName: 'Milestone',
        HeaderLabel: 'TableGridCurrentMilestone_Label',
        IsVisibleAlways: false,
        IsVisible: true,
        IsSortable: false,
        Order: 6,
        Width: 190
    },
    {
        GridSettingColumnID: 7,
        Code: 'ClearanceStatus',
        FieldName: 'ClearanceStatus',
        HeaderLabel: 'TableStatus_Label',
        IsVisibleAlways: false,
        IsVisible: true,
        IsSortable: false,
        Order: 7,
        Width: 120
    },
    {
        GridSettingColumnID: 8,
        Code: 'ModifiedOn',
        FieldName: 'ModifiedOn',
        HeaderLabel: 'LastModified_Label',
        IsVisibleAlways: false,
        IsVisible: true,
        IsSortable: true,
        Order: 8,
        Width: 160
    },
    {
        GridSettingColumnID: 9,
        Code: 'ModeOfTransport',
        FieldName: 'ModeOfTransport',
        HeaderLabel: 'MOTTitle_Label',
        IsVisibleAlways: false,
        IsVisible: true,
        IsSortable: false,
        Order: 9,
        Width: 110
    }
];

export const clearancesDownloadStatuses = [
    { ABICode: '02', Value: 'Hold intact' },
    { ABICode: '04', Value: 'Data rejected per review' },
    { ABICode: '0', Value: 'Data sent' },
    { ABICode: '01', Value: 'Data under review' },
    { ABICode: '07', Value: 'May proceed' }
];
