import React, { Component } from 'react';
import { connect } from 'react-redux';

class BatchJobExpired extends Component {
    render() {
        return (
            <div>
                <div className='col-sm-12'>
                    <h3>{this.props.title}</h3>
                </div>
                <div className='col-sm-12'>
                    <div className='back-link'>
                        <a href='/SystemAdmin'>{this.props.translations.Phrases['BackToSystemAdmin_Label']}</a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(BatchJobExpired);
