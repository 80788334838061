import { Component } from 'react';
import { connect } from 'react-redux';
import { imageDetailsPath, storageModelName } from '../../../constants/mlp-constants';
import { removeModelFromStorage } from '../../../utils/modelStorage';
import SearchGridRowMoreDetails from '../ViewImages/SearchGridRowMoreDetails';

class DetailsEntry extends Component {
    detailPages = [
        imageDetailsPath.VIEWIMAGE_SEARCHSHIPMENTDETAIL,
        imageDetailsPath.DOCUMENTCOMPARE_VIEW,
        imageDetailsPath.DOCUMENT_VIEW,
        imageDetailsPath.DOWNLOAD_AND_PRINT
    ];

    componentWillUnmount() {
        if (this.props.history.location.pathname !== '/Imaging' && !this.detailPages.includes(this.props.history.location.pathname)) {
            removeModelFromStorage(storageModelName.VIEWIMAGE);
        }
    }

    getSelectedEntry = (entries) => {
        if (!entries.some((x) => x.isSelected)) {
            return entries[0];
        }

        return entries.find((x) => x.isSelected);
    };

    render() {
        const selectedEntry = this.getSelectedEntry(this.props.viewModel);

        return (
            <div className='container imaging-details-container'>
                <div className='entry-details-container'>
                    <SearchGridRowMoreDetails
                        entry={selectedEntry}
                        visibilitySetting='isVisibleOnDetailsScreen'
                        orderSetting='detailsScreenOrder'
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        viewModel: state.imagingDetails.model.ViewModel
    };
};

export default connect(mapStateToProps)(DetailsEntry);
