import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { REACT_APP_ASSETS_URL } from '../constants/constants-portal';

interface CountryFlagProps {
    countryCode: string;
    width?: string;
    height?: string;
    className?: string;
}

const CountryFlag: React.FC<CountryFlagProps> = ({ countryCode, width, height, className }) => {
    let flag;

    switch (countryCode.toUpperCase()) {
        case 'ALL':
            return <FontAwesomeIcon icon={faGlobe} className={`globe ${className}`} />;
        case 'CA':
            flag = 'canadian-flag';
            break;
        case 'US':
            flag = 'usa-flag';
            break;
        default:
            return null;
    }

    return (
        <img
            className={className}
            src={`${REACT_APP_ASSETS_URL}/assets/images/icons/flags/${flag}.svg`}
            alt={`${flag} flag`}
            width={width}
            height={height}
        />
    );
};

export default CountryFlag;
