import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SidebarCloseButtonProps {
    icon: IconDefinition;
    onClick: () => void;
}

const SidebarCloseButton: React.FC<SidebarCloseButtonProps> = ({ icon, onClick }) => {
    return (
        <button type='button' className='sidebar-close-button-mobile btn btn-tertiary-white btn-lg' onClick={onClick}>
            <FontAwesomeIcon icon={icon} />
        </button>
    );
};

export default SidebarCloseButton;
