import { PropDescription } from './types';

type handleOnClickFunction = () => void;

export enum ButtonType {
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
    TertiaryBlue = 'tertiary-blue',
    TertiaryWhite = 'tertiary-white',
    Ghost = 'ghost',
    GhostDark = 'ghost-dark',
    Danger = 'danger',
    DangerOutline = 'outline-danger',
    DangerGhost = 'ghost-danger'
}

export enum ButtonSize {
    Small = 'btn-sm',
    Medium = '',
    Large = 'btn-lg'
}

export interface ButtonProps {
    children?: React.ReactNode;
    disabled?: boolean;
    type?: ButtonType;
    size?: ButtonSize;
    onClick?: handleOnClickFunction;
    className?: string;
}

export const Props: PropDescription[] = [
    {
        name: 'children',
        description: 'Add content to the button',
        type: 'ReactNode',
        required: false
    },
    {
        name: 'disabled',
        description: 'Disable button when set to true',
        type: 'boolean',
        required: false
    },
    {
        name: 'type',
        description:
            'Style of the button. Primary | Secondary | Tertiary | TertiaryBlue | TertiaryWhite | Ghost | GhostDark | Danger | DangerOutline | DangerGhost',
        type: 'ButtonType',
        defaultValue: 'Primary',
        required: false
    },
    {
        name: 'size',
        description: 'Size of the button. Small | Medium | Large',
        type: 'ButtonSize',
        defaultValue: 'Large',
        required: false
    },
    {
        name: 'onClick',
        description: 'Callback function to be called on click',
        type: '() => void',
        required: false
    },
    {
        name: 'className',
        description: 'Add className to Button component',
        type: 'String',
        required: false
    }
];
