import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { AUTHID, GTMID, PREVIEWID } from '../constants/mlp-constants';

export const initializeGTM = (user_id: number, language: string, isLanguageChanged: boolean = false) => {
    if (GTMID.length > 0 && (!window?.dataLayer?.length || isLanguageChanged)) {
        const tagManagerArgs: TagManagerArgs = {
            dataLayer: {
                user_id: user_id || 0,
                language: language
            },
            gtmId: GTMID,
            auth: AUTHID,
            preview: PREVIEWID
        };
        TagManager.initialize(tagManagerArgs);
    }
};
