import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DateRangeFilter, MOTFilter } from '../../common/constants/constants-clearances';
import { AdvancedSearchDateRange, AdvancedSearchMOT } from '../../common/models/AdvancedSearch';
import { AdvancedSearchSettingsSlice } from '../../common/models/ReduxSlices';

const initialState: AdvancedSearchSettingsSlice = {
    mot: MOTFilter,
    dateRange: DateRangeFilter.map((item, index) =>
        index === 5
            ? { ...item, itemId: item.id + '-advanced-search-date-range', isChecked: true }
            : { ...item, itemId: item.id + '-advanced-search-date-range', isChecked: false }
    ),
    tagsInput: '',
    tags: []
};

const advancedSearchSettingsSlice = createSlice({
    name: 'advancedSearchSettings',
    initialState,
    reducers: {
        setTagsInput(state, action: PayloadAction<string>) {
            state.tagsInput = action.payload;
        },
        setTags(state, action: PayloadAction<string[]>) {
            state.tags = action.payload;
        },
        clearAllTags(state) {
            state.tags = [];
            state.tagsInput = '';
        },
        setAdvancedSearchSettings(
            state,
            action: PayloadAction<{ mot: AdvancedSearchMOT[]; dateRange: AdvancedSearchDateRange[]; tags: string[]; tagsInput: string }>
        ) {
            state.mot = action.payload.mot;
            state.dateRange = action.payload.dateRange;
            state.tags = action.payload.tags;
            state.tagsInput = action.payload.tagsInput;
        },
        resetAdvancedSearchSettings() {
            return { ...initialState };
        }
    }
});

export const { setTagsInput, setTags, clearAllTags, setAdvancedSearchSettings, resetAdvancedSearchSettings } =
    advancedSearchSettingsSlice.actions;

export default advancedSearchSettingsSlice.reducer;
