import { useHistory } from 'react-router';
import { PortalRoutes, REACT_APP_ASSETS_URL } from '../../../../common/constants/constants-portal';
import Breadcrumb from '../../../../common/features/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../../common/features/Breadcrumb/BreadcrumbItem';
import ErrorPage from '../../../../common/features/ErrorPage';
import Spinner from '../../../../common/features/Spinner';
import useTranslation from '../../../../common/hooks/useTranslation';
import { useProfileSelector } from '../../redux/hooks';

const RequestSuccess = () => {
    const history = useHistory();
    const translate = useTranslation();
    const { caseNumber, isLoading, error } = useProfileSelector((state) => state.requestAnUpdate);

    const returnToProfile = () => {
        history.push('/Profile');
    };

    if (error === 500) {
        return <ErrorPage errorCode={500} withTranslations />;
    }

    return (
        <>
            {isLoading && (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}

            {!isLoading && caseNumber && (
                <div className='d-flex flex-column'>
                    {/* BREADCRUMB */}
                    <Breadcrumb>
                        <BreadcrumbItem url={PortalRoutes.profile}>{translate('MyProfile_Label')}</BreadcrumbItem>
                        <BreadcrumbItem>{translate('RequestAnUpdate_Label')}</BreadcrumbItem>
                    </Breadcrumb>

                    {/* SUCCESS MESSAGE */}
                    <div className='container-narrow my-4 my-md-5'>
                        <div className='row'>
                            <div className='col'>
                                {/* CARD */}

                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='centered-header text-center'>
                                            <div className='header-image mb-3 mb-md-4'>
                                                <img
                                                    src={`${REACT_APP_ASSETS_URL}/assets/images/illustrations/objects/object-notes-tick.svg`}
                                                    alt='500 system failure'
                                                />
                                            </div>
                                            <h1 className='mb-3 mb-md-4'> {translate('RequestSubmitted_Label')} </h1>

                                            <div className='alert alert-info d-inline-block' role='alert'>
                                                {translate('YourCaseNumber_Label')} <strong>{caseNumber}</strong>
                                            </div>

                                            <p className='mb-3 mb-md-4'>{translate('ClientServiceRepresentative_Label')}</p>
                                            <button type='button' className='btn btn-primary btn-lg mb-md-4' onClick={returnToProfile}>
                                                {translate('ReturnToProfile_Label')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default RequestSuccess;
