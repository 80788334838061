import { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { userStatus as StatusConstants } from '../../constants/mlp-constants';
import TeamManagementModal from './TeamManagementModal';

class StatusChangeLink extends Component {
    state = { isOpen: false };

    static defaultProps = {
        hideCancel: false
    };

    cancelUser = (userId) => {
        this.props.cancelAction(userId);
        this.closeModal();
    };

    reactivateUser = (userId) => {
        this.props.reactivateAction(userId);
        this.closeModal();
    };

    openModal = () => {
        ReactGA.event({
            category: 'button',
            action: 'click',
            label: 'user state change'
        });
        this.setState({ isOpen: true });
    };

    closeModal = () => {
        this.setState({ isOpen: false });
    };

    deleteButton = (teamMember, canDelete) => {
        if (canDelete) {
            if (teamMember.Status === StatusConstants.ACTIVE) {
                if (this.props.hideCancel === true) return null;
                else
                    return (
                        <span>
                            <span className='btn btn-link' onClick={this.openModal}>
                                {this.props.translations.Phrases['CancelUser_Label']}
                            </span>
                            <TeamManagementModal
                                isOpen={this.state.isOpen}
                                onRequestClose={this.closeModal}
                                title={this.props.translations.Phrases['CancelUser_Label']}
                                yesOption={this.props.translations.Phrases['YesCancel_Label']}
                                noOption={this.props.translations.Phrases['No_Label']}
                                onUserClick={() => this.cancelUser(teamMember.UserId)}
                            >
                                <p>
                                    {this.props.translations.Phrases['CancelConfirmation_Label']} {teamMember.FirstName}{' '}
                                    {teamMember.LastName}?
                                </p>
                            </TeamManagementModal>
                        </span>
                    );
            } else {
                return (
                    <span>
                        <span className='btn btn-link' onClick={this.openModal}>
                            {this.props.translations.Phrases['ReactivateUser_Label']}
                        </span>
                        <TeamManagementModal
                            isOpen={this.state.isOpen}
                            onRequestClose={this.closeModal}
                            title={this.props.translations.Phrases['ReactivateUser_Label']}
                            yesOption={this.props.translations.Phrases['ReactivateUser_Label']}
                            noOption={this.props.translations.Phrases['Cancel_Button']}
                            onUserClick={() => this.reactivateUser(teamMember.UserId)}
                        >
                            <p>
                                {this.props.translations.Phrases['ReactivateConfirmation_Label']} {teamMember.FirstName}{' '}
                                {teamMember.LastName}?
                            </p>
                        </TeamManagementModal>
                    </span>
                );
            }
        } else {
            return null;
        }
    };

    render() {
        const teamMember = this.props.teamMember;
        const canDelete = this.props.canDelete;

        return this.deleteButton(teamMember, canDelete);
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(StatusChangeLink);
