import { GridCellProps } from '@progress/kendo-react-grid';
import { useHistory } from 'react-router-dom';
import ActionButtonElipsis from '../../../../../common/features/Grid/ActionButtonElipsis';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ActionButtonOptions } from '../../../common/constants/constants-subscribers';
import { useSubscribersDispatch } from '../../../redux/hooks';
import { resetDataState } from '../../../redux/reducers/subscribersGridSettingsSlice';

interface ActionButtonProps extends GridCellProps {
    onRemoveSubscriber: (id?: null | number) => void;
    disabled: boolean;
}

export const ActionButton: React.FC<ActionButtonProps> = ({ onRemoveSubscriber, disabled, dataItem, className, style, ...rest }) => {
    const translate = useTranslation();
    const dispatch = useSubscribersDispatch();
    const { push } = useHistory();
    const handleAction = (optionId: (typeof ActionButtonOptions)[number]['id']) => {
        switch (optionId) {
            case 'EDIT_SUBSCRIPTION': {
                dispatch(resetDataState());

                return push({
                    pathname: `/Subscribers/${dataItem.RowId}`,
                    state: {
                        isEditable: true,
                        editEmail: dataItem.Email,
                        editPhone: dataItem.MobileNumber,
                        fullName: dataItem.FullName,
                        role: dataItem.Role,
                        language: dataItem.Language,
                        userId: dataItem.UserId
                    }
                });
            }
            case 'DELETE_SUBSCRIPTION': {
                onRemoveSubscriber(dataItem?.RowId);
                return;
            }
            default:
                break;
        }
    };

    return (
        <ActionButtonElipsis
            options={ActionButtonOptions.map((option) => ({ ...option, label: translate(option.label) }))}
            dangerOption={'DELETE_SUBSCRIPTION'}
            onAction={(option) => handleAction(option as (typeof ActionButtonOptions)[number]['id'])}
            disabled={disabled}
            dataItem={dataItem}
            className={className}
            style={style}
            {...rest}
        />
    );
};
