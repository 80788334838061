import React from 'react';
import { Redirect } from 'react-router-dom';
import { usePortalSelector } from '../../redux/hooks';

type Conditions = {
    isInternalUser?: boolean;
};

interface ProtectedProps {
    conditions: Conditions;
    redirectUrl: string;
    children: React.ReactNode;
}

export default function Protected({ conditions, redirectUrl, children }: ProtectedProps) {
    const { user } = usePortalSelector((state) => state.hydration);

    if (!user) {
        return <>{children}</>;
    }

    const conditionMap: Conditions = {
        isInternalUser: user.InternalUser || false
    };

    const isAllowed = Object.entries(conditions).every(([key, value]) => conditionMap[key as keyof Conditions] === value);

    if (isAllowed) {
        return <>{children}</>;
    } else {
        return <Redirect to={redirectUrl} />;
    }
}
