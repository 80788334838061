import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatTranslated, isVisible } from '../../utils/mlp-utils';
import CustomDropDown from '../CustomDropDown/CustomDropDown';
import Textbox from '../Textbox/Textbox';
import { filters as Constants } from './../../constants/mlp-constants';

class PartiesFilter extends Component {
    state = {
        hidden: false
    };

    static defaultProps = {
        countryCodes: ['CA', 'US'],
        searchTypeCode: '*'
    };

    static propTypes = {
        countryCodes: PropTypes.arrayOf(PropTypes.string),
        searchTypeCode: PropTypes.string,
        filterControl: PropTypes.object,
        onOptionSelect: PropTypes.func,
        onValueChanged: PropTypes.func,
        customAction: PropTypes.func
    };

    toggleSection = () => {
        this.setState((prevState) => {
            return { hidden: !prevState.hidden };
        });
    };

    filterOptionSelect = (newIndex, oldIndex, name) => {
        this.props.onOptionSelect(newIndex, oldIndex, name);

        const filterControl = this.props.filterControl;
        const filterIndex = filterControl.FilterIndex;
        const selectedItem = filterControl.Options[newIndex];

        const nameFilter = 'Filters.' + filterIndex;

        if (!selectedItem) {
            this.props.onValueChanged('string', nameFilter + '.AttributeDataType');
            this.props.onValueChanged(Constants.ALL_TYPES, nameFilter + '.Attribute');
        } else {
            this.props.onValueChanged(selectedItem.DataType, nameFilter + '.AttributeDataType');
            this.props.onValueChanged(selectedItem.Value, nameFilter + '.Attribute');
        }
        if (filterControl.Value && filterControl.Value.length >= 1) {
            this.props.customAction();
        }
    };

    render() {
        const filterControl = this.props.filterControl;
        const filterIndex = filterControl.FilterIndex;
        const name = 'Filters.' + filterIndex + '.Value';

        if (!isVisible(filterControl, this.props.searchTypeCode)) {
            return null;
        }

        const close = this.state.hidden
            ? formatTranslated('Show_Label', this.props.translations.Phrases)
            : formatTranslated('Hide_Label', this.props.translations.Phrases);

        const customDropDown = (
            <div className='filter-dropdown'>
                <CustomDropDown
                    name={'Filters.' + filterIndex + '.Options'}
                    options={filterControl.Options}
                    valueField='Value'
                    textField='TranslatedName'
                    onItemChanged={this.filterOptionSelect}
                />
            </div>
        );

        const searchBox = (
            <div className='shipment-search-container'>
                <div className='search-box shipment-search'>
                    <Textbox
                        placeholder=''
                        className='imaging-search-textbox'
                        name={name}
                        onValueChanged={this.props.onValueChanged}
                        customAction={this.props.customAction}
                        fireOnChange={true}
                        fireOnImageBlur={true}
                        icon=''
                        value={filterControl.Value}
                    />
                </div>
            </div>
        );

        return (
            <div className='filter-section'>
                <div className='row header-section'>
                    <h4 className='col-sm-8 text-left header'>{formatTranslated('Parties_Label', this.props.translations.Phrases)}</h4>
                    <div className='col-sm-4 text-right'>
                        <span className='btn btn-link' onClick={this.toggleSection}>
                            {close}
                        </span>
                    </div>
                </div>
                <div className={this.state.hidden ? 'd-none' : 'd-block'}>
                    {customDropDown}
                    {searchBox}
                    <hr />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(PartiesFilter);
