import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import useTabSelection from '../../hooks/useTabSelection';

interface TabNavItemProps {
    id: string;
    children: React.ReactNode;
    onClick?: () => void;
    options?: Array<{ label: string; value: string; id: string; onClick?: (id: string) => void } | null>;
    activeTab?: string;
}

const TabNavItem: React.FC<TabNavItemProps> = ({ id, children, onClick, options, activeTab }) => {
    const [dropdownMenuStyling, setDropdownMenuStyling] = useState<CSSProperties>({});

    const { active, handleClick } = useTabSelection({ id });

    const dropdownRef = useRef<HTMLAnchorElement>(null);

    const dropdownItemRefs = useRef<Array<HTMLAnchorElement | null>>([]);

    useEffect(() => {
        if (!dropdownRef || !dropdownRef.current) {
            return;
        }
        const offsetY = dropdownRef.current.offsetHeight - 6;
        setDropdownMenuStyling({ marginTop: offsetY });
    }, [dropdownRef]);

    useEffect(() => {
        dropdownItemRefs.current = dropdownItemRefs.current.map((r) => r?.classList.remove('active') || r);
    }, [activeTab]);

    // Dropdown tab
    if (options)
        return (
            <div className='nav-item dropdown'>
                <a
                    className={`nav-link dropdown-toggle ${id ? 'active' : ''}`}
                    aria-haspopup='true'
                    aria-expanded='false'
                    data-toggle='dropdown'
                    role='button'
                    id='DetailsDropdownButton'
                    style={{ borderColor: '#fff', marginBottom: 'unset' }}
                    ref={dropdownRef}
                >
                    {children}
                </a>
                <div
                    id={'DetailsDropdown'}
                    className='dropdown-menu override-nav-styling'
                    aria-labelledby='DetailsDropdownButton'
                    style={dropdownMenuStyling}
                >
                    {options?.map((option, i) => {
                        if (!option) return;

                        if (option.value === 'download')
                            return (
                                <div key={i}>
                                    <div className='dropdown-divider'></div>
                                    <a
                                        className='dropdown-item d-flex justify-content-between align-items-center omit-checkmark text-primary'
                                        href={'#'}
                                        onClick={() => {
                                            if (option.onClick) option.onClick(option.id);
                                        }}
                                    >
                                        <div>{option.label}</div>
                                        <FontAwesomeIcon icon={faFileDownload} className={'ml-5 text-primary'} />
                                    </a>
                                </div>
                            );

                        return (
                            <a
                                ref={(el) => (dropdownItemRefs.current[i] = el)}
                                key={i}
                                className={`dropdown-item`}
                                id={option.id + '-tab'}
                                href={'#' + option.id}
                                data-toggle='tab'
                                role={'tab'}
                                aria-label={option.value}
                                aria-controls={option.id}
                                // aria-selected={activeTab === option.id}
                                onClick={() => {
                                    if (option.onClick) option.onClick(option.id);
                                    handleClick(option.id);
                                }}
                                style={{
                                    backgroundColor: 'unset'
                                }}
                            >
                                {option.label}
                            </a>
                        );
                    })}
                </div>
            </div>
        );

    // Regular tab
    return (
        <a
            className={`nav-link ${active ? 'active' : ''}`}
            id={`${id}-tab`}
            data-toggle='tab'
            href={`#${id}`}
            role='tab'
            aria-controls={id}
            aria-selected={active}
            style={{ borderColor: '#fff', marginBottom: 'unset' }}
            onClick={() => {
                if (onClick) onClick();
                handleClick();
            }}
        >
            {children}
        </a>
    );
};

export default TabNavItem;
