export const GET_SHIPMENT_LIST = 'GET_SHIPMENT_LIST';
export const GET_CLEARANCE_DETAILS = 'GET_CLEARANCE_DETAILS';
export const GET_CLEARANCE_SUBSCRIBERS = 'GET_CLEARANCE_SUBSCRIBERS';
export const UPDATE_CLEARANCE_SUBSCRIBERS = 'UPDATE_CLEARANCE_SUBSCRIBERS';
export const UNSUBSCRIBE_USERS = 'UNSUBSCRIBE_USERS';
export const SUBSCRIBE_USERS = 'SUBSCRIBE_USERS';
export const GET_NOTIFICATIONS_SETTINGS = 'GET_NOTIFICATIONS_SETTINGS';
export const UPDATE_NOTIFICATIONS_SETTINGS = 'UPDATE_NOTIFICATIONS_SETTINGS';
export const GET_CLERANCES_GRID_PREFERENCES = 'GET_CLERANCES_GRID_PREFERENCES';
export const SAVE_CLERANCES_GRID_PREFERENCES = 'SAVE_CLERANCES_GRID_PREFERENCES';
export const UPDATE_CLEARANCES_GRID_PREFERENCES = 'UPDATE_CLEARANCES_GRID_PREFERENCES';
export const GET_TEAM_MEMBERS_AND_CONTACTS = 'GET_TEAM_MEMBERS_AND_CONTACTS';
export const GET_LINE_DETAILS = 'GET_LINE_DETAILS';
export const GET_SHIPMENT_HEADER = 'GET_SHIPMENT_HEADER';
export const GET_DOCUMENTS_IMAGES = 'GET_DOCUMENTS_IMAGES';
export const DOWNLOAD_DOCUMENTS = 'DOWNLOAD_DOCUMENTS';
export const EXPORT_HEADER_AND_DETAIL = 'EXPORT_HEADER_AND_DETAIL';
export const GET_DOCUMENTS_INDEX = 'GET_DOCUMENTS_INDEX';
export const GET_SHIPMENT_DATA_SOURCE = 'GET_SHIPMENT_DATA_SOURCE';
export const SET_SHIPMENT_DATA_SOURCE = 'SET_SHIPMENT_DATA_SOURCE';
export const GET_FULL_CAD_DATA = 'GET_FULL_CAD_DATA';
export const GET_ALL_CLEARANCES = 'GET_ALL_CLEARANCES';
export const DOWNLOAD_CLEARANCES = 'DOWNLOAD_CLEARANCES';
