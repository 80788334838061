import { DocumentsIndex, ImagingSelectItemModel } from '../models/Documents';

export const configFilters = (documentsIndex: DocumentsIndex, entryNumbers: string[]) => {
    if (entryNumbers.length === 1) {
        const filterIndex = documentsIndex.Filters?.findIndex((filter) => filter.Name === 'Main Search');
        if (filterIndex !== -1) {
            let updatedFilters = [...documentsIndex.Filters!];
            updatedFilters[filterIndex] = {
                ...updatedFilters[filterIndex],
                Value: entryNumbers[0],
                Attribute: 'allTypes'
            };

            return updatedFilters;
        }
    } else if (entryNumbers.length > 1) {
        let updatedOptions: ImagingSelectItemModel[] = [];
        entryNumbers.forEach((item) => {
            updatedOptions.push({
                Name: item,
                SearchTypeCodes: ['USSHP'],
                Value: item,
                DataType: 'Keyword',
                IsSelected: true,
                SortValue: null
            });
        });

        const filterIndex = documentsIndex.Filters?.findIndex((filter) => filter.Attribute === '_entryNumber');
        if (filterIndex !== -1) {
            let updatedFilters = [...documentsIndex.Filters!];
            updatedFilters[filterIndex] = {
                ...updatedFilters[filterIndex],
                Options: updatedOptions,
                SearchParameterType: 'TermsParam'
            };

            return updatedFilters;
        }
    }
};
