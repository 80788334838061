export const SET_IMAGES = 'SET_IMAGES';
export const INIT_IMAGE_MODEL = 'INIT_IMAGE_MODEL';
export const SUBMIT_IMAGE_SEARCH = 'SUBMIT_IMAGE_SEARCH';
export const UPDATE_GRID_MODEL = 'UPDATE_GRID_MODEL';
export const CLEAR_IMAGE_FILTERS = 'CLEAR_IMAGE_FILTERS';
export const UPDATE_IMAGE_CLIENT_SERVICES = 'UPDATE_IMAGE_CLIENT_SERVICES';
export const CLEAR_IMAGE_CLIENT_SERVICES = 'CLEAR_IMAGE_CLIENT_SERVICES';
export const SELECT_IMAGE_SEARCH_TYPE = 'SELECT_IMAGE_SEARCH_TYPE';
export const SELECT_IMAGE_GROUP_BY_OPTION = 'SELECT_IMAGE_GROUP_BY_OPTION';
export const SELECT_IMAGE_DOCS = 'SELECT_IMAGE_DOCS';
export const SET_IMAGE_DETAILS = 'SET_IMAGE_DETAILS';
export const IMAGE_FILTER_OPTION_SELECT = 'IMAGE_FILTER_OPTION_SELECT';
export const IMAGE_FILTER_VALUE_CHANGE = 'IMAGE_FILTER_VALUE_CHANGE';
export const IMAGE_DOC_TYPE_FILTER_VALUE_CHANGE = 'IMAGE_DOC_TYPE_FILTER_VALUE_CHANGE';
export const IMAGE_DOC_TYPE_FILTER_SELECT_ALL = 'IMAGE_DOC_TYPE_FILTER_SELECT_ALL';
export const FILTER_IMAGES = 'FILTER_IMAGES';
export const SET_DOWNLOAD_DOCUMENT_URL = 'SET_DOWNLOAD_DOCUMENT_URL';
export const SORT_OPTION_SELECT = 'SORT_OPTION_SELECT';
export const SELECT_ENTRY = 'SELECT_ENTRY';
export const SELECT_DOCUMENT = 'SELECT_DOCUMENT';
export const LOAD_IMAGES = 'LOAD_IMAGES';
export const RESET_SUBMIT_IMAGE_SEARCH_FLAG = 'RESET_SUBMIT_IMAGE_SEARCH_FLAG';
export const SET_DROPDOWN_YEARS = 'SET_DROPDOWN_YEARS';
export const RESET_IMAGE_STATE = 'RESET_IMAGE_STATE';
export const RESET_IMAGE_DETAILS_STATE = 'RESET_IMAGE_DETAILS_STATE';
export const SET_IMAGE_MODEL = 'SET_IMAGE_MODEL';
export const IMAGE_MOT_FILTER_VALUE_CHANGE = "IMAGE_MOT_FILTER_VALUE_CHANGE";
export const MOT_FILTER_SELECT_ALL = "MOT_FILTER_SELECT_ALL";