import produce from 'immer';
import { global, userRole } from '../../constants/mlp-constants';
import * as actionTypes from '../actions/clientAdmin/onBehalfOfActionTypes';

const initialState = {
    model: {
        User: {},
        ClientServicesByCountry: [],
        TeamMembers: [],
        TeamMembersModalVisible: false,
        Note: '',
        UserHasPendingChanges: '',
        IsReadOnly: '',
        IsOnBehalfOf: true,
        RejectionMessage: null,
        ChangedProperties: {}
    },
    validationMessages: {}
};

const selectAllFeaturesCA = (state, action) => {
    return produce(state, (draft) => {
        let features = [];
        let value = true;
        const paymentAdmin = draft.model.User.Roles.find((x) => x.IsSelected === true).Value === userRole.PAYMENTADMIN;

        const paymentFeature =
            draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[
                action.payload.parentIndex
            ].ClientServices[0].Features.find((f) => {
                return f.FeatureId === action.payload.featureId;
            }).FeatureCode === global.PAY_FEATURE_CODE;

        if (paymentAdmin) {
            draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[
                action.payload.parentIndex
            ].ClientServices.forEach((s) => {
                if (!s.PaymentAdminId && !s.PendingPaymentAdminId && s.IsPaymentEligible) {
                    features.push(
                        s.Features.find((f) => {
                            return f.FeatureId === action.payload.featureId;
                        })
                    );
                }
            });
        } else {
            draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[
                action.payload.parentIndex
            ].ClientServices.forEach((s) => {
                if (
                    (!paymentFeature && !s.ClientAdminId && !s.PendingClientAdminId) ||
                    (paymentFeature && !s.PaymentAdminId && !s.PendingPaymentAdminId && s.IsPaymentEligible)
                ) {
                    features.push(
                        s.Features.find((f) => {
                            return f.FeatureId === action.payload.featureId;
                        })
                    );
                }
            });
        }

        if (
            features.every((f) => {
                return f.IsSelected;
            })
        ) {
            value = false;
        }

        if (value === true && !paymentAdmin && !paymentFeature) {
            draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[
                action.payload.parentIndex
            ].ClientServices.forEach((s) => {
                if (!s.ClientAdminId && !s.PendingClientAdminId) {
                    let f = s.Features.find((f) => {
                        return f.FeatureCode === global.MLP_FEATURE_CODE;
                    });
                    f.IsSelected = true;
                }
            });
        }

        features.forEach((f) => {
            f.IsSelected = value;
        });
    });
};

const toggleCountryServices = (state, action) => {
    return produce(state, (draft) => {
        draft.model.ClientServicesByCountry[action.payload.countryIndex].IsHidden =
            !draft.model.ClientServicesByCountry[action.payload.countryIndex].IsHidden;
    });
};

const removeParentService = (state, action) => {
    return produce(state, (draft) => {
        draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices.splice(action.payload.parentIndex, 1);
    });
};

const updateFeature = (state, action) => {
    return produce(state, (draft) => {
        let cs =
            draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[action.payload.parentIndex].ClientServices[
                action.payload.clientIndex
            ];
        cs.Features[action.payload.featureIndex].IsSelected = action.payload.isSelected;
        const mlpFeature = cs.Features.find((f) => {
            return f.FeatureCode === global.MLP_FEATURE_CODE;
        });
        const paymentFeature = cs.Features.find((f) => {
            return f.FeatureCode === global.PAY_FEATURE_CODE;
        });
        const mlpFeatureIndex = cs.Features.indexOf(mlpFeature);
        const selectedFeatures = cs.Features.filter((item) => {
            return item.IsSelected === true && item.FeatureCode !== global.PAY_FEATURE_CODE && item.FeatureCode !== global.MLP_FEATURE_CODE;
        });
        if (action.payload.isSelected) {
            // autocheck mlp column if exists
            if (cs.Features[mlpFeatureIndex]) {
                cs.Features[mlpFeatureIndex].IsSelected = true;
            }
        } else if (action.payload.featureIndex === cs.Features.indexOf(paymentFeature)) {
            //  when MakePayment is only selected feature autocheck/uncheck mlp column if exists
            if (cs.Features[mlpFeatureIndex]) {
                cs.Features[mlpFeatureIndex].IsSelected = selectedFeatures.length === 0 ? action.payload.isSelected : true;
            }
        }
    });
};

const removeClientService = (state, action) => {
    return produce(state, (draft) => {
        draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[action.payload.parentIndex].ClientServices.splice(
            action.clientIndex,
            1
        );
    });
};

const afterShowTeamMembers = (state, action) => {
    return produce(state, (draft) => {
        draft.model.TeamMembers = action.payload.teamMemberData;
        draft.model.TeamMembersModalVisible = true;
    });
};

const closeTeamMembersModalOBO = (state, action) => {
    return produce(state, (draft) => {
        draft.model.TeamMembersModalVisible = false;
    });
};

const removeFeature = (state, action) => {
    return produce(state, (draft) => {
        draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[action.payload.parentIndex].AvailableFeatures.find(
            (f) => {
                return f.FeatureId === action.payload.featureId;
            }
        ).IsSelected = false;
        draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[action.payload.parentIndex].ClientServices.forEach(
            (cs) => {
                cs.Features.find((f) => {
                    return f.FeatureId === action.payload.featureId;
                }).IsSelected = false;
            }
        );
    });
};

const addFeature = (state, action) => {
    return produce(state, (draft) => {
        draft.model.ClientServicesByCountry[action.payload.countryIndex].ParentServices[action.payload.parentIndex].AvailableFeatures.find(
            (f) => {
                return f.FeatureId === action.payload.featureId;
            }
        ).IsSelected = true;
    });
};

const findWhere = (array, criteria) => {
    return array.find((item) => Object.keys(criteria).every((key) => item[key] === criteria[key]));
};

const afterGetOnBehalfOf = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.payload.model;
        draft.model.OriginalUser = action.payload.model.User;
        draft.model.TeamMembersModalVisible = false;
        draft.model.TeamMembers = [];
        if (draft.model.CoordinatorCountryCode) {
            draft.model.ClientServicesByCountry.forEach((c) => {
                if (c.CountryCode.toLowerCase() !== draft.model.CoordinatorCountryCode.toLowerCase()) {
                    c.IsHidden = true;
                    c.IsReadonly = true;
                }
                c.ParentServices.forEach((ps) => {
                    ps.ClientServices.forEach((cs) => {
                        if (cs.ClientAdminName.trim() === '' || cs.ClientAdminName === undefined) {
                            cs.IsReadonly = false;
                        } else {
                            cs.IsReadonly = true;
                        }
                    });
                });
            });
        }
    });
};

const afterAddClientService = (state, action) => {
    return produce(state, (draft) => {
        draft.model.ClientServicesByCountry.forEach((csc) => {
            if (draft.model.IsOnBehalfOf || csc.CountryCode.toLowerCase() === draft.model.CoordinatorCountryCode.toLowerCase()) {
                // split children accross countries
                const parents = action.payload.item;
                let svc = parents.find((p) => {
                    return p.CountryCode === csc.CountryCode;
                });

                if (!svc) {
                    return;
                }

                svc.ClientServices.forEach((s) => {
                    if (s.CountryCode.toLowerCase() !== csc.CountryCode.toLowerCase()) {
                        const index = svc.ClientServices.indexOf(s);
                        svc.ClientServices.splice(index, 1);
                    }
                });

                // add parent service if it doesn't exist
                // if it does exist add only missing client services
                const existingParentService = findWhere(csc.ParentServices, { Number: svc.Number });

                if (existingParentService === undefined) {
                    csc.ParentServices.push(svc);
                    csc.ParentServices.sort((a, b) => a.Number - b.Number);
                } else {
                    const servicesToAdd = svc.ClientServices.filter((n) => {
                        return !findWhere(existingParentService.ClientServices, { Number: n.Number, CaptionNumber: n.CaptionNumber });
                    });

                    if (servicesToAdd.length > 0) {
                        existingParentService.ClientServices = existingParentService.ClientServices.concat(servicesToAdd);
                    }
                }
            }
        });
    });
};

const selectSalesforceContactCA = (state, action) => {
    return produce(state, (draft) => {
        const expirationDate = draft.model.User.ExpirationDate;

        draft.model.User = action.payload.contact;
        draft.model.User.UserId = draft.model.OriginalUser.UserId;
        draft.model.User.Roles = draft.model.OriginalUser.Roles;
        draft.model.User.ExpirationDate = expirationDate;
        draft.model.UserChanged = true;
        draft.model.User.isSelected = true;

        if (!draft.model.ChangedProperties.includes('User.FirstName')) {
            draft.model.ChangedProperties.push('User.FirstName');
        }

        if (!draft.model.ChangedProperties.includes('User.LastName')) {
            draft.model.ChangedProperties.push('User.LastName');
        }

        if (!draft.model.ChangedProperties.includes('User.SalesforceId')) {
            draft.model.ChangedProperties.push('User.SalesforceId');
        }

        if (!draft.model.ChangedProperties.includes('User.Email')) {
            draft.model.ChangedProperties.push('User.Email');
        }
    });
};

const resetUser = (state) => {
    return produce(state, (draft) => {
        draft.model.User = draft.model.OriginalUser;
        draft.model.UserChanged = false;

        if (!draft.model.ChangedProperties.includes('User.Email')) {
            draft.model.ChangedProperties.push('User.Email');
        }

        if (!draft.model.ChangedProperties.includes('User.SalesforceId')) {
            draft.model.ChangedProperties.push('User.SalesforceId');
        }
    });
};

const oboSetValidationMessages = (state, action) => {
    return produce(state, (draft) => {
        draft.validationMessages = action.payload.validationMessages;
    });
};

const updateClientServicesByCountry = (state) => {
    return produce(state, (draft) => {
        draft.model.ClientServicesByCountry[0].ParentServices = [];
        draft.model.ClientServicesByCountry[1].ParentServices = [];
    });
};

const oboChangeUserRole = (state, action) => {
    return produce(state, (draft) => {
        draft.model.User.Roles.find((x) => x.IsSelected === true).IsSelected = false;
        draft.model.User.Roles[action.payload.item].IsSelected = true;
    });
};

export const expirationDateChanged = (state, action) => {
    return produce(state, (draft) => {
        draft.model.User.ExpirationDate = action.payload.date;
    });
};

export const setRejectMessage = (state, action) => {
    return produce(state, (draft) => {
        draft.model.RejectionMessage = action.payload.rejectMsg;
    });
};

export const resetStateOnBehalfOf = (state) => {
    return produce(state, (draft) => {
        if (draft) {
            draft.model.User = {};
            draft.model.ClientServicesByCountry = [];
            draft.model.TeamMembers = [];
            draft.model.TeamMembersModalVisible = false;
            draft.model.Note = '';
            draft.model.UserHasPendingChanges = '';
            draft.model.IsReadOnly = '';
            draft.model.IsOnBehalfOf = true;
            draft.model.RejectionMessage = null;
            draft.model.ChangedProperties = {};
            draft.validationMessages = {};
        }
    });
};

export const updateNoteField = (state, action) => {
    return produce(state, (draft) => {
        draft.model.Note = action.payload.value;
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_COUNTRY_SERVICES:
            return toggleCountryServices(state, action);
        case actionTypes.REMOVE_PARENT_SERVICE:
            return removeParentService(state, action);
        case actionTypes.UPDATE_FEATURE:
            return updateFeature(state, action);
        case actionTypes.REMOVE_CLIENT_SERVICE:
            return removeClientService(state, action);
        case actionTypes.AFTER_SHOW_TEAM_MEMBERS:
            return afterShowTeamMembers(state, action);
        case actionTypes.REMOVE_FEATURE:
            return removeFeature(state, action);
        case actionTypes.ADD_FEATURE:
            return addFeature(state, action);
        case actionTypes.SELECT_ALL_FEATURES_CA:
            return selectAllFeaturesCA(state, action);
        case actionTypes.AFTER_ADD_CLIENT_SERVICE:
            return afterAddClientService(state, action);
        case actionTypes.SELECT_SALESFORCE_CONTACT:
            return selectSalesforceContactCA(state, action);
        case actionTypes.UPDATE_CLIENT_SERVICES_BY_COUNTRY:
            return updateClientServicesByCountry(state);
        case actionTypes.RESET_USER:
            return resetUser(state);
        case actionTypes.AFTER_GET_ON_BEHALF_OF:
            return afterGetOnBehalfOf(state, action);
        case actionTypes.CLOSE_TEAM_MEMBERS_MODAL_OBO:
            return closeTeamMembersModalOBO(state, action);
        case actionTypes.OBO_EXPIRATION_DATE_CHANGED:
            return expirationDateChanged(state, action);
        case actionTypes.OBO_CHANGE_USER_ROLE:
            return oboChangeUserRole(state, action);
        case actionTypes.OBO_SET_VALIDATION_MESSAGES:
            return oboSetValidationMessages(state, action);
        case actionTypes.CHANGE_ON_BEHALF_OF_REJECT_MSG:
            return setRejectMessage(state, action);
        case actionTypes.RESET_STATE_ON_BEHALF_OF:
            return resetStateOnBehalfOf(state);
        case actionTypes.UPDATE_NOTE_FIELD:
            return updateNoteField(state, action);
        default:
            return state;
    }
};

export default reducer;
