import { faBell, faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import Button from '../../../../../../common/features/Button';
import Checkbox from '../../../../../../common/features/Checkbox';
import DropdownMenuButton from '../../../../../../common/features/DropdownMenuButton';
import Modal from '../../../../../../common/features/Modal';
import Spinner from '../../../../../../common/features/Spinner';
import Tooltip from '../../../../../../common/features/Tooltip/Tooltip';
import useGTMEventTracking from '../../../../../../common/hooks/useGTMEventTracking';
import useNotifDropdownToggle from '../../../../../../common/hooks/useNotifDropdownToggle';
import useTranslation from '../../../../../../common/hooks/useTranslation';
import { Notifications } from '../../../../../../common/models/ResponseData';
import { ShipmentDetailsData } from '../../../../common/models/ResponseData';
import { GetNotificationsSettings, UpdateNotificationSettings } from '../../../../redux/actions/clearanceSubscribers';
import { useClearancesDispatch, useClearancesSelector } from '../../../../redux/hooks';
import { setSelfSubscriptionData } from '../../../../redux/reducers/clearanceSubscribersSlice';
import MilestonesSection from './MilestonesSection';
import NotificationsSection from './NotificationsSection';
const NotificationsDropdown = () => {
    const { shipmentDetails, country } = useClearancesSelector((state) => state.shipmentDetails);
    const { selfSubscription, selfSubscriptionUpdate, selfSubscriptionUpdateRequest, subscriptionUpdate, unsubscribe } =
        useClearancesSelector((state) => state.clearanceSubscribers);
    const { user } = useClearancesSelector((state) => state.hydration);
    const [noneMilestonesSelected, setNoneMilestonesSelected] = useState<boolean>();
    const [isApplyDisabled, setIsApplyDisabled] = useState<boolean>();
    const [notificationsEdited, setNotificationsEdited] = useState<ShipmentDetailsData['Subscription']>();
    const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
    const dropdownMenuRef = useRef(null);
    const { isOpen, handleToggle } = useNotifDropdownToggle(dropdownMenuRef);
    const dispatch = useClearancesDispatch();
    const translate = useTranslation();
    const subscribeClearancesEntryRef = useRef(null);

    useGTMEventTracking('Subs_Entry_CLR_CPEvt', { location: 'Clearances ' }, subscribeClearancesEntryRef);
    // On initial set the "selfSubscription" data from "shipmentDetails/Subscription" data
    useEffect(() => {
        if (shipmentDetails?.Subscription) {
            dispatch(setSelfSubscriptionData(shipmentDetails.Subscription));
        }
    }, [dispatch, shipmentDetails?.Subscription]);

    // Update "selfSubscription" slice on each "selfSubscriptionUpdateRequest" that is sent from the grid or within this dropdown
    useEffect(() => {
        if (
            selfSubscriptionUpdateRequest &&
            shipmentDetails?.CustomsInfoID &&
            shipmentDetails.ClientCaptionNumber &&
            !subscriptionUpdate.isLoading &&
            !selfSubscriptionUpdate.isLoading &&
            !unsubscribe.isLoading
        ) {
            dispatch(
                GetNotificationsSettings({
                    customsInfoId: shipmentDetails.CustomsInfoID,
                    clientCaptionNumber: shipmentDetails.ClientCaptionNumber,
                    profileEmail: user?.Email,
                    profileMobileNumber: user?.MobileNumber
                })
            );
        }
    }, [
        dispatch,
        selfSubscriptionUpdateRequest,
        shipmentDetails?.ClientCaptionNumber,
        shipmentDetails?.CustomsInfoID,
        subscriptionUpdate.isLoading,
        selfSubscriptionUpdate.isLoading,
        unsubscribe.isLoading,
        user?.Email,
        user?.MobileNumber
    ]);

    // Update the local state that is used for "milestones" and "on hold" checkboxes
    useEffect(() => {
        if (!selfSubscription.isLoading && !selfSubscription.error && selfSubscription.data) {
            setNotificationsEdited(selfSubscription.data);
        }
    }, [selfSubscription.error, selfSubscription.isLoading, selfSubscription.data]);

    // Update for "submit" button enabled/disabled state
    useEffect(() => {
        if (notificationsEdited) {
            if (
                (notificationsEdited.Email || notificationsEdited.Phone) &&
                (!noneMilestonesSelected || notificationsEdited[`HasNotificationsForClearanceOnHold${country === 'ca' ? '_CA' : ''}`])
            ) {
                setIsApplyDisabled(false);
            } else {
                setIsApplyDisabled(true);
            }
        }
    }, [notificationsEdited, noneMilestonesSelected, country, selfSubscription.email, selfSubscription.phone]);

    const handleEditingNotifications = (
        obj: null | Notifications | ShipmentDetailsData['Subscription'],
        notification: null | string,
        value: null | string | boolean
    ) => {
        if (selfSubscription.data && notificationsEdited) {
            if (obj) {
                setNotificationsEdited({
                    ...notificationsEdited,
                    ...obj,
                    HasNotificationsForClearanceOnHold_CA: notificationsEdited.HasNotificationsForClearanceOnHold_CA,
                    HasNotificationsForClearanceOnHold: notificationsEdited.HasNotificationsForClearanceOnHold
                });
            } else {
                setNotificationsEdited({ ...notificationsEdited, [notification!]: value });
            }
        }
    };

    const handleSubmit = (unsub: boolean) => {
        if (!selfSubscription.data) return;

        let unsubNotifications = { ...selfSubscription.data };
        if (unsub) {
            Object.entries(selfSubscription.data).forEach(([key]) => {
                if (key.includes('HasNotifications')) {
                    if (country === 'ca' && key.includes('_CA')) {
                        unsubNotifications = { ...unsubNotifications, [key]: false };
                    } else if (country === 'us' && !key.includes('_CA')) {
                        unsubNotifications = { ...unsubNotifications, [key]: false };
                    }
                }
            });
        }
        if (shipmentDetails?.CustomsInfoID && shipmentDetails?.ClientCaptionNumber) {
            dispatch(
                UpdateNotificationSettings({
                    customsInfoId: shipmentDetails.CustomsInfoID,
                    clientCaptionNumber: shipmentDetails.ClientCaptionNumber,
                    notificationSettings: unsub ? unsubNotifications : notificationsEdited
                })
            );
        }

        // Hide the unsubscribe modal
        setShowUnsubscribeModal(false);
    };

    const applyButtonContent =
        selfSubscription.data?.SubscriptionIds?.length && selfSubscription.data.SubscriptionIds[0]
            ? translate('Apply_Label')
            : translate('NotificationsSubscribe_Label');

    const onHoldHandler = () => {
        handleEditingNotifications(
            null,
            `HasNotificationsForClearanceOnHold${country === 'ca' ? '_CA' : ''}`,
            country === 'ca'
                ? !notificationsEdited?.HasNotificationsForClearanceOnHold_CA
                : !notificationsEdited?.HasNotificationsForClearanceOnHold
        );
    };
    return (
        <>
            <DropdownMenuButton
                btnSize='small'
                childrenDivClassName={'edit-subscription dropdown-menu dropdown-menu-right responsive-height'}
                isOpen={isOpen}
                onClick={handleToggle}
                ref={dropdownMenuRef}
                label={
                    <>
                        <FontAwesomeIcon icon={faBell} className='mr-2' />
                        {selfSubscription.data && selfSubscription.data.SubscriptionIds?.length
                            ? translate('ManageNotifications_Label')
                            : translate('SubscribeToThisClearance_Label')}
                        {(selfSubscription.isLoading || selfSubscriptionUpdate.isLoading) && <Spinner size='small' className='ml-2' />}
                    </>
                }
            >
                <>
                    <div className='dropdown-heading dropdown-header d-flex justify-content-between pt-0'>
                        <div className='name'>
                            <p className='mb-0'>{translate('ManageNotificationsToThisClearance_Label')}</p>
                        </div>
                    </div>
                    <NotificationsSection
                        email={selfSubscription.email ? selfSubscription.email : ''}
                        emailChecked={!!selfSubscription.data?.Email}
                        phone={selfSubscription.phone ? selfSubscription.phone : ''}
                        handleMilestonesChange={handleEditingNotifications}
                        disableApply={setIsApplyDisabled}
                    />
                    <section className='border-bottom py-1'>
                        <h5 className='mb-2'>{translate('ClearancesOnHold_Label')}</h5>
                    </section>

                    <Checkbox
                        onChange={onHoldHandler}
                        isChecked={
                            country === 'ca'
                                ? notificationsEdited?.HasNotificationsForClearanceOnHold_CA
                                : notificationsEdited?.HasNotificationsForClearanceOnHold
                        }
                        id={'HasNotificationsForClearanceOnHoldCheckBox'}
                        name={'HasNotificationsForClearanceOnHoldCheckBox'}
                        labelStyle={{
                            marginLeft: '0.5rem',
                            paddingTop: '0.4rem'
                        }}
                        inputClass=''
                        labelClass=''
                        divStyle={{
                            paddingLeft: '1.5rem',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        inputStyle={{
                            width: '1.25rem',
                            height: '1.32813rem',
                            cursor: 'pointer'
                        }}
                    >
                        <Tooltip label={translate('ShipmentOnHoldNotification_Label')}>
                            <span>{translate('SendNotificationsWhenShipmentsOnHold_Label')}</span>
                        </Tooltip>
                    </Checkbox>

                    <MilestonesSection
                        notifications={selfSubscription.milestones ? selfSubscription.milestones : null}
                        handleMilestonesChange={handleEditingNotifications}
                        noneMilestonesSelected={setNoneMilestonesSelected}
                        country={country}
                    />
                    <section className='border-bottom py-3 d-flex'>
                        <Button variant='tertiary-blue' className='mr-1 flex-fill' onClick={handleToggle}>
                            {translate('Cancel_Label')}
                        </Button>

                        {!user?.InternalUser ? (
                            <Button
                                variant='primary'
                                className='ml-1 flex-fill'
                                disabled={isApplyDisabled}
                                ref={subscribeClearancesEntryRef}
                                onClick={() => {
                                    if (user?.InternalUser) return;
                                    handleToggle();
                                    handleSubmit(false);
                                }}
                            >
                                {applyButtonContent}
                            </Button>
                        ) : (
                            <Tooltip label='Disabled for LII users.'>
                                <Button
                                    variant='primary'
                                    className='ml-1 flex-fill'
                                    disabled={isApplyDisabled}
                                    ref={subscribeClearancesEntryRef}
                                    onClick={() => {
                                        if (user?.InternalUser) return;
                                        handleToggle();
                                        handleSubmit(false);
                                    }}
                                >
                                    {applyButtonContent}
                                </Button>
                            </Tooltip>
                        )}
                    </section>
                    {selfSubscription.data?.SubscriptionIds?.length && selfSubscription.data.SubscriptionIds[0] ? (
                        <section className='pt-2 pb-0 d-flex justify-content-center'>
                            <Button
                                variant='danger-ghost'
                                className='btn-sm'
                                onClick={() => {
                                    handleToggle();
                                    setShowUnsubscribeModal(true);
                                }}
                            >
                                <FontAwesomeIcon icon={faBellSlash} className='mr-1' />
                                {translate('NotificationsUnsubscribe_Label')}
                            </Button>
                        </section>
                    ) : null}
                </>
            </DropdownMenuButton>
            {showUnsubscribeModal && (
                <Modal
                    header={translate(
                        'UnsubscribeSingleClearance_Label',
                        shipmentDetails?.EntryNumber ? shipmentDetails?.EntryNumber : null
                    )}
                    title={translate('NoNotifications_Label')}
                    toggleModal={() => {
                        handleToggle();
                        setShowUnsubscribeModal(false);
                    }}
                >
                    <>
                        <Button
                            variant='ghost'
                            className='btn-lg'
                            onClick={() => {
                                handleToggle();
                                setShowUnsubscribeModal(false);
                            }}
                        >
                            {translate('NotificationsGoBack_Label')}
                        </Button>
                        <Button
                            variant='danger'
                            className='btn-lg'
                            onClick={() => {
                                handleSubmit(true);
                            }}
                        >
                            {translate('NotificationsYesUnsubscribe_Label')}
                        </Button>
                    </>
                </Modal>
            )}
        </>
    );
};

export default NotificationsDropdown;
