import { useCallback, useEffect, useRef } from 'react';

export function useHighlightRows(newUsersAmount: number | undefined) {
    const highlightingAppliedRef = useRef(false);

    const applyHighlighting = useCallback(() => {
        if (highlightingAppliedRef.current) return;

        const rows = Array.from(document.querySelectorAll('.k-master-row'));

        if (!rows.length || !newUsersAmount) return;

        requestAnimationFrame(() => {
            rows.forEach((row, index) => {
                if (index < newUsersAmount) {
                    row.classList.add('new-row-highlight');
                }
            });
        });

        highlightingAppliedRef.current = true;
    }, [newUsersAmount]);

    useEffect(() => {
        const observer = new MutationObserver(() => {
            const gridRows = Array.from(document.querySelectorAll('.k-master-row'));
            if (gridRows.length > 0) {
                applyHighlighting();
                observer.disconnect();
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });

        return () => observer.disconnect();
    }, [applyHighlighting]);
}
