import { PortalRoutes } from '../../../../common/constants/constants-portal';
import Breadcrumb from '../../../../common/features/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../../common/features/Breadcrumb/BreadcrumbItem';
import { useSearchParams } from '../../../../common/hooks/useSearchParams';
import useTranslation from '../../../../common/hooks/useTranslation';
import { TEAM_SEARCH_PARAM_KEYS } from '../../common/constants/constants-team';
import { useTeamMemberDetailsQuery } from '../../redux/api/teamApi';

const EditTeamMemberBreadcrumb = () => {
    const [searchParams] = useSearchParams();
    const userId = parseInt(searchParams.get(TEAM_SEARCH_PARAM_KEYS.userId) || '');

    const { data, isLoading } = useTeamMemberDetailsQuery(userId.toString());

    const translate = useTranslation();

    return (
        <Breadcrumb>
            <BreadcrumbItem url={PortalRoutes.team}>{translate('Team_Label')}</BreadcrumbItem>
            <BreadcrumbItem>{isLoading ? translate('Loading_Label') : data?.Users?.[0].FullName}</BreadcrumbItem>
        </Breadcrumb>
    );
};

export default EditTeamMemberBreadcrumb;
