import { LineDetails, LineDetailsFormatted } from '../../../common/models/ShipmentLineDetails';

export const reorderLineDetails = (lineDetails: LineDetails[]): LineDetailsFormatted[] => {
    const currencyFormat = (value: number) => {
        return value.toLocaleString('en-us', { style: 'currency', currency: 'USD' });
    };

    return lineDetails.map((d, i) => ({
        RealLineNo: d.LineNo,
        LineNo: i,
        CountryExportCode: d.CountryExportCode,
        CountryExportDesc: d.CountryExportDesc,
        ManufacturerID: d.ManufacturerID,
        ManufacturerName: d.ManufacturerName,
        SupplierCode: d.SupplierCode,
        GrossWeight: d.GrossWeight.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        DestinationCode: d.DestinationCode,
        QtyComm: d.QtyComm.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        PartNo: d.PartNo,
        PartDesc: d.PartDesc,
        CountryOriginCode: d.CountryOriginCode,
        CountryOriginDesc: d.CountryOriginDesc,
        HTSNumber: d.TariffNo,
        HTSDesc: d.TariffDesc,
        SPIIndicatator: d.SPIIndicatator,
        DutyRate: (d.RateAdvalorem * 100).toFixed(2) + '%',
        ValueEnteredUS: currencyFormat(d.ValueEnteredUS),
        Currency: d.Currency,
        ExchangeRate: (d.ExchangeRate * 100).toFixed(2) + '%',
        ValueUS: currencyFormat(d.ValueUS),
        NonDutiableAmt: currencyFormat(d.NonDutiableAmt),
        MiscDiscount: currencyFormat(d.MiscDiscount),
        AdjToEnterValueAmt: currencyFormat(d.AdjToEnterValueAmt),
        CashDiscountAmt: currencyFormat(d.CashDiscountAmt),
        FreightAmt: currencyFormat(d.FreightAmt),
        OtherAdjAmt: currencyFormat(d.OtherAdjAmt),
        LineEnteredValue: currencyFormat(d.ValueEnteredUS),
        LineDuty: currencyFormat(d.DutyLineTot),
        LineMPF: currencyFormat(d.LineMPF),
        LineProMPF: currencyFormat(d.LineProMPF),
        LineHMF: currencyFormat(d.LineHMF),
        LineOtherFees: currencyFormat(d.LineOtherFees),
        LineDutyAndFees: currencyFormat(d.LineDutyAndFees),
        LineQty: d.LineQty.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        LineQty2: d.LineQty2.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        LineQty3: d.LineQty3.toLocaleString('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        LineUOM: d.LineUOM,
        LineUOM2: d.LineUOM2,
        LineUOM3: d.LineUOM3,
        ADADepRate: (d.ADADepRate * 100).toFixed(2) + '%',
        ADADutyAmt: currencyFormat(d.ADADutyAmt),
        CVDCaseNo: d.CVDCaseNo,
        CVDDepRate: (d.CVDDepRate * 100).toFixed(2) + '%',
        CVDDutyAmt: currencyFormat(d.CVDDutyAmt)
    }));
};
