import React, { Component } from 'react';
import Navigation from './Navigation';

class TopLeftContainer extends Component {
    render() {
        const phone = this.props.data[0].text;
        const navigationData = this.props.data.slice(1, this.props.data.length);

        return (
            <div>
                <div className='lii_phone'>{phone}</div>
                <Navigation
                    data={navigationData}
                    cssClassList='lii_phone-nav'
                    cssClassListItem='lii_menu-item lii_menu-item-type-post_type lii_menu-item-object-page'
                />
            </div>
        );
    }
}
export default TopLeftContainer;
