/*const dotenv = require('dotenv');
const axios = require('axios');

dotenv.config({ path: '../.env' });
*/

import axios from 'axios';
import { webAPIbaseURL } from '../constants/mlp-constants';

let signal = axios.CancelToken.source();

const cancelPortalRequests = () => {
    signal.cancel('Request is canceled!');
    signal = axios.CancelToken.source();
};

const instanceWithCancel = axios.create({
    baseURL:
        process.env.REACT_APP_MSW_ENABLED === 'TRUE'
            ? ''
            : process.env.REACT_APP_CENTRAL_DEV === 'TRUE'
            ? 'https://mlpv2-api-dev.mlpdev.liiaws.net/'
            : webAPIbaseURL
});

const instance = axios.create({
    baseURL:
        process.env.REACT_APP_MSW_ENABLED === 'TRUE'
            ? ''
            : process.env.REACT_APP_CENTRAL_DEV === 'TRUE'
            ? 'https://mlpv2-api-dev.mlpdev.liiaws.net/'
            : webAPIbaseURL
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        config.headers = {
            Authorization: `Bearer ${token}`
        };
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (process.env.REACT_APP_CENTRAL_DEV !== 'TRUE' && error.response.status === 401) {
            window.location.replace(error.response.data);
        }
        return Promise.reject(error);
    }
);

instanceWithCancel.interceptors.request.use(
    (config) => {
        config.cancelToken = signal.token;

        const token = localStorage.getItem('token');
        config.headers = {
            Authorization: `Bearer ${token}`
        };
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

instanceWithCancel.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Request cancelled
        if (error?.message === 'Request is canceled!') {
            return Promise.reject('Request is canceled');
        }

        if (process.env.REACT_APP_CENTRAL_DEV !== 'TRUE' && error.response.status === 401) {
            window.location.replace(error.response.data);
        }
        return Promise.reject(error);
    }
);

//methods - GET, POST and PUT
export const axiosGet = async (url, params) => {
    return new Promise((resolve, reject) => {
        instance
            .get(url, params)
            .then((res) => {
                const data = res.data;
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const axiosPost = async (url, data) => {
    const payload = JSON.parse(JSON.stringify(data));
    const response = await instance
        .post(url, payload)
        .then((response) => {
            return response;
        })

        .catch((error) => {
            return {
                error: error.message
            };
        });
    return response;
};

export const axiosPut = async (url, data) => {
    const payload = JSON.parse(JSON.stringify(data));
    const response = await instance
        .put(url, payload)
        .then((response) => {
            return response;
        })

        .catch((error) => {
            return {
                error: error.message
            };
        });
    return response;
};

export { cancelPortalRequests, instanceWithCancel };
export default instance;
