import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InputBase from '../Inputbase/Inputbase';

class CheckBox extends Component {
    static defaultProps = {
        checked: false,
        name: '',
        label: '',
        text: ''
    };

    static propTypes = {
        customAction: PropTypes.func,
        onChecked: PropTypes.func,
        checked: PropTypes.bool,
        name: PropTypes.string,
        label: PropTypes.string,
        text: PropTypes.string
    };

    updateState = (event) => {
        this.props.onChecked(event.target.checked, this.props.name);

        if (this.props.customAction) {
            this.props.customAction();
        }
    };

   componentDidUpdate(){
       if(this.props.validate){
           this.props.validate();
       }
   }

    render() {
        return (
            <InputBase {...this.props}>
                <div className='checkbox-container'>
                    <label>
                        <input
                            type='checkbox'
                            name={this.props.name}
                            checked={this.props.checked}
                            disabled={this.props.disabled}
                            onChange={this.updateState}
                            style={{ cursor: "pointer" }}
                        />
                        <span>{this.props.text}</span>
                    </label>
                </div>
            </InputBase>
        );
    }
}

export default CheckBox;
