import { Component } from 'react';
import { afterSignOutURL } from '../../constants/mlp-constants';

class SignOut extends Component {
    componentDidMount() {
        localStorage.removeItem('token');
        localStorage.removeItem('terms');
        window.location.replace(afterSignOutURL);
    }

    render() {
        return null;
    }
}

export default SignOut;
