import { docTypeCAHashMap, docTypeHashMap, OldMlpMap } from '../common/constants/constants-documents';
import { DestructuredArrayForDownload } from '../common/models/DownloadModal';
import { FileData, PartialGroupedDocuments } from '../common/models/ImagingModel';
export const isNumber = (value: any) => {
    return typeof value === 'number';
};
export const sortFunction = (value1: any, value2: any, order: any) => {
    if (isNumber(value1) && isNumber(value2)) {
        return order === 'DESC' ? value2 - value1 : value1 - value2;
    } else {
        // Treating undefined values as empty strings, because localeCompare function only sorts array of strings
        // const val1 = value1 ? value1 : '';
        // const val2 = value2 ? value2 : '';
        // localeCompare function is putting empty strings on the top of sorted array
        // return order === 'DESC' ? val2.localeCompare(val1) : val1.localeCompare(val2);

        // Treating empty strings as undefined values
        const val1: any = value1 && value1.toString().trim() !== '' ? value1 : undefined;
        const val2: any = value2 && value2.toString().trim() !== '' ? value2 : undefined;
        // Handling undefined values in sorted array, putting them always on the bottom of sorted array
        let exp: any = Number(!val1) - Number(!val2);
        return order === 'DESC' ? exp || -(val1 > val2) || +(val1 < val2) : exp || +(val1 > val2) || -(val1 < val2);
    }
};

export const sortBy = (array: any, property: any, order?: any) => {
    return array.sort((a: any, b: any) => sortFunction(a[property], b[property], order));
};

export const multiParamSort = (
    arrayDestructured: FileData[],
    sortColumnOne: string,
    sortColumnTwo: string,
    ascDescOne: boolean,
    ascDescTwo: boolean
): string[] => {
    let sortColumns = [sortColumnOne, sortColumnTwo];
    let sortOrders = [ascDescOne ? 'ASC' : 'DESC', ascDescTwo ? 'ASC' : 'DESC'];
    let sortOptions = sortBy(
        OldMlpMap.filter((x) => {
            return x.Level > 0;
        }),
        'Level'
    );
    sortOptions.forEach((y: any) => {
        if (sortColumns.indexOf(y.Attribute) < 0) {
            sortColumns.push(y.Attribute);
            sortOrders.push(y.Direction);
        }
    });
    const indexOfElement = sortColumns.indexOf('_metadataFormat');
    if (indexOfElement !== -1) {
        sortColumns.splice(indexOfElement, 1);
        sortOrders.splice(indexOfElement, 1);
    }

    arrayDestructured.sort((a, b) => {
        for (let i = 0; i < sortColumns.length; i++) {
            const key = sortColumns[i];
            const aValue = a[key] || '';
            const bValue = b[key] || '';

            if (aValue < bValue) {
                return sortOrders[i] === 'ASC' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortOrders[i] === 'ASC' ? 1 : -1;
            }
        }
        return 0;
    });

    return arrayDestructured.map((x) => x._fileS3Path ?? '');
};

export const mergeAndSort = (arrayDestructured: FileData[], groupParamOne: string, sortParamTwo: string, ascDescParamTwo: boolean) => {
    const objContainingWholeObjects: { [key: string]: FileData[] } = {};
    const objContainingS3FileNames: { [key: string]: string[] } = {};
    arrayDestructured.forEach((x: FileData) => {
        const key = x[groupParamOne] ?? 'key';
        const value = x;
        if (objContainingWholeObjects[key]) {
            objContainingWholeObjects[key].push(value);
        } else {
            objContainingWholeObjects[key] = [value];
        }
    });

    for (const groupedKey in objContainingWholeObjects) {
        objContainingWholeObjects[groupedKey].sort((a, b) => {
            const aEntry = a[sortParamTwo];
            const bEntry = b[sortParamTwo];
            if (sortParamTwo === '_imageDate') {
                if (typeof aEntry === 'string' && typeof bEntry === 'string') {
                    const dateA = new Date(
                        parseInt(aEntry.split('-')[2], 10),
                        parseInt(aEntry.split('-')[1], 10) - 1,
                        parseInt(aEntry.split('-')[0], 10)
                    );
                    const dateB = new Date(
                        parseInt(bEntry.split('-')[2], 10),
                        parseInt(bEntry.split('-')[1], 10) - 1,
                        parseInt(bEntry.split('-')[0], 10)
                    );
                    if (dateA.getTime() === dateB.getTime()) {
                        return ascDescParamTwo ? -1 : 1;
                    }
                    const timeComparison = ascDescParamTwo
                        ? dateA.getTime() > dateB.getTime()
                            ? 1
                            : -1
                        : dateB.getTime() > dateA.getTime()
                        ? 1
                        : -1;
                    return timeComparison;
                }
            }
            if (typeof aEntry === 'number' && typeof bEntry === 'number') {
                return !ascDescParamTwo ? bEntry - aEntry : aEntry - bEntry;
            }
            const aEntryStr = String(aEntry);
            const bEntryStr = String(bEntry);

            if (!ascDescParamTwo) {
                return bEntryStr.localeCompare(aEntryStr);
            } else {
                return aEntryStr.localeCompare(bEntryStr);
            }
        });
    }
    for (const groupedKey in objContainingWholeObjects) {
        objContainingWholeObjects[groupedKey].forEach((x: FileData) => {
            objContainingS3FileNames[groupedKey] = [...(objContainingS3FileNames[groupedKey] || []), x._fileS3Path ?? ''];
        });
    }
    return objContainingS3FileNames;
};

export const mergeNoSort = (arrayDestructured: FileData[]) => {
    const objContainingS3FileNames: { [key: string]: string[] } = {};

    arrayDestructured.forEach((x: FileData) => (objContainingS3FileNames[x?._fileS3Path ?? 'key'] = [x._fileS3Path ?? 'key']));

    return objContainingS3FileNames;
};

export const formS3Array = (
    isPDF: boolean,
    isNoGroup: boolean,
    {
        groupParamOne,
        sortParamTwoUniversal,
        ascDescParamOne,
        ascDescParamTwo,
        sortParamOne,
        ascDescParamThree
    }: {
        groupParamOne?: string;
        sortParamTwoUniversal?: string;
        ascDescParamOne?: boolean;
        ascDescParamTwo?: boolean;
        sortParamOne?: string;
        ascDescParamThree?: boolean;
    },
    { downloadModalView, selectedEntries, data, country, modalView, downloadModalData, compareModalView }: DestructuredArrayForDownload
) => {
    const ascDescParamOneBoolean = ascDescParamOne ?? false;
    const ascDescParamThreeBoolean = ascDescParamThree ?? false;
    const ascDescParamTwoBoolean = ascDescParamTwo ?? true;
    if (!isPDF && isNoGroup) {
        return mergeNoSort(
            getArrayDestructured({ compareModalView, downloadModalView, selectedEntries, data, country, modalView, downloadModalData })
        );
    } else if (!isPDF && !isNoGroup && groupParamOne && sortParamTwoUniversal)
        return mergeAndSort(
            getArrayDestructured({ compareModalView, downloadModalView, selectedEntries, data, country, modalView, downloadModalData }),
            groupParamOne,
            sortParamTwoUniversal,
            ascDescParamOneBoolean
        );
    else if (isPDF && sortParamOne && sortParamTwoUniversal)
        return multiParamSort(
            getArrayDestructured({ compareModalView, downloadModalView, selectedEntries, data, country, modalView, downloadModalData }),
            sortParamOne,
            sortParamTwoUniversal,
            ascDescParamTwoBoolean,

            ascDescParamThreeBoolean
        );
};

const dateFormatting = (imageDate: string) => {
    const datePortion = imageDate.split('T')[0];
    const formatedDateString = `${datePortion?.substring(datePortion.length - 2)}-${datePortion?.substring(5, 7)}-${datePortion?.substring(
        0,
        4
    )}`;

    return formatedDateString;
};

export const getArrayDestructured = (
    { compareModalView, downloadModalView, selectedEntries, data, country, modalView, downloadModalData }: DestructuredArrayForDownload,
    entryFromEllipsis?: string
) => {
    let arrayDestructured: FileData[] = [];

    if (compareModalView === 'documentDetailModal') {
        const entryNumber = selectedEntries[0] ?? entryFromEllipsis;
        downloadModalData.forEach((item) => {
            const selectedDataItem = data.filter((dataItem) => Object.keys(dataItem)[0] === entryNumber)[0];
            const returnData: FileData[] = selectedDataItem[entryNumber!].filter((fileData) => fileData._fileS3Path === item);

            const formattedReturnData = returnData.map((fileData) => {
                const metaDataFormat: string = fileData?._metadataFormat ?? '';

                return {
                    ...fileData,
                    _imageDate: dateFormatting(fileData._imageDate!),
                    _metadataFormat: country?.Code === 'us' ? docTypeHashMap[metaDataFormat] : docTypeCAHashMap[metaDataFormat]
                };
            });

            arrayDestructured.push(...formattedReturnData);
        });

        return arrayDestructured;
    }

    if (downloadModalView === 'documentsGrid' || compareModalView === 'documentsGrid' || compareModalView === 'ellipsis') {
        if (entryFromEllipsis && compareModalView === 'ellipsis') {
            selectedEntries = [entryFromEllipsis];
            compareModalView = 'documentsGrid';
        }

        for (let j = 0; j < selectedEntries.length; j++) {
            for (let i = 0; i < data.length; i++) {
                if (data[i][selectedEntries[j]]) {
                    const yetAnotherArrayToBeDestructured = data[i][selectedEntries[j]].map((x) => {
                        const metaDataFormat: string = x?._metadataFormat ?? '';

                        return {
                            ...x,
                            _imageDate: dateFormatting(x._imageDate!),
                            _metadataFormat: country?.Code === 'us' ? docTypeHashMap[metaDataFormat] : docTypeCAHashMap[metaDataFormat]
                        };
                    });

                    arrayDestructured.push(...yetAnotherArrayToBeDestructured);
                }
            }
        }
    } else if (downloadModalView === 'entryDocumentsGrid') {
        if (modalView) {
            for (const obj of data) {
                if (modalView in obj) {
                    const value = obj[modalView];
                    arrayDestructured = value.filter((item) => downloadModalData.includes(item._fileS3Path!));
                    break;
                }
            }
        }
    }

    return arrayDestructured;
};

export const prepareFilesForDownload = (selectedEntries: [] | string[], allEntries: PartialGroupedDocuments[] | null) => {
    if (selectedEntries.length && allEntries) {
        const objectAllEntries = Object.assign({}, ...allEntries);
        const result = Array.prototype
            .concat(
                ...selectedEntries.map((item: any) => {
                    return objectAllEntries[item];
                })
            )
            .map((item) => {
                return item?._fileS3Path;
            });
        return result;
    }
};

export const modelPreviewObject = ({
    downloadModalView,
    selectedEntries,
    data,
    country,
    modalView,
    downloadModalData,
    compareModalView
}: DestructuredArrayForDownload) => {
    let objectDestructured: { [key: string]: FileData[] } = {};
    getArrayDestructured({
        compareModalView,
        downloadModalView,
        selectedEntries,
        data,
        country,
        modalView,
        downloadModalData
    }).forEach((x: FileData) => (objectDestructured[x?._fileS3Path ?? 'key'] = [x]));
    return objectDestructured;
};

export const sortByDocumentsOnDates = (arrayOfFiles: FileData[], sortByParam: string) => {
    arrayOfFiles.sort((a: FileData, b: FileData) => {
        const aEntry = a[sortByParam];
        const bEntry = b[sortByParam];
        if (typeof aEntry !== 'string' || typeof bEntry !== 'string') {
            return 0;
        }
        if (sortByParam === '_imageDate') {
            const dateA = new Date(
                parseInt(aEntry.split('-')[0], 10),
                parseInt(aEntry.split('-')[1], 10) - 1,
                parseInt(aEntry.split('-')[2].split('T')[0], 10)
            );
            const dateB = new Date(
                parseInt(bEntry.split('-')[0], 10),
                parseInt(bEntry.split('-')[1], 10) - 1,
                parseInt(bEntry.split('-')[2].split('T')[0], 10)
            );
            return dateB.getTime() - dateA.getTime();
        } else {
            const dateA = new Date(
                parseInt(aEntry.split('-')[2], 10),
                parseInt(aEntry.split('-')[1], 10) - 1,
                parseInt(aEntry.split('-')[0], 10)
            );
            const dateB = new Date(
                parseInt(bEntry.split('-')[2], 10),
                parseInt(bEntry.split('-')[1], 10) - 1,
                parseInt(bEntry.split('-')[0], 10)
            );
            return dateB.getTime() - dateA.getTime();
        }
    });
};
