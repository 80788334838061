export const AFTER_GET_BATCH_JOB = 'AFTER_GET_BATCH_JOB';
export const UNDO_BATCH_JOB = 'UNDO_BATCH_JOB';
export const TOGGLE_ROLE = 'TOGGLE_ROLE';
export const UPDATE_ALL_STATUSES = 'UPDATE_ALL_STATUSES';
export const REMOVE_PARENT_USER = 'REMOVE_PARENT_USER';
export const REMOVE_CHILD_USER = 'REMOVE_CHILD_USER';
export const UPDATE_PARENT_STATUS = 'UPDATE_PARENT_STATUS';
export const UPDATE_CHILD_STATUS = 'UPDATE_CHILD_STATUS';
export const UPDATE_PARENT_FEATURE = 'UPDATE_PARENT_FEATURE';
export const UPDATE_CHILD_FEATURE = 'UPDATE_CHILD_FEATURE';
export const SET_CHILD_STATUS = 'SET_CHILD_STATUS';
export const AFTER_SUBMIT_BATCH_JOB_EDIT = 'AFTER_SUBMIT_BATCH_JOB_EDIT';
export const DOWNLOAD_MISMATCHES = 'DOWNLOAD_MISMATCHES';
export const CLEAR_STATE = 'CLEAR_STATE';
