import { useEffect, useState } from 'react';
import { useDebounce } from './useDebounce';

const useLocalStorageItem = (key: string, delay: number | undefined = 50) => {
    const [value, setValue] = useState<string | null>();

    const debounce = useDebounce(delay);

    // Get item from the "localStorage"
    useEffect(() => {
        debounce(() => {
            const localStorageItemValue = localStorage.getItem(key);
            setValue(localStorageItemValue);
        });
    }, [debounce, key]);

    const handleLocalStorageSetItem = (value: string) => {
        localStorage.setItem(key, value);
    };

    return { value, handleLocalStorageSetItem };
};

export default useLocalStorageItem;
