import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { format } from 'react-string-format';
import { batchStatusType } from '../../../constants/mlp-constants';
import * as actionCreators from '../../../store/actions/index';
import BatchJobCancelCompleteLobby from '../batchJobCancelCompleteLobby';
import BatchJobConfirmedSuccess from '../BatchJobConfirmedSuccess';
import BatchJobExpired from '../BatchJobExpired';
import BatchJobInProgress from '../BatchJobInProgress';
import SuccessConfirmationSummary from '../SuccessConfirmationSummary';
import UnexpectedProcessingError from '../UnexpectedProcessingError';
import ValidationErrorSummary from '../validationErrorSummary';
import BatchUploadCreateForm from './BatchUploadCreateForm';

class BatchUserCreateRouter extends Component {
    componentDidMount = () => {
        this.props.onInitTranslations('SystemAdmin.BatchUser.Index');
        setInterval(this.initiateStatusCheck, 3000);
        if (this.props.location.search) {
            const batchJobId = this.props.location.search.split('=')[1];
            this.props.onInitBatchJobCreate(batchJobId);
        }
    };

    componentWillUnmount() {
        this.props.onClearState();
    }

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
    }

    initiateStatusCheck = () => {
        const batchJob = this.props.model;
        if (batchJob && batchJob.BatchJobId && batchJob.BatchJobId > 0) {
            switch (batchJob.BatchStatusCode) {
                case batchStatusType.New:
                case batchStatusType.InProgress:
                case batchStatusType.Submitted:
                case batchStatusType.Cancelling:
                case batchStatusType.Completing:
                case batchStatusType.Approved:
                case batchStatusType.Rejected:
                    this.props.onGetBatchJobCreate(this.props.model.BatchJobId);
                    break;
                default:
            }
        }
    };

    showUploadPage = () => {
        this.props.onAfterGetBatchJobCreate(null);
    };

    batchJobConfirm = () => {
        this.props.onBatchJobConfirm(this.props.model.BatchJobId);
    };

    batchJobCancel = () => {
        this.props.onBatchJobCancel(this.props.model.BatchJobId);
    };

    uploadSuccess = (BatchJobId) => {
        this.props.onGetBatchJobCreate(BatchJobId);
    };

    render() {
        const batchJob = this.props.model;
        let componentByStatus = null;

        if (!batchJob || Object.keys(batchJob).length === 0) {
            componentByStatus = <BatchUploadCreateForm onGetBatchJobCreate={this.uploadSuccess} />;
        } else {
            switch (batchJob.BatchStatusCode) {
                case batchStatusType.New:
                case batchStatusType.InProgress:
                    componentByStatus = (
                        <BatchJobInProgress
                            title={format(this.props.translations.Phrases['FileUploaded_Label'], batchJob.OriginalFileName)}
                            message={this.props.translations.Phrases['FileProcessing_Label']}
                        />
                    );
                    break;
                case batchStatusType.FaultTerminated:
                    componentByStatus = (
                        <UnexpectedProcessingError
                            title={this.props.translations.Phrases['ProcessingError_Label']}
                            tryAgain={this.showUploadPage}
                            errorDetails={batchJob.Summary.ErrorDetails}
                            failedEmails={batchJob.Summary.FailedEmails}
                        />
                    );
                    break;
                case batchStatusType.ValidationTerminated:
                    componentByStatus = (
                        <ValidationErrorSummary
                            filename={batchJob.OriginalFileName}
                            invalidRowsCount={batchJob.Summary.InvalidRows}
                            totalRowCount={batchJob.Summary.TotalRows}
                            annotatedFileId={batchJob.AnnotatedFileId}
                        />
                    );
                    break;
                case batchStatusType.ExpirationTerminated:
                    componentByStatus = <BatchJobExpired title={this.props.translations.Phrases['BatchUserCreationExpired_Label']} />;
                    break;
                case batchStatusType.PendingApproval:
                    componentByStatus = (
                        <SuccessConfirmationSummary
                            onRequestCancel={this.batchJobCancel}
                            onRequestConfirm={this.batchJobConfirm}
                            batchJob={batchJob}
                        />
                    );
                    break;
                case batchStatusType.Cancelled:
                    componentByStatus = (
                        <BatchJobCancelCompleteLobby title={this.props.translations.Phrases['BatchUserCreateCancelled_Label']} />
                    );
                    break;
                case batchStatusType.Cancelling:
                    componentByStatus = (
                        <BatchJobCancelCompleteLobby title={this.props.translations.Phrases['BatchUserCreateCancelling_Label']} />
                    );
                    break;
                case batchStatusType.Completing:
                    componentByStatus = (
                        <BatchJobCancelCompleteLobby title={this.props.translations.Phrases['BatchUserCreateCompleting_Label']} />
                    );
                    break;
                case batchStatusType.Rejected:
                    componentByStatus = (
                        <BatchJobCancelCompleteLobby title={this.props.translations.Phrases['BatchUserCreateRejected_Label']} />
                    );
                    break;
                case batchStatusType.Approved:
                    componentByStatus = (
                        <BatchJobCancelCompleteLobby title={this.props.translations.Phrases['BatchUserCreateApproved_Label']} />
                    );
                    break;
                case batchStatusType.Completed:
                    const message =
                        batchJob.Summary.TotalRows > 0
                            ? format(this.props.translations.Phrases['SentMessagesToUsers_Label'], batchJob.Summary.TotalRows)
                            : '';
                    componentByStatus = (
                        <BatchJobConfirmedSuccess
                            title={this.props.translations.Phrases['BatchUserCreationCompleted_Label']}
                            message={message}
                        />
                    );
                    break;
                default:
                    componentByStatus = '';
            }
        }
        return (
            <div id='page'>
                <HelmetProvider>
                    <Helmet>
                        <title>{this.props.translations.Phrases['Page_Title']}</title>
                    </Helmet>
                </HelmetProvider>
                <div className='page-wrapper'>
                    <div className='page-top-row'>
                        <div className='page-header-section'>
                            <nav className='mlp-breadcrumb'>
                                <a href='/Dashboard' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                </a>
                                <i className='fa fa-caret-right'></i>
                                <a href='/SystemAdmin' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_2']}
                                </a>
                                <i className='fa fa-caret-right'></i>
                                <span> {this.props.translations.Phrases['Page_Header_Navigation_Level_3']}</span>
                            </nav>
                            <h1 className='bold-title'>{this.props.translations.Phrases['Page_Header']}</h1>
                        </div>
                    </div>
                    <div className='content-wrapper'>
                        <div className='row page-content'>
                            <div id='batch-user-form' className='w-100'>
                                <div className='row'>{componentByStatus}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        model: state.batchCreateUsers.model,
        showUploadPage: state.batchCreateUsers.showUploadPage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup)),
        onInitBatchJobCreate: (batchJobId) => dispatch(actionCreators.initBatchJobCreate(batchJobId)),
        onGetBatchJobCreate: (batchJobId) => dispatch(actionCreators.getBatchJobCreate(batchJobId)),
        onAfterGetBatchJobCreate: (model) => dispatch(actionCreators.afterGetBatchJobCreate(model)),
        onBatchJobConfirm: (batchJobId) => dispatch(actionCreators.confirmBatchJobCreate(batchJobId)),
        onBatchJobCancel: (batchJobId) => dispatch(actionCreators.cancelBatchJobCreate(batchJobId)),
        onClearState: () => dispatch(actionCreators.clearStateBatchJobCreate())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchUserCreateRouter);
