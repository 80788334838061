interface FilterBarProps {
    children: React.ReactNode;
}

const FilterBar: React.FC<FilterBarProps> = ({ children }) => {
    return (
        <nav className='filter-bar bg-white border-bottom'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col d-md-flex align-items-md-center px-0'>{children}</div>
                </div>
            </div>
        </nav>
    );
};

export default FilterBar;
