import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Menu from '../../features/Menu/Menu';
import * as actions from '../../store/actions/index';
import Footer from '../footer/Footer';
import Header from '../header/Header';

const RouteBasedLayout = withRouter((props) => <Layout {...props} />);

class Layout extends Component {
    componentDidMount() {
        this.props.onInitUserModel();
        import('../../assets/scss/main.scss');
    }

    render() {
        return (
            <div>
                <div id='page-body'>
                    <div id='overlay'>
                        <div className='spinner'></div>
                    </div>
                    <header id='lii-header'>
                        <Header></Header>
                    </header>
                    <div className='page-content'>
                        <article className='page'>
                            <main>{this.props.children}</main>
                        </article>
                    </div>
                    <footer id='lii-footer'>
                        <Footer />
                    </footer>
                </div>
                <nav id='page-menu'>
                    <Menu />
                </nav>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        hasCentralPermission: state.user.model.hasCentralPermission,
        hasDCPPermission: state.user.model.hasDCPPermission
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitUserModel: () => dispatch(actions.initUserModel())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteBasedLayout);
