export const SET_TEAM_DATA = 'SET_TEAM_MANAGEMENT_DATA';
export const SET_TEAM_MEMBER_STATUS = 'SET_TEAM_MEMBER_STATUS';
export const SET_TEAM_MEMBER_DETAILS_STATUS = 'SET_TEAM_MEMBER_DETAILS_STATUS';
export const SET_NEW_TEAM_MEMBER = 'SET_NEW_TEAM_MEMBER';
export const SET_TEAM_MEMBER_ERROR = 'SET_TEAM_MEMBER_ERROR';
export const SET_TEAM_MEMBER_DETAILS_ERROR = 'SET_TEAM_MEMBER_DETAILS_ERROR';
export const CANCEL_USER = 'CANCEL_USER';
export const CANCEL_USER_DETAILS = 'CANCEL_USER_DETAILS';
export const CHECK_FEATURE = 'CHECK_FEATURE';
export const CHANGE_USER_ROLE = 'CHANGE_USER_ROLE';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const REACTIVATE_TEAM_MEMBER = 'REACTIVATE_TEAM_MEMBER';
export const REMOVE_USER = 'REMOVE_USER';
export const SELECT_USER = 'SELECT_USER';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const SELECT_ALL_FEATURES = 'SELECT_ALL_FEATURES';
export const SET_VALIDATION_MESSAGES = 'SET_VALIDATION_MESSAGES';
export const CLEAR_STATE = 'CLEAR_STATE';
export const MODEL_CHANGE = 'MODEL_CHANGE';
export const SET_VALIDATION_MESSAGES_SUBMIT = 'SET_VALIDATION_MESSAGES_SUBMIT';
export const RESET_TEAM_MANAGEMENT_DETAILS_STATE = "RESET_TEAM_MANAGEMENT_DETAILS_STATE";
export const RESET_TEAM_MANAGEMENT_STATE = "RESET_TEAM_MANAGEMENT_STATE";