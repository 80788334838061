import * as actionTypes from './testformActionTypes'

export const setTest = (viewModel) => {
    return {
        type: actionTypes.SET_TEST,
        model: viewModel
    }
}

export const initTest = () => {
    return (dispatch) => {
        dispatch(setTest('String Value'))
    }
}

export const changeText = (value) => {
    return {
        type: actionTypes.CHANGE_TEXT,
        payload: { value: value }
    }
}

// export const changeText = (value) => {
//     console.log('changeText')

//     // return (dispatch) => {
//     //     dispatch(changeText('value'))
//     // }
// }
