//import $ from 'jquery'
import { Component } from 'react';
import { connect } from 'react-redux';
import CheckIcon from '../../common/Icons/CheckIcon';
import ExpandIcon from '../../common/Icons/ExpandIcon';
import MoreDetailsIcon from '../../common/Icons/MoreDetailsIcon';
import Modal from '../../common/Modal/Modal';
import ModalConfirm from '../../common/Modal/ModalConfirm';
import TextArea from '../../common/Textarea/Textarea';
import TextBox from '../../common/Textbox/Textbox';
import * as actionCreators from '../../store/actions/index';

class TestForm extends Component {
    state = { isModalOpen: false, isConfirmModalOpen: false, isExpanded: false, isChecked: false };

    componentDidMount() {
        this.props.onInitTestForm();
    }

    openConfirmModal = () => {
        this.setState({ isConfirmModalOpen: true });
    };

    closeConfirmModal = () => {
        this.setState({ isConfirmModalOpen: false });
    };

    openModal = () => {
        this.setState({ isModalOpen: true });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };

    expandIcon = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    };

    checkIcon = () => {
        this.setState({ isChecked: !this.state.isChecked });
    };

    getUserDetails = () => {
        return [
            { label: 'Company Name', value: 'Name' },
            { label: 'Roles', value: 'Role Name' },
            { label: 'Email', value: 'SomeEmail@Email.com' },
            { label: 'ExpirationDate', value: 'Yesterday' }
        ];
    };

    render() {
        let detailsForIcon = this.getUserDetails();

        return (
            <div className='page-wrapper'>
                <div className='test-form-height'>
                    <h3>TextArea</h3>
                </div>

                <TextArea
                    label='This is a label'
                    name='UploadEditList'
                    value={this.props.stringValue}
                    rows='6'
                    sanitize
                    onValueChanged={this.props.onChangeText}
                >
                    <p> {'Some Text Here'}</p>
                </TextArea>

                <div className='test-form-height'>
                    <h3>TextBox</h3>
                </div>

                <TextBox
                    Name='searchText'
                    onlyCustomAction={false}
                    fireOnChange={false}
                    disableBlurEvent={false}
                    label='Search Clients'
                    value={this.props.stringValue}
                    onValueChanged={this.props.onChangeText}
                />

                <div className='test-form-height'>
                    <h3>ModalConfirm</h3>
                </div>

                <button className='action-button' onClick={() => this.openConfirmModal()}>
                    Open Modal Confirm
                </button>
                <ModalConfirm
                    isOpen={this.state.isConfirmModalOpen}
                    onRequestClose={() => this.closeConfirmModal()}
                    OkButtonText='Ok'
                    CancelButtonText='Close'
                    title='Confirm Modal Title'
                ></ModalConfirm>

                <div className='test-form-height'>
                    <h3>Modal</h3>
                </div>

                <button className='action-button' onClick={() => this.openModal()}>
                    Open Modal
                </button>
                <Modal isOpen={this.state.isModalOpen} onRequestClose={() => this.closeModal()} title='Modal Title'>
                    Bla bla bla
                </Modal>

                <div className='test-form-height'>
                    <h3>Icons</h3>
                </div>
                <span onClick={() => this.expandIcon()}>
                    <ExpandIcon isExpanded={this.state.isExpanded} />
                </span>
                <span onClick={() => this.checkIcon()}>
                    <CheckIcon isChecked={this.state.isChecked} />
                </span>

                <MoreDetailsIcon items={detailsForIcon} />

                <div className='test-form-height'>
                    <h3>Search Box</h3>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        stringValue: state.test.model.TestString
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTestForm: () => dispatch(actionCreators.initTest()),
        onChangeText: (value) => dispatch(actionCreators.changeText(value))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestForm);
