import axios from 'axios';
import $ from 'jquery';
import toastr from 'toastr';
import axiosInstance from '../../../axios-mlp';
import { searchShiment as Constants } from '../../../constants/mlp-constants';
import { formatTranslated, getSearchTypesBySelectedServices } from '../../../utils/mlp-utils';
import * as actionTypes from './searchShipmentActionTypes';

const CancelToken = axios.CancelToken;
let cancelRequest = null;

export const setSearchShipment = (viewModel) => {
    return {
        type: actionTypes.SET_SEARCH_SHIPMENT,
        model: viewModel
    };
};

export const initSearchShipment = () => {
    return (dispatch) => {
        $('#overlay').show();
        dispatch(resetInitSearchShipmentFlag());
        axiosInstance
            .get('/SearchShipment/Index')
            .then((response) => {
                dispatch(setSearchShipment(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const resetInitSearchShipmentFlag = () => {
    return {
        type: actionTypes.RESET_INIT_SEARCH_SHIPMENT_FLAG
    };
};

export const initSearchShipmentModel = (translations) => {
    return {
        type: actionTypes.INIT_SEARCH_SHIPMENT_MODEL,
        translations: translations
    };
};

export const submitSearchShipment = (model) => {
    return (dispatch) => {
        dispatch(resetSubmitSearchShipmentFlag());
        if (getSearchTypesBySelectedServices(model).length > 0) {
            if (cancelRequest) {
                cancelRequest();
            }
            $('#grid-loader').show();
            let toPost = JSON.parse(JSON.stringify(model));
            toPost.Shipments = [];
            const config = {
                cancelToken: new CancelToken(function executor(c) {
                    cancelRequest = c;
                })
            };
            axiosInstance
                .post('/SearchShipment/SubmitShipmentSearch/', toPost, config)
                .then((response) => {
                    const data = response.data;
                    dispatch(afterSubmitSearchShipment(data.Shipments, data.TotalHits));
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        return;
                    } else if (error.response) {
                        if (error.response.status === 400 || error.response.status === 411) {
                            toastr.warning(error.response.data);
                        } else {
                            toastr.error(error.response.data);
                        }
                    }
                })
                .finally(() => {
                    $('#grid-loader').hide();
                    cancelRequest = null;
                });
        }
    };
};

export const resetSubmitSearchShipmentFlag = () => {
    return {
        type: actionTypes.RESET_SUBMIT_SEARCH_SHIPMENT_FLAG
    };
};

export const afterSubmitSearchShipment = (shipments, totalHits) => {
    return {
        type: actionTypes.SUBMIT_SEARCH_SHIPMENT,
        payload: {
            shipments: shipments,
            totalHits: totalHits
        }
    };
};

export const resetUpdateDataSourceFlag = () => {
    return {
        type: actionTypes.RESET_UPDATE_DATA_SOURCE_FLAG
    };
};

export const clearShipmentFilters = () => {
    return {
        type: actionTypes.CLEAR_SHIPMENT_FILTERS
    };
};

export const clearShipmentDateFilters = () => {
    return {
        type: actionTypes.CLEAR_SHIPMENT_DATE_FILTERS
    };
};

export const updateShipmentClientServices = (data) => {
    return {
        type: actionTypes.UPDATE_SHIPMENT_CLIENT_SERVICES,
        data: data
    };
};

export const clearShipmentClientServices = () => {
    return {
        type: actionTypes.CLEAR_SHIPMENT_CLIENT_SERVICES
    };
};

export const shipmentFilterOptionSelect = (newIndex, oldIndex, name) => {
    const filterIndex = name.split('.')[1];
    return {
        type: actionTypes.SHIPMENT_FILTER_OPTION_SELECT,
        payload: { newIndex: newIndex, oldIndex: oldIndex, filterIndex: filterIndex }
    };
};

export const shipmentFilterValueChange = (property, value, filterIndex) => {
    return {
        type: actionTypes.SHIPMENT_FILTER_VALUE_CHANGE,
        payload: { property: property, value: value, filterIndex: filterIndex }
    };
};

export const filterShipments = () => {
    return {
        type: actionTypes.FILTER_SHIPMENTS
    };
};

export const setSortParams = (sortParams) => {
    return {
        type: actionTypes.SET_SORT_PARAMS,
        payload: { sortParams: sortParams }
    };
};

export const setGridFilters = (gridFilter) => {
    return {
        type: actionTypes.SET_GRID_FILTERS,
        gridFilter: gridFilter
    };
};

export const downloadImages = (downloadModel, history) => {
    $('#overlay').show();
    axiosInstance
        .post('/Imaging/Download', downloadModel)
        .then((response) => {
            let imageUrl;
            if (typeof response.data === 'string') {
                imageUrl = JSON.parse(response.data).ImageUrl;
            } else {
                imageUrl = response.data.ImageUrl;
            }
            window.open(imageUrl);
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 400 || error.response.status === 411) {
                    toastr.warning(error.response.data);
                } else if (error.response.status !== 401 && error.response.status !== 403) {
                    toastr.error("No images are available or somehow user may not try to download from an entry without images")
                }
            }
        })
        .finally(() => {
            $('#overlay').hide();
        });
};

export const loadShipments = () => {
    return {
        type: actionTypes.LOAD_SHIPMENTS
    };
};

export const saveColumnVisibility = (columnName, isVisible, translations) => {
    axiosInstance
        .post(Constants.SHIPMENT_SEARCH_SAVE_COLUMNSELECTION_URL, null, { params: { columnName: columnName, columnVisibility: isVisible } })
        .then((response) => {})
        .catch((error) => {
            if (error.toString() === '') {
                toastr.error(formatTranslated('Column_Selection_Session_TimeOut_Label', translations.Phrases));
            } else {
                toastr.error(formatTranslated('Column_Selection_Save_Error_Label', translations.Phrases));
            }
        });
};

export const resetStateSearchShipment = () => {
    return {
        type: actionTypes.RESET_STATE_SEARCH_SHIPMENT
    };
};
