import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signIn(state, action) {
            const { token } = action.payload;
            return {
                ...state,
                token
            };
        },
        signOut() {
            return initialState;
        }
    }
});

export const { signIn, signOut } = authSlice.actions;

export default authSlice.reducer;
