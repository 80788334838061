import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SubmitButton from '../../../common/SubmitButton/SubmitButton';
import * as actionCreators from '../../../store/actions/index';
import RejectModal from '../RejectModal';
import UserInfo from '../UserInfo';
import ServiceCoordinatorControl from './ServiceCoordinatorControl';
import VASalesforceContactControl from './VASalesforceContactControl';

class VerifyAccessAdminForm extends Component {
    componentDidMount = () => {
        this.props.onInitTranslations('ClientAdmin.Signup.VerifyAccess');
        const requestId = this.props.location.search.substring(
            this.props.location.search.indexOf('=') + 1,
            this.props.location.search.length
        );
        this.props.onGetVerifyAccess(requestId);
    };

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
    }

    onChangeRejectMsg = (rejectMessage) => {
        this.props.onChangeRejectMsg(rejectMessage);
    };

    onSetValidationMessages = (validationMessages) => {
        this.props.onVerifyAccessValidatonMessages(validationMessages);
    };

    componentWillUnmount = () => {
        this.props.onResetStateVerifyAccess();
    };

    render() {
        const vm = this.props.model;
        const val = this.props.validationMessages;
        const tran = this.props.translations;
        const coordinators = vm.CoordinatorsByCountry.map((cc, index) => {
            return (
                <div className='col-sm-4' key={cc.CountryCode + 'Coordinator'}>
                    <ServiceCoordinatorControl coordinator={cc} countryIndex={index} valMsg={tran.Phrases[val.CoordinatorsByCountry]} />
                </div>
            );
        });

        return (
            <div id='page'>
                <HelmetProvider>
                    <Helmet>
                        <title>{this.props.translations.Phrases['Page_Title']}</title>
                    </Helmet>
                </HelmetProvider>
                <div className='page-wrapper'>
                    <div className='page-top-row'>
                        <div className='page-header-section'>
                            <nav className='mlp-breadcrumb'>
                                <Link to='/Dashboard' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                </Link>
                                <i className='fa fa-caret-right'></i>
                                <span>{this.props.translations.Phrases['Page_Header_Navigation_Level_2']}</span>
                            </nav>
                            <h1 className='bold-title'>{this.props.translations.Phrases['Page_Header']}</h1>
                        </div>
                    </div>
                    <div className='content-wrapper'>
                        <div className='row page-content'>
                            <div className='w-100' id='verify-access-form'>
                                <div>
                                    <UserInfo viewModel={vm} />

                                    <hr />

                                    <div className='row'>
                                        <div className='col-sm-4'>
                                            <VASalesforceContactControl viewModel={vm} validationMessages={val} />
                                        </div>
                                        {coordinators}
                                    </div>

                                    <hr />

                                    <div className='text-right'>
                                        <RejectModal
                                            model={vm}
                                            msg={vm.RejectionMessage}
                                            name='RejectionMessage'
                                            action='/ClientAdmin/RejectVerifyAccess'
                                            translations={this.props.translations}
                                            okButtonText={this.props.translations.Phrases['Reject_Button']}
                                            title={this.props.translations.Phrases['RejectionMessageTitle_Label']}
                                            label={this.props.translations.Phrases['RejectionMessage_Label']}
                                            btnText={this.props.translations.Phrases['Reject_Button']}
                                            onValueChanged={this.onChangeRejectMsg}
                                            onSuccessfulReject={{
                                                backToPath: '/Dashboard',
                                                backToLabel: 'BackToDashboard_Label',
                                                phraseGroup: 'ClientAdmin.Signup.SuccessfulRequestRevoke'
                                            }}
                                            postAction={this.onSetValidationMessages}
                                        />
                                        <SubmitButton
                                            label={this.props.translations.Phrases['Submit_Button']}
                                            model={this.props.model}
                                            postActionUrl='ClientAdmin/VerifyAccess'
                                            postAction={this.props.onVerifyAccessValidatonMessages}
                                            onSuccessfulSubmit={{
                                                backToPath: '/Dashboard',
                                                backToLabel: 'BackToDashboard_Label',
                                                phraseGroup: 'ClientAdmin.Signup.SuccessfulRequestGrant'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        model: state.verifyAccess.model,
        validationMessages: state.verifyAccess.ValidationMessages
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup)),
        onGetVerifyAccess: (requestId) => dispatch(actionCreators.getVerifyAccess(requestId)),
        onVerifyAccessValidatonMessages: (validationMessages) =>
            dispatch(actionCreators.setVerifyAccessValidationMessages(validationMessages)),
        onValidateVerifyAccess: (model) => dispatch(actionCreators.validateVerifyAccess(model)),
        onChangeRejectMsg: (rejectMsg) => dispatch(actionCreators.changeVerifyAccessRejectMsg(rejectMsg)),
        onResetStateVerifyAccess: () => dispatch(actionCreators.resetStateVerifyAccess())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccessAdminForm);
