import useTranslation from '../../../../common/hooks/useTranslation';
interface DownloadModalDropdownProps {
    handleFunction: (x: string) => void;
    menuItems: string[];
    selectedDropdownItem: string;
}
const DownloadModalDropdown: React.FC<DownloadModalDropdownProps> = ({ handleFunction, menuItems, selectedDropdownItem }) => {
    const translate = useTranslation();
    return (
        <div className='dropdown'>
            <button
                className='btn btn-tertiary dropdown-toggle btn-block mb-3'
                type='button'
                id='GroupBy'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
            >
                {selectedDropdownItem}
            </button>
            <div className='dropdown-menu' aria-labelledby='GroupBy'>
                {menuItems.map((x: string, index) => {
                    return (
                        <div
                            id={x}
                            key={index}
                            className={`dropdown-item d-flex justify-content-between align-items-stretch ${
                                selectedDropdownItem === x ? 'active' : ''
                            }`}
                            onClick={() => handleFunction(x)}
                        >
                            <div className='mr-3 d-flex align-items-center'>
                                {translate(
                                    `${x
                                        .split(' ')
                                        .map((z: string) => {
                                            return `${z[0].toUpperCase()}${z.substring(1)}`;
                                        })
                                        .join('')
                                        .replaceAll(' ', '')
                                        .replaceAll('.', '')}_Label`
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DownloadModalDropdown;
