import { FileData, GroupedDocuments } from '../../../documents/common/models/ImagingModel';

export const formModalProp = (fileDataArray: FileData[], selectedCountryCodeInitial: string) => {
    const entryOrTransactionNumbers = fileDataArray.map((fileData) =>
        selectedCountryCodeInitial === 'us' ? fileData._entryNumber ?? '' : fileData._transactionNumber ?? ''
    );

    const uniqueEntries = Array.from(new Set(entryOrTransactionNumbers));

    if (uniqueEntries.length === 0) {
        return [];
    }

    const groupedDocuments: GroupedDocuments[] = [];
    groupedDocuments.push({ [uniqueEntries[0]]: fileDataArray });

    return groupedDocuments;
};
