import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../../axios-portal';
import { API_TEAM_PATH } from '../../../../common/constants/constants-portal';
import { Contact, TeamsAdminByClientServices } from '../../common/models/Contact';
import { ADD_NEW_CONTACTS, GET_CONTACTS_DATA, GET_TEAMS_ADMINS, REMOVE_CONTACTS, UPDATE_CONTACT } from './actionTypes';

export const GetListOfAdmins = createAsyncThunk(GET_TEAMS_ADMINS, async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get<TeamsAdminByClientServices[]>(`${API_TEAM_PATH}/GetTeamsAdminsByUsersClientServices`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const GetContactsData = createAsyncThunk(GET_CONTACTS_DATA, async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get<Contact[]>(`${API_TEAM_PATH}/GetExternalContacts`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const AddNewContacts = createAsyncThunk(
    ADD_NEW_CONTACTS,
    async (
        payload: {
            newContacts: Contact[];
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post(
                `${API_TEAM_PATH}/NewExternalContacts`,
                payload.newContacts.map(
                    ({
                        IsFullNameValid,
                        IsEmailValid,
                        IsMobileNumberValid,
                        IsRoleLabelValid,
                        IsLanguageValid,
                        IsCarrierCodesValid,
                        ...newValue
                    }) => newValue
                )
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const RemoveContacts = createAsyncThunk(
    REMOVE_CONTACTS,
    async (
        payload: {
            contactIds: number[];
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post(`${API_TEAM_PATH}/RemoveExternalContacts`, payload.contactIds);
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const UpdateContact = createAsyncThunk(
    UPDATE_CONTACT,
    async (
        payload: {
            contactToBeUpdated: Contact;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post(
                `${API_TEAM_PATH}/UpdateExternalContact`,
                ...[payload.contactToBeUpdated].map(
                    ({
                        IsFullNameValid,
                        IsEmailValid,
                        IsMobileNumberValid,
                        IsRoleLabelValid,
                        IsLanguageValid,
                        IsCarrierCodesValid,
                        ...newValue
                    }) => newValue
                )
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);
