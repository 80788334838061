import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class Modal extends Component {
    static propTypes = {
        isOpen: PropTypes.bool,
        onRequestClose: PropTypes.func
    };

    static defaultProps = {
        isOpen: false
    };

    stopProp = (e) => {
        e.stopPropagation();
    };

    render() {
        return (
            <div
                className={this.props.isOpen ? 'modal modal-overlay d-block' : 'modal fade d-none'}
                onClick={this.props.onRequestClose}
                role='dialog'
                aria-hidden='false'
            >
                <div className='modal-dialog' onClick={(event) => this.stopProp(event)}>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h4 className='modal-title text-left'>{this.props.title}</h4>
                            <button type='button' className='close close-button' aria-label='Close' onClick={this.props.onRequestClose}>
                                <span>{this.props.translations.Phrases['Close_Label']}</span>
                            </button>
                        </div>
                        <div className='modal-body'>{this.props.children}</div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(Modal);
