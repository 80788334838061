import { API_TEAM_PATH } from '../../../../common/constants/constants-portal';
import { apiSlice } from '../../../../redux/api/apiSlice';
import type { TeamData, TeamMemberData } from '../../common/models';

export const teamApi = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        userTeam: build.query<TeamData, void>({
            query: () => ({
                url: `${API_TEAM_PATH}/IndexPortal`
            }),
            providesTags: ['UserTeam'],
            transformResponse: (response: TeamData) => ({ ...response, TeamMembers: response.TeamMembers?.reverse() })
        }),
        initNewTeamMember: build.query<TeamMemberData, void>({
            query: () => ({
                url: `${API_TEAM_PATH}/NewTeamMember`
            }),
            providesTags: ['NewTeamMember']
        }),
        teamMemberDetails: build.query<TeamMemberData, string>({
            query: (id) => ({
                url: `${API_TEAM_PATH}/TeamMemberDetails?teamMemberId=${id}`
            }),
            providesTags: (_result, _error, id) => [{ type: 'TeamMemberDetails', id }]
        }),
        clonedTeamMemberDetails: build.query<TeamMemberData, string>({
            query: (id) => ({
                url: `${API_TEAM_PATH}/CloneTeamMember?teamMemberId=${id}`
            }),
            providesTags: (_result, _error, id) => [{ type: 'ClonedTeamMemberDetails', id }]
        }),
        edmClientServices: build.query<Record<string, string>, (string | null | undefined)[]>({
            query: (body) => ({
                url: '/api/clientAdmin/GetEDMClientAccountStatuses',
                method: 'POST',
                body
            }),
            providesTags: (_result, _error, id) => [{ type: 'EdmClientServices', id: id ? id.join() : '' }]
        }),
        cancelTeamMember: build.mutation<string, string>({
            query: (userId) => ({
                url: `${API_TEAM_PATH}/CancelUser`,
                method: 'POST',
                params: { userId }
            }),
            invalidatesTags: (_result, _error, userId) => ['UserTeam', { type: 'TeamMemberDetails', id: userId }]
        }),
        reactivateTeamMember: build.mutation<string, string>({
            query: (userId) => ({
                url: `${API_TEAM_PATH}/ReactivateUser`,
                method: 'POST',
                params: { userId }
            }),
            invalidatesTags: (_result, _error, userId) => ['UserTeam', { type: 'TeamMemberDetails', id: userId }]
        }),
        createTeamMember: build.mutation<string, TeamMemberData>({
            query: (body) => ({
                url: `${API_TEAM_PATH}/NewTeamMember`,
                method: 'POST',
                body
            }),
            invalidatesTags: ['UserTeam']
        }),
        updateTeamMember: build.mutation<string, { data: TeamMemberData; editData: TeamMemberData }>({
            query: ({ data, editData }) => ({
                url: `${API_TEAM_PATH}/EditTeamMember`,
                method: 'POST',
                body: {
                    ...data,
                    ChangedProperties: Object.keys(data.Users?.[0] || {})?.reduce((acc: string[], item) => {
                        const teamMember = data.Users?.[0];
                        const teamMemberEdited = editData.Users?.[0];

                        const initialVal = teamMember?.[item as keyof typeof teamMember];
                        const updatedVal = teamMemberEdited?.[item as keyof typeof teamMemberEdited];

                        if (updatedVal && initialVal !== updatedVal && item !== 'Roles' && item !== 'UserRole') {
                            acc.push(`Users.0.${item}`);
                        }

                        return acc;
                    }, []),
                    Users: [
                        {
                            ...data.Users?.[0],
                            ...editData.Users?.[0]
                        }
                    ]
                }
            }),
            invalidatesTags: (_result, _error, { data }) => [
                'UserTeam',
                { type: 'TeamMemberDetails', id: data.Users?.[0].UserId!.toString() }
            ]
        }),
        validateNewTeamMember: build.query<boolean, { Email: string }>({
            query: (args) => ({
                url: `${API_TEAM_PATH}/NewTeamMemberValidate`,
                method: 'POST',
                body: {
                    ChangedProperties: Object.keys(args).map((arg) => 'Users.0.' + arg),
                    Users: [
                        {
                            Email: args.Email
                        }
                    ]
                }
            }),
            transformResponse: (response: string) => {
                return response.includes('EmailExists_ValidationMsg');
            }
        })
    })
});

export const {
    useUserTeamQuery,
    useTeamMemberDetailsQuery,
    useClonedTeamMemberDetailsQuery,
    useInitNewTeamMemberQuery,
    useEdmClientServicesQuery,
    useCancelTeamMemberMutation,
    useReactivateTeamMemberMutation,
    useCreateTeamMemberMutation,
    useUpdateTeamMemberMutation,
    useLazyValidateNewTeamMemberQuery
} = teamApi;
