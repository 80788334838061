import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../../common/Modal/Modal';

class TeamMembersModal extends Component {
    static defaultProps = {
        isOpen: false
    };

    static propTypes = {
        isOpen: PropTypes.bool,
        onTeamMembersModalClose: PropTypes.func
    };

    closeModal = () => {
        this.props.onTeamMembersModalClose();
    };

    render() {
        let teamDisplay;
        const team = this.props.teamMembers;
        if (this.props.teamMembers.length !== 0) {
            const members = this.props.teamMembers.Members.map((member, index) => {
                return (
                    <tbody key={member.Name + member.Email + index}>
                        <tr>
                            <td>{member.Name}</td>
                            <td>{member.RoleName}</td>
                            <td>{member.Email}</td>
                        </tr>
                    </tbody>
                );
            });
            teamDisplay = (
                <div key={team.Name}>
                    <h4>{team.Name}</h4>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Role</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        {members}
                    </table>
                </div>
            );
        }

        return (
            <span>
                <Modal
                    isOpen={this.props.isOpen}
                    onRequestClose={this.closeModal}
                    title={this.props.translations.Phrases['TeamMembers_Label']}
                >
                    <div>{teamDisplay}</div>
                </Modal>
            </span>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(TeamMembersModal);
