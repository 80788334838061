import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import toastr from 'toastr';
import axios from '../../axios-mlp';
import { setValueByPath } from '../../utils/mlp-utils';

class SubmitButton extends Component {
    static defaultProps = {
        label: 'Submit'
    };

    static propTypes = {
        label: PropTypes.string,
        customAction: PropTypes.func,
        tokenAction: PropTypes.func,
        postAction: PropTypes.func,
        postActionUrl: PropTypes.string,
        model: PropTypes.object,
        onSuccessfulSubmit: PropTypes.shape({ backToPath: PropTypes.string, backToLabel: PropTypes.string, phraseGroup: PropTypes.string })
    };

    handleClick = (e) => {
        if (this.props.customAction) {
            this.props.customAction();
        }

        $('#overlay').show();
        axios
            .post(this.props.postActionUrl, this.props.model)
            .then((response) => {
                if (response.data === 'SuccessfulSubmit') {
                    // route to succes submit commponent
                    this.props.history.push({
                        pathname: '/SuccessfullSubmit',
                        state: {
                            backToPath: this.props.onSuccessfulSubmit.backToPath,
                            backToLabel: this.props.onSuccessfulSubmit.backToLabel,
                            phraseGroup: this.props.onSuccessfulSubmit.phraseGroup
                        }
                    });
                } else {
                    //get validation messages
                    let validationMessages = {};
                    const json = JSON.parse(response.data);
                    for (let i in json) {
                        setValueByPath(validationMessages, i, json[i]);
                    }
                    this.props.postAction(validationMessages);
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 400) {
                        toastr.warning(error.response.data.Message);
                    } else {
                        toastr.error(error.response.data.Message);
                    }
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };

    render() {
        const isDisabled = this.props.disabled; 

        return (
            <button 
                type='button' 
                className={`button small blue ${isDisabled ? "disabled" : ""}`} 
                onClick={this.handleClick} 
                disabled={isDisabled}
            >
                {this.props.label}
            </button>
        );
    }
}

export default withRouter(SubmitButton);
