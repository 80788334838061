import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Component } from 'react';
import InputBase from '../Inputbase/Inputbase';
import Modal from '../Modal/Modal';
import Calendar from './Calendar';

class DatePickerModal extends Component {
    static propTypes = {
        name: PropTypes.string,
        date: PropTypes.string,
        linkText: PropTypes.string,
        dateLabel: PropTypes.string,
        onDateChanged: PropTypes.func,
        customAction: PropTypes.func
    };

    state = { isOpen: false };

    closeModal = (type) => {
        this.setState({ isOpen: false });

        if (this.props.customAction && type === 'select') {
            this.props.customAction();
        }
    };

    openModal = () => {
        this.setState({ isOpen: true });
    };

    render() {
        let linkText = this.props.linkText;
        if (this.props.date) {
            let date = dayjs(this.props.date);
            if (this.props.dateLabel) {
                linkText = this.props.dateLabel + ': ' + date.toDate().toLocaleDateString();
            } else {
                linkText = date.toDate().toLocaleDateString();
            }
        }

        if (this.props.readonly || this.props.disabled) {
            return (
                <div className='readonly-label-control-container'>
                    <span>{linkText}</span>
                </div>
            );
        }

        return (
            <span className='date-picker'>
                <Modal isOpen={this.state.isOpen} onRequestClose={() => this.closeModal('close')} title={linkText}>
                    <div className='text-center'>
                        <Calendar
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                            date={this.props.date}
                            name={this.props.name}
                            customFormat={this.props.customFormat}
                            onDateChanged={this.props.onDateChanged}
                            customAction={() => this.closeModal('select')}
                        />
                    </div>
                </Modal>
                <InputBase {...this.props}>
                    <span className='btn btn-link' onClick={this.openModal}>
                        <i className='fa fa-calendar fa-lg'></i> {linkText}
                    </span>
                </InputBase>
            </span>
        );
    }
}

export default DatePickerModal;
