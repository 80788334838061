import { PropDescription } from './types';

export enum FooterAlign {
    Left = 'justify-content-start',
    Center = 'justify-content-center',
    Right = 'justify-content-end',
    Spaced = 'justify-content-between'
}
export interface FooterProps {
    children?: React.ReactNode;
    align?: FooterAlign;
    className?: string;
}

export const Props: PropDescription[] = [
    {
        name: 'children',
        description: 'Add content to the footer of the card',
        type: 'ReactNode',
        required: false
    },
    {
        name: 'align',
        description: 'Button alignment on footer. Left | Center | Right | Spaced',
        type: 'FooterAlign',
        defaultValue: 'Left',
        required: false
    },
    {
        name: 'className',
        description: 'Add classNames to the footer',
        type: 'string',
        required: false
    }
];
