export const dateConvert = (date: string) => {
    if (!date) return;

    const year = date.substring(0, 4);
    const month = date.substring(4, 6);
    const day = date.substring(6, 8);

    if (date.length === 8) {
        return `${month}/${day}/${year}`;
    } else if (date.length === 14) {
        let hour = parseInt(date.substring(8, 10));
        const period = hour < 12 ? 'AM' : 'PM';
        hour = hour > 12 ? hour - 12 : hour.toString() === '0' ? hour + 12 : hour;
        let hourStr = hour < 10 ? `0${hour}` : hour;
        let min = parseInt(date.substring(10, 12));
        let minStr = min < 10 ? `0${min}` : min;
        let sec = parseInt(date.substring(12, 14));
        let secStr = sec < 10 ? `0${sec}` : sec;

        return `${month}/${day}/${year}, ${hourStr}:${minStr}:${secStr} ${period}`;
    }
};

export const numberWithCommas = (number: number) => {
    return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numericCADValuesFormatting = (value: number, trailingZeros: string) => {
    let cellContent = value.toString().includes('.') ? numberWithCommas(value) : `${numberWithCommas(value)?.toString()}.${trailingZeros}`;

    return cellContent;
};
