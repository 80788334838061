import produce from 'immer';
import * as actionTypes from '../actions/systemAdmin/batchCreateUsersActionTypes';

const initialState = {
    model: null,
    showUploadPage: false
};

const afterGetBatchJobCreate = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.payload.model;
    });
};

const clearStateBatchJobCreate = (state) => {
    return produce(state, (draft) => {
        draft.model = null;
        draft.showUploadPage = false;
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AFTER_GET_BATCH_JOB_CREATE:
            return afterGetBatchJobCreate(state, action);
        case actionTypes.CLEAR_STATE:
            return clearStateBatchJobCreate(state);
        default:
            return state;
    }
};

export default reducer;
