import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';
import { useCallback, useMemo } from 'react';
import { AgencyList } from '../../../../../common/constants/constants-portal';
import Spinner from '../../../../../common/features/Spinner';
import { fetchHtmlFile, getCountryFromClientNumber } from '../../../../../common/functions/utils';
import { useFetch } from '../../../../../common/hooks/useFetch';
import useTranslation from '../../../../../common/hooks/useTranslation';
import type { ClearanceAlertType } from '../../../common/features/ClearanceAlertCard/ClearanceAlertCard';
import ClearanceAlertCard from '../../../common/features/ClearanceAlertCard/ClearanceAlertCard';
import type { ShipmentDetailsData } from '../../../common/models/ResponseData';
import type { PGA, PGAEvent } from '../../../common/models/ShipmentDetails';
import { useClearancesSelector } from '../../../redux/hooks';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
    if (!node.hasAttribute('href')) return;

    const href = node.getAttribute('href') || '';

    if (/^https?:\/\//i.test(href)) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener noreferrer');
    }
});

type EventType = 'customs' | 'pga' | 'broker';
type EventWithType = PGAEvent & { Type: EventType; Header: string | null };

const sortByNewestDate = (array: EventWithType[]) => {
    return array.sort((a, b) => {
        const dateA = a.Date ? Date.parse(a.Date) : 0;
        const dateB = b.Date ? Date.parse(b.Date) : 0;
        return dateB - dateA;
    });
};

const getLatestPGAEventsByName = (pgaEvents: PGA[] | null | undefined, eventPattern: RegExp) => {
    return (
        pgaEvents?.reduce<EventWithType[]>((result, pga) => {
            const latestEvent = pga.Events?.[0];
            const eventKey = latestEvent?.Key;

            if (eventKey && eventPattern.test(eventKey)) {
                result.push({ ...latestEvent, Type: 'pga', Header: pga.Header });
            }

            return result;
        }, []) || []
    );
};

const mapBrokerHolds = (brokerHolds: ShipmentDetailsData['BHs'] | null | undefined) => {
    return (
        brokerHolds?.map<EventWithType>((item) => ({
            Key: item.EventTypeKey,
            Date: item.HeldOn,
            AgencyKey: null,
            MessageFile: item.MessageFile,
            MessageTitle: item.MessageTitle,
            Type: 'broker',
            Header: null
        })) || []
    );
};

export default function ClearanceAlerts() {
    const { shipmentDetails } = useClearancesSelector((state) => state.shipmentDetails);
    const { languageSelected } = useClearancesSelector((state) => state.language);
    const languageCode = languageSelected?.code;

    const translate = useTranslation();

    const events = useMemo(() => {
        const pgaEventPattern = /(?:MayProceed|Rejected|Hold|Review)/i;
        const pgaEvents = getLatestPGAEventsByName(shipmentDetails?.PGAs, pgaEventPattern);

        const brokerHolds = mapBrokerHolds(shipmentDetails?.BHs);

        const events = [...pgaEvents, ...brokerHolds];
        const sortedEvents = sortByNewestDate(events);

        return sortedEvents;
    }, [shipmentDetails?.BHs, shipmentDetails?.PGAs]);

    const fetchFiles = useCallback(() => {
        return Promise.all(
            events.map((item) => {
                if (item.MessageFile) {
                    return fetchHtmlFile(`/data/${item.Type}-holds/${languageCode}/${item.MessageFile}.html`);
                }
                return Promise.resolve('');
            })
        );
    }, [languageCode, events]);

    const { data, isFetching, error } = useFetch(fetchFiles);

    const customsInfoHold =
        shipmentDetails?.ClientCaptionNumber && getCountryFromClientNumber(shipmentDetails?.ClientCaptionNumber) === 'us'
            ? AgencyList['Customs_US']
            : AgencyList['Customs_CA'];

    if (isFetching) {
        return (
            <div className='d-flex justify-content-center align-items-center'>
                <Spinner />
            </div>
        );
    }

    if (error) {
        console.error(error);
        return null;
    }

    return (
        <>
            {data &&
                events.map((event, index) => {
                    const isCustoms = event.AgencyKey === 'Customs';
                    const isBrokerHold = event.Type === 'broker';
                    const isPgaHold = /Hold/i.test(event.Key || '');
                    const isPgaRejected = /Rejected/i.test(event.Key || '');
                    const isPgaUnderReview = /Review/i.test(event.Key || '');
                    const isPgaMayProceed = /MayProceed/i.test(event.Key || '');

                    let alertType: ClearanceAlertType = 'danger';

                    if (isBrokerHold || isPgaHold || isPgaRejected) {
                        alertType = 'danger';
                    } else if (isPgaUnderReview) {
                        alertType = 'warning';
                    } else if (isPgaMayProceed) {
                        alertType = 'success';
                    }

                    const alertIcon = alertType === 'danger' ? faExclamationCircle : faInfoCircle;

                    const alertTitle: Record<EventType, JSX.Element> = {
                        customs: (
                            <>
                                <p className='mb-1'>
                                    <strong>
                                        {translate(
                                            alertType === 'danger' ? 'AlertsOnHold_Label' : 'AlertsInProgress_Label',
                                            '',
                                            translate(customsInfoHold.label)
                                        )}
                                    </strong>
                                </p>
                                <p className='mb-1'>{translate('HoldAlert_Label')}</p>
                            </>
                        ),
                        pga: (
                            <>
                                <p className='mb-1'>
                                    <strong>
                                        {isPgaHold && translate('AlertsOnHold_Label', '', translate(AgencyList[event.AgencyKey!]?.label))}
                                        {isPgaRejected &&
                                            translate('EntryReviewedRejected_Label', translate(AgencyList[event.AgencyKey!]?.label))}
                                        {isPgaUnderReview &&
                                            translate('EntryUnderReview_Label', translate(AgencyList[event.AgencyKey!]?.label))}
                                        {isPgaMayProceed &&
                                            translate('EntryReviewedAccepted_Label', translate(AgencyList[event.AgencyKey!]?.label))}
                                    </strong>
                                </p>
                                <p className='text-uppercase mb-1'>
                                    <strong>{translate(event.MessageTitle, event.Header)}</strong>
                                </p>
                                <p>{translate('ExpandAdditionalDetails_Label')}</p>
                            </>
                        ),
                        broker: (
                            <>
                                <p className='mb-1'>
                                    <strong className='mr-1'>{translate('ShipmentOnHold_Label')}</strong>
                                    {translate('EntryOnHoldByLivingston_Label')}
                                </p>
                                <p className='text-uppercase mb-1'>
                                    <strong>{translate(event.MessageTitle)}</strong>
                                </p>
                                <p>{translate('ExpandAdditionalDetails_Label')}</p>
                            </>
                        )
                    };

                    // Customs
                    if (isCustoms) {
                        return (
                            <ClearanceAlertCard
                                key={index}
                                type={alertType}
                                icon={alertIcon}
                                title={alertTitle.customs}
                                date={event.Date}
                                linkHref={AgencyList[event.AgencyKey!].label}
                                linkLabel={translate(AgencyList[event.AgencyKey!].viewInfoLabel)}
                                cardId={AgencyList[event.AgencyKey!].label}
                            />
                        );
                    }

                    // PGA events and Broker holds
                    return (
                        <ClearanceAlertCard
                            key={index}
                            isExpandable
                            expandableButtonLabel={translate(AgencyList[event.AgencyKey!]?.viewInfoLabel || 'ViewLivingstonHoldInfo_Label')}
                            type={alertType}
                            icon={alertIcon}
                            title={alertTitle[event.Type]}
                            date={event.Date}
                        >
                            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data[index]) }} />
                        </ClearanceAlertCard>
                    );
                })}
        </>
    );
}
