import { Component } from 'react';
import { connect } from 'react-redux';
import { imageDetailsPath, Side, storageModelName } from '../../../constants/mlp-constants';
import * as actions from '../../../store/actions/index';
import { removeModelFromStorage } from '../../../utils/modelStorage';
import PreviewDocument from './PreviewDocument';

class DocumentCompareView extends Component {
    detailPages = [
        imageDetailsPath.VIEWIMAGE_SEARCHSHIPMENTDETAIL,
        imageDetailsPath.DOCUMENTCOMPARE_VIEW,
        imageDetailsPath.DOCUMENT_VIEW,
        imageDetailsPath.DOWNLOAD_AND_PRINT
    ];

    componentDidMount() {
        const entryIndexLeft = this.getSelectedEntryIndex(this.props.entriesToCompareLeft);
        const entryIndexRight = this.getSelectedEntryIndex(this.props.entriesToCompareRight);
        const selectedDocumentLeft = this.props.entriesToCompareLeft[entryIndexLeft].documents.find((x) => x.isSelected);
        const selectedDocumentRight = this.props.entriesToCompareRight[entryIndexRight].documents.find((x) => x.isSelected);
        if (!selectedDocumentLeft) {
            this.props.selectDocument(0, -1, entryIndexLeft, Side.LEFT);
        }
        if (!selectedDocumentRight) {
            this.props.selectDocument(0, -1, entryIndexRight, Side.RIGHT);
        }
    }

    componentWillUnmount() {
        if (this.props.history.location.pathname !== '/Imaging' && !this.detailPages.includes(this.props.history.location.pathname)) {
            removeModelFromStorage(storageModelName.VIEWIMAGE);
        }
    }

    getSelectedEntryIndex = (entries) => {
        const selectedEntryIndex = entries.findIndex((x) => x.isSelected);
        return selectedEntryIndex;
    };

    selectDocumentLeft = (newIndex, oldIndex, event, name) => {
        const entryIndex = this.props.entriesToCompareLeft.findIndex((x) => x.groupId.toString() === name);
        this.props.selectDocument(newIndex, oldIndex, entryIndex, Side.LEFT);
    };

    selectDocumentRight = (newIndex, oldIndex, event, name) => {
        const entryIndex = this.props.entriesToCompareRight.findIndex((x) => x.groupId.toString() === name);
        this.props.selectDocument(newIndex, oldIndex, entryIndex, Side.RIGHT);
    };

    selectEntryLeft = (newIndex, oldIndex, event, name) => {
        this.props.selectEntry(newIndex, oldIndex, Side.LEFT);
    };

    selectEntryRight = (newIndex, oldIndex, event, name) => {
        this.props.selectEntry(newIndex, oldIndex, Side.RIGHT);
    };

    render() {
        if (
            (this.props.entriesToCompareLeft && this.props.entriesToCompareLeft.length === 0) ||
            (this.props.entriesToCompareRight && this.props.entriesToCompareRight.length === 0)
        ) {
            return <div></div>;
        }
        return (
            <div id='imaging'>
                <div className='entry-details-container'>
                    <div className='row'>
                        <div className='col-sm-6 preview-document'>
                            <PreviewDocument
                                {...this.props}
                                getPublicDocUrl='GetPresignedUrl'
                                includeEntrySelection={true}
                                entryData={this.props.entriesToCompareLeft}
                                entrySelectAction={this.selectEntryLeft}
                                selectAction={this.selectDocumentLeft}
                            />
                        </div>
                        <div className='col-sm-6 preview-document'>
                            <PreviewDocument
                                {...this.props}
                                getPublicDocUrl='GetPresignedUrl'
                                includeEntrySelection={true}
                                entryData={this.props.entriesToCompareRight}
                                entrySelectAction={this.selectEntryRight}
                                selectAction={this.selectDocumentRight}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        entriesToCompareLeft: state.imagingDetails.model.EntriesToCompareLeft,
        entriesToCompareRight: state.imagingDetails.model.EntriesToCompareRight
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectEntry: (newIndex, oldIndex, side) => dispatch(actions.selectEntry(newIndex, oldIndex, side)),
        selectDocument: (newIndex, oldIndex, entryIndex, side) => dispatch(actions.selectDocument(newIndex, oldIndex, entryIndex, side))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCompareView);
