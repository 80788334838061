import { faCalculator, faChalkboardTeacher, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { customsBrokerageCalculatorUrl, tradeNewsUrl, webinarsUrl } from '../../../constants/mlp-constants';
import { shipmentDataSourceConstants } from '../../common/constants/constants-portal';
import ClientChooser from '../../common/features/ClientChooser/ClientChooser';
import useTranslation from '../../common/hooks/useTranslation';
import { useLazyGetShipmentDataSourceQuery, useSaveShipmentDataSourceMutation } from '../clearances/redux/api/clearancesApi';
import ClearancesRealTimeDataCard from './common/components/cards/ClearancesRealTimeDataCard';
import SpringboardCard from './common/components/cards/SpringboardCard';
import SupportCard from './common/components/cards/SupportCard';
import Col from './common/components/main/Col';
import Container from './common/components/main/Container';
import HeaderWithDropdown from './common/components/main/HeaderWithDropdown';
import Row from './common/components/main/Row';
import { useClearanceListQuery } from './redux/api/dashboardApi';
import { useDashboardSelector } from './redux/hooks';
import ClearancesSnapshot from './screens/ClearancesSnapshot';

const OverviewDashboard = () => {
    const { user } = useDashboardSelector((state) => state.hydration);
    const { initialClientSelection } = useDashboardSelector((state) => state.clientSelection);
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);

    const translate = useTranslation();

    const { data, isFetching } = useClearanceListQuery(
        {
            Status: null,
            Country: country?.Code.toUpperCase(),
            ClientCaptionNumbers: user?.ClientCaptionNumbers,
            PageSize: user?.PortalClearancesSnapshotSummaryLimit ?? 15,
            OrderBy: null,
            StartRow: 0,
            DateRange: { dateRangeSelected: 'LAST_30', customDateRange: [] },
            AdvancedSearchFilters: { Tags: [] },
            ClearancesCreatedOnDate: ''
        },
        { skip: !user || !country, refetchOnMountOrArgChange: true }
    );
    const isLoading = !user || !country || isFetching;
    const [saveShipmentDataSource] = useSaveShipmentDataSourceMutation();
    const [getShipmentDataSource, { data: shipmentDataSource }] = useLazyGetShipmentDataSourceQuery();
    const url = shipmentDataSource === shipmentDataSourceConstants.us ? '/Clearances' : undefined;

    useEffect(() => {
        (async function () {
            await saveShipmentDataSource({ PreferenceValue: shipmentDataSourceConstants.us, Country: 'ca' });

            getShipmentDataSource('ca');
        })();
    }, [getShipmentDataSource, saveShipmentDataSource]);

    return (
        <div className='flex-fill overflow-auto pb-5'>
            <Container fluid>
                <Row>
                    <Col>
                        <HeaderWithDropdown>
                            <ClientChooser />
                        </HeaderWithDropdown>
                    </Col>
                </Row>
                <Row>
                    <Col className='col-12 col-md-4 mb-4'>
                        <ClearancesRealTimeDataCard
                            title={translate('Clearances_Label')}
                            status='ON_HOLD'
                            statusLabel={translate('ViewLast30OnHold_Label')}
                            count={data?.totalOnHold}
                            dangerText
                            isLoading={isLoading}
                            linkLabel={translate('ViewOnHold_Label')}
                            url={url}
                        />
                    </Col>
                    <Col className='col-12 col-md-4 mb-4'>
                        <ClearancesRealTimeDataCard
                            title={translate('Clearances_Label')}
                            status='COMPLETED'
                            statusLabel={translate('ViewLast30Completed_Label')}
                            count={data?.totalCompleted}
                            isLoading={isLoading}
                            linkLabel={translate('ViewCompleted_Label')}
                            url={url}
                        />
                    </Col>
                    <Col className='col-12 col-md-4 mb-4'>
                        <ClearancesRealTimeDataCard
                            title={translate('Clearances_Label')}
                            status='IN_PROGRESS'
                            statusLabel={translate('ViewLast30InProgress_Label')}
                            count={data?.totalInProgress}
                            isLoading={isLoading}
                            linkLabel={translate('ViewInProgress_Label')}
                            url={url}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='grid-container dashboard-grid-layout'>
                            <ClearancesSnapshot
                                clearances={data?.clearances || []}
                                isLoading={isLoading}
                                translate={translate}
                                country={country?.Code ?? 'us'}
                            />
                            <div className='dashboard-card-2 position-relative'>
                                <SpringboardCard title={translate('TradeNews_Label')} url={tradeNewsUrl} icon={faNewspaper} />
                            </div>
                            <div className='dashboard-card-3'>
                                <SpringboardCard
                                    title={translate('BrokerageCalculator_Label')}
                                    url={customsBrokerageCalculatorUrl}
                                    icon={faCalculator}
                                />
                            </div>
                            <div className='dashboard-card-4'>
                                <SpringboardCard title={translate('Webinars_Label')} url={webinarsUrl} icon={faChalkboardTeacher} />
                            </div>
                            <div className='dashboard-card-5'>
                                <SupportCard country={country?.Code} translate={translate} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default OverviewDashboard;
