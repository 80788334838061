//import $ from 'jquery'
import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SubmitButton from '../../../common/SubmitButton/SubmitButton';
import TextArea from '../../../common/Textarea/Textarea';
import { ClientServicesByCountry as CliSrvByCountry } from '../../../constants/mlp-constants';
import * as actionCreators from '../../../store/actions/index';
import { formatTranslated } from '../../../utils/mlp-utils';
import ClientServicesByCountry from '../ClientServicesByCountry';
import RejectModal from '../RejectModal';
import OnBehalfOfHeader from './OnBehalfOfHeader';
import TeamMembersModal from './TeamMembersModal';

class OnBehalfOfForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: this.props.model,
            currentRole: '',
            selectedClientsStatuses: []
        };
        this.setCurrentRole = this.setCurrentRole.bind(this);
    }

    componentDidMount = () => {
        this.props.onInitTranslations('ClientAdmin.Signup.OnBehalfOf');

        const queryString = this.props.location.search;
        if (queryString && queryString.indexOf('requestId')) {
            const requestId = queryString.substring(queryString.indexOf('=') + 1, queryString.length);
            this.props.onGetOnBehalfOf(requestId);
        } else {
            this.props.onGetOnBehalfOf();
        }
    };

    componentDidUpdate = (prevProps) => {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }

        if (
            this.props.model &&
            this.props.model.User &&
            this.props.model !== prevProps.model &&
            this.props.model.ClientServicesByCountry.length
        ) {
            const usNumbers = this.props.model.ClientServicesByCountry[0].ParentServices.flatMap((item) =>
                item.ClientServices.map((x) => x.Number)
            );

            const caNumbers = this.props.model.ClientServicesByCountry[1].ParentServices.flatMap((item) =>
                item.ClientServices.map((x) => x.Number)
            );

            const combinedNumbers = usNumbers.concat(caNumbers);

            if (!combinedNumbers.length) return;

            this.props.onGetClientStatus(combinedNumbers);
        }
    };

    setCurrentRole(roleValue) {
        this.setState({ currentRole: roleValue });
        const oldIndex = this.props.model.User.Roles.findIndex((element) => element.IsSelected === true);
        const newIndex = this.props.model.User.Roles.findIndex((element) => element.Value === roleValue);
        this.props.onRoleChange(newIndex, oldIndex, roleValue, 'User.Roles');
    }

    allClientServicesHaveAdmins = () => {
        return this.props.clientServicesByCountry.every((csc) => {
            return csc.ParentServices.every((ps) => {
                return ps.ClientServices.every((cs) => {
                    return cs.ClientAdminId;
                });
            });
        });
    };

    allClientServicesHavePaymentAdmins = () => {
        return this.props.clientServicesByCountry.every((csc) => {
            return csc.ParentServices.every((ps) => {
                return ps.ClientServices.every((cs) => {
                    return cs.PaymentAdminId;
                });
            });
        });
    };

    clientServicesByCountryRecreate = () => {
        this.props.updateClientServicesByCountry();
    };

    onTeamMembersModalClose = () => {
        this.props.onCloseTeamMembersModal();
    };

    onSetValidationMessages = (validationMessages) => {
        this.props.oboSetValidationMessages(validationMessages);
    };

    onChangeRejectMsg = (rejectMsg) => {
        this.props.onChangeRejectMsg(rejectMsg);
    };

    setSelectedClientStatus = (value) => {
        if (!this.state.selectedClientsStatuses.includes(value)) {
            this.setState((prevState) => ({
                selectedClientsStatuses: [...prevState.selectedClientsStatuses, value]
            }));
        }
    };

    componentWillUnmount = () => {
        this.props.onResetStateOnBehalfOf();
    };

    render() {
        if (this.props.model.User.Roles && this.props.translations.PhraseGroupCode === 'ClientAdmin.Signup.OnBehalfOf') {
            const rejectMsgModal = (
                <RejectModal
                    model={this.props.model}
                    msg={this.props.rejectionMessage}
                    name='RejectionMessage'
                    action='/ClientAdmin/DeclineOnBehalfOf'
                    translations={this.props.translations.Phrases['onBehalfOf']}
                    okButtonText={this.props.translations.Phrases['Reject_Button']}
                    title={this.props.translations.Phrases['RejectionMessageTitle_Label']}
                    label={this.props.translations.Phrases['RejectionMessage_Label']}
                    btnText={this.props.translations.Phrases['Reject_Button']}
                    onValueChanged={this.onChangeRejectMsg}
                    onSuccessfulReject={{
                        backToPath: '/Dashboard',
                        backToLabel: 'BackToDashboard_Label',
                        phraseGroup: 'ClientAdmin.Signup.SuccessfulRequestRevoke'
                    }}
                    postAction={this.onSetValidationMessages}
                />
            );

            let submit = (
                <div className='col-sm-12 text-right'>
                    <span className='validation-text'>
                        {this.props.translations.Phrases[this.props.validationMessages.AllClientServices]}
                    </span>
                    <span className='validation-text'>
                        {formatTranslated(this.props.validationMessages.ExistingClientServices, this.props.translations.Phrases)}
                    </span>
                    {this.props.isOnBehalfOf ? '' : rejectMsgModal}
                </div>
            );

            let parentsByCountry = (
                <div>
                    <br />
                    <br />
                    <h3 className='text-center'>{this.props.translations.Phrases['SelectUserAndClientNumbers_Label']}</h3>
                    <br />
                    <br />
                </div>
            );

            if (
                this.props.clientServicesByCountry.some((x) => {
                    return x.ParentServices.length > 0;
                }) &&
                this.props.user.Email &&
                this.state.currentRole !== ''
            ) {
                parentsByCountry = this.props.clientServicesByCountry.map((cs, index) => {
                    return (
                        <div key={index + cs.CountryCode}>
                            <hr />
                            <ClientServicesByCountry
                                pendingUser={this.props.user}
                                country={cs}
                                countryIndex={index}
                                area={CliSrvByCountry.CLIENT_ADMIN}
                                currentUserRole={this.state.currentRole}
                                userRoles={this.props.model.User.Roles}
                                selectedClients={this.state.selectedClientsStatuses}
                            />
                        </div>
                    );
                });

                if (this.allClientServicesHaveAdmins() && this.allClientServicesHavePaymentAdmins()) {
                    submit = (
                        <div className='col-sm-12 text-right'>
                            <span className='validation-text'>{this.props.translations.Phrases['AllClientsHaveAdmins_Label']}</span>
                            <span className='validation-text'>
                                {this.props.translations.Phrases[this.props.validationMessages.AllClientServices]}
                            </span>
                            <span className='validation-text'>
                                {formatTranslated(this.props.validationMessages.ExistingClientServices, this.props.translations.Phrases)}
                            </span>
                            {this.props.isOnBehalfOf ? null : rejectMsgModal}
                        </div>
                    );
                } else {
                    submit = (
                        <div className='col-sm-12 text-right'>
                            <span className='validation-text'>
                                {this.props.model.User.Roles.find((role) => role.IsSelected).Value === 'CentralAdmin' &&
                                this.props.validationMessages.ShipmentStatusMsg
                                    ? this.props.translations.Phrases[this.props.validationMessages.ShipmentStatusMsg]
                                    : this.props.translations.Phrases[this.props.validationMessages.AllClientServices]}
                            </span>
                            <span className='validation-text'>
                                {formatTranslated(this.props.validationMessages.ExistingClientServices, this.props.translations.Phrases)}
                            </span>
                            {this.props.isOnBehalfOf ? '' : rejectMsgModal}
                            <SubmitButton
                                label={
                                    this.props.isOnBehalfOf
                                        ? this.props.translations.Phrases['Submit_Button']
                                        : this.props.translations.Phrases['Approve_Button']
                                }
                                model={this.props.model}
                                postActionUrl='ClientAdmin/OnBehalfOf'
                                postAction={this.props.oboSetValidationMessages}
                                onSuccessfulSubmit={{
                                    backToPath: '/Dashboard',
                                    backToLabel: 'BackToDashboard_Label',
                                    phraseGroup: 'ClientAdmin.Signup.SuccessfulSignup'
                                }}
                                onClick={(e) => e.preventDefault()}
                            />
                        </div>
                    );
                }
            }

            const noteField =
                this.props.clientServicesByCountry.some((x) => {
                    return x.ParentServices.length > 0;
                }) &&
                this.props.user.Email &&
                this.state.currentRole !== '' ? (
                    <div className='row'>
                        <div className='col-sm-12 note-field'>
                            <TextArea
                                label={this.props.translations.Phrases['Note_Label']}
                                name='Note'
                                value={this.props.model.Note}
                                onValueChanged={this.props.onHandleUpdate}
                            />
                        </div>
                    </div>
                ) : (
                    ''
                );

            return (
                <div id='page' className='wide-page'>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.props.translations.Phrases['Page_Title']}</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className='page-wrapper'>
                        <div className='page-top-row'>
                            <div className='page-header-section'>
                                <nav className='mlp-breadcrumb'>
                                    <Link to='/Dashboard' className='breadcrumb-parent'>
                                        {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                    </Link>
                                    <i className='fa fa-caret-right'></i>
                                    <span>{this.props.translations.Phrases['Page_Header_Navigation_Level_2']}</span>
                                </nav>
                                <h1 className='bold-title'>{this.props.translations.Phrases['Page_Header']}</h1>
                            </div>
                        </div>
                        <div className='content-wrapper'>
                            <div className='row page-content'>
                                <div id='on-behalf-of-form'>
                                    <TeamMembersModal
                                        teamMembers={this.props.teamMembers}
                                        isOpen={this.props.teamMembersModalVisible}
                                        onTeamMembersModalClose={this.onTeamMembersModalClose}
                                    />
                                    <OnBehalfOfHeader
                                        viewModel={this.props.model}
                                        validationMessages={this.props.validationMessages}
                                        roleChanged={this.clientServicesByCountryRecreate}
                                        setCurrentRole={this.setCurrentRole}
                                        setSelectedClientStatus={this.setSelectedClientStatus}
                                    />
                                    <div>{parentsByCountry}</div>
                                    <br />
                                    {noteField}
                                    <div className='row'>{submit}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div className='page-wrapper'></div>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        validationMessages: state.onBehalfOf.validationMessages,
        user: state.onBehalfOf.model.User,
        clientServicesByCountry: state.onBehalfOf.model.ClientServicesByCountry,
        rejectionMessage: state.onBehalfOf.model.RejectionMessage,
        isOnBehalfOf: state.onBehalfOf.model.IsOnBehalfOf,
        teamMembers: state.onBehalfOf.model.TeamMembers,
        teamMembersModalVisible: state.onBehalfOf.model.TeamMembersModalVisible,
        model: state.onBehalfOf.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateClientServicesByCountry: () => dispatch(actionCreators.updateClientServicesByCountry()),
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup)),
        onCloseTeamMembersModal: () => dispatch(actionCreators.closeTeamMembersModalOBO()),
        onGetOnBehalfOf: (requestId) => dispatch(actionCreators.getOnBehalfOf(requestId)),
        oboSetValidationMessages: (validationMessages) => dispatch(actionCreators.oboSetValidationMessages(validationMessages)),
        onChangeRejectMsg: (rejectMsg) => dispatch(actionCreators.changeOnBehalfOfRejectMsg(rejectMsg)),
        onResetStateOnBehalfOf: () => dispatch(actionCreators.resetStateOnBehalfOf()),
        onHandleUpdate: (value) => dispatch(actionCreators.updateNoteField(value)),
        onRoleChange: (item) => dispatch(actionCreators.oboChangeUserRole(item)),
        onGetClientStatus: (numbers) => dispatch(actionCreators.getClientStatus(numbers))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnBehalfOfForm);
