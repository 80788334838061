import { GridColumn as Column, Grid, GridCellProps } from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import '@progress/kendo-theme-default/dist/all.css';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Card from '../../../../common/Card/Card';
import Spinner from '../../../common/features/Spinner';
import useGridLanguage from '../../../common/hooks/useGridLanguage';
import { ClearanceListItem } from '../../../common/models/ShipmentList';
import { useDashboardSelector } from '../redux/hooks';
import { FormattedGridCell } from './FormatedGridCell';

const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;

interface ClearancesSnapshotProps {
    clearances: ClearanceListItem[];
    isLoading: boolean;
    translate: (key?: string | null | undefined, ...subs: any) => string;
    country: string;
}

const EntryNoHeader = (props: any) => {
    return (
        <div className='k-column-title' style={{ paddingLeft: '22px' }}>
            {props.title}
        </div>
    );
};

const ClearancesSnapshot: React.FC<ClearancesSnapshotProps> = ({ clearances, country, isLoading, translate }) => {
    const minGridWidth = useRef(0); // Ensuring minGridWidth is of type number
    const grid = useRef<HTMLDivElement | null>(null); // Ref type assertion
    const [applyMinWidth, setApplyMinWidth] = useState(false);
    const [gridCurrent, setGridCurrent] = useState(0);
    const { languageSelected: selectedLanguage } = useDashboardSelector((state) => state.language);
    const gridLanguage = useGridLanguage(selectedLanguage);

    useEffect(() => {
        grid.current = document.querySelector('.k-grid') as HTMLDivElement; // Ref type assertion
        if (!grid.current) return;

        window.addEventListener('resize', handleResize);

        //columns.forEach((item) => (minGridWidth.current += item.minWidth || 0)); // Ensure minWidth is of number type
        minGridWidth.current += 640;

        setGridCurrent(grid.current.offsetWidth);
        setApplyMinWidth(grid.current.offsetWidth < minGridWidth.current);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        if (grid.current && grid.current.offsetWidth < minGridWidth.current && !applyMinWidth) {
            setApplyMinWidth(true);
        } else if (grid.current && grid.current.offsetWidth > minGridWidth.current) {
            setGridCurrent(grid.current.offsetWidth);
            setApplyMinWidth(false);
        }
    };

    const setWidth = (minWidth: number | undefined) => {
        // Ensuring minWidth is of type number or undefined
        minWidth = minWidth || 0;
        let width = applyMinWidth ? minWidth : minWidth + (gridCurrent - minGridWidth.current) / 4;
        width = width < COLUMN_MIN ? width : (width -= ADJUST_PADDING);
        return width;
    };
    const formattedCell = (props: GridCellProps) => <FormattedGridCell {...props} />;

    return (
        <>
            <Card className='dashboard-card-1 h-100'>
                <Card.Header title={translate('ClearancesSnapshot_Label')} />
                <Card.Body className={`${isLoading ? '' : 'p-0 '}max-height-550`}>
                    {!isLoading && (
                        <LocalizationProvider language={gridLanguage.language}>
                            <IntlProvider locale={gridLanguage.locale}>
                                <Grid data={clearances} resizable={true} className='border-0'>
                                    <Column
                                        headerCell={EntryNoHeader}
                                        width={setWidth(160)}
                                        field='EntryNumber'
                                        title={translate('EntryNo_Label')}
                                        cell={formattedCell}
                                    />
                                    {country === 'us' ? (
                                        <Column
                                            field='BillOfLading'
                                            title={translate('BOLNo_Label')}
                                            cell={formattedCell}
                                            width={setWidth(180)}
                                        />
                                    ) : (
                                        <Column
                                            field='CargoControlNumber'
                                            title={translate('CargoControlNo_Label')}
                                            cell={formattedCell}
                                            width={setWidth(180)}
                                        />
                                    )}
                                    <Column
                                        field='ClearanceStatus'
                                        title={translate('Status_Label')}
                                        cell={formattedCell}
                                        width={setWidth(180)}
                                    />
                                    <Column
                                        field='Milestone'
                                        title={translate('CurrentMilestone_Label')}
                                        cell={formattedCell}
                                        width={setWidth(180)}
                                    />
                                </Grid>
                            </IntlProvider>
                        </LocalizationProvider>
                    )}
                    {isLoading && (
                        <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                            <Spinner />
                        </div>
                    )}
                </Card.Body>
                <Card.Footer>
                    <Link to='/Clearances' className='btn btn-tertiary'>
                        {translate('ClearancesGoToAllButton_Label')}
                    </Link>
                </Card.Footer>
            </Card>
        </>
    );
};

export default ClearancesSnapshot;
