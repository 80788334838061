import { Component } from 'react';
import { connect } from 'react-redux';
import { UTCtoLocalDateTime } from '../../../utils/mlp-utils';
import BatchEditGridChildRow from './BatchEditGridChildRow';
import BatchEditGridParentRow from './BatchEditGridParentRow';

class BatchEditParentGrid extends Component {
    getUserDetails = (user) => {
        if (!user.IsEnabled) {
            return [
                {
                    label: this.props.translations.Phrases['Status_Message_Label'],
                    value: this.props.translations.Phrases['Locked_User_Status_Message']
                }
            ];
        }

        return [
            { label: this.props.translations.Phrases['CompanyName_Label'], value: user.CompanyName },
            { label: this.props.translations.Phrases['Roles_Label'], value: user.UserRoleDisplay },
            { label: this.props.translations.Phrases['Email_Label'], value: user.Email },
            { label: this.props.translations.Phrases['ExpirationDate_Label'], value: UTCtoLocalDateTime(user.ExpirationDate) }
        ];
    };

    renderParentRow() {
        const { user: parent, parentIndex } = this.props;

        if (parent) {
            return (
                <BatchEditGridParentRow key={parentIndex} getUserDetails={this.getUserDetails} user={parent} parentIndex={parentIndex} />
            );
        }
    }

    renderInferiorUsers() {
        const { user: parent, parentIndex } = this.props;

        if (parent.InferiorUsers && parent.InferiorUsers.length > 0) {
            return parent.InferiorUsers.map((inferiorUser, index) => (
                <BatchEditGridChildRow
                    key={`child__user-${index}`}
                    getUserDetails={this.getUserDetails}
                    parentUser={parent}
                    user={inferiorUser}
                    parentIndex={parentIndex}
                    childIndex={index}
                />
            ));
        }
    }

    render() {
        return (
            <tbody className='tbody-separator'>
                {this.renderParentRow()}
                {this.renderInferiorUsers()}
            </tbody>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(BatchEditParentGrid);
