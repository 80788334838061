import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatTranslated, isVisible } from '../../utils/mlp-utils';
import CheckBox from '../CheckBox/CheckBox';

class MotFilter extends Component{

    state = {
        seeMoreOptions:false
    };

    static propTypes = {
        countryCodes: PropTypes.arrayOf(PropTypes.string),
        onValueChanged: PropTypes.func,
        customAction: PropTypes.func,
        onResetSelection: PropTypes.func
    };

    handleChecked = (value, name)=>{
       const splittedName = name.split('.');
       const filterIndex = splittedName[1];
       const optionIndex = splittedName[3];
        this.props.onValueChanged(value, filterIndex, optionIndex);
    }

    renderCheckboxes=()=>{
        const {filterControl} = this.props;
        const {FilterIndex} = filterControl;
        let checkboxes = [];

        checkboxes = filterControl.Options.map((option,index)=>{
            const name = 'Filters.' + FilterIndex + '.Options.' + index + '.IsSelected';
            return (
            <div key={index}>
                <div className="d-block">
                <CheckBox 
                        name={name}
                        text={option.TranslatedName}
                        checked={option.IsSelected}
                        onChecked={this.handleChecked}
                        customAction={this.props.customAction}
                        noBorder
                        noHeader
                />
                </div>
            </div>);
        });
        //Shows only 4 checkboxes if "seeMoreOptions" is false
            if(this.state.seeMoreOptions){
            return checkboxes;
            }
            else{
            return checkboxes.filter((ckbx, index)=>index < 4);
            }
    };

    selectAll = (value)=>{
        this.props.onSelectAll(value,this.props.filterControl.FilterIndex);

        if(this.props.customAction)
        this.props.customAction();
    };

    handleSeeMoreOptions = ()=>{
        const currVal = this.state.seeMoreOptions;
        this.setState({seeMoreOptions : !currVal});
    };

    renderSeeMore = ()=>{
        const seeMore = this.state.seeMoreOptions;
        const label = seeMore ? "SeeLess_Label" : "SeeMore_Label";
        return (<div className="btn btn-link" onClick={this.handleSeeMoreOptions}>
            {`${formatTranslated(label,this.props.translations.Phrases)} ${seeMore ? "-" : "+"}`}
            </div>);      
    };

    renderHeader = ()=>{
        return (<div className="input-header">
                    <div className="input-label">
                        <span>{formatTranslated("USMOT",this.props.translations.Phrases)}</span>
                    </div>
                </div>);
    }

    render(){
        const {Phrases} = this.props.translations;
                const {filterControl} = this.props;
        const {searchTypeCode} = this.props;

        if(!isVisible(filterControl,searchTypeCode)){
        return null;
        }
        return (<div className="input-container">
                    {this.renderHeader()}
                    <div>
                        <span className="btn btn-link"
                        onClick={()=>{this.selectAll(true)}}
                        > 
                        {formatTranslated("SelectAll_Label",Phrases)}
                        </span>
                        <span className="btn btn-link"
                        onClick={()=>{this.selectAll(false)}}> 
                        {formatTranslated("SelectNone_Label",Phrases)}
                        </span>
                    </div>
                        <div className="input-body">
                        {this.renderCheckboxes()}
                        </div>
                    {this.renderSeeMore()}
                </div>);
    };
}

const mapStateToProps = (state)=>{
 return {
        translations: state.translations.model
    };
};


export default connect(mapStateToProps)(MotFilter);