import $ from 'jquery';
import axios from '../../../axios-mlp';
import * as actionTypes from './brpReportsActionTypes';

export const setBRPReports = (viewModel) => {
    return {
        type: actionTypes.SET_BRP_REPORTS,
        model: viewModel
    };
};

export const initBRPReports = () => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .get('BRP/Index')
            .then((response) => {
                dispatch(setBRPReports(response.status));
            })
            .catch((error) => {
                if (error.response) {
                    dispatch(setBRPReports(error.response.status));
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};
