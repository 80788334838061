import dayjs from 'dayjs';
import { memo, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import CloseButton from '../../../common/features/CloseButton';
import Spinner from '../../../common/features/Spinner';
import useTranslationHydration from '../../../common/hooks/useTranslationHydration';
import { useActiveNoticesQuery } from '../../../redux/api/noticesApi';
import { usePortalDispatch, usePortalSelector } from '../../../redux/hooks';
import { removeUnreadLobbyItems } from '../../../redux/reducers/alertsSlice';
import { useDebounce } from '../../hooks/useDebounce';
import type { ClearanceAlert } from '../../models/ClearanceAlert';
import type { DownloadLobbyItem } from '../../models/ReduxSlices';
import AlertDrawerCard from './AlertDrawerCard';
import DownloadLobbyDrawerCard from './DownloadLobbyDrawerCard';

const ENTER_TIMEOUT = 50;
const EXIT_TIMEOUT = 150;

interface AlertsDrawerProps {
    onToggleAlerts: () => void;
}

const AlertsDrawer = memo(function AlertsDrawer({ onToggleAlerts }: AlertsDrawerProps) {
    const { alerts, downloadLobby } = usePortalSelector((state) => state.alerts);
    const dispatch = usePortalDispatch();

    useActiveNoticesQuery();

    const [showAnimation, setShowAnimation] = useState(false);
    const translate = useTranslationHydration();

    const debounceEnter = useDebounce(ENTER_TIMEOUT);
    const debounceExit = useDebounce(EXIT_TIMEOUT);

    useEffect(() => {
        debounceEnter(() => {
            setShowAnimation(true);
        });
    }, [debounceEnter]);

    useEffect(() => {
        dispatch(removeUnreadLobbyItems());
    }, [dispatch]);

    const handleExit = () => {
        setShowAnimation(false);

        debounceExit(() => {
            onToggleAlerts();
        });
    };

    const mappedAlerts = useMemo(() => {
        if (!alerts || !downloadLobby) return;

        const sortAlertsDownloads = (arr: (ClearanceAlert | DownloadLobbyItem)[]) =>
            arr.sort((a, b) => {
                const dateA = a.type === 'DOWNLOAD' ? a.createdOn : a.date;
                const dateB = b.type === 'DOWNLOAD' ? b.createdOn : b.date;

                return dayjs(dateB).valueOf() - dayjs(dateA).valueOf();
            });

        return [...alerts.notices, ...sortAlertsDownloads([...alerts.statusAlerts, ...downloadLobby])];
    }, [alerts, downloadLobby]);

    return (
        <>
            {ReactDOM.createPortal(
                <>
                    <div className={`screen-overlay ${showAnimation ? 'show' : ''}`} onClick={handleExit}></div>
                    <aside className={`offcanvas alerts-drawer ${showAnimation ? 'show' : ''}`}>
                        <div
                            className='alerts-drawer-heading sticky-top bg-white border-bottom p-4'
                            style={{ display: 'flex', alignItems: 'start' }}
                        >
                            <h3 className='mb-0'>{translate('AlertsTitle_Label')}</h3>
                            <CloseButton onClick={handleExit} style={{ position: 'relative', top: '0.5rem', right: 0 }} />
                        </div>
                        {!mappedAlerts && (
                            <div className='w-100 h-50 d-flex align-items-center justify-content-center'>
                                <Spinner />
                            </div>
                        )}
                        {mappedAlerts && (
                            <div className='alert-cards p-4' style={{ minWidth: '0px' }}>
                                {mappedAlerts.map((item) => {
                                    if (item.type === 'DOWNLOAD') {
                                        return <DownloadLobbyDrawerCard key={item.packageId} {...item} handleClose={handleExit} />;
                                    } else if (item.type === 'NOTICE') {
                                        return <AlertDrawerCard key={item.id} {...item} />;
                                    } else {
                                        return <AlertDrawerCard key={item.id} {...item} handleClose={handleExit} />;
                                    }
                                })}
                                {mappedAlerts.length === 0 && <p>{translate('AlertsNoNew_Label')}</p>}
                            </div>
                        )}
                    </aside>
                </>,
                document.querySelector('#portalDrawer') || document.body
            )}
        </>
    );
});

export default AlertsDrawer;
