import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';
import './styles.css';

interface Props {
    id: string;
    text: string;
    remainingSpace: number;
    setSearchKeywords: React.Dispatch<
        React.SetStateAction<
            {
                id: string;
                text: string;
            }[]
        >
    >;
    onRemove: (keywordId: string) => void;
}

const cleanString = (str: string) => str?.replace(/[^a-zA-Z0-9,_\s-/]/g, '');

const SearchChip: React.FC<Props> = ({ id: idProp, text, remainingSpace, setSearchKeywords, onRemove }) => {
    const spanRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (spanRef.current) {
            spanRef.current.innerText = text;
        }
    }, [text]);

    const handleChangeValue = (e: React.FocusEvent<HTMLSpanElement, Element> | React.KeyboardEvent<HTMLSpanElement>) => {
        setSearchKeywords((prevState) =>
            prevState.map((item) => (item.id === idProp ? { id: item.id, text: cleanString(e.currentTarget.innerText) } : item))
        );
    };

    const startingLength = (spanRef.current && spanRef.current.innerText.length) || 0;

    return (
        <div className={'search-chip'}>
            <span
                id='search_chip_input_span'
                ref={spanRef}
                role='textbox'
                contentEditable='true'
                suppressContentEditableWarning={true}
                onBlur={handleChangeValue}
                onKeyDown={(e) => {
                    // Prevent special characters from being added & character limit
                    if (
                        /[^a-zA-Z0-9,_\s-/]/.test(e.key) ||
                        e.key === 'Enter' ||
                        (spanRef.current && spanRef.current?.innerText?.length >= remainingSpace + startingLength)
                    ) {
                        e.preventDefault();
                    }
                }}
                onPaste={(e) => {
                    // @ts-ignore
                    let paste = (e.clipboardData || window?.clipboardData).getData('text');

                    if (/[^a-zA-Z0-9,_\s-/]/.test(paste)) {
                        e.preventDefault();
                    }
                }}
            >
                {text}
            </span>

            <span className='pl-2' onClick={onRemove.bind(null, idProp)}>
                <FontAwesomeIcon icon={faTimes} />
            </span>
        </div>
    );
};

export default SearchChip;
