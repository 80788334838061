import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Subscription } from '../../../../common/models/ClearanceSubscriptions';
import { NewSubscriber } from '../../../../common/models/NewSubscriber';
import { ClearanceSubscribersSlice } from '../../common/models/ReduxSlices';
import { ShipmentDetailsData } from '../../common/models/ResponseData';
import {
    GetClearanceSubscribers,
    GetNotificationsSettings,
    GetTeamMembersAndContacts,
    SubscribeUsers,
    UnsubscribeUsers,
    UpdateNotificationSettings,
    UpdateSubscription
} from '../actions/clearanceSubscribers';

const defaultNewSubscriber: NewSubscriber = {
    id: 0,
    email: '',
    emailIsValid: false,
    phone: '',
    phoneIsValid: false,
    fullName: '',
    role: '',
    language: '-'
};

const initialState: ClearanceSubscribersSlice = {
    subscribers: null,
    isLoading: false,
    error: undefined,
    subscriptionUpdate: {
        data: null,
        isLoading: false,
        error: undefined,
        action: null,
        alertMessage: null,
        alertType: null
    },
    unsubscribe: {
        data: null,
        isLoading: false,
        error: undefined,
        removedSubscribersAmount: null
    },
    subscribe: {
        data: null,
        isLoading: false,
        error: undefined
    },
    selfSubscription: {
        data: null,
        isLoading: false,
        error: undefined,
        email: null,
        phone: null,
        milestones: null
    },
    selfSubscriptionUpdate: {
        data: null,
        isLoading: false,
        error: undefined,
        alertMessage: null,
        alertType: null
    },
    selfSubscriptionUpdateRequest: false,
    newSubscribers: [defaultNewSubscriber],
    dataState: {
        take: 10,
        skip: 0
    },
    membersAndContacts: null
};

const clearanceSubscribersSlice = createSlice({
    name: 'clearanceSubscribers',
    initialState,
    reducers: {
        // STATE UPDATES/CHANGES
        setSubscriptionUpdateAction(state, action: PayloadAction<string>) {
            state.subscriptionUpdate.action = action.payload;
        },
        setSelfSubscriptionData(state, action: PayloadAction<ShipmentDetailsData['Subscription']>) {
            state.selfSubscription.data = action.payload;
            if (action.payload) {
                // Get email
                state.selfSubscription.email = action.payload.Email ? action.payload.Email : action.payload.ProfileEmail;
                // Get phone
                state.selfSubscription.phone = action.payload.Phone;
                // Get milestones
                state.selfSubscription.milestones = Object.fromEntries(
                    Object.entries(action.payload).filter(([key, value]) => /hasnotifications/i.test(key))
                );
            }
        },
        setNewSubscriberEmail(state, action: PayloadAction<{ id: number; value: string; isValid: null | boolean }>) {
            state.newSubscribers = state.newSubscribers.map((subscriber) =>
                subscriber.id === action.payload.id
                    ? { ...subscriber, email: action.payload.value, emailIsValid: action.payload.isValid }
                    : subscriber
            );
        },
        setNewSubscriberPhone(state, action: PayloadAction<{ id: number; value: string; isValid: null | boolean }>) {
            state.newSubscribers = state.newSubscribers.map((subscriber) =>
                subscriber.id === action.payload.id
                    ? { ...subscriber, phone: action.payload.value, phoneIsValid: action.payload.isValid }
                    : subscriber
            );
        },
        setNewSubscriberFullName(state, action: PayloadAction<{ id: number; value: string }>) {
            state.newSubscribers = state.newSubscribers.map((subscriber) =>
                subscriber.id === action.payload.id ? { ...subscriber, fullName: action.payload.value } : subscriber
            );
        },
        setNewSubscriberUserId(state, action: PayloadAction<{ id: number; value: string }>) {
            state.newSubscribers = state.newSubscribers.map((subscriber) =>
                subscriber.id === action.payload.id ? { ...subscriber, userId: action.payload.value } : subscriber
            );
        },
        setNewSubscriberRole(state, action: PayloadAction<{ id: number; value: NewSubscriber['role'] }>) {
            state.newSubscribers = state.newSubscribers.map((subscriber) =>
                subscriber.id === action.payload.id ? { ...subscriber, role: action.payload.value } : subscriber
            );
        },
        setNewSubscriberLanguage(state, action: PayloadAction<{ id: number; value: NewSubscriber['language'] }>) {
            state.newSubscribers = state.newSubscribers.map((subscriber) =>
                subscriber.id === action.payload.id ? { ...subscriber, language: action.payload.value } : subscriber
            );
        },
        addNewSubscriber(state) {
            state.newSubscribers.push({ ...defaultNewSubscriber, id: Math.random() * 1000 + 1 });
        },
        removeNewSubscriber(state, action: PayloadAction<number>) {
            if (state.newSubscribers.length === 1) {
                state.newSubscribers = initialState.newSubscribers;
                return;
            }
            state.newSubscribers = state.newSubscribers.filter((subscriber) => subscriber.id !== action.payload);
        },
        // STATE RESETS
        resetSubscribe(state) {
            state.subscribe = initialState.subscribe;
        },
        resetUnsubscribe(state) {
            state.unsubscribe = initialState.unsubscribe;
        },
        resetSubscriptionUpdate(state) {
            state.subscriptionUpdate = initialState.subscriptionUpdate;
        },
        resetSelfSubscriptionUpdate(state) {
            state.selfSubscriptionUpdate = initialState.selfSubscriptionUpdate;
        },
        resetNewSubscribers(state) {
            state.newSubscribers = initialState.newSubscribers;
        },
        resetNewSubscriberFields(state, action: PayloadAction<number>) {
            state.newSubscribers = state.newSubscribers.map((subscriber) =>
                subscriber.id === action.payload
                    ? { ...subscriber, role: '', language: '-', email: '', emailIsValid: false, phone: '', phoneIsValid: false }
                    : subscriber
            );
        },
        resetClearanceSubscribers() {
            return { ...initialState };
        },
        clearNewSubscribers: (state) => {
            if (state.subscribers?.length) {
                state.subscribers = [
                    ...state.subscribers.map((el) => ({
                        ...el,
                        isNew: false
                    }))
                ];
            }
        }
    },
    extraReducers: (builder) => {
        builder
            //GET CLEARANCE SUBSCRIBERS
            .addCase(GetClearanceSubscribers.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetClearanceSubscribers.fulfilled, (state, action) => {
                state.isLoading = false;

                const payload = action.payload.SubscriptionsPerClientNumbers;

                if (state.subscribers?.length) {
                    const newSubs = [
                        ...payload.map((el: Subscription) => ({
                            ...el,
                            isNew: state.subscribers?.find((oldEl) => oldEl.SubscriptionId === el.SubscriptionId) ? false : true
                        }))
                    ];

                    state.subscribers = newSubs;
                } else {
                    state.subscribers = payload;
                }
            })
            .addCase(GetClearanceSubscribers.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            // UPDATE SUBSCRIPTION (SUBSCRIBERS GRID)
            .addCase(UpdateSubscription.pending, (state, action) => {
                if (
                    action.meta.arg.requestModel.Email === state.selfSubscription.email ||
                    action.meta.arg.requestModel.Phone === state.selfSubscription.phone
                ) {
                    state.selfSubscriptionUpdateRequest = true;
                } else {
                    state.selfSubscriptionUpdateRequest = false;
                }
                state.unsubscribe = initialState.unsubscribe;
                state.subscribe = initialState.subscribe;
                state.selfSubscriptionUpdate = initialState.selfSubscriptionUpdate;
                state.subscriptionUpdate.isLoading = true;
                state.error = undefined;
            })
            .addCase(UpdateSubscription.fulfilled, (state, action) => {
                state.subscriptionUpdate.isLoading = false;
                state.subscriptionUpdate.data = action.payload;
                if (action.payload.ResponseCode === 400 && action.payload.ResponseMessage === 'FAIL') {
                    state.subscriptionUpdate.alertMessage = 'ClientNumberNoClearancesAvailable_Label';
                    state.subscriptionUpdate.alertType = 'warning';
                } else {
                    if (action.payload.RequestedAction === 'UPDATE' && action.payload.ResponseMessage === 'FAIL') {
                        state.subscriptionUpdate.alertMessage = 'SubscriptionWarning_Label';
                        state.subscriptionUpdate.alertType = 'warning';
                    } else {
                        if (state.subscriptionUpdate.action === 'onhold') {
                            state.subscriptionUpdate.alertMessage = 'OnHoldNotificationsUpdated_Label';
                        } else {
                            state.subscriptionUpdate.alertMessage = 'MilestonesUpdated_Label';
                        }
                        state.subscriptionUpdate.alertType = 'success';
                    }
                }
            })
            .addCase(UpdateSubscription.rejected, (state, action) => {
                state.subscriptionUpdate.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.subscriptionUpdate.error = action.payload;
                } else {
                    state.subscriptionUpdate.error = action.error.message;
                }
            })
            // UNSUBSCRIBE (SUBSCRIBERS GRID)
            .addCase(UnsubscribeUsers.pending, (state, action) => {
                state.selfSubscriptionUpdateRequest = false;
                if (state.selfSubscription.data && state.selfSubscription.data.SubscriptionIds?.length) {
                    for (const subscriptionId of action.meta.arg) {
                        if (subscriptionId.toString() === state.selfSubscription.data.SubscriptionIds[0].toString()) {
                            state.selfSubscriptionUpdateRequest = true;
                            break;
                        }
                    }
                }
                state.subscriptionUpdate = initialState.subscriptionUpdate;
                state.subscribe = initialState.subscribe;
                state.selfSubscriptionUpdate = initialState.selfSubscriptionUpdate;
                state.unsubscribe.isLoading = true;
                state.unsubscribe.error = undefined;
                state.unsubscribe.removedSubscribersAmount = action.meta.arg.length;
            })
            .addCase(UnsubscribeUsers.fulfilled, (state, action) => {
                state.unsubscribe.isLoading = false;
                state.unsubscribe.data = action.payload;
            })
            .addCase(UnsubscribeUsers.rejected, (state, action) => {
                state.unsubscribe.isLoading = false;
                state.unsubscribe.removedSubscribersAmount = initialState.unsubscribe.removedSubscribersAmount;
                if (typeof action.payload === 'number') {
                    state.unsubscribe.error = action.payload;
                } else {
                    state.unsubscribe.error = action.error.message;
                }
            })
            // SUBSCRIBE (SUBSCRIBERS GRID)
            .addCase(SubscribeUsers.pending, (state, action) => {
                state.subscriptionUpdate = initialState.subscriptionUpdate;
                state.unsubscribe = initialState.unsubscribe;
                state.selfSubscriptionUpdate = initialState.selfSubscriptionUpdate;
                state.subscribe.isLoading = true;
                state.subscribe.error = undefined;
            })
            .addCase(SubscribeUsers.fulfilled, (state, action) => {
                state.subscribe.isLoading = false;
                state.subscribe.data = action.payload;
            })
            .addCase(SubscribeUsers.rejected, (state, action) => {
                state.subscribe.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.subscribe.error = action.payload;
                } else {
                    state.subscribe.error = action.error.message;
                }
            })
            // GET NOTIFICATIONS SETTINGS (SELF SUBSCRIPTION)
            .addCase(GetNotificationsSettings.pending, (state, action) => {
                state.selfSubscriptionUpdateRequest = false;
                state.selfSubscription.isLoading = true;
                state.selfSubscription.error = undefined;
            })
            .addCase(GetNotificationsSettings.fulfilled, (state, action) => {
                state.selfSubscription.isLoading = false;
                state.selfSubscription.data = action.payload;
                if (action.payload) {
                    // Get email
                    state.selfSubscription.email = action.payload.Email ? action.payload.Email : action.payload.ProfileEmail;
                    // Get phone
                    state.selfSubscription.phone = action.payload.Phone;
                    // Get milestones
                    state.selfSubscription.milestones = Object.fromEntries(
                        Object.entries(action.payload).filter(([key, value]) => /hasnotifications/i.test(key))
                    );
                }
            })
            .addCase(GetNotificationsSettings.rejected, (state, action) => {
                state.selfSubscription.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.selfSubscription.error = action.payload;
                } else {
                    state.selfSubscription.error = action.error.message;
                }
            })
            // UPDATE NOTIFICATIONS SETTINGS (SELF SUBSCRIPTION)
            .addCase(UpdateNotificationSettings.pending, (state, action) => {
                state.selfSubscriptionUpdateRequest = true;
                state.subscriptionUpdate = initialState.subscriptionUpdate;
                state.unsubscribe = initialState.unsubscribe;
                state.subscribe = initialState.subscribe;
                state.selfSubscriptionUpdate.isLoading = true;
                state.selfSubscriptionUpdate.error = undefined;
            })
            .addCase(UpdateNotificationSettings.fulfilled, (state, action) => {
                state.selfSubscriptionUpdate.isLoading = false;
                state.selfSubscriptionUpdate.data = action.payload;
                if (action.payload.ResponseCode >= 200 && action.payload.ResponseCode < 300)
                    if (action.payload.SubscriptionStatusCode === 'SUBSCRIBED' && state.selfSubscription.data?.SubscriptionIds?.length) {
                        state.selfSubscriptionUpdate.alertMessage = 'SubscriptionUpdated_Label';
                    } else if (
                        action.payload.SubscriptionStatusCode === 'SUBSCRIBED' &&
                        !state.selfSubscription.data?.SubscriptionIds?.length
                    ) {
                        state.selfSubscriptionUpdate.alertMessage = 'SubscribedToClearance_Label';
                    } else {
                        state.selfSubscriptionUpdate.alertMessage = 'NotificationsSingleClearanceUnsubscribe_Label';
                    }
                state.selfSubscriptionUpdate.alertType = 'success';
            })
            .addCase(UpdateNotificationSettings.rejected, (state, action) => {
                state.selfSubscriptionUpdate.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.selfSubscriptionUpdate.error = action.payload;
                } else {
                    state.selfSubscriptionUpdate.error = action.error.message;
                }
            })
            // GET TEAM MEMBERS AND EXTERNAL CONTACTS
            .addCase(GetTeamMembersAndContacts.pending, (state, action) => {
                state.error = undefined;
            })
            .addCase(GetTeamMembersAndContacts.fulfilled, (state, action) => {
                state.membersAndContacts = action.payload;
            })
            .addCase(GetTeamMembersAndContacts.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});
export const {
    setSubscriptionUpdateAction,
    setSelfSubscriptionData,
    setNewSubscriberEmail,
    setNewSubscriberPhone,
    setNewSubscriberFullName,
    setNewSubscriberUserId,
    setNewSubscriberRole,
    setNewSubscriberLanguage,
    addNewSubscriber,
    removeNewSubscriber,
    resetNewSubscriberFields,
    resetSubscribe,
    resetUnsubscribe,
    resetSubscriptionUpdate,
    resetSelfSubscriptionUpdate,
    resetNewSubscribers,
    resetClearanceSubscribers,
    clearNewSubscribers
} = clearanceSubscribersSlice.actions;

export default clearanceSubscribersSlice.reducer;
