import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { StatusAlertProps } from '../../common/features/StatusAlert';
import type { ToastSlice } from '../../common/models/ReduxSlices';

const initialState: ToastSlice = {
    toast: null
};

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        sendToast(state, action: PayloadAction<StatusAlertProps>) {
            // if toast already exists, don't add
            if (state.toast) return;

            state.toast = action.payload;
        },
        clearToast(state) {
            state.toast = null;
        }
    }
});

export const { sendToast, clearToast } = toastSlice.actions;

export default toastSlice.reducer;
