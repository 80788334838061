import PropTypes from 'prop-types';
import React, { Component } from 'react';

class ModalConfirm extends Component {
    static defaultProps = {
        isOpen: false,
        CancelButtonText: 'Cancel',
        OkButtonText: 'OK'
    };

    static propTypes = {
        isOpen: PropTypes.bool,
        onRequestClose: PropTypes.func,
        customAction: PropTypes.func,
        onRequestConfirm: PropTypes.func,
        onUserClick: PropTypes.func,
        title: PropTypes.string,
        OkButtonText: PropTypes.string,
        CancelButtonText: PropTypes.string
    };

    deleteButton = () => {
        this.props.onUserClick();
        this.props.onRequestClose();
    };

    onRequestClose = () => {
        if (this.props.customAction !== undefined) {
            this.props.customAction();
        }
    };

    render() {
        return (
            <div className={this.props.isOpen ? 'modal modal-overlay d-block' : 'modal fade d-none'} role='dialog' aria-hidden='false'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h4 className='modal-title text-left'>{this.props.title}</h4>
                        </div>
                        <div className='modal-body'>{this.props.children}</div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-primary' onClick={this.props.onRequestConfirm}>
                                {this.props.OkButtonText}
                            </button>
                            <button type='button' className='btn btn-default' onClick={this.props.onRequestClose}>
                                {this.props.CancelButtonText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalConfirm;
