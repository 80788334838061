import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { LanguageCodes } from '../../common/constants/constants-portal';
import type { LanguageSlice } from '../../common/models/ReduxSlices';

const initialState: LanguageSlice = {
    languageSelected: null
};

const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguageSelected(state, action: PayloadAction<number>) {
            const languageSelectedIndex = action.payload;
            state.languageSelected = LanguageCodes[languageSelectedIndex];
        }
    }
});

export const { setLanguageSelected } = languageSlice.actions;

export default languageSlice.reducer;
