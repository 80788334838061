import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from './Button';
import ModalWrapper from './Wrappers/ModalWrapper';

interface ModalProps {
    id?: number;
    header?: string;
    title: string;
    children?: React.ReactNode;
    toggleModal: (val: boolean) => void;
}

const Modal: React.FC<ModalProps> = ({ id, header, title, children, toggleModal }) => {
    const closeModal = () => {
        toggleModal(false);
    };

    return (
        <ModalWrapper onEscape={closeModal}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h3 className='modal-title' id='staticBackdropLabel'>
                            {header}
                        </h3>
                        <Button
                            variant='tertiary'
                            aria-label='Close'
                            className='modal-close btn-lg'
                            data-dismiss='modal'
                            onClick={closeModal}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    </div>
                    <div className='modal-body'>{title}</div>
                    <div className='modal-footer'>{children}</div>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default Modal;
