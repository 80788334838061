import $ from 'jquery';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import { formatTranslated, sortBy } from '../../../utils/mlp-utils';
import SearchGridRowDetails from './SearchGridRowDetails';

class SearchGridRow extends Component {
    state = {
        showDetails: false
    };

    expandTooltipRef = React.createRef();
    downloadTooltipRef = React.createRef();

    componentDidMount() {
        $(ReactDOM.findDOMNode(this.expandTooltipRef.current)).tooltip({
            title: formatTranslated('Expand', this.props.translations.Phrases),
            placement: 'top'
        });
        $(ReactDOM.findDOMNode(this.downloadTooltipRef.current)).tooltip({
            title: formatTranslated('Download', this.props.translations.Phrases),
            placement: 'top'
        });
    }

    toggleDetails = () => {
        this.setState((prevState) => {
            return { showDetails: !prevState.showDetails };
        });
    };

    showDetailsIconClass = () => {
        let elementClass = ['fa', 'fa-lg'];
        if (this.state.showDetails) {
            elementClass.push('fa-minus-square-o');
        } else {
            elementClass.push('fa-plus-square-o');
        }
        return elementClass.join(' ');
    };

    selectAll = (e) => {
        this.props.selectDocs(e.target.checked, this.props.groupIndex, -1);
    };

    render() {
        const rowDetails = this.state.showDetails ? <SearchGridRowDetails {...this.props} /> : null;
        let data = [];
        this.props.entry['metadata'].forEach((x) => {
            if (x.isVisibleOnGrid) {
                data.push({ ...x });
            }
        });
        data = sortBy(data, 'gridOrder');

        const width = 85 / data.length;
        const param = JSON.stringify(data);

        const rowData = data.map((item, i) => {
            let tempItemValue;
            let styleFormatEntryNo = null;
            switch (item.attribute) {
                case '_entryNumber':
                    item.value = item.value.replace('-', ' ');
                    styleFormatEntryNo = {
                        width: '80px'
                    };
                    break;
                case '_transactionNumber':
                    item.value = item.value.slice(0, 5) + ' ' + item.value.slice(5);
                    break;
                case 'VADOCTYPE':
                    tempItemValue = this.props.translations.Phrases[item.value];
                    break;
                default:
                    break;
            }

            const url = '/FreightTracker/FreightTracker/ShipmentDetail?referenceNumber=';
            let content = '';
            if (i === 0 && item.attribute === 'AffiliatedReferenceNumber') {
                content = <Link to={url + param}>{item.value}</Link>;
            } else if (i === 0) {
                content = (
                    <Link
                        to='#'
                        onClick={() => this.props.onViewDetails({ index: this.props.groupIndex }, this.props.gridModel, this.props.history)}
                    >
                        {item.value}
                    </Link>
                );
            } else if (i === 6 && item.attribute === 'VADOCTYPE') {
                content = tempItemValue;
            } else {
                content = item.value;
            }
            return (
                <div className='mlp-table-cell' style={{ width: width + '%' }} key={i}>
                    <span style={styleFormatEntryNo}>{content}</span>
                </div>
            );
        });

        let selectedRowClass = '';

        if (this.props.entry.isSelected) {
            selectedRowClass = 'row mlp-table-row selected-row';
        } else {
            selectedRowClass = 'row mlp-table-row';
        }

        return (
            <div>
                <div className={selectedRowClass}>
                    <div className='mlp-table-cell'>
                        <span>
                            <input
                                type='checkbox'
                                disabled={this.props.selectedLimitReached && !this.props.entry.isSelected}
                                onChange={(e) => this.selectAll(e)}
                                checked={this.props.entry.isSelected}
                            />
                        </span>
                    </div>
                    {rowData}
                    <div className='mlp-table-cell'>
                        <span onClick={(e) => actions.downloadDocuments(false, this.props.groupIndex, '*', this.props.gridModel, e)}>
                            <i ref={this.downloadTooltipRef} className='fa fa-arrow-circle-o-down fa-lg'></i>
                        </span>
                    </div>
                    <div className='mlp-table-cell'>
                        <span onClick={this.toggleDetails}>
                            <i ref={this.expandTooltipRef} className={this.showDetailsIconClass()}></i>
                        </span>
                    </div>
                </div>
                {rowDetails}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        gridModel: state.imaging.model.GridModel
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectDocs: (checked, groupIndex, docIndex) => dispatch(actions.selectImageDocs(checked, groupIndex, docIndex)),
        onViewDetails: (data, entries, history) => dispatch(actions.viewDetails(data, entries, history))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchGridRow));
