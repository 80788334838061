import { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';

class FeatureSelector extends Component {
    selectFeature = (featureId) => {
        if (this.props.feature.IsSelected) {
            this.props.removeFeature(this.props.countryIndex, this.props.parentIndex, featureId, this.props.area);
        } else {
            this.props.addFeature(this.props.countryIndex, this.props.parentIndex, featureId, this.props.area);
        }
    };

    render() {
        return (
            <div className='col-sm-12'>
                <label className='pull-left'>{this.props.feature.Name}</label>
                <div className='pull-right'>
                    <input
                        type='checkbox'
                        readOnly={true}
                        checked={this.props.feature.IsSelected}
                        onClick={this.selectFeature.bind(this, this.props.feature.FeatureId)}
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        removeFeature: (countryIndex, parentIndex, featureId, area) =>
            dispatch(actionCreators.removeFeature(countryIndex, parentIndex, featureId, area)),
        addFeature: (countryIndex, parentIndex, featureId, area) =>
            dispatch(actionCreators.addFeature(countryIndex, parentIndex, featureId, area))
    };
};

export default connect(null, mapDispatchToProps)(FeatureSelector);
