import { Component } from 'react';
import { connect } from 'react-redux';
import { dashboard } from '../../constants/mlp-constants';
import WidgetContent from './WidgetContent';
import WidgetContentPortal from './WidgetContentPortal';

class Widget extends Component {
    handleMoveWidget = () => {
        // mlp.dashboard.dispatchers.dashboardDispatcher.emit('handleMoveWidget', [this.propssvg.data]);
        //looks like this is not used. Double check when you are able to test
    };

    render() {
        const vm = this.props.data;
        const widgHeight = vm.Widget.Height * this.props.rowHeight + (vm.Widget.Height - 1) * dashboard.WIDGET_SPACING;
        const widgWidth = vm.Widget.Width * this.props.colWidth + (vm.Widget.Width - 1) * dashboard.WIDGET_SPACING;
        const clientIds = this.props.clientNumbers;

        const divStyle = {
            width: widgWidth,
            height: widgHeight
        };

        const divStyleDef = {
            width: widgWidth + dashboard.WIDGET_SPACING / 2,
            height: widgHeight + dashboard.WIDGET_SPACING,
            marginLeft: dashboard.WIDGET_SPACING / 2
        };

        let content = <div />;
        if (this.props.data.Widget.ContentType === dashboard.CONTENT_TYPE_IFRAME) {
            content = (
                <div style={divStyle} className='widget'>
                    <WidgetContent data={this.props.data.Widget} widgetParams={this.props.widgetParams} user={this.props.user} />
                </div>
            );
        } else {
            const selectedIds = [];
            clientIds.forEach((x, i) => {
                if (x.IsSelected === true) {
                    selectedIds.push(x.Number + x.Caption);
                }
            });
            content = (
                <div style={divStyle} className='widget'>
                    <WidgetContentPortal
                        widgetParams={this.props.widgetParams}
                        style={divStyle}
                        data={this.props.data.Widget}
                        user={this.props.user}
                        widget={this.props.data}
                        clientids={selectedIds}
                    />
                </div>
            );
        }

        return (
            <li
                key={this.props.data.Widget.Id}
                data-sort={this.props.data.SortOrder}
                id={this.props.data.WidgetPlacementID}
                className='ui-state-default'
                style={divStyleDef}
            >
                {content}
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(Widget);
