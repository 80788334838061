import $ from 'jquery';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { dashboard } from '../../constants/mlp-constants';
import { formatTranslated } from '../../utils/mlp-utils';

class WidgetContentPortal extends Component {
    expandTooltipRef = React.createRef();

    componentDidMount = () => {
        $(ReactDOM.findDOMNode(this.expandTooltipRef.current)).tooltip({
            title: formatTranslated('ViewReport', this.props.translations.Phrases),
            placement: 'top'
        });
    };

    openFullReport = (widgetDivId) => {
        const widgetPlacement = this.props.model.WidgetPlacementViewModels.find((vm) => vm.Widget.WidgetMapping === widgetDivId);

        let alternateWidgetUrl = widgetPlacement.Widget.PopOutUrl + this.createWidgetParams();
        const openFullScreen = widgetPlacement.Widget.RenderType === dashboard.RENDER_TYPE_TAB;
        if (!openFullScreen) {
            alternateWidgetUrl = dashboard.FULL_REPORT_URL + widgetPlacement.Widget.Id;
        }

        window.open(alternateWidgetUrl);
    };

    createWidgetParams = () => {
        const clientNumbers = this.props.model.ClientServiceSelection.ClientServices.filter((item) => {
            return item.IsSelected;
        });
        let parameters = dashboard.WIDGET_CLIENT_NUMBER_PARAM;
        if (clientNumbers.length === 0) {
            parameters = '';
        } else {
            const clientNumberParams = clientNumbers.map((clientNumber, i) => {
                return clientNumber.FullNumber;
            });
            parameters += clientNumberParams.join(';');
        }
        return parameters;
    };

    render() {
        const widget = this.props.data;
        const selectedClientIds = this.props.clientids;

        let reportLink = <div />;

        switch (widget.RenderType) {
            case dashboard.RENDER_TYPE_TAB:
            case dashboard.RENDER_TYPE_LINK:
                reportLink = (
                    <div
                        className='widget-popout'
                        style={{ right: 0, position: 'absolute', marginRight: '10px', bottom: '10px', cursor: 'pointer' }}
                        onClick={() => this.openFullReport(widget.WidgetMapping)}
                    >
                        <i ref={this.expandTooltipRef} className='fa fa-external-link-square fa-2x' />
                    </div>
                );
                break;
            case dashboard.RENDER_WIDGET_ONLY:
                break;
            default:
                break;
        }

        let allOptions = [];
        for (let key in widget.WidgetDisplayOptions) {
            if (widget.WidgetDisplayOptions.hasOwnProperty(key)) {
                allOptions.push({
                    Value: key,
                    Name: key,
                    TranslatedName: this.props.translations.Phrases['WidgetDisplayOption_' + key],
                    IsSelected: widget.WidgetDisplayOptions[key]
                });
            }
        }
        const widgetId = 'WidgetContent_' + widget.Id;

        let vm = {
            WidgetTitle: widget.Name,
            WidgetContentId: widget.WidgetContentId,
            WidgetDivId: widget.WidgetMapping,
            clientIds: selectedClientIds,
            ContentId: 'content',
            colors: ['#b5bac0', '#004e9c', '#fcb414', '#485865', '#2b80c2', '#464646'],
            allOptions: allOptions,
            initialData: widget.Data ? JSON.parse(widget.Data) : undefined
        };

        vm = JSON.stringify(vm);

        const countryType = widget.WidgetMapping.indexOf('US') !== -1 ? 'us' : 'ca';
        const divId = widget.WidgetMapping.replace('_US', '').replace('_CA', '');

        return (
            <div style={{ backgroundColor: '#fff' }} key={widgetId}>
                <div
                    className='widget-content'
                    data-country={countryType}
                    id={divId}
                    style={this.props.style}
                    data-widgetid={widgetId}
                    data-model={vm}
                ></div>
                <div>{reportLink}</div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        model: state.dashboard.model,
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(WidgetContentPortal);
