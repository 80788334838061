import { usePortalSelector } from '../../../redux/hooks';
import { createDateForAlert } from '../../functions/parser';
import useTranslationHydration from '../../hooks/useTranslationHydration';
import { DownloadLobbyItem } from '../../models/ReduxSlices';
import Badge, { BadgeProps } from '../Badge';
import Button from '../Button';

export interface DownloadLobbyDrawerCardProps extends DownloadLobbyItem {
    handleClose?: () => void;
}

const DownloadLobbyDrawerCard: React.FC<DownloadLobbyDrawerCardProps> = ({
    imageUrl,
    inProgress,
    createdOn,
    documentCount,
    entryNumbers,
    packageSource,
    handleClose,
    hasError
}) => {
    const { languageSelected } = usePortalSelector((state) => state.language);

    const translate = useTranslationHydration();

    const titleLabel = hasError
        ? translate('DownloadLobbyErrorTitle_Label')
        : inProgress
        ? translate('DownloadLobbyTitle_Label')
        : translate('DownloadReady_Label');

    const descLabel = hasError
        ? translate('DownloadLobbyDesc4_Label') // Error desc
        : inProgress
        ? translate('DownloadLobbyDesc_Label') // Loading
        : entryNumbers.length
        ? translate('DownloadLobbyDesc2_Label', entryNumbers.length, documentCount) // Documents download
        : translate('DownloadLobbyDesc3_Label'); // Header/Line download

    const badgeInfo: { type: BadgeProps['type']; text: string } = hasError
        ? { type: 'danger', text: translate('ProcessingError_Label') }
        : inProgress
        ? { type: 'info', text: translate('ProcessingRequest_Label') }
        : { type: 'success', text: translate('ProcessingComplete_Label') };

    return (
        <div className='alert-card bg-white p-1 my-2'>
            <div className='card-content d-flex flex-column align-items-start p-3'>
                <header className='d-flex w-100 flex-fill justify-content-between'>
                    {/* Badge */}
                    <Badge className='mb-2' type={badgeInfo.type}>
                        {badgeInfo.text}
                    </Badge>
                    {/* Date */}
                    <p
                        className='alert-card-date m-0'
                        style={{ marginBottom: '0.5rem', marginLeft: '1rem', position: 'relative', top: '0px', right: '0px' }}
                    >
                        {createDateForAlert(createdOn, languageSelected?.locale)}
                    </p>
                </header>
                {/* Title */}
                <p className='alert-card-title mb-2' style={{ paddingRight: 0 }}>
                    <strong>{titleLabel}</strong>
                </p>
                {/* Description */}
                <p className='alert-card-desc mb-0'>{packageSource === 'SearchShipmentClearances' && !hasError ? '' : descLabel}</p>
                {/* Download button */}
                {hasError ? null : inProgress ? (
                    <Button variant='tertiary-blue' className='mt-3' onClick={handleClose}>
                        {translate('DownloadLobbyButton_Label')}
                    </Button>
                ) : (
                    <a href={imageUrl} target='_blank' rel='noopener noreferrer' className='btn btn-tertiary-blue mt-3'>
                        {translate('DownloadButton_Label')}
                    </a>
                )}
            </div>
        </div>
    );
};

export default DownloadLobbyDrawerCard;
