import { createGenericSearchFilterSlice } from '../../../../redux/reducers/genericSearchFilterSlice';

const teamSearchFilterSlice = createGenericSearchFilterSlice({
    name: 'teamSearchFilter',
    initialState: { searchTerm: '' },
    reducers: {}
});

export const { setSearchFilter, clearSearchFilter } = teamSearchFilterSlice.actions;

export default teamSearchFilterSlice.reducer;
