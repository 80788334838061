import produce from 'immer';
import * as actionTypes from '../actions/brpReports/brpReportsActionTypes';

const initialState = {
    model: {}
};

const setBRPReports = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.model;
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_BRP_REPORTS:
            return setBRPReports(state, action);
        default:
            return state;
    }
};

export default reducer;
