import { Grid, GridColumn, GridColumnMenuFilter, GridNoRecords } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default/dist/all.css';
import produce from 'immer';
import $ from 'jquery';
import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import toastr from 'toastr';
import axios from '../../axios-mlp';
import ChooserControl from '../../common/ClientChooser/ChooserControl';
import LocationFIlter from '../../common/Filters/LocationFIlter';
import MainSearchFilter from '../../common/Filters/MainSearchFilter';
import MeasuresFilter from '../../common/Filters/MeasuresFilter';
import MultipleWildcardFilter from '../../common/Filters/MultipleWildcardFilter';
import PartiesFilter from '../../common/Filters/PartiesFilter';
import PartsFilter from '../../common/Filters/PartsFilter';
import PrimaryDateFilter from '../../common/Filters/PrimaryDateFilter';
import { filters as Constants } from '../../constants/mlp-constants';
import * as actions from '../../store/actions/index';
import { formatTranslated, getSearchTypesBySelectedServices } from '../../utils/mlp-utils';
import CustomColumnMenu from './CustomColumnMenu';
import DetailComponent from './DetailComponent';

function EntryNumberCell(props) {
    const url = props.entryNumberUrl + props.dataItem.OSCode + '-' + props.dataItem.FileNo;
    return (
        <td>
            <a href={url} target='_blank' rel='noopener noreferrer'>
                {props.dataItem['_entryNumber']}
            </a>
        </td>
    );
}

function StatusCell(props) {
    let statusLabel;
    if (props.dataItem.ISFAcceptDt) {
        statusLabel = 'ISFAcceptDt';
    }
    if (props.dataItem.DocumentReceiveDt) {
        statusLabel = 'DocumentReceiveDt';
    }
    if (props.dataItem.CustomsCertDt) {
        statusLabel = 'CustomsCertDt';
    }
    if (props.dataItem.CustomsReleaseDt) {
        statusLabel = 'CustomsReleaseDt';
    }
    if (props.dataItem.EntSummAcceptDt) {
        statusLabel = 'EntSummAcceptDt';
    }
    return (
        <td>
            <span className='status-icons'>
                <i
                    className={props.dataItem && props.dataItem.ISFAcceptDt ? 'fa fa-square fa-lg ' : 'fa fa-square-o fa-lg '}
                    aria-hidden='true'
                    title={props.translations.Phrases['ISFAcceptDt_Label']}
                ></i>
                <i
                    className={props.dataItem && props.dataItem.DocumentReceiveDt ? 'fa fa-square fa-lg ml-1' : 'fa fa-square-o fa-lg ml-1'}
                    aria-hidden='true'
                    title={props.translations.Phrases['DocumentReceiveDt_Label']}
                ></i>
                <i
                    className={props.dataItem && props.dataItem.CustomsCertDt ? 'fa fa-square fa-lg ml-1' : 'fa fa-square-o fa-lg ml-1'}
                    aria-hidden='true'
                    title={props.translations.Phrases['CustomsCertDt_Label']}
                ></i>
                <i
                    className={props.dataItem && props.dataItem.CustomsReleaseDt ? 'fa fa-square fa-lg ml-1' : 'fa fa-square-o fa-lg ml-1'}
                    aria-hidden='true'
                    title={props.translations.Phrases['CustomsReleaseDt_Label']}
                ></i>
                <i
                    className={props.dataItem && props.dataItem.EntSummAcceptDt ? 'fa fa-square fa-lg ml-1' : 'fa fa-square-o fa-lg ml-1'}
                    aria-hidden='true'
                    title={props.translations.Phrases['EntSummAcceptDt_Label']}
                ></i>
            </span>
            <span className='status-message'>{props.translations.Phrases[statusLabel + '_Label']}</span>
        </td>
    );
}

function ModeOfTransportCell(props) {
    const style = {
        backgroundImage: `url(/MOT_Icons/${props.dataItem.ModeTransportCode}.png)`
    };
    return (
        <td>
            <div className='modetransport-icon' style={style}></div>
            <div className='modetransport-code'>{props.dataItem.ModeTransportCode}</div>
        </td>
    );
}

class SearchShipmentForm extends Component {
    state = {
        filtersVisible: false,
        dataState: {
            sort: [],
            filter: {
                logic: 'and',
                filters: []
            },
            skip: 0,
            take: this.props.model.NumberOfResultsToLoad,
            filterable: false,
            pageable: false,
            groupable: false,
            resizable: false,
            pageSize: this.props.model.NumberOfResultsToLoad,
            reorderable: false,
            columnVirtualization: false,
            expandField: 'expanded',
            selectedField: 'selected',
            className: 'table-borderless'
        },
        currencyFields: ['EnteredValueForDuty', 'MLPHMF', 'MLPMPF'],
        columns: [],
        gridData: undefined,
        selectedData: [],
        selectAll: false,
        columnsExpanded: false,
        previousModelState: {}
    };

    postDelayTimer = null;

    componentDidMount() {
        this.props.onInitTranslations('SearchShipment.SearchShipment.Index');
        this.props.onInitSearchShipment();
    }

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
        if (this.props.shouldInitSearchShipment) {
            this.props.onInitSearchShipment();
        }
        if (this.props.shouldInitModel) {
            this.props.onInitSearchShipmentModel(this.props.translations);
        }
        if (this.props.shouldSubmitSearchShipment) {
            if (this.state.previousModelState !== this.props.model) {
                this.setState({ previousModelState: this.props.model });
                this.props.onSubmitSearchShipment(this.props.model);
            }
        }
        if (this.props.shouldUpdateDataSource) {
            this.setColumns();
            this.setGridData();
            this.props.onResetUpdateDataSourceFlag();
        }
    }

    componentWillUnmount() {
        this.props.onClearClientServices();
        this.props.onResetStateSearchShipment();
    }

    setColumns = () => {
        if (!this.state.columns || this.state.columns.length === 0) {
            const columns = this.props.model.ReturnFields.map((field) => {
                const title =
                    field === 'DutyTot'
                        ? this.props.translations.Phrases[field + 'MainGrid_Label']
                        : this.props.translations.Phrases[field + '_Label'];
                const width = field === '_entryNumber' || field === 'ModeTransportCode' ? '200px' : this.props.model.ColumnWidth[field];
                if (field === 'PieceCount' || this.state.currencyFields.indexOf(field) !== -1 || field.endsWith('Tot')) {
                    const format = field === 'PieceCount' ? undefined : '{0:c}';
                    return {
                        title: title,
                        field: field,
                        width: width,
                        headerClassName: 'grid-column-header',
                        filter: 'numeric',
                        format: format,
                        className: 'grid-column-right',
                        show: true
                    };
                } else if (field.endsWith('Dt')) {
                    return {
                        title: title,
                        field: field,
                        width: width,
                        headerClassName: 'grid-column-header',
                        filter: 'date',
                        format: '{0:yyyy-MM-dd HH:mm:ss}',
                        show: true
                    };
                } else {
                    return { title: title, field: field, width: width, headerClassName: 'grid-column-header', filter: 'text', show: true };
                }
            });
            columns.splice(1, 0, { title: this.props.translations.Phrases['Status_Label'], field: 'Status', width: '200px', show: true });
            columns.unshift({ title: 'Select All', field: 'SelectAll', show: true });
            columns.pop();
            this.setState({ columns: columns });
        }
    };

    toggleColumn = (index) => {
        let columns;
        if (index === 0) {
            const show = !this.state.columns[index].show;
            columns = this.state.columns.map((col) => {
                if (col.field === '_entryNumber') {
                    return {
                        ...col,
                        show: true
                    };
                } else {
                    return {
                        ...col,
                        show: show
                    };
                }
            });
            setTimeout(() => {
                actions.saveColumnVisibility('Select All', show, this.props.translations);
            }, 10);
        } else {
            columns = this.state.columns.map((column, i) => {
                return i === index ? { ...column, show: !column.show } : column;
            });
            setTimeout(() => {
                actions.saveColumnVisibility(this.state.columns[index].field, !this.state.columns[index].show, this.props.translations);
            }, 10);
        }
        this.setState({ columns, columnsExpanded: true });
    };

    onMenuItemClick = (event) => {
        event.preventDefault();
        this.setState({ columnsExpanded: true });
    };

    closeMenu = (event) => {
        if (
            this.state.columnsExpanded &&
            event.target.className !== 'k-columnmenu-item ' &&
            event.target.className !== 'k-icon k-i-columns' &&
            event.target.className !== 'k-checkbox' &&
            event.target.className !== 'k-checkbox-label' &&
            event.target.className !== 'k-input' &&
            event.target.className !== 'k-textbox' &&
            event.target.className !== 'k-button' &&
            event.target.className !== 'k-columnmenu-actions' &&
            event.target.className !== 'k-item k-state-selected'
        ) {
            this.setState({ columnsExpanded: false });
            $('.k-grid-columnmenu-popup').hide('slow');
        }
    };

    setGridData = () => {
        const moreData = this.props.model.Shipments.map((dataItem) =>
            Object.assign({ selected: false }, { expanded: false }, { details: {} }, { detailTabIndex: 0 }, dataItem)
        );
        if (!this.state.gridData || this.props.type === 'filter') {
            const gridData = [];
            this.setState({ gridData: gridData.concat(moreData) });
        } else {
            this.setState({ gridData: this.state.gridData.concat(moreData) });
        }
    };

    toggleFilters = () => {
        this.setState((prevState) => {
            return { filtersVisible: !prevState.filtersVisible };
        });
    };

    getHeaderContent = (searchFilters) => {
        const availableSearchTypes = getSearchTypesBySelectedServices(this.props.model);
        const isCASelected = this.props.model.ClientServiceSelection.Countries.find((item) => item.Code === 'ca').IsSelected;
        if (!availableSearchTypes || availableSearchTypes.length <= 0) {
            return (
                <div className='row'>
                    <div className='col-sm-12 text-center'>
                        <h3>{isCASelected ? 'This feature is not yet available for Canada' : 'There are no selected clients'}</h3>
                    </div>
                </div>
            );
        }

        return (
            <div className='row'>
                <div className='col-sm-12'>{searchFilters[Constants.SEARCH_SECTION_MAIN]}</div>
            </div>
        );
    };

    getInitialMainContent = () => {
        const availableSearchTypes = getSearchTypesBySelectedServices(this.props.model);
        if (!availableSearchTypes || availableSearchTypes.length <= 0) {
            return <div></div>;
        }
        return (
            <div className='row'>
                <div className='col-sm-12 text-center'>
                    <h3>Please select a search type</h3>
                </div>
            </div>
        );
    };

    dataStateChange = (event) => {
        if (event.dataState) {
            this.setState({ dataState: event.dataState });
        }
    };

    columnProps(column) {
        const active = this.isColumnActive(column.field, this.state.dataState) ? 'active' : '';
        return {
            title: column.title,
            field: column.field,
            width: column.width,
            filter: column.filter,
            headerClassName: column.headerClassName + ' ' + active
        };
    }

    isColumnActive(field, dataState) {
        return GridColumnMenuFilter.active(field, dataState.filter);
    }

    expandChange = (event) => {
        const data = produce(this.state.gridData, (draft) => {
            draft[event.dataIndex].expanded = event.value;
        });
        this.setState({ gridData: data });
    };

    setShipmentDetails = (type, result, entryNumber) => {
        const data = produce(this.state.gridData, (draft) => {
            const expandedItem = draft.find((item) => item._entryNumber === entryNumber);
            expandedItem.details[type] = result;
            if (expandedItem.selected === true) {
                expandedItem.details.images?.forEach((item) => (item.selected = true));
            }
        });

        this.setState({ gridData: data });
    };

    scrollHandler = (event) => {
        const e = event.nativeEvent;
        if (
            e.target.scrollTop + this.props.model.NumberOfResultsToLoad >= e.target.scrollHeight - e.target.clientHeight &&
            this.state.gridData.length > 1 &&
            this.props.model.TotalLoadedResults < this.props.model.TotalHits
        ) {
            this.props.onLoadShipments();
        }
    };

    filterChange = (event) => {
        this.setState({ dataState: { ...this.state.dataState, filter: event.filter }, selectedData: [] });
        this.props.onSetGridFilters(event.filter);
    };

    sortChange = (event) => {
        this.setState({ dataState: { ...this.state.dataState, sort: event.sort }, selectedData: [] });
        this.props.onSetSortParams(event.sort);
    };

    selectionChange = (event) => {
        const gridData = produce(this.state.gridData, (draft) => {
            const item = draft.find((item) => item._entryNumber === event.dataItem._entryNumber);
            item.selected = !event.dataItem.selected;
            if (item.details.images && item.details.images.length > 0) {
                item.details.images.forEach((image) => {
                    image.selected = !event.dataItem.selected;
                });
            }
        });

        this.setState({ gridData });
        this.setSelectedData(gridData);
    };

    headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        const gridData = produce(this.state.gridData, (draft) => {
            draft.forEach((item) => {
                item.selected = checked;
                if (item.details.images && item.details.images.length > 0) {
                    item.details.images.forEach((image) => {
                        image.selected = checked;
                    });
                }
            });
        });
        this.setState({ gridData: gridData, selectAll: checked });
        this.setSelectedData(gridData);
    };

    imageSelectionChange = (entryNumber, pid, checked) => {
        const gridData = produce(this.state.gridData, (draft) => {
            const item = draft.find((item) => item._entryNumber === entryNumber);
            const image = item.details.images.find((image) => image._pid === pid);
            image.selected = checked;
            if (checked) {
                if (!item.selected) {
                    item.selected = checked;
                }
            } else {
                if (!item.details.images.some((image) => image.selected)) {
                    item.selected = checked;
                }
            }
        });

        this.setState({ gridData });
        this.setSelectedData(gridData);
    };

    imageHeaderSelectionChange = (entryNumber, checked) => {
        const gridData = produce(this.state.gridData, (draft) => {
            const item = draft.find((item) => item._entryNumber === entryNumber);
            item.details.images.forEach((image) => {
                image.selected = checked;
            });
            item.selected = checked;
        });

        this.setState({ gridData });
        this.setSelectedData(gridData);
    };

    selectDetailTab = (entryNumber, selected) => {
        const gridData = produce(this.state.gridData, (draft) => {
            const item = draft.find((item) => item._entryNumber === entryNumber);
            item.detailTabIndex = selected;
        });
        this.setState({ gridData });
    };

    setSelectedData = (gridData) => {
        const selectedData = gridData.filter((item) => item.selected);
        this.setState({ selectedData: selectedData });
    };

    onColumnsSubmit = (columnsState) => {
        this.setState({
            columns: columnsState,
            columnsExpanded: true
        });
    };

    onFilterShipments = () => {
        clearTimeout(this.postDelayTimer);
        this.postDelayTimer = setTimeout(() => {
            this.filterShipments();
        }, 700);
    };

    filterShipments = () => {
        this.setState({ selectedData: [] });
        this.props.filterShipments();
    };

    clearFilters = () => {
        this.setState({ selectedData: [] });
        this.props.onClearFilters();
    };

    clearDateFilters = () => {
        this.setState({ selectedData: [] });
        this.props.onClearDateFilters();
    };

    columnGeneration = () => {
        return this.state.columns
            .filter((column) => column.show)
            .map((column, index) => {
                switch (column.field) {
                    case 'SelectAll':
                        return null;
                    case '_entryNumber':
                        return (
                            <GridColumn
                                key={index}
                                {...this.columnProps(column)}
                                cell={(props) => <EntryNumberCell {...props} entryNumberUrl={this.props.model.EntryNumberUrl} />}
                                columnMenu={(props) => (
                                    <CustomColumnMenu
                                        {...props}
                                        columns={this.state.columns}
                                        columnsExpanded={this.state.columnsExpanded}
                                        onMenuItemClick={this.onMenuItemClick}
                                        onToggleColumn={this.toggleColumn}
                                        onColumnsSubmit={this.onColumnsSubmit}
                                    />
                                )}
                            />
                        );
                    case 'Status':
                        return (
                            <GridColumn
                                key={index}
                                {...this.columnProps(column)}
                                filterable={false}
                                sortable={false}
                                cell={(props) => <StatusCell {...props} translations={this.props.translations} />}
                                columnMenu={(props) => (
                                    <CustomColumnMenu
                                        {...props}
                                        columns={this.state.columns}
                                        columnsExpanded={this.state.columnsExpanded}
                                        onMenuItemClick={this.onMenuItemClick}
                                        onToggleColumn={this.toggleColumn}
                                        onColumnsSubmit={this.onColumnsSubmit}
                                    />
                                )}
                            />
                        );
                    case 'ModeTransportCode':
                        return (
                            <GridColumn
                                key={index}
                                {...this.columnProps(column)}
                                cell={(props) => <ModeOfTransportCell {...props} translations={this.props.translations} />}
                                columnMenu={(props) => (
                                    <CustomColumnMenu
                                        {...props}
                                        columns={this.state.columns}
                                        columnsExpanded={this.state.columnsExpanded}
                                        onMenuItemClick={this.onMenuItemClick}
                                        onToggleColumn={this.toggleColumn}
                                        onColumnsSubmit={this.onColumnsSubmit}
                                    />
                                )}
                            />
                        );
                    default:
                        return (
                            <GridColumn
                                key={index}
                                {...this.columnProps(column)}
                                className={column.className}
                                format={column.format}
                                columnMenu={(props) => (
                                    <CustomColumnMenu
                                        {...props}
                                        columns={this.state.columns}
                                        columnsExpanded={this.state.columnsExpanded}
                                        onMenuItemClick={this.onMenuItemClick}
                                        onToggleColumn={this.toggleColumn}
                                        onColumnsSubmit={this.onColumnsSubmit}
                                    />
                                )}
                            />
                        );
                }
            });
    };

    _export;
    _grid;

    downloadImages = () => {
        let fileNames = {};
        let entryNums = {};
        let docCnt = 0;
        this.state.selectedData.forEach((item) => {
            if (item.details.images && item.details.images.length > 0) {
                fileNames[item._entryNumber] = [];
                item.details.images.forEach((image) => {
                    if (image.selected) {
                        fileNames[item._entryNumber].push(image._fileS3Path);
                        docCnt++;
                    }
                });
            } else {
                if (!entryNums['allEntries']) {
                    entryNums['allEntries'] = [];
                }
                entryNums['allEntries'].push(item._entryNumber);
            }
        });

        if (entryNums['allEntries'] || docCnt > 0) {
            const downloadModel = {
                S3ImageFileNames: fileNames,
                EntryNumbers: entryNums,
                ImagePackage: {
                    ImagingPackageTypeID: 1
                }
            };
            actions.downloadImages(downloadModel, this.props.history);
        } else {
            if (this.state.selectedData.length === 0) {
                toastr.warning(this.props.translations.Phrases['NoSelectedEntry_Msg']);
            } else {
                toastr.warning(this.props.translations.Phrases['NoDocumentsToDownload_Msg']);
            }
        }
    };

    exportLineDetails = () => {
        if (this.state.selectedData.length > 0) {
            const params = {
                Shipments: this.state.selectedData,
                Filters: this.props.model.Filters,
                ImageGroupings: this.props.model.ImageGroupings,
                SelectAll: this.state.selectAll
            };
            $('#overlay').show();
            axios
                .post('/SearchShipment/ExportDetail', params)
                .then((response) => {
                    if (this.state.selectAll) {
                        const obj = JSON.parse(response.data);
                        window.open(obj.ImageUrl);
                    } else {
                        const filename = 'SHS_Detail.csv';
                        const a = document.createElement('a');
                        document.body.appendChild(a);
                        a.style.cssText = 'display: none';
                        a.download = filename;
                        if (window.navigator.msSaveOrOpenBlob) {
                            //IE support
                            var fileData = [response.data];
                            var blobObject = new Blob(fileData);
                            $(a).on(() => {
                                window.navigator.msSaveOrOpenBlob(blobObject, filename);
                            });
                            a.click();
                        } else {
                            const blob = new Blob([response.data], { type: 'octet/stream' }),
                                url = window.URL.createObjectURL(blob);
                            a.href = url;
                            a.click();
                            window.URL.revokeObjectURL(url);
                        }
                        if (a.remove) {
                            a.remove();
                        }
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.data.Message === 'No documents') {
                        toastr.error(formatTranslated('Download_Doc_Error_Any_Label', this.props.translations.Phrases));
                    } else {
                        toastr.error(formatTranslated('Download_Doc_Error_Label', this.props.translations.Phrases));
                    }
                })
                .finally(() => {
                    $('#overlay').hide();
                });
        } else {
            toastr.warning(this.props.translations.Phrases['NoSelectedEntry_Msg']);
        }
    };

    exportHeader = () => {
        let filteredColumns = this.state.columns.filter(function (el) {
            return el.field !== 'SelectAll' && el.show;
        });

        let visibleColumns = filteredColumns.map((x) => x.field);

        if (this.state.selectAll) {
            const model = {
                Shipments: this.state.selectedData,
                Filters: this.props.model.Filters,
                ImageGroupings: this.props.model.ImageGroupings,
                SelectAll: this.state.selectAll,
                VisibleColumns: visibleColumns // pass filtered columns to backend
            };
            axios
                .post('/SearchShipment/ExportHeader', model)
                .then((response) => {
                    let imageUrl;
                    if (typeof response.data === 'string') {
                        imageUrl = JSON.parse(response.data).ImageUrl;
                    } else {
                        imageUrl = response.data.ImageUrl;
                    }
                    window.open(imageUrl);
                })
                .catch((error) => {
                    if (error.response && error.response.data.Message === 'No documents') {
                        toastr.error(formatTranslated('Download_Doc_Error_Any_Label', this.props.translations.Phrases));
                    } else {
                        toastr.error(formatTranslated('Download_Doc_Error_Label', this.props.translations.Phrases));
                    }
                })
                .finally(() => {
                    $('#overlay').hide();
                });
        } else {
            if (this.state.selectedData.length > 0) {
                const currencyFormatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                });
                let csvContent = '';
                filteredColumns.forEach((column) => {
                    csvContent += column.title + ',';
                });
                csvContent += '\n';
                this.state.selectedData.forEach((item) => {
                    filteredColumns.forEach((column) => {
                        if (column.field === 'Status') {
                            let statusLabel = '';
                            if (item.ISFAcceptDt) {
                                statusLabel = 'ISFAcceptDt';
                            }
                            if (item.DocumentReceiveDt) {
                                statusLabel = 'DocumentReceiveDt';
                            }
                            if (item.CustomsCertDt) {
                                statusLabel = 'CustomsCertDt';
                            }
                            if (item.CustomsReleaseDt) {
                                statusLabel = 'CustomsReleaseDt';
                            }
                            if (item.EntSummAcceptDt) {
                                statusLabel = 'EntSummAcceptDt';
                            }
                            if (item.CustomsReleaseDtCancelled) {
                                statusLabel = 'CustomsReleaseDtCancelled';
                            }
                            csvContent += '"' + this.props.translations.Phrases[statusLabel + '_Label'] + '",';
                        } else if (column.field.endsWith('Dt')) {
                            if (item[column.field] !== '') {
                                let shipmentDate = new Date(item[column.field]);
                                const dateToBeWritten =
                                    ('0' + shipmentDate.getDate()).slice(-2) +
                                    '/' +
                                    ('0' + (shipmentDate.getMonth() + 1)).slice(-2) +
                                    '/' +
                                    shipmentDate.getFullYear() +
                                    ' ' +
                                    ('0' + shipmentDate.getHours()).slice(-2) +
                                    ':' +
                                    ('0' + shipmentDate.getMinutes()).slice(-2);

                                csvContent += item[column.field] ? dateToBeWritten + ',' : ',';
                            } else {
                                csvContent += ',';
                            }
                        } else if (this.state.currencyFields.indexOf(column.field) !== -1 || column.field.endsWith('Tot')) {
                            csvContent +=
                                item[column.field] !== undefined || item[column.field] !== '' || item[column.field] !== null
                                    ? '"' + currencyFormatter.format(item[column.field]) + '",'
                                    : ',';
                        } else {
                            csvContent += item[column.field] ? '"' + item[column.field] + '",' : ',';
                        }
                    });
                    csvContent += '\n';
                });

                $('#overlay').show();
                // download csv
                const encodedUri = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
                const filename = 'SHS_Header.csv';
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style.cssText = 'display: none';
                a.download = filename;
                if (window.navigator.msSaveOrOpenBlob) {
                    //IE support
                    const fileData = [csvContent];
                    const blobObject = new Blob(fileData, { type: 'text/csv' });
                    $(a).on(() => {
                        window.navigator.msSaveOrOpenBlob(blobObject, filename);
                    });
                    a.click();
                } else {
                    const url = encodedUri;
                    a.href = url;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
                if (a.remove) {
                    a.remove();
                }
                $('#overlay').hide();
            } else {
                toastr.warning(this.props.translations.Phrases['NoSelectedEntry_Msg']);
            }
        }
    };

    updateClientServices = (data) => {
        this.setState({ gridData: undefined, selectedData: [] });
        this.props.onUpdateClientServices(data);
    };

    render() {
        if (this.props.model.SearchResults && this.props.translations.PhraseGroupCode === 'SearchShipment.SearchShipment.Index') {
            let searchFilters = {};
            searchFilters[Constants.SEARCH_SECTION_MAIN] = [];
            searchFilters[Constants.SEARCH_SECTION_DATE] = [];
            searchFilters[Constants.SEARCH_SECTION_LOCATON] = [];
            searchFilters[Constants.SEARCH_SECTION_MEASURES] = [];
            searchFilters[Constants.SEARCH_SECTION_PARTIES] = [];
            searchFilters[Constants.SEARCH_SECTION_PARTS] = [];
            searchFilters[Constants.SEARCH_SECTION_REF_NUMBERS] = [];

            const pageTitle = formatTranslated('ShipmentSearch_PageHeader', this.props.translations.Phrases).split(' ');
            const gridWidth = this.state.filtersVisible ? 'col-sm-9' : 'col-sm-12';
            let mainContent = this.getInitialMainContent();
            let filterSearchContent;

            if (this.state.filtersVisible) {
                filterSearchContent = (
                    <div className={this.state.filtersVisible ? 'd-block col-sm-3' : 'd-none col-sm-3'}>
                        <div className='img-filter-container'>
                            <div className='row header-row'>
                                <h3 className='col-sm-5 row-content'>
                                    {formatTranslated('Filter_Label', this.props.translations.Phrases)}
                                </h3>
                                <div className='col-sm-7 text-right row-content'>
                                    <span className='btn btn-link ' onClick={() => this.clearFilters()}>
                                        {formatTranslated('Clear_Label', this.props.translations.Phrases)}
                                    </span>
                                </div>
                            </div>
                            <hr />
                            <div className='row header-row'>
                                <div className='col-sm-12 row-content d-flex justify-content-end'>
                                    <span className='btn btn-link' onClick={() => this.clearDateFilters()}>
                                        {formatTranslated('Clear_Label', this.props.translations.Phrases)}
                                    </span>
                                </div>
                            </div>
                            {searchFilters[Constants.SEARCH_SECTION_DATE]}
                            {searchFilters[Constants.SEARCH_SECTION_LOCATON]}
                            {searchFilters[Constants.SEARCH_SECTION_MEASURES]}
                            {searchFilters[Constants.SEARCH_SECTION_PARTIES]}
                            {searchFilters[Constants.SEARCH_SECTION_PARTS]}
                            {searchFilters[Constants.SEARCH_SECTION_REF_NUMBERS]}
                        </div>
                    </div>
                );
            }

            const customFilterOperators = {
                text: [{ text: 'grid.filterContainsOperator', operator: 'contains' }],
                numeric: [
                    { text: 'grid.filterGtOperator', operator: 'gt' },
                    { text: 'grid.filterLtOperator', operator: 'lt' }
                ],
                date: [
                    { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
                    { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' }
                ]
            };

            const gridContent =
                this.props.model.SearchTypes.filter((s) => s.CountryCode === 'ca' && s.IsSelected).length === 1 ? (
                    <></>
                ) : (
                    <div id='container' className='shp-search-results-small w-100'>
                        <div id='grid-loader'>
                            <div className='spinner'></div>
                        </div>
                        <div id='grid'>
                            {this.state.gridData ? (
                                <Grid
                                    {...this.state.dataState}
                                    sortable={{
                                        mode: 'multiple',
                                        allowUnsort: true
                                    }}
                                    filterOperators={customFilterOperators}
                                    data={this.state.gridData}
                                    detail={(props) => (
                                        <DetailComponent
                                            {...props}
                                            setShipmentDetails={this.setShipmentDetails}
                                            imageSelectionChange={this.imageSelectionChange}
                                            imageHeaderSelectionChange={this.imageHeaderSelectionChange}
                                            selectDetailTab={this.selectDetailTab}
                                        />
                                    )}
                                    onDataStateChange={this.dataStateChange}
                                    onFilterChange={this.filterChange}
                                    onSortChange={this.sortChange}
                                    onSelectionChange={this.selectionChange}
                                    onHeaderSelectionChange={this.headerSelectionChange}
                                    onScroll={this.scrollHandler}
                                    onExpandChange={this.expandChange}
                                    ref={(grid) => (this._grid = grid)}
                                >
                                    <GridColumn
                                        field='selected'
                                        width='50px'
                                        headerClassName='grid-column-right'
                                        className='grid-column-right'
                                        headerSelectionValue={
                                            this.state.gridData.length > 0 &&
                                            this.state.gridData.findIndex((dataItem) => dataItem.selected === false) === -1
                                        }
                                    />
                                    {this.columnGeneration()}
                                    <GridNoRecords>
                                        <div className='noRecords' id='noRecords'>
                                            {this.props.translations.Phrases['NoDocuments_Label']}
                                        </div>
                                    </GridNoRecords>
                                </Grid>
                            ) : null}
                        </div>
                    </div>
                );

            const searchTypesForCurrentCountry = getSearchTypesBySelectedServices(this.props.model);
            const selectedSearchType = this.props.model.SearchTypes.find((type) => type.IsSelected === true);
            const filterButtonVisible = true;
            if (selectedSearchType) {
                this.props.model.Filters.forEach((f, i) => {
                    let component;
                    switch (f.ControlName) {
                        case 'MultipleWildcardFilter':
                            component = (
                                <div key={i}>
                                    <MultipleWildcardFilter
                                        filterControl={f}
                                        selectedSearchType={selectedSearchType}
                                        searchTypeCode={selectedSearchType.Code}
                                        onOptionSelect={this.props.onFilterOptionSelect}
                                        onValueChanged={this.props.onFilterValueChange}
                                        customAction={() => this.onFilterShipments()}
                                    />
                                </div>
                            );
                            break;
                        case 'MainSearchFilter':
                            component = (
                                <div key={i}>
                                    <MainSearchFilter
                                        filterControl={f}
                                        filterButtonVisible={filterButtonVisible}
                                        selectedSearchType={selectedSearchType}
                                        searchTypeCode={selectedSearchType.Code}
                                        filtersVisible={this.state.filtersVisible}
                                        onToggleSection={this.toggleFilters}
                                        onValueChanged={this.props.onFilterValueChange}
                                        customAction={() => this.onFilterShipments()}
                                    />
                                </div>
                            );
                            break;
                        case 'PrimaryDateFilter':
                            component = (
                                <div key={i}>
                                    <PrimaryDateFilter
                                        filterControl={f}
                                        selectedSearchType={selectedSearchType}
                                        searchTypeCode={selectedSearchType.Code}
                                        onOptionSelect={this.props.onFilterOptionSelect}
                                        onValueChanged={this.props.onFilterValueChange}
                                        customAction={() => this.filterShipments()}
                                    />
                                </div>
                            );
                            break;
                        case 'LocationFilter':
                            component = (
                                <div key={i}>
                                    <LocationFIlter
                                        filterControl={f}
                                        selectedSearchType={selectedSearchType}
                                        searchTypeCode={selectedSearchType.Code}
                                        onOptionSelect={this.props.onFilterOptionSelect}
                                        onValueChanged={this.props.onFilterValueChange}
                                        customAction={() => this.onFilterShipments()}
                                    />
                                </div>
                            );
                            break;
                        case 'MeasuresFilter':
                            component = (
                                <div key={i}>
                                    <MeasuresFilter
                                        filterControl={f}
                                        selectedSearchType={selectedSearchType}
                                        searchTypeCode={selectedSearchType.Code}
                                        onOptionSelect={this.props.onFilterOptionSelect}
                                        onValueChanged={this.props.onFilterValueChange}
                                        customAction={() => this.onFilterShipments()}
                                    />
                                </div>
                            );
                            break;
                        case 'PartiesFilter':
                            component = (
                                <div key={i}>
                                    <PartiesFilter
                                        filterControl={f}
                                        selectedSearchType={selectedSearchType}
                                        searchTypeCode={selectedSearchType.Code}
                                        onOptionSelect={this.props.onFilterOptionSelect}
                                        onValueChanged={this.props.onFilterValueChange}
                                        customAction={() => this.onFilterShipments()}
                                    />
                                </div>
                            );
                            break;
                        case 'PartsFilter':
                            component = (
                                <div key={i}>
                                    <PartsFilter
                                        filterControl={f}
                                        selectedSearchType={selectedSearchType}
                                        searchTypeCode={selectedSearchType.Code}
                                        onOptionSelect={this.props.onFilterOptionSelect}
                                        onValueChanged={this.props.onFilterValueChange}
                                        customAction={() => this.onFilterShipments()}
                                    />
                                </div>
                            );
                            break;
                        default:
                            break;
                    }
                    if (searchFilters[f.Level]) {
                        searchFilters[f.Level].push(component);
                    }
                });

                if (searchTypesForCurrentCountry && searchTypesForCurrentCountry.length > 0) {
                    const totalHits = this.props.model.TotalHits.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    const numberOfDocuments =
                        this.props.model.TotalHits !== '1' ? (
                            <div id='numberOfDocuments'>
                                <p className='numberOfShowed'>
                                    {this.props.translations.Phrases['Showing_Label']} {this.props.model.TotalLoadedResults}{' '}
                                    {this.props.translations.Phrases['Of_Label']} {totalHits}
                                    {this.props.translations.Phrases['Records_Label']}
                                    ,&nbsp;
                                </p>
                                <p className='numberOfSelected'>
                                    {this.state.selectedData.length} {this.props.translations.Phrases['EntriesSelected_Label']}
                                </p>
                            </div>
                        ) : (
                            <div id='numberOfDocuments'>
                                <p className='numberOfShowed'>
                                    {this.props.translations.Phrases['Showing_Label']} {this.props.model.TotalLoadedResults}{' '}
                                    {this.props.translations.Phrases['Of_Label']} {totalHits}
                                    {this.props.translations.Phrases['Record_Label']}
                                    ,&nbsp;
                                </p>
                                <p className='numberOfSelected'>
                                    {this.state.selectedData.length} {this.props.translations.Phrases['EntriesSelected_Label']}
                                </p>
                            </div>
                        );
                    mainContent = (
                        <div className='row shipment-row'>
                            {filterSearchContent}
                            <div className={gridWidth}>
                                <div className='row justify-content-between'>
                                    {numberOfDocuments}
                                    <div className='btn-group'>
                                        <input
                                            type='button'
                                            className='button action-button white'
                                            value={formatTranslated('DownloadImages_Label', this.props.translations.Phrases)}
                                            onClick={this.downloadImages}
                                        />
                                        <button
                                            className='button action-button white dropdown-toggle'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            {formatTranslated('Export_Label', this.props.translations.Phrases)}
                                            <span className='caret'></span>
                                        </button>
                                        <div className='dropdown-menu dropdown-menu-right' aria-labelledby='dropdownMenuButton'>
                                            <button className='dropdown-item' onClick={this.exportHeader}>
                                                {formatTranslated('HeaderExport_Label', this.props.translations.Phrases)}
                                            </button>
                                            <button className='dropdown-item' onClick={this.exportLineDetails}>
                                                {formatTranslated('DetailExport_Label', this.props.translations.Phrases)}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>{gridContent}</div>
                            </div>
                        </div>
                    );
                }
            }

            return (
                <div id='search-shipment' onClick={this.closeMenu}>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.props.translations.Phrases['Page_Title']}</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className='page-wrapper img-page-wrapper'>
                        <div className='page-top-row'>
                            <div className='page-header-section'>
                                <nav className='mlp-breadcrumb'>
                                    <Link to='/Dashboard' className='breadcrumb-parent'>
                                        {formatTranslated('SearchShipment_NavLevel1', this.props.translations.Phrases)}
                                    </Link>
                                    <i className='fa fa-caret-right'></i>
                                    <span className='breadcrumb-parent'>
                                        {formatTranslated('SearchShipment_NavLevel2', this.props.translations.Phrases)}
                                    </span>
                                </nav>
                                <div>
                                    <div className='header-align'>
                                        <h1 className='pull-left bold-title'>
                                            <span>{pageTitle[0]}&nbsp;</span>
                                            <span className='gold-title'>{pageTitle[1]}</span>
                                        </h1>
                                    </div>
                                    <div className='header-align'>
                                        <div className='pull-right'>
                                            <ChooserControl
                                                data={this.props.model.ClientServiceSelection}
                                                groupingAttribute='Number'
                                                onConfirm={this.updateClientServices}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='search-header'>{this.getHeaderContent(searchFilters)}</div>
                            </div>
                        </div>
                        <div className='content-wrapper'>
                            <div className='row page-content'>
                                <div id='shipment-form' className='w-100'>
                                    {mainContent || ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div className='page-wrapper'></div>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        model: state.searchShipment.model,
        exportDetails: state.searchShipment.exportDetails,
        shouldInitSearchShipment: state.searchShipment.shouldInitSearchShipment,
        shouldInitModel: state.searchShipment.shouldInitModel,
        shouldSubmitSearchShipment: state.searchShipment.shouldSubmitSearchShipment,
        shouldUpdateDataSource: state.searchShipment.shouldUpdateDataSource,
        type: state.searchShipment.type
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actions.initTranslations(phraseGroup)),
        onInitSearchShipment: () => dispatch(actions.initSearchShipment()),
        onInitSearchShipmentModel: (translations) => dispatch(actions.initSearchShipmentModel(translations)),
        onSubmitSearchShipment: (model) => dispatch(actions.submitSearchShipment(model)),
        onResetUpdateDataSourceFlag: () => dispatch(actions.resetUpdateDataSourceFlag()),
        onClearFilters: () => dispatch(actions.clearShipmentFilters()),
        onClearDateFilters: () => dispatch(actions.clearShipmentDateFilters()),
        onUpdateClientServices: (data) => dispatch(actions.updateShipmentClientServices(data)),
        onClearClientServices: (data) => dispatch(actions.clearShipmentClientServices()),
        onClearShipmentFilters: () => dispatch(actions.clearShipmentFilters()),
        onFilterOptionSelect: (newIndex, oldIndex, name) => dispatch(actions.shipmentFilterOptionSelect(newIndex, oldIndex, name)),
        onFilterValueChange: (value, name) => {
            const splittedName = name.split('.');
            dispatch(actions.shipmentFilterValueChange(splittedName[2], value, splittedName[1]));
        },
        filterShipments: () => dispatch(actions.filterShipments()),
        onSetSortParams: (sortParams) => dispatch(actions.setSortParams(sortParams)),
        onSetGridFilters: (gridFilter) => dispatch(actions.setGridFilters(gridFilter)),
        onLoadShipments: () => dispatch(actions.loadShipments()),
        onResetStateSearchShipment: () => dispatch(actions.resetStateSearchShipment())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchShipmentForm));
