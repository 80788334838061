import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Link } from 'react-router-dom';
import { appItems } from '../../../constants/constants-portal';
import useTranslationHydration from '../../../hooks/useTranslationHydration';
import classes from './WaffleDraggableItem.module.css';

interface WaffleDraggableItemProps {
    item: { url: string; label: string };
    index: number;
    moveItem: (fromIndex: number, toIndex: number) => void;
    onItemDropped: (draggedItem: DragItem, dropResult: DropResult) => void;
}

interface DragItem {
    index: number;
}

interface DropResult {
    targetIndex: number;
    targetId: number;
}

const ITEM_TYPE = 'CUSTOM_ITEM_TYPE';

const WaffleDraggableItem: React.FC<WaffleDraggableItemProps> = ({ item, index, moveItem, onItemDropped }) => {
    const translate = useTranslationHydration();

    const ref = useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = useDrop<DragItem, DropResult, { handlerId: any }>({
        accept: ITEM_TYPE,
        hover: (item, monitor) => {
            if (!monitor.isOver({ shallow: true })) return;

            if (item.index === index) return;

            moveItem(item.index, index);
            item.index = index;
        }
    });

    const [{ opacity, isDragging }, drag] = useDrag({
        type: ITEM_TYPE,
        item: { index },
        collect: (monitor) => ({
            isDragging: !!monitor.getItem(),
            opacity: monitor.isDragging()
        }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult<DropResult>();
            if (item && dropResult) {
                onItemDropped(item, dropResult);
            }
        }
    });

    drag(drop(ref));

    return (
        <div
            ref={ref}
            className={`${opacity ? classes.invisible : ''} ${isDragging ? classes['disable-hover'] : ''}`}
            data-handler-id={handlerId}
        >
            <Link
                to={{ pathname: item.url }}
                className={`app-link d-flex flex-row justify-content-between align-items-center rounded-sm flex-grow-1 p-2 ${classes.link}`}
                target='_blank'
                rel='noopener noreferrer'
            >
                <div className='d-flex flex-row align-items-center'>
                    <div className='app-icon d-flex flex-column justify-content-center align-items-center shadow-sm flex-shrink-0 mr-2'>
                        <FontAwesomeIcon icon={appItems.filter((appItem) => appItem.Label === item.label)[0]?.Icon} />
                    </div>
                    <div className='app-name flex-shrink-0'>{translate(item.label)}</div>
                </div>
                <div className={`external-link-icon ml-3 ${isDragging ? classes['disable-hover-icon'] : ''}`}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                </div>
            </Link>
        </div>
    );
};

export default WaffleDraggableItem;
