import { useEffect, useState } from 'react';

interface SearchTagsProps {
    inputValue: string;
    onEnterKey?: (payload: { filteredInput: string; filteredTags: string[] }) => void;
}

const useSearchTags = ({ inputValue, onEnterKey }: SearchTagsProps) => {
    const [value, setValue] = useState(inputValue);

    useEffect(() => {
        setValue(inputValue);
    }, [inputValue]);

    const handleTags = (input: string) => {
        // Filter the input tags
        const filteredTags = input
            .replace(/[\s]+/g, '')
            .split(',')
            .filter((value) => value !== '')
            .filter((value, index, self) => self.indexOf(value) === index);

        if (filteredTags.length === 1 && filteredTags[0] === '') {
            return { filteredInput: '', filteredTags: [] };
        }

        return { filteredInput: filteredTags.join(', '), filteredTags };
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Prevent user from entering unwanted characters
        setValue(e.currentTarget.value.replace(/[^\w\s,-]/, '').replace(/,+$/, ','));
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === ',') {
            const tags = handleTags(e.currentTarget.value);
            setValue(tags.filteredInput);
        }
        if (e.key === 'Enter' && onEnterKey) {
            const tags = handleTags(e.currentTarget.value);
            onEnterKey(tags);
        }
    };

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tags = handleTags(e.currentTarget.value);
        setValue(tags.filteredInput.replace(/,$/, ''));
    };

    const handleReset = () => {
        setValue('');
    };

    return {
        value,
        handleChange,
        handleBlur,
        handleKeyDown,
        handleReset,
        handleTags
    };
};

export default useSearchTags;
