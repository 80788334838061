import React, { useEffect, useRef, useState } from 'react';
import { MILESTONES_CA, MILESTONES_US } from '../../../../../../common/constants/constants-portal';
import Button from '../../../../../../common/features/Button';
import Checkbox from '../../../../../../common/features/Checkbox';
import MilestonesSelection from '../../../../../../common/features/MilestonesSelection';
import Tooltip from '../../../../../../common/features/Tooltip/Tooltip';
import useGTMEventTracking from '../../../../../../common/hooks/useGTMEventTracking';
import useTranslation from '../../../../../../common/hooks/useTranslation';
import { Notifications } from '../../../../../../common/models/ResponseData';
import { SubscribeUsers } from '../../../../redux/actions/clearanceSubscribers';
import { useClearancesDispatch, useClearancesSelector } from '../../../../redux/hooks';

interface SingleSubscriberNotificationsProps {
    handlePrevStep: () => void;
    closeModal: () => void;
}

const SingleSubscriberNotifications: React.FC<SingleSubscriberNotificationsProps> = ({ handlePrevStep, closeModal }) => {
    const { shipmentDetails, country } = useClearancesSelector((state) => state.shipmentDetails);
    const { subscribe, newSubscribers } = useClearancesSelector((state) => state.clearanceSubscribers);
    const [notifications, setNotifications] = useState<any>(null);
    const dispatch = useClearancesDispatch();
    const translate = useTranslation();
    const addSubscriberClearancesConfirmRef = useRef(null);
    useGTMEventTracking('Confirm_Subscription_Entry_CLR_CPEvt', { location: 'Clearances' }, addSubscriberClearancesConfirmRef);
    const updateNotifications = (obj: null | Notifications, notification: null | string, value: null | string | boolean) => {
        setNotifications(obj ? { ...notifications!, ...obj } : { ...notifications!, [notification!]: value });
    };

    const handleSubmit = () => {
        const getReqNotifications = (countryTo: string): Notifications[] => {
            const otherNotifications = country === 'ca' ? MILESTONES_US : MILESTONES_CA;
            const milestones = countryTo === country ? notifications : otherNotifications;

            return Object.keys(milestones).map((key, index) => ({
                Name: key,
                Value: index === 0 && countryTo !== country ? false : milestones[key]
            }));
        };

        if (shipmentDetails?.ClientCaptionNumber && shipmentDetails.CustomsInfoID) {
            dispatch(
                SubscribeUsers({
                    name: '',
                    customerNumbers: [shipmentDetails.ClientCaptionNumber],
                    customsInfoId: shipmentDetails.CustomsInfoID,
                    subscribers: newSubscribers.map((subscriber) => {
                        return {
                            CustomerNumber: null,
                            Email: subscriber.emailIsValid ? subscriber.email : '',
                            FullName: subscriber.fullName,
                            IsExternal: subscriber.userId?.includes('EXT') ? true : false,
                            isNew: true,
                            Language: subscriber.language === '-' ? null : subscriber.language,
                            MilestoneNotifications_CA: null,
                            MilestoneNotifications_US: null,
                            Phone: subscriber.phoneIsValid ? subscriber.phone : '',
                            RoleName: subscriber.role,
                            SubscriptionId: null,
                            UserID: subscriber.userId
                        };
                    }),
                    notificationsCA: getReqNotifications('ca'),
                    notificationsUS: getReqNotifications('us'),
                    userId: '',
                    entryTransactionNumber: shipmentDetails?.EntryNumber || ''
                })
            );
        }
        closeModal();
    };

    useEffect(() => {
        setNotifications(country === 'ca' ? MILESTONES_CA : MILESTONES_US);
    }, [country]);

    const onHoldLabel = country === 'ca' ? 'HasNotificationsForClearanceOnHold_CA' : 'HasNotificationsForClearanceOnHold';

    const isSubmitDisabled = () => {
        if (!Object.values(notifications).includes(true)) {
            return true;
        }

        if (subscribe.isLoading) {
            return true;
        }

        return false;
    };

    if (!notifications) return null;

    return (
        <div>
            <div className='modal-body'>
                <form>
                    <div className='d-flex flex-column align-items-start'>
                        <div id='scrollTable'>
                            <span className='text-secondary'>{translate('NoticeMilestonesChange_Label')}</span>
                            <div className='custom-control custom-checkbox mb-1'>
                                <Checkbox
                                    id={'HasNotificationsForClearanceOnHold'}
                                    name={'HasNotificationsForClearanceOnHold'}
                                    isChecked={notifications[onHoldLabel]}
                                    onChange={() => {
                                        updateNotifications(null, onHoldLabel, !notifications[onHoldLabel]);
                                    }}
                                >
                                    <Tooltip label={translate('ShipmentOnHoldNotification_Label')}>
                                        <span>{translate('OnHold_Label')}</span>
                                    </Tooltip>
                                </Checkbox>
                            </div>
                            <p>{translate('NotificationsUncheckedTitle_Label')}</p>
                            <MilestonesSelection
                                country={country}
                                notifications={notifications}
                                showSelectAll={true}
                                showCountry={true}
                                handleMilestonesChange={updateNotifications}
                            />
                        </div>
                    </div>
                </form>
            </div>

            <div className='modal-footer justify-content-center'>
                <Button variant='tertiary-blue' size='large' onClick={() => handlePrevStep()}>
                    {translate('Back_Label')}
                </Button>

                <div style={{ flex: 1 }}></div>

                <Button
                    variant='primary'
                    size='large'
                    ref={addSubscriberClearancesConfirmRef}
                    onClick={handleSubmit}
                    disabled={isSubmitDisabled()}
                >
                    {translate('AddOneOrMultipleSubscribers_Label', newSubscribers.length)}
                </Button>
            </div>
        </div>
    );
};

export default SingleSubscriberNotifications;
