import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { REACT_APP_ASSETS_URL_CSS } from '../../common/constants/constants-portal';
import Protected from '../../common/features/Protected';
import ToastProvider from '../../common/features/Toast/ToastProvider';
import App from './App';
import store from './redux/store';

const Portal = () => {
    return (
        <Provider store={store}>
            <Protected conditions={{ isInternalUser: true }} redirectUrl='/'>
                <ToastProvider>
                    <HelmetProvider>
                        <Helmet>
                            <link rel='stylesheet' href={REACT_APP_ASSETS_URL_CSS} />
                        </Helmet>
                        <App />
                    </HelmetProvider>
                </ToastProvider>
            </Protected>
        </Provider>
    );
};

export default Portal;
