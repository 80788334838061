import React from 'react';
import { REACT_APP_ASSETS_URL } from '../constants/constants-portal';
import useTranslationHydration from '../hooks/useTranslationHydration';

interface ErrorPageProps {
    errorCode?: 403 | 404 | 500 | 501 | number | string;
    withTranslations?: boolean;
}

const ErrorPageWithTranslations: React.FC<ErrorPageProps> = ({ errorCode }) => {
    const translate = useTranslationHydration();
    let imageName = '';
    let altDescription = '';
    let title = '';
    // let description;

    switch (errorCode) {
        case 403:
            title = translate('NotAuthorized_Label');
            // description = (
            //     <>
            //         {translate(
            //             'NotAuthorizedNote_Label',
            //             <span>
            //                 <a href='mailto:portal@livingston.com'>portal@livingston.com</a>
            //             </span>
            //         )}
            //     </>
            // );
            imageName = 'scene-error-403-not-authorized';
            altDescription = '403 not authorized';
            break;
        case 404:
            title = translate('PageNotFound_Label');
            // description = translate('PageNotFoundNote_Label');
            imageName = 'scene-error-404-page-not-found';
            altDescription = '404 page not found';
            break;
        case 500:
            title = translate('SystemFailure_Label');
            // description = (
            //     <>
            //         {translate(
            //             'SystemFailureNote_Label',
            //             <span>
            //                 <a href='mailto:portal@livingston.com'>portal@livingston.com</a>
            //             </span>
            //         )}
            //     </>
            // );
            imageName = 'scene-error-500-system-failure';
            altDescription = '500 system failure';
            break;
        case 501:
            title = translate('SystemFailure_Label');
            // description = translate('ServiceUnavailableNote_Label');
            imageName = 'scene-error-500-system-failure';
            altDescription = '501 service unavailable';
            break;
    }
    return (
        <div className='error-page-container'>
            <div className='error-image'>
                <img src={`${REACT_APP_ASSETS_URL}/assets/images/illustrations/scenes/${imageName}.svg`} alt={altDescription} />
            </div>
            <h1 className='title mb-4'>{title}</h1>
            {/* Commented until further review */}
            {/* <p className='desc'>{description}</p> */}
        </div>
    );
};

const ErrorPageDefault: React.FC<ErrorPageProps> = ({ errorCode }) => {
    let imageName = '';
    let altDescription = '';
    let title = '';
    // let description;

    switch (errorCode) {
        case 403:
            title = 'You don’t have permission to view this page';
            // description = (
            //     <>
            //         Please contact{' '}
            //         <span>
            //             <a href='mailto:portal@livingston.com'>portal@livingston.com</a>
            //         </span>{' '}
            //         to inquire about this issue.
            //     </>
            // );
            imageName = 'scene-error-403-not-authorized';
            altDescription = '403 not authorized';
            break;
        case 404:
            title = 'Page not found';
            // description = (
            //     <>
            //         Try retyping the URL, or{' '}
            //         <span>
            //             <a href='/#'>view your clearances</a>.
            //         </span>
            //     </>
            // );
            imageName = 'scene-error-404-page-not-found';
            altDescription = '404 page not found';
            break;
        case 500:
            title = 'Sorry — something went wrong';
            // description = (
            //     <>
            //         This isn’t working properly right now. Try to refresh the page or email us at{' '}
            //         <span>
            //             <a href='mailto:portal@livingston.com'>portal@livingston.com</a>
            //         </span>
            //     </>
            // );
            imageName = 'scene-error-500-system-failure';
            altDescription = '500 system failure';
            break;
        case 501:
            title = 'Sorry — something went wrong';
            // description = 'The service you requested is temporary unavailable. Try again in a few minutes.';
            imageName = 'scene-error-500-system-failure';
            altDescription = '501 service unavailable';
            break;
    }
    return (
        <div className='error-page-container'>
            <div className='error-image'>
                <img src={`${REACT_APP_ASSETS_URL}/assets/images/illustrations/scenes/${imageName}.svg`} alt={altDescription} />
            </div>
            <h1 className='title mb-4'>{title}</h1>
            {/* Commented until further review */}
            {/* <p className='desc'>{description}</p> */}
        </div>
    );
};

const ErrorPage: React.FC<ErrorPageProps> = ({ errorCode, withTranslations }) => {
    return <>{withTranslations ? <ErrorPageWithTranslations errorCode={errorCode} /> : <ErrorPageDefault errorCode={errorCode} />}</>;
};

export default ErrorPage;
