import { FunctionComponent } from 'react';
import { HeaderProps } from '../Models/Header.Types';

export const Header: FunctionComponent<HeaderProps> = ({ title = null, children = null, className = '' }) => {
    return (
        <div className={`card-header ${className}`}>
            {title && <h4>{title}</h4>}
            {children}
        </div>
    );
};
