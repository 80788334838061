interface TabPanelProps {
    id?: string;
    className?: string;
    children: React.ReactNode;
}

const TabPanel: React.FC<TabPanelProps> = ({ id = 'nav-tabContent', className = '', children }) => {
    return (
        <div className={`tab-content d-flex flex-column flex-fill ${className}`} id={id}>
            {children}
        </div>
    );
};

export default TabPanel;
