import $ from 'jquery';
import axios from '../../../axios-mlp';
import * as actionTypes from './dashboardActionTypescopy';

export const setDashboardCopy = (viewModel) => {
    return {
        type: actionTypes.SET_DASHBOARD_COPY,
        model: viewModel
    };
};

export const initDashboardCopy = () => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .get('/DashboardCopy/Index')
            .then((response) => {
                dispatch(setDashboardCopy(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status === 302) {
                    window.location.href = window.location.protocol + '//' + document.location.host + error.response.data;
                } else if (error.response && error.response.status !== 401) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const updateWidgetSortOrderCopy = (data) => {
    return {
        type: actionTypes.UPDATE_WIDGET_SORT_ORDER_COPY,
        data: data
    };
};

export const updateDashboardClientServiceCopy = (data) => {
    return {
        type: actionTypes.UPDATE_CLIENT_SERVICE_COPY,
        data: data
    };
};

export const updateDashboardWidgetsCopy = (data) => {
    return {
        type: actionTypes.UPDATE_WIDGETS_COPY,
        data: data
    };
};

export const resetStateDashboardCopy = () => {
    return {
        type: actionTypes.RESET_STATE_DASHBOARD_COPY
    };
};
