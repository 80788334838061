import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios-portal';
import { API_CLEARANCES_PATH, API_DASHBOARD_PATH } from '../../common/constants/constants-portal';
import { ClientSelectionData, ClientService } from '../../common/models/ResponseData';
import { GET_CLIENT_SEARCH, GET_CLIENT_SELECTION, UPDATE_CLIENT_SELECTION } from './actionTypes';

export const GetClientSelectionSettings = createAsyncThunk(GET_CLIENT_SELECTION, async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get<ClientSelectionData>(`${API_DASHBOARD_PATH}/GetClientServiceSelection`);
        const clientCaptionNumber = await axios.get<string[]>(`${API_CLEARANCES_PATH}/clearance/get-user-client-caption-numbers`);
        const userCountry = response.data.ClientServiceSelection.Countries[0].IsSelected ? 'us' : 'ca';
        for (let i = 0; i < response.data.ClientServiceSelection.ClientServices.length; i++) {
            if (response.data.ClientServiceSelection.ClientServices[i].CountryCode === userCountry) {
                if (!clientCaptionNumber.data.find((num) => num === response.data.ClientServiceSelection.ClientServices[i].Number)) {
                    clientCaptionNumber.data.push(response.data.ClientServiceSelection.ClientServices[i].Number);
                }
            }
        }
        const newClientNumbersArray: ClientService[] = [];
        //This part of code makes sure that number field is unique by combining it with caption
        const { ClientServices } = response.data.ClientServiceSelection;
        clientCaptionNumber.data.forEach((clientNumber) => {
            let indexofElement = ClientServices.findIndex((x) => x.Number + '-' + x.Caption === clientNumber);
            if (indexofElement !== -1) {
                newClientNumbersArray.push({ ...ClientServices[indexofElement], Number: clientNumber });
            } else if (ClientServices.findIndex((x) => x.Number === clientNumber) === -1) {
                indexofElement = ClientServices.findIndex((x) => x.Number.includes(clientNumber));
                newClientNumbersArray.push({
                    ...ClientServices[indexofElement],
                    Number: clientNumber,
                    FullNumber: clientNumber,
                    Caption: ''
                });
            } else {
                indexofElement = ClientServices.findIndex((x) => x.Number === clientNumber);
                newClientNumbersArray.push({ ...ClientServices[indexofElement] });
            }
        });
        response.data.ClientServiceSelection.ClientTableData = newClientNumbersArray;
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const GetClientSelectionSearch = createAsyncThunk(
    GET_CLIENT_SEARCH,
    async (payload: { query: string; countryCode: 'ca' | 'us' }, { rejectWithValue, getState }) => {
        try {
            const response = await axios.post<ClientService[]>(
                `/Dashboard/SearchEDMClients?numberOrName=${payload.query}&countryCode=${payload.countryCode}`
            );

            // Search through response data
            const searchResponse = response.data?.filter(
                (c) =>
                    c?.Name?.toLowerCase()?.includes(payload?.query.toLowerCase()) ||
                    c?.Number?.toLowerCase()?.includes(payload?.query.toLowerCase())
            );

            const state = getState() as any;

            return {
                res: searchResponse,
                copyServices: state.clientSelection?.clientSelection?.ClientServices
            };
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const UpdateClientSelectionSettings = createAsyncThunk(
    UPDATE_CLIENT_SELECTION,
    async (payload: ClientSelectionData['ClientServiceSelection'], { rejectWithValue }) => {
        try {
            const response = await axios.post(`/Dashboard/SaveClientServiceSelection`, payload);

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);
