import { SortDescriptor } from '@progress/kendo-data-query';
import { GridPagerSettings } from '@progress/kendo-react-grid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductReportGridSettingsSlice } from '../../common/models/productReportModels';

let pagerSettings: GridPagerSettings = {
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [10, 20, 50],
    previousNext: true
};

const initialState: ProductReportGridSettingsSlice = {
    pageSize: 10,
    totalRecords: 0,
    pageSelected: 1,
    dataState: { take: 1, skip: 0 },
    pageable: pagerSettings,
    columnSortFields: [{ field: 'Name', dir: 'asc' }]
};

const productReportGridSettingsSlice = createSlice({
    name: 'productReportGridSettingsSlice',
    initialState,
    reducers: {
        setPageSize(state, action: PayloadAction<number>) {
            state.pageSize = action.payload;
        },
        setTotalRecords(state, action: PayloadAction<number>) {
            state.totalRecords = action.payload;
        },
        setPageSelected(state, action: PayloadAction<number>) {
            state.pageSelected = action.payload;
        },
        setDataState(state, action: PayloadAction<any>) {
            state.dataState = action.payload;
        },
        setPagerSettings(state, action: PayloadAction<any>) {
            state.pageable = action.payload;
        },
        setSortFields(state, action: PayloadAction<SortDescriptor[]>) {
            state.columnSortFields = action.payload;
        },
        resetGridSettings() {
            return { ...initialState };
        }
    }
});

export const { setPageSize, setTotalRecords, setPageSelected, setDataState, resetGridSettings, setSortFields } =
    productReportGridSettingsSlice.actions;

export default productReportGridSettingsSlice.reducer;
