import $ from 'jquery';
import axios from '../../../axios-mlp';
import * as actionTypes from './editInternalUserActions';

export const setInternalUser = (internalUser) => {
    return {
        type: actionTypes.SET_INTERNAL_USER,
        model: internalUser
    };
};

export const initEditInternalUser = (userId) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .get('/AccessManagement/GetInternalUser', { params: { userId: userId } })
            .then((response) => {
                dispatch(setInternalUser(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const setInternalUserStatus = (status) => {
    return {
        type: actionTypes.SET_INTERNAL_USER_STATUS,
        status: status
    };
};

export const fetchChangeStatusFailed = (error) => {
    return {
        type: actionTypes.CHANGE_STATUS_FAILED,
        error: error
    };
};

export const handleUpdateInternalUser = (newValue, key) => {
    return {
        type: actionTypes.HANDLE_UPDATE_INTERNAL_USER,
        payload: { value: newValue, key: key }
    };
};

export const cancelUserInternal = (userId) => {
    let params = new URLSearchParams();
    params.append('userId', userId);
    return (dispatch) => {
        $('#overlay').show();
        axios
            .post('/AccessManagement/CancelUser?userId=' + userId)
            .then((response) => {
                dispatch(setInternalUserStatus('Cancelled'));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const reactivateUserInternal = (userId) => {
    let params = new URLSearchParams();
    params.append('userId', userId);
    return (dispatch) => {
        $('#overlay').show();
        axios
            .post('/AccessManagement/ReactivateUser?userId=' + userId)
            .then((response) => {
                dispatch(setInternalUserStatus('Active'));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const setValidationMessagesInternalUser = (validationMessages) => {
    return {
        type: actionTypes.SET_VALIDATION_MESSAGES_INTERNAL_USER,
        payload: { validationMessages: validationMessages }
    };
};

export const resetStateInternalUser = () => {
    return {
        type: actionTypes.RESET_STATE_INTERNAL_USER
    };
};
