import { useEffect, useMemo, useState } from 'react';
import { useHydrationQuery } from '../../redux/api/hydrationApi';
import { useLazyActiveNoticesQuery } from '../../redux/api/noticesApi';
import { usePortalDispatch } from '../../redux/hooks';
import { setAlerts } from '../../redux/reducers/alertsSlice';
import type { ClearanceAlert } from '../models/ClearanceAlert';
import type { Notice } from '../models/Notice';

/**
 * Hook for getting alerts. There are two types of alerts:
 * - Status alerts ('on holds' and 'in progress')
 * - Notices
 * Status alert (on hold):
 * {
 *   id: 'a1',
 *   type: 'ON_HOLD',
 *   date: '2021-11-11T13:41:00+01:00',
 *   entryNumber: <EntryNumber>,
 *   agencyKey: <Agency>
 * }
 * Status alert (in progress):
 * {
 *   id: 'a1',
 *   type: 'IN_PROGRESS',
 *   date: '2021-11-11T13:41:00+01:00',
 *   entryNumber: <EntryNumber>,
 *   agencyKey: <Agency>
 * }
 * Notices:
 * {
 *   id: 'a1',
 *   type: 'NOTICE',
 *   date: '2021-11-11T13:41:00+01:00',
 *   title: <Title>
 *   description: <Content>
 * }
 * All data is already sorted by date in descending order (newest alerts are showing first).
 *
 */

const useAlerts = ({ languageCode }: { languageCode: string | undefined }) => {
    const dispatch = usePortalDispatch();

    const { data: user, isFetching: userIsFetching } = useHydrationQuery();
    const [getActiveNotices, { data: activeNotices, error: noticesError }] = useLazyActiveNoticesQuery();
    const [noticesIsFetching, setNoticesIsFetching] = useState(true);
    const isLoading = userIsFetching || noticesIsFetching;

    useEffect(() => {
        if (!languageCode) return;

        let isCurrent = true;

        setNoticesIsFetching(true);

        getActiveNotices()
            .unwrap()
            .finally(() => {
                if (isCurrent) setNoticesIsFetching(false);
            });

        return () => {
            isCurrent = false;
        };
    }, [getActiveNotices, languageCode]);

    const alerts = useMemo(() => {
        if (isLoading) return;

        // Store Alerts and Notices in separate arrays
        let userStatusAlerts: ClearanceAlert[] = [];
        let userNotices: Notice[] = [];
        // Check first if user is defined before transforming each array
        // On-hold alerts
        if (user?.ClearanceAlerts?.length) {
            userStatusAlerts = user.ClearanceAlerts.map((alert) => {
                return {
                    id: `${Math.random()}${alert.EntryNumber || ''}`,
                    type: alert.AlertStatus,
                    date: alert.HoldEventDateTime || '',
                    entryNumber: alert.EntryNumber || '',
                    agencyKey: alert.AgencyKey || '',
                    customsInfoID: alert.CustomsInfoID || 0,
                    eventTypeKey: alert.EventTypeKey || ''
                };
            });
        }
        // Notices
        if (activeNotices) {
            userNotices = activeNotices.map((notice) => ({
                id: Math.random() + notice.Id,
                type: 'NOTICE',
                date: notice.EffectiveDateFrom,
                title: notice.Title,
                description: notice.Content
            }));
        }

        return { statusAlerts: [...userStatusAlerts], notices: [...userNotices] };
    }, [activeNotices, isLoading, user?.ClearanceAlerts]);

    useEffect(() => {
        if (!alerts) return;

        dispatch(setAlerts(alerts));
    }, [alerts, dispatch]);

    return { alerts, noticesIsLoading: noticesIsFetching, noticesError };
};

export default useAlerts;
