import { faBan, faSyncAlt, faUserEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import type { TeamMemberStatus } from '../models';

export const STATUS_FILTER_OPTIONS = [
    { id: 'ALL', label: 'StatusAll_Label' },
    { id: 'ACTIVE', label: 'StatusActive_Label' },
    { id: 'INACTIVE', label: 'StatusInactive_Label' }
] as const;

export const ACTION_BUTTON_OPTIONS = [
    { id: 'CLONE_USER', label: 'CloneUser_Label', icon: faUserPlus },
    { id: 'EDIT_USER', label: 'EditUser_Label', icon: faUserEdit },
    { id: 'DEACTIVATE_USER', label: 'DeactivateUser_Label', icon: faBan },
    { id: 'REACTIVATE_USER', label: 'ReactivateUser_Label', icon: faSyncAlt }
] as const;

export const ADMIN_ROLES = [
    { id: 'LIIInternalAdmin' },
    { id: 'LIIClientAdmin' },
    { id: 'ClientAdmin' },
    { id: 'BasicClientAdmin' },
    { id: 'PaymentAdmin' },
    { id: 'CentralAdmin' }
];

export const TEAM_MEMBER_STATUS_LABELS: Record<TeamMemberStatus, string> = {
    Active: 'Active_Label',
    Deactivated: 'Deactivated_Label',
    Expired: 'Expired_Label',
    Cancelled: 'Cancelled_Label',
    Pending: 'Pending_Label',
    Hold: 'Hold_Label',
    PendingUserConfirmRequired: 'Pending_Label',
    PendingFeatureSetup: 'PendingFeatureSetUp_Label',
    PendingUserActivation: 'PendingUserActivation_Label',
    ActiveFeatureSetupInProgress: 'ActiveFeatureSetupInProgress_Label',
    EmailChangedConfirmRequired: 'EmailChangedConfirmRequired_Label'
} as const;

export const TEAM_SEARCH_PARAM_KEYS = {
    userId: 'userId'
} as const;
