import { ShipmentMilestones } from '../constants/constants-clearances';
import { ShipmentListData } from '../models/ResponseData';

export const transformData = (data: ShipmentListData) => {
    const shipmentList = data.Clearances.map((obj) => {
        return {
            ...obj,
            ModeOfTransport: { id: obj.ModeOfTransport },
            BillOfLading: {
                MasterBill: obj.BillOfLading?.length && obj.BillOfLading[0].MasterBill ? obj.BillOfLading[0].MasterBill : undefined,
                HouseBill: obj.BillOfLading?.length && obj.BillOfLading[0].HouseBill ? obj.BillOfLading[0].HouseBill : undefined
            },
            Milestone:
                obj.Destination && obj.Stage?.length
                    ? ShipmentMilestones[obj.Destination as keyof typeof ShipmentMilestones][
                          obj.Stage[obj.Stage.length - 1] as keyof (typeof ShipmentMilestones)['Canada']
                      ].label ||
                      ShipmentMilestones[obj.Destination as keyof typeof ShipmentMilestones][
                          obj.Stage[obj.Stage.length - 1] as keyof (typeof ShipmentMilestones)['USA']
                      ].label
                    : undefined
        };
    });

    return {
        totalInProgress: data.TotalInProgress,
        totalOnHold: data.TotalOnHold,
        totalCompleted: data.TotalCompleted,
        shipmentList
    };
};
