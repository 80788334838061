import { Component } from 'react';
import { connect } from 'react-redux';
import { imageDetailsPath, storageModelName } from '../../../constants/mlp-constants';
import * as actions from '../../../store/actions/index';
import { removeModelFromStorage } from '../../../utils/modelStorage';
import PreviewDocument from './PreviewDocument';

class DocumentView extends Component {
    detailPages = [
        imageDetailsPath.VIEWIMAGE_SEARCHSHIPMENTDETAIL,
        imageDetailsPath.DOCUMENTCOMPARE_VIEW,
        imageDetailsPath.DOCUMENT_VIEW,
        imageDetailsPath.DOWNLOAD_AND_PRINT
    ];

    componentDidMount() {
        const selectedDocument = this.getSelectedDocument();
        if (!selectedDocument) {
            const entryIndex = this.props.viewModel.findIndex((x) => x.isSelected);
            if (entryIndex) {
                this.props.selectDocument(0, -1, entryIndex);
            } else {
                this.props.selectDocument(0, -1, 0);
            }
        }
    }

    componentWillUnmount() {
        if (this.props.history.location.pathname !== '/Imaging' && !this.detailPages.includes(this.props.history.location.pathname)) {
            removeModelFromStorage(storageModelName.VIEWIMAGE);
        }
    }

    getSelectedEntry = () => {
        let selectedEntry = this.props.viewModel.find((x) => x.isSelected);
        if (!selectedEntry) {
            selectedEntry = this.props.viewModel[0];
        }
        return selectedEntry;
    };

    getSelectedDocument = () => {
        const selectedEntry = this.getSelectedEntry();
        if (selectedEntry) {
            const selectedDocument = selectedEntry.documents.find((x) => x.isSelected);
            return selectedDocument;
        } else {
            return undefined;
        }
    };

    selectDocument = (newIndex, oldIndex, event, name) => {
        const entryIndex = this.props.viewModel.findIndex((x) => x.groupId.toString() === name);
        this.props.selectDocument(newIndex, oldIndex, entryIndex);
    };

    render() {
        const selectedEntry = this.getSelectedEntry();
        return (
            <div className='col-sm-12'>
                <div className='container imaging-details-container'>
                    <div className='entry-details-container'>
                        <PreviewDocument
                            data={selectedEntry.documents}
                            getPublicDocUrl='GetPresignedUrl'
                            selectAction={this.selectDocument}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        viewModel: state.imagingDetails.model.ViewModel
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        selectDocument: (newIndex, oldIndex, entryIndex) => dispatch(actions.selectDocument(newIndex, oldIndex, entryIndex))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentView);
