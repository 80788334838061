import { faBell, faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useState } from 'react';
import Button from '../../../../../common/features/Button';
import CountryFlag from '../../../../../common/features/CountryFlag';
import MilestoneDropdown from '../../../../../common/features/MilestoneDropdown';
import Modal from '../../../../../common/features/Modal';
import Tooltip from '../../../../../common/features/Tooltip/Tooltip';
import { formatDateMMDDYY } from '../../../../../common/functions/parser';
import { getCountryFromClientNumber } from '../../../../../common/functions/utils';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { Notifications, SubscriptionsData } from '../../../../../common/models/ResponseData';
import { Unsubscribe, UpdateSubscription } from '../../../redux/actions/subscriptions';
import { useProfileDispatch, useProfileSelector } from '../../../redux/hooks';
import { resetSelectedState } from '../../../redux/reducers/profileGridSettingsSlice';
import { resetUnsubscribe, setCustomerNumber, setSubscriptionUpdateAction } from '../../../redux/reducers/subscriptionsSlice';
import NotificationTypesDropdown from './NotificationTypesDropdown';
import OnHoldWrapper from './OnHoldWrapper';

interface DataItem extends SubscriptionsData {
    selected?: boolean;
    isNew?: boolean;
}

interface FormattedGridCellProps extends GridCellProps {
    dataItem: DataItem;
    isLocked: boolean;
}

const FormattedGridCell: React.FC<FormattedGridCellProps> = ({ field = '', dataItem, isLocked, selectionChange, className }) => {
    const { user } = useProfileSelector((state) => state.hydration);
    const [showModal, setShowModal] = useState(false);
    const { unsubscribe } = useProfileSelector((state) => state.subscriptions);
    const dispatch = useProfileDispatch();
    const translate = useTranslation();
    let cellContent;

    const handleSubscriptionUpdate = (
        action: string,
        customerNumber: null | string,
        subscriptionId: null | number,
        milestones: null | Notifications[],
        email: null | string,
        phone: null | string,
        DocID: null | number,
        language: string | undefined
    ) => {
        dispatch(resetSelectedState());
        if (unsubscribe.removedSubscribersAmount) {
            dispatch(resetUnsubscribe());
        }
        dispatch(setSubscriptionUpdateAction(action));
        dispatch(setCustomerNumber(customerNumber));
        if (action === 'unsubscribe') {
            if (subscriptionId) {
                dispatch(Unsubscribe({ subscriptionId }));
            }
        } else {
            if (customerNumber) {
                dispatch(
                    UpdateSubscription({ customerNumber, subscriptionId, milestones, email, phone, DocID, language: user?.LanguageCode })
                );
            }
        }
    };

    const milestoneDropdown = (dataItem: any) => {
        const email = dataItem.Email;
        const phone = dataItem.Phone;
        const docId = dataItem.DocID;
        const handleUpdate = (
            action: string,
            customerNumber: null | string,
            subscriptionId: null | number,
            milestones: null | Notifications[]
        ) => {
            handleSubscriptionUpdate(action, customerNumber, subscriptionId, milestones, email, phone, docId, user?.LanguageCode);
        };

        return (
            <MilestoneDropdown
                {...dataItem}
                updateMilestones={handleUpdate}
                disabled={!dataItem?.IsAvailableToSubscribe || isLocked}
                applyButtonLabel={
                    !user?.InternalUser ? (
                        translate('Apply_Label')
                    ) : (
                        <Tooltip label='Disabled for LII users.'>
                            <span>{translate('Apply_Label')}</span>
                        </Tooltip>
                    )
                }
                cancelButtonLabel={translate('Cancel_Label')}
            />
        );
    };

    const subscribeButton = (dataItem: any) => {
        const country = getCountryFromClientNumber(dataItem?.CustomerNumber!);
        const subscribed = dataItem?.SubscriptionId ? dataItem?.SubscriptionId : false;
        const subscriptionId = dataItem?.SubscriptionId;
        const clientNumber = dataItem?.CustomerNumber;
        const email = dataItem.Email;
        const phone = dataItem.Phone;
        const DocID = dataItem.DocID;

        return (
            <>
                <div style={{ left: '0px', right: '0px' }}>
                    <Button
                        variant={subscribed ? 'danger-ghost' : 'ghost'}
                        onClick={() => {
                            if (dataItem?.IsAvailableToSubscribe) {
                                subscribed
                                    ? setShowModal(true)
                                    : handleSubscriptionUpdate(
                                          'subscribe',
                                          clientNumber!,
                                          null,
                                          dataItem![`MilestoneNotifications${country === 'ca' ? '_CA' : '_US'}`]!,
                                          email,
                                          phone,
                                          DocID,
                                          user?.LanguageCode
                                      );
                            }
                        }}
                        disabled={isLocked}
                        data-target={'#modal'}
                    >
                        <FontAwesomeIcon icon={subscribed ? faBellSlash : faBell} className={'mr-1'} />
                        {subscribed ? (
                            translate('Unsubscribe_Label')
                        ) : !dataItem?.IsAvailableToSubscribe ? (
                            <Tooltip label={translate('NotificationsSubscribeMessageAllNoClearances_Label')}>
                                <span>{translate('Subscribe_Label')}</span>
                            </Tooltip>
                        ) : (
                            translate('Subscribe_Label')
                        )}
                    </Button>
                </div>
                {showModal && (
                    <Modal
                        header={translate('AreYouSureYouWantToUnsubscribe_Label', clientNumber)}
                        title={translate('YouWillBeUnsubscribedFromAll_Label')}
                        toggleModal={setShowModal}
                    >
                        <>
                            <Button
                                variant='ghost'
                                aria-label={translate('Close_Label')}
                                className='btn-lg'
                                children={translate('Close_Label')}
                                data-dismiss='modal'
                                onClick={() => setShowModal(false)}
                            />
                            <Button
                                variant='danger'
                                aria-label={translate('YesUnsubscribe_Label')}
                                className='btn-lg'
                                children={translate('YesUnsubscribe_Label')}
                                data-dismiss='modal'
                                onClick={() => [
                                    handleSubscriptionUpdate(
                                        'unsubscribe',
                                        clientNumber,
                                        subscriptionId,
                                        null,
                                        email,
                                        phone,
                                        null,
                                        user?.LanguageCode
                                    ),
                                    setShowModal(false)
                                ]}
                            />
                        </>
                    </Modal>
                )}
            </>
        );
    };

    const onHoldToggle = (dataItem: any) => {
        const country = getCountryFromClientNumber(dataItem?.CustomerNumber!);
        const subscriptionId = dataItem?.SubscriptionId;
        const clientNumber = dataItem?.CustomerNumber;
        const email = dataItem.Email;
        const phone = dataItem.Phone;
        let milestones = dataItem![`MilestoneNotifications${country === 'ca' ? '_CA' : '_US'}`]!;
        const DocID = dataItem.DocID;

        const handleToggle = (value: boolean) => {
            milestones = milestones.map((item: { Name: string }) => {
                if (item.Name === `HasNotificationsForClearanceOnHold${country === 'ca' ? '_CA' : ''}`) {
                    return { Name: item.Name, Value: value };
                }
                return item;
            });
            handleSubscriptionUpdate('onhold', clientNumber!, subscriptionId!, milestones, email, phone, DocID, user?.LanguageCode);
        };

        return <OnHoldWrapper data={dataItem} toggleChecked={handleToggle} disabled={!dataItem?.IsAvailableToSubscribe || isLocked} />;
    };

    const notificationTypes = (dataItem: any) => {
        const country = getCountryFromClientNumber(dataItem?.CustomerNumber!);
        const email = dataItem.Email ?? dataItem.ProfileEmail;
        const phone = dataItem.Phone ?? dataItem.ProfilePhone;
        const subscriptionId = dataItem?.SubscriptionId;
        const emailChecked = dataItem.SubscriptionId && dataItem.Email ? true : false;
        const clientNumber = dataItem?.CustomerNumber;
        const DocID = dataItem.DocID;
        let milestones = dataItem![`MilestoneNotifications${country === 'ca' ? '_CA' : '_US'}`]!;

        const handleNotificationTypes = (isEmailChecked: boolean, mobilePhone: null | string) => {
            handleSubscriptionUpdate(
                'notifTypes',
                clientNumber!,
                subscriptionId!,
                milestones,
                isEmailChecked ? email : null,
                mobilePhone,
                DocID,
                user?.LanguageCode
            );
        };

        return (
            <NotificationTypesDropdown
                email={email}
                phone={phone}
                emailChecked={emailChecked}
                applyChange={handleNotificationTypes}
                disabled={user?.InternalUser || !dataItem?.IsAvailableToSubscribe || isLocked}
            />
        );
    };

    const countryFlagImage = (dataItem: any) => {
        const country = getCountryFromClientNumber(dataItem?.CustomerNumber!);
        return (
            <div className='pl-3'>
                <CountryFlag countryCode={country} />
            </div>
        );
    };

    const customerNumber = (dataItem: any) => {
        return <span>{dataItem.CustomerNumber}</span>;
    };

    const entryNumber = (dataItem: any) => {
        return <span>{dataItem.EntryNo}</span>;
    };

    const subscriptionType = (dataItem: any) => {
        const typeLabel = {
            SINGLE: 'SingleEntry_Label',
            CLIENT: 'ClientNo_Label'
        }[dataItem.SubscriptionType as 'SINGLE' | 'CLIENT'];

        return <span>{translate(typeLabel)}</span>;
    };

    const selection = (dataItem: any) => {
        return <Checkbox value={!!dataItem.selected} onChange={selectionChange} disabled={!dataItem.IsEditable} />;
    };

    const dateSubscribed = (dataItem: any) => {
        const date = formatDateMMDDYY(dataItem.DateSubscribed);
        return <>{date}</>;
    };

    switch (field) {
        case 'CustomerNumber':
            cellContent = dataItem ? customerNumber(dataItem) : null;
            break;
        case 'EntryNumber':
            cellContent = dataItem ? entryNumber(dataItem) : null;
            break;
        case 'SubscriptionType':
            cellContent = dataItem ? subscriptionType(dataItem) : null;
            break;
        case 'Milestones':
            cellContent = dataItem ? milestoneDropdown(dataItem) : null;
            break;
        case 'Subscription':
            cellContent = dataItem ? subscribeButton(dataItem) : null;
            break;
        case 'OnHoldNotifications':
            cellContent = dataItem ? onHoldToggle(dataItem) : null;
            break;
        case 'Country':
            cellContent = dataItem ? countryFlagImage(dataItem) : null;
            break;
        case 'DateSubscribed':
            cellContent = dataItem ? dateSubscribed(dataItem) : null;
            break;
        case 'NotificationTypes':
            cellContent = dataItem ? notificationTypes(dataItem) : null;
            break;
        case 'selected':
            cellContent = dataItem ? selection(dataItem) : null;
            break;
        default:
            cellContent = null;
    }

    return (
        <td
            className={`${className}${dataItem?.selected ? 'k-state-selected' : ''} ${dataItem?.isNew ? 'new-row-highlight' : ''}`}
            style={{ textAlign: field === 'selected' ? 'center' : 'left' }}
        >
            {cellContent}
        </td>
    );
};

export default FormattedGridCell;
