import { Component } from 'react';
import { connect } from 'react-redux';
import NoticesTableRow from './NoticesTableRow';

class NoticesTableArea extends Component {

    renderTableHeader(){
        return  (<thead>
                    <tr>
                        <th>{this.props.translations.Phrases['ManageNotices_TableHeader_StartDate']}</th>
                        <th>{this.props.translations.Phrases['ManageNotices_TableHeader_EndDate']}</th>
                        <th>{this.props.translations.Phrases['ManageNotices_TableHeader_Title']}</th>
                        <th>{this.props.translations.Phrases['ManageNotices_TableHeader_Application']}</th>
                        <th>{this.props.translations.Phrases['ManageNotices_TableHeader_NoticeType']}</th>
                        <th>{this.props.translations.Phrases['ManageNotices_TableHeader_Language']}</th>
                    <th className='right-align'>{this.props.translations.Phrases['ManageNotices_TableHeader_Actions']}</th>
                    </tr>
                </thead>);
    }

    renderTableBody(){
        const rows = this.props.notices.map((entry, i) => {
            if (!entry.IsDeleted) {
                return <NoticesTableRow key={entry.LookupKey} data={entry} index={i} />;
            } else {
                return null;
            }
        });

        return  <tbody>{rows}</tbody>;
    }
    render() {


        return (
            <div className='container'>
                <div className='row'>
                    <div className='manage-notices-area notice-search-results'>
                        <table className='table'>
                           {this.renderTableHeader()}
                           {this.renderTableBody()}
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notices: state.notices.model.NoticesList,
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(NoticesTableArea);
