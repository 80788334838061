import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../axios-mlp';
import { ChangeLanguageData, TranslationsData } from '../../common/models/ResponseData';
import { CHANGE_LANGUAGE, CHANGE_LANGUAGE_HYDRATION, GET_TRANSLATIONS } from './actionTypes';

export const GetTranslations = createAsyncThunk(
    GET_TRANSLATIONS,
    async (params: { phraseGroup: string; languageCode: string; slice?: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get<TranslationsData>('/Translations/Index', {
                params: { phraseGroup: params.phraseGroup, languageCode: params.languageCode }
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const ChangeLanguage = createAsyncThunk(
    CHANGE_LANGUAGE,
    async (params: { localization: string; languageCode: string; phraseGroup: string }, { rejectWithValue }) => {
        try {
            const response = await axios.put<ChangeLanguageData>(`${params.localization}/localization/`, {
                languageCode: params.languageCode,
                phraseGroup: params.phraseGroup
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const ChangeLanguageHydration = createAsyncThunk(
    CHANGE_LANGUAGE_HYDRATION,
    async (params: { localization: string; languageCode: string; phraseGroup: string }, { rejectWithValue }) => {
        try {
            const response = await axios.put<ChangeLanguageData>(`${params.localization}/localization/`, {
                languageCode: params.languageCode,
                phraseGroup: params.phraseGroup
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);
