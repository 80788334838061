import { useEffect, useRef, useState } from 'react';
import { useDebounce } from './useDebounce';

const useElementDimensions = (element: undefined | null | string, delay: undefined | number = 200) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const elementRef = useRef<any>(null);

    // On initial load set the element dimensions
    useEffect(() => {
        if (!element) return;
        elementRef.current = document.querySelector(element);
        setDimensions({ width: elementRef.current?.offsetWidth || 0, height: elementRef.current?.offsetHeight || 0 });
    }, [element]);

    const debounce = useDebounce(delay);

    // On element resize update dimensions
    useEffect(() => {
        if (!element) return;

        elementRef.current = document.querySelector(element);

        if (!elementRef.current) return;

        const throttle = (f: any) => {
            return function (...args: any) {
                debounce(() => {
                    f(...args);
                });
            };
        };

        const resizeObserver = new ResizeObserver(
            throttle((entries: ResizeObserverEntry[]) => {
                for (const entry of entries) {
                    setDimensions({ width: entry.contentRect.width, height: entry.contentRect.height });
                }
            })
        );

        resizeObserver.observe(elementRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [element, delay, debounce]);

    return { width: dimensions.width, height: dimensions.height };
};

export default useElementDimensions;
