import type { PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { SearchFilterSlice } from '../../common/models/ReduxSlices';

export const createGenericSearchFilterSlice = <Reducers extends SliceCaseReducers<SearchFilterSlice>>({
    name = '',
    initialState,
    reducers
}: {
    name: string;
    initialState: SearchFilterSlice;
    reducers: ValidateSliceCaseReducers<SearchFilterSlice, Reducers>;
}) => {
    return createSlice({
        name,
        initialState,
        reducers: {
            setSearchFilter(state, action: PayloadAction<string>) {
                state.searchTerm = action.payload;
            },
            clearSearchFilter(state) {
                state.searchTerm = '';
            },
            ...reducers
        }
    });
};
