import type { SortDescriptor, State } from '@progress/kendo-data-query';
import type { GridPagerSettings } from '@progress/kendo-react-grid';
import type { PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { GridSettingsSlice } from '../../common/models/ReduxSlices';

export const genericPagerSettings: GridPagerSettings = {
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [10, 20, 50],
    previousNext: true
};

export const genericGridSettingsInitialState: GridSettingsSlice = {
    pageable: genericPagerSettings,
    dataState: {
        take: 10,
        skip: 0
    },
    totalItems: 0,
    selectedState: {},
    headerCheckbox: false,
    currentDataItems: []
};

export const createGenericGridSettingsSlice = <Reducers extends SliceCaseReducers<GridSettingsSlice>>({
    name = '',
    initialState,
    reducers
}: {
    name: string;
    initialState: GridSettingsSlice;
    reducers: ValidateSliceCaseReducers<GridSettingsSlice, Reducers>;
}) => {
    return createSlice({
        name,
        initialState,
        reducers: {
            setPageSelected(state, action: PayloadAction<number>) {
                state.dataState.skip = !state.dataState.take ? 0 : (action.payload - 1) * state.dataState.take;
            },
            setDataState(state, action: PayloadAction<State>) {
                state.dataState = action.payload;
            },
            setTotalItems(state, action: PayloadAction<number>) {
                state.totalItems = action.payload;
            },
            setSelectedState(
                state,
                action: PayloadAction<{
                    [id: string]: boolean | number[];
                }>
            ) {
                state.selectedState = action.payload;
            },
            setHeaderCheckbox(state, action: PayloadAction<boolean>) {
                state.headerCheckbox = action.payload;
            },
            setCurrentDataItems(state, action: PayloadAction<any>) {
                state.currentDataItems = action.payload;
            },
            setSort(state, action: PayloadAction<SortDescriptor[]>) {
                state.dataState.sort = action.payload;
            },
            // STATE RESETS
            resetDataState(state, action: PayloadAction<undefined | GridSettingsSlice['dataState']>) {
                state.dataState = action.payload || initialState.dataState;
            },
            resetSelectedState(state) {
                state.selectedState = initialState.selectedState;
                state.headerCheckbox = initialState.headerCheckbox;
            },

            ...reducers
        }
    });
};
