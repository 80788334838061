import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatTranslated, isVisible } from '../../utils/mlp-utils';
import InputBase from '../Inputbase/Inputbase';

class CheckboxFilter extends Component {
    static defaultProps = {
        countryCodes: ['CA', 'US'],
        searchTypeCode: '*'
    };

    static propTypes = {
        countryCodes: PropTypes.arrayOf(PropTypes.string),
        searchTypeCode: PropTypes.string,
        filterControl: PropTypes.object,
        onValueChanged: PropTypes.func,
        customAction: PropTypes.func
    };

    toggle = () => {
        let val = this.props.filterControl.Value;
        const filterIndex = this.props.filterControl.FilterIndex;
        if (!val) {
            val = 'Y';
        } else if (val.toUpperCase() === 'Y') {
            val = 'N';
        } else if (val.toUpperCase() === 'N') {
            val = '';
        }
        const name = 'Filters.' + filterIndex + '.Value';

        this.props.onValueChanged(val, name);

        if (this.props.customAction) {
            this.props.customAction();
        }
    };

    render() {
        const filterControl = this.props.filterControl;
        if (!isVisible(filterControl, this.props.searchTypeCode)) {
            return null;
        }
        let text = formatTranslated('Select_Label', this.props.translations.Phrases);
        if (filterControl.Value && filterControl.Value.toUpperCase() === 'Y') {
            text = formatTranslated('Yes_Label', this.props.translations.Phrases);
        } else if (filterControl.Value && filterControl.Value.toUpperCase() === 'N') {
            text = formatTranslated('No_Label', this.props.translations.Phrases);
        }

        return (
            <div>
                <InputBase value={filterControl.Value} label={formatTranslated(filterControl.Name, this.props.translations.Phrases)}>
                    <span className='btn btn-link' onClick={() => this.toggle()}>
                        {text}
                    </span>
                </InputBase>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(CheckboxFilter);
