// Format the date as "Jun 6, 2022, 1:00 PM"
export const createDateForAlert = (date?: string | null, locale: string = 'en-US') => {
    if (!date) return '';
    return new Date(date).toLocaleDateString(locale, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour12: true,
        hour: 'numeric',
        minute: 'numeric'
    });
};

export const formatDateMMDDYY = (date?: string | null) => {
    if (!date) return '';
    let d = new Date(date);
    let day = d.getDate().toString();
    let month = (d.getMonth() + 1).toString();
    let formatDay = day.length === 1 ? '0' + day : day;
    let formatMonth = month.length === 1 ? '0' + month : month;
    return formatMonth + '/' + formatDay + '/' + d.getFullYear().toString().substr(-2);
};
