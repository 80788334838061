import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/imaging/imaging';
import { formatTranslated, isVisible } from '../../utils/mlp-utils';
import Dropdown from '../Dropdown/Dropdown';

class YearFilter extends Component {
    state = { hidden: false };

    static defaultProps = {
        countryCodes: ['CA', 'US'],
        searchTypeCode: '*'
    };

    static propTypes = {
        countryCodes: PropTypes.arrayOf(PropTypes.string),
        searchTypeCode: PropTypes.string,
        filterControl: PropTypes.object,
        onValueChanged: PropTypes.func,
        setDropdownYears: PropTypes.func,
        customAction: PropTypes.func
    };

    componentDidMount() {
        const year = new Date().getFullYear() + 1;
        let allOptions = [
            {
                Text: formatTranslated('Select_Label', this.props.translations.Phrases),
                Value: '',
                IsSelected: !this.props.filterControl.Value
            }
        ];
        for (let i = year; i > year - 51; i--) {
            allOptions.push({
                Text: i,
                Value: i.toString(),
                IsSelected: false
            });
        }

        if (this.props.filterControl.Options.every((e) => !e.IsSelected)) {
            allOptions[0].IsSelected = true;
        }

        this.props.onSetDropdownYears(allOptions);
    }

    componentDidUpdate(prevProps) {
        if (this.props.filterControl.Options !== prevProps.filterControl.Options) {
            const selectedItem = this.props.filterControl.Options.find((x) => {
                return x.IsSelected;
            });

            const name = 'Filters.' + this.props.filterControl.FilterIndex + '.Value';
            if (selectedItem) {
                this.props.onValueChanged(selectedItem.Value, name);
            }
        }
    }

    filterOptionSelect = (newIndex, oldIndex, value, name) => {
        this.props.onOptionSelect(newIndex, oldIndex, name);
        this.props.onValueChanged(value, name);
        this.props.customAction();
    };

    toggleSection = () => {
        this.setState((prevState) => {
            return { hidden: !prevState.hidden };
        });
    };

    render() {
        const filterControl = this.props.filterControl;
        const filterIndex = filterControl.FilterIndex;
        if (!isVisible(filterControl, this.props.searchTypeCode)) {
            return null;
        }
        const close = this.state.hidden
            ? formatTranslated('Show_Label', this.props.translations.Phrases)
            : formatTranslated('Hide_Label', this.props.translations.Phrases);
        return (
            <div className='filter-section'>
                <div className='row header-section'>
                    <h4 className='col-sm-8 text-left header'>
                        {formatTranslated(filterControl.Attribute, this.props.translations.Phrases)}
                    </h4>
                    <div className='col-sm-4 text-right'>
                        <span className='btn btn-link' onClick={this.toggleSection}>
                            {close}
                        </span>
                    </div>
                </div>
                <div className={this.state.hidden ? 'd-none' : 'd-block'}>
                    <Dropdown
                        options={filterControl.Options}
                        name={'Filters.' + filterIndex + '.Value'}
                        noHeader
                        label={filterControl.Name}
                        onItemChanged={this.filterOptionSelect}
                    />
                    <hr />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSetDropdownYears: (years) => dispatch(actions.setDropdownYears(years))
    };
};

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(YearFilter);
