import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import classes from './CollapsableCard.module.css';

interface CollapsableCardProps {
    title: React.ReactNode;
    children: React.ReactNode;
}

export default function CollapsableCard({ title, children }: CollapsableCardProps) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded((prevState) => !prevState);
    };

    return (
        <div className='card overflow-hidden collapsible'>
            <button
                type='button'
                className={`btn py-2 d-flex justify-content-between align-items-center card-header ${classes['btn-expand']} ${
                    isExpanded ? '' : 'collapsed'
                }`}
                onClick={toggleExpand}
                aria-expanded={isExpanded}
            >
                <span className='h4 text-left mb-0 mr-2'>{title}</span>
                <FontAwesomeIcon icon={faChevronDown} className={isExpanded ? classes.expanded : undefined} />
            </button>
            <div className={`collapse pt-4 px-4 pb-2 ${isExpanded ? 'show' : ''}`}>{children}</div>
        </div>
    );
}
