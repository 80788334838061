import { SearchBoxProps } from '../../../models/SearchBox';
import SearchBoxDesktop from './SearchBoxDesktop';
import SearchBoxMobile from './SearchBoxMobile';

const SearchBox: React.FC<SearchBoxProps> = ({
    inputValue,
    placeholder,
    advancedSearchPlaceholder,
    submitButtonText,
    showAdvancedSearch,
    showMobileSearch,
    enableAdvancedSearch = false,
    customStyle,
    onToggleMobileSearch,
    onChange,
    onKeyDown,
    onBlur,
    onClearInputValue,
    onToggleAdvancedSearch,
    onSubmit
}) => {
    return (
        <>
            {!showMobileSearch ? (
                // DESKTOP VERSION
                <SearchBoxDesktop
                    inputValue={inputValue}
                    placeholder={placeholder}
                    submitButtonText={submitButtonText}
                    advancedSearchPlaceholder={advancedSearchPlaceholder}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    onToggleMobileSearch={onToggleMobileSearch}
                    onToggleAdvancedSearch={onToggleAdvancedSearch}
                    onClearInputValue={onClearInputValue}
                    onSubmit={onSubmit}
                    showAdvancedSearch={showAdvancedSearch}
                    showMobileSearch={showMobileSearch}
                    enableAdvancedSearch={enableAdvancedSearch}
                    customStyle={customStyle}
                />
            ) : (
                // MOBILE VERSION
                <SearchBoxMobile
                    inputValue={inputValue}
                    placeholder={placeholder}
                    submitButtonText={submitButtonText}
                    advancedSearchPlaceholder={advancedSearchPlaceholder}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    onToggleMobileSearch={onToggleMobileSearch}
                    onToggleAdvancedSearch={onToggleAdvancedSearch}
                    onClearInputValue={onClearInputValue}
                    onSubmit={onSubmit}
                    showAdvancedSearch={showAdvancedSearch}
                    showMobileSearch={showMobileSearch}
                    enableAdvancedSearch={enableAdvancedSearch}
                />
            )}
        </>
    );
};

export default SearchBox;
