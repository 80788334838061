import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import CustomDropDown from '../CustomDropDown/CustomDropDown';
import DatePickerModal from '../DateTimePicker/DatePickerModal';
import { formatTranslated, isVisible } from './../../utils/mlp-utils';

class PrimaryDateFilter extends Component {
    state = { hidden: false };

    static defaultProps = {
        countryCodes: ['CA', 'US'],
        searchTypeCode: '*'
    };

    static propTypes = {
        countryCodes: PropTypes.arrayOf(PropTypes.string),
        searchTypeCode: PropTypes.string,
        filterControl: PropTypes.object,
        onOptionSelect: PropTypes.func,
        onValueChanged: PropTypes.func,
        customAction: PropTypes.func
    };

    toggleSection = () => {
        this.setState((prevState) => {
            return { hidden: !prevState.hidden };
        });
    };

    setDate = (hours) => {
        const nameStart = 'Filters.' + this.props.filterControl.FilterIndex + '.RangeStart';
        const nameEnd = 'Filters.' + this.props.filterControl.FilterIndex + '.RangeEnd';
        const isUtc = this.props.filterControl.Attribute === '_imageDate';
        const start = dayjs().startOf('day').subtract(hours, 'hours');
        const startString = isUtc ? start.add(24, 'hours').toISOString() : start.format('YYYY-MM-DD');
        const end = dayjs().endOf('day');
        const endString = isUtc ? end.toDate().toISOString() : end.format('YYYY-MM-DDTHH:mm:ss');
        this.props.onValueChanged(startString, nameStart);
        this.props.onValueChanged(endString, nameEnd);
        if (this.props.customAction) {
            this.props.customAction();
        }
    };

    clearDates = () => {
        const filterControl = this.props.filterControl;
        const filterIndex = filterControl.FilterIndex;
        if (filterControl.RangeStart || filterControl.RangeEnd) {
            if (filterControl.RangeStart) {
                const nameStart = 'Filters.' + filterIndex + '.RangeStart';
                this.props.onValueChanged(undefined, nameStart);
            }
            if (filterControl.RangeEnd) {
                const nameEnd = 'Filters.' + filterIndex + '.RangeEnd';
                this.props.onValueChanged(undefined, nameEnd);
            }
            if (this.props.customAction) {
                this.props.customAction();
            }
        }
    };

    filterOptionSelect = (newIndex, oldIndex, name) => {
        this.props.onOptionSelect(newIndex, oldIndex, name);

        const filterControl = this.props.filterControl;
        const filterIndex = filterControl.FilterIndex;
        const selectedItem = filterControl.Options[newIndex];

        const nameFilter = 'Filters.' + filterIndex;
        const isUtc = selectedItem.Value === '_folderDate' || filterControl.Attribute === '_imageDate';
        this.props.onValueChanged(selectedItem.DataType, nameFilter + '.AttributeDataType');
        this.props.onValueChanged(selectedItem.Value, nameFilter + '.Attribute');
        if (filterControl.RangeStart || filterControl.RangeEnd) {
            if (filterControl.RangeStart) {
                const start = dayjs(filterControl.RangeStart).startOf('day');
                const startString = isUtc ? start.toISOString() : start.format('YYYY-MM-DD');
                this.props.onValueChanged(startString, nameFilter + '.RangeStart');
            }
            if (filterControl.RangeEnd) {
                const end = dayjs(filterControl.RangeEnd).endOf('day');
                const endString = isUtc ? end.toDate().toISOString() : end.format('YYYY-MM-DDTHH:mm:ss');
                this.props.onValueChanged(endString, nameFilter + '.RangeEnd');
            }
            if (this.props.customAction) {
                this.props.customAction();
            }
        }
    };

    render() {
        const filterControl = this.props.filterControl;
        const filterIndex = filterControl.FilterIndex;
        if (!isVisible(filterControl, this.props.searchTypeCode)) {
            return null;
        }
        const close = this.state.hidden
            ? formatTranslated('Show_Label', this.props.translations.Phrases)
            : formatTranslated('Hide_Label', this.props.translations.Phrases);
        const isUtc = filterControl.Attribute === '_imageDate';
        return (
            <div className='filter-section'>
                <div className='row header-section'>
                    <h4 className='col-sm-8 text-left header'>{formatTranslated('Date_Label', this.props.translations.Phrases)}</h4>
                    <div className='col-sm-4 text-right'>
                        <span className='btn btn-link' onClick={this.toggleSection}>
                            {close}
                        </span>
                    </div>
                </div>
                <div className={this.state.hidden ? 'd-none' : 'd-block'}>
                    <div className='filter-dropdown'>
                        <CustomDropDown
                            name={'Filters.' + filterIndex + '.DateType'}
                            options={filterControl.Options}
                            valueField='Value'
                            textField='TranslatedName'
                            onItemChanged={this.filterOptionSelect}
                        />
                    </div>
                    <div className='input-container no-border'>
                        <div className='input-body'>
                            <span className='btn btn-link' onClick={() => this.setDate(24 * 30)}>
                                {formatTranslated('Last30Days_Label', this.props.translations.Phrases)}
                            </span>
                        </div>
                        <div className='input-body'>
                            <span className='btn btn-link' onClick={() => this.setDate(24 * 7)}>
                                {formatTranslated('Last7Days_Label', this.props.translations.Phrases)}
                            </span>
                        </div>
                        <div className='input-body'>
                            <span className='btn btn-link' onClick={() => this.setDate(24 * 2)}>
                                {formatTranslated('Last2Days_Label', this.props.translations.Phrases)}
                            </span>
                        </div>
                        <div className='input-body'>
                            <span className='btn btn-link' onClick={this.clearDates}>
                                {formatTranslated('Clear_Label', this.props.translations.Phrases)}
                            </span>
                        </div>
                    </div>
                    <DatePickerModal
                        name={'Filters.' + filterIndex + '.RangeStart'}
                        date={filterControl.RangeStart}
                        label={formatTranslated('From_Label', this.props.translations.Phrases)}
                        noBorder
                        linkText={formatTranslated('SelectDate_Label', this.props.translations.Phrases)}
                        dateLabel=''
                        onDateChanged={this.props.onValueChanged}
                        customFormat={(x) => {
                            return isUtc
                                ? dayjs(x).utc().startOf('day').toDate().toISOString()
                                : dayjs(x).startOf('day').format('YYYY-MM-DD');
                        }}
                        customAction={this.props.customAction}
                    />
                    <DatePickerModal
                        name={'Filters.' + filterIndex + '.RangeEnd'}
                        date={filterControl.RangeEnd}
                        label={formatTranslated('To_Label', this.props.translations.Phrases)}
                        noBorder
                        linkText={formatTranslated('SelectDate_Label', this.props.translations.Phrases)}
                        dateLabel=''
                        onDateChanged={this.props.onValueChanged}
                        customFormat={(x) => {
                            return isUtc
                                ? dayjs(x).utc().endOf('day').toDate().toISOString()
                                : dayjs(x).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
                        }}
                        customAction={this.props.customAction}
                    />
                    <hr />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(PrimaryDateFilter);
