import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'react-string-format';
import { RootState } from '../../redux/store';
import { TranslationsSlice } from '../models/ReduxSlices';

const useTranslation = () => {
    const { translations } = useSelector<RootState, TranslationsSlice>((state) => state.translations);

    const translate = useCallback(
        function (key?: null | string, ...subs: any) {
            // If key is null or undefined just return empty string
            if (!key) {
                return '';
            }

            const indexDouble = key.indexOf('@@');

            let translatedArr;
            if (indexDouble >= 0) {
                translatedArr = key.split('@@');
                key = translatedArr[0];
            } else {
                translatedArr = Array.prototype.slice.call(arguments);
                key = translatedArr.shift();
            }

            const translatedPhrase = key ? translations?.Phrases[key] : '';

            if (!translatedPhrase) {
                return `Untranslated: [${key}]`;
            } else if (subs.length > 0) {
                return format(translatedPhrase.trim(), ...subs);
            } else if (translatedArr.length === 3) {
                return format(translatedPhrase.trim(), translatedArr[1], translatedArr[2]);
            } else if (translatedArr.length === 2) {
                return format(translatedPhrase.trim(), translatedArr[1]);
            } else {
                return translatedPhrase.trim();
            }
        },
        [translations?.Phrases]
    );

    return translate;
};

export default useTranslation;
