import React, { useEffect } from 'react';

interface UseModalActionsProps {
    ref: React.RefObject<HTMLDivElement>;
    onEscape?: () => void;
}

const useModalActions = ({ ref, onEscape }: UseModalActionsProps) => {
    // On inital load set focus on modal
    useEffect(() => {
        ref.current?.focus();
    }, [ref]);

    useEffect(() => {
        const modalElement = ref?.current ?? document.body;

        const getFocusableElements = () => {
            return (
                ref.current?.querySelectorAll(
                    [
                        'a',
                        'button:not([disabled])',
                        'input:not([disabled])',
                        'select:not([disabled])',
                        'textarea:not([disabled])',
                        'details',
                        '[tabindex]:not([tabindex^="-"])'
                    ].join(',')
                ) ?? []
            );
        };

        const handleKeyDown = (e: KeyboardEvent) => {
            const pressingEscape = e.key === 'Escape';
            const pressingShift = e.shiftKey;
            const pressingTab = e.key === 'Tab';

            if (pressingEscape && onEscape) {
                onEscape();
            } else if (pressingTab || pressingShift) {
                const elements = getFocusableElements();

                if (elements.length === 0) {
                    return;
                }

                const firstElement = elements[0];
                const lastElement = elements[elements?.length - 1];
                // Rotate to the top of focusable list
                if (pressingTab && !pressingShift && e.target === lastElement) {
                    e.preventDefault();
                    (firstElement as HTMLElement)?.focus();
                }
                // Rotate to the bottom of focusable list
                if (pressingShift && pressingTab && e.target === firstElement) {
                    e.preventDefault();
                    (lastElement as HTMLElement)?.focus();
                }
            }
        };

        modalElement.addEventListener('keydown', handleKeyDown);

        return () => {
            modalElement.removeEventListener('keydown', handleKeyDown);
        };
    }, [ref, onEscape]);
};

export default useModalActions;
