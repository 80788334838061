import PropTypes from 'prop-types';
import React, { Component } from 'react';

class ModalClientPicker extends Component {
    state = {
        isOpen: this.props.isOpen
    };

    static defaultProps = {
        isOpen: false,
        CancelButtonText: 'Cancel',
        OkButtonText: 'OK'
    };
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        onRequestConfirm: PropTypes.func,
        CancelButtonText: PropTypes.string,
        OkButtonText: PropTypes.string,
        customAction: PropTypes.func
    };

    onRequestClose = () => {
        if (this.props.customAction !== undefined) {
            this.props.customAction();
        }
    };
    render() {
        return (
            <div className={this.props.isOpen ? 'modal modal-overlay d-block' : 'modal fade d-none'} role='dialog' aria-hidden='false'>
                <div className='modal-dialog client-picker-modal'>
                    <div className='modal-content'>
                        <div id='client-chooser-loader'>
                            <div className='spinner'></div>
                        </div>
                        <div className='modal-body'>
                            <div className='row'>
                                <div className='col-12'>{this.props.children}</div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <div className='col-sm-6 col-12'>
                                <button type='button' className='button grey large full-width' onClick={this.props.onRequestClose}>
                                    {this.props.CancelButtonText}
                                </button>
                            </div>
                            <div className='col-sm-6 col-12'>
                                <button type='button' className='button blue large full-width' onClick={this.props.onRequestConfirm}>
                                    {this.props.OkButtonText}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ModalClientPicker;
