import { API_CLEARANCES_PATH } from '../../../../common/constants/constants-portal';
import { apiSlice } from '../../../../redux/api/apiSlice';

interface SaveShipmentDataSourceBody {
    PreferenceValue: string;
    Country: string;
}

export const clearancesApi = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getShipmentDataSource: build.query<string, string>({
            query: (id) => ({
                url: `${API_CLEARANCES_PATH}/clearance/get-shipment-data-source/${id}`
            })
        }),
        saveShipmentDataSource: build.mutation<string, SaveShipmentDataSourceBody>({
            query: (body) => ({
                url: `${API_CLEARANCES_PATH}/clearance/save-shipment-data-source`,
                method: 'POST',
                body
            })
        })
    })
});

export const { useGetShipmentDataSourceQuery, useLazyGetShipmentDataSourceQuery, useSaveShipmentDataSourceMutation } = clearancesApi;
