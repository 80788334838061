import { useEffect, useState } from 'react';

interface SelectionChangeFilterDropdownProps {
    selectedValues: number[];
    selection: null | number[];
}

const useSelectionChangeFilterDropdown = ({ selectedValues, selection }: SelectionChangeFilterDropdownProps) => {
    const [hasChanged, setHasChanged] = useState(false);
    useEffect(() => {
        let result: boolean[] = [];
        if (!selectedValues.length && !selection?.length) {
            setHasChanged(true);
        } else if (selectedValues.length === 1 && !selection?.length) {
            setHasChanged(false);
        } else {
            const newSelected = [...selectedValues].sort();
            const currentSelection = selection?.length ? [...selection].sort() : [];
            result = [
                ...(newSelected.length > currentSelection.length ? newSelected : currentSelection).map((val) =>
                    (newSelected.length > currentSelection.length ? currentSelection : newSelected)!.includes(val)
                )
            ];
            result.includes(false) ? setHasChanged(false) : setHasChanged(true);
        }
    }, [selectedValues, selection]);

    return { hasChanged };
};

export default useSelectionChangeFilterDropdown;
