import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatTranslated, isVisible } from '../../utils/mlp-utils';
import TextBox from '../Textbox/Textbox';

class TextFilter extends Component {
    static defaultProps = {
        countryCodes: ['CA', 'US'],
        searchTypeCode: '*'
    };

    static propTypes = {
        countryCodes: PropTypes.arrayOf(PropTypes.string),
        searchTypeCode: PropTypes.string,
        onValueChanged: PropTypes.func,
        customAction: PropTypes.func
    };

    state = { valueIsValid: true, numType: '' };

    onValueChanged = (value, name) => {
        this.props.onValueChanged(value, name);
        const filterControl = this.props.filterControl,
            filterControlAttributeDataType = filterControl.AttributeDataType,
            filterControlValue = value,
            selectedSearchType = this.props.selectedSearchType,
            decReSnippet = '^(-?\\d*\\.?\\d*$)$',
            intReSnippet = '^(-?\\d*$)$',
            decAttrs = ['Decimal', 'Double'],
            intAttrs = ['Integer', 'Long'];

        if (selectedSearchType && selectedSearchType.ImageTypeGroupName === 'Imaging') {
            let valueIsValid = true;
            let numType = '';
            if (
                filterControlValue &&
                filterControlValue.length > 0 &&
                (decAttrs.indexOf(filterControlAttributeDataType) > -1 || intAttrs.indexOf(filterControlAttributeDataType) > -1)
            ) {
                let regExp;
                if (decAttrs.indexOf(filterControlAttributeDataType) > -1) {
                    regExp = new RegExp(decReSnippet);
                    numType = 'Decimal';
                } else {
                    regExp = new RegExp(intReSnippet);
                    numType = 'Integer';
                }
                valueIsValid = regExp.test(filterControlValue) ? true : false;
            }
            this.setState({ valueIsValid: valueIsValid, numType: numType });
            this.props.onValidate(name, valueIsValid);
            const otherInvalidFilters = this.props.invalidTextFilters.filter((x) => x !== name);
            if (valueIsValid && otherInvalidFilters.length === 0) {
                this.props.customAction();
            }
        }
    };

    render() {
        const filterControl = this.props.filterControl,
            filterIndex = filterControl.FilterIndex,
            filterControlValue = filterControl.Value,
            filterControlName = filterControl.Name;
        const customFunc = this.props.selectedSearchType.ImageTypeGroupName === 'Imaging' ? undefined : this.props.customAction;
        if (!isVisible(filterControl, this.props.searchTypeCode)) {
            return null;
        }

        const name = 'Filters.' + filterIndex + '.Value';

        const valMsg = this.state.valueIsValid
            ? ''
            : formatTranslated('Invalid_' + this.state.numType + '_Value', this.props.translations.Phrases);

        return (
            <div>
                <TextBox
                    name={name}
                    value={filterControlValue}
                    label={formatTranslated(filterControlName, this.props.translations.Phrases)}
                    valMsg={valMsg}
                    onValueChanged={this.onValueChanged}
                    customAction={customFunc}
                    fireOnChange={true}
                    fireOnImageBlur={true}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(TextFilter);
