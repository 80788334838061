import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import InputBase from '../Inputbase/Inputbase';

class TimeComponent extends Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.string,
        placeholder: PropTypes.string,
        timeLabel: PropTypes.string,
        valMsg: PropTypes.string,
        onTimeChanged: PropTypes.func
    };

    inputElementRef = React.createRef();

    onSelect = (time) => {
        this.props.onTimeChanged(time);
    };

    handleChange = (e) => {
        this.props.onTimeChanged(e.target.value);
    };

    validateFormat = (e) => {
        const isValid = /^(0[0-9]|1[0-2]):([0-5][0-9])?$/.test(e.target.value);

        if (isValid && e.target.value < '13:00') {
            this.props.onTimeChanged(e.target.value);
        } else {
            this.props.onTimeChanged('');
            if (e.target.value !== '') {
                let validationFailedMessage = this.props.translations.Phrases['Notices_StartTimeFormatErr'];
                if (this.props.name.split('.')[2] === 'EffectiveTimePartTo') {
                    validationFailedMessage = this.props.translations.Phrases['Notices_EndTimeFormatErr'];
                }
                toastr.error(validationFailedMessage);
            }
        }
    };

    render() {
        let time = '';

        if (this.props.value) {
            time = this.props.value;
        }
        let timeArray = [];
        for (let i = 0; i < 12; i++) {
            for (let j = 0; j < 4; j++) {
                timeArray.push((i === 0 ? '12' : i) + ':' + (j === 0 ? '00' : 15 * j));
            }
        }
        const timeList = timeArray.map((item, i) => {
            return (
                <li key={item + '-' + i} onClick={() => this.onSelect(item)} className='dropdown-menu-item' value={item}>
                    {item}
                </li>
            );
        });

        const icon = <i id='date-picker-clock-icon' className='fa fa-clock-o fa-lg' />;

        const labelClass = this.props.valMsg ? 'date_time-field-validation-error' : '';
        return (
            <div className='col-sm-4'>
                <InputBase {...this.props}>
                    <label htmlFor='inputElement' className={labelClass}>
                        {this.props.valMsg ? this.props.translations.Phrases[this.props.valMsg] : this.props.timeLabel}
                    </label>
                    <div className='input-group'>
                        <input
                            ref={this.inputElementRef}
                            placeholder={this.props.placeholder}
                            type={this.props.type}
                            name={this.props.name}
                            value={time}
                            className='form-control'
                            disabled={this.props.disabled}
                            readOnly={this.props.readonly}
                            onChange={(event) => this.handleChange(event)}
                            onBlur={(event) => this.validateFormat(event)}
                        />
                        <div className='input-group-btn'>
                            <button
                                className='date-time-button action-button white dropdown-toggle'
                                data-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                            >
                                {icon}
                            </button>
                            <ul id='time-picker-dropdown' className='dropdown-menu dropdown-menu-right'>
                                {timeList}
                            </ul>
                        </div>
                    </div>
                </InputBase>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(TimeComponent);
