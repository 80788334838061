import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <div className='lii_section-background lii_blue-background' style={{ minHeight: 75 }}>
                <div className='lii_footer-border'></div>
                <div className='container-fluid'>
                    <p className='lii_footer-copyright-new'>© {new Date().getFullYear()} Livingston International</p>
                </div>
            </div>
        );
    }
}

export default Footer;
