import { useCallback, useEffect, useState } from 'react';
import { ShipmentsCASS } from '../models/completeEntryDataModels';

interface UseHeaderSelectionProps {
    allEntries: ShipmentsCASS[];
    selectedEntries: string[] | [];
    dispatchSelection: (payload: boolean) => void;
}

const useHeaderSelection = ({ allEntries, selectedEntries, dispatchSelection }: UseHeaderSelectionProps) => {
    const [headerCheckbox, setHeaderCheckbox] = useState(false);
    useEffect(() => {
        const uniqueAll = allEntries.filter((a, i) => allEntries.findIndex((s) => a.TransactionNumber === s.TransactionNumber) === i);
        const allEntriesLength = uniqueAll.length;

        const currentPageSelectedEntries = selectedEntries.filter((no) => allEntries.find((entry) => entry.TransactionNumber === no));

        setHeaderCheckbox(currentPageSelectedEntries.length === allEntriesLength && allEntriesLength ? true : false);
    }, [selectedEntries, allEntries]);

    const handleHeaderCheckbox = useCallback(() => {
        setHeaderCheckbox(!headerCheckbox);
        dispatchSelection(headerCheckbox);
    }, [headerCheckbox, dispatchSelection]);

    return { headerCheckbox, handleHeaderCheckbox };
};

export default useHeaderSelection;
