import { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

class ParentServiceHeader extends Component {
    allFeaturesSelected = (featureId) => {
        return this.props.parentService.ClientServices.map((s) => {
            return s.Features.find((f) => {
                return f.FeatureId === featureId;
            });
        })
            .filter((f) => {
                return f;
            })
            .every((f) => {
                return f.IsSelected;
            });
    };

    selectAllFeatures = (featureId) => {
        this.props.onSelectAllFeatures(this.props.parentIndex, featureId);
    };
    render = () => {
        let featuresHeader = [];
        let selectAllHeader = [];

        this.props.availableFeatures.forEach((f) => {
            const selectAllText = this.allFeaturesSelected(f.FeatureId)
                ? this.props.translations.Phrases['SelectNone_Label']
                : this.props.translations.Phrases['SelectAll_Label'];

            featuresHeader.push(
                <th key={'availableFeatures-' + f.FeatureId} className='text-center'>
                    {f.Name}
                </th>
            );
            selectAllHeader.push(
                <td key={'selectAll-' + f.FeatureId} className='text-center'>
                    <span className='btn btn-link' onClick={this.selectAllFeatures.bind(this, f.FeatureId)}>
                        {selectAllText}
                    </span>
                </td>
            );
        });

        const selectAllRow = this.props.readonly ? null : (
            <tr>
                <td colSpan='2'></td>
                {selectAllHeader}
            </tr>
        );

        return (
            <thead>
                <tr>
                    <th>{this.props.translations.Phrases['ServiceNumber_Label']}</th>
                    <th>{this.props.translations.Phrases['ServiceName_Label']}</th>
                    {featuresHeader}
                </tr>
                {selectAllRow}
            </thead>
        );
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectAllFeatures: (parentIndex, featureId) => dispatch(actions.selectAllFeatures(parentIndex, featureId))
    };
};
const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ParentServiceHeader);
