import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SubmitButton from '../../../common/SubmitButton/SubmitButton';
import Textbox from '../../../common/Textbox/Textbox';
import * as actionCreators from '../../../store/actions/index';

class FeatureSetupForm extends Component {
    componentDidMount = () => {
        this.props.onInitTranslations('ClientAdmin.Signup.FeatureSetup');

        const requestId = this.props.location.search.substring(
            this.props.location.search.indexOf('=') + 1,
            this.props.location.search.length
        );
        this.props.onGetFeatureSetup(requestId, this.props.history);
    };

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
        if (this.props.model.Redirect) {
            this.props.history.push({
                pathname: '/SuccessfullSubmit',
                state: {
                    backToPath: '/Dashboard',
                    backToLabel: 'BackToDashboard_Label',
                    phraseGroup: 'ClientAdmin.Signup.SuccessfulFeatures'
                }
            });
        }
    }

    /* onChangeFeatureUsername = (value) => {
        this.props.changeFeatureUsername(value);
    };*/

    componentWillUnmount = () => {
        this.props.onResetFeatureSetupState();
    };

    render() {
        if (this.props.model.RequestId.length > 0 && this.props.translations.PhraseGroupCode === 'ClientAdmin.Signup.FeatureSetup') {
            const usernameLabel = this.props.model.Feature.ParentName + ' ' + this.props.translations.Phrases['SystemUsername_Label'];
            const clientNumbers = this.props.model.ClientServiceNumbers.join(', ');

            let featureName = '';
            if (this.props.model.Feature != null) {
                featureName = this.props.model.Feature.ParentName ? this.props.model.Feature.ParentName : this.props.model.Feature.Name;
            }

            const systemName = featureName + ' ' + this.props.translations.Phrases['Page_Header'];

            return (
                <div id='page'>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.props.translations.Phrases['Page_Title']}</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className='page-wrapper'>
                        <div className='page-top-row'>
                            <div className='page-header-section'>
                                <nav className='mlp-breadcrumb'>
                                    <Link to='/Dashboard' className='breadcrumb-parent'>
                                        {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                    </Link>
                                    <i className='fa fa-caret-right'></i>
                                    <Link to='#/' className='breadcrumb-parent'>
                                        {this.props.translations.Phrases['Page_Header_Navigation_Level_2']}
                                    </Link>
                                    <i className='fa fa-caret-right'></i>
                                    <span> {this.props.translations.Phrases['Page_Header_Navigation_Level_3']}</span>
                                </nav>
                                <h1 className='bold-title'>{systemName}</h1>
                            </div>
                        </div>
                        <div className='content-wrapper'>
                            <div className='row page-content'>
                                <div id='feature-setup-form'>
                                    <div className='row'>
                                        <div className='col-md-6 col-sm-12'>
                                            <Textbox
                                                readonly={true}
                                                label={this.props.translations.Phrases['SystemName_Label']}
                                                value={this.props.model.Feature.ParentName}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 col-sm-12'>
                                            <Textbox
                                                readonly={true}
                                                label={this.props.translations.Phrases['MLPUsername_Label']}
                                                value={this.props.model.UserName}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 col-sm-12'>
                                            <Textbox
                                                readonly={true}
                                                label={this.props.translations.Phrases['ClientNumbers_Label']}
                                                value={clientNumbers}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6 col-sm-12'>
                                            <Textbox
                                                readonly={true}
                                                label={usernameLabel}
                                                name='FeatureUsername'
                                                value={this.props.model.FeatureUsername}
                                                valMsg={this.props.translations.Phrases[this.props.validationMessages.FeatureUsername]}
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <SubmitButton
                                            label={this.props.translations.Phrases['Submit_Button']}
                                            model={this.props.model}
                                            postActionUrl='ClientAdmin/FeatureSetup'
                                            postAction={this.props.featureSetupSetValidationMessages}
                                            onSuccessfulSubmit={{
                                                backToPath: '/Dashboard',
                                                backToLabel: 'BackToDashboard_Label',
                                                phraseGroup: 'ClientAdmin.Signup.SuccessfulFeatures'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div className='page-wrapper'></div>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model,
        model: state.featureSetup.model,
        validationMessages: state.featureSetup.validationMessages
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup)),
        onGetFeatureSetup: (requestId, history) => dispatch(actionCreators.getFeatureSetup(requestId, history)),
        featureSetupSetValidationMessages: (validationMessages) =>
            dispatch(actionCreators.featureSetupSetValidationMessages(validationMessages)),
        onResetFeatureSetupState: () => dispatch(actionCreators.resetStateFeatureSetup())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeatureSetupForm);
