import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../../axios-portal';
import { API_CASS_PATH } from '../../../../common/constants/constants-portal';
import { EntryClientPair } from '../../common/models/CADDetails';
import { GET_FULL_CAD_DATA } from './actionTypes';

export const GetFullCADData = createAsyncThunk(GET_FULL_CAD_DATA, async (payload: { request: EntryClientPair }) => {
    try {
        const response = await axios.post(`${API_CASS_PATH}/GetFullCADData`, payload.request);
        return response.data;
    } catch (error: any) {
        return error.response.status;
    }
});
