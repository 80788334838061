import { Component } from 'react';
import { connect } from 'react-redux';
import ModalConfirm from '../../common/Modal/ModalConfirm';

class BatchCancelModal extends Component {
    state = { isOpen: false };

    openModal = () => {
        this.setState({ isOpen: true });
    };
    closeModal = () => {
        this.setState({ isOpen: false });
    };

    render() {
        return (
            <span className='batch-cancel'>
                <ModalConfirm
                    isOpen={this.state.isOpen}
                    onRequestConfirm={this.props.cancelAction}
                    onRequestClose={this.closeModal}
                    OkButtonText={this.props.okButtonText}
                    CancelButtonText={this.props.cancelButtonText}
                    title={this.props.title}
                >
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h4>{this.props.translations.Phrases['IfCancel_Label']}</h4>
                            <br />
                            <h4>{this.props.translations.Phrases['ReallyCancel_Label']}</h4>
                        </div>
                    </div>
                </ModalConfirm>
                <span className='btn btn-link' onClick={this.openModal}>
                    {this.props.btnText}
                </span>
            </span>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(BatchCancelModal);
