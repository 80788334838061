import React from 'react';
import useInputToggleCheck from '../hooks/useInputToggleCheck';

interface CheckboxProps {
    id: string;
    name: string;
    isChecked?: boolean;
    children?: React.ReactNode;
    divClass?: string;
    divStyle?: React.CSSProperties;
    inputClass?: string;
    inputStyle?: React.CSSProperties;
    labelClass?: string;
    activeLabel?: string;
    disabled?: boolean;
    labelStyle?: React.CSSProperties;
    onChange?: (key: string, value: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
    id,
    isChecked,
    children,
    divClass = '',
    divStyle = undefined,
    labelClass = 'custom-control-label',
    inputClass = 'custom-control-input',
    inputStyle = undefined,
    activeLabel = '',
    disabled,
    name,
    labelStyle = undefined,
    
    onChange
}) => {
    const { checked, handleChange } = useInputToggleCheck({ isChecked, onChange });

    return (
        <div className={divClass} style={divStyle}>
            <input
                type='checkbox'
                className={inputClass}
                style={disabled ? { ...inputStyle, cursor: 'default' } : inputStyle}
                name={name}
                id={id}
                checked={checked}
                onChange={handleChange}
                disabled={disabled}
            />
            <label className={`${labelClass} ${activeLabel}`} style={labelStyle} htmlFor={id}>
                {children}
            </label>
        </div>
    );
};

export default Checkbox;
