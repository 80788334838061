import PropTypes from 'prop-types';
import React, { Component } from 'react';

class ToggleIcon extends Component {
    static defaultProps = {
        isOn: false,
        img_class: ''
    };

    static propTypes = {
        isOn: PropTypes.bool,
        imgClass: PropTypes.string,
        selectedIcon: PropTypes.string.isRequired,
        unselectedIcon: PropTypes.string.isRequired
    };

    getIconUrl = (isOn) => {
        return isOn ? this.props.selectedIcon : this.props.unselectedIcon;
    };

    render() {
        return (
            <div>
                <img className={this.props.imgClass} src={this.getIconUrl(this.props.isOn)} alt='Icon' />
            </div>
        );
    }
}

export default ToggleIcon;
