import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ImagingFiltersSlice } from '../../common/models/ReduxSlices';

const initialState: ImagingFiltersSlice = {
    documentTypeSelectedIndexes: [],
    documentTypeSelectedValues: [],
    documentTypeObject: {},
    documentStatusSelectedIndexes: [],
    documentStatusObject: {},
    documentStatusSelectedValues: [],
    documentMotSelectedIndexes: [],
    documentMotSelectedValues: []
};

const imagingFiltersSlice = createSlice({
    name: 'imagingFiltersSlice',
    initialState,
    reducers: {
        setDocumentTypesSelected(state, action: PayloadAction<number[]>) {
            state.documentTypeSelectedIndexes = action.payload;
        },
        setDocumentTypeSelectedValues(state, action: PayloadAction<string[]>) {
            state.documentTypeSelectedValues = action.payload;
        },
        setDocumentTypeObject(state, action: PayloadAction<{ [key: string]: number }>) {
            state.documentTypeObject = action.payload;
        },

        setDocumentStatusIndexes(state, action: PayloadAction<number[]>) {
            state.documentStatusSelectedIndexes = action.payload;
        },
        setDocumentStatusObject(state, action: PayloadAction<{ [key: string]: number }>) {
            state.documentStatusObject = action.payload;
        },
        setDocumentStatusSelectedValues(state, action: PayloadAction<string[]>) {
            state.documentStatusSelectedValues = action.payload;
        },
        setDocumentMotSelectedIndexes(state, action: PayloadAction<number[]>) {
            state.documentMotSelectedIndexes = action.payload;
        },
        setDocumentMotSelectedValues(state, action: PayloadAction<string[]>) {
            state.documentMotSelectedValues = action.payload;
        },
        resetDocumentsDropdownFilters() {
            return { ...initialState };
        }
    }
});

export const {
    setDocumentTypesSelected,
    setDocumentTypeSelectedValues,
    setDocumentTypeObject,
    setDocumentStatusIndexes,
    setDocumentStatusObject,
    setDocumentStatusSelectedValues,
    setDocumentMotSelectedIndexes,
    setDocumentMotSelectedValues,
    resetDocumentsDropdownFilters
} = imagingFiltersSlice.actions;

export default imagingFiltersSlice.reducer;
