import { useCallback, useEffect, useState } from 'react';

const useInput = (initial?: null | string) => {
    const [value, setValue] = useState(initial || '');

    useEffect(() => {
        if (initial) {
            setValue(initial);
        } else {
            setValue('');
        }
    }, [initial]);

    const handleChange = (value: string) => {
        setValue(value);
    };

    const handleReset = useCallback((value?: null | string) => {
        setValue(value || '');
    }, []);

    return {
        value,
        handleChange,
        handleReset
    };
};

export default useInput;
