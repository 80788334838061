import type { Placement } from '@popperjs/core';
import React, { cloneElement, isValidElement, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { generateUUID } from '../../functions/utils';
import { useDebounce } from '../../hooks/useDebounce';
import classes from './Tooltip.module.css';

interface TooltipProps {
    label: string;
    children: React.ReactNode;
    placement?: Placement;
    offset?: number;
    delay?: number;
}

type ElementWithRef = React.ReactElement & {
    ref?: React.Ref<HTMLElement>;
};

function mergeRefs<T>(...refs: (React.Ref<T> | null)[]): React.RefCallback<T> {
    return (value: T | null) => {
        refs.forEach((ref) => {
            if (typeof ref === 'function') {
                ref(value);
            } else if (ref !== null && ref !== undefined) {
                (ref as React.MutableRefObject<T | null>).current = value;
            }
        });
    };
}

const Tooltip: React.FC<TooltipProps> = ({ label, placement = 'top', children, offset = 5, delay = 0 }) => {
    const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);

    const [tooltipId] = useState(`tooltip-${generateUUID()}`);
    const [isVisible, setIsVisible] = useState(false);
    const { styles, attributes, update, state } = usePopper(referenceElement, popperElement, {
        placement: placement,
        modifiers: [
            { name: 'offset', options: { offset: [0, offset] } },
            { name: 'arrow', options: { element: arrowElement } }
        ]
    });

    const parentContainer = document.querySelector('#portalTooltip') || document.body;

    useEffect(() => {
        if (update) update();
    }, [update]);

    const debounce = useDebounce(delay);

    const showTooltip = () => {
        debounce(() => {
            setIsVisible(true);
        });
    };

    const hideTooltip = () => {
        setIsVisible(false);
    };

    if (!isValidElement(children)) return null;

    const childrenWithRef = children as ElementWithRef;

    const getStaticSide = (placement: Placement) => {
        return {
            top: 'bottom',
            right: 'left',
            bottom: 'top',
            left: 'right'
        }[placement.split('-')[0]] as string;
    };

    const staticSide = getStaticSide(state?.placement || placement);

    return (
        <>
            {cloneElement(children as React.ReactElement, {
                ...children.props,
                ref: childrenWithRef.ref ? mergeRefs(setReferenceElement, childrenWithRef.ref) : setReferenceElement,
                onMouseEnter: (e: MouseEvent) => {
                    showTooltip();

                    if (children.props.onMouseEnter) {
                        children.props.onMouseEnter(e);
                    }
                },
                onMouseLeave: (e: MouseEvent) => {
                    hideTooltip();

                    if (children.props.onMouseLeave) {
                        children.props.onMouseLeave(e);
                    }
                },
                onFocus: (e: FocusEvent) => {
                    showTooltip();

                    if (children.props.onFocus) {
                        children.props.onFocus(e);
                    }
                },
                onKeyDown: (e: KeyboardEvent) => {
                    if (e.key === 'Escape') {
                        hideTooltip();
                    }
                    if (children.props.onKeyDown) {
                        children.props.onKeyDown(e);
                    }
                },
                onBlur: (e: FocusEvent) => {
                    hideTooltip();

                    if (children.props.onBlur) {
                        children.props.onBlur(e);
                    }
                },
                className: `${children.props.className || ''} ${classes['tooltip-trigger']}`,
                'aria-describedby': tooltipId,
                tabIndex: 0
            })}
            {isVisible &&
                createPortal(
                    <div
                        className={`${classes['tooltip-content']} ${classes['fade-in']}`}
                        ref={setPopperElement}
                        style={{ ...styles.popper, maxWidth: '30vw' }}
                        {...attributes.popper}
                        role='tooltip'
                    >
                        {label}
                        <div ref={setArrowElement} style={{ ...styles.arrow, [staticSide]: '-4px' }} className={classes.arrow} />
                    </div>,
                    parentContainer
                )}
        </>
    );
};

export default Tooltip;
