import $ from 'jquery';
import axios from '../../../axios-mlp';
import * as actionTypes from './batchCreateUsersActionTypes';

export const getBatchJobCreate = (batchJobId) => {
    return (dispatch) => {
        let params = new URLSearchParams();
        params.append('batchJobId', batchJobId);

        axios.get('/BatchUser/GetBatchJobById', { params: params }).then((response) => {
            dispatch(afterGetBatchJobCreate(response.data));
        });
    };
};

export const initBatchJobCreate = (batchJobId) => {
    return (dispatch) => {
        $('#overlay').show();
        let params = new URLSearchParams();
        params.append('batchJobId', batchJobId);

        axios
            .get('/BatchUser/Index', { params: params })
            .then((response) => {
                dispatch(afterGetBatchJobCreate(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const confirmBatchJobCreate = (batchJobId) => {
    return (dispatch) => {
        $('#overlay').show();
        let params = new URLSearchParams();
        params.append('batchJobId', batchJobId);

        axios
            .post('/BatchUser/ConfirmUserCreation', null, { params: params })
            .then((response) => {
                dispatch(afterGetBatchJobCreate(response.data));
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const cancelBatchJobCreate = (batchJobId) => {
    return (dispatch) => {
        $('#overlay').show();
        let params = new URLSearchParams();
        params.append('batchJobId', batchJobId);

        axios
            .post('/BatchUser/RejectBatchJob', null, { params: params })
            .then((response) => {
                dispatch(afterGetBatchJobCreate(response.data));
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const afterGetBatchJobCreate = (model) => {
    return {
        type: actionTypes.AFTER_GET_BATCH_JOB_CREATE,
        payload: { model: model }
    };
};

export const clearStateBatchJobCreate = () => {
    return {
        type: actionTypes.CLEAR_STATE
    };
};
