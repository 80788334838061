import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../../../../common/features/Button';
import Checkbox from '../../../../../common/features/Checkbox';
import DropdownMenuButton from '../../../../../common/features/DropdownMenuButton';
import useDropdownToggle from '../../../../../common/hooks/useDropdownToggle';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { useCompleteEntryDataDispatch, useCompleteEntryDataSelector } from '../../../redux/hooks';
import { updateSelectLocationFilter } from '../../../redux/reducers/completeEntryDataSlice';
import {
    cancelSelectedItems,
    setApplySelectLocation,
    setIsLocationSelectOpen,
    toggleCheckbox,
    updateApplyLocationFiltered
} from '../../../redux/reducers/locationFilterSlice';

interface Location {
    Name: string;
    SearchTypeCodes: string[];
    Value: string;
    DataType: string;
    IsSelected: boolean;
    SortValue: string | null;
}

const DropdownLocationFilter = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchedOptions, setSearchedOptions] = useState<Location[]>([]);

    const {
        locationTypeSelected,
        checkedNameItems,
        checkedIdItems,
        isLocationFiltered,
        appliiedCheckedItemsNames,
        selectedLocationLabels,
        applySelectLocation,
        appliedLocationTypeSelected,
        isLocationSelectOpen
    } = useCompleteEntryDataSelector((state) => state.locationFilter);
    const { model } = useCompleteEntryDataSelector((state) => state.completeEntryData);

    const dropdownMenuRef = useRef(null);
    const searchInputRef = useRef(null);
    const buttontRef = useRef(null);
    const applyButtonClickedRef = useRef(null);
    const [appliedLocationFilter, setAppliedLocationFilter] = useState<boolean>();

    const { isOpen: isApplyButtonClicked, handleToggle: handleApplyButtonClick } = useDropdownToggle(applyButtonClickedRef);
    const { isOutside, handleToggle: handleDropdownClicked } = useDropdownToggle(buttontRef);
    const { isOpen, handleToggle } = useDropdownToggle(dropdownMenuRef);

    const translate = useTranslation();
    const dispatch = useCompleteEntryDataDispatch();

    const locationTypeAttribute = locationTypeSelected?.attribute;
    const options = useMemo(() => {
        if (model && model.Filters && locationTypeAttribute) {
            const filter = model.Filters.find((filter) => filter.Attribute === locationTypeAttribute);
            if (filter) {
                // Sort options by isSelected property
                return filter.Options.slice().sort((a, b) => {
                    if (a.IsSelected && !b.IsSelected) return -1;
                    if (!a.IsSelected && b.IsSelected) return 1;
                    return 0;
                });
            }
        }
        return [];
    }, [model, locationTypeAttribute]);

    useEffect(() => {
        if (options && locationTypeAttribute) {
            let filteredItems;
            if (searchTerm.length >= 2) {
                filteredItems = options.filter((location) =>
                    `${location.Value} - ${location.Name}`.toLowerCase().includes(searchTerm.toLowerCase())
                );
            } else {
                filteredItems = options;
            }

            setSearchedOptions(filteredItems);
        }
    }, [searchTerm, locationTypeAttribute, options]);

    useEffect(() => {
        dispatch(setIsLocationSelectOpen(isOpen));
    }, [isOpen, dispatch]);
    const handleCancel = useCallback(() => {
        if (!isLocationFiltered) {
            dispatch(updateSelectLocationFilter({ attribute: locationTypeSelected?.attribute, selectedArray: [] }));
        } else {
            dispatch(cancelSelectedItems());
            dispatch(updateSelectLocationFilter({ attribute: locationTypeSelected?.attribute, selectedArray: selectedLocationLabels }));
        }
    }, [dispatch, isLocationFiltered, locationTypeSelected, selectedLocationLabels]);
    useEffect(() => {
        if (isLocationSelectOpen && !isApplyButtonClicked && isOutside && !isOpen && !applySelectLocation) {
            dispatch(cancelSelectedItems());
            dispatch(updateSelectLocationFilter({ attribute: locationTypeSelected?.attribute, selectedArray: selectedLocationLabels }));
        } else if (
            isLocationSelectOpen &&
            !isApplyButtonClicked &&
            isOutside &&
            selectedLocationLabels.length &&
            applySelectLocation &&
            !isOpen
        ) {
            dispatch(cancelSelectedItems());
            dispatch(updateSelectLocationFilter({ attribute: locationTypeSelected?.attribute, selectedArray: selectedLocationLabels }));
        } else if (isLocationSelectOpen && !isApplyButtonClicked && isOutside && !isOpen && applySelectLocation) {
            dispatch(cancelSelectedItems());
            dispatch(updateSelectLocationFilter({ attribute: locationTypeSelected?.attribute, selectedArray: selectedLocationLabels }));
        }
    }, [
        isOpen,
        dispatch,
        isApplyButtonClicked,
        applySelectLocation,
        selectedLocationLabels.length,
        isOutside,
        isLocationSelectOpen,
        locationTypeSelected?.attribute,
        selectedLocationLabels
    ]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        const areIdentical = JSON.stringify(appliiedCheckedItemsNames) === JSON.stringify(checkedNameItems);

        setAppliedLocationFilter(areIdentical);
    }, [appliiedCheckedItemsNames, checkedNameItems]);

    const handleLocationCheckboxChange = (checkboxName: string, checkboxId: string) => {
        dispatch(toggleCheckbox({ checkboxName, checkboxId }));

        dispatch(updateApplyLocationFiltered({ checkboxName }));
    };
    const handleApply = (attribute: string, selectedArray: string[], appliedArray: string[]) => {
        dispatch(updateSelectLocationFilter({ attribute, selectedArray }));
        dispatch(setApplySelectLocation({ isApplied: true, appliedItems: appliedArray, appliedItemsNames: selectedArray }));
    };

    return (
        <>
            <DropdownMenuButton
                label={
                    checkedNameItems.length > 0
                        ? `${checkedNameItems.length} ${translate('SelectedItems_Label')}`
                        : translate('SelectLocation_Label')
                }
                isOpen={isOpen}
                disabled={locationTypeAttribute ? false : true}
                onClick={() => {
                    handleToggle();
                    handleDropdownClicked();
                }}
                ref={dropdownMenuRef}
                buttonRef={buttontRef}
                btnClassName={'btn-sm dropdown-toggle ml-1 btn-block'}
            >
                <div className='card shadow-none'>
                    <div className={'border-bottom flex-grow-0 px-4 py-3'}>
                        <div className={'search-bar-wrapper align-self-center flex-md-grow-1'}>
                            <div className={'search-bar'}>
                                <div className={'input-group form-group align-items-center position-relative m-0'}>
                                    <input
                                        type={'text'}
                                        className={'main-desktop-input form-control search-input rounded-sm'}
                                        placeholder={translate(locationTypeSelected?.placeholder)}
                                        ref={searchInputRef}
                                        value={searchTerm}
                                        onChange={handleInputChange}
                                    />
                                    <div className={'magnifying-glass-input-icon'}>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </div>
                                    <div className={'position-relative'}>
                                        <span className={'main-desktop-form-clear form-clear d-none'}>
                                            <FontAwesomeIcon icon={faTimesCircle} />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='max-height-280'>
                        <div className='card-body px-4 py-3'>
                            {/* Provided dropdown options */}
                            {searchedOptions.map((option, index) => (
                                <label
                                    key={option.Value}
                                    className='d-flex justify-content-between align-items-stretch mb-2'
                                    htmlFor={option.Value}
                                >
                                    <Checkbox
                                        id={`${option.Name.replace(/[,\s]/g, '')}${option.Value}${locationTypeSelected?.attribute}`}
                                        name={option.Name}
                                        inputClass='custom-control-input mr-3'
                                        labelClass='custom-control-label text-lii-text font-weight-normal'
                                        divClass='custom-control custom-checkbox'
                                        isChecked={checkedIdItems.includes(
                                            `${option.Name.replace(/[,\s]/g, '')}${option.Value}${locationTypeSelected?.attribute}`
                                        )}
                                        onChange={() => {
                                            handleLocationCheckboxChange(
                                                option.Name,
                                                `${option.Name.replace(/[,\s]/g, '')}${option.Value}${locationTypeSelected?.attribute}`
                                            );
                                        }}
                                    >
                                        {`${option.Value} - ${option.Name}`}
                                    </Checkbox>
                                </label>
                            ))}
                        </div>
                        {/* Cancel and Apply */}
                    </div>
                </div>

                <div className='card shadow-none'>
                    <div className='card-body px-4 py-3 border-top'>
                        <div className='font-weight-medium mb-3'>
                            {checkedNameItems.length} {translate('SelectedItems_Label')}
                        </div>
                        <div className='d-flex align-items-center'>
                            <Button
                                variant='tertiary-blue'
                                className='mr-1 flex-fill'
                                children={translate('Cancel_Label')}
                                onClick={() => {
                                    handleToggle();
                                    dispatch(cancelSelectedItems());
                                    handleCancel();
                                }}
                            />
                            <Button
                                variant='primary'
                                className='ml-1 flex-fill'
                                ref={applyButtonClickedRef}
                                disabled={
                                    appliedLocationFilter ||
                                    (!checkedNameItems.length && locationTypeSelected?.attribute !== appliedLocationTypeSelected?.attribute)
                                }
                                onClick={() => {
                                    handleToggle();
                                    if (locationTypeSelected && locationTypeSelected.attribute) {
                                        handleApply(locationTypeSelected?.attribute, checkedNameItems, checkedIdItems);
                                        handleApplyButtonClick();
                                        setSearchTerm('');
                                    }
                                }}
                                children={translate('Apply_Label')}
                            />
                        </div>
                    </div>
                </div>
            </DropdownMenuButton>
        </>
    );
};

export default DropdownLocationFilter;
