import produce from 'immer';
import * as actionTypes from '../actions/teamManagement/teamManagementActionTypes';

const initialState = {
    model: {
        TeamName: null,
        Company: null,
        UserRole: null,
        UserPermissions: null,
        ShowAllUsers: false,
        TeamMembers: []
    },
    Error: null,
    ChangedProperties: []
};

const setCurrentUserTeamData = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.model;
        draft.Error = action.model.Error;
        draft.ChangedProperties = action.ChangedProperties;
    });
};

const setTeamMemberStatus = (state, action) => {
    return produce(state, (draft) => {
        draft.model.TeamMembers.find((element) => element.UserId === action.payload.userId).Status = action.payload.value;
    });
};

const setTeamMemberError = (state, action) => {
    return produce(state, (draft) => {
        draft.Error = action.payload.value;
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TEAM_DATA:
            return setCurrentUserTeamData(state, action);
        case actionTypes.SET_TEAM_MEMBER_STATUS:
            return setTeamMemberStatus(state, action);
        case actionTypes.SET_TEAM_MEMBER_ERROR:
            return setTeamMemberError(state, action);
        case actionTypes.RESET_TEAM_MANAGEMENT_STATE:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
