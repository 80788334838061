import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { formatTranslated, isVisible } from '../../utils/mlp-utils';
import CustomDropDown from '../CustomDropDown/CustomDropDown';
import TextBox from './../../common/Textbox/Textbox';
import { filters as Constants } from './../../constants/mlp-constants';

class MainSearchFilter extends Component {
    state = {
        allOptions: []
    };

    static defaultProps = {
        countryCodes: ['CA', 'US'],
        searchTypeCode: '*',
        filterButtonVisible: false,
        filtersVisible: false
    };

    static propTypes = {
        countryCodes: PropTypes.arrayOf(PropTypes.string),
        searchTypeCode: PropTypes.string,
        filterControl: PropTypes.object,
        filterButtonVisible: PropTypes.bool,
        filtersVisible: PropTypes.bool,
        customAction: PropTypes.func,
        onValueChanged: PropTypes.func,
        onOptionSelect: PropTypes.func,
        onToggleSection: PropTypes.func
    };

    componentDidMount() {
        this.initAllOptions();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filterControl.Options !== this.props.filterControl.Options) {
            this.initAllOptions();
        }
    }

    initAllOptions = () => {
        const allOptions = [
            {
                Value: 'All Types',
                Name: 'All Types',
                TranslatedName: formatTranslated('AllTypes_Label', this.props.translations.Phrases),
                IsSelected: false
            }
        ];

        this.props.filterControl.Options.forEach((x) => {
            allOptions.push(x);
        });

        if (this.props.filterControl.Options.every((e) => !e.IsSelected)) {
            allOptions[0].IsSelected = true;
        }

        this.setState({ allOptions: allOptions });
    };

    filterOptionSelect = (newIndex, oldIndex, name) => {
        this.props.onOptionSelect(newIndex - 1, oldIndex - 1, name);

        const filterControl = this.props.filterControl;
        const filterIndex = filterControl.FilterIndex;
        const selectedItem = filterControl.Options[newIndex - 1];

        const nameFilter = 'Filters.' + filterIndex;

        if (!selectedItem) {
            this.props.onValueChanged('string', nameFilter + '.AttributeDataType');
            this.props.onValueChanged(Constants.ALL_TYPES, nameFilter + '.Attribute');
        } else {
            this.props.onValueChanged(selectedItem.DataType, nameFilter + '.AttributeDataType');
            this.props.onValueChanged(selectedItem.Value, nameFilter + '.Attribute');
        }
        if (filterControl.Value && filterControl.Value.length >= 3) {
            this.props.customAction();
        }
    };

    handleSearchChange = (value) => {
        if (value.length >= 3 || value.length === 0) {
            this.props.customAction();
        }
    };

    render() {
        const filterControl = this.props.filterControl;
        const filterIndex = filterControl.FilterIndex;
        const name = 'Filters.' + filterIndex + '.Value';

        if (!isVisible(filterControl, this.props.searchTypeCode)) {
            return null;
        }

        const buttonText = this.props.filtersVisible
            ? formatTranslated('FilterHide_Label', this.props.translations.Phrases)
            : formatTranslated('FilterShow_Label', this.props.translations.Phrases);
        const filterButton = (
            <button className='action-button white shipment-filter-btn' onClick={this.props.onToggleSection}>
                <span>{buttonText}</span>
            </button>
        );
        const customDropDown = (
            <div className='search-dropdown'>
                <CustomDropDown
                    name={'Filters.' + filterIndex + '.Options'}
                    options={this.state.allOptions}
                    valueField='Value'
                    textField='TranslatedName'
                    onItemChanged={this.filterOptionSelect}
                />
            </div>
        );
        const searchBox = (
            <div className='search-box'>
                <TextBox
                    placeholder='Type here to search'
                    className='imaging-search-textbox'
                    name={name}
                    onValueChanged={this.props.onValueChanged}
                    customAction={this.handleSearchChange}
                    fireOnChange={true}
                    fireOnImageBlur={true}
                    icon='fa fa-search fa-lg search-icon'
                    value={filterControl.Value}
                />
            </div>
        );
        let mainContent = (
            <div className='user-search-container'>
                {customDropDown}
                {searchBox}
            </div>
        );
        if (this.props.filterButtonVisible) {
            mainContent = (
                <div className='user-search-container'>
                    {filterButton}
                    {searchBox}
                </div>
            );
        }
        return <div>{mainContent}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(MainSearchFilter);
