import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MOTFilterSlice } from '../../common/models/ReduxSlices';

const initialState: MOTFilterSlice = {
    selectedStringArray: []
};

const motFilterSlice = createSlice({
    name: 'motFilterSlice',
    initialState,
    reducers: {
        setSelectedArraySelectedStringArray(state, action: PayloadAction<string[]>) {
            state.selectedStringArray = action.payload;
        },
        resetMotFilters(state) {
            state.selectedStringArray = [];
        }
    }
});
export const { setSelectedArraySelectedStringArray, resetMotFilters } = motFilterSlice.actions;

export default motFilterSlice.reducer;
