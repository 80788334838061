import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDebounce } from '../../hooks/useDebounce';
import useModalActions from '../../hooks/useModalActions';

const TIMEOUT = 50;

interface ModalWrapperProps {
    id?: string;
    children: React.ReactNode;
    onEscape?: () => void;
}

const ModalWrapper = ({ id, children, onEscape }: ModalWrapperProps) => {
    const [showAnimation, setShowAnimation] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    useModalActions({ ref: modalRef, onEscape: onEscape });

    const debounce = useDebounce(TIMEOUT);

    useEffect(() => {
        debounce(() => {
            setShowAnimation(true);
        });
    }, [debounce]);

    const parentContainer = document.querySelector('#portalModal') ?? document.body;

    return ReactDOM.createPortal(
        <div
            ref={modalRef}
            tabIndex={-1}
            role='dialog'
            className='position-fixed'
            style={{ top: 0, left: 0, right: 0, bottom: 0, zIndex: 1050 }}
            id={id}
        >
            <div className={`modal-backdrop fade ${showAnimation ? 'show' : ''}`}></div>
            <div className={`modal fade ${showAnimation ? 'show' : ''}`} style={{ display: 'block' }}>
                {children}
            </div>
        </div>,
        parentContainer
    );
};

export default ModalWrapper;
