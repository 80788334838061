import $ from 'jquery';
import axios from '../../../axios-mlp';
import { accessManagement } from '../../../constants/mlp-constants';
import { setValueByPath } from '../../../utils/mlp-utils';
import * as actionTypes from './userSearchActions';

export const setUserSearch = (viewModel) => {
    return {
        type: actionTypes.SET_USER_SEARCH,
        model: viewModel
    };
};

export const setNewClientAdmin = (viewModel) => {
    $('#overlay').show();
    return (dispatch) => {
        axios
            .post(accessManagement.CREATE_NEW_CLIENT_ADMIN_URL, viewModel)
            .then((response) => {
                dispatch(setUserSearch(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const selectSalesforceContact = (vm, salesForceContact) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .post(accessManagement.MATCH_SALESFORCE_CONTACT, vm)
            .then((response) => {
                if (
                    response.data.ValidationErrors != null &&
                    (response.data.ValidationErrors['emailChanged'] || response.data.ValidationErrors['emailExistsAsPendingUser'])
                ) {
                    dispatch(
                        afterConfirmNewClientAdmin(
                            response.data,
                            response.data.ValidationErrors[Object.keys(response.data.ValidationErrors)[0]]
                        )
                    );
                } else dispatch(setNewClientAdmin(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const initUserSearch = () => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .get('/AccessManagement/IndexSearch')
            .then((response) => {
                dispatch(setUserSearch(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const confirmNewClientAdmin = (vm) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .post(accessManagement.CONFIRM_CLIENT_ADMIN_URL, vm)
            .then((response) => {
                let valMsgs = {};
                if (response.data.ValidationErrors) {
                    const val = response.data.ValidationErrors;
                    for (let i in val) {
                        setValueByPath(valMsgs, i, val[i] === null ? '' : val[i]);
                    }
                }
                dispatch(afterConfirmNewClientAdmin(response.data, valMsgs));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const afterConfirmNewClientAdmin = (data, valMsgs) => {
    return {
        type: actionTypes.CONFIRM_NEW_CLIENT_ADMIN,
        model: data,
        validationMessages: valMsgs
    };
};

export const transferRightsNewClientAdmin = (userId) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .get(`${accessManagement.SELECT_NEW_CLIENT_ADMIN_URL}${userId}`)
            .then((response) => {
                dispatch(setUserSearch(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};
export const resetStateUserSearch = () => {
    return {
        type: actionTypes.RESET_STATE_USER_SEARCH
    };
};
