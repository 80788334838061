import $ from 'jquery';
import axios from '../../../axios-mlp';
import * as actionTypes from './systemAdminActionTypes';

export const initSystemAdminIndex = () => {
    return (dispatch) => {
        $('#overlay').show();

        axios
            .get('/BatchUser/IndexSystemAdmin')
            .then((response) => {
                dispatch(afterInitIndexView(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const afterInitIndexView = (model) => {
    return {
        type: actionTypes.SET_SYSTEM_ADMIN_INDEX_VIEW,
        playload: { model: model }
    };
};
