import produce from 'immer';
import { initValidationMessages } from '../../utils/mlp-utils';
import * as actionTypes from '../actions/accessManagement/editInternalUserActions';

const initialState = {
    model: {
        User: {},
        HasUnrestrictedAccess: false,
        IsReadOnly: false,
        IsValid: true,
        ValidateAll: false,
        ValidationMessages: [],
        ChangedProperties: [],
        ClientNumbersToAddText: '',
        ClientNumbersToRemoveText: ''
    },
    error: false,
    isInitModel: false
};

const setInternalUser = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.model;
        draft.ValidationMessages = initValidationMessages(action.model);
        draft.isInitModel = true;
    });
};

const setInternalUserStatus = (state, action) => {
    return produce(state, (draft) => {
        draft.model.User.Status = action.status;
    });
};

const setFailedError = (state, action) => {
    return produce(state, (draft) => {
        draft.model.error = true;
    });
};
const handleUpdateInternalUser = (state, action) => {
    return produce(state, (draft) => {
        //user
        if (action.payload.key && action.payload.key.includes('User')) {
            const keySlice = action.payload.key.split('.');
            for (let prop in draft.model.User) {
                if (Object.prototype.hasOwnProperty.call(draft.model.User, prop)) {
                    if (prop === keySlice[1]) {
                        draft.model.User[prop] = action.payload.value;
                        if (!draft.model.ChangedProperties.includes(action.payload.key)) {
                            draft.model.ChangedProperties.push(action.payload.key);
                        }
                    }
                }
            }
        }
        //model
        for (let prop in draft.model) {
            if (Object.prototype.hasOwnProperty.call(draft.model, prop)) {
                if (prop === action.payload.key) {
                    draft.model[prop] = action.payload.value;
                    if (!draft.model.ChangedProperties.includes(action.payload.key)) {
                        draft.model.ChangedProperties.push(action.payload.key);
                    }
                }
            }
        }

        draft.error = false;
    });
};
const setValidationMessagesInternalUser = (state, action) => {
    return produce(state, (draft) => {
        if (action.payload.validationMessages) {
            draft.ValidationMessages = action.payload.validationMessages;
        } else {
            draft.ValidationMessages = initValidationMessages(state.model);
        }
        if (action.payload.validationMessages['AllParentServices']) {
            draft.ValidationMessages['AllParentServices'] = action.payload.validationMessages['AllParentServices'];
        }
        draft.error = true;
    });
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_INTERNAL_USER:
            return setInternalUser(state, action);
        case actionTypes.SET_INTERNAL_USER_STATUS:
            return setInternalUserStatus(state, action);
        case actionTypes.CHANGE_STATUS_FAILED:
            return setFailedError(state, action);
        case actionTypes.HANDLE_UPDATE_INTERNAL_USER:
            return handleUpdateInternalUser(state, action);
        case actionTypes.SET_VALIDATION_MESSAGES_INTERNAL_USER:
            return setValidationMessagesInternalUser(state, action);
        case actionTypes.RESET_STATE_INTERNAL_USER:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
