import React from 'react';

interface BreadcrumProps {
    children: React.ReactNode;
}

const Breadcrumb: React.FC<BreadcrumProps> = ({ children }) => {
    return (
        <nav className='breadcrumb-wrapper container-fluid border-bottom' aria-label='breadcrumb'>
            <ol className='breadcrumb'>{children}</ol>
        </nav>
    );
};

export default Breadcrumb;
