import $ from 'jquery';
import axios from '../../../axios-mlp';
import * as actionTypes from './manageUserActions';

export const initManageUser = (userId) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .get('/ManageUser/ManageUser', { params: { userId: userId } })
            .then((response) => {
                dispatch(setManageUser(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const setManageUser = (model) => {
    return {
        type: actionTypes.SET_MANAGE_USER,
        model: model
    };
};
export const resetStateManageUser = (user) => {
    return {
        type: actionTypes.RESET_STATE_MANAGE_USER
    }
}

export const selectManageUser = (user) => {
    return {
        type: actionTypes.SELECT_MANAGE_USER,
        payload: { user: user }
    };
};

export const manageUserModelChange = (value, key) => {
    return {
        type: actionTypes.MODEL_CHANGED,
        payload: { value: value, key: key }
    };
};

export const manageUserEmailChange = (email) => {
    return {
        type: actionTypes.EMAIL_CHANGED,
        payload: { Email: email }
    };
};

export const manageUserSetValidationMessages = (validationMessages) => {
    return {
        type: actionTypes.MANAGE_USER_SET_VALIDATION_MESSAGES,
        payload: { ValidationMessages: validationMessages }
    };
};
