import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { imagingPackageStatusType } from '../../common/constants/constants-portal';
import type { ClearanceAlert } from '../../common/models/ClearanceAlert';
import type { Notice } from '../../common/models/Notice';
import type { AlertsSlice } from '../../common/models/ReduxSlices';
import type { CheckDownloadLobbyResponseModified, InitialDownloadLobbyResponseModified } from '../api/downloadLobbyApi';

const initialState: AlertsSlice = {
    alerts: null,
    alertsReadTime: localStorage.getItem('alertsReadTime'),
    unreadAlerts: 0,
    downloadLobby: null
};

const getUnreadItems = (arr: ClearanceAlert[] | Notice[], alertsReadTime: string) => {
    return arr.reduce((count, item) => {
        return dayjs(item.date).utc() > dayjs(alertsReadTime).utc() ? count + 1 : count;
    }, 0);
};

const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        setAlerts(state, action: PayloadAction<{ statusAlerts: ClearanceAlert[]; notices: Notice[] }>) {
            state.alerts = action.payload;
            state.unreadAlerts =
                state.alertsReadTime !== null
                    ? getUnreadItems(action.payload.statusAlerts, state.alertsReadTime) +
                      getUnreadItems(action.payload.notices, state.alertsReadTime)
                    : action.payload.statusAlerts.length + action.payload.notices.length;
        },
        readAlerts(state, action: PayloadAction<string>) {
            state.unreadAlerts = 0;
            state.alertsReadTime = action.payload;
        },
        addToDownloadLobby(state, action: PayloadAction<{ packageId: string; packageSource?: string }>) {
            const found = state.downloadLobby?.find((i) => i.packageId === action.payload.packageId);
            if (found) return state;

            // Add new alert to localStorage
            const unreadLobby = JSON.parse(localStorage.getItem('unreadLobby') || '[]') as string[];
            unreadLobby.push(action.payload.packageId);
            localStorage.setItem('unreadLobby', JSON.stringify(unreadLobby));

            state.downloadLobby = [
                {
                    type: 'DOWNLOAD',
                    packageId: action.payload.packageId,
                    inProgress: true,
                    imageUrl: '',
                    showToast: true,
                    createdOn: new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }),
                    documentCount: 0,
                    entryNumbers: [],
                    hasError: false,
                    packageSource: action.payload.packageSource!
                },
                ...(state.downloadLobby || [])
            ];
        },
        removeUnreadLobbyItems(state) {
            // Remove all unread download lobby items
            const unreadLobby = JSON.parse(localStorage.getItem('unreadLobby') || '[]') as string[];
            const filteredLobby = unreadLobby.filter(
                (unread) => state.downloadLobby?.find((item) => item.packageId === unread)?.inProgress
            );
            localStorage.setItem('unreadLobby', JSON.stringify(filteredLobby));
        },
        removeUnreadLobbyItem(_state, action: PayloadAction<string>) {
            // Remove unread download lobby item by packageId
            const unreadLobby = JSON.parse(localStorage.getItem('unreadLobby') || '[]') as string[];
            const filteredLobby = unreadLobby.filter((unread) => unread !== action.payload);
            localStorage.setItem('unreadLobby', JSON.stringify(filteredLobby));
        },
        setCheckDownloadLobby: (state, action: PayloadAction<CheckDownloadLobbyResponseModified>) => {
            // Check if error
            if (action.payload.ImagingPackageStatusTypeID === 2 || action.payload.ImageUrl === '') {
                state.downloadLobby =
                    state.downloadLobby?.map((p) =>
                        p.imageUrl === ''
                            ? {
                                  ...p,
                                  inProgress: false,
                                  hasError: true
                              }
                            : p
                    ) || null;
            }
            // Check if completed
            if (action.payload.ImagingPackageStatusTypeID === 4) {
                state.downloadLobby =
                    state.downloadLobby?.map((p) =>
                        p.packageId === action.payload.PackageId.toString()
                            ? {
                                  ...p,
                                  inProgress: false,
                                  imageUrl: action.payload.ImageUrl,
                                  packageSource: action.payload.PackageSource!
                                  //   showToast: true
                              }
                            : p
                    ) || null;
            }
        },
        setInitialDownloadLobby: (state, action: PayloadAction<InitialDownloadLobbyResponseModified[]>) => {
            if (Array.isArray(action.payload)) {
                state.downloadLobby = action.payload.map((p) => ({
                    type: 'DOWNLOAD',
                    imageUrl: p.ImageUrl,
                    inProgress: [imagingPackageStatusType['New'], imagingPackageStatusType['InProgress']].includes(
                        p.ImagingPackageStatusTypeID
                    ),
                    packageId: `${p.PackageId}`,
                    showToast: false,
                    createdOn: p.CreatedDate,
                    documentCount: p.CountOfDocuments,
                    entryNumbers: p.EntryNumbers,
                    hasError: [
                        imagingPackageStatusType['FaultTerminated'],
                        imagingPackageStatusType['Cancelled'],
                        imagingPackageStatusType['OverLimit'],
                        imagingPackageStatusType['IncorrectPassword']
                    ].includes(p.ImagingPackageStatusTypeID),
                    packageSource: p.PackageSource!
                }));
            } else {
                state.downloadLobby = [];
            }
        }
    }
});

export const {
    setAlerts,
    readAlerts,
    addToDownloadLobby,
    removeUnreadLobbyItems,
    removeUnreadLobbyItem,
    setInitialDownloadLobby,
    setCheckDownloadLobby
} = alertsSlice.actions;

export default alertsSlice.reducer;
