import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateRangeFilter } from '../../common/constants/constants-clearances';
import { DateRangeSlice } from '../../common/models/ReduxSlices';

const initialState: DateRangeSlice = {
    dateRangeSelected: DateRangeFilter[0],
    customDateRange: [],
    isDateDropdownOpen: false
};

const dateRangeSlice = createSlice({
    name: 'dateRangeSelected',
    initialState,
    reducers: {
        setDateRangeSelected(state, action: PayloadAction<number>) {
            const dateRangeSelectedIndex = action.payload;
            state.dateRangeSelected = DateRangeFilter[dateRangeSelectedIndex];
        },
        setCustomDateRange(state, action: PayloadAction<{ type: string; date: string }[]>) {
            state.customDateRange = action.payload;
        },
        setIsOpen(state, action: PayloadAction<boolean>) {
            state.isDateDropdownOpen = !action.payload;
        },
        resetDateRange() {
            return { ...initialState };
        }
    }
});

export const { setDateRangeSelected, setCustomDateRange, resetDateRange, setIsOpen } = dateRangeSlice.actions;

export default dateRangeSlice.reducer;
