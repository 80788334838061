import React, { useEffect } from 'react';
import Button from '../../../../../common/features/Button';
import ModalWrapper from '../../../../../common/features/Wrappers/ModalWrapper';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { SubscribersModals } from '../../../../../common/models/SubscribersModals';
import { SelectedSubscriber } from '../../../common/models/Subscriber';
import { useSubscribersDispatch, useSubscribersSelector } from '../../../redux/hooks';
import { resetUnsubscribe } from '../../../redux/reducers/subscribersSlice';

interface UnsubscribeModalProps {
    subscribers: SelectedSubscriber[] | null;
    isEditPage?: boolean;
    toggleModal: (prop: keyof SubscribersModals, next?: boolean) => void;
    handleSubmit: () => void;
}

const UnsubscribeModal: React.FC<UnsubscribeModalProps> = ({ subscribers, isEditPage, toggleModal, handleSubmit }) => {
    const { unsubscribe } = useSubscribersSelector((state) => state.subscribers);
    const dispatch = useSubscribersDispatch();
    const translate = useTranslation();

    // On initial load reset "unsubscribe" state
    useEffect(() => {
        dispatch(resetUnsubscribe());
    }, [dispatch]);

    const handleClose = () => {
        toggleModal('remove');
    };

    const isMultiple = subscribers != null ? subscribers.length > 1 : null;

    return (
        <ModalWrapper onEscape={handleClose}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content'>
                    {/* Heading */}

                    {/* Body */}
                    <div className='modal-body'>
                        <h3 className='modal-title' id='staticBackdropLabel'>
                            {isEditPage
                                ? translate('RemoveMultipleSubscribersEditPage_Label', subscribers?.length.toString())
                                : isMultiple
                                ? translate('RemoveMultipleSubscribers_Label', subscribers?.length.toString())
                                : translate('RemoveSingleSubscriber_Label')}
                        </h3>
                        <p className='note mb-0 notification-message' style={{ width: '100%' }}>
                            {isEditPage
                                ? translate('UnsubscribeMultipleModal_Label', subscribers?.length.toString())
                                : isMultiple
                                ? translate('RemoveMultipleSubscribersMessages_Label')
                                : translate('RemoveSingleSubscriberMessage_Label')}
                        </p>
                    </div>

                    {/* Footer */}
                    <div className='modal-footer'>
                        <Button variant='secondary' size='medium' onClick={handleClose}>
                            {translate('Cancel_Label')}
                        </Button>

                        <Button variant='danger' size='medium' onClick={handleSubmit} disabled={unsubscribe.isLoading ? true : false}>
                            {isEditPage
                                ? translate('YesUnsubscribe_Label', subscribers?.length.toString())
                                : isMultiple
                                ? translate('YesRemoveSubscribers_Label', subscribers?.length.toString())
                                : translate('YesRemoveSubscriber_Label')}
                        </Button>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default UnsubscribeModal;
