const TermsFormUS = () => {
    return (
        <div className='col-sm-12'>
            {/* <h3>{this.props.translations.Phrases['Page_Sub_Header']}</h3> */}
            <br />
            <div>
                {/* <h4>
                       <strong>Acknowledgement and Acceptance of Terms and Conditions of Use</strong>
                    </h4> */}
                <p>
                    This is a legal Agreement{' '}
                    <strong>
                        <i>(“Agreement”)</i>
                    </strong>{' '}
                    between you, together with any company or other business entity you are representing, if any (collectively, the{' '}
                    <strong>
                        <i>“User”</i>
                    </strong>
                    ) and Livingston International Inc., its affiliates and related companies and entities (collectively,{' '}
                    <strong>
                        <i>“Livingston,” “we,” “us,” “our”</i>
                    </strong>
                    ). This Agreement governs the access to and use of all Livingston websites and solutions (collectively, the{' '}
                    <strong>
                        <i>“Solutions”</i>
                    </strong>
                    ) for which User registers and which are provided by or through the Livingston website and the Livingston online
                    resources, including without limitation the Livingston website building and hosting Solutions. By using Livingston’s
                    website or our Solutions, you accept and agree to be bound and comply with the terms of this Agreement, and our{' '}
                    <a
                        href='https://www.livingstonintl.com/about-us/terms-of-use-privacy/privacy-statement/'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Privacy Policy
                    </a>
                    .
                </p>
                <p>
                    If you do not agree to the terms of this Agreement or our Privacy Policy, do not purchase, use or access our website or
                    the Solutions. By using Livingston’s Solutions, you represent and warrant that you are the legal age of majority under
                    applicable law to form a binding contract with Livingston and meet all the foregoing eligibility requirements. If you do
                    not meet all these requirements, you must not access or use our website or the Solutions.
                </p>
                <p>
                    The access to the Solutions is offered to User conditional upon User’s acceptance without modification of this
                    Agreement. User acknowledges that, from time to time, it may be necessary for Livingston to update or revise certain
                    provisions of this Agreement. By accepting this Agreement, User agrees that Livingston may change the terms of the
                    Agreement in its sole discretion without specific notice to User. You agree to periodically review the terms and
                    conditions in order to be aware of any such modifications and your continued use shall be your acceptance of these. If
                    you do not agree to the changes proposed by Livingston, or to any terms in this Agreement, your sole and exclusive
                    remedy is to cancel your account.
                </p>

                <h4>Description of Solutions</h4>
                <p>
                    The Solutions are internet-based applications owned by Livingston and are provided through the Livingston website. Such
                    Solutions may be purchased as single package or used to gain visibility into the customs brokerage, freight, trade
                    consulting and global trade management service work that Livingston is performing on behalf of its customers. Solution
                    features may include, but are not limited to, the ability to create shipping documents, generate customized reports and
                    perform trend analysis on stored data, maintain a repository of product classifications and other key information
                    required to manage international import and export trade operations, etc.
                </p>

                <h4>Registration Obligations</h4>
                <p>
                    Each User must: (a) provide true, accurate, current and complete information on the Solutions' registration form, and
                    (b) maintain and promptly update the registration data as necessary. If after investigation, we have reasonable grounds
                    to suspect that any User’s information is untrue, inaccurate, not current or incomplete, we may suspend or terminate
                    that User’s account and prohibit any and all current or future use of the Solutions (or any portion thereof) by that
                    User.
                </p>

                <h4>Use and Protection of Username and Password</h4>
                <p>
                    Each User will receive a username and password upon completing the Solutions' registration process and is wholly
                    responsible for maintaining the confidentiality thereof and wholly liable for all activities occurring thereunder. Any
                    username, password, or any other piece of information chosen by you, or provided to you as part of our security
                    procedures, must be treated as confidential, and you must not disclose it to any other person or entity. You must
                    exercise caution when accessing your account from a public or shared computer so that others are not able to view or
                    record your password or other personal information. You understand and agree that should you be provided an account,
                    your account is personal to you and you agree not to provide any other person with access to this Website or portions of
                    it using your username, password, or other security information. You agree to notify us immediately of any unauthorized
                    access to or use of your username or password or any other breach of security. You also agree to ensure that you logout
                    from your account at the end of each session. You are responsible for any password misuse or any unauthorized access.
                    Livingston cannot and will not be liable for any loss or damage arising from a User’s failure to comply with this
                    section. Livingston cannot and will not be liable for any loss or damage arising hereunder or those arising from any
                    User’s failure to: (a) immediately notify Livingston of any unauthorized use of their password or account or any other
                    breach of security, and (b) ensure that User exits from their account at the end of each session.
                </p>

                <h4>User conduct</h4>
                <p>
                    As a condition of User’s access and use, you agree that you may use the Solutions only for lawful purposes and in
                    accordance with this Agreement. The following content standards apply to any and all content, material, and information
                    you submit, post, publish, display, or transmit to the Solutions. All submissions must comply with all applicable
                    federal, provincial, local, and international laws, regulations, and terms of service.
                </p>

                <b>Without limiting the foregoing, you warrant and agree: </b>
                <ul>
                    <li>
                        Not to use the Solutions, in whole or in part, for any purpose that is unlawful or prohibited by this Agreement;
                    </li>
                    <li>
                        Not to use the Solutions in any manner that could damage, disable, overburden, or impair the Solutions or interfere
                        with any other party's use of the Solutions;
                    </li>
                    <li>
                        Not to impersonate or attempt to impersonate Livingston, a Livingston employee, another user, or any other person or
                        entity (including, without limitation, by using email addresses, or screen names associated with any of the
                        foregoing);
                    </li>
                    <li>
                        Not to use or attempt to use another's account or username, password without authorization from Livingston to access
                        the Solutions;
                    </li>
                    <li>Not to access or attempt to access any design content which you are not authorized to access;</li>
                    <li>
                        Not to disrupt or interfere with the security of, or otherwise cause harm to, the site, or any Solutions, materials,
                        system resources, accounts, passwords, servers or networks connected to or accessible through the site or any
                        affiliated or linked sites;
                    </li>
                    <li>
                        Not to use any robot, spider, or other automatic device, process, or means to access the Solutions for any purpose,
                        including monitoring or copying any of the material on the Solutions;
                    </li>
                    <li>
                        Not to introduce any viruses, trojan horses, worms, logic bombs, or other material that is malicious or
                        technologically harmful;
                    </li>
                    <li>
                        Not to modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works
                        from, frame in another web page, use on any other website, transfer, or sell any information, software, lists of
                        Users, databases or other lists, or Solutions provided through or obtained from the Solutions other than for
                        internal business use of the User in accordance with this Agreement;
                    </li>
                    <li>
                        Not to engage in the practices of "screen scraping", "database scraping", or any other activity with the purpose of
                        obtaining lists of Users or other information;
                    </li>
                    <li>
                        Not to obtain or attempt to obtain any materials or information through any means not intentionally made available
                        or provided for through the Solutions; and
                    </li>
                    <li>Not to otherwise attempt to interfere with the proper working of the Solutions.</li>
                </ul>

                <h4>Use of content and data entered</h4>
                <p>
                    The User, and not Livingston, is entirely responsible for all content that User uploads, posts, emails or otherwise
                    transmits via Livingston or any Solutions. No User shall transmit content or otherwise conduct or participate in any
                    activities on Livingston and/or any Solutions which, in the opinion of Livingston's counsel, are prohibited by law in
                    applicable jurisdictions, including laws governing the encryption of software, the export of technology or the
                    permissible uses of intellectual property. The content may be used solely for business, informational and internal
                    purposes. The rights granted to the User constitute a license and not a transfer of title.
                </p>

                <p>Livingston shall have the right (but not the obligation) in its sole discretion and without notice to: </p>

                <ul>
                    <li>remove or refuse to post on the Solutions for any or no reason in our sole discretion; </li>
                    <li>
                        refuse or delete any content that it reasonably considers to violate the terms of usage or be otherwise illegal;
                    </li>
                    <li>
                        take appropriate legal action, including, without limitation, referral to law enforcement or regulatory authority,
                        or notifying the harmed party of any illegal or unauthorized use of the Solutions; and
                    </li>
                    <li>
                        terminate or suspend your access to all or part of the Solutions for any or no reason, including, without
                        limitation, any violation of this Agreement.
                    </li>
                </ul>

                <h4>Intellectual Property Rights and Information Ownership</h4>
                <p>
                    The User understands and agrees that the Solutions and their entire contents, features, and functionality, including,
                    but not limited to, all information, software, code, text, displays, graphics, photographs, video, audio, design,
                    presentation, selection, and arrangement, are owned by the Livingston, its licensors, or other providers of such
                    material and are protected in all forms by intellectual property laws including without limitation, copyright,
                    trademark, patent, trade secret, and any other proprietary rights.
                </p>
                <p>
                    Livingston’s name, and all related names, logos, product and service names, designs, images, and slogans are trademarks
                    of Livingston or its affiliates or licensors. You must not use such marks without the prior written permission of
                    Livingston. Other names, logos, product and service names, designs, images, and slogans mentioned, or which appear on
                    the Solutions are the trademarks of their respective owners. Use of any such property, except as expressly authorized,
                    shall constitute an infringement or violation of the rights of the property owner and may be a violation of federal or
                    other laws and could subject the infringer to legal action.
                </p>
                <p>
                    By accepting this Agreement, the User accepts that all information or data input, transmitted to or stored within the
                    Solutions becomes the sole property of Livingston. Subject to the conditions of its Privacy Policy and its regulatory
                    requirements, Livingston reserves the right to use this information for internal purposes as it deems appropriate and
                    without obtaining prior consent from the User. To learn how Livingston protects your information refer to the Livingston
                    online Privacy Policy. The User further renounces ownership of this information and agrees that such information may
                    only be shared with the User, in whole or in part, at the sole discretion of Livingston.
                </p>

                <h4>Compensation for Solutions</h4>
                <p>
                    The User is required to pay a fee for all or any part of the Solutions for which User has chosen to register. User
                    acknowledges that Livingston reserves the right to change any fees (which includes but is not limited to, increasing
                    prices and charging a fee for upgrades or Solutions for which Livingston does not currently charge a fee) at any time,
                    provided, however, that Livingston gives the User reasonable notice prior to making any fee changes. In addition,
                    Livingston will also give the User reasonable notice before any modification to the Solutions that could adversely
                    impact the User. The standard terms of payment are 30 days from the invoice date, or as otherwise agreed to by the
                    parties in writing. If the User finds any change to the Solutions to be unacceptable, the User is free to cancel any
                    part of the Solutions or the User account at any time, but Livingston will not refund any remaining portion of User’s
                    pre-paid fees when User cancels any part of the Solutions or User’s account. The User acknowledges that the fees
                    associated with the Solutions are not inclusive of any applicable service fees for Livingston services. Fees for such
                    services will be set out in the applicable service agreement between Livingston and the client.
                </p>

                <h4>Termination of access to Solutions</h4>
                <p>
                    A User’s account may be terminated for any reason including, but are not limited to, the following: (a) violation of the
                    terms of this Agreement; (b) abuse of site resources or attempt to gain unauthorized entry to the site or site
                    resources; (c) use of Solutions in a manner inconsistent with the intended purpose; (d) a User’s request for such
                    termination; (e) requirement of applicable law, regulation, court or governing agency order or; (f) non-compliance with
                    the terms of payment.
                </p>

                <p>
                    Livingston's termination of any User’s access to Livingston Solutions hereunder may be affected without notice and, on
                    such termination, Livingston may immediately deactivate or delete User’s account or bar any further access to such
                    files. Livingston shall not be liable to any User or third-party for any termination of that User’s access or account
                    hereunder.
                </p>

                <h4>Disclaimer of Warranty</h4>
                <p>
                    USER UNDERSTANDS AND AGREES THAT USE OF THE SOLUTIONS, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH
                    THE SOLUTION IS AT USERS OWN RISK. THE SOLUTION, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE
                    SOLUTION ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND, EITHER
                    EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER
                    APPLICABLE LAW.
                </p>
                <p>
                    NEITHER LIVINGSTON NOR ITS SUBSIDIARIES, AFFILIATES, OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE
                    PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE ANY WARRANTY, REPRESENTATION, OR ENDORSEMENT
                    WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY, OR AVAILABILITY OF THE
                    SOLUTION OR ITS CONTENTS. WITHOUT LIMITING THE FOREGOING, NEITHER LIVINGSTON NOR ITS SUBSIDIARIES, AFFILIATES OR THEIR
                    RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR
                    SUCCESSORS REPRESENT OR WARRANT THAT THE SOLUTION, ITS CONTENT, OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE
                    SOLUTION WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SOLUTION OR
                    THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                </p>
                <p>
                    WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE SOLUTION
                    WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR YOUR USE OF THE WEBSITE
                    AND YOUR COMPUTER, INTERNET, AND DATA SECURITY. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY
                    LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK, DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING,
                    MAILBOMBING, OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY
                    INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SOLUTION
                    OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR
                    ON ANY WEBSITE LINKED TO IT.
                </p>

                <h4>Limitation of Liability</h4>
                <p>
                    EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO CIRCUMSTANCE WILL LIVINGSTON NOR ITS SUBSIDIARIES,
                    AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS,
                    LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION, FUNDAMENTAL
                    BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL,
                    OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
                    REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND
                    WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, BREACH OF PRIVACY, OR OTHERWISE, EVEN IF THE PARTY
                    WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, OR
                    RELIANCE ON, THE SOLUTION, ANY LINKED WEBSITES OR SUCH OTHER THIRD-PARTY WEBSITES, NOR ANY WEBSITE CONTENT, MATERIALS,
                    POSTING, OR INFORMATION THEREON EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW.
                </p>
                <p>
                    YOU WAIVE AND HOLD HARMLESS LIVINGSTON AND ITS SUBSIDIARIES, AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS,
                    EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND SUCCESSORS FROM ANY AND ALL
                    CLAIMS RESULTING FROM ANY ACTION TAKEN BY LIVINGSTON AND ANY OF THE FOREGOING PARTIES RELATING TO ANY, INVESTIGATIONS BY
                    EITHER LIVINGSTON OR SUCH PARTIES OR BY LAW ENFORCEMENT AUTHORITIES.
                </p>
                <p>
                    We have no obligation, nor any responsibility to any party to monitor the Solutions or its use, and do not and cannot
                    undertake to review material that you or other users submit to the Solutions. We cannot ensure prompt removal of
                    objectionable material after it has been posted and we have no liability for any action or inaction regarding
                    transmissions, communications, or content provided by any user or third party, subject to applicable laws.
                </p>

                <h4>Indemnification</h4>
                <p>
                    To the maximum extent permitted by applicable law, User agrees to defend, indemnify, and hold harmless Livingston, its
                    subsidiaries, affiliates, and their respective directors, officers, employees, agents, service providers, contractors,
                    licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards,
                    losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to User’s breach of
                    the terms of this Agreement, or use of the Solution, including, but not limited to, third-party sites, any use of the
                    Solution’s content, services, and products other than as expressly authorized in this Agreement.
                </p>

                <h4>Third Parties </h4>
                <p>
                    For your convenience, Livingston Solutions may provide links or pointers to third-party sites, for example, for payment
                    processing purposes. We make no representations about any other websites that may be accessed from Livingston Solutions.
                    If you choose to access any such sites, you do so at your own risk. We have no control over the contents of any such
                    third-party sites and accept no responsibility for such sites or for any loss or damage that may arise from your use of
                    them. You are subject to any terms and conditions of such third-party sites.
                </p>

                <h4>Privacy </h4>
                <p>
                    By submitting your personal information and using our Solutions, you consent to the collection, use, reproduction,
                    hosting, transmission, and disclosure of any such user content submissions in compliance with our Privacy Policy, as we
                    deem necessary for use of the Solutions and provision of services. By using our Solutions, you are consenting to the use
                    of cookies which allow a server to recall previous requests or registration and/or IP addresses to analyze website use
                    patterns. You can set your browser to notify you before you receive a cookie, giving you the chance to decide whether to
                    accept it. You can also set your browser to turn off cookies. If you do, however, some areas of the Solutions may not
                    function adequately. For more information on this automated information gathering practices, see{' '}
                    <a
                        href='https://www.livingstonintl.com/about-us/terms-of-use-privacy/privacy-statement/cookies-policy/'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Cookie Policy
                    </a>
                    .
                </p>

                <h4>Governing Law and Choice of Forum </h4>
                <p>
                    The Solutions and this Agreement will be governed by and construed in accordance with the laws of the Province of
                    Ontario, other than conflict of laws principles thereof directing the application of any law other than that of Ontario.
                    Courts within the Province of Ontario will have jurisdiction over all disputes between the parties hereto arising out of
                    or relating to this Agreement and the agreements, instruments and documents contemplated hereby. The parties hereby
                    consent to and agree to submit to the jurisdiction of such courts.
                </p>

                <h4>Entire Agreement </h4>
                <p>
                    This Agreement and our Privacy Policy and Cookies Policy constitute the sole and entire agreement between the User and
                    Livingston regarding the use of Livingston’s Solutions and supersede all prior and contemporaneous understandings,
                    agreements, representations and warranties, both written and oral, regarding such subject matter. In the event User
                    enters into a service agreement with Livingston for a Livingston service, the terms and conditions of that agreement
                    will govern the relationship between the parties in relation to the services being provided by Livingston.
                </p>

                <h4>Language </h4>
                <p>
                    It is the express wish of the parties that the terms and conditions of use and all related documents be drawn up in
                    English. C'est la volonté expresse des parties que la présente convention ainsi que les documents qui s'y rattachent
                    soient rédigés en anglais.
                </p>
            </div>
            {/* <div className='row justify-content-center align-items-center' id='button-row'>
                                <Link to='#' className='button blue small' onClick={this.acceptTerms}>
                                    {this.props.translations.Phrases['Approve_Button']}
                                </Link>
                                <Link to='/Auth/TermsOfService'>{this.props.translations.Phrases['Reject_Button']}</Link>
                            </div> */}
        </div>
    );
};

export default TermsFormUS;
