import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import useTranslationHydration from '../hooks/useTranslationHydration';
import FormInputField from './FormInputField';
import DropdownWrapper from './Wrappers/DropdownWrapper';

interface ComboBoxProps {
    children: React.ReactNode;
    childrenDivClassName?: string;
    isOpen: boolean;
    style?: React.CSSProperties;
    name: string;
    isValid?: boolean | null;
    hasError?: boolean;
    onChangeFullName: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlurFullName: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: () => void;
}

const ComboBox = React.forwardRef<HTMLDivElement, ComboBoxProps>(
    (
        {
            children,
            childrenDivClassName = 'dropdown-menu',
            isOpen,
            style,
            name,
            isValid,
            hasError,
            onChangeFullName,
            onBlurFullName,
            onClick
        },
        ref
    ) => {
        const [referenceElement, setReferenceElement] = useState<any>(null);
        const [popperElement, setPopperElement] = useState<any>(null);
        const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
            placement: 'bottom-start',
            modifiers: [{ name: 'offset', options: { offset: [0, 2] } }]
        });
        const translate = useTranslationHydration();
        useEffect(() => {
            if (update) update();
        }, [update]);

        return (
            <div className='dropdown' ref={setReferenceElement}>
                <FormInputField
                    label=''
                    placeholder={translate('SearchAndAddSubscribers_Label')}
                    value={name}
                    onChange={(e) => onChangeFullName(e)}
                    onBlur={(e) => onBlurFullName(e)}
                    onClick={onClick}
                    isValid={isValid}
                    hasError={hasError}
                />
                {isOpen ? (
                    <DropdownWrapper>
                        <div
                            className={`dropdown-menu pt-0 show ${childrenDivClassName}`}
                            ref={setPopperElement}
                            style={{ margin: 0, ...styles.popper, ...style }}
                            {...attributes.popper}
                        >
                            <div ref={ref}>{children}</div>
                        </div>
                    </DropdownWrapper>
                ) : null}
            </div>
        );
    }
);

export default ComboBox;
