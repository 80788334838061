import { Component } from 'react';
import { connect } from 'react-redux';
import MoreDetailsIcon from '../../../common/Icons/MoreDetailsIcon';
import SearchBox from '../../../common/Searchbox/Searchbox';
import * as actionCreators from '../../../store/actions/index';

class VASalesforceContactControl extends Component {
    state = {
        searchBoxValue: ''
    };

    componentDidUpdate(prevProps) {
        if (this.props.viewModel.ChangedProperties !== prevProps.viewModel.ChangedProperties || this.props.viewModel.UserChanged) {
            this.props.onValidateVerifyAccess(this.props.viewModel);
        }
    }

    resetUser = () => {
        this.props.onResetUser();
    };

    clearUser = () => {
        this.props.onClearUser();
    };

    onSelectSalesforceContact = (contact) => {
        this.props.onSelectSalesforceContact(contact);
    };

    searchBoxValueChanged = (val) => {
        this.setState({
            searchBoxValue: val
        });
    };

    formatResultsBuilder = (i) => {
        const address = i.Address || '';
        const addressArray = address.split(',');
        let ContactAddress = <div></div>;
        if (addressArray.length > 1) {
            ContactAddress = (
                <div className='data-row'>
                    <div className='result-label'> Address: </div>
                    <div className='result-value'>
                        <div>{addressArray[0]}</div>
                        <div>
                            {addressArray[1]}, {addressArray[2]}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className='search-results'>
                <div>
                    <strong> {i.FullName} </strong>
                </div>
                <div className='data-row'>
                    <div className='result-label'>Email:</div>
                    <div className='result-value'>{i.Email}</div>
                </div>
                <div className='data-row'>
                    <div className='result-label'>Phone:</div>
                    <div className='result-value'>{i.Phone}</div>
                </div>
                <div className='data-row'>
                    <div className='result-label'>Account:</div>
                    <div className='result-value'>{i.Account}</div>
                </div>
                <div className='data-row'>
                    <div className='result-label'> ContactId: </div>
                    <div className='result-value'>{i.SalesforceId} </div>
                </div>
                {ContactAddress}
            </div>
        );
    };

    getUserDetails = (user) => {
        return [
            { label: this.props.translations.Phrases['Email_Label'], value: user.Email },
            { label: this.props.translations.Phrases['Phone_Label'], value: user.Phone },
            { label: this.props.translations.Phrases['Account_Label'], value: user.Account },
            { label: this.props.translations.Phrases['ContactId_Label'], value: user.ContactId },
            { label: this.props.translations.Phrases['Address_Label'], value: user.Address }
        ];
    };

    render() {
        const vm = this.props.viewModel;
        const val = this.props.validationMessages;
        const userDetails = this.getUserDetails(vm.User);
        let resetButton = (
            <span className='btn btn-link' onClick={this.clearUser}>
                {this.props.translations.Phrases['Clear_Label']}
            </span>
        );

        if (vm.UserChanged) {
            resetButton = (
                <span className='btn btn-link' onClick={this.resetUser}>
                    {this.props.translations.Phrases['Reset_Label']}
                </span>
            );
        }

        let userNameAndEmail = '';
        if (vm.User && vm.User.SalesforceId) {
            userNameAndEmail = (
                <div className='selected-user-box'>
                    <div className='pull-left'>
                        <div className='text-wrap'>
                            <strong>{vm.User.FullName}</strong>
                            <MoreDetailsIcon items={userDetails} />
                        </div>
                        <div className='text-wrap'>{vm.User.Email}</div>
                    </div>
                    {vm.User.Email ? <div className='pull-right'>{resetButton}</div> : ''}
                </div>
            );
        }

        return (
            <SearchBox
                label={this.props.translations.Phrases['SFContactLookup_Label']}
                placeholder={this.props.translations.Phrases['TypeHereToSearch_Label']}
                name='User.Email'
                url='/ClientAdmin/GetSalesforceContacts'
                formatResults={this.formatResultsBuilder}
                minChars={4}
                valMsg={val.User ? this.props.translations.Phrases[val.User.Email] : ''}
                onSelectItem={this.onSelectSalesforceContact}
                value={this.state.searchBoxValue}
                onValueChanged={this.searchBoxValueChanged}
            >
                {userNameAndEmail}
            </SearchBox>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectSalesforceContact: (contact) => dispatch(actionCreators.selectSalesforceContactVA(contact)),
        onResetUser: () => dispatch(actionCreators.resetUserVA()),
        onClearUser: () => dispatch(actionCreators.clearUserVA()),
        onValidateVerifyAccess: (model) => dispatch(actionCreators.validateVerifyAccess(model))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(VASalesforceContactControl);
