import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import useMediaQuery from '../hooks/useMediaQuery';
import type { ButtonProps } from './Button';
import Button from './Button';
import DropdownWrapper from './Wrappers/DropdownWrapper';

interface DropdownMenuButtonProps {
    label: React.ReactNode | null;
    children: React.ReactNode;
    id?: string;
    btnClassName?: string;
    btnClassNameSecondary?: string;
    btnVariant?: ButtonProps['variant'];
    btnSize?: ButtonProps['size'];
    childrenDivClassName?: string;
    divClassName?: string;
    disabled?: boolean;
    isOpen: boolean;
    icon?: IconDefinition;
    iconWidth?: string;
    iconHeight?: string;
    inlineIcon?: IconDefinition;
    style?: React.CSSProperties;
    waffleMenuItemsLength?: number;
    buttonRef?: React.RefObject<HTMLButtonElement>;
    onClick: () => void;
}

const DropdownMenuButton = React.forwardRef<HTMLDivElement, DropdownMenuButtonProps>(
    (
        {
            label,
            children,
            id,
            btnClassName = 'dropdown-toggle',
            btnClassNameSecondary,
            btnVariant = 'tertiary',
            btnSize,
            childrenDivClassName = 'dropdown-menu pt-0',
            divClassName,
            disabled,
            isOpen,
            icon,
            iconWidth = '20px',
            iconHeight = '20px',
            inlineIcon,
            style,
            waffleMenuItemsLength,
            onClick,

            buttonRef
        },
        ref
    ) => {
        const [referenceElement, setReferenceElement] = useState<any>(null);
        const [popperElement, setPopperElement] = useState<any>(null);
        const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
            placement: 'bottom-start',
            modifiers: [{ name: 'offset', options: { offset: [0, 2] } }]
        });

        const middleView = useMediaQuery(1000, 1225);
        const mobileView = useMediaQuery(335, 570);

        useEffect(() => {
            if (update) update();
        }, [update]);

        return (
            <div className={`dropdown ${divClassName}`} ref={setReferenceElement}>
                <Button
                    variant={btnVariant}
                    size={btnSize}
                    className={!icon ? (inlineIcon ? btnClassNameSecondary : btnClassName) : btnClassNameSecondary}
                    id={id}
                    ref={buttonRef}
                    disabled={disabled}
                    onClick={onClick}
                    style={style}
                >
                    {icon ? (
                        <FontAwesomeIcon icon={icon} />
                    ) : inlineIcon ? (
                        <>
                            <span className='mr-2 mt-1'>
                                <FontAwesomeIcon style={{ width: iconWidth, height: iconHeight }} icon={inlineIcon} />
                            </span>
                            <span className='text-truncate'>{label}</span>
                        </>
                    ) : (
                        label
                    )}
                </Button>
                {isOpen && (
                    <DropdownWrapper>
                        <div
                            className={`dropdown-menu show ${childrenDivClassName}`}
                            aria-labelledby={id}
                            ref={setPopperElement}
                            style={{
                                margin: 0,
                                ...styles.popper,
                                maxWidth: waffleMenuItemsLength ? (!mobileView ? '690px' : '340px') : '',
                                transform:
                                    middleView && icon && waffleMenuItemsLength
                                        ? waffleMenuItemsLength > 3
                                            ? 'translate(-10px, 50px)'
                                            : `${styles.popper.transform}`
                                        : mobileView && icon && waffleMenuItemsLength
                                        ? waffleMenuItemsLength > 3
                                            ? 'translate(10px, 50px)'
                                            : `${styles.popper.transform}`
                                        : `${styles.popper.transform}`
                            }}
                            {...attributes.popper}
                        >
                            <div ref={ref}>{children}</div>
                        </div>
                    </DropdownWrapper>
                )}
            </div>
        );
    }
);

export default DropdownMenuButton;
