import React, { useEffect, useRef, useState } from 'react';
import { getCountryFromClientNumber, getMilestoneCount } from '../functions/utils';
import useDropdownToggle from '../hooks/useDropdownToggle';
import useTranslation from '../hooks/useTranslation';
import { Notifications, SubscriptionsData } from '../models/ResponseData';
import Button from './Button';
import DropdownMenuButton from './DropdownMenuButton';
import MilestonesSelection from './MilestonesSelection';

interface MilestoneDropdownProps extends SubscriptionsData {
    applyButtonLabel: React.ReactNode;
    cancelButtonLabel: string;
    disabled?: boolean;
    updateMilestones: (
        action: string,
        customerNumber: null | string,
        subscriptionId: null | number,
        milestones: null | Notifications[]
    ) => void;
}

const MilestoneDropdown: React.FC<MilestoneDropdownProps> = (props) => {
    const [notifications, setNotifications] = useState<Notifications | null>();
    const [notificationsEdited, setNotificationsEdited] = useState<Notifications | null>();
    const [milestonesCount, setMilestonesCount] = useState(0);
    const dropdownMenuRef = useRef(null);
    const { isOpen, handleToggle } = useDropdownToggle(dropdownMenuRef);
    const clientNo = props?.CustomerNumber;
    const subscriptionId = props?.SubscriptionId;
    const country = getCountryFromClientNumber(clientNo!);
    const translate = useTranslation();

    useEffect(() => {
        setNotificationsEdited(notifications!);
    }, [isOpen, notifications]);

    useEffect(() => {
        if (props) {
            let milestones = country === 'ca' ? props.MilestoneNotifications_CA : props.MilestoneNotifications_US;
            let obj = {};
            milestones!.forEach((item) => {
                obj = { ...obj, [item.Name!]: item.Value };
            });

            setNotifications(obj);
            setMilestonesCount(getMilestoneCount(obj));
            setNotificationsEdited(obj);
        }
    }, [props, country]);

    const handleEditingNotifications = (obj: null | Notifications, notification: null | string, value: null | string | boolean) => {
        setNotificationsEdited(obj ? { ...notificationsEdited!, ...obj } : { ...notificationsEdited!, [notification!]: value });
    };

    const handleApply = () => {
        props.updateMilestones(
            'milestones',
            clientNo ? clientNo : null,
            subscriptionId ? subscriptionId : null,
            Object.entries(notificationsEdited!).map(([key, value]) => {
                return { Name: key, Value: value };
            })
        );
        handleToggle();
    };

    return (
        <div className='table-milestone-dropdown'>
            <DropdownMenuButton
                btnSize='small'
                label={translate('OutOf5Milestones_Label', milestonesCount)}
                isOpen={isOpen}
                onClick={handleToggle}
                ref={dropdownMenuRef}
                disabled={props.disabled}
            >
                <form className='checkboxes ml-4 mr-4 mt-3'>
                    {notifications && isOpen && (
                        <MilestonesSelection
                            country={country}
                            notifications={notifications}
                            showSelectAll={false}
                            showCountry={false}
                            handleMilestonesChange={handleEditingNotifications}
                        />
                    )}
                </form>
                <hr />
                <div className='button-container d-flex pb-2 mr-4 ml-4' style={{ width: '16rem' }}>
                    <Button
                        children={props.cancelButtonLabel}
                        type='button'
                        variant='tertiary-blue'
                        className='mr-2 w-50'
                        onClick={handleToggle}
                    />
                    <Button children={props.applyButtonLabel} type='button' variant='primary' className='w-50' onClick={handleApply} />
                </div>
            </DropdownMenuButton>
        </div>
    );
};

export default MilestoneDropdown;
