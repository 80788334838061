export const LivingstonLogoPortal = () => (
    <svg width='140' height='38' viewBox='0 0 140 38' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='m19.283 19.095 4.856 18.768 4.855-18.782H19.28l.003.014Z' fill='url(#a)' />
        <path d='M0 19.161h28.974L33.819.428 0 19.161Z' fill='#29B8F5' />
        <path
            d='M49.742 22.883h4.974c6.18 0 10.021-2.96 10.021-8.553 0-5.63-3.84-8.554-10.02-8.554H43.632v26.246h6.11v-9.14Zm0-5.117V10.93h4.645c2.89 0 4.352 1.243 4.352 3.4 0 2.156-1.463 3.436-4.352 3.436h-4.645Zm34.453 4.642c0-5.995-3.986-10.052-9.655-10.052-5.706 0-9.656 4.057-9.656 10.052 0 5.995 3.95 10.016 9.656 10.016 5.67 0 9.655-4.02 9.655-10.016Zm-13.496 0c0-3.363 1.5-5.264 3.84-5.264 2.341 0 3.841 1.9 3.841 5.264 0 3.363-1.5 5.264-3.84 5.264s-3.84-1.9-3.84-5.264Zm27.988-9.687a11.55 11.55 0 0 0-1.28-.073c-2.23 0-4.096 1.097-4.974 2.632v-2.522H86.58v19.264h5.852v-9.467c0-2.78 2.012-4.35 4.535-4.35.66 0 1.097.036 1.72.146v-5.63Zm3.224 14.147c0 3.948 2.084 5.337 6.254 5.337 1.39 0 2.487-.11 3.511-.256v-4.79c-.658.074-.987.11-1.682.11-1.463 0-2.304-.291-2.304-1.9v-7.786h3.767v-4.825h-3.767V7.42h-5.779v5.337h-2.45v4.825h2.45v9.285Zm17.641 5.52c2.524 0 4.426-.768 5.706-2.084.988 1.645 3.255 2.267 6.62 1.718V27.71c-1.134.146-1.499-.146-1.499-1.133v-7.421c0-4.496-3.109-6.8-8.157-6.8-4.901 0-7.937 2.779-8.778 6.178l5.596.84c.366-1.571 1.244-2.302 2.926-2.302 1.902 0 2.67.914 2.67 2.34v.329l-4.316.73c-4.06.586-7.425 2.194-7.425 6.179 0 3.4 2.78 5.739 6.657 5.739v-.001Zm5.084-7.275c0 1.974-1.792 2.998-3.731 2.998-1.389 0-2.304-.658-2.304-1.864 0-1.353 1.134-1.865 2.67-2.12l3.365-.622v1.608Zm15.312-19.337h-5.852v26.246h5.852V5.776Z'
            fill='#fff'
        />
        <defs>
            <linearGradient id='a' x1='24.137' y1='19.081' x2='24.137' y2='37.863' gradientUnits='userSpaceOnUse'>
                <stop stopColor='#007BC0' />
                <stop offset='1' stopColor='#29B8F5' />
            </linearGradient>
        </defs>
    </svg>
);
