import $ from 'jquery';
import 'jquery-mask-plugin';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import InputBase from '../Inputbase/Inputbase';

class Textbox extends Component {
    textBoxRef = React.createRef();

    static defaultProps = {
        value: '',
        name: '',
        type: 'text',
        placeholder: '',
        onlyCustomAction: false,
        fireOnChange: false,
        fireOnImageBlur: false,
        disableBlurEvent: false,
        customPaste: false
    };

    static propTypes = {
        customAction: PropTypes.func,
        onValueChanged: PropTypes.func,
        value: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        placeholder: PropTypes.string,
        icon: PropTypes.string,
        inputMask: PropTypes.string,
        fireOnImageBlur: PropTypes.bool,
        disabled: PropTypes.bool,
        customPaste: PropTypes.bool,
        fireOnChange: PropTypes.bool,
        disableBlurEvent: PropTypes.bool,
        readonly: PropTypes.bool,
        onlyCustomAction: PropTypes.bool
    };

    handleBlur = (event) => {
        if (!this.props.disableBlurEvent) {
            if (!this.props.onlyCustomAction && !this.props.fireOnImageBlur) {
                this.props.onValueChanged(event.target.value, this.props.name);

                if (this.props.validate) {
                    this.props.validate();
                }

                if (this.props.customAction) {
                    this.props.customAction(event.target.value, this.props.name);
                }
            }
        }
    };

    handleChange = (event) => {
        if (!this.props.onlyCustomAction) {
            this.props.onValueChanged(event.target.value, this.props.name);
        }

        if (this.props.fireOnChange && this.props.customAction) {
            this.props.customAction(event.target.value, this.props.name);
        }
    };

    handlePaste = (e) => {
        if (this.props.customPaste) {
            const pastedContent = e.clipboardData.getData('Text');
            const newPastedContent = pastedContent.replace(/\r/g, '').replace(/\t|\n/g, ';');

            // if exists ; on the end remove
            const finalContent =
                newPastedContent.charAt(newPastedContent.length - 1) === ';' ? newPastedContent.slice(0, -1) : newPastedContent;

            if (!this.props.onlyCustomAction) {
                this.props.onValueChanged(finalContent, this.props.name);
            }

            if (this.props.fireOnChange && this.props.customAction) {
                this.props.customAction(finalContent, this.props.name);
            }
            e.preventDefault();
        }
    };

    componentDidMount = () => {
        this.setInputMask();
    };

    setInputMask = () => {
        const $inputElement = $(ReactDOM.findDOMNode(this.textBoxRef.current));

        try {
            $inputElement.unmask(); //needed to consistently apply mask when changes occur
        } catch (err) {}
        if (this.props.inputMask) {
            $inputElement.mask(this.props.inputMask);
        }
    };

    render() {
        const icon = this.props.icon !== undefined ? <i className={this.props.icon} /> : null;
        return (
            <InputBase {...this.props}>
                {icon}
                <input
                    ref={this.textBoxRef}
                    placeholder={this.props.placeholder}
                    type={this.props.type}
                    name={this.props.name}
                    value={this.props.value}
                    className='form-control'
                    disabled={this.props.disabled}
                    readOnly={this.props.readonly}
                    onChange={(event) => this.handleChange(event)}
                    onBlur={(event) => this.handleBlur(event)}
                    onPaste={this.handlePaste}
                />
                {this.props.children}
            </InputBase>
        );
    }
}

export default Textbox;
