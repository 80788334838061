import React, { Component } from 'react';
import { connect } from 'react-redux';
import { groupBy } from '../../utils/mlp-utils';
import ChooserGridRow from './ChooserGridRow';

class ChooserGrid extends Component {
    selectAll = (isSelected) => {
        this.props.onToggleSelect(isSelected);
    };
    render() {
        const selectedCount = this.props.data.filter((c) => c.IsSelected).length;
        const totalCountText = `${selectedCount}/${this.props.data.length} ${this.props.translations.Phrases['Clients_Selected_Label']}`;
        const groupingAttribute = this.props.groupingAttribute;

        const groupedClientServicesObject = groupBy(this.props.data, groupingAttribute);
        const groupedClientServices = Object.values(groupedClientServicesObject);

        const rows = groupedClientServices.map((val, index) => {
            return (
                <ChooserGridRow key={index + '-' + val.FullNumber} data={val} onClientServiceSelect={this.props.onToggleClientService} />
            );
        });
        return (
            <div className='result-grid-wrapper'>
                <div className='row select-deselect-row'>
                    <div className='col-sm-6 col-6'>
                        <button className='anchor-button' onClick={this.selectAll.bind(this, true)}>
                            {this.props.translations.Phrases['SelectAll_Label']}
                        </button>
                        <button className='anchor-button' onClick={this.selectAll.bind(this, false)}>
                            {this.props.translations.Phrases['Deselect_All_Label']}
                        </button>
                    </div>
                    <div className='col-sm-6 col-6 selected-text-column text-right'>
                        <div className='selected-text'>{totalCountText}</div>
                    </div>
                </div>
                {rows}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};
export default connect(mapStateToProps)(ChooserGrid);
