import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatTranslated, isVisible } from '../../utils/mlp-utils';
import CheckBox from '../CheckBox/CheckBox';

class DocTypeFilter extends Component {
    state = {
        seeMoreDocTypes: '',
        hidden: false
    };

    static defaultProps = {
        countryCodes: ['CA', 'US'],
        searchTypeCode: '*'
    };

    static propTypes = {
        countryCodes: PropTypes.arrayOf(PropTypes.string),
        searchTypeCode: PropTypes.string,
        onValueChanged: PropTypes.func,
        onSelectAll: PropTypes.func,
        customAction: PropTypes.func
    };

    selectAll = (select) => {
        this.props.onSelectAll(select, this.props.filterControl.FilterIndex);

        if (this.props.customAction) {
            this.props.customAction();
        }
    };

    toggleMoreDocTypes = () => {
        this.setState((prevState) => {
            return { seeMoreDocTypes: !prevState.seeMoreDocTypes };
        });
    };

    toggleSection = () => {
        this.setState((prevState) => {
            return { hidden: !prevState.hidden };
        });
    };

    render() {
        const filterControl = this.props.filterControl;
        const filterIndex = filterControl.FilterIndex;
        if (!isVisible(filterControl, this.props.searchTypeCode)) {
            return null;
        }
        const close = this.state.hidden
            ? formatTranslated('Show_Label', this.props.translations.Phrases)
            : formatTranslated('Hide_Label', this.props.translations.Phrases);
        let seeMore = null;
        if (filterControl.Options.length > 5) {
            if (!this.state.seeMoreDocTypes) {
                seeMore = (
                    <div className='btn btn-link' onClick={this.toggleMoreDocTypes}>
                        {formatTranslated('SeeMore_Label', this.props.translations.Phrases)} +
                    </div>
                );
            } else {
                seeMore = (
                    <div className='btn btn-link' onClick={this.toggleMoreDocTypes}>
                        {formatTranslated('SeeLess_Label', this.props.translations.Phrases)} -
                    </div>
                );
            }
        }
        const checkboxes = filterControl.Options.map((f, index) => {
            const name = 'Filters.' + filterIndex + '.Options.' + index + '.IsSelected';
            return (
                <div key={index} className={index < 5 || this.state.seeMoreDocTypes ? 'd-block' : 'd-none'}>
                    <CheckBox
                        name={name}
                        text={f.TranslatedName}
                        checked={f.IsSelected}
                        onChecked={this.props.onValueChanged}
                        customAction={this.props.customAction}
                        noBorder
                        noHeader
                    />
                </div>
            );
        });
        return (
            <div className='filter-section'>
                <div className='row header-section'>
                    <h4 className='col-sm-8 text-left header'>{formatTranslated('DocTypes_Label', this.props.translations.Phrases)}</h4>
                    <div className='col-sm-4 text-right'>
                        <span className='btn btn-link' onClick={this.toggleSection}>
                            {close}
                        </span>
                    </div>
                </div>
                <div className={this.state.hidden ? 'd-none' : 'd-block'}>
                    <div>
                        <span className='btn btn-link' onClick={() => this.selectAll(true)}>
                            {formatTranslated('SelectAll_Label', this.props.translations.Phrases)}
                        </span>
                        <span className='btn btn-link' onClick={() => this.selectAll(false)}>
                            {formatTranslated('SelectNone_Label', this.props.translations.Phrases)}
                        </span>
                    </div>
                    {checkboxes}
                    {seeMore}
                    <hr />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(DocTypeFilter);
