import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import InputBase from '../Inputbase/Inputbase';
import Modal from '../Modal/Modal';
import Calendar from './Calendar';

class DateComponent extends Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.string,
        placeholder: PropTypes.string,
        dateLabel: PropTypes.string,
        valMsg: PropTypes.string,
        onDateChanged: PropTypes.func
    };

    inputElementRef = React.createRef();

    state = { isCalendarOpen: false };

    openCalendarModal = () => {
        this.setState({ isCalendarOpen: true });
    };

    closeCalendarModal = () => {
        this.setState({ isCalendarOpen: false });
    };

    createCalendarModal = () => {
        const DATEFORMAT = this.props.translations.Phrases['Date_Format'];
        const linkText = this.props.translations.Phrases['Select_Date_Label'];
        const calendarModal = (
            <Modal isOpen={this.state.isCalendarOpen} onRequestClose={() => this.closeCalendarModal()} title={linkText}>
                <div className='text-center'>
                    <Calendar
                        customFormat={(x) => {
                            return dayjs(x).startOf('day').format(DATEFORMAT);
                        }}
                        customAction={() => this.closeCalendarModal()}
                        selectedDate={this.props.value}
                        onDateChanged={this.props.onDateChanged}
                    ></Calendar>
                </div>
            </Modal>
        );
        return calendarModal;
    };

    handleChange = (e) => {
        this.props.onDateChanged(e.target.value);
        e.preventDefault();
    };

    validateFormat = (e) => {
        const DATEFORMAT = this.props.translations.Phrases['Date_Format'];
        if (!dayjs(e.target.value, DATEFORMAT, true).isValid()) {
            this.props.onDateChanged('');
            if (e.target.value !== '') {
                let validationFailedMessage = this.props.translations.Phrases['Notices_StartDateFormatErr'];
                if (this.props.name.split('.')[2] === 'EffectiveDatePartTo') {
                    validationFailedMessage = this.props.translations.Phrases['Notices_EndDateFormatErr'];
                }
                toastr.error(validationFailedMessage);
            }
        }
    };

    render() {
        const DATEFORMAT = this.props.translations.Phrases['Date_Format'];
        let date = '';
        const icon = <i id='date-picker-calendar-icon' className='fa fa-calendar fa-lg' />;
        if (this.props.value) {
            if (this.props.value.length > 10) {
                date = dayjs(this.props.value).isValid() ? dayjs(this.props.value).format(DATEFORMAT) : this.props.value;
            } else {
                date = this.props.value;
            }
        }
        const labelClass = this.props.valMsg ? 'date_time-field-validation-error' : '';
        const calendar = this.createCalendarModal();

        return (
            <div className='col-sm-6'>
                <InputBase {...this.props}>
                    <label htmlFor='inputElement' className={labelClass}>
                        {this.props.valMsg ? this.props.translations.Phrases[this.props.valMsg] : this.props.dateLabel}
                    </label>
                    <div className='input-group'>
                        <input
                            ref={this.inputElementRef}
                            placeholder={this.props.placeholder}
                            type={this.props.type}
                            name={this.props.name}
                            value={date}
                            className='form-control'
                            disabled={this.props.disabled}
                            readOnly={this.props.readonly}
                            onChange={(event) => this.handleChange(event)}
                            onBlur={(event) => this.validateFormat(event)}
                        />
                        <div className='input-group-btn'>
                            <button
                                className='date-time-button action-button white dropdown-toggle'
                                onClick={() => this.openCalendarModal()}
                            >
                                {icon}
                            </button>
                        </div>
                    </div>
                </InputBase>
                {calendar}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(DateComponent);
