import type { SolutionMenuItem } from '../../common/models/ResponseData';
import { apiSlice } from './apiSlice';

interface SolutionsMenuResponse {
    SolutionsMenuItems: null | SolutionMenuItem[];
    IsUserConfigured: boolean;
}

interface SolutionsMenuResponseModified {
    solutionsMenuItems: null | SolutionMenuItem[];
    isUserConfigured: boolean;
}

interface SaveSolutionsMenuBody {
    SolutionsMenuItems: null | SolutionMenuItem[];
    token: string | null;
    IsUserConfigured: boolean;
}

export const portalSolutionsMenuApi = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getPortalSolutionsMenu: build.query<SolutionsMenuResponseModified, string>({
            query: (token) => ({
                url: `/Menu/GetPortalSolutionsMenu?token=${token}`
            }),
            transformResponse: (response: SolutionsMenuResponse) => {
                return { solutionsMenuItems: response.SolutionsMenuItems, isUserConfigured: response.IsUserConfigured };
            }
        }),
        savePortalSolutionsMenu: build.mutation<string, SaveSolutionsMenuBody>({
            query: ({ token, ...rest }) => ({
                url: `/Menu/SaveUserPortalSolutionsMenu?token=${token}`,
                method: 'POST',
                body: rest
            })
        })
    })
});

export const { useLazyGetPortalSolutionsMenuQuery, useSavePortalSolutionsMenuMutation } = portalSolutionsMenuApi;
