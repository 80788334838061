import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { useSearchParams } from '../../../../common/hooks/useSearchParams';
import useTranslation from '../../../../common/hooks/useTranslation';
import { TEAM_SEARCH_PARAM_KEYS } from '../../common/constants/constants-team';
import TeamSectionWrapper from '../../common/features/TeamSectionWrapper';
import type { EditTeamMemberData } from '../../common/models';
import { useTeamMemberDetailsQuery } from '../../redux/api/teamApi';
import { useTeamSelector } from '../../redux/hooks';
import type { UserDetailsEditableActions } from './UserDetails/UserDetailsEditable';
import UserDetailsEditable from './UserDetails/UserDetailsEditable';
import UserDetailsNonEditable from './UserDetails/UserDetailsNonEditable';

export type EditTeamMemberUserDetailsActions = {
    getData: () => EditTeamMemberData;
    validate: () => boolean;
};

const EditTeamMemberUserDetails = forwardRef<EditTeamMemberUserDetailsActions>(function EditTeamMemberUserDetails(_, ref) {
    const { user } = useTeamSelector((state) => state.hydration);

    const [searchParams] = useSearchParams();
    const userId = parseInt(searchParams.get(TEAM_SEARCH_PARAM_KEYS.userId) || '');

    const { data, isFetching } = useTeamMemberDetailsQuery(userId.toString());

    const translate = useTranslation();

    const teamMember = data?.Users?.[0];
    const isEditable = useMemo(() => user?.Roles?.includes('TeamManagement_Edit'), [user?.Roles]);
    const isPendingStatus =
        teamMember?.Status === 'PendingUserActivation' ||
        teamMember?.Status === 'PendingFeatureSetup' ||
        teamMember?.Status === 'EmailChangedConfirmRequired' ||
        teamMember?.Status === 'PendingUserConfirmRequired' ||
        teamMember?.Status === 'ActiveFeatureSetupInProgress';
    const isInactiveStatus =
        teamMember?.Status === 'Cancelled' ||
        teamMember?.Status === 'Deactivated' ||
        teamMember?.Status === 'Expired' ||
        teamMember?.Status === 'Hold';

    const userDetailsEditable = useRef<UserDetailsEditableActions>(null);

    useImperativeHandle(ref, () => {
        return {
            getData() {
                return userDetailsEditable.current!.getData();
            },
            validate() {
                return userDetailsEditable.current!.validate();
            }
        };
    });

    return (
        <TeamSectionWrapper title={translate('TeamMemberUserDetailsHeader_Label')} isLoading={isFetching}>
            {isEditable && !isPendingStatus && !isInactiveStatus ? (
                <UserDetailsEditable ref={userDetailsEditable} data={data!} />
            ) : (
                <UserDetailsNonEditable data={data!} />
            )}
        </TeamSectionWrapper>
    );
});

export default EditTeamMemberUserDetails;
