import PropTypes from 'prop-types';
import React, { Component } from 'react';

class AmPmComponent extends Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.string,
        onAmPmChanged: PropTypes.func
    };

    setAmPm = (val) => {
        this.props.onAmPmChanged(val);
    };

    render() {
        let amPm = this.props.value;
        const altAmPm = this.props.value === 'AM' ? 'PM' : 'AM';

        return (
            <div className='col-sm-2'>
                <button
                    id='btn-am-pm'
                    className='date-time-button button action-button white dropdown-toggle am-pm-button'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                    value={amPm}
                >
                    {amPm}
                    <span className='caret'></span>
                </button>
                <ul id='am-pm-dropdown' className='dropdown-menu' onClick={() => this.setAmPm(altAmPm)}>
                    <li>{altAmPm}</li>
                </ul>
            </div>
        );
    }
}

export default AmPmComponent;
