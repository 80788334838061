import $ from 'jquery';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import axios from '../../axios-mlp';
import { dashboard } from '../../constants/mlp-constants';
import { getCountryFromSelectedServices, getSelectedClientServices } from '../../utils/mlp-utils';
import CustomDropDown from '../CustomDropDown/CustomDropDown';
import TextBox from '../Textbox/Textbox';
import ChooserGrid from './ChooserGrid';
import ModalClientPicker from './ModalClientPicker';

class ChooserContent extends Component {
    state = {
        searchText: '',
        enableOk: true,
        isModelChanged: false
    };

    static getDerivedStateFromProps(props, state) {
        if (!state.data && props.data.Countries && props.data.Countries.length > 0) {
            return {
                searchText: '',
                data: { ...props.data }
            };
        }
        return null;
    }

    componentDidMount = () => {
        this.handleSearch = this.debounce((query) => {
            this.getServices(query);
        }, 500);
    };

    debounce = (func, wait = 100) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                func.apply(this, args);
            }, wait);
        };
    };

    getServices = (query) => {
        const model = this.state.data;
        const country = getCountryFromSelectedServices(this.state.data.Countries);
        const selected = model.ClientServices.filter((item) => {
            return item.IsSelected;
        });

        $('#client-chooser-loader').show();
        axios
            .post(dashboard.DASHBOARD_SEARCH_EDM_CLIENT_SERVICES_URL, null, { params: { numberOrName: query, countryCode: country.Code } })
            .then((response) => {
                if (this.state.searchText === '') {
                    return;
                }

                let resultingServices = response.data.filter((item) => {
                    return selected.find((s) => s.FullNumber === item.FullNumber) === undefined;
                });

                const clientServices = resultingServices.concat(selected);

                this.setState((prevState) => ({
                    data: {
                        ...prevState.data,
                        ClientServices: clientServices
                    }
                }));
            })
            .finally(() => {
                $('#client-chooser-loader').hide();
            });
    };

    search = (value) => {
        if (this.props.data.ServicesLimitReached && value.length >= 4) {
            this.handleSearch(value);
        }

        if (value === '') {
            this.resetSearch();
            return;
        }

        this.setState({ searchText: value });
    };

    resetSearch = () => {
        if (this.state.data.ServicesLimitReached) {
            this.setState((prevState) => ({
                data: {
                    ...prevState.data,
                    ClientServices: this.state.data.ClientServices ?? []
                },
                searchText: '',
                isModelChanged: false
            }));
        } else {
            this.setState({ searchText: '', isModelChanged: false });
        }
    };

    chooseCountry = (newIndex, oldIndex) => {
        if (newIndex !== oldIndex) {
            const value = this.state.data.Countries.map((c, index) => {
                if (index === oldIndex) {
                    c = { ...c, IsSelected: false };
                } else if (index === newIndex) {
                    c = { ...c, IsSelected: true };
                }
                return c;
            });

            this.setState((prevState) => ({
                data: {
                    ...prevState.data,
                    Countries: value
                },
                isModelChanged: true
            }));
        }
    };

    toggleAll = (isSelected) => {
        const model = this.state.data;
        const country = this.state.data.Countries.find((c) => {
            return c.IsSelected;
        });
        const countryServices = model.ClientServices.filter((cs) => {
            return cs.CountryCode === country.Code;
        });

        if (isSelected && countryServices.length > model.MaxSelectedServicesPerCountry) {
            toastr.error(this.props.translations.Phrases['MaxServicesSelectedError_Label'], model.MaxSelectedServicesPerCountry);
            return;
        }

        if (countryServices.length > 0) {
            const mapedCS = countryServices.map((cs) => {
                if (this.filterBySearchText(cs, this.state.searchText)) {
                    cs = {
                        ...cs,
                        IsSelected: isSelected
                    };
                }
                return cs;
            });

            const servicesOfNonSelectedCountries = model.ClientServices.filter((cs) => {
                return cs.CountryCode !== country.Code;
            });

            servicesOfNonSelectedCountries.map((s) => mapedCS.push(s))

            this.setState((prevState) => ({
                data: {
                    ...prevState.data,
                    ClientServices: mapedCS
                },
                isModelChanged: true
            }));
        }
    };

    toggleClientService = (isSelected, clientService, toggleGroupFlag) => {
        const model = this.state.data;
        const country = this.state.data.Countries.find((c) => {
            return c.IsSelected;
        });

        if (isSelected && getSelectedClientServices(model).length >= model.MaxSelectedServicesPerCountry) {
            toastr.error(this.props.translations.Phrases['MaxServicesSelectedError_Label'], model.MaxSelectedServicesPerCountry);
            return;
        }

        const servicesToToggle = model.ClientServices.map((cs) => {
            if (
                cs.CountryCode === country.Code &&
                cs.Number === clientService.Number &&
                (toggleGroupFlag || cs.FullNumber === clientService.FullNumber)
            ) {
                cs = {
                    ...cs,
                    IsSelected: isSelected
                };
            }
            return cs;
        });

        this.setState((prevState) => ({
            data: {
                ...prevState.data,
                ClientServices: servicesToToggle
            },
            isModelChanged: true
        }));
    };

    confirmChoice = () => {
        let model = this.state.data;
        const services = model.ServicesLimitReached ? model.ClientServices.filter((cs) => cs.IsSelected) : model.ClientServices;
        model.ClientServices = services;
        if (this.state.isModelChanged) {
            this.saveClientNumberSelection(model);
        } else {
            this.cancelChoice();
        }
    };

    saveClientNumberSelection = (model) => {
        $('#client-chooser-loader').show();
        axios
            .post(dashboard.DASHBOARD_SAVE_CLIENTSERVICESELECTION_URL, model)
            .then(() => {
                this.props.onRequestConfirm(model);
            })
            .catch((error) => {
                if (error.toString() === '') {
                    toastr.error(this.props.translations.Phrases['Client_Selection_Save_Session_Expired_Label']);
                    toastr.clear();
                    // toastr.error('Session is timed out, please log in again.'); //TODO - Change to error label for next iteration(uncomment line above, delete this line)
                } else {
                    toastr.error(this.props.translations.Phrases['Client_Selection_Save_Error_Label']);
                }
            })
            .finally(() => {
                $('#client-chooser-loader').hide();
                this.cancelChoice();
            });
    };

    cancelChoice = () => {
        this.resetSearch();
        this.props.closeModal();
    };

    filterClientServices = () => {
        const searchTxt = this.state.searchText;
        const selectedCountry = this.state.data.Countries ? this.state.data.Countries.find((c) => c.IsSelected) : '';

        const clientServices = this.state.data.ClientServices
            ? this.state.data.ClientServices.filter(
                  (cs) => cs.CountryCode === selectedCountry.Code && (!searchTxt || this.filterBySearchText(cs, searchTxt))
              )
            : '';

        return clientServices;
    };

    filterBySearchText = (cs, matchPattern) => {
        if (matchPattern === undefined || matchPattern === '') return true;
        return (
            cs.FullNumber.includes(matchPattern) ||
            cs.Name.toLowerCase().includes(matchPattern.toLowerCase()) ||
            cs.IsSelected.toString().toLowerCase().includes('true')
        );
    };

    render() {
        if (!this.state.data || !this.state.data.Countries || this.state.data.Countries.length === 0) return null;
        const countryServices = this.filterClientServices();
        const servicesLimitReached = this.props.data.ServicesLimitReached;

        return (
            <div className='client-number-chooser-content'>
                <ModalClientPicker
                    isOpen={this.props.isOpen}
                    onRequestClose={this.cancelChoice}
                    title={this.props.translations.Phrases['ClientChooser_Label']}
                    closeText={this.props.translations.Phrases['Close_Label']}
                    onRequestConfirm={this.confirmChoice}
                    enabledOk={this.state.enableOk}
                >
                    <div className='container'>
                        <div className='row chooser-top-row'>
                            <div className='col-sm-12 country-dropdown'>
                                <CustomDropDown
                                    options={this.state.data.Countries}
                                    label='Select Country'
                                    name='Countries'
                                    textField='Name'
                                    valueField='Code'
                                    onItemChanged={this.chooseCountry}
                                />
                            </div>
                        </div>
                        <div className='row search-row'>
                            <div className='col-sm-12'>
                                <TextBox
                                    placeholder='E.g. 123456'
                                    Name='searchText'
                                    onValueChanged={this.search}
                                    fireOnChange={true}
                                    disableBlurEvent={true}
                                    label='Search Clients'
                                    value={this.state.searchText}
                                />
                                {servicesLimitReached ? (
                                    <small className='text-muted'>{this.props.translations.Phrases['ServicesDisplayedInfo_Label']}</small>
                                ) : null}
                            </div>
                        </div>
                        <ChooserGrid
                            data={countryServices}
                            groupingAttribute={this.props.groupingAttribute}
                            onToggleSelect={this.toggleAll}
                            onToggleClientService={this.toggleClientService}
                        />
                    </div>
                </ModalClientPicker>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};
export default connect(mapStateToProps)(ChooserContent);
