import { memo } from 'react';
import ProductReportPage from './screens/productReport/ProductReportPage';

const ProductReport = memo(function CompleteEntryData() {
    return (
        <main className='main-stage-wrapper h-100 d-flex flex-column flex-fill position-relative'>
            <div className='d-flex flex-column flex-fill'>
                <ProductReportPage />
            </div>
        </main>
    );
});

export default ProductReport;
