import { Component } from 'react';
import { connect } from 'react-redux';
import SearchBox from '../../../common/Searchbox/Searchbox';
import * as actionCreators from '../../../store/actions/index';

class ServiceCoordinatorControl extends Component {
    state = {
        searchBoxValue: ''
    };

    onSelectCoordinator = (coordinator) => {
        this.props.onSelectServiceCoordinator(this.props.countryIndex, coordinator);
    };

    clearCoordinator = () => {
        this.props.onClearServiceCoordinator(this.props.countryIndex);
    };

    searchBoxValueChanged = (val) => {
        this.setState({
            searchBoxValue: val
        });
    };

    render() {
        const selectedSC = this.props.coordinator.Coordinator;
        let selectedSCControl = null;

        if (selectedSC) {
            selectedSCControl = (
                <div className='selected-user-box'>
                    <div className='pull-left'>
                        <div className='text-wrap'>
                            <strong>{selectedSC.FullName}</strong>
                        </div>
                        <div className='text-wrap'>{selectedSC.Email}</div>
                    </div>
                    <div className='pull-right'>
                        <span className='btn btn-link' onClick={this.clearCoordinator}>
                            {this.props.translations.Phrases['Clear_Label']}
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <SearchBox
                label={this.props.coordinator.CountryCode.toUpperCase() + ' ' + this.props.translations.Phrases['ServiceCoordinator_Label']}
                placeholder={this.props.translations.Phrases['TypeHereToSearch_Label']}
                name='ServiceCoordinator'
                url='/ClientAdmin/GetCoordinators'
                titleField='FullName'
                descriptionField='Email'
                minChars={4}
                valMsg={this.props.valMsg}
                onSelectItem={this.onSelectCoordinator}
                value={this.state.searchBoxValue}
                onValueChanged={this.searchBoxValueChanged}
            >
                {selectedSCControl}
            </SearchBox>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectServiceCoordinator: (countryIndex, coordinator) =>
            dispatch(actionCreators.selectServiceCoordinator(countryIndex, coordinator)),
        onClearServiceCoordinator: (countryIndex) => dispatch(actionCreators.clearServiceCoordinator(countryIndex))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCoordinatorControl);
