import type { FormEvent } from 'react';
import Spinner from '../../../../common/features/Spinner';
import useToast from '../../../../common/features/Toast/useToast';
import ModalWrapper from '../../../../common/features/Wrappers/ModalWrapper';
import useTranslation from '../../../../common/hooks/useTranslation';
import { useCancelTeamMemberMutation, useReactivateTeamMemberMutation } from '../../redux/api/teamApi';
import { useTeamDispatch, useTeamSelector } from '../../redux/hooks';
import { closeStatusModal } from '../../redux/reducers/statusUserSlice';

const ChangeUserStatusModal = () => {
    const { statusUserId, isActive } = useTeamSelector((state) => state.statusUser);
    const dispatch = useTeamDispatch();

    const translate = useTranslation();

    const toast = useToast();

    const [cancelTeamMember, { isLoading: cancelTeamMemberIsLoading }] = useCancelTeamMemberMutation();
    const [reactivateTeamMember, { isLoading: reactivateTeamMemberIsLoading }] = useReactivateTeamMemberMutation();
    const isLoading = cancelTeamMemberIsLoading || reactivateTeamMemberIsLoading;

    const handleSubmit = async (userId: number, e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            if (isActive) {
                await cancelTeamMember(userId.toString());
            } else {
                await reactivateTeamMember(userId.toString());
            }

            toast.send({ type: 'success', message: isActive ? translate('UserDeactivate_Label') : translate('UserReactivate_Label') });
        } catch (error) {
            toast.send({ type: 'error', message: translate('SomethingWentWrong_Label') });
        } finally {
            dispatch(closeStatusModal());
        }
    };

    return (
        <ModalWrapper onEscape={() => dispatch(closeStatusModal())}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content' style={{ padding: '15px', maxWidth: '500px' }}>
                    <div className='modal-header' style={{ borderBottom: 'none', paddingBottom: '0' }}>
                        <h3 className='modal-title' style={{ fontSize: '20px' }}>
                            {isActive ? translate('DeactivateUserModal_Label') : translate('ReactivateUserModal_Label')}
                        </h3>
                    </div>
                    <div className='modal-body' style={{ paddingTop: '12px', paddingBottom: '40px' }}>
                        {isActive ? translate('DeactivateModalMessage_Label') : translate('ReactivateModalMessage_Label')}
                    </div>
                    <form className='modal-footer' onSubmit={handleSubmit.bind(null, statusUserId)}>
                        <button
                            type='button'
                            className='btn btn-tertiary-blue btn-lg'
                            onClick={() => {
                                dispatch(closeStatusModal());
                            }}
                        >
                            {translate('Close_Label')}
                        </button>
                        <button type='submit' className='btn btn-danger btn-lg d-flex align-items-center' disabled={isLoading}>
                            {isLoading && <Spinner size='small' className='mr-2' />}
                            {isActive ? translate('ConfirmUserDeactivation_Label') : translate('ConfirmUserReactivation_Label')}
                        </button>
                    </form>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default ChangeUserStatusModal;
