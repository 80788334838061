import useTranslationHydration from '../../../common/hooks/useTranslationHydration';
import TermsFormFrench from '../common/components/TermsFormFrench';
import TermsFormUS from '../common/components/TermsFormUS';
import { useTermsOfUseSelector } from '../redux/hooks';

const TermsOfUse = () => {
    const { languageSelected } = useTermsOfUseSelector((state) => state.language);
    const translate = useTranslationHydration();

    return (
        <div className='flex-fill overflow-auto p-4'>
            <div className='page-top-row'>
                <div className='page-header-section'>
                    <h1
                        style={{
                            fontWeight: '700',
                            color: '#004e9c',
                            margin: '20px 0 20px -3px',
                            textTransform: 'uppercase',
                            fontSize: '46px'
                        }}
                        className='bold-title'
                    >
                        {translate('TermsOfUse_Label')}
                    </h1>
                </div>
            </div>
            <div className='content-wrapper'>
                <div className='row page-content'>{languageSelected?.country === 'CA' ? <TermsFormFrench /> : <TermsFormUS />}</div>
            </div>
        </div>
    );
};

export default TermsOfUse;
