import { MouseEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Spinner from '../../../../../common/features/Spinner';
import useLocalStorageItem from '../../../../../common/hooks/useLocalStorageItem';

interface ClearancesRealTimeDataCardProps {
    title?: string;
    status: string;
    statusLabel: string;
    count?: number;
    dangerText?: boolean;
    linkLabel: string;
    className?: string;
    isLoading: boolean;
    url: string | undefined;
}

const ClearancesRealTimeDataCard: React.FC<ClearancesRealTimeDataCardProps> = ({
    title,
    status,
    statusLabel,
    count,
    dangerText = false,
    className = '',
    isLoading,
    linkLabel,
    url
}) => {
    const history = useHistory();

    const { handleLocalStorageSetItem } = useLocalStorageItem('preApplyStatus');

    const handleClick = (status: string, e: MouseEvent) => {
        e.preventDefault();

        handleLocalStorageSetItem(status);

        if (url) history.push(url);
    };

    return (
        <div className={`card h-100 ${className}`}>
            <div className='card-body'>
                <small className='text-uppercase text-lii-text-light font-weight-bold'>{title}</small>
                <h5 className={`${dangerText ? 'text-danger-dark' : ''} ${isLoading ? 'mb-2' : 'mb-0'}`}>{statusLabel}</h5>
                {isLoading ? (
                    <Spinner />
                ) : (
                    <h1 className={`${dangerText ? 'text-danger-dark' : ''} display-3 mb-0`}>{isLoading ? <Spinner /> : count}</h1>
                )}
            </div>
            <div className='card-footer'>
                {isLoading || !url ? (
                    <span className='btn btn-tertiary'>{linkLabel}</span>
                ) : (
                    <Link to={url} onClick={handleClick.bind(null, status)} className='btn btn-tertiary'>
                        {linkLabel}
                    </Link>
                )}
            </div>
        </div>
    );
};
export default ClearancesRealTimeDataCard;
