import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import useTranslation from '../../../../common/hooks/useTranslation';
import type { TeamMemberData } from '../models';
import FixedFooterWhite from './FixedFooterWhite';

interface TeamMemberFooterProps {
    data: TeamMemberData | undefined;
    isDisabled: boolean;
    isPending: boolean;
    error: { userDetails: boolean; clientsAndPermissions: boolean };
    onSubmit: () => void;
}

const TeamMemberFooter: React.FC<TeamMemberFooterProps> = ({ data, isDisabled, isPending, error, onSubmit }) => {
    const [formIsSubmitted, setFormIsSubmitted] = useState(false);

    const translate = useTranslation();

    let message = '';

    if (formIsSubmitted) {
        if (error.userDetails) {
            message = translate('AddAtLeastOneUser_Label');
        } else if (error.clientsAndPermissions) {
            message =
                data?.AdminRole === 'CentralAdmin'
                    ? translate('ClearancesFeatureMustBeSelected_Label')
                    : translate('MLPFeatureMustBeSelected_Label');
        }
    }

    const handleAddingUsers = () => {
        setFormIsSubmitted(true);

        if (data) {
            onSubmit();
        }
    };

    if (!data) return null;

    return (
        <FixedFooterWhite
            isPending={isPending}
            primaryLabel={translate('SaveUsers_Label')}
            primaryIcon={faUserPlus}
            primaryOnClick={handleAddingUsers}
            primaryDisabled={isDisabled}
            message={message}
        />
    );
};

export default TeamMemberFooter;
