import $ from 'jquery';
import queryString from 'query-string';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import toastr from 'toastr';
import axios from '../../axios-mlp';
import CheckBox from '../../common/CheckBox/CheckBox';
import SubmitButton from '../../common/SubmitButton/SubmitButton';
import TextArea from '../../common/Textarea/Textarea';
import { internalUser as Constants } from '../../constants/mlp-constants';
import * as actions from '../../store/actions/index';
import { formatTranslated, setValueByPath } from '../../utils/mlp-utils';
import EditClientAdminHeader from './EditClientAdminHeader';

class EditInternalUserForm extends Component {
    clientNumbersToAddRef = React.createRef();
    clientNumbersToRemoveRef = React.createRef();

    componentDidMount = () => {
        let userId = null;
        if (this.props.location.state) {
            userId = this.props.location.state.userId;
        } else {
            const params = queryString.parse(this.props.location.search);
            if (params.userId) {
                userId = parseInt(params.userId);
            }
        }

        if (userId !== null) {
            this.props.onInitInternalUserForm(userId);
        } else {
            this.props.history.push('/Error');
        }

        this.props.onInitTranslations('AccessManagement.AccessManagement.InternalUser');
    };

    componentDidUpdate(prevProps) {
        if (this.props.translations.Phrases !== prevProps.translations.Phrases) {
            $(ReactDOM.findDOMNode(this.clientNumbersToAddRef.current)).tooltip({
                title: formatTranslated('AddClientNumbersHint_Label', this.props.translations.Phrases),
                placement: 'bottom'
            });

            $(ReactDOM.findDOMNode(this.clientNumbersToRemoveRef.current)).tooltip({
                title: formatTranslated('RemoveClientNumbersHint_Label', this.props.translations.Phrases),
                placement: 'bottom'
            });
        }
    }

    validateForm = () => {
        axios
            .post(Constants.INTERNAL_USER_VALIDATE, this.props.model)
            .then((response) => {
                let validationMessages = {};
                const json = JSON.parse(response.data);
                for (let i in json) {
                    setValueByPath(validationMessages, i, json[i]);
                }
                this.props.onSetValidationMessages(validationMessages);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data && error.response.data.Message) {
                        if (error.response.status === 400) {
                            toastr.warning(error.response.data.Message);
                        } else {
                            toastr.error(error.response.data.Message);
                        }
                    } else if (error.response.status !== 401 && error.response.status !== 403) {
                        this.props.history.push('/BasicError');
                    }
                }
            });
    };

    componentWillUnmount = () => {
        this.props.onResetStateInternalUser();
    };

    render() {
        const clientAddText = this.props.model.ValidationMessages
            ? this.props.translations.Phrases[this.props.model.ValidationMessages.ClientNumbersToAddText]
            : '';
        const clientRemoveText = this.props.model.ValidationMessages
            ? this.props.translations.Phrases[this.props.model.ValidationMessages.ClientNumbersToRemoveText]
            : '';
        let submitSection = null;
        if (this.props.model.IsReadOnly) {
            submitSection = (
                <div className='col-sm-12 text-right'>
                    <Link to={Constants.SEARCH_INTERNAL_USERS_URL}>{this.props.translations.Phrases['Cancel_Button']}</Link>
                </div>
            );
        } else {
            submitSection = (
                <div className='col-sm-12 text-right'>
                    <SubmitButton
                        model={this.props.model}
                        label={this.props.translations.Phrases['Submit_Button']}
                        postActionUrl={Constants.SUBMIT_INTERNAL_USER}
                        postAction={this.props.onSetValidationMessages}
                        onSuccessfulSubmit={{
                            backToPath: '/Dashboard',
                            backToLabel: 'BackToDashboard_Label',
                            phraseGroup: 'AccessManagement.AccessManagement.EditSuccessful'
                        }}
                    />
                    <Link to={Constants.SEARCH_INTERNAL_USERS_URL} className='btn-link pull-right button small'>
                        {this.props.translations.Phrases['Cancel_Button']}
                    </Link>
                </div>
            );
        }
        let clientServiceArea = null;
        if (!this.props.model.HasUnrestrictedAccess) {
            clientServiceArea = (
                <div className='row'>
                    <div ref={this.clientNumbersToAddRef} className='col-sm-6'>
                        <TextArea
                            label={this.props.translations.Phrases['AddClientNumbers_Label']}
                            rows='12'
                            value={this.props.model.ClientNumbersToAddText == null ? '' : this.props.model.ClientNumbersToAddText}
                            name='ClientNumbersToAddText'
                            readonly={this.props.model.IsReadOnly}
                            onValueChanged={this.props.onHandleUpdate}
                        ></TextArea>
                        <div className='validation-text'>{clientAddText}</div>
                    </div>
                    <div ref={this.clientNumbersToRemoveRef} className='col-sm-6'>
                        <TextArea
                            label={this.props.translations.Phrases['RemoveClientNumbers_Label']}
                            readonly={this.props.model.IsReadOnly}
                            rows='12'
                            value={this.props.model.ClientNumbersToRemoveText == null ? '' : this.props.model.ClientNumbersToRemoveText}
                            name='ClientNumbersToRemoveText'
                            onValueChanged={this.props.onHandleUpdate}
                        />
                        <div className='validation-text'>{clientRemoveText}</div>
                    </div>
                </div>
            );
        }
        return (
            <div id='page' className='wide-page'>
                <div className='page-wrapper'>
                    <div className='page-top-row'>
                        <div className='page-header-section'>
                            <nav className='mlp-breadcrumb'>
                                <Link to='/Dashboard' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                </Link>
                                <i className='fa fa-caret-right'></i>
                                <Link to='/AccessManagement/AccessManagement/SearchInternalUsers' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_2']}
                                </Link>
                                <i className='fa fa-caret-right'></i>
                                <span>{this.props.translations.Phrases['Page_Header_Navigation_Level_3']}</span>
                            </nav>
                            <h1 className='bold-title'>{this.props.translations.Phrases['Page_Header']}</h1>
                        </div>
                    </div>
                    <div className='content-wrapper'>
                        <div className='row page-content'>
                            <div id='edit-internal-user-form' className='w-100'>
                                <EditClientAdminHeader
                                    isEditMode={!this.props.model.IsReadOnly}
                                    viewModel={this.props.model}
                                    handleUpdate={this.props.onHandleUpdate}
                                    validateForm={this.validateForm}
                                    cancelUser={this.props.onCancelUser}
                                    reactivateUser={this.props.onReactivateUser}
                                    validationMessages={this.props.validationMessages}
                                />
                                <div className='row'>
                                    <div className='col-sm-2'>
                                        <CheckBox
                                            name='HasUnrestrictedAccess'
                                            noBorder={true}
                                            noHeader={true}
                                            disabled={this.props.model.IsReadOnly}
                                            text={this.props.translations.Phrases['UserHasUnrestrictedAccess_Label']}
                                            checked={this.props.model.HasUnrestrictedAccess}
                                            onChecked={this.props.onHandleUpdate}
                                        />
                                    </div>
                                </div>
                                {clientServiceArea}
                                <div className='row'>{submitSection}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        model: state.internalUser.model,
        isInitModel: state.internalUser.isInitModel,
        translations: state.translations.model,
        validationMessages: state.internalUser.ValidationMessages
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actions.initTranslations(phraseGroup)),
        onInitInternalUserForm: (userId) => dispatch(actions.initEditInternalUser(userId)),
        onSetInternalUser: (internalUser) => dispatch(actions.setInternalUser()),
        onSetValidationMessages: (validationMessages) => dispatch(actions.setValidationMessagesInternalUser(validationMessages)),
        onHandleUpdate: (value, key) => dispatch(actions.handleUpdateInternalUser(value, key)),
        onCancelUser: (userId) => dispatch(actions.cancelUserInternal(userId)),
        onReactivateUser: (userId) => dispatch(actions.reactivateUserInternal(userId)),
        onResetStateInternalUser: () => dispatch(actions.resetStateInternalUser())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditInternalUserForm));
