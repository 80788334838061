import { Component } from 'react';
import CheckIcon from '../Icons/CheckIcon';

class ChooserGridRowDetails extends Component {
    render = () => {
        const rows = this.props.data.map((cs, index) => {
            const displayText = cs.Caption + ' - ' + cs.Name;
            return (
                <div className='row detail-row' key={cs.Name + index}>
                    <div className='col-sm-9 offset-sm-1'>{displayText}</div>
                    <div className='col-sm-2' onClick={() => this.props.onClientServiceSelect(!cs.IsSelected, cs, false)}>
                        <input
                            type='checkbox'
                            checked={cs.IsSelected}
                            onChange={() => this.props.onClientServiceSelect(!cs.IsSelected, cs, false)}
                            className='checkbox-none'
                        />
                        <CheckIcon isChecked={cs.IsSelected} />
                    </div>
                </div>
            );
        });

        return <div>{rows}</div>;
    };
}
export default ChooserGridRowDetails;
