import type { GridCellProps } from '@progress/kendo-react-grid';
import type { ClientService } from '../models';

interface ClientsAndPermissionsTableCheckboxCellProps extends GridCellProps {
    isDisabled?: boolean;
    dataItem: ClientService;
    isChecked: boolean | null | undefined;
    onSelect: (payload: { clientServiceIndex: number; featureName: string }) => void;
}

export default function ClientsAndPermissionsTableCheckboxCell({
    isDisabled,
    dataItem,
    field,
    dataIndex,
    isChecked,
    onSelect
}: ClientsAndPermissionsTableCheckboxCellProps) {
    const id = dataIndex + (dataItem.Number || '') + field;

    return (
        <td>
            <div className='d-flex justify-content-center'>
                <div className='custom-control custom-checkbox'>
                    <input
                        type='checkbox'
                        className='custom-control-input'
                        id={id}
                        defaultChecked={isChecked || undefined}
                        disabled={isDisabled}
                        onChange={onSelect.bind(null, { clientServiceIndex: dataIndex, featureName: field || '' })}
                    />
                    <label className='custom-control-label' htmlFor={id} />
                </div>
            </div>
        </td>
    );
}
