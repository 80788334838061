import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { emptySubscriber } from '../../../../common/constants/constants-portal';
import { NewSubscriber } from '../../../../common/models/NewSubscriber';
import { GetUserWidthGridSetting } from '../../../profile/redux/actions/subscriptions';
import { SubscribersSlice } from '../../common/models/ReduxSlices';
import { Subscriber } from '../../common/models/Subscriber';
import {
    GetTeamMembersAndContacts,
    GetUserSubscribersData,
    SubscribeUsers,
    UnsubscribeUsers,
    UpdateSubscription
} from '../actions/subscribersManagement';

const initialState: SubscribersSlice = {
    subscribers: null,
    isLoading: false,
    allColumns: [],
    visibleColumns: [],
    error: undefined,
    subscribe: {
        data: null,
        isLoading: false,
        error: undefined,
        newSubscribersAmount: null
    },
    unsubscribe: {
        data: null,
        isLoading: false,
        error: undefined,
        removedSubscribersAmount: null
    },
    newSubscribers: [emptySubscriber],
    subscriptionUpdate: {
        data: null,
        isLoading: false,
        error: undefined,
        action: null,
        alertMessage: null,
        alertType: null
    },
    clients: null,
    selectedClients: null,
    filteredClients: null,
    clientsState: null,
    membersAndContacts: null
};

const subscribersSlice = createSlice({
    name: 'subscribers',
    initialState,
    reducers: {
        // STATE UPDATES/CHANGES
        setSubscriptionUpdateAction(state, action: PayloadAction<string>) {
            state.subscriptionUpdate.action = action.payload;
        },
        setNewSubscriberEmail(state, action: PayloadAction<{ id: number; value: string; isValid: boolean }>) {
            state.newSubscribers = state.newSubscribers.map((subscriber) =>
                subscriber.id === action.payload.id
                    ? { ...subscriber, email: action.payload.value, emailIsValid: action.payload.isValid }
                    : subscriber
            );
        },
        setNewSubscriberPhone(state, action: PayloadAction<{ id: number; value: string; isValid: null | boolean }>) {
            state.newSubscribers = state.newSubscribers.map((subscriber) =>
                subscriber.id === action.payload.id
                    ? { ...subscriber, phone: action.payload.value, phoneIsValid: action.payload.isValid }
                    : subscriber
            );
        },
        setNewSubscriberFullName(state, action: PayloadAction<{ id: number; value: string }>) {
            state.newSubscribers = state.newSubscribers.map((subscriber) =>
                subscriber.id === action.payload.id ? { ...subscriber, fullName: action.payload.value } : subscriber
            );
        },
        setNewSubscriberUserId(state, action: PayloadAction<{ id: number; value: string }>) {
            state.newSubscribers = state.newSubscribers.map((subscriber) =>
                subscriber.id === action.payload.id ? { ...subscriber, userId: action.payload.value } : subscriber
            );
        },
        setNewSubscriberRole(state, action: PayloadAction<{ id: number; value: NewSubscriber['role'] }>) {
            state.newSubscribers = state.newSubscribers.map((subscriber) =>
                subscriber.id === action.payload.id ? { ...subscriber, role: action.payload.value } : subscriber
            );
        },
        setNewSubscriberLanguage(state, action: PayloadAction<{ id: number; value: NewSubscriber['language'] }>) {
            state.newSubscribers = state.newSubscribers.map((subscriber) =>
                subscriber.id === action.payload.id ? { ...subscriber, language: action.payload.value } : subscriber
            );
        },
        addNewSubscriber(state) {
            state.newSubscribers.push({ ...emptySubscriber, id: Math.random() * 1000 + 1 });
        },
        removeNewSubscriber(state, action: PayloadAction<number>) {
            if (state.newSubscribers.length === 1) {
                state.newSubscribers = initialState.newSubscribers;
                return;
            }
            state.newSubscribers = state.newSubscribers.filter((subscriber) => subscriber.id !== action.payload);
        },
        resetNewSubscriberFields(state, action: PayloadAction<number>) {
            state.newSubscribers = state.newSubscribers.map((subscriber) =>
                subscriber.id === action.payload
                    ? { ...subscriber, role: '', language: '-', email: '', emailIsValid: false, phone: '', phoneIsValid: false }
                    : subscriber
            );
        },
        resetSubscribe(state) {
            state.subscribe = initialState.subscribe;
        },
        resetUnsubscribe(state) {
            state.unsubscribe = initialState.unsubscribe;
        },
        resetNewSubscribers(state) {
            state.newSubscribers = initialState.newSubscribers;
        },
        resetSubscriptionUpdate(state) {
            state.subscriptionUpdate = initialState.subscriptionUpdate;
        },
        clearNewSubscribers: (state) => {
            if (state.subscribers?.length) {
                state.subscribers = [
                    ...state.subscribers.map((el) => ({
                        ...el,
                        isNew: false
                    }))
                ];
            }
        },
        // CLIENTS
        setClients(state, action: PayloadAction<any>) {
            state.clients = action.payload;
        },
        setClientsState(state, action: PayloadAction<any>) {
            state.clientsState = action.payload;
            const filteredState = { ...action.payload };
            for (const [key, value] of Object.entries(filteredState)) {
                if (value === false) delete filteredState[key];
            }
            state.selectedClients = filteredState;
        },
        setFilteredCLients(state, action: PayloadAction<any>) {
            state.filteredClients = action.payload;
        },
        resetClientsState(state) {
            state.clients = initialState.clients;
            state.selectedClients = initialState.selectedClients;
            state.clientsState = initialState.clientsState;
            state.filteredClients = initialState.filteredClients;
        },
        resetSubscribers() {
            return { ...initialState };
        }
    },
    extraReducers: (builder) => {
        builder
            // GET SUBSCRIBERS
            .addCase(GetUserSubscribersData.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetUserSubscribersData.fulfilled, (state, action) => {
                state.isLoading = false;

                if (state.subscribers?.length) {
                    const newSubs = [
                        ...action.payload.map((el: Subscriber) => ({
                            ...el,
                            isNew: state.subscribers?.find((oldEl) => oldEl.MobileNumber === el.MobileNumber && oldEl.Email === el.Email)
                                ? false
                                : true
                        }))
                    ];

                    state.subscribers = newSubs;
                } else {
                    state.subscribers = action.payload;
                }
            })
            .addCase(GetUserSubscribersData.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(GetUserWidthGridSetting.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetUserWidthGridSetting.fulfilled, (state, action) => {
                state.allColumns = action.payload.GridColumns;
                state.visibleColumns = action.payload.GridColumns;
                state.isLoading = false;
            })
            .addCase(GetUserWidthGridSetting.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            // SUBSCRIBE (SUBSCRIBERS GRID)
            .addCase(SubscribeUsers.pending, (state, action) => {
                state.subscriptionUpdate = initialState.subscriptionUpdate;
                state.unsubscribe = initialState.unsubscribe;
                state.subscribe.isLoading = true;
                state.subscribe.error = undefined;
                state.subscribe.newSubscribersAmount = action.meta.arg.subscribers.length;
            })
            .addCase(SubscribeUsers.fulfilled, (state, action) => {
                state.subscribe.isLoading = false;
                state.subscribe.data = action.payload!;
            })
            .addCase(SubscribeUsers.rejected, (state, action) => {
                state.subscribe.isLoading = false;
                state.subscribe.newSubscribersAmount = initialState.subscribe.newSubscribersAmount;
                if (typeof action.payload === 'number') {
                    state.subscribe.error = action.payload;
                } else {
                    state.subscribe.error = action.error.message;
                }
            })
            // UNSUBSCRIBE (SUBSCRIBERS GRID)
            .addCase(UnsubscribeUsers.pending, (state, action) => {
                state.subscriptionUpdate = initialState.subscriptionUpdate;
                state.subscribe = initialState.subscribe;
                state.unsubscribe.isLoading = true;
                state.unsubscribe.error = undefined;
                state.unsubscribe.removedSubscribersAmount = action.meta.arg?.length;
            })
            .addCase(UnsubscribeUsers.fulfilled, (state, action) => {
                state.unsubscribe.isLoading = false;
                state.unsubscribe.data = action.payload;
            })
            .addCase(UnsubscribeUsers.rejected, (state, action) => {
                state.unsubscribe.isLoading = false;
                state.unsubscribe.removedSubscribersAmount = initialState.unsubscribe.removedSubscribersAmount;
                if (typeof action.payload === 'number') {
                    state.unsubscribe.error = action.payload;
                } else {
                    state.unsubscribe.error = action.error.message;
                }
            })
            // UPDATE SUBSCRIPTION (SUBSCRIBERS GRID)
            .addCase(UpdateSubscription.pending, (state, action) => {
                state.unsubscribe = initialState.unsubscribe;
                state.subscribe = initialState.subscribe;
                state.subscriptionUpdate.isLoading = true;
                state.error = undefined;
            })
            .addCase(UpdateSubscription.fulfilled, (state, action) => {
                state.subscriptionUpdate.isLoading = false;
                state.subscriptionUpdate.data = action.payload;

                if (action.payload.ResponseCode === 400 && action.payload.ResponseMessage === 'FAIL') {
                    state.subscriptionUpdate.alertMessage = 'ClientNumberNoClearancesAvailable_Label';
                    state.subscriptionUpdate.alertType = 'warning';
                } else {
                    if (action.payload.RequestedAction === 'UPDATE' && action.payload.ResponseMessage === 'FAIL') {
                        state.subscriptionUpdate.alertMessage = 'SubscriptionWarning_Label';
                        state.subscriptionUpdate.alertType = 'warning';
                    } else {
                        if (state.subscriptionUpdate.action === 'onhold') {
                            state.subscriptionUpdate.alertMessage = 'OnHoldNotificationsUpdated_Label';
                        } else {
                            state.subscriptionUpdate.alertMessage = 'MilestonesUpdated_Label';
                        }
                        state.subscriptionUpdate.alertType = 'success';
                    }
                }
            })
            .addCase(UpdateSubscription.rejected, (state, action) => {
                state.subscriptionUpdate.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.subscriptionUpdate.error = action.payload;
                } else {
                    state.subscriptionUpdate.error = action.error.message;
                }
            })
            // GET TEAM MEMBERS AND EXTERNAL CONTACTS
            .addCase(GetTeamMembersAndContacts.pending, (state, action) => {
                state.error = undefined;
            })
            .addCase(GetTeamMembersAndContacts.fulfilled, (state, action) => {
                state.membersAndContacts = action.payload;
            })
            .addCase(GetTeamMembersAndContacts.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const {
    setSubscriptionUpdateAction,
    setNewSubscriberEmail,
    setNewSubscriberPhone,
    setNewSubscriberFullName,
    setNewSubscriberUserId,
    setNewSubscriberRole,
    setNewSubscriberLanguage,
    addNewSubscriber,
    removeNewSubscriber,
    resetSubscribe,
    resetUnsubscribe,
    resetSubscriptionUpdate,
    resetNewSubscribers,
    resetNewSubscriberFields,
    setClients,
    setFilteredCLients,
    setClientsState,
    resetClientsState,
    resetSubscribers,
    clearNewSubscribers
} = subscribersSlice.actions;

export default subscribersSlice.reducer;
