import { useEffect, useState } from 'react';

interface UseDisableColumnsDragProps {
    isLoading: boolean;
    elements: HTMLElement[];
}

const useDisableColumnsDrag = ({ isLoading, elements }: UseDisableColumnsDragProps) => {
    const [disableDrag, setDisableDrag] = useState(false);
    const [touchedHasNoDrag, setTouchedHasNoDrag] = useState(false);
    useEffect(() => {
        if (!isLoading) {
            // Touchscreen devices
            if (window.matchMedia('(any-pointer: coarse)').matches && elements[elements.length - 1]) {
                const handleDragMobile = (event: TouchEvent) => {
                    var hoveredElement;
                    if (event.type === 'touchstart') {
                        hoveredElement = document.elementFromPoint(event.touches[0].clientX, event.touches[0].clientY);
                        if (hoveredElement && hoveredElement?.classList.contains('no-drag')) {
                            setDisableDrag(true);
                            setTouchedHasNoDrag(true);
                        } else {
                            setDisableDrag(false);
                            setTouchedHasNoDrag(false);
                        }
                    }
                    if (event.type === 'touchmove') {
                        hoveredElement = document.elementFromPoint(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
                        hoveredElement?.classList.contains('no-drag') || touchedHasNoDrag ? setDisableDrag(true) : setDisableDrag(false);
                    }
                };

                document.addEventListener('touchstart', handleDragMobile);
                document.addEventListener('touchmove', handleDragMobile);
                return () => {
                    document.removeEventListener('touchstart', handleDragMobile);
                    document.removeEventListener('touchmove', handleDragMobile);
                };
            } else {
                // Desktop
                const handleDrag = (event: MouseEvent) => {
                    if (event.type === 'mouseenter' && (event.target as HTMLElement).tagName !== 'input') {
                        setDisableDrag(true);
                    } else if (event.type === 'mouseleave' && (event.target as HTMLElement).tagName !== 'input') {
                        setDisableDrag(false);
                    }
                };

                elements.forEach((element) => {
                    if (element && !element.hasAttribute('listener')) {
                        element.style.cursor = 'arrow';
                        element.setAttribute('listener', '');
                        (element as HTMLElement).addEventListener('mouseenter', handleDrag);
                        (element as HTMLElement).addEventListener('mouseleave', handleDrag);
                    }
                    return () => {
                        if (element && element.hasAttribute('listener')) {
                            element.removeAttribute('listener');
                            (element as HTMLElement).removeEventListener('mouseenter', handleDrag);
                            (element as HTMLElement).removeEventListener('mouseleave', handleDrag);
                        }
                    };
                });
            }
        }
    }, [isLoading, elements, touchedHasNoDrag]);

    return { disableDrag };
};

export default useDisableColumnsDrag;
