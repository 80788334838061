import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import useTranslation from '../../../common/hooks/useTranslation';

const LAST_UPDATED = '2024-11-02T00:00:00Z';
const DATE_FORMAT = 'MMMM YYYY';

export default function FaqGuideLastUpdated() {
    const translate = useTranslation();

    const lastUpdated = dayjs(LAST_UPDATED).locale('en').format(DATE_FORMAT);

    return (
        <p className='text-right'>
            {translate('LastUpdated_Label')} <i>{lastUpdated}</i>
        </p>
    );
}
