import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';

import './styles.css';

interface Props {
    id: string;
    text: string;
    setSearchKeywords: React.Dispatch<
        React.SetStateAction<
            {
                id: string;
                text: string;
            }[]
        >
    >;
    onRemove: (keywordId: string) => void;
}

const SearchChip: React.FC<Props> = ({ text, setSearchKeywords, onRemove, id: idProp }) => {
    const spanRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (spanRef.current) {
            spanRef.current.innerText = text;
        }
    }, [text]);

    const handleChangeValue = (e: React.FocusEvent<HTMLSpanElement, Element>) => {
        setSearchKeywords((prevState) =>
            prevState.map((item) => (item.id === idProp ? { id: item.id, text: e.currentTarget.innerText } : item))
        );
    };

    return (
        <div className={'search-chip'}>
            <span
                id='search_chip_input_span'
                ref={spanRef}
                role='textbox'
                contentEditable='true'
                suppressContentEditableWarning={true}
                onBlur={handleChangeValue}
                onKeyDown={(e) => {
                    // Prevent special characters from being added
                    if (/[^a-zA-Z0-9/_-]/.test(e.key) || e.key === 'Enter') {
                        e.preventDefault();
                    }
                }}
            >
                {text}
            </span>

            <span className='pl-2' onClick={onRemove.bind(null, idProp)}>
                <FontAwesomeIcon icon={faTimes} />
            </span>
        </div>
    );
};

export default SearchChip;
