import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Badge from '../../../common/features/Badge';
import CloseButton from '../../../common/features/CloseButton';
import { createDateForAlert } from '../../../common/functions/parser';
import useTranslationHydration from '../../../common/hooks/useTranslationHydration';
import { usePortalSelector } from '../../../redux/hooks';
import { AgencyList } from '../../constants/constants-portal';
import ToastWrapper from '../Wrappers/ToastWrapper';
import classes from './AlertToastCard.module.css';

const TIMEOUT = 8000;

type AlertToastCardProps = (
    | {
          type: 'ON_HOLD' | 'IN_PROGRESS' | 'UNDER_REVIEW';
          entryNumber: string;
          agencyKey: string;
          customsInfoID: number;
          eventTypeKey: string;
          title?: undefined;
          description?: undefined;
      }
    | {
          type: 'NOTICE';
          title: string;
          description: string;
          entryNumber?: undefined;
          agencyKey?: undefined;
          customsInfoID?: undefined;
          eventTypeKey?: undefined;
      }
) & { date: string; timeout?: number };

const AlertToastCard: React.FC<AlertToastCardProps> = ({
    type,
    title,
    date,
    description = '',
    entryNumber,
    agencyKey,
    customsInfoID,
    eventTypeKey
}) => {
    const { languageSelected } = usePortalSelector((state) => state.language);
    const { user } = usePortalSelector((state) => state.hydration);

    const [isVisible, setIsVisible] = useState(true);
    const translate = useTranslationHydration();

    const timeout = user?.PortalTimeoutLong || TIMEOUT;

    const closeToast = () => {
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <ToastWrapper type={type === 'NOTICE' ? 'notice-alert' : 'on-hold-alert'} timeout={timeout}>
            <div className='toast-body alert-toasts d-flex flex-column align-items-start p-4'>
                <div className='d-flex justify-content-between w-100'>
                    {/* Badge: Shipment on hold */}
                    {type === 'ON_HOLD' && (
                        <Badge className='mb-2' type='danger'>
                            <FontAwesomeIcon icon={faExclamationCircle} className='mr-1' />
                            {translate('ShipmentOnHold_Label')}
                        </Badge>
                    )}
                    {/* Badge: Shipment under review */}
                    {type === 'UNDER_REVIEW' && (
                        <Badge className='mb-2' type='warning'>
                            <FontAwesomeIcon icon={faInfoCircle} className='mr-1' />
                            {translate('ShipmentUnderReview_Label')}
                        </Badge>
                    )}
                    {/* Badge: In progress */}
                    {type === 'IN_PROGRESS' && (
                        <Badge className='mb-2' type='info'>
                            {translate('StatusInProgress_Label')}
                        </Badge>
                    )}
                    {/*Badge: Livingston notice */}
                    {type === 'NOTICE' && (
                        <Badge className='mb-2' type='neutral'>
                            {translate('LivingstonNoticeTitle_Label')}
                        </Badge>
                    )}
                    {/* Date and Close button */}
                    <div className='d-flex ml-2 align-items-start'>
                        <p className={`${classes.date} mb-0`}>{createDateForAlert(date, languageSelected?.locale)}</p>
                        <CloseButton type='toast' className={`ml-1 mt-n1 ${classes['btn-close']}`} onClick={closeToast} />
                    </div>
                </div>
                {/* Title */}
                <p className='toast-title w-100 mb-1 w-100'>
                    <strong>
                        {type?.toUpperCase() === 'ON_HOLD'
                            ? /hold/i.test(eventTypeKey || '')
                                ? translate(
                                      'AlertsOnHold_Label',
                                      entryNumber,
                                      translate(AgencyList[agencyKey as keyof typeof AgencyList].label)
                                  )
                                : /rejected/i.test(eventTypeKey || '')
                                ? translate(
                                      'EntryReviewedRejected_Label',
                                      entryNumber,
                                      translate(AgencyList[agencyKey as keyof typeof AgencyList].label)
                                  )
                                : null
                            : null}
                        {type === 'UNDER_REVIEW' &&
                            translate(
                                'AlertsInProgress_Label',
                                entryNumber,
                                translate(AgencyList[agencyKey as keyof typeof AgencyList].label)
                            )}
                        {type === 'IN_PROGRESS' &&
                            translate(
                                'EntryReviewedAccepted_Label',
                                entryNumber,
                                translate(AgencyList[agencyKey as keyof typeof AgencyList].label)
                            )}
                        {type === 'NOTICE' && title}
                    </strong>
                </p>
                {/* Description */}
                {type === 'ON_HOLD' && (
                    <p className='toast-desc mb-0'>
                        {/customs/i.test(agencyKey || '')
                            ? translate('HoldAlert_Label')
                            : translate('ClickBelowAdditionalInformation_Label')}
                    </p>
                )}
                {(type === 'UNDER_REVIEW' || type === 'IN_PROGRESS') && (
                    <p className='toast-desc mb-0'>{translate('ClickBelowAdditionalInformation_Label')}</p>
                )}
                {type === 'NOTICE' && (
                    <p
                        className='toast-desc mb-0'
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description.replace(/&nbsp;/g, ' ')) }}
                    ></p>
                )}
                {/* View entry link */}
                {(type === 'ON_HOLD' || type === 'IN_PROGRESS' || type === 'UNDER_REVIEW') && (
                    <Link to={`/Clearances/${customsInfoID}`} className='btn btn-tertiary-blue mt-3'>
                        {translate('ViewEntry_Label')}
                    </Link>
                )}
            </div>
        </ToastWrapper>
    );
};

export default AlertToastCard;
