import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AmPmComponent from './AmPmComponent';
import DateComponent from './DateComponent';
import TimeComponent from './TimeComponent';

class DateTimePicker extends Component {
    static propTypes = {
        date: PropTypes.string,
        time: PropTypes.string,
        amPm: PropTypes.string,
        dateLabel: PropTypes.string,
        timeLabel: PropTypes.string,
        datePlaceholder: PropTypes.string,
        timePlaceholder: PropTypes.string,
        dateName: PropTypes.string,
        timeName: PropTypes.string,
        amPmName: PropTypes.string,
        dateValMsg: PropTypes.string,
        timeValMsg: PropTypes.string,
        onDateChanged: PropTypes.func,
        onTimeChanged: PropTypes.func,
        onAmPmChanged: PropTypes.func
    };

    render() {
        return (
            <div id='date-time-picker'>
                <div className='row'>
                    <DateComponent
                        name={this.props.dateName}
                        value={this.props.date}
                        placeholder={this.props.datePlaceholder}
                        dateLabel={this.props.dateLabel}
                        valMsg={this.props.dateValMsg}
                        onDateChanged={this.props.onDateChanged}
                        noBorder
                        noHeader
                    ></DateComponent>
                    <TimeComponent
                        name={this.props.timeName}
                        value={this.props.time}
                        placeholder={this.props.timePlaceholder}
                        timeLabel={this.props.timeLabel}
                        valMsg={this.props.timeValMsg}
                        onTimeChanged={this.props.onTimeChanged}
                        noBorder
                        noHeader
                    ></TimeComponent>
                    <AmPmComponent name={this.props.amPmName} value={this.props.amPm} onAmPmChanged={this.props.onAmPmChanged}></AmPmComponent>
                </div>
            </div>
        );
    }
}

export default DateTimePicker;
