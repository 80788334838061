import { ShipmentsCASS } from '../../../completeEntryData/common/models/completeEntryDataModels';

export const calculateTextWidth = (text: string, defaultWidth: number, freeSpace: number, font = '16px Arial') => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
        context.font = font;
        const metrics = context.measureText(text);
        const columnWidth = metrics.width + freeSpace;

        return columnWidth > defaultWidth ? columnWidth : defaultWidth;
    }

    return defaultWidth;
};

export const getColumnWidth = (column: string, title: string, data: ShipmentsCASS[]): number => {
    const defaultWidth = 200;
    const freeSpace = 20;

    const longestValue = getLongestValueByProperty(data, column as keyof ShipmentsCASS);

    const text = longestValue.length > title.length ? longestValue : title;

    return calculateTextWidth(text, defaultWidth, freeSpace);
};

const getLongestValueByProperty = (array: ShipmentsCASS[], parameter: keyof ShipmentsCASS): string => {
    return array
        .map((obj) => String(obj[parameter]))
        .reduce((longest, current) => (current.length > longest.length ? current : longest), '');
};
