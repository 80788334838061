import { Component, default as React } from 'react';
import { connect } from 'react-redux';

class UserInfo extends Component {
    render() {
        const verified = this.props.viewModel.User.SalesforceId ? <i className='fa fa-check text-success'></i> : '';

        const requestedFeatures = this.props.viewModel.RequestedFeatures.map((rf) => {
            const selected = rf.IsSelected ? <i className='fa fa-check text-success'></i> : '';

            return (
                <div key={rf.RequestedFeatureName} className='col-sm-12'>
                    {rf.RequestedFeatureName} {selected}
                </div>
            );
        });

        return (
            <div className='user-info card'>
                <div className='col-sm-12'>
                    <div className='row card-body'>
                        <div className='col-sm-4'>
                            <h4>
                                <strong>{this.props.translations.Phrases['UserDetails_Label']}</strong>
                            </h4>
                            <div className='text-wrap'>{this.props.viewModel.User.FullName}</div>
                            <div className='text-wrap'>{this.props.viewModel.User.CompanyName}</div>
                            <div className='text-wrap'>
                                {this.props.viewModel.User.Email} {verified}
                            </div>
                            <div>{this.props.viewModel.User.Phone}</div>
                        </div>
                        <div className='col-sm-4'>
                            <h4>
                                <strong>{this.props.translations.Phrases['ProductRequests_Label']}</strong>
                            </h4>
                            <div className='row'>{requestedFeatures}</div>
                        </div>
                        <div className='col-sm-4'>
                            <h4>
                                <strong>{this.props.translations.Phrases['RequestedClientNumbers_Label']}</strong>
                            </h4>
                            <span>{this.props.viewModel.RequestedClientNumbers.join(', ')}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(UserInfo);
