import { Component } from 'react';
import { connect } from 'react-redux';
import CheckBox from '../../../common/CheckBox/CheckBox';
import MoreDetailsIcon from '../../../common/Icons/MoreDetailsIcon';
import { global } from '../../../constants/mlp-constants';
import * as actionCreators from '../../../store/actions/index';
import { camelToTitleCase } from '../../../utils/mlp-utils';
import BatchEditDropDown from './BatchEditDropDown';

class BatchEditGridChildRow extends Component {
    updateFeature = (data, roleId, selected) => {
        this.props.onUpdateChildFeature(data.parentIndex, data.childIndex, roleId, selected);
    };

    removeChildRow = (event) => {
        this.props.onRemoveChildUser(this.props.parentIndex, this.props.childIndex);
    };

    updateChildStatus = (status) => {
        this.props.onUpdateChildStatus(this.props.parentIndex, this.props.childIndex, status);
    };

    //This method is being passed as props so there is no need to declare it
    //Keeping it here for history reasons
    // getUserDetails = (user) => {
    //     return [
    //         { label: this.props.translations.Phrases['CompanyName_Label'], value: user.CompanyName },
    //         { label: this.props.translations.Phrases['Roles_Label'], value: user.UserRoleDisplay },
    //         { label: this.props.translations.Phrases['Email_Label'], value: user.Email },
    //         { label: this.props.translations.Phrases['ExpirationDate_Label'], value: UTCtoLocalDateTime(user.ExpirationDate) }
    //     ];
    // };

    getUserStatusDisplay = (user) => {
        if (user.Status.some((x) => x.IsSelected)) {
            return (
                <td className='batch-edit-dropdown'>
                    <BatchEditDropDown
                        disabled={!user.IsEnabled}
                        options={user.Status}
                        customAction={this.updateChildStatus}
                        readOnly={false}
                    />
                </td>
            );
        }

        return (
            <td>
                <span>{camelToTitleCase(user.StatusName)}</span>
            </td>
        );
    };

    render() {
        const parentIndex = this.props.parentIndex;
        const childIndex = this.props.childIndex;
        const user = this.props.user;
        const { parentUser } = this.props;
        const isDangerIcon = !user.IsEnabled;
        const data = {
            parentIndex: parentIndex,
            childIndex: childIndex
        };

        const RolesAndPermissions = user.RolesAndPermissions.map(
            function (r, index) {
                var name = 'ParentUsers.' + parentIndex + '.InferiorUsers.' + childIndex + '.';

                let isDisabled = !r.IsEnabled || !user.IsEnabled;
                if (parentUser) {
                    if (r.Alias === global.PAY_FEATURE_CODE) {
                        if (parentUser) {
                            const parentPay = parentUser.RolesAndPermissions.find((pr) => pr.Alias === global.PAY_FEATURE_CODE);
                            if (parentPay) {
                                if (!isDisabled && parentPay.IsEnabled) {
                                    isDisabled = true;
                                }
                            }
                        }
                    }
                }

                return (
                    <td key={'roles-' + index} className='text-center'>
                        <CheckBox
                            embed
                            name={name}
                            checked={r.IsSelected}
                            disabled={isDisabled}
                            onChecked={this.updateFeature.bind(this, data, r.RoleId)}
                        />
                    </td>
                );
            }.bind(this)
        );

        var removeButton = (
            <span onClick={this.removeChildRow} className='btn btn-link'>
                <i className='text-danger fa fa-times-circle'></i>
            </span>
        );

        return (
            <tr>
                <td>
                    {user.FullName}
                    <MoreDetailsIcon isDangerIcon={isDangerIcon} items={this.props.getUserDetails(user)} />
                </td>
                {this.getUserStatusDisplay(user)}
                {RolesAndPermissions}
                <td>{removeButton}</td>
            </tr>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onUpdateChildFeature: (parentIndex, childIndex, roleId, isSelected, model) =>
            dispatch(actionCreators.updateChildFeature(parentIndex, childIndex, roleId, isSelected, model)),
        onRemoveChildUser: (parentIndex, childIndex) => dispatch(actionCreators.removeChildUser(parentIndex, childIndex)),
        onUpdateChildStatus: (parentIndex, childIndex, status) =>
            dispatch(actionCreators.updateChildStatus(parentIndex, childIndex, status))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchEditGridChildRow);
