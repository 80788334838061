import produce from 'immer';
import * as actionTypes from '../actions/user/userActionTypes';

const initialState = {
    model: {
        username: null,
        unreadNotices: false,
        hasCentralPermission: false,
        hasDCPPermission: false
    }
};

const setUserModel = (state, action) => {
    return produce(state, (draft) => {
        draft.model.username = action.model.username;
        draft.model.unreadNotices = action.model.unreadNotices;
        draft.model.hasCentralPermission = action.model.hasCentralPermission;
        draft.model.hasDCPPermission = action.model.hasDCPPermission;
    });
};

const setUserPermission = (state, action) => {
    return produce(state, (draft) => {
        draft.model.hasCentralPermission = action.model.hasCentralPermission;
    });
};

const updateUnreadNotices = (state) => {
    return produce(state, (draft) => {
        draft.model.unreadNotices = false;
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_MODEL:
            return setUserModel(state, action);
        case actionTypes.UPDATE_UNREAD_NOTICES:
            return updateUnreadNotices(state);
        case actionTypes.SET_USER_PERMISSION:
            return setUserPermission(state, action);
        default:
            return state;
    }
};

export default reducer;
