import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { formatTranslated, isVisible } from '../../utils/mlp-utils';
import DatePickerModal from '../DateTimePicker/DatePickerModal';

class DatePickerFilter extends Component {
    static defaultProps = {
        countryCodes: ['CA', 'US'],
        searchTypeCode: '*'
    };
    static propTypes = {
        countryCodes: PropTypes.arrayOf(PropTypes.string),
        searchTypeCode: PropTypes.string,
        onDateChanged: PropTypes.func,
        customAction: PropTypes.func
    };

    customFormat = (x) => {
        const filterControl = this.props.filterControl;
        const isUtc = filterControl.Attribute === '_imageDate';
        return isUtc ? dayjs(x).utc().startOf('day').toDate().toISOString() : dayjs(x).startOf('day').format('YYYY-MM-DD');
    };

    render() {
        const filterControl = this.props.filterControl;
        const filterIndex = filterControl.FilterIndex;
        if (!isVisible(filterControl, this.props.searchTypeCode)) {
            return null;
        }
        const name = 'Filters.' + filterIndex + '.RangeStart';

        filterControl.Options.forEach((e) => {
            e.IsHidden = !isVisible(e, this.props.searchTypeCode);
        });

        return (
            <div>
                <DatePickerModal
                    name={name}
                    date={filterControl.RangeStart}
                    label={formatTranslated(filterControl.Name, this.props.translations.Phrases)}
                    linkText={formatTranslated('SelectDate_Label', this.props.translations.Phrases)}
                    dateLabel=''
                    customFormat={this.customFormat}
                    onDateChanged={this.props.onDateChanged}
                    customAction={this.props.customAction}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(DatePickerFilter);
