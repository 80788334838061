import { PayloadAction } from '@reduxjs/toolkit';
import { createGenericStatusFilterSlice } from '../../../../redux/reducers/genericStatusFilterSlice';
import { StatusFilterOptions } from '../../common/constants/constants-subscribers';

const initialState: any = {
    selectedStatus: StatusFilterOptions[0]
};

const statusFilterSlice = createGenericStatusFilterSlice({
    name: 'statusFilter',
    initialState,
    reducers: {
        setSelectedStatus(state, action: PayloadAction<number>) {
            state.selectedStatus = StatusFilterOptions[action.payload];
        }
    }
});

export const { setSelectedStatus } = statusFilterSlice.actions;

export default statusFilterSlice.reducer;
