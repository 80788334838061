import { CompositeFilterDescriptor, isCompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { GridPagerSettings } from '@progress/kendo-react-grid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MOTKeys } from '../../common/models/AdvancedSearch';
import { ShipmentGridSettingsSlice } from '../../common/models/ReduxSlices';
import { ColumnFilters } from '../../common/models/ShipmentGridSettings';

let pagerSettings: GridPagerSettings = {
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [10, 20, 50],
    previousNext: true
};

const initialState: ShipmentGridSettingsSlice = {
    columnFilters: { ClearanceStatus: ['InProgress', 'OnHold', 'Completed'] },
    MOTSelectedIndexes: [],
    MilestoneSelectedIndexes: [],
    columnSortFields: [{ field: 'ModifiedOn', dir: 'desc' }],
    pageSize: 10,
    totalRecords: 0,
    pageSelected: 1,
    dataState: { take: 1, skip: 0 },
    pageable: pagerSettings
};

const shipmentGridSettingsSlice = createSlice({
    name: 'shipmentGridSettingsSlice',
    initialState,
    reducers: {
        setColumnFilters(state, action: PayloadAction<ColumnFilters>) {
            state.columnFilters = action.payload;
        },
        setMOTSelectedIndexes(state, action: PayloadAction<number[]>) {
            state.MOTSelectedIndexes = action.payload;
        },
        setMilestoneSelectedIndexes(state, action: PayloadAction<number[]>) {
            state.MilestoneSelectedIndexes = action.payload;
        },
        setMOTFilter(
            state,
            action: PayloadAction<{
                selectedMOT: MOTKeys[];
                dataStateMOTFilter: CompositeFilterDescriptor;
            }>
        ) {
            if (state.dataState.filter?.filters.length) {
                const dataStateWithoutMOT = state.dataState.filter?.filters.filter((item) => {
                    if (isCompositeFilterDescriptor(item) && !isCompositeFilterDescriptor(item.filters[0])) {
                        return item.filters[0].field !== 'ModeOfTransport';
                    } else {
                        return item;
                    }
                });
                state.dataState.filter = { logic: 'and', filters: [...dataStateWithoutMOT, action.payload.dataStateMOTFilter] };
            } else {
                state.dataState.filter = { logic: 'and', filters: [action.payload.dataStateMOTFilter] };
            }
            state.columnFilters.ModeOfTransport = action.payload.selectedMOT;
        },
        resetMOTFilter(state) {
            if (state.dataState.filter?.filters.length) {
                const dataStateWithoutMOT = state.dataState.filter?.filters.filter((item) => {
                    if (isCompositeFilterDescriptor(item) && !isCompositeFilterDescriptor(item.filters[0])) {
                        return item.filters[0].field !== 'ModeOfTransport';
                    } else {
                        return item;
                    }
                });
                state.dataState.filter = { logic: 'and', filters: [...dataStateWithoutMOT] };
            }
            state.columnFilters.ModeOfTransport = [];
        },
        setSortFields(state, action: PayloadAction<SortDescriptor[]>) {
            state.columnSortFields = action.payload;
        },
        setPageSize(state, action: PayloadAction<number>) {
            state.pageSize = action.payload;
        },
        setTotalRecords(state, action: PayloadAction<number>) {
            state.totalRecords = action.payload;
        },
        setPageSelected(state, action: PayloadAction<number>) {
            state.pageSelected = action.payload;
        },
        setDataState(state, action: PayloadAction<any>) {
            state.dataState = action.payload;
        },
        setPagerSettings(state, action: PayloadAction<any>) {
            state.pageable = action.payload;
        },
        resetShipmentGridSettings() {
            return { ...initialState };
        }
    }
});

export const {
    setColumnFilters,
    setMOTSelectedIndexes,
    setMilestoneSelectedIndexes,
    setSortFields,
    setPageSize,
    setTotalRecords,
    setPageSelected,
    setDataState,
    resetShipmentGridSettings,
    setMOTFilter,
    resetMOTFilter
} = shipmentGridSettingsSlice.actions;

export default shipmentGridSettingsSlice.reducer;
