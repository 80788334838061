import {
    GridColumnMenuFilter,
    GridColumnMenuItem,
    GridColumnMenuItemContent,
    GridColumnMenuItemGroup,
    GridColumnMenuSort
} from '@progress/kendo-react-grid';
import React, { Component } from 'react';

class CustomColumnMenu extends Component {
    state = {
        columns: this.props.columns,
        columnsExpanded: this.props.columnsExpanded ?? false,
        filterExpanded: false
    };

    onToggleColumn = (id) => {
        this.props.onToggleColumn(id);
    };

    onFilterExpandChange = (value) => {
        this.setState({
            filterExpanded: value,
            columnsExpanded: value ? false : this.state.columnsExpanded
        });
    };

    render() {
        return (
            <div>
                {this.props.column.field !== 'Status' ? <GridColumnMenuSort {...this.props} /> : null}
                <GridColumnMenuItemGroup>
                    <GridColumnMenuItem title={'Columns'} iconClass={'k-i-columns'} onClick={this.props.onMenuItemClick} />
                    <GridColumnMenuItemContent show={this.props.columnsExpanded}>
                        <div className={'k-column-list-wrapper'}>
                            <div className={'k-column-list'}>
                                {this.state.columns.map((column, index) => (
                                    <div key={index} className={'k-column-list-item'}>
                                        <span className='p-3'>
                                            <input
                                                id={`column-visiblity-show-${index}`}
                                                className='k-checkbox'
                                                type='checkbox'
                                                readOnly={true}
                                                disabled={column.field === '_entryNumber'}
                                                checked={column.show}
                                                onClick={() => this.onToggleColumn(index)}
                                            />
                                            <label htmlFor={`column-visiblity-show-${index}`} className='k-checkbox-label'>
                                                {column.title}
                                            </label>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </GridColumnMenuItemContent>
                </GridColumnMenuItemGroup>
                {this.props.column.field !== 'Status' ? (
                    <GridColumnMenuFilter
                        {...this.props}
                        onExpandChange={this.onFilterExpandChange}
                        expanded={this.state.filterExpanded}
                        hideSecondFilter={this.props.column.filter === 'text'}
                    />
                ) : null}
            </div>
        );
    }
}

export default CustomColumnMenu;
