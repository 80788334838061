import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'core-js/features/string/replace-all';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import accessManagementReducer from './store/reducers/accessManagement';
import batchCreateUsersReducer from './store/reducers/batchCreateUsers';
import batchEditUsersReducer from './store/reducers/batchEditUsers';
import brpReducer from './store/reducers/brpReports';
import clientAdminReducer from './store/reducers/clientAdmin';
import dashboardReducer from './store/reducers/dashboard';
import dashboardCopyReducer from './store/reducers/dashboardcopy';
import editInternalUser from './store/reducers/editInternalUser';
import featureSetupReducer from './store/reducers/featureSetup';
import headerReducer from './store/reducers/header';
import imagingReducer from './store/reducers/imaging';
import imagingDetailsReducer from './store/reducers/imagingDetails';
import manageUserReducer from './store/reducers/manageUser';
import menuReducer from './store/reducers/menu';
import noticeReducer from './store/reducers/notices';
import onBehalfOfReducer from './store/reducers/onBehalfOf';
import searchShipmentReducer from './store/reducers/searchShipment';
import systemAdminReducer from './store/reducers/systemAdmin';
import teamManagementReducer from './store/reducers/teamManagement';
import teamManagementDetailsReducer from './store/reducers/teamManagementDetails';
import testReducer from './store/reducers/test';
import translationsReducer from './store/reducers/translations';
import userReducer from './store/reducers/user';
import userSearchReducer from './store/reducers/userSearch';
import verifyAccessReducer from './store/reducers/verifyAccess';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    key: 'imagingDetails',
    storage: storage
};

const rootReducer = combineReducers({
    translations: translationsReducer,
    notices: noticeReducer,
    header: headerReducer,
    dashboard: dashboardReducer,
    dashboardCopy: dashboardCopyReducer,
    searchShipment: searchShipmentReducer,
    menu: menuReducer,
    user: userReducer,
    test: testReducer,
    teamManagement: teamManagementReducer,
    teamManagementDetails: teamManagementDetailsReducer,
    userSearch: userSearchReducer,
    onBehalfOf: onBehalfOfReducer,
    featureSetup: featureSetupReducer,
    accessManagement: accessManagementReducer,
    internalUser: editInternalUser,
    manageUser: manageUserReducer,
    clientAdmin: clientAdminReducer,
    verifyAccess: verifyAccessReducer,
    imaging: imagingReducer,
    imagingDetails: persistReducer(persistConfig, imagingDetailsReducer),
    brp: brpReducer,
    batchEditUsers: batchEditUsersReducer,
    systemAdmin: systemAdminReducer,
    batchCreateUsers: batchCreateUsersReducer
});

// Be sure to ONLY add this middleware in development!
const middleware = process.env.NODE_ENV !== 'production' ? [require('redux-immutable-state-invariant').default(), thunk] : [thunk];

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

const persistor = persistStore(store);

const app = (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </PersistGate>
    </Provider>
);

if (process.env.REACT_APP_MSW_ENABLED === 'TRUE') {
    const { worker } = require('./central/mocks/browser');
    worker.start();
}
ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
