import type { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { VariationPlacement } from '@popperjs/core';
import type { GridCellProps } from '@progress/kendo-react-grid';
import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import type { EllipsisData } from '../../../pages/documents/common/models/ReduxSlices';
import useDropdownToggle from '../../hooks/useDropdownToggle';
import Spinner from '../Spinner';
import DropdownWrapper from '../Wrappers/DropdownWrapper';

interface ActionButtonElipsisProps extends GridCellProps {
    options: readonly { id: string; label: string; icon: IconDefinition | null; rightIcon?: IconDefinition }[];
    dangerOption: string;
    disabled?: boolean;
    placement?: VariationPlacement;
    currentRow?: string | undefined;
    ellipsisData?: EllipsisData;
    docsCount?: number;
    onToggleActionPanel?: () => void;
    onAction?: (payload: string) => void;
    handleActionButtonForDocumentsGrid?: (entryNo: string) => void;
}

const ActionButtonElipsis: React.FC<ActionButtonElipsisProps> = ({
    options,
    dangerOption,
    onToggleActionPanel,
    onAction,
    placement = 'right-start',
    disabled,
    dataItem,
    className = '',
    style,
    currentRow,
    ellipsisData,
    docsCount,
    handleActionButtonForDocumentsGrid
}) => {
    const [referenceElement, setReferenceElement] = useState<any>(null);
    const [popperElement, setPopperElement] = useState<any>(null);
    const actionPanelRef = useRef(null);
    const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
        placement: placement,
        modifiers: [{ name: 'offset', options: { offset: [0, 2] } }]
    });
    const { isOpen, isOutside, handleToggle } = useDropdownToggle(actionPanelRef);

    useEffect(() => {
        if (update) update();
    }, [update]);

    useEffect(() => {
        if (isOutside && handleActionButtonForDocumentsGrid) {
            handleActionButtonForDocumentsGrid('');
        }
    }, [isOutside]);

    const handleOptionClick = (option: (typeof options)[number]) => {
        handleToggle();
        if (handleActionButtonForDocumentsGrid && option.id !== 'COMPARE_DOCUMENT' && option.id !== 'VIEW_DOCUMENT') {
            handleActionButtonForDocumentsGrid('');
        }

        if (onAction) onAction(option.id);
    };

    return (
        <div className={`${className} text-center`} style={{ ...style, backgroundColor: dataItem?.selected ? '#DDE9F9' : '' }}>
            <button
                className='btn btn-tertiary btn-sm'
                type='button'
                onClick={() => {
                    handleToggle();
                    if (onToggleActionPanel) onToggleActionPanel();

                    if (handleActionButtonForDocumentsGrid && ellipsisData) {
                        handleActionButtonForDocumentsGrid(ellipsisData.entryNo === currentRow ? '' : currentRow!);
                    }
                }}
                ref={setReferenceElement}
                disabled={disabled}
            >
                <FontAwesomeIcon icon={faEllipsisV} />
            </button>

            {((ellipsisData?.entryNo === currentRow && (ellipsisData?.isLoading || ellipsisData?.customsInfoID !== undefined)) ||
                isOpen) && (
                <DropdownWrapper>
                    <div
                        className='dropdown-menu dropdown-menu-right show'
                        ref={setPopperElement}
                        style={{ margin: 0, ...styles.popper }}
                        {...attributes.popper}
                    >
                        <div ref={actionPanelRef}>
                            {options.map((option, index) => {
                                const isOptionDisabled =
                                    (option.id === 'VIEW_DETAILS' && ellipsisData && !ellipsisData.customsInfoID) ||
                                    (option.id === 'COMPARE_DOCUMENT' && docsCount === 1) ||
                                    (option.id === 'CLONE_USER' &&
                                        (dataItem?.Status === 'ActiveFeatureSetupInProgress' ||
                                            dataItem?.Status === 'Deactivated' ||
                                            dataItem?.Status === 'Expired' ||
                                            dataItem?.Status === 'Cancelled'));

                                return (
                                    <div
                                        key={index}
                                        className={`dropdown-item d-flex justify-content-between align-items-stretch text-decoration-none p-0 ${
                                            option.id === dangerOption ? 'danger' : ''
                                        }`}
                                    >
                                        <button
                                            type='button'
                                            className='d-flex flex-fill align-items-center btn px-3 py-2'
                                            disabled={isOptionDisabled}
                                            onClick={handleOptionClick.bind(null, option)}
                                        >
                                            {option.icon && <FontAwesomeIcon className='icon mr-3' icon={option.icon} />}
                                            <span>{option.label}</span>
                                            {option.rightIcon && (
                                                <>
                                                    <span className='flex-fill'></span>
                                                    <FontAwesomeIcon className='icon ml-3' icon={option.rightIcon} />
                                                </>
                                            )}
                                            {ellipsisData &&
                                                (ellipsisData.entryNo ? currentRow === ellipsisData.entryNo : true) &&
                                                ellipsisData.isLoading &&
                                                option.id === 'VIEW_DETAILS' && <Spinner size='small' className='ml-2' />}
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </DropdownWrapper>
            )}
        </div>
    );
};

export default ActionButtonElipsis;
