import { faThLarge } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useLazyGetPortalSolutionsMenuQuery, useSavePortalSolutionsMenuMutation } from '../../../../redux/api/portalSolutionsMenuApi';
import { usePortalSelector } from '../../../../redux/hooks';
import { useDebounce } from '../../../hooks/useDebounce';
import useDropdownToggle from '../../../hooks/useDropdownToggle';
import useGTMEventTracking from '../../../hooks/useGTMEventTracking';
import useTranslationHydration from '../../../hooks/useTranslationHydration';
import type { SolutionMenuItem } from '../../../models/ResponseData';
import DropdownMenuButton from '../../DropdownMenuButton';
import WaffleDraggableItem from './WaffleDraggableItem';

const TIMEOUT = 3000;

const WaffleDraggableDropdown = () => {
    const { user } = usePortalSelector((state) => state.hydration);

    const translate = useTranslationHydration();

    const token = localStorage.getItem('token');

    const [getPortalSolutionsMenuQuery] = useLazyGetPortalSolutionsMenuQuery();
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState<SolutionMenuItem[]>([]);

    useEffect(() => {
        if (!token || !user?.LanguageCode) return;

        let isCurrent = true;

        setIsLoading(true);

        getPortalSolutionsMenuQuery(token)
            .unwrap()
            .then((response) => {
                if (isCurrent && response.solutionsMenuItems) {
                    if (response.isUserConfigured) {
                        setItems(response.solutionsMenuItems);
                        return;
                    }

                    const sorted = [...response.solutionsMenuItems].sort((a, b) => {
                        const nameA = translate(a.Label).toLowerCase();
                        const nameB = translate(b.Label).toLowerCase();
                        return nameA.localeCompare(nameB, user?.LanguageCode);
                    });

                    setItems(sorted);
                }
            })
            .finally(() => {
                if (isCurrent) setIsLoading(false);
            });

        return () => {
            isCurrent = false;
        };
    }, [getPortalSolutionsMenuQuery, token, translate, user?.LanguageCode]);

    const debounce = useDebounce(TIMEOUT);

    const [savePortalSolutionsMenu] = useSavePortalSolutionsMenuMutation();

    const dropdownWuffleMenuRef = useRef(null);
    const buttonRef = useRef(null);
    const { isOpen: isWaffleOpen, handleToggle: handleWaffleToggle } = useDropdownToggle(dropdownWuffleMenuRef);
    useGTMEventTracking('WaffleMenu_Main_CPEvt', { location: 'WaffleMenu' }, buttonRef);

    const handleDrop = () => {
        debounce(() => {
            savePortalSolutionsMenu({ SolutionsMenuItems: items, token, IsUserConfigured: true });
        });
    };

    const moveItem = (fromIndex: number, toIndex: number) => {
        setItems((prevItems) => {
            const reordered = [...prevItems];
            const [movedItem] = reordered.splice(fromIndex, 1);
            reordered.splice(toIndex, 0, movedItem);
            return reordered.map((item, index) => ({ ...item, Order: index + 1 }));
        });
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div style={{ display: 'flex' }}>
                <div className='d-flex align-items-center justify-center'>
                    <DropdownMenuButton
                        childrenDivClassName='dropdown waffle-menu-wrapper mx-1 pb-0'
                        id='WaffleMenuDropdown'
                        label={null}
                        isOpen={isWaffleOpen}
                        disabled={isLoading}
                        onClick={handleWaffleToggle}
                        icon={faThLarge}
                        btnClassNameSecondary='btn btn-tertiary rounded-circle image-40px position-relative mx-1'
                        ref={dropdownWuffleMenuRef}
                        buttonRef={buttonRef}
                        style={{ maxWidth: '590px' }}
                        waffleMenuItemsLength={items.length}
                    >
                        <h4 className='dropdown-header border-bottom'>{translate('PortalApps_Label')}</h4>
                        <div
                            className={`waffle-menu bg-gray-200 border-top rounded-bottom ${items.length <= 3 ? 'single-column' : 'grid'}`}
                            style={
                                items.length > 3
                                    ? {
                                          gridTemplateRows: items.length ? `repeat(${Math.ceil(items.length / 2)}, 50px)` : '',
                                          gridAutoFlow: 'column'
                                      }
                                    : undefined
                            }
                        >
                            {items.map((item: SolutionMenuItem, index: number) => (
                                <WaffleDraggableItem
                                    key={item.SolutionMenuItemId}
                                    item={{ label: item.Label, url: item.Url }}
                                    index={index}
                                    moveItem={moveItem}
                                    onItemDropped={handleDrop}
                                />
                            ))}
                        </div>
                    </DropdownMenuButton>
                </div>
            </div>
        </DndProvider>
    );
};

export default WaffleDraggableDropdown;
