import type { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ShipmentHeader } from '../../pages/clearances/common/models/ShipmentHeader';
import type { Notifications } from '../models/ResponseData';

export const getCountryFromClientNumber = (clientNumber: string) => {
    const value = parseInt(clientNumber[0]);
    return value < 4 || value === 7 ? 'ca' : 'us';
};

export const getMilestoneCount = (notifObj: Notifications) => {
    let counter = 0;
    Object.entries(notifObj).forEach(([key, value]) => {
        if (!key.includes('OnHold') && value) counter++;
    });

    return counter;
};

export const checkAllFalsy = (obj: Partial<ShipmentHeader> | null): boolean => {
    if (obj !== null) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key) && obj[key as keyof ShipmentHeader]) {
                return true;
            }
        }
        return false;
    } else {
        return false;
    }
};

export async function fetchHtmlFile(filePath: string) {
    try {
        const response = await fetch(filePath);
        const html = await response.text();

        return html;
    } catch (error) {
        throw error;
    }
}

export const generateUUID = () => `${Date.now()}-${Math.random().toString(36).slice(2, 9)}`;

export const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
    return typeof error === 'object' && error !== null && 'status' in error;
};
