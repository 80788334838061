import { GridPagerSettings } from '@progress/kendo-react-grid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompleteEntryDataGridSettingsSlice } from '../../common/models/completeEntryDataModels';

let pagerSettings: GridPagerSettings = {
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: [10, 20, 50],
    previousNext: true
};

const initialState: CompleteEntryDataGridSettingsSlice = {
    pageSize: 10,
    totalRecords: 0,
    pageSelected: 1,
    dataState: { take: 1, skip: 0 },
    pageable: pagerSettings
};

const completeEntryDataGridSettingsSlice = createSlice({
    name: 'completeEntryDataGridSettingsSlice',
    initialState,
    reducers: {
        setPageSize(state, action: PayloadAction<number>) {
            state.pageSize = action.payload;
        },
        setTotalRecords(state, action: PayloadAction<number>) {
            state.totalRecords = action.payload;
        },
        setPageSelected(state, action: PayloadAction<number>) {
            state.pageSelected = action.payload;
        },
        setDataState(state, action: PayloadAction<any>) {
            state.dataState = action.payload;
        },
        setPagerSettings(state, action: PayloadAction<any>) {
            state.pageable = action.payload;
        },
        resetShipmentGridSettings() {
            return { ...initialState };
        }
    }
});

export const { setPageSize, setTotalRecords, setPageSelected, setDataState, resetShipmentGridSettings } =
    completeEntryDataGridSettingsSlice.actions;

export default completeEntryDataGridSettingsSlice.reducer;
