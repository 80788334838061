import { useEffect, useState } from 'react';

interface Request<T> {
    isFetching: boolean;
    data: T | null;
    error: string | null;
}

export const useFetch = <T>(fetchFn: () => Promise<T>) => {
    const [{ isFetching, data, error }, setRequest] = useState<Request<T>>({
        isFetching: false,
        data: null,
        error: null
    });

    useEffect(() => {
        let isCurrent = true;

        (async function () {
            setRequest({ isFetching: true, data: null, error: null });

            try {
                const response = await fetchFn();

                if (isCurrent) {
                    setRequest((prevState) => ({ ...prevState, data: response }));
                }
            } catch (error) {
                if (isCurrent) {
                    setRequest((prevState) => ({ ...prevState, error: String(error) }));
                }
            } finally {
                if (isCurrent) {
                    setRequest((prevState) => ({ ...prevState, isFetching: false }));
                }
            }
        })();

        return () => {
            isCurrent = false;
        };
    }, [fetchFn]);

    return { isFetching, data, error };
};
