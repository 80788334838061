import { SortDescriptor } from '@progress/kendo-data-query';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../../axios-portal';
import { API_CLEARANCES_PATH } from '../../../../common/constants/constants-portal';
import { addToDownloadLobby } from '../../../../redux/reducers/alertsSlice';
import { sendToast } from '../../../../redux/reducers/toastSlice';
import { transformData } from '../../common/functions/clearancesFunctions';
import { ShipmentListData } from '../../common/models/ResponseData';
import { ColumnFilters } from '../../common/models/ShipmentGridSettings';
import { ClearancesDownloadItem } from '../../common/models/ShipmentList';
import { DOWNLOAD_CLEARANCES, GET_ALL_CLEARANCES } from './actionTypes';

export const DownloadClearancesData = createAsyncThunk(
    DOWNLOAD_CLEARANCES,
    async (
        payload: {
            UserId?: number;
            Shipments: ClearancesDownloadItem[];
            ReturnAllFields: boolean;
            CountryCode: string;
        },
        { dispatch }
    ) => {
        try {
            const response = await axios.post(`${API_CLEARANCES_PATH}/downloadClearancesData`, payload);

            const parsed = JSON.parse(response.data);

            // Check if response has download lobby
            if (parsed?.ImageUrl.includes('DownloadLobby')) {
                const match = parsed.ImageUrl.match(/packageId=(\d+)/);

                if (match) {
                    const packageId = match[1];
                    dispatch(addToDownloadLobby({ packageId: packageId, packageSource: 'SearchShipmentClearances' }));
                }
            }

            return response.data;
        } catch (error: any) {
            if (error.response.status === 500) {
                dispatch(sendToast({ message: 'DownloadLobbyErrorTitle_Label', isLabel: true, type: 'error' }));
            }

            return error.response.status;
        }
    }
);

export const GetShipmentList = createAsyncThunk(
    GET_ALL_CLEARANCES,
    async (
        payload: {
            Status: null | string;
            Country: string | null | undefined;
            ClientCaptionNumbers: string[] | undefined;
            PageSize: number;
            OrderBy: SortDescriptor[] | null;
            StartRow: number;
            ColumnFilters: ColumnFilters;
            DateRange: {
                dateRangeSelected: string;
                customDateRange:
                    | {
                          type: string;
                          date: string;
                      }[]
                    | undefined;
            };
            AdvancedSearchFilters: {
                Tags: string[];
            };
            ClearancesCreatedOnDate: string;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post<ShipmentListData>(`${API_CLEARANCES_PATH}/clearanceList`, payload);

            return transformData(response.data);
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);
