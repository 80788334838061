import { createSlice } from '@reduxjs/toolkit';
import { UserProfileSlice } from '../../common/models/ReduxSlices';
import { GetProfileData } from '../actions/profile';

const initialState: UserProfileSlice = {
    profile: null,
    isLoading: false,
    error: undefined
};

const userProfileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(GetProfileData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetProfileData.fulfilled, (state, action) => {
                state.profile = action.payload;
                state.isLoading = false;
            })
            .addCase(GetProfileData.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export default userProfileSlice.reducer;
