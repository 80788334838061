import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';
import queryString from 'query-string';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import axios, { cancelRequests } from '../../axios-mlp';
import ChooserControl from '../../common/ClientChooser/ChooserControl';
import { dashboard } from '../../constants/mlp-constants';
import * as actionCreators from '../../store/actions/index';
import { getCountryFromSelectedServices, getSelectedClientServices } from '../../utils/mlp-utils';
import DashboardArea from './DashboardArea';
import WidgetSelector from './WidgetSelector';

const d3 = require('d3');
const createReactClass = require('create-react-class');
window.createReactClass = createReactClass;
window.React = React;
window.axios = axios;
window.ReactDOM = ReactDOM;
window.d3 = d3;
window.$ = $;

class DashboardFormCopy extends Component {
    componentDidMount() {
        if (this.props.location.search) {
            let params = queryString.parse(this.props.location.search);
            if (params.key) {
                localStorage.setItem('key', params.key);
            }
        }
        const keyVal = localStorage.getItem('key');
        if (keyVal) {
            this.downloadReport(keyVal);
        } else {
            this.props.onInitDashboard();
        }
        this.props.onInitTranslations('Dashboard.Dashboard.Index');
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }

        if (this.props.model.WidgetPlacementViewModels && this.props.translations.PhraseGroupCode === 'Dashboard.Dashboard.Index') {
            $('#sortable').sortable({
                opacity: 0.6,
                items: '.ui-state-default',
                cancel: '#ShipmentByCountry_svg',
                scroll: true,
                stop: () => {
                    let items = Array.prototype.slice.call(document.getElementById('sortable').children);
                    let widgetPlacementID = 0;
                    let modelCopy = JSON.parse(JSON.stringify(this.props.model));

                    items.forEach((item, i) => {
                        widgetPlacementID = item.id;
                        modelCopy.WidgetPlacementViewModels.forEach((element) => {
                            if (element.WidgetPlacementID === widgetPlacementID) {
                                element.SortOrder = i + 1;
                            }
                        });
                    });

                    axios.post(dashboard.DASHBOARD_SAVEWIDGETSORTORDER_URL, modelCopy);
                }
            });
        }
    }

    componentWillUnmount() {
        this.props.onResetStateDashboard();
        this.props.onUpdateClientService([]);
        cancelRequests();
    }

    downloadReport = (keyValue) => {
        $('#overlay').show();
        let params = new URLSearchParams();
        params.append('key', keyValue);
        axios
            .post('/Dashboard/DownloadReport', null, { params: params })
            .then((response) => {
                const fileName = keyValue + '.xlsx';
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style.cssText = 'display: none';
                a.download = fileName;
                if (window.navigator.msSaveOrOpenBlob) {
                    //IE support
                    const fileData = [response.data];
                    const blobObject = new Blob(fileData);
                    $(a).on(() => {
                        window.navigator.msSaveOrOpenBlob(blobObject, fileName);
                    });
                    a.click();
                } else {
                    const blob = this.b64toBlob(response.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'),
                        url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
                if (a.remove) {
                    a.remove();
                }
                localStorage.removeItem('key');
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    this.props.history.push('/BasicError');
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };

    b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    updateSelectedServices = (data) => {
        this.props.onUpdateClientService(data);
    };

    updateSelectedWidgets = (data) => {
        this.props.onUpdateSelectedWidgets(data);
    };

    getSelectedServices = (clientServiceSelectionModel) => {
        let selectedClientServices = [];

        if (clientServiceSelectionModel) {
            selectedClientServices = getSelectedClientServices(clientServiceSelectionModel);
        }

        const clientServices = selectedClientServices.map((item) => {
            return item.FullNumber;
        });

        if (window.widgetTotalShipments && window.widgetTotalShipments.loadWidgetData) {
            window.widgetTotalShipments.loadWidgetData(clientServices);
        }

        if (window.widgetShipmentsByMOT && window.widgetShipmentsByMOT.loadWidgetData) {
            window.widgetShipmentsByMOT.loadWidgetData(clientServices);
        }

        if (window.widgetShipmentsInProgress && window.widgetShipmentsInProgress.loadWidgetData) {
            window.widgetShipmentsInProgress.loadWidgetData(clientServices);
        }

        if (window.widgetVendorsByShipmentValue && window.widgetVendorsByShipmentValue.loadWidgetData) {
            window.widgetVendorsByShipmentValue.loadWidgetData(clientServices);
        }

        if (window.widgetTopCustomsOffices && window.widgetTopCustomsOffices.loadWidgetData) {
            window.widgetTopCustomsOffices.loadWidgetData(clientServices);
        }

        if (window.widgetMapOfShipments && window.widgetMapOfShipments.loadWidgetData) {
            window.widgetMapOfShipments.loadWidgetData(clientServices);
        }

        if (window.widgetDutyPaid && window.widgetDutyPaid.loadWidgetData) {
            window.widgetDutyPaid.loadWidgetData(clientServices);
        }

        if (window.widgetTopHS && window.widgetTopHS.loadWidgetData) {
            window.widgetTopHS.loadWidgetData(clientServices);
        }

        if (window.widgetTopPartsByValue && window.widgetTopPartsByValue.loadWidgetData) {
            window.widgetTopPartsByValue.loadWidgetData(clientServices);
        }

        if (window.widgetFDAStatus && window.widgetFDAStatus.loadWidgetData) {
            window.widgetFDAStatus.loadWidgetData(clientServices);
        }

        return selectedClientServices;
    };

    createDashboardArea = () => {
        const clientNumberValues = this.getSelectedServices(this.props.model.ClientServiceSelection);
        const country = getCountryFromSelectedServices(this.props.model.ClientServiceSelection.Countries);

        $('[id^="WIDGETSCRIPT_"]').remove();

        this.props.model.WidgetPlacementViewModels.forEach((model, iterator) => {
            let script = document.createElement('script');
            script.setAttribute('id', 'WIDGETSCRIPT_' + model.Widget.Id);
            script.src = model.Widget.ScriptLocation.replace('/mlp-widgets-v2/', '/mlp-widgets-refactor/');
            //Above line specifies s3 location. New widget development is stored in lii-mlp/dev/mlp-widgets-refactor/widget-name.js
            script.type = 'text/javascript';
            document.getElementsByTagName('body')[0].appendChild(script);
        });

        const countryCode = country ? country.Code : '';
        const hasCountryLayout = this.props.model.WidgetPlacementViewModels.some((item) => {
            return item.CountryCode === countryCode;
        });

        const countryCodeValue = hasCountryLayout ? countryCode : '';

        let filteredWidgets = this.props.model.WidgetPlacementViewModels.filter((item) => {
            return item.CountryCode === countryCodeValue && item.Visible === true;
        });

        filteredWidgets.sort((a, b) => a.SortOrder - b.SortOrder);
        let dashboardArea = <div></div>;
        if (this.props.model.ClientServiceSelection !== undefined) {
            if (!$.isEmptyObject(this.props.model.User)) {
                dashboardArea = (
                    <DashboardArea
                        clientNumbers={clientNumberValues}
                        widgets={filteredWidgets}
                        user={this.props.model.User}
                        reportHost={this.props.model.ReportHost}
                        reportPrefix={this.props.model.ReportPrefix}
                    />
                );
            }
        }
        return dashboardArea;
    };

    render() {
        if (
            this.props.model.WidgetPlacementViewModels &&
            this.props.model.ClientServiceSelection.Countries &&
            this.props.translations.PhraseGroupCode === 'Dashboard.Dashboard.Index'
        ) {
            const dashboardArea = this.createDashboardArea();

            let countryCode = '';
            if (this.props.model.ClientServiceSelection.Countries) {
                const country = getCountryFromSelectedServices(this.props.model.ClientServiceSelection.Countries);
                countryCode = country ? country.Code : '';
            }

            return (
                <div className='page-wrapper'>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.props.translations.Phrases['Page_Title']}</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className='page-top-row'>
                        <div className='page-header-section'>
                            <nav className='mlp-breadcrumb'>
                                <span className='breadcrumb-parent'>{this.props.translations.Phrases['Dashboard_NavLevel1']}</span>
                            </nav>
                        </div>
                        <div>
                            <div className='header-align'>
                                <h1 className='pull-left bold-title'>{this.props.translations.Phrases['Dashboard_Label']}</h1>
                            </div>
                            <div className='header-align'>
                                <div className='pull-right'>
                                    <ChooserControl
                                        data={this.props.model.ClientServiceSelection}
                                        groupingAttribute='Number'
                                        onConfirm={this.updateSelectedServices}
                                    />
                                </div>
                                <div className='pull-right'>
                                    {
                                        <WidgetSelector
                                            data={this.props.model.WidgetPlacementViewModels}
                                            onConfirm={this.updateSelectedWidgets}
                                            countryCode={countryCode}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='content-wrapper'>
                        <div className='row page-content'>
                            <div className='dashboard-area w-100'>{dashboardArea}</div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div className='page-wrapper'></div>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        model: state.dashboardCopy.model,
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitDashboard: () => dispatch(actionCreators.initDashboardCopy()),
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup)),
        onUpdateClientService: (data) => dispatch(actionCreators.updateDashboardClientServiceCopy(data)),
        onUpdateSelectedWidgets: (data) => dispatch(actionCreators.updateDashboardWidgetsCopy(data)),
        onUpdateWidgetSortOrder: (data) => dispatch(actionCreators.updateWidgetSortOrderCopy(data)),
        onResetStateDashboard: () => dispatch(actionCreators.resetStateDashboardCopy())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardFormCopy));
