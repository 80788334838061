import produce, { applyPatches, enablePatches } from 'immer';
import { global as GlobalConstants, userStatus } from '../../constants/mlp-constants';
import * as actionTypes from '../actions/systemAdmin/batchEditUsersActionTypes';

const initialState = {
    model: null,
    showUploadPage: false
};
const isMlpRole = (role) => {
    return role.Alias === GlobalConstants.MLP_FEATURE_CODE;
};

const afterGetBatchJob = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.payload.model;
    });
};

const undoBatchJob = (state, action) => {
    enablePatches();
    let inverseChanges = [];
    return produce(state, (draft) => {
        state = applyPatches(state, inverseChanges);
    });
};

const updateChildFeature = (state, action) => {
    return produce(state, (draft) => {
        const user = draft.model.BatchEditModel.ParentUsers[action.payload.parentIndex].InferiorUsers[action.payload.childIndex];
        user.RolesAndPermissions.find((f) => f.RoleId === action.payload.roleId).IsSelected = action.payload.isSelected;
        if (action.payload.isSelected) {
            user.RolesAndPermissions.find(isMlpRole).IsSelected = true;
        }
    });
};

const removeChildUser = (state, action) => {
    return produce(state, (draft) => {
        draft.model.BatchEditModel.ParentUsers[action.payload.parentIndex].InferiorUsers.splice(action.payload.childIndex, 1);
    });
};
const updateChildStatus = (state, action) => {
    return produce(state, (draft) => {
        draft.model.BatchEditModel.ParentUsers[action.payload.parentIndex].InferiorUsers[action.payload.childIndex].Status.forEach((s) => {
            s.IsSelected = s.Value === action.payload.status;
        });
    });
};

const updateParentFeature = (state, action) => {
    return produce(state, (draft) => {
        const parent = draft.model.BatchEditModel.ParentUsers[action.payload.parentIndex];
        parent.RolesAndPermissions.find((r) => r.RoleId === action.payload.roleId).IsSelected = action.payload.isSelected;

        if (action.payload.isSelected) {
            parent.RolesAndPermissions.find((r) => isMlpRole(r)).IsSelected = action.payload.isSelected;
        }

        // auto-select mlp feature
        if (parent.InferiorUsers) {
            if (action.payload.isSelected) {
                //enable feature access to inferiour users
                parent.InferiorUsers.forEach((u) => {
                    u.RolesAndPermissions.find((r) => r.RoleId === action.payload.roleId).IsEnabled = true;
                    u.RolesAndPermissions.find((r) => isMlpRole(r)).IsEnabled = true;
                });
            } else {
                // revoke feature access to inferiour users
                parent.InferiorUsers.forEach((u) => {
                    let matchingRole = u.RolesAndPermissions.find((f) => f.RoleId === action.payload.roleId);
                    matchingRole.IsEnabled = false;
                    matchingRole.IsSelected = false;
                });
            }
        }
    });
};

const removeParentUser = (state, action) => {
    return produce(state, (draft) => {
        draft.model.BatchEditModel.ParentUsers.splice(action.payload.parentIndex, 1);
    });
};

const updateParentStatus = (state, action) => {
    return produce(state, (draft) => {
        let parent = draft.model.BatchEditModel.ParentUsers[action.payload.parentIndex];
        parent.Status.forEach((ps) => {
            ps.IsSelected = ps.Value === action.payload.status;
        });
        if (parent.InferiorUsers) {
            autoSelectChildStatuses(parent.InferiorUsers, action.payload.status);
        }
    });
};

const autoSelectChildStatuses = (inferiorUsers, status) => {
    if (status === userStatus.ACTIVE) {
        inferiorUsers.forEach((ch) => {
            ch.Status.forEach((s) => {
                s.IsSelected = s.Value === userStatus.ACTIVE;
            });
        });
    } /* else {
        inferiorUsers.forEach((ch) => {
            let currStatus = ch.Status.find((s) => s.IsSelected === true);
            var newStatus = currStatus.Value === userStatus.ACTIVE ? userStatus.HOLD : currStatus.Value;
            ch.Status.forEach((s) => {
                s.IsSelected = s.Value === newStatus;
            });
        });
    } */
};

const setChildStatus = (state, action) => {
    return produce(state, (draft) => {
        draft.model.BatchEditModel.ParentUsers[action.payload.parentIndex].InferiorUsers[action.payload.childIndex].Status.forEach((s) => {
            s.IsSelected = s.Value === action.payload.selectedStatus;
        });
    });
};

const afterSubmitBatchEdit = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.payload.model;
    });
};
const toggleRole = (state, action) => {
    return produce(state, (draft) => {
        const val = action.payload.selected;
        const roleId = action.payload.roleId;

        draft.model.BatchEditModel.Roles.find((r) => r.RoleId === roleId).IsSelected = val;

        draft.model.BatchEditModel.ParentUsers.forEach((pu) => {
            let parentRole = pu.RolesAndPermissions.find((r) => r.RoleId === roleId && r.IsEnabled && pu.IsEnabled);
            if (parentRole) {
                parentRole.IsSelected = val;
            }
            if (pu.InferiorUsers) {
                pu.InferiorUsers.forEach((u) => {
                    let childRole = u.RolesAndPermissions.find((r) => r.RoleId === roleId && u.IsEnabled);
                    if (childRole !== undefined) {
                        childRole.IsEnabled = val;
                        childRole.IsSelected = val;
                    }
                });
            }
        });
    });
};

const updateAllStatuses = (state, action) => {
    return produce(state, (draft) => {
        draft.model.BatchEditModel.Statuses.forEach((r) => {
            r.IsSelected = r.Value === action.payload.status;
        });
        draft.model.BatchEditModel.ParentUsers.forEach((pu) => {
            if (pu.IsEnabled) {
                pu.Status.forEach((ps) => {
                    ps.IsSelected = ps.Value === action.payload.status;
                });

                if (pu.InferiorUsers) {
                    autoSelectChildStatuses(pu.InferiorUsers, action.payload.status);
                }
            }
        });
    });
};

const clearStateBatchJobEdit = (state) => {
    return produce(state, (draft) => {
        draft.model = null;
        draft.showUploadPage = false;
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AFTER_GET_BATCH_JOB:
            return afterGetBatchJob(state, action);
        case actionTypes.UNDO_BATCH_JOB:
            return undoBatchJob(state, action);
        case actionTypes.UPDATE_CHILD_FEATURE:
            return updateChildFeature(state, action);
        case actionTypes.TOGGLE_ROLE:
            return toggleRole(state, action);
        case actionTypes.UPDATE_ALL_STATUSES:
            return updateAllStatuses(state, action);
        case actionTypes.REMOVE_PARENT_USER:
            return removeParentUser(state, action);
        case actionTypes.REMOVE_CHILD_USER:
            return removeChildUser(state, action);
        case actionTypes.UPDATE_PARENT_STATUS:
            return updateParentStatus(state, action);
        case actionTypes.UPDATE_CHILD_STATUS:
            return updateChildStatus(state, action);
        case actionTypes.UPDATE_PARENT_FEATURE:
            return updateParentFeature(state, action);
        case actionTypes.SET_CHILD_STATUS:
            return setChildStatus(state, action);
        case actionTypes.AFTER_SUBMIT_BATCH_JOB_EDIT:
            return afterSubmitBatchEdit(state, action);
        case actionTypes.CLEAR_STATE:
            return clearStateBatchJobEdit(state);
        default:
            return state;
    }
};

export default reducer;
