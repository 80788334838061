import { faBan, faInfo, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import Badge from '../../../../../common/features/Badge';
import FormDatePicker from '../../../../../common/features/FormDatePicker';
import FormInputField from '../../../../../common/features/FormInputField';
import Select from '../../../../../common/features/Select';
import Spinner from '../../../../../common/features/Spinner';
import useToast from '../../../../../common/features/Toast/useToast';
import Tooltip from '../../../../../common/features/Tooltip/Tooltip';
import { dateFormatValidator, dateIsAfterValidator, inputHasValueValidator } from '../../../../../common/functions/validators';
import useInputValidation from '../../../../../common/hooks/useInputValidation';
import useMaskedInputValidation from '../../../../../common/hooks/useMaskedInputValidation';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { TEAM_MEMBER_STATUS_LABELS, TEAM_SEARCH_PARAM_KEYS } from '../../../common/constants/constants-team';
import type { EditTeamMemberData, TeamMemberData } from '../../../common/models';
import { useCancelTeamMemberMutation, useReactivateTeamMemberMutation } from '../../../redux/api/teamApi';
import { useTeamSelector } from '../../../redux/hooks';
import ActiveModal from './ActiveModal';

interface UserDetailsEditableProps {
    data: TeamMemberData;
}

export type UserDetailsEditableActions = {
    getData: () => EditTeamMemberData;
    validate: () => boolean;
};

const UserDetailsEditable = forwardRef<UserDetailsEditableActions, UserDetailsEditableProps>(function UserDetailsEditable({ data }, ref) {
    const { user } = useTeamSelector((state) => state.hydration);

    const [searchParams] = useSearchParams();
    const userId = parseInt(searchParams.get(TEAM_SEARCH_PARAM_KEYS.userId) || '');

    const translate = useTranslation();

    const teamMember = data?.Users?.[0];
    const email = teamMember?.Email || '';

    const [selectedRole, setSelectedRole] = useState(teamMember?.Roles?.find((item) => item?.IsSelected)?.Text || '');

    const roles = useMemo(() => {
        return data?.Roles?.map((item) => {
            if (item?.Text === selectedRole) {
                return { ...item, IsSelected: true };
            } else {
                return { ...item, IsSelected: false };
            }
        });
    }, [data?.Roles, selectedRole]);
    const allRoles = useMemo(() => {
        if (data?.Roles) {
            return data?.Roles?.map((item) => {
                return { value: item?.Text!, label: '' };
            });
        } else {
            return [{ value: '', label: '' }];
        }
    }, [data?.Roles]);

    const {
        value: firstName,
        handleChange: firstNameChangeHandler,
        handleBlur: firstNameBlurHandler,
        isValid: firstNameIsValid,
        hasError: firstNameHasError
    } = useInputValidation({ initial: teamMember?.FirstName, validators: [inputHasValueValidator] });
    const {
        value: lastName,
        handleChange: lastNameChangeHandler,
        handleBlur: lastNameBlurHandler,
        isValid: lastNameIsValid,
        hasError: lastNameHasError
    } = useInputValidation({ initial: teamMember?.LastName, validators: [inputHasValueValidator] });
    const {
        value: company,
        handleChange: companyChangeHandler,
        handleBlur: companyBlurHandler,
        isValid: companyIsValid,
        hasError: companyHasError
    } = useInputValidation({ initial: teamMember?.CompanyName, validators: [inputHasValueValidator] });
    const {
        value: phoneNumber,
        handleChange: phoneNumberChangeHandler,
        handleBlur: phoneNumberBlurHandler,
        hasError: phoneNumberHasError,
        isValid: phoneNumberIsValid,
        errorIndex: phoneNumberErrorIndex
    } = useMaskedInputValidation({
        initial: teamMember?.Phone,
        validators: [inputHasValueValidator, (value: string) => isValidPhoneNumber(value, 'US')],
        inputMask: '(###) ###-#### x######'
    });
    const {
        value: mobileNumber,
        handleChange: mobileNumberChangeHandler,
        handleBlur: mobileNumberBlurHandler,
        hasError: mobileNumberHasError,
        isValid: mobileNumberIsValid
    } = useMaskedInputValidation({
        initial: teamMember?.MobileNumber,
        validators: [(value: string) => isValidPhoneNumber(value, 'US')],
        required: false,
        inputMask: '(###) ###-####'
    });
    const {
        value: date,
        handleChange: dateChangeHandler,
        handleBlur: dateBlurHandler,
        handlePicker: datePickerHandler,
        hasError: dateHasError,
        isValid: dateIsValid
    } = useInputValidation({
        initial: teamMember?.ExpirationDate ? dayjs(teamMember?.ExpirationDate).format('MM-DD-YYYY') : '',
        validators: [dateFormatValidator, dateIsAfterValidator],
        required: false
    });

    useImperativeHandle(ref, () => {
        return {
            getData() {
                const { ParentServices, ...rest } = data;

                return {
                    ...rest,
                    Users: [
                        {
                            FirstName: firstName.trim(),
                            LastName: lastName.trim(),
                            Email: email.trim(),
                            CompanyName: company.trim(),
                            Phone: phoneNumber.trim(),
                            MobileNumber: mobileNumber.trim(),
                            UserRole: selectedRole,
                            Roles: roles,
                            ExpirationDate: date.trim()
                        }
                    ]
                };
            },
            validate() {
                return Boolean(
                    firstNameIsValid && lastNameIsValid && companyIsValid && !phoneNumberHasError && !mobileNumberHasError && !dateHasError
                );
            }
        };
    });

    const toast = useToast();

    const [reactivateTeamMember, { isLoading: reactivateTeamMemberIsLoading }] = useReactivateTeamMemberMutation();
    const [cancelTeamMember, { isLoading: cancelTeamMemberIsLoading }] = useCancelTeamMemberMutation();

    const isStatusLoading = reactivateTeamMemberIsLoading || cancelTeamMemberIsLoading;

    const changeTeamMemberStatus = async (userId: number) => {
        try {
            if (teamMember?.IsActive) {
                await cancelTeamMember(userId.toString());
            } else {
                await reactivateTeamMember(userId.toString());
            }
        } catch (error) {
            toast.send({ type: 'error', message: translate('SomethingWentWrong_Label') });
        }
    };

    const [activeModalIsVisible, setActiveModalIsVisible] = useState(false);

    let statusContent;

    if (teamMember?.IsActive) {
        statusContent = (
            <>
                <Badge type='success'>
                    {teamMember.Status === 'ActiveFeatureSetupInProgress' ? (
                        <Tooltip label={translate('ClickForMoreInformation_Label')}>
                            <button
                                type='button'
                                className='btn p-0 rounded-lg d-flex align-items-center'
                                style={{ fontSize: 'inherit', color: 'inherit' }}
                                onClick={() => setActiveModalIsVisible(true)}
                            >
                                {translate(TEAM_MEMBER_STATUS_LABELS[teamMember.Status])}
                                <FontAwesomeIcon className='mx-2' icon={faInfo} />
                            </button>
                        </Tooltip>
                    ) : (
                        teamMember.Status && translate(TEAM_MEMBER_STATUS_LABELS[teamMember.Status])
                    )}
                </Badge>
                {user?.Roles !== undefined &&
                    (user.Roles.includes('TeamManagement_Edit') || user.Roles.includes('TeamManagement_Create')) && (
                        <button
                            type='button'
                            className='btn btn-outline-danger btn-sm ml-2'
                            onClick={changeTeamMemberStatus.bind(null, userId)}
                            disabled={isStatusLoading}
                        >
                            {isStatusLoading ? (
                                <Spinner size='small' color='inherit' className='mr-1' />
                            ) : (
                                <FontAwesomeIcon icon={faBan} className='mr-1' />
                            )}
                            {translate('DeactivateUser_Label')}
                        </button>
                    )}
            </>
        );
    } else {
        statusContent = (
            <>
                <Badge type='danger'>{teamMember?.Status && translate(TEAM_MEMBER_STATUS_LABELS[teamMember.Status])}</Badge>
                {user?.Roles !== undefined &&
                    (user.Roles.includes('TeamManagement_Edit') || user.Roles.includes('TeamManagement_Create')) && (
                        <button
                            type='button'
                            className='btn btn-outline-success btn-sm ml-2'
                            onClick={changeTeamMemberStatus.bind(null, userId)}
                            disabled={isStatusLoading}
                        >
                            {isStatusLoading ? (
                                <Spinner size='small' color='inherit' className='mr-1' />
                            ) : (
                                <FontAwesomeIcon icon={faSyncAlt} className='mr-1' />
                            )}
                            {translate('ReactivateUser_Label')}
                        </button>
                    )}
            </>
        );
    }

    return (
        <div className='container-fluid p-0'>
            <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3'>
                <div className='col order-md-1'>
                    <div className='form-group'>
                        <FormInputField
                            label={translate('FirstName_Label')}
                            value={firstName}
                            onChange={firstNameChangeHandler}
                            onBlur={firstNameBlurHandler}
                            isValid={firstNameIsValid}
                            hasError={firstNameHasError}
                        />
                        {firstNameHasError && <div className='error-message'>{translate('RequiredFirstName_Label')}</div>}
                    </div>
                </div>
                <div className='col order-md-2'>
                    <div className='form-group'>
                        <FormInputField
                            label={translate('LastName_Label')}
                            value={lastName}
                            onChange={lastNameChangeHandler}
                            onBlur={lastNameBlurHandler}
                            isValid={lastNameIsValid}
                            hasError={lastNameHasError}
                        />
                        {lastNameHasError && <div className='error-message'>{translate('RequiredLastName_Label')}</div>}
                    </div>
                </div>
                <div className='col order-md-3'>
                    <div className='form-group'>
                        <label>{translate('Status_Label')}</label>
                        <div className='d-flex align-items-center'>{statusContent}</div>
                    </div>
                </div>
                <div className='col order-md-4'>
                    <div className='form-group'>
                        <label>{translate('Email_Label')}</label>
                        <p>{email}</p>
                    </div>
                </div>
                <div className='col order-md-5'>
                    <div className='form-group'>
                        <FormInputField
                            label={translate('PhoneNumber_Label')}
                            value={phoneNumber}
                            onChange={phoneNumberChangeHandler}
                            onBlur={phoneNumberBlurHandler}
                            isValid={phoneNumberIsValid}
                            hasError={phoneNumberHasError}
                        />
                        {phoneNumberHasError && phoneNumberErrorIndex === 0 && (
                            <div className='error-message'>{translate('RequiredPhoneNumber_Label')}</div>
                        )}
                        {phoneNumberHasError && phoneNumberErrorIndex === 1 && (
                            <div className='error-message'>{translate('InvalidPhoneNumber_Label')}</div>
                        )}
                    </div>
                </div>
                <div className='col order-md-6'>
                    <div className='form-group'>
                        <FormInputField
                            label={translate('MobileNumber_Label')}
                            value={mobileNumber}
                            onChange={mobileNumberChangeHandler}
                            onBlur={mobileNumberBlurHandler}
                            isValid={mobileNumberIsValid}
                            hasError={mobileNumberHasError}
                        />
                        {mobileNumberHasError && <div className='error-message'>{translate('InvalidMobileNumber_Label')}</div>}
                    </div>
                </div>
                <div className='col order-md-7'>
                    <div className='form-group'>
                        <FormInputField
                            label={translate('Company_Label')}
                            value={company}
                            onChange={companyChangeHandler}
                            onBlur={companyBlurHandler}
                            isValid={companyIsValid}
                            hasError={companyHasError}
                        />
                        {companyHasError && <div className='error-message'>{translate('RequiredCompany_Label')}</div>}
                    </div>
                </div>
                <div className='col order-md-8'>
                    <Select label={translate('UserRole_Label')} options={allRoles} value={selectedRole} onChange={setSelectedRole} />
                </div>
                <div className='col order-md-9'>
                    <div className='form-group'>
                        <FormDatePicker
                            label={translate('ExpirationDate_Label')}
                            noBorder
                            noHeader
                            value={date}
                            onChange={dateChangeHandler}
                            onDateChange={datePickerHandler}
                            onBlur={dateBlurHandler}
                            dateFormat='MM-DD-YYYY'
                            hasError={dateHasError}
                            isValid={dateIsValid}
                        />
                        {dateHasError && <div className='error-message'>{translate('InvalidDate_Label')}</div>}
                    </div>
                </div>
            </div>
            <ActiveModal isOpen={activeModalIsVisible} setIsOpen={setActiveModalIsVisible} />
        </div>
    );
});

export default UserDetailsEditable;
