import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useSearchParams() {
    const { search } = useLocation();
    const history = useHistory();

    const searchParams = useMemo(() => new URLSearchParams(search), [search]);

    const setSearchParams = useCallback(
        (params: URLSearchParams | ((prevParams: URLSearchParams) => URLSearchParams)) => {
            const newSearchParams = new URLSearchParams(searchParams);

            const updateParams = typeof params === 'function' ? params(searchParams) : params;

            Object.entries(updateParams).forEach(([key, value]) => {
                if (value === null || value === undefined) {
                    newSearchParams.delete(key);
                } else {
                    newSearchParams.set(key, value);
                }
            });

            history.push({
                search: newSearchParams.toString()
            });
        },
        [history, searchParams]
    );

    return [searchParams, setSearchParams] as const;
}
