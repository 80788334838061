import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { MILESTONES_CA, MILESTONES_US } from '../../../../../common/constants/constants-portal';
import Checkbox from '../../../../../common/features/Checkbox';
import NotificationsCheckboxes from '../../../../../common/features/NotificationsCheckboxes';
import { getCountryFromClientNumber } from '../../../../../common/functions/utils';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { Notifications } from '../../../../../common/models/ResponseData';
import { MultipleUnsubscribe, UpdateGlobalSubscriptions } from '../../../redux/actions/editSubscribers';
import { useSubscribersDispatch, useSubscribersSelector } from '../../../redux/hooks';
import { setCustomerNumbers } from '../../../redux/reducers/editSubscriptiosSubscribersSlice';

interface MultiSelectedModalProps {
    email: string | null;
    phone: string | null;
    selectedClientsNumber: number | null | undefined;
    setModal: (value: boolean) => void;
    notificationsCA: any;
    notificationsUS: any;
    setNotificationsUS: (value: any) => void;
    setNotificationsCA: (value: any) => void;
    setLoadingUpdatedGrid: (value: boolean) => void;
    setGridSubscriptions: (value: any) => void;
    selectedSubscriptions: any;
    gridSubscriptions: any;
}

export const MultiSelectModal: React.FC<MultiSelectedModalProps> = ({
    email,
    phone,
    selectedClientsNumber,
    setModal,
    setNotificationsUS,
    setNotificationsCA,
    notificationsCA,
    notificationsUS,
    selectedSubscriptions,
    setLoadingUpdatedGrid,
    setGridSubscriptions,
    gridSubscriptions
}) => {
    const { unsubscribe, editSubscriber } = useSubscribersSelector((state) => state.editSubscriptionsSubscriber);
    const CustomerNumbersArray = selectedSubscriptions.map((customerNumber: any) => customerNumber.CustomerNumber);
    const [isEmailChecked, setIsEmailChecked] = useState(false);
    const [isPhoneChecked, setIsPhoneChecked] = useState(false);
    const dispatch = useSubscribersDispatch();
    const translate = useTranslation();

    useEffect(() => {
        dispatch(setCustomerNumbers(CustomerNumbersArray));
    }, [dispatch]);

    useEffect(() => {
        setNotificationsCA(MILESTONES_CA);
        setNotificationsUS(MILESTONES_US);
    }, [setNotificationsCA, setNotificationsUS]);

    const getReqNotifications = (notifications: any[]): Notifications[] => {
        return Object.keys(notifications).map((key: any) => ({
            Name: key,
            Value: notifications[key]
        }));
    };

    const checkNotifications = [...getReqNotifications(notificationsCA), ...getReqNotifications(notificationsUS)].every(
        (item) => !item.Value
    );

    const hasCountry = useCallback(
        (c: 'ca' | 'us') => {
            if (!selectedSubscriptions) return false;
            return (
                CustomerNumbersArray.filter((number: string) => {
                    const country = getCountryFromClientNumber(number);
                    return country === c;
                }).length > 0
            );
        },
        [selectedSubscriptions, CustomerNumbersArray]
    );

    const handleSubmit = async () => {
        setLoadingUpdatedGrid(false);

        if (gridSubscriptions) {
            setGridSubscriptions(
                gridSubscriptions.map((item: any) => ({
                    ...item,
                    isNew: false
                }))
            );
        }

        await setModal(false);

        let subscriptionIdCustomerNumbers: any[] = [];
        selectedSubscriptions.forEach((item: any) => {
            subscriptionIdCustomerNumbers.push({
                SubscriptionId: item.SubscriptionId,
                CustomerNumber: item.CustomerNumber,
                DocID: item.DocID
            });
        });

        await dispatch(
            UpdateGlobalSubscriptions({
                requestModel: {
                    SubscriptionIdCustomerNumberDyads: subscriptionIdCustomerNumbers,
                    Language: editSubscriber.language,
                    FullName: editSubscriber.fullName,
                    Role: editSubscriber.role,
                    UserId: selectedSubscriptions[0].UserId,
                    Email: isEmailChecked ? email : null,
                    Phone: isPhoneChecked ? phone : null,
                    MilestoneNotifications_CA: getReqNotifications(notificationsCA),
                    MilestoneNotifications_US: getReqNotifications(notificationsUS)
                }
            })
        );

        if (unsubscribe.removedSubscribersAmount) {
            dispatch(MultipleUnsubscribe({ subscriptionIds: [] }));
        }

        setLoadingUpdatedGrid(true);
    };

    const updateNotificationsUS = (key: string, value: boolean) => {
        setNotificationsUS({ ...notificationsUS, [key]: value });
    };
    const updateNotificationsCA = (key: string, value: boolean) => {
        setNotificationsCA({ ...notificationsCA, [key]: value });
    };

    const handleCheckbox = (key: string, value: boolean) => {
        key === 'email' ? setIsEmailChecked(value) : setIsPhoneChecked(value);
    };

    return (
        <>
            {selectedSubscriptions && (
                <div className='modal-dialog  modal-dialog-centered modal-dialog-scrollable manage-notifications'>
                    <div className='modal-content'>
                        {/* Heading */}
                        <div className='modal-header'>
                            <h3 className='modal-title' style={{ fontWeight: 'bold' }}>
                                {translate('ManageNotificationsSelectedClients_Label', selectedClientsNumber)}
                            </h3>
                            <button type='button' className='modal-close btn btn-tertiary-blue btn-lg' onClick={() => setModal(false)}>
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </div>
                        {/* Body */}
                        <>
                            <div className='modal-body d-flex flex-column align-items-start'>
                                <div id='scrollTable' style={{ width: '100%' }}>
                                    <div className='text-secondary mb-4' style={{ fontWeight: 'bold' }}>
                                        {translate('SelectMilestonesNotifiedOn_Label')}
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                        {hasCountry('us') && (
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                <NotificationsCheckboxes
                                                    handleChange={updateNotificationsUS}
                                                    setNotificationSettings={setNotificationsUS}
                                                    notificationSettings={notificationsUS}
                                                    country={[{ id: 'US' }]}
                                                    addSubscriberModal={false}
                                                    onHoldTitle={translate('ClearancesOnHoldTitle_Label')}
                                                    onHoldLabel={translate('OnHold_Label')}
                                                />
                                            </div>
                                        )}
                                        {hasCountry('ca') && (
                                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                <NotificationsCheckboxes
                                                    handleChange={updateNotificationsCA}
                                                    setNotificationSettings={setNotificationsCA}
                                                    notificationSettings={notificationsCA}
                                                    country={[{ id: 'CA' }]}
                                                    addSubscriberModal={false}
                                                    onHoldTitle={translate('ClearancesOnHoldTitle_Label')}
                                                    onHoldLabel={translate('OnHold_Label')}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className='mr-4'>
                                        <p style={{ fontWeight: 'bold' }}>{translate('HowReceiveNotifications_Label')}</p>
                                        <form className='checkboxes'>
                                            {email && (
                                                <div className='custom-control custom-checkbox mb-1'>
                                                    <Checkbox
                                                        id={'email'}
                                                        name={'Email'}
                                                        inputStyle={{ width: '1.25rem', height: '1.32813rem' }}
                                                        divStyle={{ paddingBottom: '1vh' }}
                                                        onChange={() => handleCheckbox('email', !isEmailChecked)}
                                                        isChecked={isEmailChecked}
                                                    >
                                                        {email}
                                                    </Checkbox>
                                                </div>
                                            )}
                                            {phone && (
                                                <div className='custom-control custom-checkbox mb-1'>
                                                    <Checkbox
                                                        id={'phone'}
                                                        name={'Phone'}
                                                        inputStyle={{ width: '1.25rem', height: '1.32813rem' }}
                                                        divStyle={{ paddingBottom: '1vh' }}
                                                        onChange={() => handleCheckbox('phone', !isPhoneChecked)}
                                                        isChecked={isPhoneChecked}
                                                    >
                                                        {phone}
                                                    </Checkbox>
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/* Footer */}
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-ghost btn-lg' onClick={() => setModal(false)}>
                                    {translate('Cancel_Label')}
                                </button>
                                <button
                                    type='button'
                                    className='btn btn-primary btn-lg'
                                    onClick={handleSubmit}
                                    disabled={(isPhoneChecked || isEmailChecked) && !checkNotifications ? false : true}
                                >
                                    {translate('SaveSettings_Label')}
                                </button>
                            </div>
                        </>
                    </div>
                </div>
            )}
        </>
    );
};
