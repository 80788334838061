import React, { useEffect, useState } from 'react';
import { TabNavigationIds } from '../../../../common/constants/constants-portal';
import BasicSearchBox from '../../../../common/features/BasicSearchBox';
import Button from '../../../../common/features/Button';
import ClientChooser from '../../../../common/features/ClientChooser/ClientChooser';
import ErrorPage from '../../../../common/features/ErrorPage';
import FilterDropdown from '../../../../common/features/FilterDropdown';
import HeaderBar from '../../../../common/features/HeaderBar/HeaderBar';
import Spinner from '../../../../common/features/Spinner';
import TabNav from '../../../../common/features/Tabs/TabNav';
import TabNavItem from '../../../../common/features/Tabs/TabNavItem';
import { UserProfileImage } from '../../../../common/features/UserProfileImage';
import useBasicSearch from '../../../../common/hooks/useBasicSearch';
import useClearanceNotificationsFilter from '../../../../common/hooks/useClearanceNotificationsFilter';
import useTranslation from '../../../../common/hooks/useTranslation';
import { GridSubscriptions } from '../../../subscribers/common/models/EditSubscriber';
import { subscriptionTypeConstants } from '../../common/constants/constants-profile';
import TabContent from '../../common/features/TabContent';
import { GetUsersTickets } from '../../redux/actions/tickets';
import { useProfileDispatch, useProfileSelector } from '../../redux/hooks';
import { setFilteredSubscriptions } from '../../redux/reducers/subscriptionsSlice';

const ProfileGrid = () => {
    const { profile, isLoading, error } = useProfileSelector((state) => state.profile);
    const { subscriptions } = useProfileSelector((state) => state.subscriptions);
    const { user } = useProfileSelector((state) => state.hydration);
    const inputRef = React.createRef<HTMLInputElement>();
    const [gridSubscriptions, setGridSubscriptions] = useState<GridSubscriptions[]>([]);
    const [subscriptionType, setSubscriptionType] = useState<{ value: string; label: string }[]>([]);
    const { searchParam, showClear, handleSearch, handleInputChange, handleKeyDown, handleOnClearInput } = useBasicSearch({ inputRef });
    const { filteredSubscriptions, count } = useClearanceNotificationsFilter({
        searchParam,
        subscriptionType,
        gridSubscriptions
    });
    const [dropdownSelection, setDropdownSelection] = useState<number[]>([]);
    const dispatch = useProfileDispatch();
    const translate = useTranslation();

    const [currentTab, setCurrentTab] = useState<1 | 2>(1);

    useEffect(() => {
        if (!user?.InternalUser) dispatch(GetUsersTickets());
    }, [dispatch, user?.InternalUser]);

    useEffect(() => {
        if (localStorage.getItem('activeTab') === 'profile-subscription' && currentTab !== 2) {
            setCurrentTab(2);
        }
    }, [localStorage.getItem('activeTab')]);

    useEffect(() => {
        dispatch(setFilteredSubscriptions(filteredSubscriptions ? filteredSubscriptions : null));
    }, [dispatch, filteredSubscriptions]);

    useEffect(() => {
        if (subscriptions?.length) setGridSubscriptions(subscriptions);
    }, [subscriptions]);

    const handleApply = (id: string, selectedArray: number[]) => {
        const subsType = selectedArray.map((item: any) => {
            return subscriptionTypeConstants[item];
        });
        setSubscriptionType(subsType);
        setDropdownSelection(selectedArray);
    };

    const clearFilters = () => {
        setDropdownSelection([]);
        setSubscriptionType([]);
    };

    return (
        <>
            {/* Status: pending */}
            {isLoading && (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}
            {/* Status: fulfilled */}
            {!isLoading && profile && !error && (
                <div className='d-flex flex-column flex-fill h-100'>
                    <HeaderBar>
                        <div className='profile-name d-flex align-items-center'>
                            <div className='avatar icon mr-3'>
                                <UserProfileImage />
                            </div>
                            <h1>{`${profile?.FirstName}  ${profile?.LastName}`}</h1>
                        </div>

                        {currentTab === 2 && <ClientChooser />}
                    </HeaderBar>
                    {currentTab === 2 && (
                        <>
                            <BasicSearchBox
                                ref={inputRef}
                                inputPlaceHolder={translate('SearchClientNoEntryNo_Label')}
                                buttonVariant={'primary'}
                                buttonText={translate('Search_Label')}
                                handleSearch={handleSearch}
                                handleInputChange={handleInputChange}
                                handleKeyDown={handleKeyDown}
                                onClearInputValue={handleOnClearInput}
                                showClear={showClear}
                            />
                            <div className='scrollable-filter-area d-flex flex-nowrap align-items-center border-bottom bg-white py-3 pr-3'>
                                <div className='font-weight-medium pl-3 pr-2'>{translate('Filters_Label')}</div>
                                <FilterDropdown
                                    id={'SubscriptionType'}
                                    name={translate('SubscriptionType_Label')}
                                    options={subscriptionTypeConstants}
                                    optionsLabel={translate('FilterBySubscriptionType_Label')}
                                    selection={dropdownSelection}
                                    handleSelection={handleApply}
                                />
                                {subscriptionType.length ? (
                                    <div className='clear-all-filters d-flex flex-row align-items-center'>
                                        <Button className='no-bold-underline' variant={'link'} onClick={clearFilters}>
                                            {translate('Clear_Label')}
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                            <div className='align-items-center border-bottom bg-white py-3 pr-3 pl-3'>
                                <span className='font-weight-bold'>{count}</span>
                                {` ${translate('Subscriptions_Label')}`}
                            </div>
                        </>
                    )}
                    {/* Tabs */}
                    <TabNav className='border-none' tabs={TabNavigationIds.Profile}>
                        <TabNavItem onClick={() => setCurrentTab(1)} id={TabNavigationIds.Profile[0]}>
                            {translate('UserProfile_Label')}
                        </TabNavItem>
                        {!user?.InternalUser && (
                            <TabNavItem onClick={() => setCurrentTab(2)} id={TabNavigationIds.Profile[1]}>
                                {translate('ClearanceNotifications_Label')}
                            </TabNavItem>
                        )}
                    </TabNav>
                    <TabContent />
                </div>
            )}
            {/* Status: rejected */}
            {!isLoading && error && <ErrorPage errorCode={error} withTranslations />}
        </>
    );
};

export default ProfileGrid;
