import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { manageUser as Constants } from '../../../constants/mlp-constants';
import * as actions from '../../../store/actions/index';
import ManageUserHeader from './ManageUserHeader';
import TeamMembersGrid from './TeamMembersGrid';

class ManageUserForm extends Component {
    componentDidMount() {
        const value = this.props.location.search !== '' ? this.props.location.search : this.props.location.pathname;
        this.props.onInitManageUser(this.findUserId(value));
        this.props.onInitTranslations(Constants.INDEX_TRANSLATIONS_URL);
    }

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
    }
    componentWillUnmount() {
        this.props.resetStateManageUser();
    }

    findUserId = (search) => {
        return search.substring(search.indexOf('=') + 1, search.length);
    };

    render() {
        if (this.props.translations.PhraseGroupCode === Constants.INDEX_TRANSLATIONS_URL) {
            const model = this.props.manageUser.model;
            const val = this.props.manageUser.ValidationMessages;

            const header = <ManageUserHeader model={model} validationMessages={val} translations={this.props.translations} />;
            const team =
                model.TeamMembers && model.TeamMembers.length > 0 ? (
                    <TeamMembersGrid teamMembers={model.TeamMembers} translations={this.props.translations} />
                ) : (
                    ''
                );
            const cancel = (
                <div className='col-sm-12 text-right'>
                    <Link className='btn-link pull-right button small' to={Constants.SYSTEM_ADMIN_LINK}>
                        {this.props.translations.Phrases['Cancel_Button']}
                    </Link>
                </div>
            );

            return (
                <div className='page-wrapper'>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.props.translations.Phrases['Page_Title']}</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className='page-top-row'>
                        <div className='page-header-section'>
                            <nav className='mlp-breadcrumb'>
                                <Link to='/Dashboard' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                </Link>
                                <i className='fa fa-caret-right'></i>
                                <Link to='/SystemAdmin/ManageUser' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_2']}
                                </Link>
                                <i className='fa fa-caret-right'></i>
                                <span>{this.props.translations.Phrases['Page_Header_Navigation_Level_3']}</span>
                            </nav>
                        </div>
                        <div className='header-align'>
                            <h1 className='float-left bold-title'>{this.props.translations.Phrases['Page_Title']}</h1>
                        </div>
                    </div>
                    <div className='content-wrapper'>
                        <div className='row page-content'>
                            {header}
                            <br />
                            {team}
                            <br />
                            {cancel}
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div className='page-wrapper'></div>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        manageUser: state.manageUser,
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actions.initTranslations(phraseGroup)),
        onInitManageUser: (userId) => dispatch(actions.initManageUser(userId)),
        resetStateManageUser: () => dispatch(actions.resetStateManageUser())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUserForm);
