import axios from '../../../axios-mlp';
import * as actionTypes from './userActionTypes';

export const setUserModel = (viewModel) => {
    return {
        type: actionTypes.SET_USER_MODEL,
        model: viewModel
    };
};

export const setUserPermission = (viewModel) => {
    return {
        type: actionTypes.SET_USER_PERMISSION,
        model: viewModel
    };
};

export const updateUnreadNotices = () => {
    return {
        type: actionTypes.UPDATE_UNREAD_NOTICES
    };
};

export const initUserModel = () => {
    return (dispatch) => {
        axios.get('/Auth/GetLoggedInUser').then((response) => {
            dispatch(setUserModel(response.data));
        });
    };
};

export const initUserPermission = (permissionName) => {
    let params = new URLSearchParams();
    params.append('permissionName', permissionName);
    return (dispatch) => {
        return (dispatch) => {
            axios.get('/Auth/CurrentUserHasPermissionFromIdentity', { params: params }).then((response) => {
                dispatch(setUserPermission(response.data));
            });
        };
    };
};
