import { faTrash, faUserEdit } from '@fortawesome/free-solid-svg-icons';

export const StatusFilterOptions = [
    { id: 'ALL', label: 'StatusAll_Label' },
    { id: 'PORTAL_USER', label: 'StatusPortalUser_Label' },
    { id: 'EXTERNAL', label: 'StatusExternal_Label' }
] as const;

export const ActionButtonOptions = [
    { id: 'EDIT_SUBSCRIPTION', label: 'EditSubscription_Label', icon: faUserEdit },
    { id: 'DELETE_SUBSCRIPTION', label: 'DeleteSubscription_Label', icon: faTrash }
] as const;

export const subscriptionTypeConstants = [
    { value: 'SINGLE', label: 'SingleEntry_Label' },
    { value: 'CLIENT', label: 'ClientNo_Label' }
];
