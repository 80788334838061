import { faArrowLeft, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ReactDOM from 'react-dom';
import Button from '../../../../common/features/Button';
import { SearchBoxProps } from '../../../../common/models/SearchBox';
import AdvancedSearch from '../../../../pages/clearances/screens/shipmentlist/filtersbar/searchbox/AdvancedSearch';
import ClearSearchInputButton from './ClearSearchInputButton';

const SearchBoxMobile: React.FC<SearchBoxProps> = ({
    inputValue,
    placeholder,
    advancedSearchPlaceholder,
    submitButtonText,
    showAdvancedSearch,
    showMobileSearch,
    enableAdvancedSearch,
    onToggleMobileSearch,
    onChange,
    onBlur,
    onKeyDown,
    onClearInputValue,
    onToggleAdvancedSearch,
    onSubmit
}) => {
    const parentContainer = document && document.querySelector('.main-stage-wrapper');

    return (
        <>
            {parentContainer &&
                ReactDOM.createPortal(
                    <>
                        <div className='search-mobile w-100' style={{ position: 'absolute', zIndex: '999', top: '0px', left: '0px' }}>
                            <div className='input-group form-group input-group-lg align-items-center position-relative'>
                                <input
                                    type='text'
                                    placeholder={placeholder}
                                    value={inputValue}
                                    className='form-control search-input text-truncate'
                                    onChange={onChange}
                                    onKeyDown={onKeyDown}
                                    onBlur={onBlur}
                                />
                                <ClearSearchInputButton inputValue={inputValue} onClick={onClearInputValue} />
                                <Button variant='ghost-dark' onClick={onToggleMobileSearch} className='arrow-left-input-icon'>
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </Button>
                                {enableAdvancedSearch && (
                                    <div className='advanced-search-button dropdown show'>
                                        <Button variant='ghost-dark' className='settings-icon' onClick={onToggleAdvancedSearch}>
                                            <FontAwesomeIcon icon={faSlidersH} />
                                        </Button>
                                        {/* Advanced Search */}
                                        {showAdvancedSearch && (
                                            <AdvancedSearch
                                                placeholder={advancedSearchPlaceholder}
                                                showMobileSearch={showMobileSearch}
                                                onToggleAdvancedSearch={onToggleAdvancedSearch}
                                                onToggleMobileSearch={onToggleMobileSearch}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className='search-button d-flex align-items-center justify-content-center'>
                                <Button variant='primary' onClick={onSubmit}>
                                    {submitButtonText}
                                </Button>
                            </div>
                        </div>
                        <div className='overlay' onClick={onToggleMobileSearch} style={{ zIndex: 998 }}></div>
                    </>,
                    parentContainer
                )}
        </>
    );
};

export default SearchBoxMobile;
