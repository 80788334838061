interface SelectProps {
    options: readonly { value: string; label: string; isDisabled?: boolean }[];
    value?: string;
    defaultValue?: string;
    className?: string;
    hasError?: null | boolean;
    isValid?: null | boolean;
    label?: string;
    id?: string;
    disabled?: boolean;
    onChange?: (payload: string) => void;
    onBlur?: (event: React.ChangeEvent<HTMLInputElement> | string) => void;
}

const Select: React.FC<SelectProps> = ({
    options,
    value,
    defaultValue,
    className = 'custom-select',
    hasError,
    isValid,
    label,
    id,
    disabled,
    onChange,
    onBlur
}) => {
    return (
        <>
            {label && <label htmlFor={id}>{label}</label>}
            <select
                id={id}
                className={`${className} ${hasError ? 'is-invalid' : ''} ${isValid ? 'is-valid' : ''}`}
                value={value}
                defaultValue={defaultValue}
                onChange={(e) => {
                    if (onChange) onChange(e.target.value);
                }}
                onBlur={(e) => {
                    if (onBlur) onBlur(e.target.value);
                }}
                disabled={disabled}
            >
                {options.map((option) => (
                    <option key={option.value} value={option.value} disabled={option.isDisabled}>
                        {option.label || option.value}
                    </option>
                ))}
            </select>
        </>
    );
};

export default Select;
