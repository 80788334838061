export const webAPIbaseURL = 'https://mlpv2-api-uat.mlp.liiaws.net/';
//'http://localhost:54729/'; //https://mlp-dev.aws3.liiaws.net:8443/; //https://mlp-qa.aws3.liiaws.net:8443/
export const afterSignOutURL = 'https://www.livingstonintl.com/';
export const marketingSiteRegistrationPageUrl = 'https://www.livingstonintl.com/services/trade-technology/livingston-portal/';
export const tradeNewsUrl = 'https://www.livingstonintl.com/trade-news/';
export const customsBrokerageCalculatorUrl = 'https://www.livingstonintl.com/customs-brokerage-calculator/';
export const webinarsUrl = 'https://www.livingstonintl.com/webinars/';
export const supportNumberUS = '1-855-225-5548';
export const supportNumberCA = '1-855-225-5544';
export const supportMailUS = 'clientserviceUS@livingstonintl.com';
export const supportMailCA = 'clientserviceCA@livingstonintl.com';

//export const googleAnalytics = '{GoogleAnalyticsCode}';
//dev 'UA-61898762-1' //qa 'UA-61898762-2' //uat 'UA-61898762-4' //prod 'UA-61898762-5'
export const GTMID = 'GTM-WGRS5NM';
export const AUTHID = 'Nk2AOtfVayc8MH4Y1s8zoQ';
export const PREVIEWID = 'env-62';

export const global = {
    MLP_FEATURE_CODE: 'portal',
    PAY_FEATURE_CODE: 'pay',
    CLEARANCES_FEATURE_CODE: 'cntrl'
};

export const REACT_APP_ASSETS_URL = 'https://cdn.livingstonintl.com/digital-design/uat';

export const teamManagement = {
    INTERNALCLIENTADMIN: 'LIIClientAdmin',
    CLIENTADMIN: 'ClientAdmin',
    CLIENTUSER: 'ClientUser',

    INDEX_TRANSLATIONS_URL: 'TeamManagement.TeamManagement.Index',
    NEW_TEAM_MEMBER_TRANSLATIONS_URL: 'TeamManagement.TeamManagement.NewTeamMember',

    NEW_TEAM_MEMBER_URL: '/TeamManagement/NewTeamMember',
    EDIT_TEAM_MEMBER_URL: '/TeamManagement/EditTeamMember',
    NEW_TEAM_MEMBER_VALIDATION_URL: '/TeamManagement/NewTeamMemberValidate',
    EDIT_TEAM_MEMBER_VALIDATION_URL: '/TeamManagement/EditTeamMemberValidate',

    TEAM_MEMBER_DETAILS_LINK: '/TeamManagement/TeamManagement/TeamMemberDetails',
    NEW_TEAM_MEMBER_LINK: '/TeamManagement/TeamManagement/NewTeamMember',
    CLONE_TEAM_MEMBER_LINK: '/TeamManagement/TeamManagement/CloneTeamMember',
    EDIT_TEAM_MEMBER_LINK: '/TeamManagement/TeamManagement/EditTeamMember',
    GET_SALESFORCE_CONTACTS_LINK: '/TeamManagement/GetSalesforceContacts'
};

export const clientChooser = {
    COUNTRY_CODE_US: 'us',
    COUNTRY_CODE_CA: 'ca'
};

export const dashboard = {
    DASHBOARD_SAVE_CLIENTSERVICESELECTION_URL: '/Dashboard/SaveClientServiceSelection',
    DASHBOARD_SAVE_WIDGETSELECTION_URL: '/Dashboard/SaveWidgetSelection',
    DASHBOARD_SEARCH_EDM_CLIENT_SERVICES_URL: '/Dashboard/SearchEDMClients',
    DASHBOARD_SAVEWIDGETSORTORDER_URL: '/Dashboard/SaveWidgetSortOrder',
    CONTENT_TYPE_IFRAME: 'Iframe',
    CONTENT_TYPE_PORTAL: 'Portal',
    WIDGET_CLIENT_NUMBER_PARAM: 'liiclientaccountno/',
    WIDGET_SPACING: 15,
    WIDGET_HEIGHT_WIDTH_RATIO: 1,
    RENDER_TYPE_TAB: 'ExternalTab',
    RENDER_TYPE_LINK: 'ReportsPage',
    RENDER_WIDGET_ONLY: 'WidgetOnly',
    FULL_REPORT_URL: '/Reports/Reports/GetReport?widgetID='
};

export const accessManagement = {
    SEARCH_USERS_URL: '/AccessManagement/AccessManagement/SearchUsers',
    CREATE_NEW_CLIENT_ADMIN_URL: '/AccessManagement/CreateNewClientAdmin',
    COMPLETE_CLIENT_ADMIN_TRANSFER_URL_REVOKE: '/AccessManagement/TransferClientAdminRightsRevoke',
    COMPLETE_CLIENT_ADMIN_TRANSFER_URL_RETAIN: '/AccessManagement/TransferClientAdminRightsRetain',
    GET_USERS_FOR_EXISTING_CLIENT_ADMIN_URL: '/AccessManagement/GetUsersForExistingClientAdmin',
    GET_USERS_FOR_EXISTING_PAY_ADMIN_URL: '/AccessManagement/GetUsersForExistingPayAdmin',
    GET_USER_DETAIL_URL: '/AccessManagement/IndexUserDetail?userId=',
    CONFIRM_CLIENT_ADMIN_URL: '/AccessManagement/ConfirmNewClientAdmin',
    SELECT_NEW_CLIENT_ADMIN_URL: '/AccessManagement/SelectNewClientAdmin?userId=',
    MATCH_SALESFORCE_CONTACT: '/AccessManagement/MatchSalesforceContact',
    CLIENT_ADMIN_VALIDATE_URL: '/AccessManagement/ClientAdminValidate',
    PAY_ADMIN_ROLE_NAME: 'PaymentAdmin'
};

export const userStatus = {
    ACTIVE: 'Active',
    DEACTIVATED: 'Deactivated',
    HOLD: 'Hold',
    CANCELLED: 'Cancelled',
    EXPIRED: 'Expired'
};

export const userFlags = {
    NEWUSERQUESTIONS: 0,
    ONBOARDED: 1,
    SHIPPINGQUESTIONS: 2,
    CONFIRMED: 3,
    ENTRYREADY: 4,
    BANKINGINFO: 5,
    POATTERMS: 6
};

export const imaging = {
    PACKAGE_TYPE_ZIP: 1,
    PACKAGE_TYPE_MERGE: 2,

    imagingPackageStatusType: {
        New: 1,
        FaultTerminated: 2,
        Cancelled: 3,
        Completed: 4,
        InProgress: 5,
        Submitted: 6,
        OverLimit: 7,
        IncorrectPassword: 8
    }
};

export const internalUser = {
    SEARCH_INTERNAL_USERS_URL: '/AccessManagement/AccessManagement/SearchInternalUsers',
    SUBMIT_INTERNAL_USER: 'AccessManagement/InternalUser',
    INTERNAL_USER_VALIDATE: '/AccessManagement/InternalUserValidate'
};

export const userRole = {
    PAYMENTADMIN: 'PaymentAdmin'
};

export const batchType = {
    BatchCreate: 1,
    BatchEdit: 2
};

export const clientAdminHeader = {
    SELECT_NEW_CLIENT_ADMIN_URL: '/AccessManagement/NewClientAdminForm'
};

export const SystemAdmin = {
    GET_USER_MANAGE_URL: '/SystemAdmin/ManageUser/ManageUser'
};

export const batchStatusType = {
    New: 1,
    FaultTerminated: 2,
    ValidationTerminated: 3,
    ExpirationTerminated: 4,
    PendingApproval: 5,
    Cancelled: 6,
    Completed: 7,
    InProgress: 8,
    Submitted: 9,
    Cancelling: 10,
    Completing: 11,
    Approved: 12,
    Rejected: 13
};
export const batchJobUrlPaths = {
    SUBMIT_BATCH_EDIT_LIST: '/BatchUser/SubmitEditData'
};

export const manageUser = {
    INDEX_TRANSLATIONS_URL: 'SystemAdmin.ManageUser.ManageUser',
    SYSTEM_ADMIN_LINK: '/SystemAdmin/ManageUser',
    SUBMIT_MANAGE_USER_URL: '/ManageUser/ManageUser',
    SALESFORCE_CONTACT_URL: '/ManageUser/GetSalesforceContacts',
    VALIDATE_USER_URL: '/ManageUser/EditUsersValidate'
};

export const filters = {
    ALL_TYPES: 'allTypes',

    SEARCH_SECTION_CLIENT_NUMBER: 'clientNumber',
    SEARCH_SECTION_MAIN: 'main',
    SEARCH_SECTION_DATE: 'date',
    SEARCH_SECTION_DOC_TYPE: 'docType',
    SEARCH_SECTION_OTHER: 'other',
    SEARCH_SECTION_LOCATON: 'location',
    SEARCH_SECTION_MEASURES: 'measures',
    SEARCH_SECTION_PARTIES: 'parties',
    SEARCH_SECTION_PARTS: 'parts',
    SEARCH_SECTION_REF_NUMBERS: 'referenceNumber'
};

export const storageModelName = {
    VIEWIMAGE: 'ViewImage'
};

export const imageDetailsPath = {
    VIEWIMAGE_SEARCHSHIPMENTDETAIL: '/Imaging/Imaging/SearchResultDetails/DetailsEntry',
    DOCUMENTCOMPARE_VIEW: '/Imaging/Imaging/SearchResultDetails/DocumentCompareView',
    DOCUMENT_VIEW: '/Imaging/Imaging/SearchResultDetails/DocumentView',
    DOWNLOAD_AND_PRINT: '/Imaging/Imaging/SearchResultDetails/DownloadAndPrint'
};

export const searchShiment = {
    SEARCHTYPECODECA: 'CASS',
    SEARCHTYPECODEUS: 'USSS',
    SHIPMENT_SEARCH_SAVE_COLUMNSELECTION_URL: '/SearchShipment/SaveColumnVisibility'
};

export const ClientServicesByCountry = {
    ACCESS_MANAGEMENT: 'Access Management',
    CLIENT_ADMIN: 'Client Admin'
};

export const Upload = {
    UPLOAD_CREATE_FILE: '/BatchUser/UploadCreateFile',
    UPLOAD_ACCEPTED_FILE_TYPES_MSG: 'Invalid file type, only CSV files can be uploaded',
    CSV_EXTENSION: '.csv',
    UPLOAD_EDIT_FILE: '/BatchUser/UploadEditFile'
};

export const Side = {
    LEFT: 'left',
    RIGHT: 'right'
};

export const userPermission = {
    CENTRALDASHBOARD_ACCESS: 'CentralDashboard_Access',
    RECAPTCHA_KEY: '6LehhHMcAAAAAOgqLZikZvd68mRtfSYtIRgVsZuH'
};

export const LanguageCodes = { English: 'en', French: 'fr' };
