import React from 'react';

const TeamMemberRow = (props) => {
    return (
        <tr className='team-row'>
            <td className='team-row-data'>{props.teamMember.FirstName}</td>
            <td className='team-row-data'>{props.teamMember.LastName}</td>
            <td className='team-row-data'>{props.teamMember.Email}</td>
        </tr>
    );
};

export default TeamMemberRow;
