import { API_CLEARANCES_PATH } from '../../../../common/constants/constants-portal';
import { apiSlice } from '../../../../redux/api/apiSlice';
import { ShipmentMilestones } from '../../common/constants/overview-dashboard-constants';
import type { ClearanceListBody, ClearanceListData, ClearanceListModified } from '../../common/models/overviewDashboard';

export const dashboardApi = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        clearanceList: build.query<ClearanceListModified, ClearanceListBody>({
            query: (body) => ({
                url: `${API_CLEARANCES_PATH}/clearanceList`,
                method: 'POST',
                body
            }),
            transformResponse: (response: ClearanceListData) => {
                const clearances = response.Clearances.map((obj) => {
                    return {
                        ...obj,
                        ModeOfTransport: { id: obj.ModeOfTransport },
                        BillOfLading: {
                            MasterBill:
                                obj.BillOfLading?.length && obj.BillOfLading[0].MasterBill ? obj.BillOfLading[0].MasterBill : undefined
                        },
                        Milestone:
                            obj.Destination && obj.Stage?.length
                                ? ShipmentMilestones[obj.Destination as keyof typeof ShipmentMilestones][
                                      obj.Stage[obj.Stage.length - 1] as keyof (typeof ShipmentMilestones)['Canada']
                                  ].label ||
                                  ShipmentMilestones[obj.Destination as keyof typeof ShipmentMilestones][
                                      obj.Stage[obj.Stage.length - 1] as keyof (typeof ShipmentMilestones)['USA']
                                  ].label
                                : undefined
                    };
                });

                return {
                    totalInProgress: response.TotalInProgress || 0,
                    totalOnHold: response.TotalOnHold || 0,
                    totalCompleted: response.TotalCompleted || 0,
                    clearances
                };
            }
        })
    })
});

export const { useClearanceListQuery } = dashboardApi;
