import { useEffect, useState } from 'react';
import { TranslationsData } from '../../../../common/models/ResponseData';
import { useDocumentsDispatch } from '../../redux/hooks';
import { formatTranslated, getSearchTypesBySelectedServices, setGroupAttributes, sortBy } from '../../utils/imaging-utils';
import { DocumentsReturnFields } from '../constants/constants-documents';
import { filtersConstants } from '../imaging-constants';
import { Filters, ImageGroupings, model, Options, Payload } from '../models/ImagingModel';
import { ImagingGridSettingsSlice } from '../models/ReduxSlices';

interface UseSetupPayloadForImagingProps {
    model: model;
    translations: TranslationsData | null | undefined;
    shouldSubmitImageSearch: boolean;
    selectedCountry: string | undefined;
    gridSettings: ImagingGridSettingsSlice;
    clientSelection: any;
    searchTypeCode: string;
}

const useSetupPayloadForImaging = ({
    model,
    translations,
    shouldSubmitImageSearch,
    selectedCountry,
    gridSettings,
    clientSelection,
    searchTypeCode
}: UseSetupPayloadForImagingProps) => {
    const [payloadToGetDocs, setPayloadToGetDocs] = useState<Payload | {}>({});
    const dispatch = useDocumentsDispatch();

    useEffect(() => {
        if (
            model &&
            selectedCountry &&
            model?.ClientServiceSelection?.Countries.find((item) => item.IsSelected) &&
            shouldSubmitImageSearch &&
            translations &&
            Object.entries(translations?.Phrases).length
        ) {
            const clientServices = clientSelection.ClientServices;
            const updatedCountries = model.ClientServiceSelection.Countries.map((item) => {
                return item.Code === selectedCountry ? { ...item, IsSelected: true } : { ...item, IsSelected: false };
            });
            const clientServiceSelection = { ...model.ClientServiceSelection, ClientServices: clientServices, Countries: updatedCountries };

            let availableSearchTypes = getSearchTypesBySelectedServices(model);
            let imageGroupings = [] as ImageGroupings[] | [];
            if (availableSearchTypes && availableSearchTypes.length && translations?.Phrases) {
                imageGroupings = [...model.ImageGroupings].map((item, index) => {
                    return (item = {
                        ...model.ImageGroupings[index === 0 ? 0 : index],
                        IsSelected:
                            selectedCountry === 'us'
                                ? item.SearchTypeCode === 'USSHP' && item.Name === '_entryNumber'
                                    ? true
                                    : false
                                : item.SearchTypeCode === 'CASHP' && item.Name === '_transactionNumber' && searchTypeCode !== 'CACLVS'
                                ? true
                                : item.SearchTypeCode === 'CACLVS' && item.Name === 'LICCD' && searchTypeCode === 'CACLVS'
                                ? true
                                : false,
                        IsHidden:
                            selectedCountry === 'us'
                                ? item.SearchTypeCode === 'USSHP' && item.Name === '_entryNumber'
                                    ? false
                                    : true
                                : item.SearchTypeCode === 'CASHP' && item.Name === '_transactionNumber' && searchTypeCode !== 'CACLVS'
                                ? false
                                : item.SearchTypeCode === 'CACLVS' && item.Name === 'LICCD' && searchTypeCode === 'CACLVS'
                                ? false
                                : true,
                        CalculatedValue: item.SearchTypeCode + '-' + item.Name,
                        TranslatedName: formatTranslated(item.Name, translations?.Phrases),
                        GroupAttributes: setGroupAttributes(item)
                    });
                });
            }

            const searchTypes = [...model.SearchTypes].map((item, index) => {
                return (item = {
                    ...model.SearchTypes[index],
                    IsSelected:
                        selectedCountry === 'us'
                            ? item.Code === 'USSHP'
                                ? true
                                : false
                            : item.Code === 'CASHP' && searchTypeCode !== 'CACLVS'
                            ? true
                            : item.Code === 'CACLVS' && searchTypeCode === 'CACLVS'
                            ? true
                            : false
                });
            });

            const getAttributeAndDataType = (filter: Filters, sortedOptions: Options[]) => {
                if (filter.ControlName === 'MainSearchFilter') {
                    return { Attribute: filtersConstants.ALL_TYPES, AttributeDataType: 'string' };
                } else if (filter.ControlName === 'PrimaryDateFilter') {
                    return {
                        Attribute: sortedOptions.filter((sortOption) => sortOption.IsSelected)[0].Value,
                        AttributeDataType: sortedOptions[0].DataType
                    };
                } else if (filter.ControlName === 'LISTAT') {
                    return { Attribute: 'LISTAT', AttributeDataType: 'keyword' };
                } else if (filter.ControlName === 'USSTAT') {
                    return { Attribute: 'USSTAT', AttributeDataType: 'keyword' };
                } else {
                    return { Attribute: filter.Attribute, AttributeDataType: filter.AttributeDataType };
                }
            };

            const isUsOptionsSelected = model.Filters.filter(
                (option) =>
                    option.Name === 'Date' &&
                    option.ControlName === 'PrimaryDateFilter' &&
                    option.Options.some((option) => option.Name === 'USARVD')
            )[0].Options.some((opt) => opt.IsSelected);

            const isCaOptionsSelected = model.Filters.filter(
                (option) =>
                    option.Name === 'Date' &&
                    option.ControlName === 'PrimaryDateFilter' &&
                    option.Options.some((option) => option.Name === 'LIETA')
            )[0].Options.some((opt) => opt.IsSelected);

            const filters = [...model.Filters].map((item, index) => {
                if (item?.Options.length) {
                    const options: Options[] = [...item.Options].map((it, i) => {
                        return (it = {
                            ...it,
                            TranslatedName: translations?.Phrases[it.Name] || it.Name
                        });
                    });

                    let sortedOptions =
                        options[0] && options[0].SortValue ? sortBy(options, 'SortValue') : sortBy(options, 'TranslatedName');
                    sortedOptions = sortedOptions.map((it: Options, i: number) => {
                        return (it = {
                            ...it,
                            IsSelected:
                                i === 0 && item.ControlName === 'PrimaryDateFilter'
                                    ? it.Name === 'USARVD'
                                        ? isUsOptionsSelected
                                            ? it.IsSelected
                                            : true
                                        : it.Name === 'LIETA'
                                        ? isCaOptionsSelected
                                            ? it.IsSelected
                                            : true
                                        : true
                                    : it.IsSelected
                        });
                    });

                    return {
                        ...item,
                        FilterIndex: index,
                        Options: sortedOptions,
                        Attribute: getAttributeAndDataType(item, sortedOptions)?.Attribute,
                        AttributeDataType: getAttributeAndDataType(item, sortedOptions)?.AttributeDataType
                    };
                } else {
                    return { ...item, FilterIndex: index };
                }
            });

            const payload = {
                ChangedProperties: model.ChangedProperties,
                ClientServiceSelection: clientServiceSelection,
                Filters: filters,
                GridModel: [],
                ImageGroupings: imageGroupings,
                MaxSelectedDocuments: model.MaxSelectedDocuments,
                NumberOfResultsToLoad: model.NumberOfResultsToLoad,
                TotalLoadedResults: model.TotalLoadedResults,
                OnlyShowLatest: model.OnlyShowLatest,
                QuickSearchValue: model.QuickSearchValue,
                ReturnFields: DocumentsReturnFields,
                SearchResults: [],
                SearchTypes: searchTypes,
                IsPortalSearch: true,
                TotalEntries: model.TotalEntries,
                TotalResults: model.TotalResults
            } as Payload;
            setPayloadToGetDocs(payload);
        }
    }, [model, translations, shouldSubmitImageSearch, selectedCountry, gridSettings, clientSelection, dispatch]);

    const resetPayloadToGetDocs = () => {
        setPayloadToGetDocs({});
    };

    return { payloadToGetDocs, resetPayloadToGetDocs };
};

export default useSetupPayloadForImaging;
