import { memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { PortalRoutes } from '../../common/constants/constants-portal';
import CloneTeamMember from './screens/CloneTeamMember/CloneTeamMember';
import CreateNewTeamMember from './screens/CreateNewTeamMember/CreateNewTeamMember';
import EditTeamMember from './screens/EditTeamMember/EditTeamMember';
import TeamGrid from './screens/TeamGrid/TeamGrid';

const Team = memo(function Team() {
    return (
        <main className='main-stage-wrapper h-100 d-flex flex-column flex-fill position-relative'>
            <div className='d-flex flex-column flex-fill'>
                <Switch>
                    <Route
                        path={[`${PortalRoutes.team}/TeamMember`, `/${PortalRoutes.team}//TeamMember`]}
                        exact
                        component={EditTeamMember}
                    />
                    <Route path={[PortalRoutes.team, `/${PortalRoutes.team}`]} exact component={TeamGrid} />
                    <Route
                        path={[`${PortalRoutes.team}/CreateNewUser`, `/${PortalRoutes.team}//CreateNewUser`]}
                        exact
                        component={CreateNewTeamMember}
                    />
                    <Route
                        path={[`${PortalRoutes.team}/CloneNewUser`, `/${PortalRoutes.team}//CloneNewUser`]}
                        exact
                        component={CloneTeamMember}
                    />
                </Switch>
            </div>
        </main>
    );
});

export default Team;
