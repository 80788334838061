import React from 'react';

interface ColProps {
    children?: React.ReactNode;
    className?: string;
}

const Col: React.FC<ColProps> = ({ children, className = '' }) => {
    return <div className={`col ${className}`}>{children}</div>;
};

export default Col;
