import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import SearchBox from '../../common/Searchbox/Searchbox';

class ClientAdminSearchBox extends Component {
    state = {
        value: '',
        redirect: false
    };

    static propTypes = {
        onSelectItem: PropTypes.func,
        formatResultBuilder: PropTypes.func,
        url: PropTypes.string
    };

    onValueChanged = (val) => {
        this.setState({
            value: val
        });
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to='/AccessManagement' />;
        }
        return (
            <SearchBox
                label={''}
                placeholder={this.props.translations.Phrases['TypeHereToSearch_Label']}
                name='User.Email'
                url={this.props.url}
                formatResults={this.props.formatResultBuilder}
                minChars={4}
                valMsg={''}
                onSelectItem={this.props.onSelectItem}
                value={this.state.value}
                onValueChanged={this.onValueChanged}
            ></SearchBox>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default withRouter(connect(mapStateToProps)(ClientAdminSearchBox));
