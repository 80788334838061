import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    DropResult,
    Droppable,
    DroppableProvided
} from 'react-beautiful-dnd';
import useDropdownToggle from '../../hooks/useDropdownToggle';
import useTranslation from '../../hooks/useTranslation';
import { ColumnSettings } from '../../models/GridPreferences';
import Button from '../Button';
import Checkbox from '../Checkbox';
import DropdownMenuButton from '../DropdownMenuButton';
import Spinner from '../Spinner';

interface ColumnSelectionDropdownProps {
    descriptiveText: string;
    applyDropdownChanges: (payload: ColumnSettings[]) => void;
    columnActivatedChange?: (payload: boolean) => void;
    allColumns: ColumnSettings[] | null;
    visibleColumns: ColumnSettings[] | null;
    areColumnsLoading: boolean;
}

const ColumnSelectionDropdown: React.FC<ColumnSelectionDropdownProps> = ({
    descriptiveText,
    applyDropdownChanges,
    columnActivatedChange,
    allColumns,
    visibleColumns,
    areColumnsLoading
}) => {
    const dropdownMenuRef = useRef(null);
    const { isOpen, handleToggle } = useDropdownToggle(dropdownMenuRef);
    const translate = useTranslation();
    const [checkBoxes, setCheckBoxes] = useState<ColumnSettings[]>(allColumns ? allColumns : []);
    const [selectAll, setSelectAll] = useState(false);
    const [columnActivated, setColumnActivated] = useState(false);
    const [disableApply, setDisableApply] = useState(true);

    useEffect(() => {
        if (checkBoxes.length) {
            const allSelected: boolean[] = [];
            for (let i = 0; i < checkBoxes.length; i++) {
                if (checkBoxes[i].IsVisibleAlways) {
                    continue;
                } else {
                    checkBoxes[i].IsVisible ? allSelected.push(true) : allSelected.push(false);
                }
            }
            setSelectAll(allSelected?.includes(false) ? false : true);
        }
    }, [isOpen, checkBoxes]);

    useEffect(() => {
        if (allColumns) {
            setCheckBoxes(allColumns);
            setDisableApply(true);
        }
    }, [isOpen, allColumns]);

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        const reorderedItems: ColumnSettings[] = reorder(checkBoxes, result.source.index, result.destination.index);
        setCheckBoxes(reorderedItems);
        checkForChanges([...reorderedItems]);
    };

    const checkForChanges = (checkBoxesEdit: ColumnSettings[]) => {
        if (allColumns) {
            setColumnActivated(false);
            const checkBoxesSorted = [...checkBoxesEdit];
            const check = checkBoxesSorted.map((item, i) => {
                const lookupItem = allColumns.find((element) => element.Code === item.Code);
                if (!lookupItem?.IsVisible && item.IsVisible) setColumnActivated(true);

                if (item.Code === allColumns[i].Code && item.IsVisible === allColumns[i].IsVisible && item.Order === allColumns[i].Order) {
                    return true;
                } else {
                    return false;
                }
            });
            check.includes(false) ? setDisableApply(false) : setDisableApply(true);
        }
    };

    const reorder = (list: ColumnSettings[], startIndex: number, endIndex: number) => {
        const result = [...Array.from(list)];
        result[startIndex] = { ...result[startIndex], Order: endIndex + 1 };
        result[endIndex] = { ...result[endIndex], Order: startIndex + 1 };
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleCheckbox = (key: string, value: boolean) => {
        let handledCheckBoxes: ColumnSettings[] = [];
        handledCheckBoxes = [...checkBoxes].map((item) => {
            if (key === 'selectAll') {
                if (item.IsVisibleAlways) {
                    return (item = { ...item, IsVisible: true });
                } else {
                    return (item = { ...item, IsVisible: value });
                }
            } else {
                if (item.Code === key) {
                    item = { ...item, IsVisible: value };
                }
                return item;
            }
        });
        setSelectAll(!selectAll);
        setCheckBoxes(handledCheckBoxes);
        checkForChanges(handledCheckBoxes);
    };

    const handleApply = () => {
        if (visibleColumns) {
            applyDropdownChanges(checkBoxes);
            if (columnActivatedChange) columnActivatedChange(columnActivated);
            setColumnActivated(false);
            handleToggle();
            setDisableApply(true);
        }
    };

    return (
        <>
            <div className='dropdown' style={{ marginLeft: 'auto' }}>
                <div className='d-flex align-items-center justify-center'>
                    <DropdownMenuButton
                        childrenDivClassName=' dropdown-menu-right w-300px w-sm-400px'
                        id='RearrangeDropdownToggle'
                        label={
                            <>
                                {descriptiveText}
                                {areColumnsLoading && <Spinner size='small' className='ml-2' />}
                            </>
                        }
                        isOpen={isOpen}
                        onClick={handleToggle}
                        ref={dropdownMenuRef}
                        disabled={false}
                    >
                        <p className='text-muted text-nowrap px-4 py-2 m-0'>{translate('ShowHideReorderColumns_Label')}</p>
                        <div className='dropdown-item m-0'>
                            <div className='custom-control custom-checkbox'>
                                <Checkbox
                                    id={`selectAll`}
                                    name={'selectAll'}
                                    isChecked={selectAll}
                                    onChange={handleCheckbox}
                                    disabled={areColumnsLoading ? true : false}
                                >
                                    {translate('AllColumns_Label')}
                                </Checkbox>
                            </div>
                        </div>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId='droppable'>
                                {(provided: DroppableProvided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        style={{ maxHeight: '45vh', overflow: 'hidden', overflowY: 'auto' }}
                                        className='position-relative'
                                    >
                                        {checkBoxes &&
                                            checkBoxes?.map((column, index) => (
                                                <Draggable key={column.Code} draggableId={column.Code.toString()} index={index}>
                                                    {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                                        <label
                                                            {...provided.dragHandleProps}
                                                            className={
                                                                snapshot.isDragging
                                                                    ? 'dropdown-item m-0 item-dragging'
                                                                    : 'dropdown-item m-0'
                                                            }
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                        >
                                                            <div className='inner-wrapper d-flex justify-content-between align-items-center'>
                                                                <Checkbox
                                                                    id={`${column?.Code}${index}`}
                                                                    inputClass={'custom-control-input mr-3'}
                                                                    divClass={'custom-control custom-checkbox'}
                                                                    name={column.Code}
                                                                    isChecked={column.IsVisible}
                                                                    onChange={handleCheckbox}
                                                                    disabled={
                                                                        column.IsVisibleAlways ? true : areColumnsLoading ? true : false
                                                                    }
                                                                >
                                                                    {translate(column.HeaderLabel)}
                                                                </Checkbox>
                                                                <div className='text-muted drag-handle-icon'>
                                                                    <FontAwesomeIcon icon={faGripVertical} />
                                                                </div>
                                                            </div>
                                                        </label>
                                                    )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <hr />
                        <div className='button-container d-flex pb-2 mr-4 ml-4' style={{ width: '16rem' }}>
                            <Button
                                children={translate('Cancel_Label')}
                                type='button'
                                variant='tertiary-blue'
                                className='mr-2 w-50'
                                onClick={handleToggle}
                            />
                            <Button
                                children={translate('Apply_Label')}
                                type='button'
                                variant='primary'
                                className='w-50'
                                disabled={areColumnsLoading || disableApply ? true : false}
                                onClick={handleApply}
                            />
                        </div>
                    </DropdownMenuButton>
                </div>
            </div>
        </>
    );
};

export default ColumnSelectionDropdown;
