import { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { formatTranslated } from '../../../utils/mlp-utils';

class SearchHeader extends Component {
    render() {
        const searchTypeComponents = this.props.searchTypes.map((e) => {
            let className = 'imaging-nav-item';

            if (e.IsSelected) {
                className += ' selected';
            }

            return (
                <div className={className} key={e.Code} onClick={() => this.props.onSelectSearchType(this.props.searchTypes, e.Code)}>
                    <div>
                        <span>{formatTranslated(e.Code, this.props.translations.Phrases)}</span>
                    </div>
                </div>
            );
        });

        return <div className='search-header-top-row imaging-nav-container'>{searchTypeComponents}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectSearchType: (searchTypes, code) => {
            const currentSearchType = searchTypes.find((type) => type.IsSelected === true);
            if (currentSearchType.Code !== code) {
                dispatch(actions.selectSearchType(code));
                dispatch(actions.clearImageFilters());
            }
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchHeader);
