import { API_HYDRATION_PATH } from '../../common/constants/constants-portal';
import type { NoticesData } from '../../common/models/ResponseData';
import { apiSlice } from './apiSlice';

export const noticesApi = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        activeNotices: build.query<NoticesData['Notices'], void>({
            query: () => ({
                url: `${API_HYDRATION_PATH}/GetActiveNotices/`
            }),
            transformResponse: (response: NoticesData) => {
                return response.Notices;
            }
        })
    })
});

export const { useActiveNoticesQuery, useLazyActiveNoticesQuery } = noticesApi;
