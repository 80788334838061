import { faBoxOpen, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo } from 'react';
import ClientChooser from '../../../../common/features/ClientChooser/ClientChooser';
import HeaderBar from '../../../../common/features/HeaderBar/HeaderBar';
import useTranslation from '../../../../common/hooks/useTranslation';
import ProductReportGrid from '../ProductReportGrid';

const ProductReportPage = memo(function DocumentsPage() {
    const translate = useTranslation();

    return (
        <div className='d-flex flex-column flex-fill'>
            {/* Header bar */}
            <HeaderBar>
                <div className={'d-flex align-items-center'}>
                    <span className='badge badge-circular badge-info mr-3'>
                        <FontAwesomeIcon icon={faBoxOpen} />
                    </span>
                    <h1 className={'m-0 mr-2'}>{translate('ProductReport_Label')}</h1>
                </div>
                <ClientChooser />
            </HeaderBar>
            <div className='component-box bg-white mb-5' style={{ display: 'flex', flexWrap: 'wrap' }}>
                <div className='alert alert-info  m-3' role='alert' id='alert-dark' style={{ width: 'max-content' }}>
                    <div className='container-fluid px-0 pr-3 pr-md-4'>
                        <div className='row'>
                            <div className='col d-flex justify-content-start'>
                                <div>
                                    <FontAwesomeIcon icon={faExclamationCircle} />
                                </div>
                                <div className='container-fluid pr-0'>
                                    <div>
                                        <div className='flex-wrap'>
                                            <span className='font-weight-bold'>{translate('ProductReport_Label')} </span>
                                            <span>
                                                {translate('IsAvailable_Label')}
                                                <br />
                                                {translate('PleaseUseDataGrid_Label')}
                                                <br />
                                                <br />
                                                {translate('EachClientNumberHasCsv_Label')} <br />
                                                {translate('NotAllClientNumbers_Label')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ProductReportGrid />
        </div>
    );
});

export default ProductReportPage;
