import { PortalRoutes } from '../../../../../common/constants/constants-portal';
import Breadcrumb from '../../../../../common/features/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../../../common/features/Breadcrumb/BreadcrumbItem';
import useTranslationHydration from '../../../../../common/hooks/useTranslationHydration';
import { useClearancesSelector } from '../../../redux/hooks';

interface BreadcrumbClearancesProps {
    origin: string | null;
}

const BreadcrumbClearances: React.FC<BreadcrumbClearancesProps> = ({ origin }) => {
    const translate = useTranslationHydration();
    const { shipmentDetails } = useClearancesSelector((state) => state.shipmentDetails);

    if (origin === 'documents') {
        return (
            <Breadcrumb>
                <BreadcrumbItem url={PortalRoutes.documents}>{translate('Documents_Label')}</BreadcrumbItem>
                <BreadcrumbItem>{shipmentDetails?.EntryNumber}</BreadcrumbItem>
            </Breadcrumb>
        );
    } else if (origin === 'cad') {
        return (
            <Breadcrumb>
                <BreadcrumbItem url={PortalRoutes.completeEntryData}>{translate('CAD_Label')}</BreadcrumbItem>
                <BreadcrumbItem>{shipmentDetails?.EntryNumber}</BreadcrumbItem>
            </Breadcrumb>
        );
    } else {
        return (
            <Breadcrumb>
                <BreadcrumbItem url={PortalRoutes.clearances}>{translate('Clearances_Label')}</BreadcrumbItem>
                <BreadcrumbItem>{shipmentDetails?.EntryNumber}</BreadcrumbItem>
            </Breadcrumb>
        );
    }
};

export default BreadcrumbClearances;
