import 'jquery-mask-plugin';
import React, { Component } from 'react';
import { formatTranslated } from '../../utils/mlp-utils';
import DropZone from './DropZone';

class Upload extends Component {
    dropZoneRef = React.createRef();
    dropZoneElementRef = React.createRef();

    state = {
        anyFiles: false,
        message: '',
        isError: false,
        isSuccess: false,
        isServerError: false,
        uploadInProgress: false
    };

    changeFileCount = (files) => {
        this.setState({ anyFiles: files.length > 0 });
    };

    getDropZone = (dropZoneReference) => {
        this.MyDropZone = dropZoneReference;
    };

    clearFiles = () => {
        if (!this.isClearDisabled()) {
            this.MyDropZone.removeAllFiles();
        }
    };

    uploadFiles = () => {
        if (!this.isUploadDisabled()) {
            this.setUploadInProgress(true);
            this.MyDropZone.processQueue();
        }
    };

    constructMessageClass = () => {
        let messageClass = ['message'];
        if (this.state.isError) {
            messageClass.push('error');
        } else if (this.state.isSuccess) {
            messageClass.push('success');
        }
        return messageClass.join(' ');
    };

    constructUploadBtnClass = () => {
        let btnClass = ['button', 'small', 'blue'];
        if (this.isUploadDisabled()) {
            btnClass.push('disabled');
        }
        return btnClass.join(' ');
    };

    constructClearBtnClass = () => {
        let btnClass = ['button', 'small', 'blue', 'clear-margin-right'];
        if (this.isClearDisabled()) {
            btnClass.push('disabled');
        }
        return btnClass.join(' ');
    };

    isUploadDisabled = () => {
        return !this.state.anyFiles || this.state.uploadInProgress || (this.state.isError && !this.state.isServerError);
    };

    isClearDisabled = () => {
        return this.state.uploadInProgress || !this.state.anyFiles;
    };

    setMessage = (message) => {
        const translatedMessage = formatTranslated(message, this.props.translations.Phrases);
        if (translatedMessage) {
            message = translatedMessage;
        }
        this.setState({
            message: message
        });
    };

    setStatus = (isError, isServerError, isSuccess) => {
        this.setState({
            isSuccess: isSuccess,
            isError: isError,
            isServerError: isServerError
        });
    };

    setUploadInProgress = (inProgress) => {
        this.setState({ uploadInProgress: inProgress });
    };

    isClearDisabled = () => {
        return this.state.uploadInProgress || !this.state.anyFiles;
    };

    isUploadDisabled = () => {
        return !this.state.anyFiles || this.state.uploadInProgress || (this.state.isError && !this.state.isServerError);
    };

    render() {
        const messageElement = <div className={this.constructMessageClass()}>{this.state.message}</div>;

        const uploadComponent = (
            <DropZone
                ref={this.dropZoneRef}
                {...this.props}
                fileCountChanged={this.changeFileCount}
                setMessage={this.setMessage}
                setStatus={this.setStatus}
                setUploadInProgress={this.setUploadInProgress}
                translations={this.props.translations}
                getDropZone={this.getDropZone}
            />
        );

        const uploadInProgress = this.state.uploadInProgress ? (
            <div className='upload-in-progress text-center'>
                <span className=''>{formatTranslated('UploadingFile_Label', this.props.translations.Phrases)}</span>
            </div>
        ) : (
            ''
        );
        return (
            <div className='uploader'>
                <div className='col-sm-12'>{messageElement}</div>
                <div className='col-sm-12'>
                    {uploadInProgress}
                    {uploadComponent}
                </div>
                <div className='col-sm-12 space-top text-right'>
                    <button className={this.constructUploadBtnClass()} onClick={this.uploadFiles}>
                        <i className='fa fa-upload'></i>
                        {formatTranslated('Upload_Button', this.props.translations.Phrases)}
                    </button>
                    <button className={this.constructClearBtnClass()} onClick={this.clearFiles}>
                        <i className='fa fa-times'></i>
                        {formatTranslated('Clear_Button', this.props.translations.Phrases)}
                    </button>
                </div>
            </div>
        );
    }
}

export default Upload;
