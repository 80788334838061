import produce from 'immer';
import * as actionTypes from '../actions/clientAdmin/verifyAccessActionTypes';
const initialState = {
    model: {
        User: {},
        CoordinatorsByCountry: [],
        RequestedClientNumbers: [],
        RequestedFeatures: [],
        IsDeclined: false,
        RejectionMessage: null,
        ChangedProperties: []
    },
    ValidationMessages: {}
};

const afterGetVerifyAccess = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.payload.model;
        draft.model.OriginalUser = draft.model.User;
    });
};

const afteSelectServiceCoordinator = (state, action) => {
    return produce(state, (draft) => {
        draft.model.CoordinatorsByCountry[action.payload.countryIndex].Coordinator = action.payload.coordinator;
        if (!draft.model.ChangedProperties.includes('CoordinatorsByCountry')) {
            draft.model.ChangedProperties.push('CoordinatorsByCountry');
        }
    });
};

const afterClearServiceCoordinator = (state, action) => {
    return produce(state, (draft) => {
        draft.model.CoordinatorsByCountry[action.payload.countryIndex].Coordinator = null;
        if (!draft.model.ChangedProperties.includes('CoordinatorsByCountry')) {
            draft.model.ChangedProperties.push('CoordinatorsByCountry');
        }
    });
};

const afterSelectSaleforceContactVA = (state, action) => {
    return produce(state, (draft) => {
        const expirationDate = draft.model.User.ExpirationDate;

        draft.model.User = action.payload.contact;
        draft.model.User.UserId = draft.model.OriginalUser.UserId;
        draft.model.User.Roles = draft.model.OriginalUser.Roles;
        draft.model.User.ExpirationDate = expirationDate;
        draft.model.UserChanged = true;
        draft.model.User.isSelected = true;

        if (!draft.model.ChangedProperties.includes('User.FirstName')) {
            draft.model.ChangedProperties.push('User.FirstName');
        }

        if (!draft.model.ChangedProperties.includes('User.LastName')) {
            draft.model.ChangedProperties.push('User.LastName');
        }

        if (!draft.model.ChangedProperties.includes('User.SalesforceId')) {
            draft.model.ChangedProperties.push('User.SalesforceId');
        }

        if (!draft.model.ChangedProperties.includes('User.Email')) {
            draft.model.ChangedProperties.push('User.Email');
        }
    });
};

const afterResetUserVA = (state) => {
    return produce(state, (draft) => {
        draft.model.User = draft.model.OriginalUser;
        draft.model.UserChanged = false;

        if (!draft.model.ChangedProperties.includes('User.Email')) {
            draft.model.ChangedProperties.push('User.Email');
        }
    });
};

const afterClearUserVA = (state) => {
    return produce(state, (draft) => {
        draft.model.User = draft.model.OriginalUser;
        draft.model.Email = '';

        if (!draft.model.ChangedProperties.includes('User.Email')) {
            draft.model.ChangedProperties.push('User.Email');
        }
        draft.model.UserChanged = false;
    });
};

const afterSetValidationMessages = (state, action) => {
    return produce(state, (draft) => {
        draft.ValidationMessages = action.payload.validationMessages;
    });
};

const afterChangeVerifyAccessRejectMsg = (state, action) => {
    return produce(state, (draft) => {
        draft.model.RejectionMessage = action.payload.rejectMsg;
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AFTER_GET_VERIFY_ACCESS:
            return afterGetVerifyAccess(state, action);
        case actionTypes.SELECT_SERVICE_COORDINATOR:
            return afteSelectServiceCoordinator(state, action);
        case actionTypes.CLEAR_SERVICE_COORDINATOR:
            return afterClearServiceCoordinator(state, action);
        case actionTypes.SELECT_SALEFORCE_CONTACT_VA:
            return afterSelectSaleforceContactVA(state, action);
        case actionTypes.RESET_USER_VA:
            return afterResetUserVA(state);
        case actionTypes.CLEAR_USER_VA:
            return afterClearUserVA(state);
        case actionTypes.VERIFY_ACCESS_SET_VALIDATION_MESSAGES:
            return afterSetValidationMessages(state, action);
        case actionTypes.CHANGE_VERIFY_ACCESS_REJECT_MSG:
            return afterChangeVerifyAccessRejectMsg(state, action);
        case actionTypes.RESET_STATE_VERIFY_ACCESS:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
