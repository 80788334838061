import { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';

class DeleteNoticeModal extends Component {
    render() {
        const modalTitle = this.props.translations.Phrases['Notices_Delete_Title_Label'];
        const NoticesTitle = this.props.data == null ? '' : this.props.data.Title;
        const NoticesDeleteText = this.props.translations.Phrases['Notices_Delete_Text'];

        return (
            <div className={this.props.isOpen ? 'modal modal-overlay d-block' : 'modal fade d-none'} role='dialog' aria-hidden='false'>
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='notices-modal-header'>
                            <div className='row'>
                                <div className='col-10'>
                                    <h3>{modalTitle}</h3>
                                </div>
                                <div className='col-2'>
                                    <i className='fa fa-close' onClick={() => this.props.onCloseDeleteNoticeModal()}></i>
                                </div>
                            </div>
                        </div>
                        <div className='modal-body left-align'>
                            <div className='row'>
                                <div className='col-12 modal-title-section'>{NoticesTitle}</div>
                                <div className='col-12 delete-modal-content'>{NoticesDeleteText}</div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <div className='row'>
                                <div className='col-12'>
                                    <button
                                        type='button'
                                        className='button white small'
                                        onClick={() => this.props.onCloseDeleteNoticeModal()}
                                    >
                                        {this.props.translations.Phrases['ManageNotices_Cancel']}
                                    </button>
                                    <button
                                        type='button'
                                        className='button blue small'
                                        onClick={() =>
                                            this.props.onDeleteNotice(
                                                this.props.data,
                                                this.props.index,
                                                this.props.translations.Phrases['NoticeDeleted_Msg']
                                            )
                                        }
                                    >
                                        {this.props.translations.Phrases['ManageNotices_Delete']}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.notices.selectedNotice,
        index: state.notices.selectedIndex,
        isOpen: state.notices.isDeleteModalOpen,
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseDeleteNoticeModal: () => dispatch(actionCreators.closeDeleteNoticeModal()),
        onDeleteNotice: (notice, index, successMessage) => {
            dispatch(actionCreators.deleteNotice(notice, index, successMessage));
            dispatch(actionCreators.closeDeleteNoticeModal());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteNoticeModal);
