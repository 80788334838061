import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './SpringboardCard.module.css';

interface SpringboardCardProps {
    url?: string;
    icon?: IconProp;
    title?: string;
    description?: string;
    className?: string;
}

const SpringboardCard: React.FC<SpringboardCardProps> = ({ url, icon, title, description, className = '' }) => {
    return (
        <div className={`card h-100 position-relative ${className}`}>
            <div className='card-body d-flex align-items-center' aria-hidden>
                <div className='d-flex align-items-center'>
                    {icon && (
                        <span className='badge badge-circular badge-circular-lg badge-info mr-3'>
                            <FontAwesomeIcon icon={icon} />
                        </span>
                    )}
                    <div>
                        {title && <h2 className='h5 mb-0'>{title}</h2>}
                        {description && <p>{description}</p>}
                    </div>
                </div>
            </div>
            {url && (
                <a
                    href={url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={`position-absolute rounded-lg ${classes.link}`}
                    aria-label={title}
                >
                    <span />
                </a>
            )}
        </div>
    );
};

export default SpringboardCard;
