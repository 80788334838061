import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { UpdateClientSelectionSettings } from '../../redux/actions/clientSelection';
import { ClientSelectionData } from '../models/ResponseData';

const useChangeUserCountry = () => {
    const dispatch = useDispatch();

    const updateClientSelectionCountry = useCallback(
        (clientSelection: ClientSelectionData['ClientServiceSelection'], country: 'us' | 'ca') => {
            if (clientSelection) {
                const isUnrestricted = clientSelection?.HasUnrestrictedAccess || clientSelection?.ServicesLimitReached || false;
                const clientServices = !isUnrestricted
                    ? clientSelection?.ClientServices
                    : clientSelection?.ClientServices.filter((c) => c.IsSelected);
                dispatch(
                    UpdateClientSelectionSettings({
                        ServicesLimitReached: clientSelection?.ServicesLimitReached,
                        MaxSelectedServicesPerCountry: clientSelection?.MaxSelectedServicesPerCountry,
                        HasUnrestrictedAccess: isUnrestricted,
                        Countries: [
                            { ...clientSelection.Countries[0], IsSelected: country === 'us' ? true : false },
                            { ...clientSelection.Countries[1], IsSelected: country === 'ca' ? true : false }
                        ],
                        ClientServices: clientServices
                    })
                );
            }
        },
        [dispatch]
    );

    return { updateClientSelectionCountry };
};

export default useChangeUserCountry;
