import { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import ClientServicesRow from './ClientServicesRow';
import FeatureSelectorModal from './FeatureSelectorModal';
import ParentServiceHeader from './ParentServiceHeader';

class ClientServicesGrid extends Component {
    removeParentService = () => {
        this.props.removeParentService(this.props.countryIndex, this.props.parentIndex, this.props.area);
        this.props.oboSetValidationMessages({});
    };

    render() {
        const clientServices = this.props.parentService.ClientServices.map((s, index) => {
            if (s.IsHidden) {
                return '';
            }

            const clientServiceReadonly =
                this.props.isRoleDropdownChanged ||
                this.props.readonly ||
                s.IsReadonly ||
                ((s.ClientAdminId > 0 || s.PendingClientAdminId > 0) && !this.props.pendingUser.InternalUser);

            const clientServicePaymentReadonly =
                this.props.isRoleDropdownChanged || s.PaymentAdminId > 0 || s.PendingPaymentAdminId > 0 || !s.IsPaymentEligible;

            return (
                <ClientServicesRow
                    key={index}
                    pendingUser={this.props.pendingUser}
                    clientService={s}
                    availableFeatures={this.props.parentService.AvailableFeatures}
                    clientIndex={index}
                    parentIndex={this.props.parentIndex}
                    countryIndex={this.props.countryIndex}
                    readonly={clientServiceReadonly}
                    paymentReadOnly={clientServicePaymentReadonly}
                    area={this.props.area}
                    userRoles={this.props.userRoles}
                    selectedClients={this.props.selectedClients}
                />
            );
        });

        const parentControls =
            this.props.readonly || this.props.isRoleDropdownChanged ? (
                ''
            ) : (
                <span className='pull-right'>
                    <FeatureSelectorModal
                        countryIndex={this.props.countryIndex}
                        parentIndex={this.props.parentIndex}
                        availableFeatures={this.props.parentService.AvailableFeatures}
                        area={this.props.area}
                    />
                    <span onClick={this.removeParentService} className='btn btn-link'>
                        <i className='text-danger fa fa-times-circle'></i> Remove All Clients
                    </span>
                </span>
            );
        return (
            <div>
                <br />
                <br />
                <div>
                    <h4 className='pull-left bold'>
                        {this.props.parentService.Number} - {this.props.parentService.Name}
                    </h4>
                    {parentControls}
                </div>
                <table className='table table-striped'>
                    <ParentServiceHeader
                        parentService={this.props.parentService}
                        parentIndex={this.props.parentIndex}
                        countryIndex={this.props.countryIndex}
                        readonly={this.props.readonly}
                        area={this.props.area}
                        userRoles={this.props.userRoles}
                        pendingUser={this.props.pendingUser}
                        isRoleDropdownChanged={this.props.isRoleDropdownChanged}
                        selectedClients={this.props.selectedClients}
                    />
                    <tbody>{clientServices}</tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        removeParentService: (countryIndex, parentIndex, area) =>
            dispatch(actionCreators.removeParentService(countryIndex, parentIndex, area)),
        oboSetValidationMessages: (validationMessages) => dispatch(actionCreators.oboSetValidationMessages(validationMessages))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientServicesGrid);
