import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import Button from '../../../../../common/features/Button';
import ModalWrapper from '../../../../../common/features/Wrappers/ModalWrapper';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { TEAM_SEARCH_PARAM_KEYS } from '../../../common/constants/constants-team';
import { useTeamMemberDetailsQuery } from '../../../redux/api/teamApi';

interface ActiveModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ActiveModal({ isOpen, setIsOpen }: ActiveModalProps) {
    const [searchParams] = useSearchParams();
    const userId = parseInt(searchParams.get(TEAM_SEARCH_PARAM_KEYS.userId) || '');

    const { data } = useTeamMemberDetailsQuery(userId.toString());

    const pendingFeatures = useMemo(
        () =>
            data?.ParentServices?.reduce<string[]>((acc, curr) => {
                const clientServices = curr.ClientServices;

                for (const clientService of clientServices) {
                    const features = clientService.Features;

                    for (const feature of features) {
                        const featureName = feature.Name == null ? '' : feature.Name;
                        if (
                            featureName !== '' &&
                            featureName.toLowerCase() !== 'my livingston portal' &&
                            featureName.toLowerCase() !== 'clearances' &&
                            feature.IsPending &&
                            !acc.includes(featureName)
                        ) {
                            acc.push(featureName);
                        }
                    }
                }

                return acc;
            }, []),
        [data?.ParentServices]
    );

    const translate = useTranslation();

    const handleClose = () => {
        setIsOpen(false);
    };

    if (!isOpen) return null;

    return (
        <ModalWrapper onEscape={handleClose}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content'>
                    <div className='modal-header '>
                        <h3 className='modal-title'>{translate('FeatureSetupInProgress_Label')}</h3>
                        <button
                            type='button'
                            className='modal-close btn btn-tertiary btn-lg'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={handleClose}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    <div className='modal-body'>
                        <p className='note mb-3 notification-message'>{translate('FeatureSetupInProgressReason_Label')}</p>
                        <ul>
                            {pendingFeatures?.map((feature) => (
                                <li key={feature}>{feature}</li>
                            ))}
                        </ul>
                    </div>
                    <div className='modal-footer'>
                        <Button variant='secondary' size='medium' onClick={handleClose}>
                            {translate('Close_Label')}
                        </Button>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
}
