import { createGenericStatusFilterSlice } from '../../../../redux/reducers/genericStatusFilterSlice';
import { StatusFilterOptions } from '../../common/constants/constants-subscribers';

const subscribersStatusFilterSlice = createGenericStatusFilterSlice({
    name: 'subscribersStatusFilter',
    initialState: { statuses: StatusFilterOptions, selectedStatus: StatusFilterOptions[0] },
    reducers: {}
});

export const { setSelectedStatus } = subscribersStatusFilterSlice.actions;

export default subscribersStatusFilterSlice.reducer;
