import React from 'react';

interface GridHeaderProps {
    children?: React.ReactNode;
    columnsDropdown?: React.ReactNode;
}

const GridHeader = React.forwardRef<HTMLDivElement, GridHeaderProps>(({ children, columnsDropdown }, ref) => {
    return (
        <div ref={ref} className='header-strip-small bg-grey-200 py-2'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col d-flex'>
                        <div className='d-flex justify-content-between align-items-center'>{children}</div>
                    </div>
                    {columnsDropdown && columnsDropdown}
                </div>
            </div>
        </div>
    );
});

export default GridHeader;
