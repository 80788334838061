import React, { ComponentPropsWithoutRef } from 'react';

const SIZES = {
    standard: '',
    small: 'spinner-border-sm'
} as const;

const COLORS = {
    blue: 'text-primary',
    white: 'text-light',
    inherit: ''
} as const;

interface SpinnerProps extends Pick<ComponentPropsWithoutRef<'span'>, 'style' | 'className'> {
    size?: keyof typeof SIZES;
    color?: keyof typeof COLORS;
}

const Spinner: React.FC<SpinnerProps> = ({ size = 'standard', color = 'blue', style, className = '' }) => {
    return (
        <span
            className={`spinner-border ${SIZES[size]} ${COLORS[color]} ${className}`}
            role='status'
            style={{ ...(style || {}), color: color === 'inherit' ? 'inherit' : 'unset' }}
        >
            <span className='sr-only'>Loading...</span>
        </span>
    );
};

export default Spinner;
