import axios from 'axios';
import { teamManagement, webAPIbaseURL } from './constants/mlp-constants';

let signal = axios.CancelToken.source();

const areRequestsCanceled = (err) => {
    return err && axios.isCancel(err);
};

const cancelRequests = () => {
    signal.cancel({ isCanceled: true, message: 'Request is canceled!' });
    signal = axios.CancelToken.source();
};

const instance = axios.create(
    process.env.REACT_APP_CENTRAL_DEV === 'TRUE'
        ? {}
        : {
              baseURL: webAPIbaseURL
          }
);

instance.interceptors.request.use(
    (config) => {
        config.cancelToken = signal.token;

        const token = localStorage.getItem('token');
        config.headers = {
            Authorization: `Bearer ${token}`
        };
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (process.env.REACT_APP_CENTRAL_DEV === 'TRUE' || process.env.REACT_APP_DCP_DEV === 'TRUE') {
            return;
        }
        if (error.message) {
            const { message } = error.message;
            if (message !== undefined && message === 'Request is canceled!') {
                console.log('Request is cancelled!');
                return;
            }
        }
         if (
            error.response.status === 403 &&
            !error.response.request.responseURL.includes(teamManagement.GET_SALESFORCE_CONTACTS_LINK)
        ) {
            window.location.href = window.location.protocol + '//' + document.location.host + '/Error';
        } else if (error.response.status === 401) {
            window.location.replace(error.response.data);
        } else if (!error.response && error.message === 'Network Error') {
            window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
        }
        return Promise.reject(error);
    }
);

export { areRequestsCanceled, cancelRequests };
export default instance;
