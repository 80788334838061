import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ChangeEvent } from 'react';
import React from 'react';
import type { ButtonProps } from './Button';
import Button from './Button';

interface BasicSearchBoxProps {
    handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    handleSearch: () => void;
    onClearInputValue: () => void;
    buttonVariant: ButtonProps['variant'];
    buttonText: string;
    inputPlaceHolder: string;
    showClear: boolean;
    leftRightPadding?: boolean;
}

const BasicSearchBox = React.forwardRef<HTMLInputElement, BasicSearchBoxProps>(
    (
        {
            handleInputChange,
            handleKeyDown,
            handleSearch,
            onClearInputValue,
            buttonVariant,
            buttonText,
            inputPlaceHolder,
            showClear,
            leftRightPadding = true
        },
        ref
    ) => {
        return (
            <div
                className={`input-group pb-3 pt-3 ${
                    leftRightPadding ? 'pl-3 pr-3' : 'pl-0 pr-0'
                } container-fluid border-bottom flex-nowrap bg-white`}
            >
                <div className='simplified-search-bar simplified-search-bar-with-button'>
                    <div className='chips-input-wrapper' style={{ overflow: 'unset' }}>
                        <div className='magnifying-glass-input-icon'>
                            <FontAwesomeIcon icon={faSearch} />
                        </div>
                        <div className='chips'></div>
                        <input
                            ref={ref}
                            type='text'
                            className='main-desktop-input form-control search-input'
                            placeholder={inputPlaceHolder}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                        />
                        <div className='input-group-append'>
                            <span className={`main-desktop-form-clear form-clear ${showClear ? '' : 'd-none'}`} onClick={onClearInputValue}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </span>
                        </div>
                        <div className='position-relative'>
                            <span className='main-desktop-form-clear form-clear d-none'>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </span>
                        </div>
                    </div>
                </div>
                <div className='input-group-append'>
                    <Button type='button' variant={buttonVariant} onClick={handleSearch}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        );
    }
);

export default BasicSearchBox;
