import { FunctionComponent } from 'react';
import { CardProps } from './Card.types';
import { Body } from './CardParts/Body';
import { Button } from './CardParts/Button';
import { Footer } from './CardParts/Footer';
import { Header } from './CardParts/Header';
import { BodyProps } from './Models/Body.types';
import { ButtonProps } from './Models/Button.types';
import { FooterProps } from './Models/Footer.types';
import { HeaderProps } from './Models/Header.Types';

interface CardType extends FunctionComponent<CardProps> {
    Header: FunctionComponent<HeaderProps>;
    Body: FunctionComponent<BodyProps>;
    Footer: FunctionComponent<FooterProps>;
    Button: FunctionComponent<ButtonProps>;
}

const Card: CardType = ({ children, className = '' }) => {
    return <div className={`card ${className}`}>{children}</div>;
};

Card.Footer = Footer;
Card.Header = Header;
Card.Body = Body;
Card.Button = Button;

export default Card;
