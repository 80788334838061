import produce from 'immer';
import * as actionTypes from '../actions/manageUser/manageUserActions';

const initialState = {
    model: {
        TeamMembers: [],
        User: {},
        TeamName: '',
        Company: '',
        ChangedProperties: []
    },

    ValidationMessages: []
};

const setManageUser = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.model;
        if (action.ValidationMessages) {
            draft.ValidationMessages = action.ValidationMessages;
        }
    });
};

const modelChanged = (state, action) => {
    return produce(state, (draft) => {
        const keySlice = action.payload.key.split('.');
        for (let prop in draft.model.User) {
            if (Object.prototype.hasOwnProperty.call(draft.model.User, prop)) {
                if (prop === keySlice[1]) {
                    draft.model.User[prop] = action.payload.value;
                    if (!draft.model.ChangedProperties.includes(action.payload.key)) {
                        draft.model.ChangedProperties.push(action.payload.key);
                    }
                }
            }
        }
        draft.error = false;
    });
};

const selectManageUser = (state, action) => {
    return produce(state, (draft) => {
        draft.model.User.FirstName = action.payload.user.FirstName;
        draft.model.User.LastName = action.payload.user.LastName;
        draft.model.User.FullName = action.payload.user.FullName;
        draft.model.User.Email = action.payload.user.Email;
        draft.model.User.SalesforceId = action.payload.user.SalesforceId;

        if (!draft.model.ChangedProperties.includes('User.FirstName')) {
            draft.model.ChangedProperties.push('User.FirstName');
        }
        if (!draft.model.ChangedProperties.includes('User.LastName')) {
            draft.model.ChangedProperties.push('User.LastName');
        }
        if (!draft.model.ChangedProperties.includes('User.FullName')) {
            draft.model.ChangedProperties.push('User.FullName');
        }
        if (!draft.model.ChangedProperties.includes('User.Email')) {
            draft.model.ChangedProperties.push('User.Email');
        }
        if (!draft.model.ChangedProperties.includes('User.SalesforceId')) {
            draft.model.ChangedProperties.push('User.SalesforceId');
        }
    });
};

const setValidationMessages = (state, action) => {
    return produce(state, (draft) => {
        draft.ValidationMessages = action.payload.ValidationMessages;
    });
};

const emailChanged = (state, action) => {
    return produce(state, (draft) => {
        draft.model.User.Email = action.payload.Email;
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MANAGE_USER:
            return setManageUser(state, action);
        case actionTypes.SELECT_MANAGE_USER:
            return selectManageUser(state, action);
        case actionTypes.MODEL_CHANGED:
            return modelChanged(state, action);
        case actionTypes.EMAIL_CHANGED:
            return emailChanged(state, action);
        case actionTypes.MANAGE_USER_SET_VALIDATION_MESSAGES:
            return setValidationMessages(state, action);
        case actionTypes.RESET_STATE_MANAGE_USER:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
