import { useMemo } from 'react';
import { useEdmClientServicesQuery } from '../../redux/api/teamApi';
import type { TeamMemberData } from '../models';

export const useMappedParentServices = (parentServices: TeamMemberData['ParentServices']) => {
    const clientServiceNumbers = parentServices?.flatMap((parentService) =>
        parentService.ClientServices.map((clientService) => clientService.Number)
    ) || [null];
    const { data: edmClientServicesData, isLoading } = useEdmClientServicesQuery(clientServiceNumbers, {
        skip: clientServiceNumbers.length === 1 && clientServiceNumbers[0] === null
    });
    const data = useMemo(
        () =>
            parentServices?.map((parentService) => {
                return {
                    ...parentService,
                    ClientServices: parentService.ClientServices.map((clientService) => ({
                        ...clientService,
                        StatusCodeName: clientService.Number ? edmClientServicesData?.[clientService.Number] : undefined
                    }))
                };
            }),
        [edmClientServicesData, parentServices]
    );

    return { data, isLoading };
};
