import PropTypes from 'prop-types';
import React, { Component } from 'react';

class TeamManagementModal extends Component {
    static defaultProps = {
        isOpen: false
    };

    static propTypes = {
        isOpen: PropTypes.bool,
        onRequestClose: PropTypes.func,
        onUserClick: PropTypes.func,
        title: PropTypes.string,
        yesOption: PropTypes.string,
        noOption: PropTypes.string
    };

    deleteButton = () => {
        if (this.props.onUserClick) {
            this.props.onUserClick();
        }
        this.props.onRequestClose();
    };

    render() {
        return (
            <div className={this.props.isOpen ? 'modal modal-overlay d-block' : 'modal fade d-none'} role='dialog' aria-hidden='false'>
                <div className='modal-dialog delete-modal'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h4 className='modal-title text-left'>{this.props.title}</h4>
                        </div>
                        <div className='modal-body text-left'>
                            <div className='text-left'>{this.props.children}</div>
                            <div className='text-right'>
                                <button className='btn btn-primary' type='button' onClick={this.deleteButton} data-dismiss='modal'>
                                    {this.props.yesOption}
                                </button>
                                <span className='btn btn-link no-option' onClick={this.props.onRequestClose}>
                                    {this.props.noOption}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamManagementModal;
