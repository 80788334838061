import PropTypes from 'prop-types';
import { Component } from 'react';

class MoreDetailsIcon extends Component {
    static defaultProps = {
        faIcon: 'fa-info-circle',
        items: [],
        isDangerIcon: false
    };

    static propTypes = {
        faIcon: PropTypes.string,
        items: PropTypes.array,
        isDangerIcon: PropTypes.bool
    };

    renderClassName(element) {
        switch (element) {
            case 'icon':
                let iconClassBase = `tooltips fa ${this.props.faIcon}`;
                return this.props.isDangerIcon ? `${iconClassBase} ${this.props.faIcon}--danger` : iconClassBase;
            case 'container':
                let containerClassBase = `tooltip-container`;
                return this.props.isDangerIcon ? `${containerClassBase} ${containerClassBase}--danger` : containerClassBase;
            default:
                return;
        }
    }

    render() {
        const tooltipItems = this.props.items.map((item, i) => {
            return (
                <div key={i}>
                    <span className='tooltip-label'>{item.label}</span>
                    <span className='tooltip-value'>{item.value}</span>
                </div>
            );
        });

        return (
            <i className={this.renderClassName(`icon`)}>
                <div className={this.renderClassName('container')}>{tooltipItems}</div>
            </i>
        );
    }
}

export default MoreDetailsIcon;
