export const TOGGLE_COUNTRY_SERVICES = 'TOGGLE_COUNTRY_SERVICES';
export const REMOVE_PARENT_SERVICE = 'REMOVE_PARENT_SERVICE';
export const UPDATE_FEATURE = 'UPDATE_FEATURE';
export const REMOVE_CLIENT_SERVICE = 'REMOVE_CLIENT_SERVICE';
export const AFTER_SHOW_TEAM_MEMBERS = 'AFTER_SHOW_TEAM_MEMBERS';
export const REMOVE_FEATURE = 'REMOVE_FEATURE';
export const ADD_FEATURE = 'ADD_FEATURE';
export const OBO_EXPIRATION_DATE_CHANGED = 'OBO_EXPIRATION_DATE_CHANGED';
export const SELECT_ALL_FEATURES_CA = 'SELECT_ALL_FEATURES_CA';
export const CLOSE_TEAM_MEMBERS_MODAL_OBO = 'CLOSE_TEAM_MEMBERS_MODAL_OBO';
export const AFTER_ADD_CLIENT_SERVICE = 'AFTER_ADD_CLIENT_SERVICE';
export const AFTER_GET_ON_BEHALF_OF = 'AFTER_GET_ON_BEHALF_OF';
export const SELECT_SALESFORCE_CONTACT = 'SELECT_SALESFORCE_CONTACT';
export const RESET_USER = 'RESET_USER';
export const UPDATE_CLIENT_SERVICES_BY_COUNTRY = 'UPDATE_CLIENT_SERVICES_BY_COUNTRY';
export const OBO_CHANGE_USER_ROLE = 'OBO_CHANGE_USER_ROLE';
export const OBO_SET_VALIDATION_MESSAGES = 'OBO_SET_VALIDATION_MESSAGES';

export const AM_TOGGLE_COUNTRY_SERVICES = 'AM_TOGGLE_COUNTRY_SERVICES';
export const AM_REMOVE_PARENT_SERVICE = 'AM_REMOVE_PARENT_SERVICE';
export const AM_UPDATE_FEATURE = 'AM_UPDATE_FEATURE';
export const AM_REMOVE_CLIENT_SERVICE = 'AM_REMOVE_CLIENT_SERVICE';
export const AM_REMOVE_FEATURE = 'AM_REMOVE_FEATURE';
export const AM_ADD_FEATURE = 'AM_ADD_FEATURE';
export const AM_SELECT_ALL_FEATURES_CA = 'AM_SELECT_ALL_FEATURES_CA';
export const AM_AFTER_SHOW_TEAM_MEMBERS = 'AM_AFTER_SHOW_TEAM_MEMBERS';
export const CHANGE_ON_BEHALF_OF_REJECT_MSG = 'CHANGE_ON_BEHALF_OF_REJECT_MSG';
export const RESET_STATE_ON_BEHALF_OF = 'RESET_STATE_ON_BEHALF_OF';
export const UPDATE_NOTE_FIELD = 'UPDATE_NOTE_FIELD';
