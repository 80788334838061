import DOMPurify from 'dompurify';
import React from 'react';

const Notice = (props) => {
    return (
        <div className='notice'>
            <div className='notice-content'>
                <div className='notice-title effectiveDate-pull-right'>
                    {props.notice.EffectiveDatePartFrom}&nbsp;{props.notice.EffectiveTimePartFrom}
                    &nbsp;{props.notice.EffectiveTimePartFromAmPm}
                </div>
                <div className='notice-title largerText'>{props.notice.Title}</div>
                <div className='notice-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.notice.Content) }} />
            </div>
        </div>
    );
};

export default Notice;
