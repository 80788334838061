import $ from 'jquery';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import toastr from 'toastr';
import axios from '../../axios-mlp';
import ModalConfirm from '../../common/Modal/ModalConfirm';
import TextArea from '../../common/Textarea/Textarea';
import { setValueByPath } from '../../utils/mlp-utils';

class RejectModal extends Component {
    state = {
        isOpen: false
    };

    reject = () => {
        $('#overlay').show();
        axios
            .post(this.props.action, this.props.model)
            .then((response) => {
                if (response.data === 'SuccessfulSubmit') {
                    this.props.history.push({
                        pathname: '/SuccessfullSubmit',
                        state: {
                            backToPath: this.props.onSuccessfulReject.backToPath,
                            backToLabel: this.props.onSuccessfulReject.backToLabel,
                            phraseGroup: this.props.onSuccessfulReject.phraseGroup
                        }
                    });
                } else {
                    //get validation messages
                    let validationMessages = {};
                    const json = JSON.parse(response.data);
                    for (let i in json) {
                        setValueByPath(validationMessages, i, json[i]);
                    }
                    this.props.postAction(validationMessages);
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data && error.response.data.Message) {
                        if (error.response.status === 400) {
                            toastr.warning(error.response.data.Message);
                        } else {
                            toastr.error(error.response.data.Message);
                        }
                    } else if (error.response.status !== 401 && error.response.status !== 403) {
                        this.props.history.push('/BasicError');
                    }
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };

    closeModal = () => {
        this.setState({ isOpen: false });
    };

    openModal = () => {
        this.setState({ isOpen: true });
    };

    clearMessage = () => {
        this.setState({ isOpen: false });
        this.props.onValueChanged('');
    };

    render() {
        const rejectionMessageValidation =
            this.props.translations === undefined || this.props.ValidationMessages === undefined
                ? ''
                : this.props.translations.Phrases[this.props.ValidationMessages.RejectionMessage];

        return (
            <span className='reject-modal'>
                <ModalConfirm
                    isOpen={this.state.isOpen}
                    onRequestConfirm={this.reject}
                    onRequestClose={this.closeModal}
                    customAction={this.clearMessage}
                    OkButtonText={this.props.okButtonText}
                    title={this.props.title}
                >
                    <div className='row'>
                        <div className='col-sm-12'>
                            <TextArea
                                rows={5}
                                label={this.props.label}
                                name={this.props.name}
                                value={this.props.msg}
                                valMsg={rejectionMessageValidation}
                                onValueChanged={this.props.onValueChanged}
                            />
                        </div>
                    </div>
                </ModalConfirm>
                <span className='btn btn-link' onClick={this.openModal}>
                    {this.props.btnText}
                </span>
            </span>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default withRouter(connect(mapStateToProps)(RejectModal));
