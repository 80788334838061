import React, { useEffect } from 'react';

interface useScrollHandlerProps {
    scrollElement: HTMLElement;
    targetElementRef: React.RefObject<HTMLDivElement>;
    returnTopPosition: (payload: number) => void;
}
// Calculate Top position of an element depending on a scroll position
const useCalculateTopPositionWithScroll = ({ targetElementRef, scrollElement, returnTopPosition }: useScrollHandlerProps) => {
    useEffect(() => {
        if (!scrollElement) return;

        const top = targetElementRef?.current?.offsetTop;
        const handleOnScroll = () => {
            targetElementRef.current && scrollElement && top && returnTopPosition(top - scrollElement.scrollTop);
        };
        scrollElement.addEventListener('scroll', handleOnScroll);

        return () => {
            targetElementRef && scrollElement && top && returnTopPosition(top - scrollElement.scrollTop);
            scrollElement.removeEventListener('scroll', handleOnScroll);
        };
    }, [returnTopPosition, scrollElement, targetElementRef]);

    return { returnTopPosition };
};

export default useCalculateTopPositionWithScroll;
