import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Checked from '../../assets/images/checkbox-checked.png';
import Unchecked from '../../assets/images/checkbox-empty.png';
import ToggleIcon from './ToggleIcon';

class CheckIcon extends Component {
    static propTypes = {
        isChecked: PropTypes.bool
    };

    render() {
        return <ToggleIcon isOn={this.props.isChecked} imgClass='checkbox-icon' selectedIcon={Checked} unselectedIcon={Unchecked} />;
    }
}

export default CheckIcon;
