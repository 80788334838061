import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateFilterSlice } from '../../common/models/ReduxSlices';

const initialState: DateFilterSlice = {
    appliedDate: '',
    appliedType: '',
    date: '',
    type: '',
    dateDropdownLabel: '',
    endDateCa: '',
    startDateCa: '',
    isDateOpen: false
};
const dateFilterSlice = createSlice({
    name: 'dateFilterSlice',
    initialState,
    reducers: {
        setDate(state, action: PayloadAction<string>) {
            state.date = action.payload;
        },
        setType(state, action: PayloadAction<string>) {
            state.type = action.payload;
        },
        setDateDropdownLabel(state, action: PayloadAction<string>) {
            state.dateDropdownLabel = action.payload;
        },
        setStartDateCa(state, action: PayloadAction<string>) {
            state.startDateCa = action.payload;
        },
        setEndDateCa(state, action: PayloadAction<string>) {
            state.endDateCa = action.payload;
        },
        setAppliedDate(state, action: PayloadAction<string>) {
            state.appliedDate = action.payload;
        },
        setAppliedType(state, action: PayloadAction<string>) {
            state.appliedType = action.payload;
        },
        resetDateFilters(state) {
            state.date = '';
            state.type = '';
            state.endDateCa = '';
            state.startDateCa = '';
            state.dateDropdownLabel = 'Date_Label';
            state.appliedDate = '';
            state.appliedType = '';
        },
        updateIsDateOpen(state, action: PayloadAction<boolean>) {
            state.isDateOpen = action.payload;
        }
    }
});
export const {
    setDate,
    setType,
    setDateDropdownLabel,
    setEndDateCa,
    setStartDateCa,
    resetDateFilters,
    updateIsDateOpen,
    setAppliedDate,
    setAppliedType
} = dateFilterSlice.actions;

export default dateFilterSlice.reducer;
