import { PayloadAction } from '@reduxjs/toolkit';
import { ColumnSettings } from '../../../../common/models/GridPreferences';
import {
    createGenericGridPreferencesSlice,
    genericGridPreferencesInitialState
} from '../../../../redux/reducers/genericGridPreferencesSlice';
import { GetUserCADGridSetting, SaveUserCADGridSetting } from '../actions/completeEntryDataGridPreferences';

const completeEntryDataGridPreferencesSlice = createGenericGridPreferencesSlice({
    name: 'completeEntryDataGridPreferences',
    initialState: genericGridPreferencesInitialState,
    reducers: {
        updateColumnOrder(state, action: PayloadAction<ColumnSettings[]>) {
            state.allColumns = action.payload.map((item, i) => {
                return { ...item, Order: i + 1 };
            });
            state.visibleColumns = state.allColumns?.filter((item: ColumnSettings) => {
                if (item.IsVisible) {
                    return item;
                }
                return null;
            });
        },
        updateColumnOrderGrid(state, action: PayloadAction<ColumnSettings[]>) {
            state.allColumns = [...action.payload].filter((item: ColumnSettings) => {
                if (item.Code !== 'selected' && item.Code !== 'Actions') {
                    return item;
                }
                return null;
            });
            state.visibleColumns = state.allColumns?.filter((item: ColumnSettings) => {
                if (item.IsVisible) {
                    return item;
                }
                return null;
            });
        },
        updateColumnOrderSaveCompleted(state, action: PayloadAction<boolean>) {
            state.columnOrderSaveCompleted = action.payload;
        },
        updateCoulumnActivated(state, action: PayloadAction<boolean>) {
            state.coulumnActivated = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetUserCADGridSetting.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetUserCADGridSetting.fulfilled, (state, action) => {
                state.isLoading = false;
                state.allColumns = action.payload;
                state.visibleColumns = action.payload.filter((column) => column.IsVisible === true);
                state.columnsWidths = action.payload.map((column) => {
                    return { Code: column.Code, Width: column.Width };
                });
            })
            .addCase(GetUserCADGridSetting.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(SaveUserCADGridSetting.pending, (state, action) => {
                state.areColumnsLoading = true;
                state.error = undefined;
            })
            .addCase(SaveUserCADGridSetting.fulfilled, (state, action) => {
                state.areColumnsLoading = false;
                state.columnOrderSaveCompleted = action.payload;
            })
            .addCase(SaveUserCADGridSetting.rejected, (state, action) => {
                state.areColumnsLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const { updateColumnOrder, updateColumnOrderGrid, updateColumnOrderSaveCompleted, updateCoulumnActivated, resetGridPreferences } =
    completeEntryDataGridPreferencesSlice.actions;

export default completeEntryDataGridPreferencesSlice.reducer;
