import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { PortalRoutes } from '../../../../common/constants/constants-portal';
import ErrorPage from '../../../../common/features/ErrorPage';
import StatusFilter from '../../../../common/features/FilterBar/StatusFilter';
import EmptyState from '../../../../common/features/Grid/EmptyState';
import HeaderBar from '../../../../common/features/HeaderBar/HeaderBar';
import Spinner from '../../../../common/features/Spinner';
import { isFetchBaseQueryError } from '../../../../common/functions/utils';
import useTranslation from '../../../../common/hooks/useTranslation';
import { STATUS_FILTER_OPTIONS, TEAM_MEMBER_STATUS_LABELS } from '../../common/constants/constants-team';
import type { TransformedTeamMember } from '../../common/models';
import { useUserTeamQuery } from '../../redux/api/teamApi';
import { useTeamDispatch, useTeamSelector } from '../../redux/hooks';
import { setPageSelected } from '../../redux/reducers/teamGridSettingsSlice';
import { setSelectedStatus } from '../../redux/reducers/teamStatusFilterSlice';
import TeamSearchBox from './SearchBox/TeamSearchBox';
import TeamGridCaption from './TeamGridCaption';
import TeamsTable from './TeamsTable/TeamsTable';

// Filter team members by Status (All, Active, Inactive)
const filterTeamMembersByStatus = (teamMembers: TransformedTeamMember[], status: string) => {
    if (status.toUpperCase() === 'ACTIVE') {
        return teamMembers.filter((member) => member.IsActive);
    } else if (status.toUpperCase() === 'INACTIVE') {
        return teamMembers.filter((member) => !member.IsActive);
    } else {
        return teamMembers;
    }
};

// Filter team members by Search term (name or email)
const searchTeamMembers = (teamMembers: TransformedTeamMember[], searchTerm: string) => {
    if (!searchTerm.trim()) return teamMembers;

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    const matchesSearchTerm = (text: string | null | undefined): boolean => text?.toLowerCase().includes(lowerCaseSearchTerm) ?? false;

    return teamMembers.filter(
        (member) => matchesSearchTerm(member.FullName) || matchesSearchTerm(member.Email) || matchesSearchTerm(member.statusTranslated)
    );
};

const TeamGrid = () => {
    const { data: team, isLoading, isFetching, error } = useUserTeamQuery();

    const { searchTerm } = useTeamSelector((state) => state.teamSearchFilter);
    const { selectedStatus } = useTeamSelector((state) => state.teamStatusFilter);

    const translate = useTranslation();
    const dispatch = useTeamDispatch();
    const history = useHistory();

    const transformedTeamMembers = useMemo(
        () =>
            team?.TeamMembers?.map((member) => ({
                ...member,
                statusTranslated: member.Status ? translate(TEAM_MEMBER_STATUS_LABELS[member.Status]) : '',
                LastLogin: member.Status !== 'PendingFeatureSetup' && member.Status !== 'PendingUserActivation' ? member.LastLogin : null
            })),
        [team?.TeamMembers, translate]
    );

    const filteredTeam = useMemo(() => {
        if (!transformedTeamMembers?.length) return [];

        let filteredTeamMembers = searchTeamMembers(transformedTeamMembers, searchTerm);
        filteredTeamMembers = filterTeamMembersByStatus(filteredTeamMembers, selectedStatus.id);

        return filteredTeamMembers;
    }, [searchTerm, selectedStatus.id, transformedTeamMembers]);

    const handleStatusChange = (value: number) => {
        dispatch(setSelectedStatus(value));
        dispatch(setPageSelected(1));
    };

    return (
        <div className='d-flex flex-column flex-fill'>
            {/* Header bar */}
            <HeaderBar>
                <h1>{translate('Team_Label')}</h1>
            </HeaderBar>
            {/* Filter bar */}
            <div className='d-flex flex-column bg-white'>
                <div className='container-fluid pb-3 border-bottom'>
                    <TeamSearchBox />
                </div>
                <div className='px-md-2 py-md-1 border-bottom'>
                    <StatusFilter
                        id='status-filters'
                        options={STATUS_FILTER_OPTIONS}
                        selectedStatus={selectedStatus}
                        onChange={handleStatusChange}
                    />
                </div>
            </div>
            {isLoading && (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    <Spinner />
                </div>
            )}
            {!isLoading && team && team.TeamMembers && team.TeamMembers.length > 0 && (
                <>
                    <TeamGridCaption />
                    <TeamsTable teamMembers={filteredTeam} userTeam={team} isFetching={isFetching} />
                </>
            )}
            {/* No team members */}
            {!isLoading && team?.TeamMembers?.length === 0 && (
                <EmptyState
                    descriptionLabel={translate('NoTeamMembers_Label')}
                    addButtonLabel={translate('AddUser_Label')}
                    onAdd={() => history.push(`${PortalRoutes.team}/CreateNewUser`)}
                />
            )}
            {/* Status: rejected */}
            {error && isFetchBaseQueryError(error) && <ErrorPage errorCode={error.status} withTranslations />}
        </div>
    );
};

export default TeamGrid;
