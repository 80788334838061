import produce from 'immer';
import * as actionTypes from '../actions/clientAdmin/featureSetupActionTypes';

const initialState = {
    model: {
        EmailConfirmationStatus: '',
        Redirect: false,
        RequestId: '',
        UserId: '',
        UserName: '',
        FeatureUsername: '',
        Feature: {
            ParentName: '',
            FeatureUserName: ''
        },
        ClientServiceNumbers: [],
        IsTeamMemberEdit: false,
        ChangedProperties: []
    },
    validationMessages: {}
};

const afterGetFeatureSetup = (state, action) => {
    return produce(state, (draft) => {
        if (action.payload.model === 'SuccessfulSubmit') {
            draft.model.Redirect = true;
        } else {
            draft.model.Redirect = false;
            draft.model = action.payload.model;
            if (!action.payload.model.FeatureUsername) draft.model.FeatureUsername = '';
        }
    });
};

const afterEmailConfirmation = (state, action) => {
    return produce(state, (draft) => {
        draft.model.EmailConfirmationStatus = action.payload.model.Status;
    });
};

const changeFeatureUsername = (state, action) => {
    return produce(state, (draft) => {
        draft.model.FeatureUsername = action.payload.value ? action.payload.value : '';
        draft.model.ChangedProperties.push('FeatureUsername');
    });
};

const featureSetupSetValidationMessages = (state, action) => {
    return produce(state, (draft) => {
        draft.validationMessages = action.payload.validationMessages;
    });
};

const resetStateFeatureSetup = (state) => {
    return produce(state, (draft) => {
        if (draft) {
            draft.model.EmailConfirmationStatus = '';
            draft.model.Redirect = false;
            draft.model.RequestId = '';
            draft.model.UserId = '';
            draft.model.UserName = '';
            draft.model.FeatureUsername = '';
            draft.model.Feature = {
                ParentName: '',
                FeatureUserName: ''
            };
            draft.model.ClientServiceNumbers = [];
            draft.model.IsTeamMemberEdit = false;
            draft.model.ChangedProperties = [];
            draft.validationMessages = {};
        }
    });
};

const resetEmailConfirmationState = (state) => {
    return produce(state, (draft) => {
        if (draft) {
            draft.model.EmailConfirmationStatus = '';
        }
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AFTER_GET_FEATURE_SETUP:
            return afterGetFeatureSetup(state, action);
        case actionTypes.FEATURE_SETUP_SET_VALIDATION_MESSAGES:
            return featureSetupSetValidationMessages(state, action);
        case actionTypes.CHANGE_FEATURE_USERNAME:
            return changeFeatureUsername(state, action);
        case actionTypes.AFTER_EMAIL_CONFIRMATION:
            return afterEmailConfirmation(state, action);
        case actionTypes.RESET_STATE_FEATURE_SETUP:
            return resetStateFeatureSetup(state);
        case actionTypes.RESET_EMAIL_CONFIRMATION_STATE:
            return resetEmailConfirmationState(state);
        default:
            return state;
    }
};

export default reducer;
