import 'jquery-mask-plugin';
import { Component } from 'react';

class TagControl extends Component {
    static defaultProps = {
        tags: []
    };

    onClick = (tag) => {
        this.props.onRemove(tag);
    };

    render() {
        const tags = this.props.tags.map((tag, i) => {
            const text = this.props.textField !== undefined ? tag[this.props.textField] : tag;
            const removeButton = this.props.onRemove ? (
                <i className='fa fa-times-circle text-danger' onClick={() => this.onClick(tag)}></i>
            ) : null;
            return (
                <span key={tag + i} className='tag'>
                    <span>{text}</span>
                    {removeButton}
                </span>
            );
        });

        return (
            <div className='tag-control'>
                <div className='input'>{tags}</div>
            </div>
        );
    }
}

export default TagControl;
