import $ from 'jquery';
import { Component, default as React } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import DatePickerModal from '../../../common/DateTimePicker/DatePickerModal';
import Dropdown from '../../../common/Dropdown/Dropdown';
import Searchbox from '../../../common/Searchbox/Searchbox';
import TagControl from '../../../common/TagControl/TagControl';
import * as actionCreators from '../../../store/actions/index';
import { formatTranslated } from '../../../utils/mlp-utils';
import UserInfo from '../UserInfo';
import OboSalesforceContactControl from './OboSalesforceContactControl';

class OnBehalfOfHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientServicesSearchBoxValue: '',
            selectedRoleValue: '',
            hasSelectedClient: false
        };
        this.selectedContact = this.selectedContact.bind(this);
    }
    searchTooltipRef = React.createRef();

    componentDidMount() {
        if (window.location.search) {
            const currentRole = this.props.viewModel?.User && this.props.viewModel.User.Roles.find((role) => role.IsSelected).Value;

            if (currentRole) {
                this.setState({ selectedRoleValue: currentRole, hasSelectedClient: true });
                this.props.setCurrentRole(currentRole);
            }
        }
    }

    componentDidUpdate(prevProps) {
        $(ReactDOM.findDOMNode(this.searchTooltipRef.current)).tooltip({
            title: formatTranslated('NumberDelimiter', this.props.translations.Phrases),
            placement: 'top'
        });
    }

    setSelectedRole(role) {
        this.setState({ selectedRoleValue: role }, () => this.props.setCurrentRole(this.state.selectedRoleValue));
    }

    selectedContact() {
        this.props.setCurrentRole(this.state.selectedRoleValue);
        this.setState({ hasSelectedClient: true });
    }

    addClientService = (param) => {
        this.props.setSelectedClientStatus({ number: param.Number, status: param.StatusCodeName });
        this.props.setCurrentRole(this.state.selectedRoleValue);
        param.IsSelected = true;
        this.props.addClientService(param, this.state.selectedRoleValue);
    };

    expirationDateChanged = (date) => {
        this.props.onExpirationDateChanged(date);
    };

    handleRoleChanged = (role) => {
        this.props.onRoleChange(role);
        this.props.oboSetValidationMessages({});
    };

    formatServiceResults = (i) => {
        const address = i.Address || '';
        const addressArray = address.split(',');
        let companyAddress = <div />;
        if (addressArray.length > 1) {
            companyAddress = (
                <div className='data-row'>
                    <div className='result-label'>Address:</div>
                    <div className='result-value'>
                        <div>{addressArray[0]}</div>
                        <div>
                            {addressArray[1]}, {addressArray[2]}
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className='search-results'>
                <div>
                    <strong>{i.Number}</strong>
                </div>
                <div className='data-row'>
                    <div className='result-label-large'> Coordinator: </div>
                    <div className='result-value-large'>{i.ServiceCoordinator} </div>
                    <input className='pull-right' type='checkbox' checked={i.IsSelected} />
                </div>
                <div className='data-row'>
                    <div className='result-label-large'> Company: </div>
                    <div className='result-value-large'> {i.Name} </div>
                </div>
                <div className='data-row'>
                    <div className='result-label-large'> Account: </div>
                    <div className='result-value-large'>{i.AccountName} </div>
                </div>
                <div className='data-row'>
                    <div className='result-label-large'> Status: </div>
                    <div className='result-value-large'>{i.StatusCodeName && i.StatusCodeName.length ? i.StatusCodeName : 'Active'} </div>
                </div>
                <div className='data-row'>
                    <div className='result-label-large'> Corp. Code: </div>
                    <div className='result-value-large'>{i.CorporateCodeName} </div>
                </div>
                {companyAddress}
            </div>
        );
    };

    clientServicesSearchBoxValueChanged = (val) => {
        this.setState({
            clientServicesSearchBoxValue: val
        });
    };

    render() {
        let parentNames = [];

        this.props.viewModel.ClientServicesByCountry.forEach((cs) => {
            if (
                this.props.viewModel.IsOnBehalfOf ||
                cs.CountryCode.toLowerCase() === this.props.viewModel.CoordinatorCountryCode.toLowerCase()
            ) {
                cs.ParentServices.forEach((p) => {
                    if (parentNames.indexOf(p.Number) < 0) {
                        parentNames.push(p.Number);
                    }
                });
            }
        });

        const salesforceUserControl = (
            <OboSalesforceContactControl
                viewModel={this.props.viewModel}
                validationMessages={this.props.validationMessages}
                disabled={this.state.selectedRoleValue === ''}
                selectedContact={this.selectedContact}
            />
        );

        const clientSearchBox = (
            <Searchbox
                label={this.props.translations.Phrases['RequestedClientNumbers_Label']}
                placeholder={this.props.translations.Phrases['TypeHereToSearch_Label']}
                name='ClientServicesByCountry'
                url={
                    '/ClientAdmin/GetEDMClientServices?country=' +
                    this.props.viewModel.CoordinatorCountryCode +
                    '&role=' +
                    this.state.selectedRoleValue
                }
                formatResults={this.formatServiceResults}
                value={this.state.clientServicesSearchBoxValue}
                onValueChanged={this.clientServicesSearchBoxValueChanged}
                minChars={5}
                titleField='Number'
                descriptionField='Name'
                clearWhenSearching={false}
                multiSelect='multiselect'
                deselect='deselect'
                onSelectItem={this.addClientService}
                disabled={this.state.selectedRoleValue === '' || !this.state.hasSelectedClient}
                preventDefault={true}
            >
                <TagControl tags={parentNames} />
            </Searchbox>
        );

        const userRole = (
            <Dropdown
                name={'User.Roles'}
                options={this.props.viewModel.User.Roles}
                label={this.props.translations.Phrases['UserRole_Label']}
                customAction={this.props.roleChanged}
                onItemChanged={this.handleRoleChanged}
                setSelectedRole={(e) => this.setSelectedRole(e)}
                preventDefault={true}
                valMsg={
                    this.state.selectedRoleValue === '' && this.state.hasSelectedClient
                        ? this.props.translations.Phrases['RoleRequired_ValidationMsg']
                        : null
                }
            />
        );

        const startDate = new Date();
        startDate.setDate(startDate.getDate() + 1);

        const expirationDatePicker = (
            <DatePickerModal
                startDate={startDate}
                name='User.ExpirationDate'
                linkText={this.props.translations.Phrases['SetAccessExpirationDate_Label']}
                dateLabel={this.props.translations.Phrases['ExpirationDate_Label']}
                date={this.props.viewModel.User.ExpirationDate}
                onDateChanged={this.expirationDateChanged}
                noBorder
                valMsg={
                    this.props.validationMessages.User
                        ? this.props.translations.Phrases[this.props.validationMessages.User.ExpirationDate]
                        : ''
                }
            />
        );

        if (this.props.viewModel.IsOnBehalfOf) {
            return (
                <div className='col-sm-12'>
                    <div className='row'>
                        <div className='col-sm-4'>{userRole}</div>
                        <div className='col-sm-4'>{salesforceUserControl}</div>
                        <div ref={this.searchTooltipRef} className='col-sm-4'>
                            {clientSearchBox}
                            {expirationDatePicker}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='row'>
                    <div className='col-sm-8'>
                        <UserInfo viewModel={this.props.viewModel} />
                    </div>
                    <div className='col-sm-4'>
                        {userRole}
                        {salesforceUserControl}
                        {clientSearchBox}
                        {expirationDatePicker}
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addClientService: (item, role) => dispatch(actionCreators.addClientService(item, role)),
        onExpirationDateChanged: (date) => dispatch(actionCreators.expirationDateChanged(date)),
        onRoleChange: (item) => dispatch(actionCreators.oboChangeUserRole(item)),
        oboSetValidationMessages: (validationMessages) => dispatch(actionCreators.oboSetValidationMessages(validationMessages))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OnBehalfOfHeader);
