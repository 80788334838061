import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { ReactNode } from 'react';
import Tooltip from '../../../../common/features/Tooltip/Tooltip';
import useTranslation from '../../../../common/hooks/useTranslation';
import { Contact } from '../../common/models/Contact';
import { ActionButton } from './ActionButton';

interface DataItem extends Contact {
    selected?: boolean;
    isNew?: boolean;
}

interface FormattedGridCellProps extends GridCellProps {
    dataItem: DataItem;
    isLocked: boolean;
    isAdmin: boolean;
    handleRemoveContact: (id?: null | number) => void;
    handleEditContact: (id?: null | number) => void;
}

const FormattedGridCell: React.FC<FormattedGridCellProps> = ({ field = '', isLocked, isAdmin, dataItem, selectionChange, ...rest }) => {
    const translate = useTranslation();

    let cellContent: ReactNode;

    switch (field) {
        case 'selected':
            cellContent = <Checkbox value={!!dataItem.selected} onChange={selectionChange} />;
            break;
        case 'FullName':
            cellContent = dataItem.FullName;
            break;
        case 'Email':
            cellContent = dataItem.Email;
            break;
        case 'MobileNumber':
            cellContent = dataItem.MobileNumber;
            break;
        case 'RoleLabel':
            cellContent = translate(dataItem.RoleLabel);
            break;
        case 'Language':
            cellContent = dataItem.Language !== 'fr' ? 'EN' : 'FR';
            break;
        case 'CarrierCodes':
            cellContent = dataItem.CarrierCodes;
            break;
        case 'CreatedByUsername':
            cellContent = dataItem.CreatedByUsername;
            break;
        case 'ModifiedByUsername':
            cellContent = dataItem.ModifiedByUsername;
            break;
        case 'TeamAdminUserFullName':
            const fieldValue = dataItem.TeamAdminUserFullName
                ? `${dataItem.TeamAdminUserFullName} - ${dataItem?.TeamAdminUserCompanyName}`
                : '';
            cellContent = fieldValue;
            break;
        case 'Actions':
            cellContent = <ActionButton dataItem={dataItem} {...rest} disabled={!isAdmin || dataItem?.selected! || dataItem?.isNew!} />;
            break;
        default:
            cellContent = null;
    }

    return (
        <td
            className={`${rest.className}
                 ${dataItem?.selected ? 'k-state-selected' : ''} 
                 ${dataItem?.isNew ? 'new-row-highlight' : ''} 
                 ${field === 'Actions' ? 'contacts-grid-actions-cell' : ''}`}
            style={rest.style}
        >
            {['CreatedByUsername', 'ModifiedByUsername', 'TeamAdminUserFullName', 'Email'].includes(field) &&
            typeof cellContent === 'string' ? (
                <Tooltip label={cellContent}>
                    <div className='text-truncate'>{cellContent}</div>
                </Tooltip>
            ) : (
                cellContent
            )}
        </td>
    );
};

export default FormattedGridCell;
