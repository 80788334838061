import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Badge from '../../../common/features/Badge';
import { createDateForAlert } from '../../../common/functions/parser';
import useTranslationHydration from '../../../common/hooks/useTranslationHydration';
import { usePortalSelector } from '../../../redux/hooks';
import { AgencyList } from '../../constants/constants-portal';
import classes from './AlertDrawerCard.module.css';

type AlertDrawerCardProps = (
    | {
          type: 'ON_HOLD' | 'IN_PROGRESS' | 'UNDER_REVIEW';
          entryNumber: string;
          agencyKey: string;
          customsInfoID: number;
          eventTypeKey: string;
          title?: undefined;
          description?: undefined;
          handleClose: () => void;
      }
    | {
          type: 'NOTICE';
          title: string;
          description: string;
          entryNumber?: undefined;
          agencyKey?: undefined;
          customsInfoID?: undefined;
          eventTypeKey?: undefined;
          handleClose?: undefined;
      }
) & { date: string };

const AlertDrawerCard: React.FC<AlertDrawerCardProps> = ({
    type,
    title,
    date,
    description = '',
    entryNumber,
    agencyKey,
    customsInfoID,
    eventTypeKey,
    handleClose
}) => {
    const { languageSelected } = usePortalSelector((state) => state.language);

    const translate = useTranslationHydration();

    const history = useHistory();

    const handleViewEntry = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        history.push(`/Clearances/${customsInfoID}`);

        if (handleClose) handleClose();
    };

    return (
        <div className='alert-card bg-white p-1 my-2'>
            <div className='card-content d-flex flex-column align-items-start p-3'>
                <header className='d-flex w-100 flex-fill justify-content-between'>
                    {/* Badge: Shipment on hold */}
                    {type === 'ON_HOLD' && (
                        <Badge className='mb-2' type='danger'>
                            <FontAwesomeIcon icon={faExclamationCircle} className='mr-1' />
                            {translate('ShipmentOnHold_Label')}
                        </Badge>
                    )}
                    {/* Badge: Shipment under review */}
                    {type === 'UNDER_REVIEW' && (
                        <Badge className='mb-2' type='warning'>
                            <FontAwesomeIcon icon={faInfoCircle} className='mr-1' />
                            {translate('ShipmentUnderReview_Label')}
                        </Badge>
                    )}
                    {/* Badge: In progress */}
                    {type === 'IN_PROGRESS' && (
                        <Badge className='mb-2' type='info'>
                            {translate('StatusInProgress_Label')}
                        </Badge>
                    )}
                    {/* Badge: Livingston notice */}
                    {type === 'NOTICE' && (
                        <Badge className='mb-2' type='neutral'>
                            {translate('LivingstonNoticeTitle_Label')}
                        </Badge>
                    )}
                    {/* Date */}
                    <p className={`alert-card-date m-0 position-relative mb-2 ml-3 ${classes.date}`}>
                        {createDateForAlert(date, languageSelected?.locale)}
                    </p>
                </header>
                {/* Title */}
                <p className='alert-card-title mb-2 pr-0'>
                    <strong>
                        {type === 'ON_HOLD'
                            ? /hold/i.test(eventTypeKey || '')
                                ? translate(
                                      'AlertsOnHold_Label',
                                      entryNumber,
                                      translate(AgencyList[agencyKey as keyof typeof AgencyList].label)
                                  )
                                : /rejected/i.test(eventTypeKey || '')
                                ? translate(
                                      'EntryReviewedRejected_Label',
                                      entryNumber,
                                      translate(AgencyList[agencyKey as keyof typeof AgencyList].label)
                                  )
                                : null
                            : null}
                        {type === 'UNDER_REVIEW' &&
                            translate(
                                'AlertsInProgress_Label',
                                entryNumber,
                                translate(AgencyList[agencyKey as keyof typeof AgencyList].label)
                            )}
                        {type === 'IN_PROGRESS' &&
                            translate(
                                'EntryReviewedAccepted_Label',
                                entryNumber,
                                translate(AgencyList[agencyKey as keyof typeof AgencyList].label)
                            )}
                        {type === 'NOTICE' && title}
                    </strong>
                </p>
                {/* Description */}
                {type === 'ON_HOLD' && (
                    <p className='alert-card-desc mb-0'>
                        {/customs/i.test(agencyKey || '')
                            ? translate('HoldAlert_Label')
                            : translate('ClickBelowAdditionalInformation_Label')}
                    </p>
                )}
                {(type === 'UNDER_REVIEW' || type === 'IN_PROGRESS') && (
                    <p className='alert-card-desc mb-0'>{translate('ClickBelowAdditionalInformation_Label')}</p>
                )}
                {type === 'NOTICE' && (
                    <p
                        className='alert-card-desc mb-0'
                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description.replace(/&nbsp;/g, ' ')) }}
                    />
                )}
                {/* View entry link */}
                {(type === 'ON_HOLD' || type === 'IN_PROGRESS' || type === 'UNDER_REVIEW') && (
                    <Link to={`/Clearances/${customsInfoID}`} className='btn btn-tertiary-blue mt-3' onClick={handleViewEntry}>
                        {translate('ViewEntry_Label')}
                    </Link>
                )}
            </div>
        </div>
    );
};

export default AlertDrawerCard;
