import { useEffect, useState } from 'react';

const useZoomDetect = () => {
    const [zoomLevel, setZoomLevel] = useState(window.devicePixelRatio);

    useEffect(() => {
        const handleResize = () => {
            setZoomLevel(window.devicePixelRatio);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return zoomLevel;
};
export default useZoomDetect;
