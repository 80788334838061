import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as actions from '../../store/actions/index';
import MenuSectionContainer from './MenuSectionContainer';

class Menu extends Component {
    componentDidMount() {
        this.props.onInitMenu();
    }

    getMenuSections() {
        return this.props.menuModel.MenuSections.map((section) => {
            return <MenuSectionContainer key={section.Title} section={section} notices={this.props.notices} />;
        });
    }

    render() {
        return <div>{this.getMenuSections()}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        menuModel: state.menu.model,
        notices: state.user.model.unreadNotices,
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitMenu: () => dispatch(actions.initMenu())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
