import $ from 'jquery';
import toastr from 'toastr';
import axios from '../../../axios-mlp';
import { createAmPmDates } from '../../../utils/dateFormater';
import * as actionTypes from './noticeActionTypes';

export const initNotices = () => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .get('/Notices/Index')
            .then((response) => {
                createAmPmDates(response.data.NoticesList);
                dispatch(setNotices(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const initManageNotices = () => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .get('/Notices/ManageNotices')
            .then((response) => {
                createAmPmDates(response.data.NoticesList);
                dispatch(setNotices(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const setNotices = (viewModel) => {
    return {
        type: actionTypes.SET_NOTICES,
        model: viewModel
    };
};

export const addNotice = (notice, index) => {
    return {
        type: actionTypes.ADD_NOTICE,
        payload: { notice: notice, index: index }
    };
};

export const editNotice = (notice, index) => {
    return {
        type: actionTypes.EDIT_NOTICE,
        payload: { notice: notice, index: index }
    };
};

export const closeManageNoticesArea = () => {
    return {
        type: actionTypes.CLOSE_MANAGE_NOTICES_AREA
    };
};

export const openDeleteNoticeModal = (notice, index) => {
    return {
        type: actionTypes.OPEN_DELETE_NOTICE_MODAL,
        payload: { notice: notice, index: index }
    };
};

export const closeDeleteNoticeModal = () => {
    return {
        type: actionTypes.CLOSE_DELETE_NOTICE_MODAL
    };
};

export const resetNoticesState = () => {
    return {
        type: actionTypes.RESET_NOTICES_STATE
    };
};

export const deleteNotice = (notice, index, successMessage) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .post('/Notices/DeleteNotice', notice)
            .then((response) => {
                dispatch(afterDeleteNotice(index));
                toastr.success(successMessage);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data && error.response.data.Message) {
                        if (error.response.status === 400) {
                            toastr.warning(error.response.data.Message);
                        } else {
                            toastr.error(error.response.data.Message);
                        }
                    } else if (error.response.status !== 401 && error.response.status !== 403) {
                        window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                    }
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const afterDeleteNotice = (index) => {
    return {
        type: actionTypes.DELETE_NOTICE,
        index: index
    };
};

export const openNoticePreview = (notice) => {
    return {
        type: actionTypes.OPEN_NOTICE_PREVIEW,
        payload: notice
    };
};

export const closeNoticePreview = () => {
    return {
        type: actionTypes.CLOSE_NOTICE_PREVIEW
    };
};

export const selectServiceProvider = (newIndex, oldIndex, newValue) => {
    return {
        type: actionTypes.SELECT_SERVICE_PROVIDER,
        payload: { newIndex: newIndex, oldIndex: oldIndex, newValue: newValue }
    };
};

export const selectNoticeType = (newIndex, oldIndex, newValue) => {
    return {
        type: actionTypes.SELECT_NOTICE_TYPE,
        payload: { newIndex: newIndex, oldIndex: oldIndex, newValue: newValue }
    };
};

export const selectLanguage = (newIndex, oldIndex, newValue) => {
    return {
        type: actionTypes.SELECT_LANGUAGE,
        payload: { newIndex: newIndex, oldIndex: oldIndex, newValue: newValue }
    };
};

export const changeEffectiveDatePartFrom = (date) => {
    return {
        type: actionTypes.CHANGE_EFFECTIVE_DATE_PART_FROM,
        date: date
    };
};

export const changeEffectiveDatePartTo = (date) => {
    return {
        type: actionTypes.CHANGE_EFFECTIVE_DATE_PART_TO,
        date: date
    };
};

export const changeEffectiveTimePartFrom = (time) => {
    return {
        type: actionTypes.CHANGE_EFFECTIVE_TIME_PART_FROM,
        time: time
    };
};

export const changeEffectiveTimePartTo = (time) => {
    return {
        type: actionTypes.CHANGE_EFFECTIVE_TIME_PART_TO,
        time: time
    };
};

export const changeEffectiveTimePartFromAmPm = (value) => {
    return {
        type: actionTypes.CHANGE_EFFECTIVE_TIME_PART_FROM_AM_PM,
        amPm: value
    };
};

export const changeEffectiveTimePartToAmPm = (value) => {
    return {
        type: actionTypes.CHANGE_EFFECTIVE_TIME_PART_TO_AM_PM,
        amPm: value
    };
};

export const changeTitle = (title) => {
    return {
        type: actionTypes.CHANGE_TITLE,
        title: title
    };
};

export const changeContent = (content) => {
    return {
        type: actionTypes.CHANGE_CONTENT,
        content: content
    };
};

export const saveNotice = (notice, successMessage) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .post('/Notices/SaveNotice', notice)
            .then((response) => {
                createAmPmDates(response.data);
                dispatch(afterSaveNotice(response.data));
                toastr.success(successMessage);
                dispatch(closeManageNoticesArea());
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data && error.response.data.Message) {
                        if (error.response.status === 400) {
                            toastr.warning(error.response.data.Message);
                        } else {
                            toastr.error(error.response.data.Message);
                        }
                    } else if (error.response.status !== 401 && error.response.status !== 403) {
                        window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                    }
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const afterSaveNotice = (notices) => {
    return {
        type: actionTypes.SAVE_NOTICE,
        notices: notices
    };
};
