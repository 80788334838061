import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { HydrationSlice } from '../../common/models/ReduxSlices';
import type { HydrationData } from '../../common/models/ResponseData';
import { ChangeLanguageHydration } from '../actions/translations';

const initialState: HydrationSlice = {
    user: null,
    isLoading: false,
    error: undefined,
    isTranslationsLoading: true,
    translationsError: undefined
};

const hydrationSlice = createSlice({
    name: 'hydration',
    initialState,
    reducers: {
        setUser(state, action: PayloadAction<HydrationData['Hydration']>) {
            state.user = action.payload;
        },
        setUserCountryCode(state, action: PayloadAction<string>) {
            if (state.user && action.payload) {
                state.user.CountryCode = action.payload;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(ChangeLanguageHydration.pending, (state, action) => {
                state.isTranslationsLoading = true;
                state.translationsError = undefined;
            })
            .addCase(ChangeLanguageHydration.fulfilled, (state, action) => {
                state.isTranslationsLoading = false;
                if (state.user?.Phrases) {
                    state.user.Phrases = action.payload.PhraseGroup.Phrases;
                }
            })
            .addCase(ChangeLanguageHydration.rejected, (state, action) => {
                state.isTranslationsLoading = false;
                if (typeof action.payload === 'number') {
                    state.translationsError = action.payload;
                } else {
                    state.translationsError = action.error.message;
                }
            });
    }
});

export const { setUserCountryCode, setUser } = hydrationSlice.actions;

export default hydrationSlice.reducer;
