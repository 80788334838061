import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface State {
    statusUserId: number;
    isActive: boolean;
    showStatusModal: boolean;
}

const initialState: State = {
    statusUserId: 0,
    isActive: false,
    showStatusModal: false
};

const statusUserSlice = createSlice({
    name: 'statusUser',
    initialState,
    reducers: {
        setUserId(state, action: PayloadAction<number>) {
            state.statusUserId = action.payload;
        },
        setIsActive(state, action: PayloadAction<boolean>) {
            state.isActive = action.payload;
        },
        openStatusModal: (state) => {
            state.showStatusModal = true;
        },
        closeStatusModal: (state) => {
            state.showStatusModal = false;
        }
    }
});

export const { setUserId, setIsActive, openStatusModal, closeStatusModal } = statusUserSlice.actions;
export default statusUserSlice.reducer;
