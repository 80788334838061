import type { ChangeEvent } from 'react';
import { usePortalDispatch } from '../../../../redux/hooks';
import { setClientSelectionCountry } from '../../../../redux/reducers/clientSelectionReducer';
import { REACT_APP_ASSETS_URL } from '../../../constants/constants-portal';
import useTranslation from '../../../hooks/useTranslation';
import type { ClientChooserRow } from '../ClientChooser';
import classes from './CountrySwitcher.module.css';

const COUNTRY_LABELS = {
    us: 'USA',
    ca: 'Canada'
};

interface CountrySwitcherProps {
    countries:
        | {
              Code: 'us' | 'ca';
              Name: 'United States' | 'Canada';
              IsSelected: boolean;
          }[]
        | undefined;
    selectedCountryCode: ClientChooserRow['country'] | undefined;
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

export default function CountrySwitcher({ countries, selectedCountryCode, setSearchQuery }: CountrySwitcherProps) {
    const translate = useTranslation();

    const dispatch = usePortalDispatch();

    const selectCountry = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setClientSelectionCountry(e.target.value));
        setSearchQuery('');
    };

    return (
        <fieldset className='flex-grow-0 mx-4 my-3'>
            <legend className='h5 mb-2'>{translate('SelectCountry_Label')}</legend>
            <div className='custom-toggle country-switcher d-flex flex-row justify-content-center'>
                <div className='switch w-100 mb-0'>
                    <div className={`slider h-100 position-relative round ${classes['before-disable']}`}>
                        <span className={`top-0 ${classes.active} ${classes[`active-${selectedCountryCode}`]}`} />
                        <div className='options d-flex'>
                            {countries?.map(({ Code, Name }) => {
                                return (
                                    <div key={Code} className='d-flex flex-1 justify-content-center align-items-center'>
                                        <label
                                            htmlFor={`country-${Code}`}
                                            className='label font-weight-bold d-flex flex-row align-items-center justify-content-center mb-0 cursor-pointer flex-1'
                                        >
                                            <div className='d-flex align-items-center'>
                                                <img
                                                    src={`${REACT_APP_ASSETS_URL}/assets/images/icons/flags/${Code}-flag.svg`}
                                                    alt={`${Name} flag`}
                                                    className='mr-2'
                                                    aria-hidden='true'
                                                />
                                                <span>{COUNTRY_LABELS[Code]}</span>
                                            </div>
                                        </label>
                                        <input
                                            type='radio'
                                            id={`country-${Code}`}
                                            name='country'
                                            onChange={selectCountry}
                                            defaultValue={Code}
                                            autoFocus={Code === selectedCountryCode}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    );
}
