import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../../../redux/api/apiSlice';
import alertsReducer from '../../../redux/reducers/alertsSlice';
import clientSelectionReducer from '../../../redux/reducers/clientSelectionReducer';
import hydrationReducer from '../../../redux/reducers/hydrationSlice';
import languageReducer from '../../../redux/reducers/languageSlice';
import toastReducer from '../../../redux/reducers/toastSlice';
import translationsReducer from '../../../redux/reducers/translationsSlice';
import contactsGridSettingsReducer from './reducers/contactsGridSettingsSlice';
import contactsSearchFilterReducer from './reducers/contactsSearchFilterSlice';
import contactsReducer from './reducers/contactsSlice';

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        // common slices
        hydration: hydrationReducer,
        translations: translationsReducer,
        language: languageReducer,
        alerts: alertsReducer,
        toast: toastReducer,
        clientSelection: clientSelectionReducer,

        // page specific slices
        contacts: contactsReducer,
        contactsGridSettings: contactsGridSettingsReducer,
        contactsSearchFilter: contactsSearchFilterReducer
    },
    middleware: (gDM) => gDM().concat(apiSlice.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
