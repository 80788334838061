import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef, useState } from 'react';
import Button from '../../../../../common/features/Button';
import { isAddContactFormValid } from '../../../../../common/functions/validators';
import useGTMEventTracking from '../../../../../common/hooks/useGTMEventTracking';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { ContactsModals } from '../../../../../common/models/ContactsModals';
import { Contact as ContactType } from '../../../common/models/Contact';
import { useContactsDispatch, useContactsSelector } from '../../../redux/hooks';
import { addNewContact, setFormIsSubmitted } from '../../../redux/reducers/contactsSlice';
import Contact from './Contact';

interface ContactInfoFormProps {
    handleSubmit: () => void;
    modal: ContactsModals;
    selectedContact?: any;
}

const ContactInfoForm: React.FC<ContactInfoFormProps> = ({ handleSubmit, modal, selectedContact }) => {
    const { newContacts, editContact } = useContactsSelector((state) => state.contacts);
    const { user } = useContactsSelector((state) => state.hydration);
    const [rowLimitError, setRowLimitError] = useState(false);
    const [displayCarrierLabel, setDisplayCarrierLabel] = useState(false);
    const translate = useTranslation();
    const dispatch = useContactsDispatch();
    const addContactsConfirmRef = useRef(null);
    useGTMEventTracking('Confirm_Contact_Team_CPEvt', { location: 'Profile' }, addContactsConfirmRef);
    const checkForCarriers = newContacts.some((x: ContactType) => {
        return x.RoleLabel.includes('Carrier') ? true : false;
    });

    useEffect(() => {
        if (newContacts.length! < 10) {
            setRowLimitError(false);
        }
    }, [newContacts.length]);

    const handleAddNewContact = () => {
        dispatch(setFormIsSubmitted({ value: true }));
        if (newContacts.length! < 10) {
            dispatch(addNewContact());
            dispatch(setFormIsSubmitted({ value: false }));
        } else {
            setRowLimitError(true);
        }
    };

    return (
        <>
            <div className='modal-body'>
                <form>
                    <div className='d-flex flex-column align-items-end overflow-auto'>
                        <div className='table-responsive' id='scrollTable'>
                            <table className='table w-100 add-subscriber input-row-overflow'>
                                <thead>
                                    <tr>
                                        {user?.InternalUser && <th scope='col'>{translate('AdminCompany_Label')}</th>}
                                        <th scope='col'>{translate('Name_Label')}</th>
                                        <th scope='col'>{translate('Email_Label')}</th>
                                        <th scope='col'>{translate('MobileNumber_Label')}</th>
                                        <th scope='col'>{translate('Role_Label')}</th>
                                        {displayCarrierLabel || checkForCarriers ? (
                                            <th scope='col'>{translate('CarrierCode_Label')}</th>
                                        ) : null}
                                        <th scope='col'>{translate('Language_Label')}</th>
                                        <th scope='col' className='row-action bg-white'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newContacts.map((contact) => (
                                        <Contact
                                            key={contact.RowId}
                                            id={contact.RowId}
                                            fullName={contact.FullName}
                                            email={contact.Email}
                                            phone={contact.MobileNumber ?? ''}
                                            role={contact.RoleLabel}
                                            language={contact.Language}
                                            modal={modal}
                                            selectedContact={selectedContact}
                                            setDisplayCarrierLabel={setDisplayCarrierLabel}
                                            someAreTrue={checkForCarriers}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {modal.edit ? null : rowLimitError ? (
                            <div className='text-center error-message'>{translate('MaxRowLimit_Label')}</div>
                        ) : (
                            <div className='p-1 w-100'>
                                <button
                                    type='button'
                                    className='btn btn-tertiary-blue btn-block'
                                    disabled={!isAddContactFormValid(newContacts, user?.InternalUser)}
                                    onClick={() => handleAddNewContact()}
                                >
                                    <FontAwesomeIcon icon={faPlus} className='mr-1' />
                                    {translate('AddAnotherContact_Label')}
                                </button>
                            </div>
                        )}
                    </div>
                </form>
            </div>
            <div className='modal-footer justify-content-end'>
                <Button
                    variant='primary'
                    size='large'
                    ref={!modal.edit ? addContactsConfirmRef : null}
                    disabled={
                        modal.edit
                            ? !isAddContactFormValid([{ ...editContact, IsRoleLabelValid: editContact.IsEditedRoleEmpty }])
                            : !isAddContactFormValid(newContacts, user?.InternalUser)
                    }
                    onClick={() => handleSubmit()}
                >
                    {modal.edit ? translate('Save_Label') : translate('AddContact_Label')}
                </Button>
            </div>
        </>
    );
};

export default ContactInfoForm;
