import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { instanceWithCancel } from '../../../../axios-portal';
import { API_IMAGING_PATH } from '../../../../common/constants/constants-portal';
import { SearchResults } from '../../common/models/ImagingModel';
import { INIT_IMAGE_MODEL, SUBMIT_IMAGE_SEARCH } from './imagingActionTypes';

export const GetImagingModel = createAsyncThunk(INIT_IMAGE_MODEL, async (payload, { rejectWithValue }) => {
    try {
        const response = await axios.get(`${API_IMAGING_PATH}/Index`);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.status);
    }
});

export const GetImagingDocuments = createAsyncThunk(SUBMIT_IMAGE_SEARCH, async (payload: {}, { rejectWithValue }) => {
    try {
        const response = await instanceWithCancel.post<SearchResults>(`${API_IMAGING_PATH}/SubmitImageSearch`, payload);

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error === 'Request is canceled' ? error : error.response.status);
    }
});
export const fetchDocumentPresignedUrl = async (payload: string) => {
    try {
        const response = await axios.post<{ result: string }>(`${API_IMAGING_PATH}/GetPresignedUrl/?url=${payload}`);

        return response.data;
    } catch (error: any) {}
};
