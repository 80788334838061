import $ from 'jquery';
import axios from '../../../axios-mlp';
import { setValueByPath } from '../../../utils/mlp-utils';
import * as actionTypes from './verifyAccessActionTypes';

export const getVerifyAccess = (requestId) => {
    return (dispatch) => {
        $('#overlay').show();

        let params = new URLSearchParams();
        params.append('requestId', requestId);

        axios
            .get('/ClientAdmin/VerifyAccess', { params: params })
            .then((response) => {
                dispatch(afterGetVerifyAccess(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const afterGetVerifyAccess = (model) => {
    return {
        type: actionTypes.AFTER_GET_VERIFY_ACCESS,
        payload: { model: model }
    };
};

export const selectServiceCoordinator = (countryIndex, coordinator) => {
    return {
        type: actionTypes.SELECT_SERVICE_COORDINATOR,
        payload: { countryIndex: countryIndex, coordinator: coordinator }
    };
};

export const clearServiceCoordinator = (countryIndex) => {
    return {
        type: actionTypes.CLEAR_SERVICE_COORDINATOR,
        payload: { countryIndex: countryIndex }
    };
};

export const selectSalesforceContactVA = (item) => {
    return {
        type: actionTypes.SELECT_SALEFORCE_CONTACT_VA,
        payload: { contact: item }
    };
};

export const clearUserVA = () => {
    return {
        type: actionTypes.CLEAR_USER_VA
    };
};

export const resetUserVA = () => {
    return {
        type: actionTypes.RESET_USER_VA
    };
};

export const setVerifyAccessValidationMessages = (validationMessages) => {
    return {
        type: actionTypes.VERIFY_ACCESS_SET_VALIDATION_MESSAGES,
        payload: { validationMessages: validationMessages }
    };
};

export const validateVerifyAccess = (model) => {
    return (dispatch) => {
        axios
            .post('/ClientAdmin/VerifyAccessValidate', model)
            .then((response) => {
                let validationMessages = {};

                const json = JSON.parse(response.data);
                for (let i in json) {
                    setValueByPath(validationMessages, i, json[i]);
                }
                dispatch(setVerifyAccessValidationMessages(validationMessages));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            });
    };
};

export const changeVerifyAccessRejectMsg = (rejectMsg) => {
    return {
        type: actionTypes.CHANGE_VERIFY_ACCESS_REJECT_MSG,
        payload: { rejectMsg: rejectMsg }
    };
};

export const resetStateVerifyAccess = () => {
    return {
        type: actionTypes.RESET_STATE_VERIFY_ACCESS
    };
};
