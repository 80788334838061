import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import classes from './Fallback.module.css';

const Fallback = () => {
    return (
        <div className={classes.container}>
            <div className={classes.loader}>
                <Loader type='Watch' color='#A9A9A9' height={100} width={100} />
            </div>
        </div>
    );
};

export default Fallback;
