import useTranslation from '../../hooks/useTranslation';
import RadioButton from '../RadioButton';

interface Status {
    id: string;
    label: string;
    amount?: number;
}

interface StatusFilterProps {
    id: string;
    options: readonly Status[];
    selectedStatus: Status;
    onChange: (value: number) => void;
}

const StatusFilter: React.FC<StatusFilterProps> = ({ id, options, selectedStatus, onChange }) => {
    const translate = useTranslation();

    return (
        <div className='filter-pills p-2 p-md-0 d-flex mr-md-2'>
            {options.map((option, index) => (
                <RadioButton
                    key={option.id}
                    id={`${id.toLowerCase()}-${option.id.toLowerCase()}`}
                    name={id}
                    divClass='custom-control custom-radio tag-radio-button m-1'
                    labelClass='custom-control-label tag-label'
                    className='custom-control-input tag-input'
                    onChange={() => onChange(index)}
                    isChecked={selectedStatus.id === option.id}
                >
                    {translate(option.label)}
                    {option.amount ? ` (${option.amount})` : ''}
                </RadioButton>
            ))}
        </div>
    );
};

export default StatusFilter;
