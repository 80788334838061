import { faChevronLeft, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { PortalRoutes } from '../../../../common/constants/constants-portal';
import Breadcrumb from '../../../../common/features/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '../../../../common/features/Breadcrumb/BreadcrumbItem';
import Checkbox from '../../../../common/features/Checkbox';
import FormInputField from '../../../../common/features/FormInputField';
import Select from '../../../../common/features/Select';
import { maskInput } from '../../../../common/functions/maskInput';
import { inputHasValueValidator } from '../../../../common/functions/validators';
import { useDebounce } from '../../../../common/hooks/useDebounce';
import useMaskedInputValidation from '../../../../common/hooks/useMaskedInputValidation';
import useTranslation from '../../../../common/hooks/useTranslation';
import { RequestUpdateTypes } from '../../common/constants/constants-profile';
import { GetCaseNumber } from '../../redux/actions/requestAnUpdate';
import { useProfileDispatch, useProfileSelector } from '../../redux/hooks';

const TIMEOUT = 4000;

const RequestUpdate = () => {
    const { requestAnUpdateModel } = useProfileSelector((state) => state.requestAnUpdate);
    const [userDataForm, setUserDataForm] = useState(requestAnUpdateModel);
    const [error, setError] = useState(false);
    const [formIsSubmitted, setFormIsSubmitted] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);
    const history = useHistory<{ requestType: string }>();
    const dispatch = useProfileDispatch();
    const translate = useTranslation();

    const {
        value: phoneNumber,
        handleChange: phoneNumberChangeHandler,
        handleBlur: phoneNumberBlurHandler,
        hasError: phoneNumberHasError,
        isValid: phoneNumberIsValid,
        errorIndex: phoneNumberErrorIndex
    } = useMaskedInputValidation({
        validators: [inputHasValueValidator, (value) => isValidPhoneNumber(value, 'US')],
        validateOnSubmit: formIsSubmitted,
        inputMask: '(###) ###-#### x######',
        initial: maskInput(requestAnUpdateModel.PhoneNumber, '(###) ###-#### x######')
    });

    useEffect(() => {
        handleChangeUserDataForm('RequestType', history.location.state.requestType);
        handleChangeUserDataForm('PhoneNumber', phoneNumber);
        return () => {
            setUserDataForm(requestAnUpdateModel);
        };
    }, [history, phoneNumber, requestAnUpdateModel]);

    useEffect(() => {
        if (formIsSubmitted && userDataForm.PhoneNumber === phoneNumber && phoneNumberIsValid) {
            if (submitClicked) {
                dispatch(GetCaseNumber(userDataForm));
                history.push({ pathname: '/Profile/RequestSuccess' });
            }
        }
    }, [formIsSubmitted, submitClicked, dispatch, history, phoneNumber, phoneNumberIsValid, userDataForm]);

    const debounce = useDebounce(TIMEOUT);

    useEffect(() => {
        debounce(() => {
            setError(false);
        });
    }, [debounce, error]);

    useEffect(() => {
        if (phoneNumberIsValid) {
            handleChangeUserDataForm('PhoneNumber', phoneNumber);
        }
    }, [phoneNumberIsValid, phoneNumber]);

    const handleChangeUserDataForm = (field: string, value: string) => {
        switch (field) {
            case 'RequestType':
                setUserDataForm((prevState) => ({ ...prevState, RequestType: value }));
                break;
            case 'Description':
                setUserDataForm((prevState) => ({ ...prevState, Description: value }));
                break;
            case 'FirstName':
                setUserDataForm((prevState) => ({ ...prevState, FirstName: value }));
                break;
            case 'LastName':
                setUserDataForm((prevState) => ({ ...prevState, LastName: value }));
                break;
            case 'Email':
                setUserDataForm((prevState) => ({ ...prevState, Email: value }));
                break;
            case 'PhoneNumber':
                setUserDataForm((prevState) => ({ ...prevState, PhoneNumber: value }));
                break;
            case 'ClientType':
                setUserDataForm((prevState) => ({ ...prevState, ClientType: value }));
                break;
        }
    };

    const handleClientType = (value: string) => {
        switch (userDataForm.ClientType) {
            case '':
                handleChangeUserDataForm('ClientType', value);
                break;
            case 'US Brokerage and CA Brokerage':
                value === 'US Brokerage'
                    ? handleChangeUserDataForm('ClientType', 'CA Brokerage')
                    : handleChangeUserDataForm('ClientType', 'US Brokerage');
                break;
            case 'US Brokerage':
                value === 'US Brokerage'
                    ? handleChangeUserDataForm('ClientType', '')
                    : handleChangeUserDataForm('ClientType', 'US Brokerage and CA Brokerage');
                break;
            case 'CA Brokerage':
                value === 'CA Brokerage'
                    ? handleChangeUserDataForm('ClientType', '')
                    : handleChangeUserDataForm('ClientType', 'US Brokerage and CA Brokerage');
                break;
        }
    };

    const submitRequest = () => {
        if (
            userDataForm.FirstName.length > 0 &&
            userDataForm.LastName.length > 0 &&
            userDataForm.Email.length > 0 &&
            userDataForm.PhoneNumber.length > 0 &&
            userDataForm.ClientType.length > 0
        ) {
            setFormIsSubmitted(true);
            phoneNumberIsValid && setSubmitClicked(true);
        } else {
            setError(true);
            setFormIsSubmitted(false);
            setSubmitClicked(false);
        }
    };

    return (
        <div className='d-flex flex-column'>
            {/* BREADCRUMB */}
            <Breadcrumb>
                <BreadcrumbItem url={PortalRoutes.profile}>{translate('MyProfile_Label')}</BreadcrumbItem>
                <BreadcrumbItem>{translate('RequestAnUpdate_Label')}</BreadcrumbItem>
            </Breadcrumb>

            {/* TOAST ERROR */}
            {error && (
                <div className='toasts-wrapper'>
                    <div className='toast error' role='alert' aria-live='assertive' aria-atomic='true' style={{ opacity: '1' }}>
                        <div className='toast-body' style={{ opacity: '1' }}>
                            <div className='toast-icon'>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </div>
                            <div className='toast-text'> {translate('FormNotFilledCorrectly_Label')} </div>
                        </div>
                    </div>
                </div>
            )}

            {/* FORM */}
            <div className='container-narrow mb-5'>
                <div className='row'>
                    {/* Header */}
                    <div className='col d-flex align-items-center my-3 mt-md-5 mb-md-4'>
                        <button type='button' className='btn btn-tertiary mr-3' onClick={() => history.push('/Profile')}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <h1 className='mb-0'> {translate('RequestAnUpdate_Label')} </h1>
                    </div>
                </div>

                {/* Form in Card */}
                <div className='row'>
                    <div className='col'>
                        <div className='card'>
                            <div className='card-body'>
                                <form>
                                    <div className='form-group'>
                                        <label>{translate('RequestType_Label')}</label>
                                        <Select
                                            options={RequestUpdateTypes.map((item) => {
                                                return { ...item, label: translate(item.label) };
                                            })}
                                            defaultValue={RequestUpdateTypes.filter(
                                                (item) => translate(item.label) === history.location.state.requestType
                                            )
                                                .map((item) => item.value)
                                                .join()}
                                            onChange={(value) => handleChangeUserDataForm('RequestType', value)}
                                        />
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label>{translate('TellUsWhatInformationToUpdate_Label')}</label>
                                        <textarea
                                            className='form-control'
                                            id='exampleFormControlTextarea1'
                                            onChange={(e) => handleChangeUserDataForm('Description', e.target.value)}
                                            maxLength={2000}
                                        ></textarea>
                                    </div>

                                    <h4 className='mb-3'>{translate('ToWhomShouldWeRespond_Label')}</h4>
                                    <div className='container-fluid'>
                                        <div className='row row-cols-1 row-cols-md-2'>
                                            <div className='col'>
                                                <div className='form-group mr-md-3 needs-validation was-validated'>
                                                    <label>{translate('FirstName_Label')}</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        id='FirstName'
                                                        value={userDataForm.FirstName}
                                                        onChange={(e) => handleChangeUserDataForm('FirstName', e.target.value)}
                                                        required
                                                    />
                                                    <div className='invalid-feedback'>{translate('InvalidFirstName_Label')}</div>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='form-group needs-validation was-validated'>
                                                    <label>{translate('LastName_Label')}</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        id='LastName'
                                                        value={userDataForm.LastName}
                                                        onChange={(e) => handleChangeUserDataForm('LastName', e.target.value)}
                                                        required
                                                    />
                                                    <div className='invalid-feedback'>{translate('InvalidLastName_Label')}</div>
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='mr-md-3'>
                                                    <FormInputField
                                                        isBold={true}
                                                        label='Phone number'
                                                        value={phoneNumber}
                                                        onChange={phoneNumberChangeHandler}
                                                        onBlur={phoneNumberBlurHandler}
                                                        isValid={phoneNumberIsValid}
                                                        hasError={phoneNumberHasError}
                                                    />
                                                    {phoneNumberHasError && phoneNumberErrorIndex === 0 && (
                                                        <div className='error-message'>{translate('PhoneNumberIsRequired_Label')}</div>
                                                    )}
                                                    {phoneNumberHasError && phoneNumberErrorIndex === 1 && (
                                                        <div className='error-message'>{translate('InvalidPhoneNumber_Label')}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='col'>
                                                <div className='form-group needs-validation was-validated'>
                                                    <label>{translate('EmailProfile_Label')}</label>
                                                    <input
                                                        type='email'
                                                        className='form-control'
                                                        id='Email'
                                                        value={userDataForm.Email}
                                                        onChange={(e) => handleChangeUserDataForm('Email', e.target.value)}
                                                        required
                                                    />
                                                    <div className='invalid-feedback'>{translate('InvalidEmail_Label')}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col'>
                                                <div className='form-group mr-md-3 mt-2'>
                                                    <label className='mb-2'>{translate('ClientType_Label')}</label>
                                                    <div className='d-flex flex-column'>
                                                        <Checkbox
                                                            divClass='custom-control custom-checkbox mb-2'
                                                            id='customCheck1'
                                                            name='check1'
                                                            onChange={() => {
                                                                setCheck1((prevState) => !prevState);
                                                                handleClientType('US Brokerage');
                                                            }}
                                                            isChecked={check1}
                                                        >
                                                            {translate('USBrokerage_Label')}
                                                        </Checkbox>
                                                        <Checkbox
                                                            divClass='custom-control custom-checkbox mb-2'
                                                            id='customCheck2'
                                                            name='check2'
                                                            onChange={() => {
                                                                setCheck2((prevState) => !prevState);
                                                                handleClientType('CA Brokerage');
                                                            }}
                                                            isChecked={check2}
                                                        >
                                                            {translate('CABrokerage_Label')}
                                                        </Checkbox>
                                                    </div>
                                                    {userDataForm.ClientType.length === 0 && (
                                                        <div className='error-message'>{translate('InvalidClientType_Label')}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='card-footer d-flex justify-content-end'>
                                <button type='button' className='btn btn-primary btn-lg' onClick={submitRequest}>
                                    {translate('SubmitRequest_Label')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequestUpdate;
