import produce from 'immer';
import * as actionTypes from '../actions/dashboard/dashboardActionTypes';

const initialState = {
    model: {
        User: {
            UserFeatures: [],
            UserId: '',
            LastName: '',
            FullName: '',
            IsLocked: '',
            InternalUser: '',
            IMSId: ''
        },
        ClientServiceSelection: [],
        WidgetPlacementViewModels: [],
        ReportHost: '',
        ReportPrefix: ''
    }
};

const setDashboard = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.model;
    });
};

const updateDashboardClientService = (state, action) => {
    return produce(state, (draft) => {
        // Old comment - initial widget data needs to be removed to ensure new data is rendered properly
        //TODO - Need completed widget in order to test this
        //let vm = this.state.ViewModel;
        // vm.WidgetPlacementViewModels.forEach(function (model, iterator) {
        //     model.Widget.Data = undefined;
        // });
        //this.setState({ ViewModel: vm });

        draft.model.ClientServiceSelection = action.data;
    });
};

const updateDashboardWidgets = (state, action) => {
    return produce(state, (draft) => {
        draft.model.WidgetPlacementViewModels = action.data;
    });
};

const updateWidgetSortOrder = (state, action) => {
    return produce(state, (draft) => {
        for (let i = 0; i < action.data.length; i++) {
            draft.model.WidgetPlacementViewModels.forEach((element) => {
                if (element.WidgetPlacementID === parseInt(action.data[i].id)) {
                    element.SortOrder = i + 1;
                }
            });
        }
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DASHBOARD:
            return setDashboard(state, action);
        case actionTypes.UPDATE_CLIENT_SERVICE:
            return updateDashboardClientService(state, action);
        case actionTypes.UPDATE_WIDGETS:
            return updateDashboardWidgets(state, action);
        case actionTypes.UPDATE_WIDGET_SORT_ORDER:
            return updateWidgetSortOrder(state, action);
        case actionTypes.RESET_STATE_DASHBOARD:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
