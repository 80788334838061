import { useEffect, useState } from 'react';

const useNotifDropdownToggle = (ref: React.RefObject<HTMLDivElement | HTMLButtonElement>) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOutside, setIsOutside] = useState(false);

    // Detect if the user clicked inside or outside of the element
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            var element = event.target as HTMLElement;
            if (
                ref.current &&
                !ref.current.contains(event.target as HTMLDivElement) &&
                !(event.offsetX > element.clientWidth || event.offsetY > element.clientHeight)
            ) {
                setIsOutside(true);
            } else {
                setIsOutside(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    // If click is outside of the element close the dropdown
    useEffect(() => {
        if (isOutside) {
            setIsOpen(false);
        }
    }, [isOutside]);

    // Toggle the dropdown's visibility
    const handleToggle = () => {
        if (isOutside) {
            setIsOpen(false);
        } else {
            setIsOpen((prevState) => !prevState);
        }
    };

    // Close the dropdown when "Enter" key is pressed on the input field inside the dropdown
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (isOpen === true) {
                setIsOpen(false);
            }
        }
    };

    return { isOpen, handleToggle, handleKeyDown };
};

export default useNotifDropdownToggle;
