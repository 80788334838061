const LiveChat = ({ liveChatSettings }) => {
    const initESW = (gslbBaseURL) => {
        let embedded_svc = window.embedded_svc;
        embedded_svc.settings.displayHelpButton = true; //Or false
        embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'
        embedded_svc.settings.enabledFeatures = ['LiveAgent'];
        embedded_svc.settings.entryFeature = 'LiveAgent';
        embedded_svc.init(
            liveChatSettings.SalesforceEnv,
            liveChatSettings.UShipUrl,
            gslbBaseURL,
            liveChatSettings.EmbedId,
            'Live_Agent_MLP',
            {
                baseLiveAgentContentURL: liveChatSettings.AgentContentUrl,
                deploymentId: '5720d0000008OuC',
                buttonId: '5733g0000004C9v',
                baseLiveAgentURL: liveChatSettings.AgentUrl,
                eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I0d0000004C9DEAU_1697187103e',
                isOfflineSupportEnabled: true
            }
        );
    };

    const LivingstonLiveAgentChat = () => {
        if (!window.embedded_svc) {
            let s = document.createElement('script');
            s.setAttribute('src', 'https://livingstonintl.my.salesforce.com/embeddedservice/5.0/esw.min.js');
            s.onload = () => {
                initESW(null);
            };
            document.body.appendChild(s);
        } else {
            initESW('https://service.force.com');
        }
    };

    LivingstonLiveAgentChat();
};

export default LiveChat;
