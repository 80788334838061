import { Notifications, NotificationTypeCheckboxes } from '../../../../common/models/ResponseData';

export const RequestUpdateTypes = [
    { value: '0', label: 'RequestAnUpdateYourName_Label' },
    { value: '1', label: 'RequestAnUpdateCompanyName_Label' },
    { value: '2', label: 'RequestAnUpdateContactInfo_Label' },
    { value: '3', label: 'RequestAnUpdateUserRole_Label' }
] as const;

export const subscriptionTypeConstants = [
    { value: 'SINGLE', label: 'SingleEntry_Label' },
    { value: 'CLIENT', label: 'ClientNo_Label' }
];

export interface GridSubscriptions {
    DocID?: null | number;
    EntryNo?: null | string;
    SubscriptionType?: null | string;
    isNew?: boolean;
    selected?: boolean;
    RowId?: null | number;
    SubscriptionId?: null | number;
    Name?: null | string;
    CustomerNumber?: null | string;
    Language?: null | string;
    UserId?: null | string;
    FirstName?: null | string;
    FullName?: null | string;
    RoleName?: null | string;
    Email?: null | string;
    ProfileEmail?: null | string;
    Phone?: null | string;
    ProfilePhone?: null | string;
    IsExternal?: null | boolean;
    IsEditable?: null | boolean;
    IsAvailableToSubscribe?: null | boolean;
    DateSubscribed?: null | string;
    MilestoneNotifications_CA?: null | Notifications[];
    MilestoneNotifications_US?: null | Notifications[];
    NotificationTypeCheckboxes?: null | NotificationTypeCheckboxes;
}
