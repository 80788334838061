import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import { Breakpoints, SidebarNavItems } from '../../../common/constants/constants-portal';
import useTranslationHydration from '../../../common/hooks/useTranslationHydration';
import { resetAdvancedSearchSettings } from '../../../pages/clearances/redux/reducers/advancedSearchSettingsSlice';
import { resetDateRange } from '../../../pages/clearances/redux/reducers/dateRangeSlice';
import { resetSearchFilter } from '../../../pages/clearances/redux/reducers/searchSlice';
import { resetShipmentGridSettings } from '../../../pages/clearances/redux/reducers/shipmentGridSettingsSlice';
import { resetStatus } from '../../../pages/clearances/redux/reducers/shipmentStatusSlice';
import { usePortalDispatch, usePortalSelector } from '../../../redux/hooks';
import { LivingstonLogoPortal } from '../../features/LivingstonLogoPortal';
import useMediaQuery from '../../hooks/useMediaQuery';
import SidebarCloseButton from './SidebarCloseButton';
import SidebarNavFooter from './SidebarNavFooter';
import SidebarNavItem from './SidebarNavItem';

interface SidebarProps {
    onLanguageChange: (payload: number) => void;
}

export type SidebarActions = {
    toggleOpen: () => void;
    close: () => void;
};

const SidebarRef = forwardRef<SidebarActions, SidebarProps>(function Sidebar({ onLanguageChange }, ref) {
    const { user } = usePortalSelector((state) => state.hydration);
    const dispatch = usePortalDispatch();

    const translate = useTranslationHydration();

    const navigationItems = user?.CountryCode === 'us' ? SidebarNavItems.filter((item) => item.id !== 'productReport') : SidebarNavItems;
    const [isOpen, setIsOpen] = useState(window.innerWidth > Breakpoints.Mobile);

    const isDesktop = useMediaQuery(Breakpoints.Mobile);

    useEffect(() => {
        setIsOpen(isDesktop);
    }, [isDesktop]);

    useImperativeHandle(ref, () => {
        return {
            toggleOpen() {
                setIsOpen((prevState) => !prevState);
            },
            close() {
                setIsOpen(false);
            }
        };
    });

    const resetAllFilters = () => {
        dispatch(resetDateRange());
        dispatch(resetStatus());
        dispatch(resetSearchFilter());
        dispatch(resetAdvancedSearchSettings());
        dispatch(resetShipmentGridSettings());
    };

    const close = () => {
        setIsOpen(false);
    };

    return (
        <>
            {isOpen && !isDesktop && <div className='overlay' onClick={close}></div>}
            <nav className={`sidebar d-flex flex-column justify-content-between ${isOpen ? 'showing' : ''}`}>
                <div className='sidebar-menu'>
                    <div className='sidebar-header'>
                        <div className='brand-color-strip lii-portal-accent'></div>
                        <div className='brand-logo d-flex justify-content-between align-items-center'>
                            <LivingstonLogoPortal />
                            <SidebarCloseButton icon={faTimes} onClick={close} />
                        </div>
                    </div>
                    <div className='list-group sidebar-list-group'>
                        {navigationItems.map((item) => {
                            return (
                                <SidebarNavItem
                                    key={item.id}
                                    title={translate(item.label)}
                                    url={item.url}
                                    icon={item.icon}
                                    onClick={() => {
                                        resetAllFilters();
                                    }}
                                />
                            );
                        })}
                        {/* {user?.HasAccessToPayments === true && !user.InternalUser && (
                            <SidebarNavExternalItem title={translate('Payments_Label')} url={user.PaymentsUrl} icon={faCreditCard} />
                        )}
                        {user?.Roles && user.Roles.includes('Dashboard_Access') && (
                            <SidebarNavExternalItem title='My Livingston' url={'/Dashboard'} icon={faPaperPlane} />
                        )} */}
                    </div>
                </div>
                <SidebarNavFooter onLanguageChange={onLanguageChange} />
            </nav>
        </>
    );
});

const Sidebar = memo(SidebarRef);

export default Sidebar;
