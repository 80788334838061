import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { useHistory } from 'react-router-dom';
import useTranslation from '../../../../common/hooks/useTranslation';
import { ProductReportData } from '../../common/models/productReportModels';

interface FormattedGridCellProps extends GridCellProps {
    onClickFunction?(): void;
    handleDownoloadProductReport?(clientNumber: string): void;
    checkboxChange: (payload: CheckboxChangeEvent) => void;
    selectedItems: string[];
    dataItem: ProductReportData;
}
const FormattedGridCell: React.FC<FormattedGridCellProps> = ({
    onClickFunction,
    handleDownoloadProductReport,
    field = '',
    dataItem,
    checkboxChange,
    selectedItems,
    className,
    style,
    ...rest
}) => {
    let cellContent;
    const translate = useTranslation();
    const history = useHistory();

    const handleClick = () => {
        if (dataItem['Number'] && handleDownoloadProductReport) {
            handleDownoloadProductReport(dataItem['Number']);
        }
    };

    switch (field) {
        case 'selected':
            cellContent = (
                <Checkbox
                    name={dataItem['Number']}
                    disabled={false}
                    value={selectedItems.includes(dataItem['Number'])}
                    onChange={checkboxChange}
                />
            );
            break;
        case 'Number':
            cellContent = dataItem['Number'];
            break;
        case 'Name':
            cellContent = dataItem['Name'];
            break;
        case 'Download':
            cellContent = (
                <div className='d-flex justify-content-center' onClick={handleClick}>
                    <FontAwesomeIcon icon={faFileDownload} size='lg' />
                </div>
            );
            break;
    }
    return (
        <td
            style={{
                ...style
            }}
        >
            {cellContent}
        </td>
    );
};

export { FormattedGridCell };
