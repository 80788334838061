import $ from 'jquery';
import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import axios from '../../axios-mlp';
import * as actionCreators from '../../store/actions/index';

class FeedbackForm extends Component {
    state = {
        height: '1200px',
        url: 'https://survey.clicktools.com/app/survey/go.jsp?iv=2piortaeutp5s'
    };

    componentDidMount = () => {
        $('#overlay').show();

        this.props.onInitTranslations('Feedback.Feedback.Index');

        axios
            .get('Feedback/Index')
            .then((response) => {
                this.setState({ url: response.data.URL });
                this.setState({ height: response.data.Height });
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    this.props.history.push('/BasicError');
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
    }

    render() {
        return (
            <div id='page'>
                <HelmetProvider>
                    <Helmet>
                        <title>{this.props.translations.Phrases['Page_Title']}</title>
                    </Helmet>
                </HelmetProvider>
                <div className='page-wrapper'>
                    <div className='page-top-row'>
                        <div className='page-header-section'>
                            <nav className='mlp-breadcrumb'>
                                <Link to='/Dashboard' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                </Link>
                                <i className='fa fa-caret-right'></i>
                                <span> {this.props.translations.Phrases['Page_Header_Navigation_Level_2']}</span>
                            </nav>
                            <h1 className='bold-title'>{this.props.translations.Phrases['Page_Header']}</h1>
                        </div>
                    </div>
                    <div className='content-wrapper'>
                        <div className='row page-content'>
                            <div className='col-md-12 iframe-no-margin' style={{ height: this.state.height }}>
                                <iframe
                                    title='feedbackIframe'
                                    src={this.state.url}
                                    frameBorder='0'
                                    className='iframe'
                                    sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeedbackForm));
