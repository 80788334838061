import { useEffect } from 'react';
import StatusAlert from '../../../../common/features/StatusAlert';
import { useDebounce } from '../../../../common/hooks/useDebounce';
import useTranslation from '../../../../common/hooks/useTranslation';
import { useContactsDispatch, useContactsSelector } from '../../redux/hooks';
import { clearNewContacts, resetAddNewContacts, resetEditContacts, resetRemoveContacts } from '../../redux/reducers/contactsSlice';

const TIMEOUT = 3000;

const ContactStatusAlert = () => {
    const { isLoading, contacts, addNewContacts, removeContacts, editedContact } = useContactsSelector((state) => state.contacts);
    const { user } = useContactsSelector((state) => state.hydration);
    const translate = useTranslation();
    const dispatch = useContactsDispatch();

    // Reset all action inside "contacts" slice on unmount
    useEffect(() => {
        return () => {
            dispatch(resetAddNewContacts());
            dispatch(resetRemoveContacts());
            dispatch(resetEditContacts());
        };
    }, [dispatch]);

    const debounce = useDebounce(TIMEOUT);

    useEffect(() => {
        debounce(() => {
            dispatch(clearNewContacts());
        });
    }, [dispatch, contacts.length, debounce]);

    if (!isLoading && !addNewContacts.isLoading) {
        return (
            <>
                {/* Status alert for adding new contact(s) */}
                {addNewContacts.data && !addNewContacts.error && (
                    <StatusAlert
                        message={translate('ContactsAdded_Label', addNewContacts.newContactsAmount)}
                        type={'success'}
                        customTimeout={user?.PortalTimeoutShort}
                    />
                )}

                {/* Status alert for deleting contact(s) */}
                {removeContacts.data && removeContacts.data === 'SuccessfullRemoved' && !removeContacts.error && (
                    <StatusAlert
                        message={translate('ContactsDeleted_Label', removeContacts.removedContactsAmount)}
                        type={'success'}
                        customTimeout={user?.PortalTimeoutShort}
                    />
                )}
                {/* Status alert for editing contact(s) */}
                {editedContact.data && !editedContact.error && (
                    <StatusAlert message={translate('ContactsEdited_Label')} type={'success'} customTimeout={user?.PortalTimeoutShort} />
                )}
            </>
        );
    } else {
        return null;
    }
};

export default ContactStatusAlert;
