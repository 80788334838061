import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ChangeEvent, FormEvent } from 'react';
import { useRef, useState } from 'react';
import { generateUUID } from '../../../../../common/functions/utils';

interface BasicSearchBoxProps {
    initialValue: string;
    placeholderLabel?: string;
    submitButtonLabel?: string;
    onReset: () => void;
    onSubmit: (value: string) => void;
}

export default function BasicSearchBox({ initialValue, placeholderLabel, submitButtonLabel, onReset, onSubmit }: BasicSearchBoxProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const [inputId] = useState(`search-${generateUUID()}`);
    const [value, setValue] = useState(initialValue);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const handleReset = () => {
        setValue('');
        inputRef.current?.focus();

        onReset();
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        onSubmit(value);
    };

    return (
        <form role='search' className='input-group' onSubmit={handleSubmit}>
            <div className='simplified-search-bar simplified-search-bar-with-button'>
                <div className='chips-input-wrapper'>
                    <div className='magnifying-glass-input-icon'>
                        <FontAwesomeIcon icon={faSearch} />
                    </div>
                    <div className='chips' />
                    <label className='visually-hidden' htmlFor={inputId}>
                        {submitButtonLabel}
                    </label>
                    <input
                        type='text'
                        id={inputId}
                        ref={inputRef}
                        className='form-control search-input text-truncate'
                        placeholder={placeholderLabel}
                        value={value}
                        onChange={handleChange}
                    />
                    <div className='justify-content-end'>
                        {value.length > 0 && (
                            <button type='button' onClick={handleReset} className='btn p-0 rounded-circle d-flex align-items-center'>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div className='input-group-append'>
                <button type='submit' className='btn btn-primary d-flex align-items-center'>
                    {submitButtonLabel}
                </button>
            </div>
        </form>
    );
}
