function InputBase(props) {
    const constructContainerClass = () => {
        let containerClass = ['input-container'];
        if (props.disabled) {
            containerClass.push('disabled');
        }
        if (props.readonly) {
            containerClass.push('readonly');
        }
        if (props.noBorder) {
            containerClass.push('no-border');
        }
        return containerClass.join(' ');
    };

    const constructValidationClass = () => {
        return !props.disabled && !props.readonly && props.valMsg ? 'field-validation-error' : 'field-validation-valid';
    };

    const keyPress = (e) => {
        if (props.allowEnter !== 'true') {
            if (e.key === 'Enter') e.preventDefault();
        }
    };

    if (props.embed) {
        return props.children;
    }

    let header = (
        <div className='input-header'>
            <div className='input-label'>
                <span>{props.label}</span>
            </div>
            <div className='input-validation'>
                <span className={constructValidationClass()}>{props.valMsg}</span>
            </div>
        </div>
    );

    if (props.noHeader) {
        header = null;
    }

    return (
        <div className={constructContainerClass()}>
            {header}
            <div className='input-body' onKeyPress={(event) => keyPress(event)}>
                {props.children}
            </div>
        </div>
    );
}

export default InputBase;
