import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Minus from '../../assets/images/minus.png';
import Plus from '../../assets/images/plus.png';
import ToggleIcon from './ToggleIcon';

class ExpandIcon extends Component {
    static propTypes = {
        isExpanded: PropTypes.bool
    };

    render() {
        return <ToggleIcon isOn={this.props.isExpanded} imgClass='expand-icon' selectedIcon={Minus} unselectedIcon={Plus} />;
    }
}

export default ExpandIcon;
