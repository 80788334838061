import React from 'react';
import Button from '../Button';
import ModalWrapper from '../Wrappers/ModalWrapper';

interface ConfirmationModalProps {
    title: string;
    description: string;
    primaryButtonLabel: string;
    secondaryButtonLabel: string;
    onPrimary: () => void;
    onSecondary: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    title,
    description,
    primaryButtonLabel,
    secondaryButtonLabel,
    onPrimary,
    onSecondary
}) => {
    return (
        <ModalWrapper onEscape={onSecondary}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content'>
                    {/* Heading */}

                    {/* Body */}
                    <div className='modal-body'>
                        <h3 className='modal-title' id='staticBackdropLabel'>
                            {title}
                        </h3>
                        <p className='note mb-0 notification-message'>{description}</p>
                    </div>

                    {/* Footer */}
                    <div className='modal-footer'>
                        <Button variant='secondary' size='medium' onClick={onSecondary}>
                            {secondaryButtonLabel}
                        </Button>

                        <Button variant='danger' size='medium' onClick={onPrimary}>
                            {primaryButtonLabel}
                        </Button>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default ConfirmationModal;
