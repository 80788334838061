import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { userPermission } from '../../constants/mlp-constants';
import * as actions from '../../store/actions/index';
import NoticesArea from './NoticesArea';

class NoticesForm extends Component {
    componentDidMount() {
        this.props.onInitNotices();
        this.props.onInitTranslations('Notices.Notices.Index');
        this.props.updateUnreadNotices();
        this.props.onInitUserPermission(userPermission.CENTRALDASHBOARD_ACCESS);
    }

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }
    }

    componentWillUnmount() {
        this.props.onResetNoticesState();
    }

    createNoticesArea() {
        this.props.notices.slice().sort((a, b) => {
            return new Date(b.EffectiveDateFrom) - new Date(a.EffectiveDateFrom);
        });

        let noticesArea =
            this.props.notices.length > 0 ? (
                <NoticesArea notices={this.props.notices} />
            ) : (
                <div className='notices-area'>
                    <div className='container'>
                        <div className='no-notices'>
                            <div className='no-notice-text'>{this.props.translations.Phrases['Notices_NoNoticesMessage']}</div>
                        </div>
                    </div>
                </div>
            );
        return noticesArea;
    }

    dashboardBreadcrumb = () => {
        if (this.props.user.hasCentralPermission) {
            return (
                <Link to='/Clearances' className='breadcrumb-parent'>
                    {this.props.translations.Phrases['Notices_NavLevel1']}
                </Link>
            );
        } else {
            return (
                <Link to='/Dashboard' className='breadcrumb-parent'>
                    {this.props.translations.Phrases['Notices_NavLevel1']}
                </Link>
            );
        }
    };

    render() {
        if (this.props.notices && this.props.translations.PhraseGroupCode === 'Notices.Notices.Index') {
            let noticesArea = this.createNoticesArea();

            return (
                <div className='page-wrapper'>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.props.translations.Phrases['Page_Title']}</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className='page-top-row'>
                        <div className='page-header-section'>
                            <nav className='mlp-breadcrumb'>
                                {this.dashboardBreadcrumb()}
                                <i className='fa fa-caret-right'></i>
                                <span>{this.props.translations.Phrases['Notices_NavLevel2']}</span>
                            </nav>
                        </div>
                        <div>
                            <div className='header-align'>
                                <h1 className='pull-left bold-title'>{this.props.translations.Phrases['Page_Title']}</h1>
                            </div>
                            <div className='header-align'></div>
                        </div>
                    </div>
                    <div className='content-wrapper'>
                        <div className='row page-content'>{noticesArea}</div>
                    </div>
                </div>
            );
        } else {
            return <div className='page-wrapper'></div>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        notices: state.notices.model.NoticesList,
        translations: state.translations.model,
        user: state.user.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitNotices: () => dispatch(actions.initNotices()),
        onInitTranslations: (phraseGroup) => dispatch(actions.initTranslations(phraseGroup)),
        updateUnreadNotices: () => dispatch(actions.updateUnreadNotices()),
        onInitUserPermission: (permissionName) => dispatch(actions.initUserPermission(permissionName)),
        onResetNoticesState: () => dispatch(actions.resetNoticesState())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoticesForm);
