import { faChevronLeft, faChevronRight, faDownload, faFile, faFolder, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, useEffect } from 'react';
import useTranslation from '../../../../../common/hooks/useTranslation';
import useTranslationHydration from '../../../../../common/hooks/useTranslationHydration';
import { removeViewImagesData, ViewDocumentsRedux, ViewDocumentsReduxDocument } from '../../../../../redux/reducers/viewImagesSlice';
import { DocumentsTypeFilterCA, DocumentsTypeFilterUS } from '../../../common/constants/constants-documents';
import { useDocumentsDispatch, useDocumentsSelector } from '../../../redux/hooks';
import './styles.css';

interface Props {
    selectedEntry: string;
    setSelectedEntry: Dispatch<string>;
    selectedDocument: ViewDocumentsReduxDocument | null;
    setSelectedDocument: Dispatch<ViewDocumentsReduxDocument | null>;
    viewImages: ViewDocumentsRedux[];
    setDownloadModalView: (value: string) => void;
    isClearances: boolean;
}

const ViewHeader: React.FC<Props> = ({
    selectedEntry,
    selectedDocument,
    setSelectedEntry,
    setSelectedDocument,
    viewImages,
    setDownloadModalView,
    isClearances
}) => {
    const translate = useTranslation();
    const translateHydration = useTranslationHydration();
    const dispatch = useDocumentsDispatch();

    const { openedLocation } = useDocumentsSelector((state) => state.viewImages);
    const { initialClientSelection } = useDocumentsSelector((state) => state.clientSelection);
    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);
    const docTypeFiltersHashMap = country?.Code === 'us' ? [...DocumentsTypeFilterUS] : [...DocumentsTypeFilterCA];

    //-- Remove btn-sm on desktop --//
    const isMobile = window.innerWidth < 994;

    const handleCloseView = () => {
        dispatch(removeViewImagesData());
    };

    const handleDownloadImages = () => {
        setDownloadModalView(openedLocation ?? 'documentsGrid');
    };

    const switchDocuments = (val: 'prev' | 'next') => {
        const currentDocs = viewImages.find((v) => v.entry === selectedEntry)?.documents;

        if (val === 'prev' && currentDocs) {
            const currentIdx = currentDocs.findIndex((d) => d._fileS3Path === selectedDocument?._fileS3Path);

            if (currentIdx === 0) return;

            setSelectedDocument(viewImages.find((v) => v.entry === selectedEntry)?.documents[currentIdx - 1] || null);
        }

        if (val === 'next' && currentDocs) {
            const currentIdx = currentDocs.findIndex((d) => d._fileS3Path === selectedDocument?._fileS3Path);

            if (currentIdx === currentDocs.length - 1) return;

            setSelectedDocument(viewImages.find((v) => v.entry === selectedEntry)?.documents[currentIdx + 1] || null);
        }
    };

    const getFormattedDate = (x: string) => {
        const datePortion = x?.split('T')[0];

        const formatedDateString = `${datePortion?.substring(5, 7)}/${datePortion?.substring(
            datePortion.length - 2
        )}/${datePortion?.substring(0, 4)}`;
        return formatedDateString;
    };

    // Set initial document
    useEffect(() => {
        setSelectedEntry(viewImages[0].entry);
        setSelectedDocument(viewImages[0].documents[0]);
    }, []);

    const renderSecondaryDescription = (secondaryDesc: string | undefined, primaryDesc: string) => {
        if (secondaryDesc && secondaryDesc !== '-' && secondaryDesc.toUpperCase() !== primaryDesc.toUpperCase()) {
            return <span>{secondaryDesc}</span>;
        }
    };

    const containerStyle: React.CSSProperties = {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        margin: 0
    };
    return (
        <div className='bg-white container-fluid py-2 px-3 py-sm-3'>
            <div className='row'>
                {/* Left section */}
                <div
                    style={isMobile ? containerStyle : {}}
                    className={`col-${isMobile ? '12' : '5'} col-sm-${isMobile ? '12' : '4'} col-md-4 col-lg-4`}
                >
                    <div className='d-flex align-items-center justify-content-start'>
                        {/* Single entry variant */}
                        {viewImages.length <= 1 ? (
                            <h2 className='text-truncate mb-0'>{translateHydration('EntryNo_Label', viewImages[0].entry)}</h2>
                        ) : null}
                        {/* Multi-entry variant */}
                        {viewImages.length > 1 ? (
                            <div className={`input-group w-100 w-sm-auto ${isMobile ? 'input-group-sm' : ''}`}>
                                <div className='display_none_on_mobile input-group-prepend d-none d-md-inline-block'>
                                    <span className='input-group-text h-100' id='basic-addon1'>
                                        <small className='font-weight-bold text-uppercase'>{translateHydration('ViewEntries_Label')}</small>
                                    </span>
                                </div>
                                <button
                                    className={`btn btn-tertiary dropdown-toggle text-truncate w-100 w-sm-auto flex-1 ${
                                        isMobile ? 'btn-sm' : ''
                                    }`}
                                    type='button'
                                    id='entriesMenuButton'
                                    data-toggle='dropdown'
                                    aria-haspopup='true'
                                    aria-expanded='false'
                                >
                                    {selectedEntry}
                                </button>
                                <div
                                    className={`dropdown-menu view_documents_dropdown ${
                                        isMobile ? 'override-transform-mobile' : 'override-transform'
                                    }`}
                                    aria-labelledby='entriesMenuButton'
                                >
                                    <span className={'dropdown-title d-flex justify-content-start align-items-center font-weight-bold'}>
                                        <FontAwesomeIcon icon={faFolder} className={'mr-2 icon'} />
                                        {translateHydration('SelectAnEntry_Label')}
                                    </span>
                                    {viewImages.map((val, i) => (
                                        <div
                                            key={i}
                                            className={`dropdown-item d-flex justify-content-between align-items-stretch`}
                                            onClick={() => {
                                                setSelectedEntry(val.entry);
                                                setSelectedDocument(viewImages.find((v) => v.entry === val.entry)?.documents[0] || null);
                                            }}
                                            role='button'
                                        >
                                            <div className='mr-3 d-flex align-items-center'>{val.entry}</div>
                                        </div>
                                    ))}
                                </div>

                                {/* <button
                                type='button'
                                className={`btn btn-tertiary ml-1 d-none d-md-inline-block ${isMobile ? 'btn-sm' : ''}`}
                                onClick={() => setOpenDrawer(true)}
                            >
                                <FontAwesomeIcon icon={faInfoCircle} />
                                <span className='ml-1 d-none d-lg-inline-block'>Info</span>
                            </button> */}
                            </div>
                        ) : null}
                    </div>
                </div>

                {/* Middle section */}
                <div className={`col-${isMobile ? '6' : '5'} col-sm-6 col-md-6 col-lg-4 text-center px-0`}>
                    <div className='d-flex align-items-center w-100 justify-content-center'>
                        <div className={`no_flex_wrap input-group w-100 w-sm-auto ${isMobile ? 'input-group-sm' : ''}`}>
                            <div className='display_none_on_mobile input-group-prepend d-none d-md-inline-block'>
                                <span className='input-group-text h-100' id='basic-addon2'>
                                    <small className='font-weight-bold text-uppercase'>{translateHydration('ViewDocuments_Label')}</small>
                                </span>
                            </div>
                            <button
                                className={`btn btn-tertiary dropdown-toggle text-truncate w-100 w-sm-auto flex-1 ${
                                    isMobile ? 'btn-sm' : ''
                                }`}
                                type='button'
                                id='documentsMenuButton'
                                data-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'
                            >
                                {selectedDocument ? (
                                    isClearances ? (
                                        // Clearances label
                                        <>
                                            {`${translate(
                                                selectedDocument._metadataFormat.trim().toUpperCase().replaceAll(' ', '') + '_Label'
                                            )} ${getFormattedDate(selectedDocument._imageDate)} `}
                                            {selectedDocument.version ?? ''}
                                        </>
                                    ) : (
                                        // Documents label
                                        <>
                                            {`${translate(
                                                docTypeFiltersHashMap.find(
                                                    (type) => type.id === selectedDocument._metadataFormat.trim().toUpperCase()
                                                )?.label
                                            )} ${getFormattedDate(selectedDocument._imageDate)} `}
                                            {selectedDocument.version ?? ''}
                                        </>
                                    )
                                ) : (
                                    ''
                                )}
                            </button>
                            <div
                                className={`dropdown-menu view_documents_dropdown ${
                                    isMobile ? 'override-transform-mobile' : 'override-transform'
                                }`}
                                aria-labelledby='documentsMenuButton'
                            >
                                <span className={'dropdown-title d-flex justify-content-start align-items-center font-weight-bold'}>
                                    <FontAwesomeIcon icon={faFile} className={'mr-2 icon'} />
                                    {translateHydration('SelectADocument_Label')}
                                </span>
                                {viewImages
                                    .find((val) => val.entry === selectedEntry)
                                    ?.documents.map((val, i) => (
                                        <div
                                            key={i}
                                            className={`dropdown-item d-flex justify-content-between align-items-stretch`}
                                            onClick={() => setSelectedDocument(val)}
                                            role='button'
                                        >
                                            <div className='mr-3 d-flex align-items-center'>
                                                {isClearances ? (
                                                    // Clearances label
                                                    <>
                                                        {`${translate(
                                                            val._metadataFormat.trim().toUpperCase().replaceAll(' ', '') + '_Label'
                                                        )} ${getFormattedDate(val._imageDate)} `}
                                                        {val.version ?? ''}
                                                    </>
                                                ) : (
                                                    // Documents label
                                                    <>
                                                        {`${translate(
                                                            docTypeFiltersHashMap.find(
                                                                (type) => type.id === val._metadataFormat.trim().toUpperCase()
                                                            )?.label
                                                        )} ${getFormattedDate(val._imageDate)} `}
                                                        {val.version ?? ''}
                                                    </>
                                                )}
                                            </div>

                                            {renderSecondaryDescription(val.secondaryDescription, val._metadataFormat)}
                                        </div>
                                    ))}
                            </div>
                        </div>

                        <button
                            type='button'
                            className={`btn btn-tertiary ml-1 d-none d-md-inline-block ${isMobile ? 'btn-sm' : ''}`}
                            data-toggle={'tooltip'}
                            data-placement={'bottom'}
                            onClick={() => switchDocuments('prev')}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        <button
                            type='button'
                            className={`btn btn-tertiary ml-1 d-none d-md-inline-block ${isMobile ? 'btn-sm' : ''}`}
                            data-toggle={'tooltip'}
                            data-placement={'bottom'}
                            onClick={() => switchDocuments('next')}
                        >
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </div>
                </div>

                {/* Right section */}
                <div className={`col-${isMobile ? '4' : '2'} col-sm-${isMobile ? '4' : '2'} col-md-2 col-lg-4 text-right`}>
                    <button
                        type='button'
                        className={`btn btn-tertiary mr-1 d-none d-md-inline-block ${isMobile ? 'btn-sm' : ''}`}
                        onClick={handleDownloadImages}
                    >
                        <span className='mr-1 d-none d-lg-inline-block'>{translateHydration('DownloadAllDocuments_Label')}</span>
                        <FontAwesomeIcon icon={faDownload} />
                    </button>
                    <button
                        type='button'
                        className={`modal-close btn btn-tertiary-blue ${isMobile ? 'btn-sm' : ''}`}
                        data-dismiss='modal'
                        aria-label='Close'
                        onClick={handleCloseView}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ViewHeader;
