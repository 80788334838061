import { useMemo, useState } from 'react';
import { useSearchParams } from '../../../../common/hooks/useSearchParams';
import useTranslation from '../../../../common/hooks/useTranslation';
import { TEAM_SEARCH_PARAM_KEYS } from '../../common/constants/constants-team';
import FixedFooterWhite from '../../common/features/FixedFooterWhite';
import { useTeamMemberDetailsQuery } from '../../redux/api/teamApi';
import { useTeamSelector } from '../../redux/hooks';

interface EditTeamMemberFooterProps {
    isPending: boolean;
    isDisabled: boolean;
    error: { userDetails: boolean; clientsAndPermissions: boolean };
    onSubmit: () => void;
}

const EditTeamMemberFooter: React.FC<EditTeamMemberFooterProps> = ({
    isPending: isPendingProp,
    isDisabled: isDisabledProp,
    error,
    onSubmit
}) => {
    const { user } = useTeamSelector((state) => state.hydration);

    const [searchParams] = useSearchParams();
    const userId = parseInt(searchParams.get(TEAM_SEARCH_PARAM_KEYS.userId) || '');

    const { data, isLoading, isError } = useTeamMemberDetailsQuery(userId.toString());

    const isEditable = useMemo(() => user?.Roles?.includes('TeamManagement_Edit'), [user?.Roles]);

    const teamMember = data?.Users?.[0];
    const isPendingStatus =
        teamMember?.Status === 'PendingUserActivation' ||
        teamMember?.Status === 'PendingFeatureSetup' ||
        teamMember?.Status === 'EmailChangedConfirmRequired' ||
        teamMember?.Status === 'PendingUserConfirmRequired' ||
        teamMember?.Status === 'ActiveFeatureSetupInProgress';
    const isInactiveStatus =
        teamMember?.Status === 'Cancelled' ||
        teamMember?.Status === 'Deactivated' ||
        teamMember?.Status === 'Expired' ||
        teamMember?.Status === 'Hold';
    const isDisabled = isPendingStatus || isInactiveStatus;

    const [formIsSubmitted, setFormIsSubmitted] = useState(false);

    const translate = useTranslation();

    let message = '';

    if (isPendingStatus) {
        message = translate('PendingFeatureSetupLocked_Label');
    } else if (isInactiveStatus) {
        message = translate('InactiveTeamMemberLocked_Label');
    } else if (formIsSubmitted) {
        if (error.clientsAndPermissions) {
            message =
                data?.AdminRole === 'CentralAdmin'
                    ? translate('ClearancesFeatureMustBeSelected_Label')
                    : translate('MLPFeatureMustBeSelected_Label');
        } else if (error.userDetails) {
            message = translate('InvalidUserDetails_Label');
        }
    }

    const handleSavingUsers = () => {
        setFormIsSubmitted(true);

        if (!data) return;

        onSubmit();
    };

    if (!isEditable || isLoading || isError) {
        return null;
    }

    return (
        <FixedFooterWhite
            primaryLabel={translate('TeamMemberSaveChanges_Label')}
            primaryOnClick={handleSavingUsers}
            primaryDisabled={isDisabled || isDisabledProp}
            isPending={isPendingProp}
            message={message}
        />
    );
};

export default EditTeamMemberFooter;
