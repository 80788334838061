import { initializeGTM } from '../../../utils/gtm';
import { API_HYDRATION_PATH, Translations } from '../../common/constants/constants-portal';
import type { HydrationData } from '../../common/models/ResponseData';
import { setUser } from '../reducers/hydrationSlice';
import { apiSlice } from './apiSlice';

export const hydrationApi = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        hydration: build.query<HydrationData['Hydration'], void>({
            query: () => ({
                url: `${API_HYDRATION_PATH}/hydration`,
                method: 'POST',
                body: {
                    phraseGroup: Translations.PhraseGroups.Hydration
                }
            }),
            async onQueryStarted(_arg, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    const userId = data?.UserId;
                    const languageCode = data?.LanguageCode ?? 'en';

                    dispatch(setUser(data));

                    initializeGTM(userId, languageCode);
                } catch (error) {
                    console.error('Error initializing GTM', error);
                }
            },
            transformResponse: (response: HydrationData) => {
                return response.Hydration;
            }
        })
    })
});

export const { useHydrationQuery } = hydrationApi;
