import { useEffect, useState } from 'react';
import FilterDropdown from '../../../../../common/features/FilterDropdown';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { MOTFilter as MOTFilterOption } from '../../../../documents/common/constants/constants-documents';
import { MOTHashMap } from '../../../common/constants/complete-entry-data-constants';
import { useCompleteEntryDataDispatch, useCompleteEntryDataSelector } from '../../../redux/hooks';
import { updateMOTFilter, updateShouldSubmitSearch } from '../../../redux/reducers/completeEntryDataSlice';
import { setSelectedArraySelectedStringArray } from '../../../redux/reducers/motFilterSlice';

const MOTFilter = () => {
    const { selectedStringArray } = useCompleteEntryDataSelector((state) => state.motFilter);
    const translate = useTranslation();
    const dispatch = useCompleteEntryDataDispatch();

    const [selectedOptionsArray, setSelectedOptionsArray] = useState<number[] | null>(null);

    useEffect(() => {
        if (selectedOptionsArray) {
            dispatch(updateShouldSubmitSearch(true));
        }
        if (selectedStringArray.length === 0) {
            setSelectedOptionsArray([]);
        }
    }, [selectedStringArray, dispatch]);

    const handleApply = (id: string, selectedArray: number[]) => {
        setSelectedOptionsArray(selectedArray);
        const selectedMOT = selectedArray.map((i) => {
            return Object.values(MOTHashMap)[i];
        });
        dispatch(setSelectedArraySelectedStringArray(selectedMOT));
        dispatch(updateMOTFilter(selectedMOT));
    };

    return (
        <FilterDropdown
            selectAllEnabled={false}
            id={'MOT'}
            name={translate('ModeOfTransport_Label')}
            options={MOTFilterOption}
            optionsLabel={translate('FilterByTransport_Label')}
            selection={selectedOptionsArray}
            handleSelection={handleApply}
        />
    );
};

export default MOTFilter;
