import React, { Component } from 'react';
import TeamMemberRow from './TeamMemberRow';

class TeamMembersGrid extends Component {
    state = { IsHidden: false };
    toggleTeamMembers = () => {
        this.setState((prevState) => {
            return {
                IsHidden: !prevState.IsHidden
            };
        });
    };

    render() {
        const teamMembers = this.props.teamMembers.map((m, index) => {
            return <TeamMemberRow key={m.UserId} memberIndex={index} teamMember={m} />;
        });

        let teamTable = (
            <>
                <br />
                <table className='table table-striped team-grid'>
                    <thead>
                        <tr>
                            <th>{this.props.translations.Phrases['FirstName_Label']}</th>
                            <th>{this.props.translations.Phrases['LastName_Label']}</th>
                            <th>{this.props.translations.Phrases['Email_Label']}</th>
                        </tr>
                    </thead>
                    <tbody>{teamMembers}</tbody>
                </table>
            </>
        );

        const toggleIconClass = this.state.IsHidden ? 'fa fa-caret-right' : 'fa fa-caret-down';
        const content = this.state.IsHidden ? '' : teamTable;

        return (
            <div className='row w-100'>
                <div className='col-sm-12'>
                    <h3 onClick={this.toggleTeamMembers} className='bold'>
                        <i className={toggleIconClass}></i> Team Members
                    </h3>
                    {content}
                </div>
            </div>
        );
    }
}

export default TeamMembersGrid;
