import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { Component } from 'react';

class Calendar extends Component {
    static defaultProps = {
        today: new Date().setHours(0, 0, 0, 0),
        startDate: null,
        endDate: null,
        name: '',
        locale: window.navigator.language || window.navigator.systemLanguage
    };

    static propTypes = {
        name: PropTypes.string,
        onDateChanged: PropTypes.func,
        customAction: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = { currentMonth: this.getCurrentMonth(this.props.date ? new Date(this.props.date) : new Date()) };
    }

    getDaysInMonth = (d) => {
        const month = d.getMonth();
        let date = new Date(d.getFullYear(), month, 1);
        let daysInWeeks = [];
        let weekIndex = 0;

        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        firstDay.setDate(firstDay.getDate() - firstDay.getDay());

        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        lastDay.setDate(lastDay.getDate() - lastDay.getDay() + 6);

        let dayIndex;

        while (firstDay <= lastDay) {
            dayIndex = firstDay.getDay();

            if (!daysInWeeks[weekIndex]) daysInWeeks[weekIndex] = [];

            daysInWeeks[weekIndex][dayIndex] = new Date(firstDay.getTime());

            firstDay.setDate(firstDay.getDate() + 1);

            weekIndex = dayIndex === 6 ? weekIndex + 1 : weekIndex;
        }

        return daysInWeeks;
    };

    getCurrentMonth = (date) => {
        dayjs.locale(this.props.locale);
        let momentDate = dayjs(date);
        const month = momentDate.format('MMMM');
        const year = momentDate.format('YYYY');

        return { monthName: month, yearName: year, year: date.getFullYear(), month: date.getMonth(), weeks: this.getDaysInMonth(date) };
    };

    moveMonth = (amount) => {
        const currentMonth = this.state.currentMonth;
        let date = new Date(currentMonth.year, currentMonth.month, 1);
        date.setMonth(date.getMonth() + amount);

        this.setState({ currentMonth: this.getCurrentMonth(date) });
    };

    selectMonth = (e) => {
        const currentMonth = this.state.currentMonth.month;
        const selectedMonth = e.target.value;
        this.moveMonth(selectedMonth - currentMonth);
    };

    selectYear = (e) => {
        const currentMonth = this.state.currentMonth.month;
        const year = e.target.value;
        const date = new Date(year, currentMonth, 1);
        this.setState({ currentMonth: this.getCurrentMonth(date) });
    };

    weekDays = () => {
        let date = new Date();
        date.setDate(date.getDate() - date.getDay());
        dayjs.locale(this.props.locale);
        let momentDate = dayjs(date);

        return [0, 1, 2, 3, 4, 5, 6].map((i) => {
            const result = (
                <th key={i} className='text-center'>
                    {momentDate.format('ddd')}
                </th>
            );
            date.setDate(date.getDate() + 1);
            momentDate = dayjs(date);
            return result;
        });
    };

    onSelect = (date) => {
        let dateString = '';
        if (this.props.customFormat) {
            dateString = this.props.customFormat(date);
        } else {
            dateString = date.toISOString();
        }

        this.setState({ currentMonth: this.getCurrentMonth(date) });

        this.props.onDateChanged(dateString, this.props.name);

        if (this.props.customAction) {
            this.props.customAction();
        }
    };

    clearDate = () => {
        this.setState({ currentMonth: this.getCurrentMonth(new Date()) });

        this.props.onDateChanged(null, this.props.name);

        if (this.props.customAction) {
            this.props.customAction();
        }
    };

    render() {
        const currentMonth = this.state.currentMonth;
        let startDate = this.props.startDate ? new Date(this.props.startDate.getTime()) : null;
        let endDate = this.props.endDate ? new Date(this.props.endDate.getTime()) : null;
        if (startDate) startDate.setHours(0, 0, 0, 0);
        if (endDate) endDate.setHours(0, 0, 0, 0);

        let weeks = currentMonth.weeks.map((w, weekIndex) => {
            let days = w.map((d, dayIndex) => {
                let className = 'btn btn-link';
                let clickHandler = () => this.onSelect(d);

                if ((startDate && d < startDate) || (endDate && d > endDate)) {
                    className = 'btn disabled';
                    clickHandler = null;
                } else if (d.getMonth() !== currentMonth.month) {
                    className = 'btn';
                }

                if (d.getTime() === new Date(this.props.selectedDate + ' 00:00:00').getTime()) {
                    className = 'btn btn-primary';
                }

                return (
                    <td key={'day-' + dayIndex}>
                        <span className={className} onClick={clickHandler}>
                            {d.getDate()}
                        </span>
                    </td>
                );
            });

            return <tr key={'week-' + weekIndex}>{days}</tr>;
        });

        let monthNames = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((x) => {
            const dateOption = new Date(currentMonth.year, x, 1);
            return (
                <option key={x} value={x}>
                    {dayjs(dateOption).format('MMMM')}
                </option>
            );
        });

        let yearRange = [];
        const currentYear = currentMonth.year;
        for (let i = currentYear - 50; i <= currentYear + 50; i++) {
            yearRange.push(i);
        }

        let years = yearRange.map((x) => {
            return (
                <option key={x} value={x}>
                    {x}
                </option>
            );
        });

        const weekDays = this.weekDays();

        const todayInRange = (!startDate || startDate < this.props.today) && (!endDate || endDate > this.props.today);

        const today = todayInRange ? (
            <span className='btn btn-link' onClick={() => this.onSelect(new Date(this.props.today))}>
                Today
            </span>
        ) : null;

        return (
            <table className='modal-margin'>
                <thead>
                    <tr>
                        <th>
                            <i className='fa fa-chevron-circle-left btn text-center' onClick={() => this.moveMonth(-1)}></i>
                        </th>
                        <th colSpan='5' className='text-center'>
                            <select className='modal-border' onChange={(e) => this.selectMonth(e)} value={currentMonth.month}>
                                {monthNames}
                            </select>
                            <select className='modal-border' onChange={(e) => this.selectYear(e)} value={currentMonth.year}>
                                {years}
                            </select>
                        </th>
                        <th>
                            <i className='fa fa-chevron-circle-right btn text-center' onClick={() => this.moveMonth(1)}></i>
                        </th>
                    </tr>
                    <tr>{weekDays}</tr>
                </thead>
                <tbody>{weeks}</tbody>
                <tfoot>
                    <tr>
                        <td colSpan='7'>
                            <span className='btn btn-link' onClick={() => this.clearDate()}>
                                Clear
                            </span>
                            {today}
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    }
}

export default Calendar;
