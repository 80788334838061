import { useCallback, useEffect, useState } from 'react';
import { AllEntries } from '../models/ImagingModel';

interface UseHeaderSelectionProps {
    allEntries: AllEntries;
    selectedEntries: string[] | [];
    dispatchSelection: (payload: boolean) => void;
}

const useHeaderSelection = ({ allEntries, selectedEntries, dispatchSelection }: UseHeaderSelectionProps) => {
    const [headerCheckbox, setHeaderCheckbox] = useState(false);
    useEffect(() => {
        const allEntriesLength = Object.entries(allEntries).length;
        setHeaderCheckbox(selectedEntries.length === allEntriesLength && allEntriesLength ? true : false);
    }, [selectedEntries, allEntries]);

    const handleHeaderCheckbox = useCallback(() => {
        setHeaderCheckbox(!headerCheckbox);
        dispatchSelection(headerCheckbox);
    }, [headerCheckbox, dispatchSelection]);

    return { headerCheckbox, handleHeaderCheckbox };
};

export default useHeaderSelection;
