import { VariationPlacement } from '@popperjs/core';
import { GridCellProps } from '@progress/kendo-react-grid';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { TabNavigationIds } from '../../../../common/constants/constants-portal';
import ActionButtonElipsis from '../../../../common/features/Grid/ActionButtonElipsis';
import useTranslation from '../../../../common/hooks/useTranslation';
import { GetCustomsInfoID } from '../../../../redux/actions/ellipsis';
import { setInitialEllipsisState } from '../../../../redux/reducers/ellipsisSlice';
import { EntryClientPair } from '../../../clearances/common/models/CADDetails';
import { DownloadDocuments } from '../../../clearances/redux/actions/clearanceDocuments';
import { DownloadCASSShipment } from '../../redux/actions/completeEntryData';
import { useCompleteEntryDataDispatch, useCompleteEntryDataSelector } from '../../redux/hooks';
import { CASSActionButtonOptions } from '../constants/complete-entry-data-constants';

interface ActionButtonProps extends GridCellProps {
    disabled: boolean;
    placement?: VariationPlacement;
}

const ActionButton = memo(function ActionButton({ disabled, dataItem, className, style, placement, ...rest }: ActionButtonProps) {
    const { ellipsisData } = useCompleteEntryDataSelector((state) => state.ellipsis);
    const { clientSelection } = useCompleteEntryDataSelector((state) => state.clientSelection);
    const translate = useTranslation();
    const dispatch = useCompleteEntryDataDispatch();
    const { push } = useHistory();

    const entryClientPair: EntryClientPair = {
        EntryNumber: dataItem?.TransactionNumber,
        ClientNumber: dataItem?.LiiIor
    };

    const handleAction = (optionId: (typeof CASSActionButtonOptions)[number]['id']) => {
        switch (optionId) {
            case 'VIEW_DETAILS':
                onViewDetails();
                break;
            case 'GRID_COLUMN': {
                dispatch(
                    DownloadCASSShipment({
                        entries: [entryClientPair],
                        returnAllFields: false
                    })
                );
                break;
            }
            case 'FULL_CAD': {
                dispatch(
                    DownloadCASSShipment({
                        entries: [entryClientPair],
                        returnAllFields: true
                    })
                );
                break;
            }
            case 'DOCUMENTS': {
                if (!dataItem?.TransactionNumber) return;

                return dispatch(
                    DownloadDocuments({
                        entryNumbers: { allEntries: [dataItem.TransactionNumber] }
                    })
                );
            }
            default:
                break;
        }
    };

    const onViewDetails = () => {
        localStorage.setItem('activeTab', TabNavigationIds.Clearances[0]);
        if (ellipsisData.customsInfoID) {
            push(`/Clearances/${ellipsisData.customsInfoID}?origin=cad`);
        }
    };

    const getCustomsInfoID = (entryNo: string) => {
        if (entryNo) {
            const clientNumbers: string[] = clientSelection
                ? clientSelection.ClientServices.map((x) => {
                      if (dataItem.LiiIor && dataItem.LiiIor === `${x.Number}${x.Caption}`) {
                          return x.Number + '-' + x.Caption;
                      }
                  }).filter((value): value is string => value !== undefined)
                : [];

            dispatch(GetCustomsInfoID({ DocumentEntryNumber: entryNo, ClientNumbers: clientNumbers }));
        } else {
            dispatch(setInitialEllipsisState());
        }
    };

    return (
        <ActionButtonElipsis
            options={CASSActionButtonOptions.map((option) => ({
                ...option,
                label: translate(option.label)
            }))}
            dangerOption={''}
            onAction={(option) => handleAction(option as (typeof CASSActionButtonOptions)[number]['id'])}
            disabled={disabled}
            dataItem={dataItem}
            className={className}
            style={style}
            placement={placement}
            handleActionButtonForDocumentsGrid={getCustomsInfoID}
            ellipsisData={ellipsisData}
            currentRow={entryClientPair.EntryNumber}
            {...rest}
        />
    );
});

export default ActionButton;
