import { faChevronLeft, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import Badge from '../../../../../common/features/Badge';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { parseBillOfLading, parseDateTimeET } from '../../../common/functions/parser';
import { useClearancesSelector } from '../../../redux/hooks';
import DownloadDropdown from './downloaddropdown/DownloadDropdown';
import NotificationsDropdown from './notificationsdropdown/NotificationsDropdown';

const ShipmentSummary = () => {
    const { shipmentDetails } = useClearancesSelector((state) => state.shipmentDetails);

    const translate = useTranslation();

    const history = useHistory();
    const [searchParams] = useSearchParams();

    return (
        <>
            <div className='container-fluid px-0'>
                <div className='position-relative'>
                    <div className='d-md-flex'>
                        <div className='mb-2 mr-md-3 mt-md-2'>
                            <button
                                type='button'
                                className='btn btn-tertiary'
                                onClick={() => history.push(searchParams.get('origin') === 'documents' ? '/Documents' : '/Clearances')}
                            >
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </button>
                        </div>
                        <div className='d-xl-flex justify-content-between align-items-center w-100'>
                            <div>
                                <div className='d-md-flex flex-wrap align-items-md-center'>
                                    <h1 className='entry-no flex-shrink-0 mb-0 mr-3'>
                                        {translate('EntryNo_Label')} {shipmentDetails?.EntryNumber}
                                    </h1>
                                    <div>
                                        <span>
                                            {/* Badge: In progress */}
                                            {shipmentDetails?.ClearanceStatus?.toUpperCase() === 'IN_PROGRESS' && (
                                                <Badge type='info' className={'mt-2 mb-1 mr-1'}>
                                                    {translate('StatusInProgress_Label')}
                                                </Badge>
                                            )}
                                            {/* Badge: On hold */}
                                            {shipmentDetails?.ClearanceStatus?.toUpperCase() === 'ON_HOLD' && (
                                                <Badge type='danger' className={'mt-2 mb-1 mr-1'}>
                                                    <FontAwesomeIcon icon={faExclamationCircle} className='mr-1' />
                                                    {translate('StatusOnHold_Label')}
                                                </Badge>
                                            )}
                                            {/* Badge: Completed */}
                                            {shipmentDetails?.ClearanceStatus?.toUpperCase() === 'COMPLETED' && (
                                                <Badge type='success' className={'mt-2 mb-1 mr-1'}>
                                                    {translate('StatusCompleted_Label')}
                                                </Badge>
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div className='d-md-flex justify-content-between align-items-center w-100 my-2'>
                                    <div className='d-md-flex'>
                                        <div className='shipment-info flex-shrink-0 mr-md-3'>
                                            <span className='mr-2'>
                                                <strong>{translate('LastModified_Label')}:</strong>
                                            </span>
                                            <span>{parseDateTimeET(shipmentDetails?.ModifiedOn)}</span>
                                        </div>
                                        <div className='shipment-info flex-shrink-0 mr-md-3'>
                                            <span className='mr-2'>
                                                <strong>{translate('ClientNo_Label')}:</strong>
                                            </span>
                                            <span>{shipmentDetails?.ClientCaptionNumber}</span>
                                        </div>
                                        <div className='shipment-info mr-md-3 d-flex' style={{ minWidth: 0 }}>
                                            <span className='d-inline-block mr-2 '>
                                                <strong>{translate('BOL_Label')}:</strong>
                                            </span>
                                            <span className='d-inline-block text-truncate'>
                                                {shipmentDetails?.BillOfLading
                                                    ? shipmentDetails.Destination === 'USA'
                                                        ? parseBillOfLading(shipmentDetails?.BillOfLading, ['MasterBill'])
                                                        : parseBillOfLading(shipmentDetails?.BillOfLading, ['MasterBill', 'HouseBill'])
                                                    : 'N/A'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-start justify-content-xl-end flex-wrap mt-3 mt-xl-0'>
                                <DownloadDropdown />
                                <div className='mr-1 m-xl-1'>
                                    <NotificationsDropdown />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShipmentSummary;
