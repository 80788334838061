import $ from 'jquery';
import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actionCreators from '../../store/actions/index';

class BasicError extends Component {
    componentDidMount() {
        $('#overlay').show();
        this.props.onInitTranslations('Error.BasicError');
    }

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
            $('#overlay').hide();
        }
    }

    render() {
        if (this.props.translations.PhraseGroupCode === 'Error.BasicError') {
            const header = this.props.translations.Phrases['Page_Header'];
            const text = this.props.translations.Phrases['Text_BasicError'];
            return (
                <section className='page-wrapper page-error'>
                    <HelmetProvider>
                        <Helmet>
                            <title>{this.props.translations.Phrases['Page_Title']}</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className='error-icon'>
                        <i className='fa fa-exclamation-triangle'></i>
                    </div>
                    <span className='error-header text-danger'>{header}</span>
                    <div>
                        <span className='error-text'>{text}</span>
                        <span className='error-text'>
                            <i className='fa fa-arrow-circle-left'></i>
                            <button className='anchor-button' onClick={this.props.history.goBack}>
                                {this.props.translations.Phrases['BackToPreviousPage_Label']}
                            </button>
                        </span>
                    </div>
                </section>
            );
        } else {
            return <section className='page-wrapper page-error'></section>;
        }
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BasicError));
