import React, { useEffect, useState } from 'react';
import { CANotifications, USNotifications } from '../constants/constants-portal';
import useTranslation from '../hooks/useTranslation';
import { Notifications, NotificationsData } from '../models/ResponseData';
import Button from './Button';
import Checkbox from './Checkbox';
import Tooltip from './Tooltip/Tooltip';

interface MilestonesSelectionProps {
    country: string;
    showSelectAll: boolean;
    showCountry: boolean;
    notifications: null | Notifications;
    noneSelected?: (noneSelected: boolean) => void;
    handleMilestonesChange: (obj: null | Notifications, notification: null | string, value: null | string | boolean) => void;
}

const MilestonesSelection: React.FC<MilestonesSelectionProps> = ({
    country,
    showSelectAll = false,
    showCountry = false,
    notifications,
    noneSelected,
    handleMilestonesChange
}) => {
    const notificationsConstants = country === 'ca' ? CANotifications : USNotifications;
    const countryExtension = country === 'ca' ? '_CA' : '';
    const [checkBoxes, setCheckBoxes] = useState<any>({});
    const [selectAll, setSelectAll] = useState(false);
    const translate = useTranslation();

    useEffect(() => {
        if (notifications) {
            let objCheckBoxes = {} as any;
            notificationsConstants.forEach((item) => {
                objCheckBoxes = {
                    ...objCheckBoxes,
                    [`${item.key}${countryExtension}`]: notifications![`${item.key}${countryExtension}` as keyof Notifications]
                };
            });
            setCheckBoxes(objCheckBoxes);
        }
    }, [notifications, notificationsConstants, countryExtension]);

    useEffect(() => {
        setSelectAll(Object.keys(checkBoxes).every((k) => checkBoxes[k]) ? false : true);
        if (noneSelected) noneSelected(Object.keys(checkBoxes).every((k) => checkBoxes[k] === false));
    }, [checkBoxes, noneSelected]);

    const handleCheckbox = (key: string, value: boolean) => {
        setCheckBoxes({ ...checkBoxes, [key]: value === true });
        handleMilestonesChange({ ...checkBoxes, [key]: value === true }, null, null);
    };

    const handleSelectAll = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        let objCheckBoxes = {} as any;
        Object.entries(checkBoxes).forEach(([key, value]) => {
            objCheckBoxes = { ...objCheckBoxes, [key]: selectAll ? true : false };
            setCheckBoxes(objCheckBoxes);
        });
        handleMilestonesChange(objCheckBoxes, null, null);
    };

    return (
        <>
            {showCountry && (
                <div style={{ fontWeight: 'bold', marginTop: '2vh', marginBottom: '1vh' }}>{country === 'us' ? 'USA' : 'Canada'}</div>
            )}
            {showSelectAll && (
                <Button className='mb-2' variant='link' onClick={handleSelectAll}>
                    {selectAll
                        ? translate('NotificationsSelectAllNotifications_Label')
                        : translate('NotificationsDeselectAllNotifications_Label')}
                </Button>
            )}
            {React.Children.toArray(
                notificationsConstants?.map((item, index) => {
                    return (
                        <>
                            {!item.key!.includes('OnHold') && (
                                <div className='custom-control custom-checkbox mb-1'>
                                    <Checkbox
                                        id={`${item.key}${countryExtension}`}
                                        name={`${item.key}${countryExtension}`}
                                        isChecked={!!checkBoxes[`${item?.key!}${countryExtension}` as keyof NotificationsData]}
                                        onChange={handleCheckbox}
                                    >
                                        <Tooltip label={translate(item.hint)}>
                                            <span>{translate(item.label)}</span>
                                        </Tooltip>
                                    </Checkbox>
                                </div>
                            )}
                        </>
                    );
                })
            )}
        </>
    );
};

export default MilestonesSelection;
