import { useEffect } from 'react';

type Tab = string;

interface TabNavProps {
    tabs: Tab[];
    defaultTab?: Tab;
    className?: string;
    withDropdown?: boolean;
    children: React.ReactNode;
}

const TabNav: React.FC<TabNavProps> = ({ tabs, defaultTab, className = '', withDropdown = false, children }) => {
    useEffect(() => {
        const activeTab = localStorage.getItem('activeTab');

        if (!activeTab && !defaultTab && tabs.length) {
            localStorage.setItem('activeTab', tabs[0]);
        } else if (activeTab && !defaultTab && tabs.length && !tabs.includes(activeTab)) {
            localStorage.setItem('activeTab', tabs[0]);
        } else if (!activeTab && defaultTab) {
            localStorage.setItem('activeTab', defaultTab);
        }
    }, [defaultTab, tabs]);

    return (
        <nav className={`nav-tabs-wrapper ${className}`}>
            <div className={`nav nav-tabs ${withDropdown ? 'nav-tabs-with-dropdown' : ''}`} role='tablist'>
                {children}
            </div>
        </nav>
    );
};

export default TabNav;
