import { createSlice } from '@reduxjs/toolkit';
import { UsersTicketsSlice } from '../../common/models/ReduxSlices';
import { GetUsersTickets } from '../actions/tickets';

const initialState: UsersTicketsSlice = {
    usersTickets: null,
    isLoading: false,
    error: undefined
};

const usersTickets = createSlice({
    name: 'usersTickets',
    initialState,
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(GetUsersTickets.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(GetUsersTickets.fulfilled, (state, action) => {
                state.usersTickets = action.payload;
                state.isLoading = false;
            })
            .addCase(GetUsersTickets.rejected, (state, action) => {
                state.isLoading = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export default usersTickets.reducer;
