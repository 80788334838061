import { useCallback } from 'react';
import { usePortalDispatch } from '../../../redux/hooks';
import { clearToast, sendToast } from '../../../redux/reducers/toastSlice';
import { StatusAlertProps } from '../StatusAlert';

const useToast = () => {
    const dispatch = usePortalDispatch();

    const send = useCallback(
        (props: StatusAlertProps) => {
            dispatch(sendToast(props));
        },
        [dispatch]
    );

    const clear = useCallback(() => {
        dispatch(clearToast());
    }, [dispatch]);

    return { send, clear };
};

export default useToast;
