import produce from 'immer';
import * as actionTypes from '../actions/translations/translationsActionTypes';

const initialState = {
    model: {
        LanguageCode: '',
        PhraseGroupCode: '',
        Phrases: []
    },
    headerModel: {
        LanguageCode: '',
        PhraseGroupCode: '',
        Phrases: []
    },
    isTranslationsUnavailable: false
};

const setTranslations = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.model;
    });
};

const setHeaderTranslations = (state, action) => {
    return produce(state, (draft) => {
        draft.headerModel = action.model;
    });
};

const setTranslationsUnavailableFlag = (state, action) => {
    return produce(state, (draft) => {
        draft.isTranslationsUnavailable = action.flag;
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TRANSLATIONS:
            return setTranslations(state, action);
        case actionTypes.SET_HEADER_TRANSLATIONS:
            return setHeaderTranslations(state, action);
        case actionTypes.SET_TRANSLATIONS_UNAVAILABLE:
            return setTranslationsUnavailableFlag(state, action);
        default:
            return state;
    }
};

export default reducer;
