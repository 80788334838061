import type { PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { StatusFilterSlice } from '../../common/models/ReduxSlices';

export const createGenericStatusFilterSlice = <Reducers extends SliceCaseReducers<StatusFilterSlice>>({
    name = '',
    initialState,
    reducers
}: {
    name: string;
    initialState: StatusFilterSlice;
    reducers: ValidateSliceCaseReducers<StatusFilterSlice, Reducers>;
}) => {
    return createSlice({
        name,
        initialState,
        reducers: {
            setSelectedStatus(state, action: PayloadAction<number>) {
                state.selectedStatus = state.statuses[action.payload];
            },
            ...reducers
        }
    });
};
