import { REACT_APP_ASSETS_URL } from '../../../../../common/constants/constants-portal';
import Button from '../../../../../common/features/Button';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { useClearancesDispatch } from '../../../redux/hooks';
import { resetAdvancedSearchSettings } from '../../../redux/reducers/advancedSearchSettingsSlice';
import { resetDateRange } from '../../../redux/reducers/dateRangeSlice';
import { resetSearchFilter } from '../../../redux/reducers/searchSlice';
import { resetShipmentGridSettings } from '../../../redux/reducers/shipmentGridSettingsSlice';
import { resetStatus } from '../../../redux/reducers/shipmentStatusSlice';

const NoSearchResults = () => {
    const dispatch = useClearancesDispatch();
    const translate = useTranslation();

    const clearFilters = () => {
        dispatch(resetDateRange());
        dispatch(resetStatus());
        dispatch(resetSearchFilter());
        dispatch(resetAdvancedSearchSettings());
        dispatch(resetShipmentGridSettings());
    };

    return (
        <>
            <img
                className='image-150px '
                src={`${REACT_APP_ASSETS_URL}/assets/images/illustrations/scenes/scene-error-404-page-not-found.svg`}
                alt='404 page not found'
            />
            <h3 className='mb-2'>{translate('NoSearchResultsFound_Label')}</h3>
            <p className='w-300px text-center'>{translate('AdvancedSearchResultsBlankState_Label')}</p>
            <Button variant='link' onClick={clearFilters}>
                {translate('ClearSearchResults_Label')}
            </Button>
        </>
    );
};

export default NoSearchResults;
