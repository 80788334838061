import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CASSDataModel, CASSModel } from '../../common/models/completeEntryDataModels';
import { DownloadCASSShipment, GetCASSData, GetCASSIndex } from '../actions/completeEntryData';

const initialState: CASSModel = {
    model: {} as CASSDataModel,
    searchKeywords: [],
    submitedSearchKeywords: [],
    isLoadingIndex: false,
    isLoading: false,
    shouldSubmitSearch: false,
    selectedEntries: [],
    downloadData: null,
    isLoadingDownload: false,
    isSearched: false,
    isFiltering: false
};

const imagingSlice = createSlice({
    name: 'completeEntryData',
    initialState,
    reducers: {
        updateSearchKeywords(state, action: PayloadAction<{ id: string; text: string }[]>) {
            state.searchKeywords = action.payload;

            if (state.model.Filters)
                state.model = {
                    ...state.model,
                    //@ts-ignore
                    Filters: [
                        ...state.model.Filters.map((filter) => {
                            if (filter.Name === 'Main Search') {
                                return {
                                    ...filter,
                                    Options: [
                                        ...filter.Options.map((opt) => ({
                                            ...opt,
                                            IsSelected: action.payload.map(({ text }) => text).length ? true : false
                                        }))
                                    ],
                                    Value: action.payload.map(({ text }) => text).join(',')
                                };
                            } else {
                                return filter;
                            }
                        })
                    ]
                };
        },
        updateSubmittedSearchKeywords(state, action: PayloadAction<{ id: string; text: string }[]>) {
            state.submitedSearchKeywords = action.payload;
        },
        resetSubmitedSearchKeywords(state) {
            state.submitedSearchKeywords = [];
        },
        updateShouldSubmitSearch(state, action: PayloadAction<boolean>) {
            state.shouldSubmitSearch = action.payload;
        },
        updatePageSize(state, action: PayloadAction<number>) {
            state.model = { ...state.model, PageSize: action.payload };
            state.shouldSubmitSearch = true;
        },
        updateStartRow(state, action: PayloadAction<number>) {
            state.model = { ...state.model, StartRow: action.payload };
            state.shouldSubmitSearch = true;
        },
        updateSearched(state, action: PayloadAction<boolean>) {
            state.isSearched = action.payload;
        },
        updateLocationTypeFilter(state, action: PayloadAction<string>) {
            if (state.model && state.model.Filters) {
                state.model = {
                    ...state.model,
                    //@ts-ignore
                    Filters: [
                        ...state.model.Filters.map((filter) => {
                            if (filter.Name === 'Location' && !filter.Attribute) {
                                return {
                                    ...filter,
                                    Options: filter.Options.map((option) => {
                                        return { ...option, IsSelected: option.Name === action.payload };
                                    })
                                };
                            } else if (
                                filter.Name !== action.payload &&
                                filter.Attribute !== action.payload &&
                                filter.Level === 'location'
                            ) {
                                return {
                                    ...filter,
                                    Options: filter.Options.map((option) => {
                                        return { ...option, IsSelected: false };
                                    })
                                };
                            } else {
                                return filter;
                            }
                        })
                    ]
                };
            }

            state.selectedEntries = [];
            state.model = { ...state.model, StartRow: 0 };
            state.isFiltering = true;
            //state.model.TotalLoadedResults = 0;
        },
        updateSelectLocationFilter(state, action: PayloadAction<{ attribute: string | undefined; selectedArray: string[] }>) {
            if (state.model && state.model.Filters) {
                state.model = {
                    ...state.model,
                    //@ts-ignore
                    Filters: [
                        ...state.model.Filters.map((filter) => {
                            if (filter.Name === action.payload.attribute) {
                                return {
                                    ...filter,
                                    Options: filter.Options.map((option) => {
                                        return {
                                            ...option,
                                            IsSelected: !!action.payload.selectedArray.find((item) => item === option.Name)
                                        };
                                    })
                                };
                            } else {
                                return filter;
                            }
                        })
                    ]
                };
            }

            state.selectedEntries = [];
            state.model = { ...state.model, StartRow: 0 };
            state.isFiltering = true;
            //state.model.TotalLoadedResults = 0;
        },
        updateMainSearchFilter(state, action: PayloadAction<{ id: string; text: string }[]>) {
            state.searchKeywords = action.payload;

            state.model = {
                ...state.model,
                //@ts-ignore
                Filters: [
                    ...state.model.Filters.map((filter) => {
                        if (filter.Name === 'Main Search') {
                            return {
                                ...filter,
                                Options: [
                                    ...filter.Options.map((opt) => ({
                                        ...opt,
                                        IsSelected: action.payload.map(({ text }) => text).length ? true : false
                                    }))
                                ],
                                Value: action.payload.map(({ text }) => text).join(',')
                            };
                        } else {
                            return filter;
                        }
                    })
                ]
            };

            state.selectedEntries = [];
            state.model = { ...state.model, StartRow: 0 };
            state.isFiltering = true;
            state.shouldSubmitSearch = true;
        },
        updateDateFilter(
            state,
            action: PayloadAction<{ attribute: string; selectedOption: string; selectedValue: string; startDate: string; endDate: string }>
        ) {
            if (state.model.Filters) {
                state.model = {
                    ...state.model,
                    //@ts-ignore
                    Filters: [
                        ...state.model.Filters.map((filter) => {
                            if (filter.Name === action.payload.attribute) {
                                return {
                                    ...filter,

                                    RangeEnd: action.payload.endDate !== '' ? action.payload.endDate : null,
                                    RangeStart: action.payload.startDate !== '' ? action.payload.startDate : null,
                                    Value: action.payload.selectedValue,
                                    Options: filter.Options.map((option) => {
                                        return {
                                            ...option,
                                            Value: action.payload.selectedValue,
                                            IsSelected:
                                                option.Name.toLocaleLowerCase() === action.payload.selectedOption.toLocaleLowerCase()
                                        };
                                    })
                                };
                            } else {
                                return filter;
                            }
                        })
                    ]
                };

                state.selectedEntries = [];
                state.model = { ...state.model, StartRow: 0 };
                state.isFiltering = true;
            }
        },
        updateMOTFilter(state, action: PayloadAction<string[]>) {
            state.model = {
                ...state.model,
                //@ts-ignore
                Filters: [
                    ...state.model.Filters.map((filter) => {
                        if (filter.Name === 'ModeTransportCode') {
                            return {
                                ...filter,
                                Options: filter.Options.map((option) => {
                                    const optionName = action.payload.find((item) => item === option.Name);
                                    return {
                                        ...option,
                                        IsSelected: !!optionName
                                    };
                                })
                            };
                        } else {
                            return filter;
                        }
                    })
                ]
            };

            state.selectedEntries = [];
            state.model = { ...state.model, StartRow: 0 };
            state.isFiltering = true;
        },
        updateSelection(state, action: PayloadAction<string>) {
            if (state.model.Shipments.length) {
                let entries = [...state.selectedEntries];
                const found = entries.find((item) => item === action.payload);
                // if item exists remove it
                if (found) {
                    entries = entries
                        .map((item) => {
                            return item !== action.payload ? item : '';
                        })
                        .filter((item) => {
                            return item.length;
                        });
                    state.selectedEntries = entries;
                    //if item doesn't exist add it
                } else {
                    entries.push(action.payload);
                    state.selectedEntries = entries;
                }
            }
        },
        updateSelectionSelectAll(state, action: PayloadAction<boolean>) {
            const arrayCopy = [...state.model.Shipments];
            const prevSelected = [...state.selectedEntries];

            const uniqueShipments = arrayCopy.filter(
                (a, i) => arrayCopy.findIndex((s) => a.TransactionNumber === s.TransactionNumber) === i
            );

            const allToSelect = [...prevSelected, ...uniqueShipments.map((item) => item.TransactionNumber)];
            const uniqueAll = allToSelect.filter((a, i) => allToSelect.indexOf(a) === i);

            if (action.payload) {
                // Select all
                state.selectedEntries = uniqueAll;
            } else {
                // Deselect all
                state.selectedEntries = prevSelected.filter((no) => !arrayCopy.find((entry) => entry.TransactionNumber === no));
            }
        },
        resetSelection(state) {
            state.selectedEntries = initialState.selectedEntries;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(GetCASSIndex.pending, (state, action) => {
                state.isLoadingIndex = true;
                state.error = undefined;
            })
            .addCase(GetCASSIndex.fulfilled, (state, action) => {
                state.model = action.payload;
                state.isLoadingIndex = false;
                state.shouldSubmitSearch = true;
            })
            .addCase(GetCASSIndex.rejected, (state, action) => {
                state.isLoadingIndex = false;
                state.shouldSubmitSearch = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(GetCASSData.pending, (state, action) => {
                state.isLoading = true;
                state.error = undefined;
            })
            .addCase(GetCASSData.fulfilled, (state, action) => {
                state.model = action.payload;
                state.isLoading = false;
                state.shouldSubmitSearch = false;
                state.isFiltering = false;
            })
            .addCase(GetCASSData.rejected, (state, action) => {
                state.isLoading = false;
                state.shouldSubmitSearch = false;
                state.isFiltering = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            })
            // DOWNLOAD DOCUMENTS
            .addCase(DownloadCASSShipment.pending, (state, action) => {
                state.isLoadingDownload = true;
                state.error = undefined;
            })
            .addCase(DownloadCASSShipment.fulfilled, (state, action) => {
                state.isLoadingDownload = false;
                state.downloadData = action.payload;
            })
            .addCase(DownloadCASSShipment.rejected, (state, action) => {
                state.isLoadingDownload = false;
                if (typeof action.payload === 'number') {
                    state.error = action.payload;
                } else {
                    state.error = action.error.message;
                }
            });
    }
});

export const {
    updateSearchKeywords,
    updateShouldSubmitSearch,
    resetSubmitedSearchKeywords,
    updateSubmittedSearchKeywords,
    updatePageSize,
    updateStartRow,
    updateLocationTypeFilter,
    updateSelectLocationFilter,
    updateMainSearchFilter,
    updateSelection,
    updateDateFilter,
    updateSelectionSelectAll,
    resetSelection,
    updateMOTFilter,
    updateSearched
} = imagingSlice.actions;
export default imagingSlice.reducer;
