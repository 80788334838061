import React from 'react';
import Spinner from './Spinner';

interface FormInputFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
    hasError?: null | boolean;
    isValid?: null | boolean;
    isBold?: null | boolean;
    validationInProgress?: null | boolean;
    placeholder?: string;
    showLabel?: boolean;
    styleAdditional?: object;
    isActive?: boolean;
    maxLength?: number;
}

const FormInputField = React.forwardRef<HTMLInputElement, FormInputFieldProps>(
    (
        {
            label,
            hasError,
            isValid,
            isBold,
            validationInProgress,
            placeholder,
            showLabel = true,
            styleAdditional,
            isActive,
            maxLength,
            ...rest
        },
        ref
    ) => {
        return (
            <>
                {showLabel && (
                    <label htmlFor={rest.id} style={isBold ? { fontWeight: '500' } : undefined}>
                        {label}
                    </label>
                )}

                <div className='input-group' style={{ position: 'relative' }}>
                    {validationInProgress && (
                        <Spinner size='small' style={{ position: 'absolute', right: '2rem', top: '0.5rem', zIndex: 100 }} />
                    )}
                    <input
                        ref={ref}
                        className={`form-control ${hasError ? 'is-invalid' : ''} ${isValid ? 'is-valid' : ''}`}
                        style={styleAdditional ? { ...styleAdditional } : {}}
                        type={rest.type || 'text'}
                        placeholder={placeholder}
                        {...rest}
                        maxLength={maxLength}
                        disabled={isActive !== null && isActive !== undefined ? isActive : false}
                    ></input>
                </div>
            </>
        );
    }
);

export default FormInputField;
