import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import Navigation from './Navigation';
import TopBottomContainer from './TopBottomContainer';
import TopLeftContainer from './TopLeftContainer';

class Header extends Component {
    componentDidMount() {
        this.props.onInitTranslations('MLP.Header');
    }
    componentDidUpdate(prevProps) {
        if (this.props.signupLangCode && prevProps.signupLangCode && this.props.signupLangCode !== prevProps.signupLangCode) {
            this.props.onInitTranslations('MLP.Header', this.props.signupLangCode);
        }
    }
    render() {
        const bellElement =
            this.props.userModel.unreadNotices === true ? (
                <Link to='/Notices'>
                    <i className='fa fa-bell bell-orange'>&nbsp;&nbsp;&nbsp;</i>
                </Link>
            ) : null;

        const destElement =
            this.props.userModel.username !== undefined && this.props.userModel.username !== null ? (
                <i className='fa fa-user'>
                    <span className='logged-in-username'> {this.props.userModel.username} </span>
                </i>
            ) : (
                'Login'
            );

        return (
            <div>
                <div id='stickme-sticky-wrapper' className='header_wrap'>
                    <div id='stickme'>
                        <div className='lii_top_wrap'>
                            <div className='lii_top_inner'>
                                <div className=''>
                                    <TopLeftContainer data={this.props.model.topRowLeft}></TopLeftContainer>
                                    <Navigation
                                        data={this.props.model.topRowRight}
                                        cssClassList='lii_secondary-nav'
                                        cssClassListItem='a lii_menu-item lii_menu-item-type-post_type lii_menu-item-object-page'
                                    >
                                        <li
                                            id='new-notices'
                                            className='a lii_menu-item lii_menu-item-type-post_type lii_menu-item-object-page'
                                        >
                                            {bellElement}
                                        </li>
                                        <li
                                            id='logged-in-user'
                                            className='a lii_menu-item lii_menu-item-type-post_type lii_menu-item-object-page'
                                        >
                                            <Link to='/Dashboard'>{destElement}</Link>
                                        </li>
                                    </Navigation>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className='lii_section-content'>
                                <div className='lii_one-column-bucket'>
                                    <TopBottomContainer data={this.props.model.topRowBottom}></TopBottomContainer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        model: state.header.model,
        userModel: state.user.model,
        signupLangCode: state.clientAdmin.signup.Model.LanguageCode
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup, langCode) => dispatch(actions.initTranslations(phraseGroup, langCode))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
