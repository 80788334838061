import { GridCellProps } from '@progress/kendo-react-grid';
import ActionButtonElipsis from '../../../../common/features/Grid/ActionButtonElipsis';
import useTranslation from '../../../../common/hooks/useTranslation';
import { ActionButtonOptions } from '../../common/constants/constants-contact';
import { useContactsDispatch, useContactsSelector } from '../../redux/hooks';
import { setEditedRoleChecker } from '../../redux/reducers/contactsSlice';

interface ActionButtonProps extends GridCellProps {
    disabled: boolean;
    handleRemoveContact: (id?: null | number) => void;
    handleEditContact: (id?: null | number) => void;
}

export const ActionButton: React.FC<ActionButtonProps> = ({
    disabled,
    dataItem,
    className,
    style,
    handleRemoveContact,
    handleEditContact,
    ...rest
}) => {
    const translate = useTranslation();
    const { user } = useContactsSelector((state) => state.hydration);
    const dispatch = useContactsDispatch();
    const handleAction = (optionId: (typeof ActionButtonOptions)[number]['id']) => {
        switch (optionId) {
            case 'EDIT_CONTACT':
                handleEditContact(dataItem.RowId);
                dispatch(setEditedRoleChecker(true));
                break;
            case 'DELETE_CONTACT':
                handleRemoveContact(dataItem.RowId);
                break;
            default:
                break;
        }
    };

    return dataItem.IsPending === true ||
        (user?.Roles?.includes('TeamManagement_Edit') === false && user?.Roles?.includes('ExternalContact_Edit') === false) ? null : (
        <ActionButtonElipsis
            options={ActionButtonOptions.map((option) => ({ ...option, label: translate(option.label) }))}
            dangerOption={'DELETE_CONTACT'}
            onAction={(option) => handleAction(option as (typeof ActionButtonOptions)[number]['id'])}
            disabled={disabled}
            dataItem={dataItem}
            className={className}
            style={style}
            {...rest}
        />
    );
};
