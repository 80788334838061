import { Component } from 'react';
import { connect } from 'react-redux';
import SearchBox from '../../../common/Searchbox/Searchbox';

class SearchUserBox extends Component {
    state = {
        value: ''
    };

    onValueChanged = (val) => {
        this.setState({
            value: val
        });
    };

    render() {
        return (
            <SearchBox
                label={''}
                placeholder={this.props.translations.Phrases['TypeHereToSearch_Label']}
                name='User.Email'
                url={this.props.url}
                formatResults={this.props.formatResultBuilder}
                minChars={4}
                valMsg={''}
                value={this.state.value}
                onValueChanged={this.onValueChanged}
                onSelectItem={this.props.onSelectItem}
            ></SearchBox>
        );
    }
}

const mapStateToProps = (state) => {
    return { translations: state.translations.model };
};

export default connect(mapStateToProps)(SearchUserBox);
