const TermsFormFrench = () => {
    return (
        <div className='col-sm-12'>
            {/* <h3>{this.props.translations.Phrases['Page_Sub_Header']}</h3> */}
            {/* <h3>
                Veuillez lire les conditions générales ci-dessous, faire défiler vers le bas et cliquer sur Accepter avant de continuer:
            </h3> */}
            <br />
            <div>
                <h4>Reconnaissance et acceptation des modalités d’utilisation</h4>
                <p>
                    Il s’agit d’une entente juridique (l’« entente ») entre vous et toute société ou autre entité commerciale que vous
                    représentez, le cas échéant (collectivement, l’« utilisateur ») et la Société internationale Livingston inc., ses
                    sociétés affiliées et sociétés et entités connexes (collectivement, « Livingston », « nous », « nos », « notre »). La
                    présente entente régit l’accès et l’utilisation de tous les sites Web et solutions de Livingston (collectivement, les
                    « solutions ») pour lesquels l’utilisateur s’inscrit et qui sont fournis par ou par le biais du site Web de Livingston
                    et des ressources en ligne de Livingston, y compris, sans s’y limiter, les solutions de création et d’hébergement du
                    site Web de Livingston. En utilisant le site Web de Livingston ou nos solutions, vous acceptez d’être lié et de vous
                    conformer aux conditions de la présente entente et à notre Politique de confidentialité,{' '}
                    <a
                        href='https://www.livingstonintl.com/fr/a-propos-de-nous/terms-of-use-privacy/declaration-de-confidentialite/'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        https://www.livingstonintl.com/fr/a-propos-de-nous/terms-of-use-privacy/declaration-de-confidentialite/
                    </a>
                    .
                </p>
                <p>
                    Si vous n’acceptez pas les modalités de la présente entente ou notre Politique de confidentialité, n’achetez pas,
                    n’utilisez pas et n’accédez pas à notre site Web ou aux solutions. En utilisant les solutions de Livingston, vous
                    déclarez et garantissez que vous avez atteint l’âge légal de la majorité en vertu de la loi applicable pour conclure un
                    contrat obligatoire avec Livingston et satisfaire à tous les critères d’admissibilité ci-dessus. Si vous ne répondez pas
                    à toutes ces exigences, vous ne devez pas accéder ou utiliser notre site Web ou nos solutions.
                </p>
                <p>
                    L’accès aux solutions est offert à l’utilisateur sous réserve de l’acceptation de l’utilisateur sans modifier la
                    présente entente. L’utilisateur reconnaît que, de temps à autre, Livingston pourrait devoir mettre à jour ou réviser
                    certaines dispositions de la présente entente. En acceptant la présente entente, l’utilisateur accepte que Livingston
                    puisse modifier les modalités de l’entente à sa seule discrétion sans préavis spécifique à l’utilisateur. Vous acceptez
                    de revoir périodiquement les modalités afin d’être au courant de ces modifications et votre utilisation continue sera
                    votre acceptation de ces dernières. Si vous n’acceptez pas les modifications proposées par Livingston ou les conditions
                    de la présente entente, votre seul et unique recours est d’annuler votre compte.
                </p>

                <h4>Description des solutions</h4>
                <p>
                    Les solutions sont des applications Internet appartenant à Livingston et sont fournies par l’intermédiaire du site Web
                    de Livingston. Ces solutions peuvent être achetées en forfait unique ou être utilisées pour avoir une meilleure
                    visibilité sur le courtage en douane, le fret, le conseil en commerce international et le service de gestion du commerce
                    mondial que Livingston effectue au nom de ses clients. Les fonctionnalités de la solution peuvent inclure, sans s’y
                    limiter, la capacité de créer des documents d’expédition, de produire des rapports personnalisés et d’effectuer des
                    analyses de tendances sur les données stockées, de tenir un référentiel de classifications de produits et d’autres
                    renseignements clés requis pour gérer les opérations d’importation et d’exportation internationales, etc.
                </p>

                <h4>Obligations en matière d’inscription</h4>
                <p>
                    Chaque utilisateur doit : (a) fournir des renseignements véridiques, exacts, à jour et complets sur le formulaire
                    d’inscription des solutions, et (b) tenir à jour et mettre à jour rapidement les données d’inscription au besoin. Si,
                    après enquête, nous avons des motifs raisonnables de soupçonner que les renseignements de tout utilisateur sont non
                    véridiques, inexacts, non actuels ou incomplets, nous pouvons suspendre ou résilier le compte de cet utilisateur et
                    interdire toute utilisation actuelle ou future des solutions (ou toute partie de celles-ci) par cet utilisateur.
                </p>

                <h4>Utilisation et protection du nom d’utilisateur et du mot de passe</h4>
                <p>
                    Chaque utilisateur recevra un nom d’utilisateur et un mot de passe à la fin du processus d’inscription des solutions et
                    est entièrement responsable du maintien de la confidentialité de ceux-ci et entièrement responsable de toutes les
                    activités qui en découlent. Tout nom d’utilisateur, mot de passe ou autre renseignement que vous avez choisi ou qui vous
                    a été fourni dans le cadre de nos procédures de sécurité doit être traité comme confidentiel, et vous ne devez le
                    divulguer à aucune autre personne ou entité. Vous devez faire preuve de prudence lorsque vous accédez à votre compte à
                    partir d’un ordinateur public ou partagé afin que d’autres personnes ne puissent pas voir ou enregistrer votre mot de
                    passe ou d’autres renseignements personnels. Vous comprenez et acceptez que si vous recevez un compte, votre compte vous
                    est personnel et vous acceptez de ne pas donner à toute autre personne l’accès à ce site Web ou à des parties de
                    celui-ci à l’aide de votre nom d’utilisateur, de votre mot de passe ou d’autres renseignements de sécurité. Vous
                    acceptez de nous informer immédiatement de tout accès ou utilisation non autorisés de votre nom d’utilisateur ou de
                    votre mot de passe ou de toute autre violation de la sécurité. Vous convenez également de vous assurer de la déconnexion
                    de votre compte à la fin de chaque session. Vous êtes responsable de toute mauvaise utilisation du mot de passe ou de
                    tout accès non autorisé. Livingston ne peut pas et ne sera pas responsable de toute perte ou de tout dommage découlant
                    du non-respect d’un utilisateur de se conformer à cette section. Livingston ne peut pas et ne sera pas responsable de
                    toute perte ou de tout dommage découlant des présentes ou de tout manquement d’un utilisateur à : (a) informer
                    immédiatement Livingston de toute utilisation non autorisée de son mot de passe ou de son compte ou de toute autre
                    violation de la sécurité, et (b) s’assurer que l’utilisateur se déconnecte de son compte à la fin de chaque session.
                </p>

                <h4>Conduite de l’utilisateur</h4>
                <p>
                    Comme condition d’accès et d’utilisation par l’utilisateur, vous convenez que vous ne pouvez utiliser les solutions qu’à
                    des fins légales et conformément à la présente entente. Les normes de contenu suivantes s’appliquent à tout contenu, à
                    tout matériel et à toute information que vous soumettez, publiez, affichez ou transmettez aux solutions. Toutes les
                    soumissions doivent être conformes à toutes les lois, règlements et conditions de service fédérales, provinciales,
                    locales et internationales applicables.
                </p>

                <b>Sans limiter ce qui précède, vous garantissez et acceptez : </b>
                <ul>
                    <li>
                        De ne pas utiliser les solutions, en tout ou en partie, à des fins illégales ou interdites par la présente entente;
                    </li>
                    <li>
                        De ne pas utiliser les solutions d’une manière susceptible de les endommager, de les désactiver, de les surcharger
                        ou de les entraver ou d’interférer avec l’utilisation des solutions par toute autre partie;
                    </li>
                    <li>
                        De ne pas usurper l’identité de Livingston, d’un employé de Livingston, d’un autre utilisateur ou de toute autre
                        personne ou entité (y compris, sans s’y limiter, l’utilisation des adresses courriel ou des pseudonymes associés à
                        ce qui précède);
                    </li>
                    <li>
                        De ne pas utiliser ou tenter d’utiliser le compte ou le nom d’utilisateur d’une autre personne, le mot de passe sans
                        l’autorisation de Livingston permettant d’accéder aux solutions;
                    </li>
                    <li>De ne pas accéder ou tenter d’accéder à tout contenu de conception auquel vous n’êtes pas autorisé à accéder;</li>
                    <li>
                        De ne pas perturber ou interférer avec la sécurité du site, ou causer autrement des dommages au site ou à toute
                        solution, matériels, ressource du système, tout compte, mot de passe, serveur ou réseau connecté au site ou
                        accessible par le biais du site ou de tout site affilié ou lié;
                    </li>
                    <li>
                        De ne pas utiliser de robot, de collecteurs ou d’autres dispositifs, processus ou moyens automatiques pour accéder
                        aux solutions à quelque fin que ce soit, y compris la surveillance ou la copie de tout matériel sur les solutions;
                    </li>
                    <li>
                        De ne pas introduire de virus, chevaux de Troie, vers, bombes logiques ou tout autre matériel malveillant ou
                        technologiquement nuisible;
                    </li>
                    <li>
                        De ne pas modifier, copier, distribuer, transmettre, afficher, exécuter, reproduire, publier, concéder sous licence,
                        créer des œuvres dérivées, créer dans une autre page Web, utiliser sur tout autre site Web, transférer ou vendre des
                        informations, logiciels, listes d’utilisateurs, bases de données ou autres listes, ou solutions fournies par
                        l’intermédiaire des solutions ou obtenues par l’intermédiaire des solutions, sauf pour l’utilisation commerciale
                        interne de l’utilisateur, conformément à la présente entente;
                    </li>
                    <li>
                        Ne pas s’engager dans les pratiques de « capture d’écran », de « capture de bases de données » ou toute autre
                        activité dans le but d’obtenir des listes d’utilisateurs ou d’autres informations;
                    </li>
                    <li>
                        Ne pas obtenir ou tenter d’obtenir du matériel ou de l’information par tout moyen non intentionnellement mis à
                        disposition ou fourni par l’intermédiaire des solutions; et
                    </li>
                    <li>Ne pas tenter autrement d’entraver le bon fonctionnement des solutions.</li>
                </ul>

                <h4>Utilisation du contenu et des données saisies</h4>
                <p>
                    L’utilisateur, et non Livingston, est entièrement responsable de tout le contenu que l’utilisateur télécharge, publie,
                    envoie par courriel ou autrement transmet par l’intermédiaire de Livingston ou de toutes solutions. Aucun utilisateur ne
                    doit transmettre du contenu ou autrement mener ou participer à des activités sur Livingston et/ou à des solutions qui,
                    de l’avis du conseiller juridique de Livingston, sont interdites par la loi dans les juridictions applicables, y compris
                    les lois régissant le cryptage des logiciels, l’exportation de la technologie ou les utilisations permises de la
                    propriété intellectuelle. Le contenu peut être utilisé uniquement à des fins commerciales, informatives et internes. Les
                    droits accordés à l’utilisateur constituent une licence et non un transfert de titre.
                </p>

                <p>Livingston aura le droit (mais non l’obligation) à son entière discrétion et sans préavis de :</p>

                <ul>
                    <li>
                        retirer ou refuser de publier sur les solutions pour quelque raison que ce soit ou pour aucune raison à notre seule
                        discrétion;
                    </li>
                    <li>
                        refuser ou supprimer tout contenu qu’il considère raisonnablement comme une violation des modalités d’utilisation ou
                        autrement illégal;
                    </li>
                    <li>
                        prendre les mesures judiciaires appropriées, y compris, sans s’y limiter, le renvoi à des organismes chargés de
                        l’application de la loi ou à des organismes de réglementation, ou aviser la partie lésée de toute utilisation
                        illégale ou non autorisée des solutions; et
                    </li>
                    <li>
                        résilier ou suspendre votre accès à l’ensemble ou à une partie des solutions pour quelque raison que ce soit ou sans
                        raison, y compris, sans s’y limiter, toute violation de la présente entente.
                    </li>
                </ul>

                <h4>Droits de propriété intellectuelle et propriété de l’information</h4>
                <p>
                    L’utilisateur comprend et accepte que les solutions et leur contenu entier, caractéristiques et fonctions, y compris,
                    mais sans s’y limiter, tous les renseignements, logiciel, code, texte, présentoirs, graphiques, photographies, vidéo,
                    audio, conception, présentation, sélection et arrangement sont la propriété de Livingston, ses concédants de licence, ou
                    d’autres fournisseurs de ce matériel et sont protégés sous toutes leurs formes par des lois sur la propriété
                    intellectuelle, y compris, mais sans s’y limiter, droits d’auteur, marque de commerce, brevet, secret commercial et tous
                    les autres droits patrimoniaux.
                </p>
                <p>
                    Le nom de Livingston et tous les noms, logos, noms de produits et de services, dessins, images et slogans connexes sont
                    des marques de commerce de Livingston ou de ses sociétés affiliées ou concédants de licence. Vous ne devez pas utiliser
                    ces marques sans l’autorisation écrite préalable de Livingston. Les autres noms, logos, noms de produits et de services,
                    dessins, images et slogans mentionnés ou qui apparaissent sur les solutions sont des marques de commerce de leurs
                    propriétaires respectifs. L’utilisation de tels biens, sauf autorisation expresse, constitue une violation des droits du
                    propriétaire et peut constituer une violation des lois fédérales ou autres, et pourrait exposer le contrevenant à une
                    action en justice.
                </p>
                <p>
                    En acceptant la présente entente, l’utilisateur accepte que toutes les informations ou saisie de données, transmises aux
                    solutions ou stockées dans les solutions deviennent la propriété exclusive de Livingston. Sous réserve des conditions de
                    sa Politique de confidentialité et de ses exigences réglementaires, Livingston se réserve le droit d’utiliser ces
                    renseignements à des fins internes comme elle le juge approprié et sans obtenir le consentement préalable de
                    l’utilisateur. Pour savoir comment Livingston protège vos renseignements, consultez la Politique de confidentialité en
                    ligne de Livingston. L’utilisateur renonce également à la propriété de ces renseignements et convient que ces
                    renseignements ne peuvent être partagés qu’avec l’utilisateur, en tout ou en partie, à la seule discrétion de
                    Livingston.
                </p>

                <h4>Rémunération pour les solutions</h4>
                <p>
                    L’utilisateur est tenu de payer des frais pour l’ensemble ou une partie des solutions auxquelles il a choisi de
                    s’inscrire. L’utilisateur reconnaît que Livingston se réserve le droit de modifier les frais (ce qui comprend, sans s’y
                    limiter, l’augmentation des prix et l’imposition de frais pour les mises à niveau ou les solutions pour lesquelles
                    Livingston ne facture actuellement pas de frais) à tout moment, à condition, toutefois, que Livingston donne à
                    l’utilisateur un préavis raisonnable avant d’apporter toute modification de frais. De plus, Livingston donnera également
                    à l’utilisateur un préavis raisonnable avant toute modification des solutions qui pourrait avoir un impact négatif sur
                    l’utilisateur. Les modalités de paiement standard sont de 30 jours à compter de la date de facturation ou comme convenu
                    autrement par les parties par écrit. Si l’utilisateur juge que toute modification apportée aux solutions est
                    inacceptable, il est libre d’annuler toute partie des solutions ou du compte de l’utilisateur à tout moment, mais
                    Livingston ne remboursera aucune partie restante des frais prépayés de l’utilisateur lorsque l’utilisateur annule toute
                    partie des solutions ou du compte de l’utilisateur.
                </p>

                <h4>Résiliation de l’accès aux solutions</h4>
                <p>
                    Le compte d’un utilisateur peut être résilié pour quelque raison que ce soit, y compris, mais sans s’y limiter, ce qui
                    suit : (a) violation des modalités de la présente entente; (b) utilisation abusive des ressources du site ou tentative
                    d’accès non autorisé au site ou aux ressources du site; (c) utilisation des solutions d’une manière incompatible avec
                    l’objectif prévu de la présente entente; (d) demande d’un utilisateur pour une telle résiliation; (e) exigence d’une
                    loi, d’un règlement, d’une ordonnance d’un tribunal ou d’un organisme directeur ou; (f) la non-conformité avec les
                    modalités de paiement.
                </p>

                <p>
                    La résiliation par Livingston de l’accès de tout utilisateur aux solutions Livingston en vertu des présentes peut être
                    affectée sans préavis et, en cas de résiliation, Livingston peut immédiatement désactiver ou supprimer le compte de
                    l’utilisateur ou interdire tout accès ultérieur à ces fichiers. Livingston ne sera pas responsable envers un utilisateur
                    ou un tiers pour toute résiliation de l’accès ou du compte de cet utilisateur en vertu des présentes.
                </p>

                <h4>Exonération de garantie</h4>
                <p>
                    L’UTILISATEUR COMPREND ET ACCEPTE QUE L’UTILISATION DES SOLUTIONS, DE SON CONTENU ET DE TOUT SERVICE OU ÉLÉMENT TROUVÉ
                    OU ATTEINT PAR LA SOLUTION EST À SES PROPRES RISQUES. LA SOLUTION, SON CONTENU ET TOUS LES SERVICES OU ÉLÉMENTS TROUVÉS
                    OU ATTEINTS PAR LA SOLUTION SONT FOURNIS « TELS QUELS » ET « SELON LA DISPONIBILITÉ », SANS AUCUNE GARANTIE OU CONDITION
                    DE QUELQUE NATURE QUE CE SOIT, EXPRESSE OU IMPLICITE, Y COMPRIS, MAIS SANS S’Y LIMITER, LES GARANTES IMPLICITES DE
                    QUALITÉ MARCHANDE, D’ADAPTATION À UNE FIN PARTICULIÈRE, OU DE NON-VIOLATION. CE QUI PRÉCÈDE N’A AUCUNE INCIDENCE SUR LES
                    GARANTIES QUI NE PEUVENT PAS ÊTRE EXCLUES OU LIMITÉES EN VERTU DE LA LOI APPLICABLE.
                </p>
                <p>
                    NI LIVINGSTON, NI SES FILIALES, SOCIÉTÉS AFFILIÉES, NI LEURS DIRECTEURS, DIRIGEANTS, EMPLOYÉS, AGENTS, PRESTATAIRES DE
                    SERVICES, ENTREPRENEURS, CONCÉDANTS DE LICENCE, TITULAIRES DE LICENCE, FOURNISSEURS OU SUCCESSEURS RESPECTIFS N’ÉMETTENT
                    AUCUNE GARANTIE, DÉCLARATION OU APPROBATION QUANT À L’EXHAUSTIVITÉ, SÉCURITÉ, FIABILITÉ, APTITUDE, EXACTITUDE, ACTUALITÉ
                    OU DISPONIBILITÉ DE LA SOLUTION OU DE SON CONTENU. SANS LIMITER CE QUI PRÉCÈDE, NI LIVINGSTON NI SES FILIALES, LES
                    SOCIÉTÉS AFFILIÉES OU LEURS DIRECTEURS RESPECTIFS, DIRIGEANTS, EMPLOYÉS, AGENTS, PRESTATAIRES DE SERVICES,
                    ENTREPRENEURS, CONCÉDANTS DE LICENCE, TITULAIRES DE LICENCE, FOURNISSEURS, OU LES SUCCESSEURS RESPECTIFS DÉCLARENT OU
                    GARANTISSENT QUE LA SOLUTION, SON CONTENU, OU TOUT SERVICE OU ÉLÉMENT TROUVÉ OU ATTEINT PAR LA SOLUTION SERAIT EXACT,
                    FIABLE, SANS ERREUR, OU ININTERROMPUE, QUE LES DÉFAUTS SERONT CORRIGÉS, QUE NOTRE SOLUTION OU LE SERVEUR QUI LA REND
                    DISPONIBLE NE CONTIENNENT PAS DE VIRUS OU D’AUTRES COMPOSANTS NUISIBLES.
                </p>
                <p>
                    NOUS NE POUVONS PAS GARANTIR ET NE GARANTISSONS PAS QUE LES FICHIERS OU LES DONNÉES DISPONIBLES POUR TÉLÉCHARGEMENT À
                    PARTIR D’INTERNET OU DE LA SOLUTION SERONT EXEMPTS DE VIRUS OU D’AUTRES CODES DESTRUCTIFS. VOUS ÊTES UNIQUEMENT ET
                    ENTIÈREMENT RESPONSABLE DE VOTRE UTILISATION DU SITE WEB ET DE VOTRE ORDINATEUR, D’INTERNET ET DE LA SÉCURITÉ DES
                    DONNÉES. DANS TOUTE LA MESURE PRÉVUE PAR LA LOI, NOUS NE SERONS PAS RESPONSABLES DES PERTES OU DOMMAGES CAUSÉS PAR UNE
                    ATTAQUE PAR DÉNI DE SERVICE, ATTAQUE PAR DÉNI DE SERVICE DISTRIBUÉ, SURCHARGEMENT, INONDATION, BOMBARDERIE, OU DES
                    PANNES INFORMATIQUES, VIRUS, CHEVAUX DE TROIE, VERS, BOMBES LOGIQUES, OU D’AUTRES MATÉRIAUX TECHNOLOGIQUEMENT NOCIFS QUI
                    PEUVENT NUIRE À VOTRE ÉQUIPEMENT INFORMATIQUE, LOGICIELS, DONNÉES, OU AUTRE MATÉRIEL EXCLUSIF EN RAISON DE VOTRE
                    UTILISATION DE LA SOLUTION OU DE TOUT SERVICE OU ARTICLE TROUVÉ OU ATTEINT PAR LE BIAIS DU SITE WEB OU DE TÉLÉCHARGEMENT
                    DE TOUT MATÉRIEL PUBLIÉ SUR CELUI-CI, OU SUR TOUT SITE WEB LIÉ À CELUI-CI.
                </p>

                <h4>Limitation de responsabilité</h4>
                <p>
                    SAUF LORSQUE DE TELLES EXCLUSIONS SONT INTERDITES PAR LA LOI, EN AUCUN CAS LIVINGSTON ET SES FILIALES OU LEURS
                    DIRECTEURS RESPECTIFS, DIRIGEANTS, EMPLOYÉS, AGENTS, PRESTATAIRES DE SERVICES, ENTREPRENEURS, CONCÉDANTS DE LICENCE,
                    TITULAIRES DE LICENCE, FOURNISSEURS, OU LES SUCCESSEURS NE SERONT RESPONSABLES DE LA NÉGLIGENCE, NÉGLIGENCE GRAVE,
                    FAUSSE REPRÉSENTATION, VIOLATION FONDAMENTALE, DES DOMMAGES DE QUELQUE NATURE QUE CE SOIT, DANS LE CADRE DE TOUTE
                    THÉORIE JURIDIQUE, Y COMPRIS TOUS DOMMAGES-INTÉRÊTS DIRECTS, INDIRECTS, SPÉCIAUX, ACCESSOIRES, CONSÉCUTIFS, OU
                    DOMMAGES-INTÉRÊTS PUNITIFS, Y COMPRIS, MAIS SANS S’Y LIMITER, BLESSURES CORPORELLES, DOULEUR ET SOUFFRANCE, TROUBLE
                    ÉMOTIONNEL, PERTE DE REVENUS, PERTE DE PROFITS, PERTE D’ENTREPRISE OU ÉCONOMIES PRÉVUES, PERTE D’UTILISATION, PERTE DE
                    BIENS, PERTE DE DONNÉES, ET QU’ILS SOIENT CAUSÉS PAR UN DÉLIT (Y COMPRIS LA NÉGLIGENCE), RUPTURE DE CONTRAT, VIOLATION
                    DE LA VIE PRIVÉE, OU AUTRE, MÊME SI LA PARTIE A ÉTÉ CONSEILLÉE OU AVAIT EU RAISON DE SAVOIR, DÉCOULANT DE OU EN LIEN
                    AVEC VOTRE UTILISATION, OU VOTRE INCAPACITÉ D’UTILISER, OU DE VOUS FIER À LA SOLUTION, À TOUT SITE WEB LIÉ OU À TOUT
                    AUTRE SITE WEB TIERS, OU À TOUT CONTENU DU SITE WEB, MATÉRIAUX, PUBLICATION, OU LES RENSEIGNEMENTS QUI S’Y RAPPORTENT,
                    MÊME SI LA PARTIE A ÉTÉ CONSEILLÉE OU AVAIT EU RAISON DE SAVOIR.
                </p>
                <p>
                    VOUS RENONCEZ À LA RESPONSABILITÉ DE LIVINGSTON ET SES FILIALES, SOCIÉTÉS AFFILIÉES ET SES DIRECTEURS, DIRIGEANTS,
                    EMPLOYÉS, AGENTS, PRESTATAIRES DE SERVICES, ENTREPRENEURS, CONCÉDANTS DE LICENCE, TITULAIRES DE LICENCE RESPECTIFS,
                    FOURNISSEURS ET SUCCESSEURS RESPECTIFS DE TOUTE RÉCLAMATION RÉSULTANT DE TOUTE ACTION DE LIVINGSTON ET DE L’UNE DES
                    PARTIES CI-DESSUS RELATIVE À TOUTE ENQUÊTE DE LIVINGSTON OU DE CES PARTIES OU DES AUTORITÉS CHARGÉES DE L’APPLICATION
                    DES LOIS.
                </p>
                <p>
                    Nous n’avons aucune obligation, ni aucune responsabilité envers toute partie de surveiller les solutions ou leur
                    utilisation, et nous ne nous engageons pas et ne pouvons pas nous engager à examiner le matériel que vous ou d’autres
                    utilisateurs soumettez aux solutions. Nous ne pouvons pas assurer le retrait rapide de matériel répréhensible après
                    qu’il a été publié et nous n’avons aucune responsabilité pour toute action ou inaction concernant les transmissions, les
                    communications ou le contenu fourni par un utilisateur ou un tiers, sous réserve des lois applicables.
                </p>

                <h4>Indemnisation</h4>
                <p>
                    Dans toute la mesure permise par la loi applicable, l’utilisateur accepte de défendre, indemniser, et dégager
                    Livingston, ses filiales, sociétés affiliées et leurs directeurs respectifs, dirigeants, employés, agents, prestataires
                    de services, les entrepreneurs, concédants de licence, fournisseurs, les successeurs, et cessionnaires de et contre
                    toutes réclamations, toutes responsabilités, tous dommages, tous jugements, toutes récompenses, toutes pertes, tous
                    coûts, toutes dépenses, ou tous frais (y compris les honoraires d’avocat raisonnables) découlant de ou en rapport avec
                    la violation par l’utilisateur des modalités de la présente entente ou s’y rapportant, ou l’utilisation de la solution,
                    y compris, mais sans s’y limiter, les sites tiers, toute utilisation du contenu de la solution, des services et des
                    produits autres que ceux expressément autorisés dans la présente entente.
                </p>

                <h4>Tiers </h4>
                <p>
                    Pour votre commodité, les solutions de Livingston peuvent fournir des liens ou des pointeurs vers des sites tiers, par
                    exemple, à des fins de traitement des paiements. Nous ne faisons aucune représentation au sujet de tout autre site Web
                    auquel on peut accéder à partir des solutions de Livingston. Si vous choisissez d’accéder à de tels sites, vous le
                    faites à vos propres risques. Nous n’avons aucun contrôle sur le contenu de ces sites tiers et n’acceptons aucune
                    responsabilité pour ces sites ou pour toute perte ou tout dommage pouvant découler de votre utilisation de ceux-ci. Vous
                    êtes assujetti à toutes les modalités de ces sites tiers.
                </p>

                <h4>Confidentialité </h4>
                <p>
                    En soumettant vos renseignements personnels et en utilisant nos solutions, vous consentez à la collecte, l’utilisation,
                    la reproduction, l’hébergement, la transmission et la divulgation de tout contenu d’utilisateur soumis conformément à
                    notre Politique de confidentialité, comme nous le jugeons nécessaire pour l’utilisation des solutions et la prestation
                    de services. En utilisant nos solutions, vous consentez à l’utilisation de témoins qui permettent à un serveur de
                    rappeler les demandes précédentes ou l’enregistrement et/ou les adresses IP pour analyser les habitudes d’utilisation du
                    site Web. Vous pouvez configurer votre navigateur pour qu’il vous informe avant de recevoir un témoin, ce qui vous donne
                    la possibilité de l’accepter ou non. Vous pouvez également configurer votre navigateur pour désactiver les témoins.
                    Cependant, si vous le faites, certaines zones des solutions pourraient ne pas fonctionner adéquatement. Pour plus
                    d’informations sur ces pratiques de collecte automatisée de renseignements, consultez{' '}
                    <a
                        href='https://www.livingstonintl.com/fr/a-propos-de-nous/terms-of-use-privacy/declaration-de-confidentialite/politique-de-livingston-en-matiere-de-temoins-de-connection/'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        https://www.livingstonintl.com/fr/a-propos-de-nous/terms-of-use-privacy/declaration-de-confidentialite/politique-de-livingston-en-matiere-de-temoins-de-connection/
                    </a>
                    .
                </p>

                <h4>Droit applicable et choix du forum </h4>
                <p>
                    Les solutions et la présente entente seront régies et interprétées conformément aux lois de la province de l’Ontario, à
                    l’exception des principes de conflit de lois qui régissent l’application de toute loi autre que celle de l’Ontario. Les
                    tribunaux de la province de l’Ontario auront compétence sur tous les différends entre les parties aux présentes
                    découlant de la présente entente ou s’y rapportant et des accords, instruments et documents visés par les présentes. Par
                    les présentes, les parties consentent et conviennent de se soumettre à la compétence de ces tribunaux.
                </p>

                <h4>Entente complète </h4>
                <p>
                    La présente entente et notre Politique de confidentialité et notre Politique sur les témoins constituent la seule et
                    unique entente entre l’utilisateur et Livingston concernant l’utilisation des solutions de Livingston et remplacent
                    toutes les ententes, conventions, déclarations et garanties antérieures et contemporaines, tant par écrit qu’à l’oral,
                    concernant ce sujet.
                </p>

                <h4>Langue </h4>
                <p>
                    C’est la volonté expresse des parties que la présente convention ainsi que les documents qui s’y rattachent soient
                    rédigés en anglais.
                </p>
            </div>
            {/*<div className='row justify-content-center align-items-center' id='button-row'>
                <Link to='#' className='button blue small' onClick={this.acceptTerms}>
                     {this.props.translations.Phrases['Approve_Button']} 
                    J’accepte les conditions d’utilisation
                </Link>
                <Link to='/Auth/TermsOfServiceFrench'>
                    {/* {this.props.translations.Phrases['Reject_Button']} 
                    Je n’accepte pas les conditions d’utilisation
                </Link>
            </div> */}
        </div>
    );
};

export default TermsFormFrench;
