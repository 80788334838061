import React from 'react';
import MenuItem from './MenuItem';

function MenuSection(props) {
    return (
        <ul>
            {props.section.MenuItems.map((item) => {
                return <MenuItem key={item.Text} item={item} notices={props.notices} />;
            })}
        </ul>
    );
}

export default MenuSection;
