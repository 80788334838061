import React, { Component } from 'react';
import { connect } from 'react-redux';

class ClientAdminsCreatedGrid extends Component{
    render() {
        const rows = this.props.clientAdminCreatedSummary.map((item, index) =>{
            return (
                <tr key={item.Name+item.Company}>					    
                    <td className='row-data'>{item.Name}</td>
                    <td className='row-data'>{item.Company}</td>
                    <td className='row-data'>{item.Numbers}</td>
                  </tr>
            );
        });

        return (
            <div>					
                <div className='row'>
                    <div className='col-sm-12'>
                        <h3 className='pull-left bold table-title'>{this.props.translations.Phrases['ClientAdminsCreated_Label']}</h3>
                    </div>							
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <br />
                        <table className='table table-striped data-table'>
                            <thead>
                                <tr>									
                                    <th>{this.props.translations.Phrases['Name_Label']}</th>
                                    <th>{this.props.translations.Phrases['CompanyName_Label']}</th>
                                    <th>{this.props.translations.Phrases['Numbers_Label']}</th>                                    
                                  </tr>
                            </thead>
                            <tbody>
                                  {rows}
                              </tbody>
                        </table>
                    </div>					
                </div>					
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    }
}

export default connect(mapStateToProps)(ClientAdminsCreatedGrid);


