import { useEffect, useRef, useState } from 'react';
import DropdownMenuButton from '../../../../../common/features/DropdownMenuButton';
import useDropdownToggle from '../../../../../common/hooks/useDropdownToggle';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { locationFilterType } from '../../../common/constants/complete-entry-data-constants';
import { useCompleteEntryDataDispatch, useCompleteEntryDataSelector } from '../../../redux/hooks';
import { setPageSelected } from '../../../redux/reducers/completeEntryDataGridSettingsSlice';
import {
    updateLocationTypeFilter,
    updateSelectLocationFilter,
    updateShouldSubmitSearch
} from '../../../redux/reducers/completeEntryDataSlice';
import {
    cancelSelectedItems,
    resetLocationFilters,
    setAppliedLocationType,
    setIsFirstLocationSelectOpen,
    setLocationTypeSelected,
    setSelectedLocationLabels
} from '../../../redux/reducers/locationFilterSlice';
import DropdownLocationFilter from './DropdownLocationFilter';

const LocationFilter = () => {
    const dropdownMenuRef = useRef(null);
    const dropdownNestedMenuRef = useRef(null);
    const [selectedLabels, setSelectedLabels] = useState<string>();
    const dispatch = useCompleteEntryDataDispatch();
    const translate = useTranslation();
    const [appliedLocationFilter, setAppliedLocationFilter] = useState<boolean>();
    const { isOpen, handleToggle } = useDropdownToggle(dropdownMenuRef);
    const { isOpen: nestedIsOpen, handleToggle: nestedHandleToggle } = useDropdownToggle(dropdownNestedMenuRef);
    const {
        locationTypeSelected,
        isLocationSelectOpen,
        checkedNameItems,
        selectedLocationLabels,
        isLocationFiltered,
        applylocationFilter,
        applySelectLocation,
        checkedIdItems,
        appliedLocationTypeSelected,
        appliiedCheckedItemsNames
    } = useCompleteEntryDataSelector((state) => state.locationFilter);

    useEffect(() => {
        selectedLocationLabels?.length
            ? setSelectedLabels(
                  `${translate(appliedLocationTypeSelected?.label)}: ${selectedLocationLabels
                      .map((item) => item.replace(/,/g, ''))
                      .join(', ')}`
              )
            : setSelectedLabels(translate('Location_Label'));
    }, [selectedLocationLabels, appliedLocationTypeSelected, translate]);
    useEffect(() => {
        const areIdentical =
            JSON.stringify(selectedLocationLabels) === JSON.stringify(appliiedCheckedItemsNames) ||
            JSON.stringify(appliiedCheckedItemsNames) !== JSON.stringify(checkedNameItems);

        setAppliedLocationFilter(areIdentical);
    }, [selectedLocationLabels, applylocationFilter, checkedNameItems, appliedLocationFilter, appliiedCheckedItemsNames]);

    useEffect(() => {
        dispatch(setIsFirstLocationSelectOpen(isOpen));
    }, [isOpen, dispatch]);
    useEffect(() => {
        if (!applySelectLocation && !isOpen) {
            dispatch(resetLocationFilters());
            dispatch(cancelSelectedItems());
        } else if (selectedLocationLabels.length && !isOpen) {
            dispatch(cancelSelectedItems());
            dispatch(updateSelectLocationFilter({ attribute: locationTypeSelected?.attribute, selectedArray: selectedLocationLabels }));
        } else if (applySelectLocation && !isOpen) {
            dispatch(cancelSelectedItems());
            dispatch(resetLocationFilters());
            dispatch(updateSelectLocationFilter({ attribute: locationTypeSelected?.attribute, selectedArray: selectedLocationLabels }));
        }
    }, [
        isOpen,
        applylocationFilter,
        dispatch,
        locationTypeSelected?.attribute,
        applySelectLocation,
        selectedLocationLabels.length,
        selectedLocationLabels
    ]);
    const handleSelection = (locationTypeIndex: number) => {
        dispatch(setLocationTypeSelected(locationTypeIndex));
        dispatch(updateLocationTypeFilter(locationFilterType[locationTypeIndex].attribute));
        dispatch(setPageSelected(1));
    };

    const handleCancel = () => {
        if (!isLocationFiltered) {
            handleToggle();
            dispatch(resetLocationFilters());
            dispatch(updateSelectLocationFilter({ attribute: locationTypeSelected?.attribute, selectedArray: [] }));
            dispatch(updateLocationTypeFilter(''));
        } else {
            handleToggle();
            dispatch(cancelSelectedItems());
            dispatch(updateSelectLocationFilter({ attribute: locationTypeSelected?.attribute, selectedArray: selectedLocationLabels }));
        }
    };

    return (
        <DropdownMenuButton
            label={selectedLabels}
            isOpen={isOpen}
            ref={isOpen && !nestedIsOpen && !isLocationSelectOpen ? dropdownMenuRef : null}
            onClick={handleToggle}
            btnClassName={
                selectedLocationLabels.length
                    ? 'btn-sm dropdown-toggle items-selected ml-1'
                    : 'btn btn-tertiary btn-sm dropdown-toggle ml-1'
            }
        >
            <>
                <div className='card shadow-none'>
                    <div className='card-body px-4 py-3 border-bottom font-weight-bold'>{translate('FilterByLocation_Label')}</div>
                </div>
                <div className='card shadow-none'>
                    <div className='card-body px-4 py-3'>
                        <DropdownMenuButton
                            label={
                                locationTypeSelected?.label ? translate(locationTypeSelected.label) : translate('SelectLocationType_Label')
                            }
                            isOpen={nestedIsOpen}
                            disabled={false}
                            onClick={() => {
                                nestedHandleToggle();
                            }}
                            ref={dropdownNestedMenuRef}
                            btnClassName={'btn-sm dropdown-toggle mb-2 ml-1 btn-block'}
                            style={{ minWidth: '155px' }}
                            childrenDivClassName=''
                        >
                            {locationFilterType.map((item, index) => (
                                <div
                                    className={`dropdown-item`}
                                    key={index}
                                    onClick={() => {
                                        nestedHandleToggle();
                                        handleSelection(index);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {translate(item.label)}
                                </div>
                            ))}
                        </DropdownMenuButton>
                        <DropdownLocationFilter />
                    </div>
                </div>
                <div className='card-footer d-flex px-4 py-3'>
                    <button
                        type='button'
                        className='btn btn-secondary cancel-custom-date-range mr-1 flex-fill text-center'
                        onClick={handleCancel}
                    >
                        {translate('Cancel_Label')}
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary apply-custom-date-range ml-1 flex-fill text-center'
                        disabled={
                            appliedLocationFilter ||
                            (!checkedNameItems.length && locationTypeSelected?.attribute !== appliedLocationTypeSelected?.attribute)
                        }
                        onClick={() => {
                            handleToggle();
                            dispatch(setSelectedLocationLabels({ checkedNameItems, checkedIdItems }));
                            dispatch(updateShouldSubmitSearch(true));

                            dispatch(setAppliedLocationType(locationTypeSelected));
                            if (!checkedNameItems.length) {
                                dispatch(updateLocationTypeFilter(''));
                            }
                        }}
                    >
                        {translate('Apply_Label')}
                    </button>
                </div>
            </>
        </DropdownMenuButton>
    );
};
export default LocationFilter;
