import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../../../redux/api/apiSlice';
import alertsReducer from '../../../redux/reducers/alertsSlice';
import clientSelectionReducer from '../../../redux/reducers/clientSelectionReducer';
import hydrationReducer from '../../../redux/reducers/hydrationSlice';
import languageReducer from '../../../redux/reducers/languageSlice';
import toastReducer from '../../../redux/reducers/toastSlice';
import translationsReducer from '../../../redux/reducers/translationsSlice';
import statusUserReducer from './reducers/statusUserSlice';
import teamGridSettingsReducer from './reducers/teamGridSettingsSlice';
import teamSearchFilterReducer from './reducers/teamSearchFilterSlice';
import teamStatusFilterReducer from './reducers/teamStatusFilterSlice';

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        // common slices
        hydration: hydrationReducer,
        translations: translationsReducer,
        language: languageReducer,
        alerts: alertsReducer,
        clientSelection: clientSelectionReducer,
        toast: toastReducer,

        // page specific slices
        teamStatusFilter: teamStatusFilterReducer,
        teamSearchFilter: teamSearchFilterReducer,
        teamGridSettings: teamGridSettingsReducer,
        statusUser: statusUserReducer
    },
    middleware: (gDM) => gDM().concat(apiSlice.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
