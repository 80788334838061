import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Inputbase extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        readonly: PropTypes.bool,
        tooltip: PropTypes.string,
        tooltipPosition: PropTypes.string,
        label: PropTypes.string,
        valMsg: PropTypes.string,
        embed: PropTypes.bool
    };

    constructContainerClass = () => {
        let containerClass = ['input-container'];
        if (this.props.disabled) {
            containerClass.push('disabled');
        }
        if (this.props.readonly) {
            containerClass.push('readonly');
        }
        if (this.props.noBorder) {
            containerClass.push('no-border');
        }
        return containerClass.join(' ');
    };

    setTooltip = () => {
        if (this.props.tooltip) {
            $(React.findDOMNode(this)).tooltip({
                title: this.props.tooltip,
                placement: this.props.tooltipPosition ? this.props.tooltipPosition : 'auto'
            });
        }
    };

    constructValidationClass = () => {
        return !this.props.disabled && !this.props.readonly && this.props.valMsg ? 'field-validation-error' : 'field-validation-valid';
    };

    componentDidMount() {
        this.setTooltip();
    }

    keyPress = (e) => {
        if (this.props.allowEnter !== 'true') {
            if (e.key === 'Enter') e.preventDefault();
        }
    };

    render() {
        if (this.props.embed) {
            return this.props.children;
        }

        let header = (
            <div className='input-header'>
                <div className='input-label'>
                    <span>{this.props.label}</span>
                </div>
                <div className='input-validation'>
                    <span className={this.constructValidationClass()}>{this.props.valMsg}</span>
                </div>
            </div>
        );

        if (this.props.noHeader) {
            header = null;
        }

        return (
            <div className={this.constructContainerClass()}>
                {header}
                <div className='input-body' onKeyPress={(event) => this.keyPress(event)}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Inputbase;
