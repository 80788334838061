import produce from 'immer';
import * as actionTypes from '../actions/accessManagement/userSearchActions';

const initialState = {
    model: {
        OriginalClientAdmin: {},
        SelectedSfUser: {},
        NewClientAdmin: {},
        PendingClientAdmin: {},
        ParentServices: [],
        ValidationErrors: []
    },
    validationMessages: {}
};

const setUserSearch = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.model;
        draft.validationMessages = action.model.ValidationErrors;
    });
};

const selectSalesForceContact = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.model;
    });
};

const afterConfirmNewClientAdmin = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.model;
        draft.validationMessages = action.validationMessages;
    });
};

const transferRightsNewClientAdmin = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.model;
        draft.validationMessages = action.validationMessages;
    });
};
const resetStateUserSearch = (state, action) => {
    return produce(state, (draft) => {
        if (draft.model) {
            draft.model.OriginalClientAdmin = null;
            draft.model.SelectedSfUser = null;
            draft.model.NewClientAdmin = null;
            draft.model.PendingClientAdmin = null;
            draft.model.ParentServices = null;
            draft.model.ValidationErrors = null;
        }
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_SEARCH:
            return setUserSearch(state, action);
        case actionTypes.SELECT_SALESFORCE_CONTACT:
            return selectSalesForceContact(state, action);
        case actionTypes.CONFIRM_NEW_CLIENT_ADMIN:
            return afterConfirmNewClientAdmin(state, action);
        case actionTypes.TRANSFER_RIGHTS_NEW_CLIENT_ADMIN:
            return transferRightsNewClientAdmin(state, action);
        case actionTypes.RESET_STATE_USER_SEARCH:
            return resetStateUserSearch(state, action);
        default:
            return state;
    }
};

export default reducer;
