import React from 'react';
import Notice from './Notice';

const NoticesArea = (props) => {
    return (
        <div className='notices-area w-100'>
            <div className='container'>
                {props.notices.map((notice) => {
                    return <Notice notice={notice} key={notice.LookupKey} />;
                })}
            </div>
        </div>
    );
};

export default NoticesArea;
