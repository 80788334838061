import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../../../axios-portal';
import { API_CLEARANCES_PATH } from '../../../../common/constants/constants-portal';
import { ColumnSettings } from '../../../../common/models/GridPreferences';
import { GET_CLERANCES_GRID_PREFERENCES, SAVE_CLERANCES_GRID_PREFERENCES } from './actionTypes';

export const GetUserClearancesGridSetting = createAsyncThunk(
    GET_CLERANCES_GRID_PREFERENCES,
    async (payload: { area: string }, { rejectWithValue }) => {
        try {
            const response = await axios.get<ColumnSettings[]>(
                `${API_CLEARANCES_PATH}/clearance/get-clearances-grid-configuration/${payload.area}`
            );

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);

export const SaveUserClearancesGridSetting = createAsyncThunk(
    SAVE_CLERANCES_GRID_PREFERENCES,
    async (
        payload: {
            GridColumns: ColumnSettings[];
            area: string;
        },
        { rejectWithValue }
    ) => {
        try {
            const response = await axios.post(`${API_CLEARANCES_PATH}/clearance/save-clearances-grid-configuration`, payload);

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.status);
        }
    }
);
