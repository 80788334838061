import produce from 'immer';
import { global as Constants } from '../../constants/mlp-constants';
import { initValidationMessages } from '../../utils/mlp-utils';
import * as actionTypes from '../actions/teamManagement/teamManagementActionTypes';

const initialState = {
    model: {
        ChangedProperties: [],
        ClientAdminId: -1,
        Error: null,
        IsHidden: false,
        IsLocked: false,
        IsMultiUserAllowed: false,
        IsReadonly: false,
        ParentServices: [],
        Permissions: [],
        Roles: [],
        UserHasPendingChanges: false,
        UserExpirationDate: '',
        UserRole: '',
        Users: [],
        isEditMode: false
    },
    ValidationMessages: {
        Users: []
    }
};

const setTeamMember = (state, action) => {
    return produce(state, (draft) => {
        draft.model = action.model;
        draft.ValidationMessages = action.validationMessages;
    });
};

const setUserRole = (state, action) => {
    return produce(state, (draft) => {
        draft.model.Users[action.payload.userIndex].Roles[action.payload.newIndex].IsSelected = true;
        if (action.payload.oldIndex !== -1) {
            draft.model.Users[action.payload.userIndex].Roles[action.payload.oldIndex].IsSelected = false;
        }
    });
};

const addNewUser = (state) => {
    return produce(state, (draft) => {
        let user = {
            FirstName: '',
            LastName: '',
            Email: '',
            Phone: '',
            CompanyName: '',
            IMSId: '',
            Roles: JSON.parse(JSON.stringify(state.model.Users[0].Roles))
        };
        user.Roles[0].IsSelected = true;
        draft.model.Users.push(user);
    });
};

const removeUser = (state, action) => {
    return produce(state, (draft) => {
        draft.model.Users.splice(action.payload.Index, 1);
    });
};

const selectUser = (state, action) => {
    return produce(state, (draft) => {
        if (action.payload.User !== null) {
            draft.model.Users[action.payload.Index].FirstName = action.payload.User.FirstName;
            draft.model.Users[action.payload.Index].LastName = action.payload.User.LastName;
            draft.model.Users[action.payload.Index].Email = action.payload.User.Email;
            draft.model.Users[action.payload.Index].Phone = action.payload.User.Phone;
            draft.model.Users[action.payload.Index].CompanyName = action.payload.User.CompanyName;
            draft.model.Users[action.payload.Index].SalesforceId = action.payload.User.SalesforceId;
            draft.model.Users[action.payload.Index].LanguageCode = action.payload.User.LanguageCode;

            if (!draft.model.ChangedProperties.includes('Users.' + action.payload.Index + '.FirstName')) {
                draft.model.ChangedProperties.push('Users.' + action.payload.Index + '.FirstName');
            }
            if (!draft.model.ChangedProperties.includes('Users.' + action.payload.Index + '.LastName')) {
                draft.model.ChangedProperties.push('Users.' + action.payload.Index + '.LastName');
            }
            if (!draft.model.ChangedProperties.includes('Users.' + action.payload.Index + '.Email')) {
                draft.model.ChangedProperties.push('Users.' + action.payload.Index + '.Email');
            }
            if (!draft.model.ChangedProperties.includes('Users.' + action.payload.Index + '.Phone')) {
                draft.model.ChangedProperties.push('Users.' + action.payload.Index + '.Phone');
            }
            if (!draft.model.ChangedProperties.includes('Users.' + action.payload.Index + '.CompanyName')) {
                draft.model.ChangedProperties.push('Users.' + action.payload.Index + '.CompanyName');
            }
            if (!draft.model.ChangedProperties.includes('Users.' + action.payload.Index + '.SalesforceId')) {
                draft.model.ChangedProperties.push('Users.' + action.payload.Index + '.SalesforceId');
            }
            if (!draft.model.ChangedProperties.includes('Users.' + action.payload.Index + '.LanguageCode')) {
                draft.model.ChangedProperties.push('Users.' + action.payload.Index + '.LanguageCode');
            }
        }
    });
};

const updateEmail = (state, action) => {
    return produce(state, (draft) => {
        if (!draft.model.ChangedProperties.includes('Users.' + action.payload.Index + '.Email')) {
            draft.model.ChangedProperties.push('Users.' + action.payload.Index + '.Email');
        }
        draft.model.Users[action.payload.Index].Email = action.payload.Email;
    });
};

const selectAllFeatures = (state, action) => {
    return produce(state, (draft) => {
        let features = [];
        let value = true;

        draft.model.ParentServices[action.payload.parentIndex].ClientServices.forEach((s) => {
            const f = s.Features.find((f) => {
                return f.FeatureId === action.payload.featureId;
            });
            if (f) {
                features.push(f);
            }
        });

        if (
            features.every((f) => {
                return f.IsSelected;
            })
        ) {
            value = false;
        }

        features.forEach((f) => {
            f.IsSelected = value;
        });

        if (value === true) {
            draft.model.ParentServices[action.payload.parentIndex].ClientServices.forEach((s) => {
                let f = s.Features.find((f) => {
                    return f.FeatureCode === Constants.MLP_FEATURE_CODE;
                });
                f.IsSelected = true;
            });
        }
    });
};

const checkFeature = (state, action) => {
    return produce(state, (draft) => {
        const cs = draft.model.ParentServices[action.payload.parentIndex].ClientServices[action.payload.clientIndex];
        cs.Features[action.payload.featureIndex].IsSelected = action.payload.checked;
        if (action.payload.checked) {
            const mlpFeature = cs.Features.find((element) => element.FeatureCode === Constants.MLP_FEATURE_CODE);
            mlpFeature.IsSelected = true;
        }
    });
};

const setValidationMessages = (state, action) => {
    return produce(state, (draft) => {
        if (action.payload.validationMessages.Users) {
            draft.ValidationMessages.Users = action.payload.validationMessages.Users;
        } else {
            draft.ValidationMessages = initValidationMessages(state.model);
        }
        draft.Error = false;
    });
};

const setValidationMessagesSubmit = (state, action) => {
    return produce(state, (draft) => {
        if (action.payload.validationMessages.Users) {
            draft.ValidationMessages.Users = action.payload.validationMessages.Users;
        } else {
            draft.ValidationMessages = initValidationMessages(state.model);
        }
        if (action.payload.validationMessages['AllParentServices']) {
            draft.ValidationMessages['AllParentServices'] = action.payload.validationMessages['AllParentServices'];
        }
        draft.Error = false;
    });
};

const modelChange = (state, action) => {
    return produce(state, (draft) => {
        const keySlice = action.payload.key.split('.');
        for (let prop in draft.model.Users[keySlice[1]]) {
            if (Object.prototype.hasOwnProperty.call(draft.model.Users[keySlice[1]], prop)) {
                if (prop === keySlice[2]) {
                    draft.model.Users[keySlice[1]][prop] = action.payload.value;
                    if (!draft.model.ChangedProperties.includes(action.payload.key)) {
                        draft.model.ChangedProperties.push(action.payload.key);
                    }
                }
            }
        }
        draft.model.Error = null;
    });
};

const setTeamMemberDetailsStatus = (state, action) => {
    return produce(state, (draft) => {
        draft.model.Users.find((element) => element.UserId === action.payload.userId).Status = action.payload.value;
    });
};

const setTeamMemberDetailsError = (state, action) => {
    return produce(state, (draft) => {
        draft.model.Error = action.payload.value;
    });
};

const clearState = (state) => {
    return produce(state, (draft) => {
        draft = initialState;
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NEW_TEAM_MEMBER:
            return setTeamMember(state, action);
        case actionTypes.CHANGE_USER_ROLE:
            return setUserRole(state, action);
        case actionTypes.ADD_NEW_USER:
            return addNewUser(state);
        case actionTypes.REMOVE_USER:
            return removeUser(state, action);
        case actionTypes.UPDATE_EMAIL:
            return updateEmail(state, action);
        case actionTypes.SELECT_USER:
            return selectUser(state, action);
        case actionTypes.SELECT_ALL_FEATURES:
            return selectAllFeatures(state, action);
        case actionTypes.CHECK_FEATURE:
            return checkFeature(state, action);
        case actionTypes.SET_TEAM_MEMBER_DETAILS_STATUS:
            return setTeamMemberDetailsStatus(state, action);
        case actionTypes.SET_VALIDATION_MESSAGES:
            return setValidationMessages(state, action);
        case actionTypes.SET_VALIDATION_MESSAGES_SUBMIT:
            return setValidationMessagesSubmit(state, action);
        case actionTypes.SET_TEAM_MEMBER_DETAILS_ERROR:
            return setTeamMemberDetailsError(state, action);
        case actionTypes.CLEAR_STATE:
            return clearState(state);
        case actionTypes.MODEL_CHANGE:
            return modelChange(state, action);
        case actionTypes.RESET_TEAM_MANAGEMENT_DETAILS_STATE:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
