import queryString from 'query-string';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class Auth extends Component {
    state = { isAuthorized: false, termsAccepted: 'False', lang: 'en' };

    componentDidMount() {
        if (this.props.location.search) {
            let params = queryString.parse(this.props.location.search);
            localStorage.setItem('token', params.token);
            localStorage.setItem('terms', params.terms);
            localStorage.setItem('lang', params.lang);
            if (params.key) {
                localStorage.setItem('key', params.key);
            }
            this.setState({ isAuthorized: true, termsAccepted: params.terms, lang: params.lang });
        }
    }

    render() {
        if (this.state.isAuthorized) {
            if (this.state.termsAccepted === 'True') {
                return <Redirect to='/' />;
            } else if (this.state.lang === 'en') {
                return <Redirect to='/Auth/TermsOfService' />;
            } else if (this.state.lang === 'fr') {
                return <Redirect to='/Auth/TermsOfServiceFrench' />;
            }
        } else return null;
    }
}

export default Auth;
