import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../../../../common/features/Button';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { formatShipmentsForDownload } from '../../../common/functions/clearancesDownloadFunctions';
import { DownloadClearancesData, GetShipmentList } from '../../../redux/actions/clearancesDownload';
import { useClearancesSelector } from '../../../redux/hooks';

const clearanceListConfigParams = {
    PAGE_SIZE: 10000,
    START_ROW: 1
};

const DownloadAllResults = () => {
    const { dateRangeSelected: selectedDateRange, customDateRange } = useClearancesSelector((state) => state.dateRange);
    const { initialClientSelection } = useClearancesSelector((state) => state.clientSelection);
    const { allResultsList } = useClearancesSelector((state) => state.clearancesDownload);
    const gridSettings = useClearancesSelector((state) => state.shipmentGridSettings);
    const searchFilter = useClearancesSelector((state) => state.searchFilter);
    const { user } = useClearancesSelector((state) => state.hydration);

    const translate = useTranslation();
    const dispatch = useDispatch();

    const country = initialClientSelection?.Countries.find((item) => item.IsSelected);

    const getClearances = (pageSize: number, startRow: number) => {
        if (user?.ClientCaptionNumbers && country?.Code) {
            const params = {
                Status: null,
                Country: country?.Code.toUpperCase(),
                ClientCaptionNumbers: user?.ClientCaptionNumbers,
                PageSize: pageSize,
                OrderBy: gridSettings.columnSortFields,
                StartRow: startRow,
                ColumnFilters: gridSettings?.columnFilters,
                DateRange: { dateRangeSelected: selectedDateRange.id, customDateRange: customDateRange },
                AdvancedSearchFilters: { Tags: searchFilter.tags },
                ClearancesCreatedOnDate: user?.PortalClearancesCreatedOnMinDate ?? ''
            };

            dispatch(GetShipmentList(params));
        }
    };

    useEffect(() => {
        if (allResultsList.length) {
            dispatch(
                DownloadClearancesData({
                    CountryCode: country?.Code!,
                    ReturnAllFields: true,
                    Shipments: formatShipmentsForDownload(allResultsList, country?.Code!, true)
                })
            );
        }
    }, [allResultsList]);

    return (
        <div>
            <Button variant='link' onClick={() => getClearances(clearanceListConfigParams.PAGE_SIZE, clearanceListConfigParams.START_ROW)}>
                {translate('DownloadAllClearances_Label')}
            </Button>
            {translate('DownloadAllClearancesLimit_Label')}
            <span className='ml-2 mr-2'>
                <FontAwesomeIcon icon={faDownload} />
            </span>
        </div>
    );
};

export default DownloadAllResults;
