import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/lii-logo.svg';
import * as actions from '../../store/actions/index';
import { closeMenuModal, openMenuModal } from '../../utils/menuModalHandler.js';
import Modal from './Modal';
import NestedNavigation from './NestedNavigation';

class TopBottomContainer extends Component {
    render() {
        const isUserLoggedIn = this.props.userModel.username !== undefined && this.props.userModel.username !== null ? true : false;
        const menuButton = isUserLoggedIn ? (
            <li className='menu-button' onClick={() => this.props.onOpenModal()}>
                <button id='menu-expander' className='button blue'>
                    <span>My Livingston</span>
                    <i className='fa fa-bars'></i>
                </button>
            </li>
        ) : null;

        return (
            <div className='row justify-content-between'>
                <Modal isOpen={this.props.isModalOpen} onRequestClose={() => this.props.onCloseModal()} />
                <div className='header-col-left'>
                    <Link to='/'>
                        <img className='lii_logo' alt='lii-logo' src={logo} />
                    </Link>
                </div>
                <div className='header-col-right'>
                    <NestedNavigation
                        data={this.props.data}
                        itemCssChildrenClass='lii_menu-item-has-children lii_parent'
                        listCssClass='lii_primary-nav'
                        itemCssClass='lii_menu-item lii_menu-item-type-custom lii_menu-item-object-custom'
                    >
                        {menuButton}
                    </NestedNavigation>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userModel: state.user.model,
        isModalOpen: state.menu.isModalOpen
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenModal: () => {
            openMenuModal();
            dispatch(actions.openModal());
        },
        onCloseModal: () => {
            closeMenuModal();
            dispatch(actions.closeModal());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBottomContainer);
