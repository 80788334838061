import { useCallback } from 'react';
import { useTeamDispatch } from '../../redux/hooks';
import { resetDataState } from '../../redux/reducers/teamGridSettingsSlice';
import { setSearchFilter } from '../../redux/reducers/teamSearchFilterSlice';
import { setSelectedStatus } from '../../redux/reducers/teamStatusFilterSlice';

export const useTeamGridPageReset = () => {
    const dispatch = useTeamDispatch();

    const reset = useCallback(() => {
        dispatch(setSelectedStatus(0));
        dispatch(setSearchFilter(''));
        dispatch(resetDataState({ take: 10, skip: 0 }));
    }, [dispatch]);

    return reset;
};
