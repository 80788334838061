import { Component, React } from 'react';
import { connect } from 'react-redux';
import WidgetSelectorGridSection from './WidgetSelectorGridSection';

class WidgetSelectorGrid extends Component {
    selectAll = (isSelected) => {
        this.props.onToggleSelect(isSelected);
    };

    compare = (a, b) => {
        if (a.Widget.Name < b.Widget.Name) return -1;
        if (a.Widget.Name > b.Widget.Name) return 1;
        return 0;
    };

    render() {
        const sectionName = 'Customs Brokerage';
        const customsBrokerage = this.props.data ? (
            <WidgetSelectorGridSection title={sectionName} widgets={this.props.data} onWidgetSelect={this.props.onToggleWidget} />
        ) : null;

        if (Array.isArray(this.props.data)) {
            this.props.data.sort(this.compare);
        }

        return (
            <div className='result-grid-wrapper'>
                <div className='widget-selector-grid-content'>
                    <div className='row select-deselect-row'>
                        <div className='col-sm-6'>
                            <button className='anchor-button' onClick={() => this.selectAll(true)}>
                                {this.props.translations.Phrases['SelectAll_Label']}
                            </button>
                            <button className='anchor-button' onClick={() => this.selectAll(false)}>
                                {this.props.translations.Phrases['Deselect_All_Label']}
                            </button>
                        </div>
                    </div>
                    {customsBrokerage}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(WidgetSelectorGrid);
