import React from 'react';
import useInputToggleCheck from '../hooks/useInputToggleCheck';

interface RadioButtonProps {
    id: string;
    name: string;
    isChecked?: boolean;
    children?: React.ReactNode;
    divClass?: string;
    labelClass?: string;
    className?: string;
    disabled?: boolean;
    onChange?: (key: string, value: boolean) => void;
    onClick?: () => void;
}

const RadioButton: React.FC<RadioButtonProps> = ({
    id,
    name,
    isChecked,
    children,
    divClass = 'custom-control custom-radio my-1',
    labelClass = 'custom-control-label',
    className = 'custom-control-input',
    onChange,
    onClick
}) => {
    const { checked, handleChange } = useInputToggleCheck({ isChecked, onChange });

    return (
        <div className={divClass}>
            <input type='radio' name={name} id={id} className={className} checked={checked} onChange={handleChange} onClick={onClick} />
            <label className={labelClass} htmlFor={id}>
                {children}
            </label>
        </div>
    );
};

export default RadioButton;
