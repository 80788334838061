import $ from 'jquery';
import { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actionCreators from '../../../store/actions/index';

class EmailConfirmedForm extends Component {
    componentDidMount = () => {
        $('#overlay').show();

        this.props.onInitTranslations('ClientAdmin.Signup.EmailConfirmed');

        const requestId = this.props.location.search.substring(
            this.props.location.search.indexOf('=') + 1,
            this.props.location.search.length
        );

        this.props.onConfirmEmail(requestId, this.props.history);
    };

    componentDidUpdate() {
        if (document.title.length === 2) {
            document.title = this.props.translations.Phrases['Page_Title'];
        }

        // Show spinner + prevent user from leaving
        const isActive = !['Locked', 'AlreadyDone', 'InvalidRequest', 'Error'].includes(this.props.emailConfirmationStatus);

        if (isActive) {
            $('#overlay').show();
        } else {
            $('#overlay').hide();
        }
    }

    componentWillUnmount = () => {
        this.props.onResetEmailConfirmationState();
    };

    GetMessages() {
        let messageLvl1;
        let messageLvl2;
        let messageLvl3;
        let btnMessage;

        if (this.props.emailConfirmationStatus === 'Locked') {
            messageLvl1 = this.props.translations.Phrases['ConfirmationLocked_Label_Level_1'];
            messageLvl2 = this.props.translations.Phrases['ConfirmationLocked_Label_Level_2'];
            messageLvl3 = this.props.translations.Phrases['ConfirmationLocked_Label_Level_3'];
            btnMessage = this.props.translations.Phrases['ConfirmationLocked_BackToDashboard_Label'];
        } else if (this.props.emailConfirmationStatus === 'AlreadyDone') {
            messageLvl1 = this.props.translations.Phrases['ConfirmationDone_Label_Level_1'];
            messageLvl2 = this.props.translations.Phrases['ConfirmationDone_Label_Level_2'];
            messageLvl3 = this.props.translations.Phrases['ConfirmationDone_Label_Level_3'];
            btnMessage = this.props.translations.Phrases['ConfirmationDone_BackToDashboard_Label'];
        } else if (this.props.emailConfirmationStatus === 'InvalidRequest') {
            messageLvl1 = this.props.translations.Phrases['InvalidRequest_Label_Level_1'];
            messageLvl2 = this.props.translations.Phrases['InvalidRequest_Label_Level_2'];
            messageLvl3 = this.props.translations.Phrases['InvalidRequest_Label_Level_3'];
            btnMessage = this.props.translations.Phrases['InvalidRequest_BackToDashboard_Label'];
        } else {
            messageLvl1 = this.props.translations.Phrases['Page_Header'];
            messageLvl2 = this.props.translations.Phrases['Page_Sub_Header'];
            messageLvl3 = this.props.translations.Phrases['Page_Sub_Header_2'];
            btnMessage = this.props.translations.Phrases['BackToDashboard_Label'];
        }
        return { messageLvl1, messageLvl2, messageLvl3, btnMessage };
    }

    render() {
        let { messageLvl1, messageLvl2, messageLvl3, btnMessage } = this.GetMessages();

        return (
            <div id='page'>
                <HelmetProvider>
                    <Helmet>
                        <title>{this.props.translations.Phrases['Page_Title']}</title>
                    </Helmet>
                </HelmetProvider>
                <div className='page-wrapper'>
                    <div className='page-top-row'>
                        <div className='page-header-section'>
                            <nav className='mlp-breadcrumb'>
                                <Link to='/Dashboard' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_1']}
                                </Link>
                                <i className='fa fa-caret-right'></i>
                                <Link to='#/' className='breadcrumb-parent'>
                                    {this.props.translations.Phrases['Page_Header_Navigation_Level_2']}
                                </Link>
                                <i className='fa fa-caret-right'></i>
                                <span> {this.props.translations.Phrases['Page_Header_Navigation_Level_3']}</span>
                            </nav>
                            <div>
                                <h1 className='bold-title'>{messageLvl1}</h1>
                                <h3 className='subheader'>{messageLvl2}</h3>
                                <h3 className='subheader'>{messageLvl3}</h3>
                            </div>
                        </div>
                        <div className='back-link'>
                            <Link to='/Dashboard' className='breadcrumb-parent'>
                                {btnMessage}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        emailConfirmationStatus: state.featureSetup.model.EmailConfirmationStatus,
        translations: state.translations.model
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitTranslations: (phraseGroup) => dispatch(actionCreators.initTranslations(phraseGroup)),
        onConfirmEmail: (requestId, history) => dispatch(actionCreators.confirmEmail(requestId, history)),
        onResetEmailConfirmationState: () => dispatch(actionCreators.resetEmailConfirmationState())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmedForm);
