import produce from 'immer';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatTranslated, isVisible } from '../../utils/mlp-utils';
import CustomDropDown from '../CustomDropDown/CustomDropDown';
import Textbox from '../Textbox/Textbox';
import { filters as Constants } from './../../constants/mlp-constants';

class MeasuresFilter extends Component {
    state = {
        hidden: false,
        rangeStart: false,
        rangeStartDdl: [
            { Key: 'Equal', Value: 'Equal to', DataType: 'Text', IsSelected: true },
            { Key: 'RangeStart', Value: 'Greater than', DataType: 'Text', IsSelected: false }
        ],
        rangeEndDdl: [{ Key: 'RangeEnd', Value: 'Less than', DataType: 'Text', IsSelected: true }]
    };

    static defaultProps = {
        countryCodes: ['CA', 'US'],
        searchTypeCode: '*'
    };

    static propTypes = {
        countryCodes: PropTypes.arrayOf(PropTypes.string),
        searchTypeCode: PropTypes.string,
        filterControl: PropTypes.object,
        onOptionSelect: PropTypes.func,
        onValueChanged: PropTypes.func,
        customAction: PropTypes.func
    };

    toggleSection = () => {
        this.setState((prevState) => {
            return { hidden: !prevState.hidden };
        });
    };

    onRangeStartOptionSelect = (newIndex, oldIndex) => {
        const rangeStartDdl = produce(this.state.rangeStartDdl, (draft) => {
            if (oldIndex !== -1) {
                draft[oldIndex].IsSelected = false;
            }
            draft[newIndex].IsSelected = true;
        });
        this.setState({ rangeStartDdl: rangeStartDdl });
        this.setOperator(rangeStartDdl);
    };

    setOperator = (options) => {
        const selectedItem = options.find((item) => item.IsSelected);
        this.setState({ rangeStart: selectedItem.Key === 'RangeStart' });
    };

    onRangeEndOptionSelect = (newIndex, oldIndex) => {
        const rangeEndDdl = produce(this.state.rangeEndDdl, (draft) => {
            if (oldIndex !== -1) {
                draft[oldIndex].IsSelected = false;
            }
            draft[newIndex].IsSelected = true;
        });
        this.setState({ rangeEndDdl: rangeEndDdl });
    };

    filterOptionSelect = (newIndex, oldIndex, name) => {
        this.props.onOptionSelect(newIndex, oldIndex, name);

        const filterControl = this.props.filterControl;
        const filterIndex = filterControl.FilterIndex;
        const selectedItem = filterControl.Options[newIndex];

        const nameFilter = 'Filters.' + filterIndex;
        if (!selectedItem) {
            this.props.onValueChanged('string', nameFilter + '.AttributeDataType');
            this.props.onValueChanged(Constants.ALL_TYPES, nameFilter + '.Attribute');
        } else {
            this.props.onValueChanged(selectedItem.DataType, nameFilter + '.AttributeDataType');
            this.props.onValueChanged(selectedItem.Value, nameFilter + '.Attribute');
        }
    };

    handleSearchChange = (value, name) => {
        const regex = '^(0|[1-9][0-9]{0,2}(?:(,[0-9]{3})*|[0-9]*))(.[0-9]+){0,1}$';
        const regExp = new RegExp(regex);
        const valueIsValid = !value || regExp.test(value) ? true : false;

        if (valueIsValid) {
            //handle equal to case
            if (name.indexOf('Start') > 0) {
                const nameEnd = name.replace('Start', 'End');
                if ($("input[name='" + nameEnd + "']")[0] === undefined) {
                    this.props.onValueChanged(value, nameEnd);
                }
            }
            if (this.props.customAction) {
                this.props.customAction();
            }
        }
    };

    render() {
        const filterControl = this.props.filterControl;
        const filterIndex = filterControl.FilterIndex;
        const nameStart = 'Filters.' + filterIndex + '.RangeStart';
        const nameEnd = 'Filters.' + filterIndex + '.RangeEnd';

        if (!isVisible(filterControl, this.props.searchTypeCode)) {
            return null;
        }

        const close = this.state.hidden
            ? formatTranslated('Show_Label', this.props.translations.Phrases)
            : formatTranslated('Hide_Label', this.props.translations.Phrases);

        const rangeStartDropDown = (
            <div className='filter-dropdown'>
                <CustomDropDown
                    name='rangeStartDdl'
                    options={this.state.rangeStartDdl}
                    valueField='Key'
                    textField='Value'
                    onItemChanged={this.onRangeStartOptionSelect}
                />
            </div>
        );

        const rangeEndDropDown = (
            <div className='filter-dropdown'>
                <CustomDropDown
                    name='rangeEndDdl'
                    options={this.state.rangeEndDdl}
                    valueField='Key'
                    textField='Value'
                    onItemChanged={this.onRangeEndOptionSelect}
                />
            </div>
        );

        const searchBox = (
            <div className='shipment-search-container'>
                <div className='search-box shipment-search'>
                    <Textbox
                        placeholder=''
                        className='imaging-search-textbox'
                        name={nameStart}
                        fireOnChange={true}
                        fireOnImageBlur={true}
                        icon=''
                        value={filterControl.RangeStart ? filterControl.RangeStart : ''}
                        onValueChanged={this.props.onValueChanged}
                        customAction={this.handleSearchChange}
                    />
                </div>
            </div>
        );

        const searchBoxLessThan = (
            <div className='shipment-search-container'>
                <div className='search-box shipment-search'>
                    <Textbox
                        placeholder=''
                        className='imaging-search-textbox'
                        name={nameEnd}
                        fireOnChange={true}
                        fireOnImageBlur={true}
                        icon=''
                        value={filterControl.RangeEnd ? filterControl.RangeEnd : ''}
                        onValueChanged={this.props.onValueChanged}
                        customAction={this.handleSearchChange}
                    />
                </div>
            </div>
        );

        const customDropDown = (
            <div className='filter-dropdown'>
                <CustomDropDown
                    name={'Filters.' + filterIndex + '.Options'}
                    options={filterControl.Options}
                    valueField='Value'
                    textField='TranslatedName'
                    onItemChanged={this.filterOptionSelect}
                />
            </div>
        );

        let mainContent;
        if (this.state.rangeStart) {
            mainContent = (
                <div>
                    {customDropDown}
                    {rangeStartDropDown}
                    {searchBox}
                    {rangeEndDropDown}
                    {searchBoxLessThan}
                </div>
            );
        } else {
            mainContent = (
                <div>
                    {customDropDown}
                    {rangeStartDropDown}
                    {searchBox}
                </div>
            );
        }

        return (
            <div className='filter-section'>
                <div className='row header-section'>
                    <h4 className='col-sm-8 text-left header'>{formatTranslated('Measures_Label', this.props.translations.Phrases)}</h4>
                    <div className='col-sm-4 text-right'>
                        <span className='btn btn-link' onClick={this.toggleSection}>
                            {close}
                        </span>
                    </div>
                </div>
                <div className={this.state.hidden ? 'd-none' : 'd-block'}>
                    {mainContent}
                    <hr />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(MeasuresFilter);
