import React from 'react';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { MOTCodes, MOTMap } from '../../../common/constants/constants-clearances';
import { parseBillOfLading, parseDateTimeET } from '../../../common/functions/parser';
import { useClearancesSelector } from '../../../redux/hooks';

const ShipmentDetailsCard = () => {
    const { shipmentDetails } = useClearancesSelector((state) => state.shipmentDetails);
    const translate = useTranslation();

    const validateValues = (value: any) => {
        return value === undefined || value === '' || value === null || value.length === 0 ? '' : value;
    };

    const isValid = (value: any) => {
        return value === undefined || value === '' || value === null || value.length === 0 ? false : true;
    };

    const getShipmentDetails = () => {
        let generatedShipmentDetails: {
            Key: string;
            Value: string | string[] | null | undefined;
        }[] = [
            { Key: translate('EntryNo_Label'), Value: shipmentDetails?.EntryNumber },
            ...(shipmentDetails?.Destination !== 'USA'
                ? [{ Key: translate('CargoControlNumber_Label'), Value: shipmentDetails?.CargoControlNumber }]
                : []),
            { Key: translate('CarrierCode_Label'), Value: shipmentDetails?.CarrierCode },
            { Key: translate('Carrier_Label'), Value: shipmentDetails?.CarrierName }
        ];

        // generate shipment details based on destination
        if (shipmentDetails?.Destination === 'USA') {
            generatedShipmentDetails.push(
                {
                    Key: translate('MasterBillOfLading_Label'),
                    Value: isValid(shipmentDetails?.BillOfLading)
                        ? parseBillOfLading(validateValues(shipmentDetails?.BillOfLading), ['MasterBill'])
                        : ''
                },
                {
                    Key: translate('HouseBillOfLading_Label'),
                    Value: isValid(shipmentDetails?.BillOfLading)
                        ? parseBillOfLading(validateValues(shipmentDetails?.BillOfLading), ['HouseBill'])
                        : ''
                },
                { Key: translate('ReferenceNo_Label'), Value: shipmentDetails.ReferenceNumber },
                {
                    Key: translate('ModeOfTransport_Label'),
                    Value: isValid(shipmentDetails.ModeOfTransport)
                        ? translate(
                              MOTMap[MOTCodes[shipmentDetails?.ModeOfTransport as keyof typeof MOTCodes] as keyof typeof MOTMap]?.label
                          )
                        : ''
                },
                { Key: translate('ManifestQuantity_Label'), Value: `${shipmentDetails.ManifestQuantity} PCS` },
                { Key: translate('DocumentsReceived_Label'), Value: parseDateTimeET(shipmentDetails.DocsReceivedDateTime) }
            );
        } else {
            generatedShipmentDetails.push(
                {
                    Key: translate('BillOfLading_Label'),
                    Value: isValid(shipmentDetails?.BillOfLading)
                        ? parseBillOfLading(validateValues(shipmentDetails?.BillOfLading), ['MasterBill', 'HouseBill'])
                        : ''
                },
                { Key: translate('ReferenceNo_Label'), Value: shipmentDetails?.ReferenceNumber },
                { Key: translate('ReferenceNo2_Label'), Value: shipmentDetails?.ReferenceNumber2 },
                {
                    Key: translate('ContainerNo_Label'),
                    Value:
                        shipmentDetails?.ContainerNumbers && isValid(shipmentDetails.ContainerNumbers)
                            ? [...shipmentDetails.ContainerNumbers]
                            : ''
                },
                { Key: translate('CustomsPort_Label'), Value: shipmentDetails?.CustomsPort },
                { Key: translate('NumberOfPackages_Label'), Value: `${shipmentDetails?.ManifestQuantity} PCS` },
                { Key: translate('GrossWeight_Label'), Value: `${shipmentDetails?.GrossWeight} KGM` },
                { Key: translate('DocumentsReceived_Label'), Value: parseDateTimeET(shipmentDetails?.DocsReceivedDateTime) }
            );
        }
        return generatedShipmentDetails;
    };
    return (
        <div className='card mb-3'>
            <div className='card-header'>
                <h4>{translate('ShipmentDetails_Label')}</h4>
            </div>
            <div className='card-body'>
                <dl className='row mb-0'>
                    {getShipmentDetails().map((item, index) => (
                        <React.Fragment key={index}>
                            <dt className='col-md-4 col-lg-5'>{item.Key}:</dt>
                            <dd className='col-md-8 col-lg-7'>{item.Value}</dd>
                        </React.Fragment>
                    ))}
                </dl>
            </div>
        </div>
    );
};

export default ShipmentDetailsCard;
