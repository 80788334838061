import React, { Component } from 'react';
import CheckIcon from '../Icons/CheckIcon';
import ExpandIcon from '../Icons/ExpandIcon';
import ChooserGridRowDetails from './ChooserGridRowDetails';

class ChooserGridRow extends Component {
    state = {
        showDetails: false
    };

    toggleDetails = (e) => {
        this.setState({ showDetails: !this.state.showDetails });
    };

    showDetailsIconClass = () => {
        const elementClass = ['fa', 'fa-lg'];
        if (this.state.showDetails) {
            elementClass.push('fa-minus-square-o');
        } else {
            elementClass.push('fa-plus-square-o');
        }
        return elementClass.join(' ');
    };

    selectGroup = (cs, isChecked) => {
        this.props.onClientServiceSelect(!isChecked, cs, true);
    };

    render() {
        const rowDetails = this.state.showDetails ? <ChooserGridRowDetails {...this.props} /> : null;
        const cs = this.props.data[0];
        const displayText = cs.Number + ' - ' + cs.Name;
        const csIsSelected = this.props.data.find((cs) => cs.IsSelected);
        const isChecked = csIsSelected ? csIsSelected.IsSelected : false;

        return (
            <div>
                <div className='row grid-row'>
                    <div className='col-sm-1 col-1'>
                        {
                            <span onClick={this.toggleDetails}>
                                <i>
                                    <ExpandIcon isExpanded={this.state.showDetails} />
                                </i>
                            </span>
                        }
                    </div>
                    <div className='col-sm-9 col-9'>
                        <span className='row-display-text'>{displayText}</span>
                    </div>
                    <div className='col-sm-2 col-2'>
                        {
                            <span onClick={this.selectGroup.bind(this, cs, isChecked)}>
                                <input
                                    type='checkbox'
                                    onChange={this.selectGroup.bind(this, cs)}
                                    checked={isChecked}
                                    className='checkbox-none'
                                />
                                <CheckIcon isChecked={isChecked} />
                            </span>
                        }
                    </div>
                </div>
                {rowDetails}
            </div>
        );
    }
}

export default ChooserGridRow;
