import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslation from '../../../../../common/hooks/useTranslation';

interface HeaderWithDropdownProps {
    children: React.ReactNode;
}

const HeaderWithDropdown: React.FC<HeaderWithDropdownProps> = ({ children }) => {
    const translate = useTranslation();
    return (
        <div className='d-flex justify-content-between align-items-center py-4'>
            <div className='d-flex align-items-center'>
                <span className='badge badge-circular badge-info'>
                    <FontAwesomeIcon icon={faHome} />
                </span>
                <h1 className='mb-0 ml-3'>{translate('Dashboard_Label')}</h1>
            </div>
            {children}
        </div>
    );
};

export default HeaderWithDropdown;
