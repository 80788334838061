import { getter } from '@progress/kendo-data-query';
import {
    GridColumn as Column,
    Grid,
    GridCellProps,
    GridHeaderSelectionChangeEvent,
    GridSelectionChangeEvent,
    getSelectedState
} from '@progress/kendo-react-grid';
import React, { useCallback, useEffect, useRef } from 'react';
import useTranslation from '../../../../../../common/hooks/useTranslation';
import { useSubscribersDispatch, useSubscribersSelector } from '../../../../redux/hooks';
import { setClients, setClientsState, setFilteredCLients } from '../../../../redux/reducers/subscribersSlice';
import FormattedGridCell from './FormattedGridCell';

interface ClientTableProps {
    searchParam: null | string;
}

const ClientTable: React.FC<ClientTableProps> = ({ searchParam }) => {
    const { selectedClients, filteredClients, clients } = useSubscribersSelector((state) => state.subscribers);
    const { initialClientSelection } = useSubscribersSelector((state) => state.clientSelection);
    const dispatch = useSubscribersDispatch();
    const translate = useTranslation();
    const initialLoad = useRef(true);

    const DATA_ITEM_KEY = 'number';
    const SELECTED_FIELD = 'selected';
    const idGetter = getter(DATA_ITEM_KEY);

    const selectedCountryCodeInitial = initialClientSelection?.Countries.find((c) => c.IsSelected)?.Code;

    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});

    //Filtering and sorting clients when searching
    useEffect(() => {
        if (searchParam) {
            const selected = selectedClients && Object.keys(selectedClients);
            const filteredSorted = clients
                ?.filter((item) => selected?.includes(item.number.toString()) || item.number.toString().includes(searchParam.trim()))
                //@ts-ignore
                .sort((a, b) => {
                    if (a.number < b.number) return -1;
                    if (a.number > b.number) return 1;
                    // equal
                    return 0;
                })
                //@ts-ignore
                .sort((a, b) => {
                    let foundA = selected?.find((e) => e === a.number.toString()) ? true : false;
                    let foundB = selected?.find((e) => e === b.number.toString()) ? true : false;
                    if (foundA < foundB) return -1;
                    if (foundA > foundB) return 1;
                    // equal
                    return 0;
                });
            dispatch(setFilteredCLients(filteredSorted));
        } else {
            dispatch(setFilteredCLients(null));
        }
    }, [searchParam, selectedClients, clients, dispatch]);

    //Initial clients numbers
    useEffect(() => {
        if (initialLoad.current === true) {
            const initialData: any = [];

            // Client numbers from hydration
            // user?.ClientCaptionNumbers?.forEach((item) => {
            //     initialData.push({ number: item, selected: false });
            // });

            // Client numbers from clientSelection
            initialClientSelection?.ClientTableData?.filter((c) => c.CountryCode === selectedCountryCodeInitial && c.IsSelected).forEach(
                (c) => {
                    initialData.push({ number: c.Number, selected: false });
                }
            );

            if (selectedClients && Object.keys(selectedClients).length > 0) {
                setSelectedState(selectedClients);
            }
            dispatch(
                setClients(
                    //@ts-ignore
                    initialData.sort((a, b) => {
                        if (a.number < b.number) return -1;
                        if (a.number > b.number) return 1;
                        // equal
                        return 0;
                    })
                )
            );
        }
        initialLoad.current = false;
    }, [
        dispatch,
        initialClientSelection?.ClientServices,
        selectedClients,
        initialClientSelection?.ClientTableData,
        selectedCountryCodeInitial
    ]);

    const onSelectionChange = React.useCallback(
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY
            });
            setSelectedState(newSelectedState);
            dispatch(setClientsState(newSelectedState));
        },
        [selectedState, dispatch]
    );

    const onHeaderSelectionChange = React.useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            const checkboxElement: any = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState = {} as any;

            event.dataItems.forEach((item) => {
                newSelectedState[idGetter(item)] = checked;
            });
            setSelectedState(newSelectedState);
            dispatch(setClientsState(newSelectedState));
        },
        [dispatch, idGetter]
    );

    const GridCell = useCallback((props: GridCellProps) => {
        return <FormattedGridCell {...props} />;
    }, []);

    return (
        <>
            <Grid
                className='clients-table'
                data={(filteredClients ? filteredClients : clients)?.map((item: any) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)]
                }))}
                total={(filteredClients ? filteredClients : clients)?.length}
                dataItemKey={DATA_ITEM_KEY}
                selectedField={SELECTED_FIELD}
                onSelectionChange={onSelectionChange}
                onHeaderSelectionChange={onHeaderSelectionChange}
            >
                <Column field={SELECTED_FIELD} width='50px' cell={GridCell} />
                <Column field='ClientNumber' title={translate('ClientNumber_Label')} cell={GridCell} />
                <Column field='Destination' title={translate('Destination_Label')} cell={GridCell} />
            </Grid>
        </>
    );
};

export default ClientTable;
