import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import Button from '../../../../../common/features/Button';
import ModalWrapper from '../../../../../common/features/Wrappers/ModalWrapper';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import useTranslation from '../../../../../common/hooks/useTranslation';
import { TEAM_MEMBER_STATUS_LABELS, TEAM_SEARCH_PARAM_KEYS } from '../../../common/constants/constants-team';
import { useTeamMemberDetailsQuery } from '../../../redux/api/teamApi';

interface PendingModalProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PendingModal({ isOpen, setIsOpen }: PendingModalProps) {
    const [searchParams] = useSearchParams();
    const userId = parseInt(searchParams.get(TEAM_SEARCH_PARAM_KEYS.userId) || '');

    const { data } = useTeamMemberDetailsQuery(userId.toString());

    const teamMember = data?.Users?.[0];

    const translate = useTranslation();

    const handleClose = () => {
        setIsOpen(false);
    };

    if (!isOpen) return null;

    return (
        <ModalWrapper onEscape={handleClose}>
            <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                <div className='modal-content'>
                    <div className='modal-header '>
                        <h3 className='modal-title'>{teamMember?.Status && translate(TEAM_MEMBER_STATUS_LABELS[teamMember.Status])}</h3>
                        <button
                            type='button'
                            className='modal-close btn btn-tertiary btn-lg'
                            data-dismiss='modal'
                            aria-label='Close'
                            onClick={handleClose}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                    </div>
                    <div className='modal-body'>
                        <p className='note mb-3 notification-message'>{translate('PendingReason_Label')}</p>
                        {data && data.Users && teamMember?.PendingSteps
                            ? teamMember.PendingSteps?.map((item, index) => {
                                  return (
                                      <p key={index} className='note mb-0 notification-message'>
                                          {translate(
                                              item.PendingReason,
                                              data && data.Users && teamMember.Email,
                                              dayjs(item.DateStarted).format('MM/DD/YYYY')
                                          )}
                                      </p>
                                  );
                              })
                            : translate('OtherReasonForPendingStatus_Label')}
                    </div>
                    <div className='modal-footer'>
                        <Button variant='secondary' size='medium' onClick={handleClose}>
                            {translate('Close_Label')}
                        </Button>
                    </div>
                </div>
            </div>
        </ModalWrapper>
    );
}
