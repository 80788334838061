import { load, loadMessages } from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json';
import frNumbers from 'cldr-numbers-full/main/fr/numbers.json';
import { useEffect, useState } from 'react';
import enTranslations from '../grid-languages/en.json';
import frTranslations from '../grid-languages/fr.json';
import { LanguageSlice } from '../models/ReduxSlices';

const useGridLanguage = (selectedLanguage: LanguageSlice['languageSelected']) => {
    const [gridLanguage, setGridLanguage] = useState({ language: 'en-US', locale: 'en' });

    // Change the language of grid's menus on language change
    useEffect(() => {
        if (selectedLanguage?.code === 'en') {
            loadMessages(enTranslations, 'en-US');
            setGridLanguage({ language: 'en-US', locale: 'en' });
        } else if (selectedLanguage?.code === 'fr') {
            loadMessages(frTranslations, 'fr-CA');
            load(likelySubtags, weekData, frNumbers, frCaGregorian, frDateFields);
            setGridLanguage({ language: 'fr-CA', locale: 'fr' });
        }
    }, [selectedLanguage?.code]);

    return gridLanguage;
};

export default useGridLanguage;
