import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import toastr from 'toastr';
import axios from '../../../axios-mlp';
import Dropdown from '../../../common/Dropdown/Dropdown';
import Searchbox from '../../../common/Searchbox/Searchbox';
import SubmitButton from '../../../common/SubmitButton/SubmitButton';
import TextBox from '../../../common/Textbox/Textbox';
import { manageUser as Constants } from '../../../constants/mlp-constants';
import * as actions from '../../../store/actions/index';
import { setValueByPath } from '../../../utils/mlp-utils';

class ManageUserHeader extends Component {
    state = {
        initialFormValues: null,
        isButtonDisabled: true
    };

    formatResultBuilder = (item) => {
        const address = item.Address || '';
        const addressArray = address.split(',');
        let ContactAddress = <></>;
        if (addressArray.length > 1) {
            ContactAddress = (
                <div className='data-row'>
                    <div className='result-value'>
                        <>{addressArray[0]}</>
                        <>
                            {addressArray[1]}, {addressArray[2]}
                        </>
                    </div>
                </div>
            );
        }

        return (
            <div className='user-search-results'>
                <div className='data-row'>
                    <div className='result-value'>
                        <strong> {item.FullName} </strong>
                    </div>
                </div>
                <div className='data-row'>
                    <div className='result-value'>{item.Email}</div>
                </div>
                <div className='data-row'>
                    <div className='result-value'>{item.Phone}</div>
                </div>
                <div className='data-row'>
                    <div className='result-value'>{item.CompanyName}</div>
                </div>
                <div className='data-row'>
                    <div className='result-value'>{item.SalesforceId} </div>
                </div>
                {ContactAddress}
            </div>
        );
    };

    validateForm = () => {
        axios
            .post(Constants.VALIDATE_USER_URL, this.props.model)
            .then((response) => {
                let validationMessages = {};
                const json = JSON.parse(response.data);
                for (let i in json) {
                    setValueByPath(validationMessages, i, json[i]);
                }
                this.props.setValidationMessages(validationMessages);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data && error.response.data.Message) {
                        if (error.response.status === 400) {
                            toastr.warning(error.response.data.Message);
                        } else {
                            toastr.error(error.response.data.Message);
                        }
                    } else if (error.response.status !== 401 && error.response.status !== 403) {
                        this.props.history.push('/BasicError');
                    }
                }
            });
    };

    onFormValueChange = (val, name) => {
        this.props.onModelChanged(val, name);

        const u1 = {
            ...this.props.model.User,
            [name.substr(5)]: val
        };
        const u2 = this.state.initialFormValues;

        if (
            u1?.FirstName === u2?.FirstName &&
            u1?.LastName === u2?.LastName &&
            u1?.Email === u2?.Email &&
            u1?.Phone === u2?.Phone &&
            u1?.CompanyName === (u2?.CompanyName || '')
        ) {
            this.setState({ isButtonDisabled: true });
        } else {
            this.setState({ isButtonDisabled: false });
        }
    };

    componentDidUpdate() {
        if (!this.state.initialFormValues) {
            this.setState({
                initialFormValues: this.props.model.User
            });
        }
    }

    render() {
        const val = this.props.validationMessages;
        const u = this.props.model.User;

        const userDetails = (
            <>
                <div className='row'>
                    <div className='col-sm-3'>
                        <TextBox
                            value={u.FirstName ? u.FirstName : ''}
                            label={this.props.translations.Phrases['FirstName_Label']}
                            name={'User.FirstName'}
                            valMsg={val.User ? this.props.translations.Phrases[val.User.FirstName] : ''}
                            readonly={false}
                            onValueChanged={this.onFormValueChange}
                            validate={this.validateForm}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <TextBox
                            value={u.LastName ? u.LastName : ''}
                            label={this.props.translations.Phrases['LastName_Label']}
                            name={'User.LastName'}
                            valMsg={val.User ? this.props.translations.Phrases[val.User.LastName] : ''}
                            readonly={false}
                            onValueChanged={this.onFormValueChange}
                            validate={this.validateForm}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <TextBox
                            value={u.TermsOfServiceDate ? u.TermsOfServiceDate : ''}
                            label={this.props.translations.Phrases['TermsOfConditions_Label']}
                            name={'User.TermsOfServiceDate'}
                            valMsg={val.User ? this.props.translations.Phrases[val.User.TermsOfServiceDate] : ''}
                            readonly={true}
                            onValueChanged={this.props.onModelChanged}
                            validate={this.validateForm}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <Dropdown
                            name={'User.Roles'}
                            options={u.Roles}
                            label={this.props.translations.Phrases['UserRole_Label']}
                            valMsg={val.User ? this.props.translations.Phrases[val.User.Roles] : ''}
                            readonly={true}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-3'>
                        <Searchbox
                            value={u.Email ? u.Email : ''}
                            label={this.props.translations.Phrases['Email_Label']}
                            formatResults={this.formatResultBuilder}
                            onSelectItem={this.props.onSelectManageUser}
                            onValueChanged={this.onFormValueChange}
                            updateProperty={this.props.onEmailChanged}
                            url={Constants.SALESFORCE_CONTACT_URL}
                            name={'User.Email'}
                            minChars={4}
                            valMsg={
                                val.User
                                    ? this.props.translations.Phrases[val.User.Email] ||
                                      this.props.translations.Phrases[val.User.SalesforceId] ||
                                      this.props.translations.Phrases[val.User.SFDC_Email] ||
                                      this.props.translations.Phrases[val.User.ExistsInIdp]
                                    : ''
                            }
                            keepSearchText={true}
                            validate={this.validateForm}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <TextBox
                            value={u.Phone ? u.Phone : ''}
                            label={this.props.translations.Phrases['Phone_Label']}
                            name={'User.Phone'}
                            valMsg={val.User ? this.props.translations.Phrases[val.User.Phone] : ''}
                            placeholder={'(123) 456-7890 x123456'}
                            inputMask='(000) 000-0000 x000000'
                            readonly={false}
                            onValueChanged={this.onFormValueChange}
                            validate={this.validateForm}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <TextBox
                            value={u.Status ? u.Status : ''}
                            label={this.props.translations.Phrases['Status_Label']}
                            name={'Status'}
                            valMsg={''}
                            readonly={true}
                            onValueChanged={this.props.onModelChanged}
                            validate={this.validateForm}
                        />
                    </div>
                    <div className='col-sm-3'>
                        <TextBox
                            value={u.LastLogin ? u.LastLogin.substring(0, 10) : ''}
                            label={this.props.translations.Phrases['LastLogin_Label']}
                            name={'User.LastLogin'}
                            valMsg={val.User ? this.props.translations.Phrases[val.User.LastLogin] : ''}
                            readonly={true}
                            onValueChanged={this.props.onModelChanged}
                            validate={this.validateForm}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-6'>
                        <TextBox
                            value={u.CompanyName ? u.CompanyName : ''}
                            label={this.props.translations.Phrases['CompanyName_Label']}
                            name={'User.CompanyName'}
                            valMsg={val.User ? this.props.translations.Phrases[val.User.CompanyName] : ''}
                            onValueChanged={this.onFormValueChange}
                            validate={this.validateForm}
                        />
                    </div>
                    <div className='col-sm-6'>
                        <SubmitButton
                            label={this.props.translations['Subscribe_Button']}
                            model={this.props.model}
                            postActionUrl={Constants.SUBMIT_MANAGE_USER_URL}
                            postAction={this.props.setValidationMessages}
                            onSuccessfulSubmit={{
                                backToPath: '/Dashboard',
                                backToLabel: 'BackToDashboard_Label',
                                phraseGroup: 'SystemAdmin.ManageUser.SuccessfulSubmit'
                            }}
                            disabled={this.state.isButtonDisabled}
                        />
                    </div>
                </div>
            </>
        );

        return (
            <div className='row w-100'>
                <div className='col-sm-12'>{userDetails}</div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onModelChanged: (value, key) => dispatch(actions.manageUserModelChange(value, key)),
        onEmailChanged: (email) => dispatch(actions.manageUserEmailChange(email)),
        onSelectManageUser: (user) => dispatch(actions.selectManageUser(user)),
        setValidationMessages: (validationMessages) => dispatch(actions.manageUserSetValidationMessages(validationMessages))
    };
};

export default withRouter(connect(null, mapDispatchToProps)(ManageUserHeader));
