import React, { useEffect, useState } from 'react';
import CheckBox from '../../common/features/Checkbox';
import { getCountryFromClientNumber } from '../functions/utils';
import { Notifications, SubscriptionsData } from '../models/ResponseData';

interface OnHoldToggleProps {
    data: SubscriptionsData;
    handleUpdate?: (milestones: Notifications[]) => void;
    disabled?: boolean;
}

const OnHoldToggle: React.FC<OnHoldToggleProps> = ({ data, handleUpdate, disabled = false }) => {
    const [checked, setChecked] = useState(false);
    const country = getCountryFromClientNumber(data?.CustomerNumber!);
    const countryNotifications = country === 'ca' ? data.MilestoneNotifications_CA : data.MilestoneNotifications_US;

    useEffect(() => {
        if (countryNotifications?.length) {
            for (const item of countryNotifications) {
                if (item.Name && /HasNotificationsForClearanceOnHold/.test(item.Name)) {
                    setChecked(item.Value ?? false);
                    break;
                }
            }
        }
    }, [countryNotifications]);

    const handleOnChange = () => {
        setChecked((prevState) => !prevState);
        if (handleUpdate) {
            let milestones = data[`MilestoneNotifications${country === 'ca' ? '_CA' : '_US'}`]?.map((item) => {
                if (item.Name === `HasNotificationsForClearanceOnHold${country === 'ca' ? '_CA' : ''}`) {
                    return { Name: item.Name, Value: !checked };
                }
                return item;
            });
            if (milestones) {
                handleUpdate(milestones);
            }
        }
    };

    return (
        <CheckBox
            id={'checkboxId' + data.SubscriptionId}
            name={'checkboxName' + data.SubscriptionId}
            onChange={handleOnChange}
            isChecked={checked}
            divStyle={{
                width: '1.25rem',
                height: '1.32813rem',
                cursor: 'pointer',
                margin: 'auto'
            }}
            labelClass=''
            inputStyle={{
                width: '1.25rem',
                height: '1.32813rem',
                cursor: 'pointer'
            }}
            inputClass=''
            disabled={disabled}
        />
    );
};

export default OnHoldToggle;
