import { Component } from 'react';
import { connect } from 'react-redux';
import { userStatus as StatusConstants } from '../../constants/mlp-constants';
import MemberRow from './MemberRow';

class TeamGrid extends Component {
    actionsCheck = () => {
        if (this.props.canDelete || this.props.canClone) {
            return <th className='visible-tablet'></th>;
        }
    };

    cloneRoleCheck = () => {
        if (this.props.canClone) {
            return <th className='visible-desktop'> {this.props.translations.Phrases['CloneTeamMember_Label']} </th>;
        }
    };

    deleteButtonRoleCheck = () => {
        if (this.props.canDelete) {
            return <th className='visible-desktop'> {this.props.translations.Phrases['UpdateTeamMemberStatus_Label']} </th>;
        }
    };

    render() {
        const team = this.props.teamMembers;
        const canDelete = this.props.canDelete;
        const canClone = this.props.canClone;
        const showUsers = this.props.showUsers;
        let teamMembers = team
            .filter((member) => member.Status === StatusConstants.ACTIVE || showUsers)
            .map((member) => {
                return (
                    <MemberRow
                        key={member.UserId}
                        memberIndex={member.UserId}
                        teamMember={member}
                        canDelete={canDelete}
                        canClone={canClone}
                    />
                );
            });

        return (
            <div className='row'>
                <div className='col-sm-12'>
                    <br />
                    <div className='table-responsive'>
                        <table className='table table-striped team-grid'>
                            <thead>
                                <tr>
                                    <th>{this.props.translations.Phrases['Name_Label']}</th>
                                    <th>{this.props.translations.Phrases['Status_Label']}</th>
                                    <th className='visible-desktop'>{this.props.translations.Phrases['LastLogin_Label']}</th>
                                    <th>{this.props.translations.Phrases['Email_Label']}</th>
                                    <th>{this.props.translations.Phrases['Phone_Label']}</th>
                                    <th className='visible-desktop'>{this.props.translations.Phrases['Expires_Label']}</th>
                                    {this.actionsCheck()}
                                    {this.cloneRoleCheck()}
                                    {this.deleteButtonRoleCheck()}
                                </tr>
                            </thead>
                            <tbody>{teamMembers}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(TeamGrid);
