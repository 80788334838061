import { useEffect, useState } from 'react';

interface UseDisableColumnsDragProps {
    isLoading: boolean;
    elements: HTMLElement[];
}

const useDisableColumnsDrag = ({ isLoading, elements }: UseDisableColumnsDragProps) => {
    const [disableDrag, setDisableDrag] = useState(false);
    useEffect(() => {
        if (!isLoading) {
            const handleDrag = (event: MouseEvent) => {
                if (event.type === 'mouseenter' && (event.target as HTMLElement).tagName !== 'input') {
                    setDisableDrag(true);
                } else if (event.type === 'mouseleave' && (event.target as HTMLElement).tagName !== 'input') {
                    setDisableDrag(false);
                }
            };
            elements.forEach((element) => {
                if (element && !element.hasAttribute('listener')) {
                    element.style.cursor = 'default';
                    element.setAttribute('listener', '');
                    (element as HTMLElement).addEventListener('mouseenter', handleDrag);
                    (element as HTMLElement).addEventListener('mouseleave', handleDrag);
                }
                return () => {
                    if (element && element.hasAttribute('listener')) {
                        element.removeAttribute('listener');
                        (element as HTMLElement).removeEventListener('mouseenter', handleDrag);
                        (element as HTMLElement).removeEventListener('mouseleave', handleDrag);
                    }
                };
            });
        }
    }, [isLoading, elements]);

    return { disableDrag };
};

export default useDisableColumnsDrag;
