import PropTypes from 'prop-types';
import { Component } from 'react';
import InputBase from '../Inputbase/Inputbase';

class Dropdown extends Component {
    static defaultProps = {
        name: '',
        options: [],
        defaultOptionText: 'Select an option',
        valueField: 'Value',
        textField: 'Text',
        onlyCustomAction: false
    };

    static propTypes = {
        name: PropTypes.string,
        options: PropTypes.array,
        label: PropTypes.string,
        readonly: PropTypes.bool,
        onItemChanged: PropTypes.func,
        customAction: PropTypes.func,
        valMsg: PropTypes.string
    };

    state = {
        value: ''
    };

    validateDefaultPropsValue(propsOptions) {
        let displayVal = propsOptions.find((element) => element.IsSelected === true);

        if (displayVal) {
            displayVal = displayVal[this.props.valueField];

            this.setState({ value: displayVal });
        }
    }
    componentDidMount() {
        if (this.props.options && this.props.options.length > 0) {
            this.validateDefaultPropsValue(this.props.options);
        }
    }

    updateState(event) {
        if (this.props.preventDefault) {
            event.preventDefault();
        }
        const oldIndex = this.props.options.findIndex((element) => element.IsSelected === true);
        const newIndex = this.props.options.findIndex((element) => element[this.props.valueField] === event.target.value);

        if (this.props.setSelectedRole) {
            this.props.setSelectedRole(event.target.value);
        }

        if (!this.props.onlyCustomAction) {
            this.props.onItemChanged(newIndex, oldIndex, event.target.value, this.props.name);
        }

        if (this.props.customAction) {
            this.props.customAction();
        }
        this.setState({ value: event.target.value });
    }

    render() {
        const options = this.props.options
            .filter((item) => {
                return !item.IsHidden;
            })
            .map((item, index) => {
                const className = item.IsSelected ? 'selected' : '';
                return (
                    <option key={`${item[this.props.valueField]}__${index}`} value={item[this.props.valueField]} className={className}>
                        {item[this.props.textField]}
                    </option>
                );
            });

        return (
            <InputBase {...this.props}>
                <select
                    value={this.state.value}
                    name={this.props.name}
                    className='form-control'
                    disabled={this.props.disabled || this.props.readonly}
                    onChange={(event) => this.updateState(event)}
                >
                    {options}
                </select>
            </InputBase>
        );
    }
}

export default Dropdown;
