export const loadModelFromStorage = (formModelName, location, prevPaths) => {
    formModelName = formModelName + 'Model';

    const locationState = location.state;
    if (locationState != null) {
        if (prevPaths.includes(locationState.prevPath)) {
            const lsModel = localStorage.getItem(formModelName);
            if (lsModel != null) {
                const model = JSON.parse(lsModel);
                return model;
            } else return null;
        }
    }
    return null;
};

export const saveModelToStorage = (formModelName, model) => {
    formModelName = formModelName + 'Model';
    localStorage.setItem(formModelName, JSON.stringify(model));
};

export const removeModelFromStorage = (formModelName) => {
    formModelName = formModelName + 'Model';
    localStorage.removeItem(formModelName);
};
