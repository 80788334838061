import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { PortalRoutes, REACT_APP_ASSETS_URL_CSS } from '../../common/constants/constants-portal';
import Protected from '../../common/features/Protected';
import ToastProvider from '../../common/features/Toast/ToastProvider';
import App from './App';
import store from './redux/store';

const Portal = () => {
    return (
        <Provider store={store}>
            <Protected conditions={{ isInternalUser: false }} redirectUrl={PortalRoutes.clearances}>
                <ToastProvider>
                    <HelmetProvider>
                        <Helmet>
                            <title>Portal</title>
                            <link rel='stylesheet' href={REACT_APP_ASSETS_URL_CSS} />
                            <link rel='stylesheet' href='/css/portal-team.css' />
                        </Helmet>
                        <App />
                    </HelmetProvider>
                </ToastProvider>
            </Protected>
        </Provider>
    );
};

export default Portal;
