import { RefObject, useEffect } from 'react';
import TagManager from 'react-gtm-module';

interface EventData {
    [key: string]: any;
}

const useGTMEventTracking = (eventName: string, eventData: EventData, elementRef: RefObject<HTMLElement>) => {
    useEffect(() => {
        const handleClick = () => {
            TagManager.dataLayer({
                dataLayer: {
                    event: eventName,
                    ...eventData
                }
            });
        };

        const element = elementRef.current;

        if (element) {
            element.addEventListener('click', handleClick);
        }

        return () => {
            if (element) {
                element.removeEventListener('click', handleClick);
            }
        };
    }, [eventName, eventData, elementRef]);
};

export default useGTMEventTracking;
