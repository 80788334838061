import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import CountryFlag from '../../../../../../common/features/CountryFlag';
import { getCountryFromClientNumber } from '../../../../../../common/functions/utils';

const FormattedGridCell: React.FC<GridCellProps> = ({ field = '', dataItem, selectionChange, className }) => {
    let cellContent;

    const clientNumber = (data: { number: string; selected: boolean }) => {
        return data.number;
    };

    const countryFlagImage = (data: { number: string; selected: boolean }) => {
        const country = getCountryFromClientNumber(data.number);
        return (
            <div className='pl-3'>
                <CountryFlag countryCode={country.toUpperCase()} />
            </div>
        );
    };

    switch (field) {
        case 'ClientNumber':
            cellContent = dataItem ? clientNumber(dataItem) : null;
            break;
        case 'Destination':
            cellContent = dataItem ? countryFlagImage(dataItem) : null;
            break;
        case 'selected':
            cellContent = <Checkbox value={!!dataItem.selected} onChange={selectionChange} />;
            break;
        default:
            cellContent = null;
    }

    return (
        <>
            <td
                className={`${className}${dataItem?.selected ? 'k-state-selected' : ''} ${dataItem?.isNew ? 'new-row-highlight' : ''}`}
                style={{ cursor: 'default' }}
            >
                {cellContent}
            </td>
        </>
    );
};

export default FormattedGridCell;
