import $ from 'jquery';
import axios from '../../../axios-mlp';
import { ClientServicesByCountry } from '../../../constants/mlp-constants';
import { setValueByPath } from '../../../utils/mlp-utils';
import * as actionTypes from './onBehalfOfActionTypes';

export const selectAllFeaturesCA = (countryIndex, parentIndex, featureId, area) => {
    if (area === ClientServicesByCountry.ACCESS_MANAGEMENT) {
        return {
            type: actionTypes.AM_SELECT_ALL_FEATURES_CA,
            payload: { countryIndex: countryIndex, parentIndex: parentIndex, featureId: featureId }
        };
    } else if (area === ClientServicesByCountry.CLIENT_ADMIN) {
        return {
            type: actionTypes.SELECT_ALL_FEATURES_CA,
            payload: { countryIndex: countryIndex, parentIndex: parentIndex, featureId: featureId }
        };
    } else {
        return;
    }
};

export const toggleCountryServices = (countryIndex, area) => {
    if (area === ClientServicesByCountry.ACCESS_MANAGEMENT) {
        return {
            type: actionTypes.AM_TOGGLE_COUNTRY_SERVICES,
            payload: { countryIndex: countryIndex }
        };
    } else if (area === ClientServicesByCountry.CLIENT_ADMIN) {
        return {
            type: actionTypes.TOGGLE_COUNTRY_SERVICES,
            payload: { countryIndex: countryIndex }
        };
    } else {
        return;
    }
};

export const removeParentService = (countryIndex, parentIndex, area) => {
    if (area === ClientServicesByCountry.ACCESS_MANAGEMENT) {
        return {
            type: actionTypes.AM_REMOVE_PARENT_SERVICE,
            payload: { countryIndex: countryIndex, parentIndex: parentIndex }
        };
    } else if (area === ClientServicesByCountry.CLIENT_ADMIN) {
        return {
            type: actionTypes.REMOVE_PARENT_SERVICE,
            payload: { countryIndex: countryIndex, parentIndex: parentIndex }
        };
    } else {
        return;
    }
};

export const oboChangeUserRole = (item) => {
    return {
        type: actionTypes.OBO_CHANGE_USER_ROLE,
        payload: { item: item }
    };
};

export const updateFeature = (countryIndex, parentIndex, clientIndex, featureIndex, isSelected, area) => {
    if (area === ClientServicesByCountry.ACCESS_MANAGEMENT) {
        return {
            type: actionTypes.AM_UPDATE_FEATURE,
            payload: {
                countryIndex: countryIndex,
                parentIndex: parentIndex,
                clientIndex: clientIndex,
                featureIndex: featureIndex,
                isSelected: isSelected
            }
        };
    } else if (area === ClientServicesByCountry.CLIENT_ADMIN) {
        return {
            type: actionTypes.UPDATE_FEATURE,
            payload: {
                countryIndex: countryIndex,
                parentIndex: parentIndex,
                clientIndex: clientIndex,
                featureIndex: featureIndex,
                isSelected: isSelected
            }
        };
    } else {
        return;
    }
};

export const removeClientService = (countryIndex, parentIndex, clientIndex, area) => {
    if (area === ClientServicesByCountry.ACCESS_MANAGEMENT) {
        return {
            type: actionTypes.AM_REMOVE_CLIENT_SERVICE,
            payload: { countryIndex: countryIndex, parentIndex: parentIndex, clientIndex: clientIndex }
        };
    } else if (area === ClientServicesByCountry.CLIENT_ADMIN) {
        return {
            type: actionTypes.REMOVE_CLIENT_SERVICE,
            payload: { countryIndex: countryIndex, parentIndex: parentIndex, clientIndex: clientIndex }
        };
    } else {
        return;
    }
};

export const showTeamMembers = (clientAdminId, area) => {
    return (dispatch) => {
        $('#overlay').show();

        let params = new URLSearchParams();
        params.append('clientAdminId', clientAdminId);

        axios
            .get('/ClientAdmin/GetTeamMembersByClientAdmin', { params: params })
            .then((response) => {
                dispatch(afterShowTeamMembers(response.data, area));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const afterShowTeamMembers = (teamMemberData, area) => {
    if (area === ClientServicesByCountry.ACCESS_MANAGEMENT) {
        return {
            type: actionTypes.AM_AFTER_SHOW_TEAM_MEMBERS,
            payload: { teamMemberData: teamMemberData }
        };
    } else if (area === ClientServicesByCountry.CLIENT_ADMIN) {
        return {
            type: actionTypes.AFTER_SHOW_TEAM_MEMBERS,
            payload: { teamMemberData: teamMemberData }
        };
    } else {
        return;
    }
};

export const closeTeamMembersModalOBO = () => {
    return {
        type: actionTypes.CLOSE_TEAM_MEMBERS_MODAL_OBO
    };
};

export const removeFeature = (countryIndex, parentIndex, featureId, area) => {
    if (area === ClientServicesByCountry.ACCESS_MANAGEMENT) {
        return {
            type: actionTypes.AM_REMOVE_FEATURE,
            payload: { countryIndex: countryIndex, parentIndex: parentIndex, featureId: featureId }
        };
    } else if (area === ClientServicesByCountry.CLIENT_ADMIN) {
        return {
            type: actionTypes.REMOVE_FEATURE,
            payload: { countryIndex: countryIndex, parentIndex: parentIndex, featureId: featureId }
        };
    } else {
        return;
    }
};

export const addFeature = (countryIndex, parentIndex, featureId, area) => {
    if (area === ClientServicesByCountry.ACCESS_MANAGEMENT) {
        return {
            type: actionTypes.AM_ADD_FEATURE,
            payload: { countryIndex: countryIndex, parentIndex: parentIndex, featureId: featureId }
        };
    } else if (area === ClientServicesByCountry.CLIENT_ADMIN) {
        return {
            type: actionTypes.ADD_FEATURE,
            payload: { countryIndex: countryIndex, parentIndex: parentIndex, featureId: featureId }
        };
    } else {
        return;
    }
};

export const expirationDateChanged = (date) => {
    return {
        type: actionTypes.OBO_EXPIRATION_DATE_CHANGED,
        payload: { date: date }
    };
};

export const addClientService = (item, role) => {
    return (dispatch) => {
        $('#overlay').show();
        let params = new URLSearchParams();
        params.append('number', item.Number);
        params.append('role', role);

        axios
            .get('/ClientAdmin/GetParentClientService', { params: params })
            .then((response) => {
                dispatch(afterAddClientService(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            });
    };
};

export const afterAddClientService = (item) => {
    return {
        type: actionTypes.AFTER_ADD_CLIENT_SERVICE,
        payload: { item: item }
    };
};

export const selectSalesforceContactCA = (item) => {
    return {
        type: actionTypes.SELECT_SALESFORCE_CONTACT,
        payload: { contact: item }
    };
};

export const resetUser = () => {
    return {
        type: actionTypes.RESET_USER
    };
};

export const updateClientServicesByCountry = () => {
    return {
        type: actionTypes.UPDATE_CLIENT_SERVICES_BY_COUNTRY
    };
};

export const getOnBehalfOf = (requestId) => {
    return (dispatch) => {
        $('#overlay').show();

        let params = new URLSearchParams();
        params.append('requestId', requestId);

        axios
            .get('/ClientAdmin/OnBehalfOf', { params: params })
            .then((response) => {
                dispatch(afterGetOnBehalfOf(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const afterGetOnBehalfOf = (model) => {
    return {
        type: actionTypes.AFTER_GET_ON_BEHALF_OF,
        payload: { model: model }
    };
};

export const validateOnBehalfOf = (model) => {
    return (dispatch) => {
        axios
            .post('/ClientAdmin/OnBehalfOfValidate', model)
            .then((response) => {
                let validationMessages = {};

                const json = JSON.parse(response.data);
                for (let i in json) {
                    if (i !== 'AllClientServices' && i !== 'ExistingClientServices') {
                        setValueByPath(validationMessages, i, json[i]);
                    } else if (i === 'AllClientServices' && json[i] === '') {
                        setValueByPath(validationMessages, i, json[i]);
                    } else if (i === 'ExistingClientServices' && json[i] === '') {
                        setValueByPath(validationMessages, i, json[i]);
                    }
                }
                dispatch(oboSetValidationMessages(validationMessages));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            });
    };
};

export const oboSetValidationMessages = (validationMessages) => {
    return {
        type: actionTypes.OBO_SET_VALIDATION_MESSAGES,
        payload: { validationMessages: validationMessages }
    };
};

export const changeOnBehalfOfRejectMsg = (rejectMsg) => {
    return {
        type: actionTypes.CHANGE_ON_BEHALF_OF_REJECT_MSG,
        payload: { rejectMsg: rejectMsg }
    };
};

export const resetStateOnBehalfOf = () => {
    return {
        type: actionTypes.RESET_STATE_ON_BEHALF_OF
    };
};

export const updateNoteField = (value) => {
    return {
        type: actionTypes.UPDATE_NOTE_FIELD,
        payload: { value: value }
    };
};
