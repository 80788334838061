import 'jquery-mask-plugin';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '../../axios-mlp';
import InputBase from '../Inputbase/Inputbase';

class Searchbox extends Component {
    searchBoxRef = React.createRef();
    delayTimer = null;

    state = {
        isLoading: false,
        items: [],
        showNoResults: false,
        visibleItemsNumber: 5
    };

    static defaultProps = {
        value: '',
        name: '',
        titleField: 'Title',
        descriptionField: 'Description',
        minChars: 5,
        placeholder: 'Type here to search',
        clearWhenSearching: true,
        showList: true
    };

    static propTypes = {
        formatResults: PropTypes.func,
        customAction: PropTypes.func,
        updateProperty: PropTypes.func,
        onNameValueChanged: PropTypes.func,
        onSelectItem: PropTypes.func,
        label: PropTypes.string,
        placeholder: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        titleField: PropTypes.string,
        descriptionField: PropTypes.string,
        multiSelect: PropTypes.string,
        readonly: PropTypes.bool,
        disableBlurEvent: PropTypes.bool,
        disabled: PropTypes.bool,
        showList: PropTypes.bool,
        clearWhenSearching: PropTypes.bool,
        onlyCustomAction: PropTypes.bool,
        minChars: PropTypes.number
    };

    trim = (value) => {
        return value ? value.replace(/^\s+|\s+$/g, '') : value;
    };

    handleChange = (event) => {
        if(this.props.preventDefault) {
            event.preventDefault();
        }
        if (this.props.value === event.target.value) {
            return;
        }
        this.props.onValueChanged(event.target.value, this.props.name);

        if (this.props.updateProperty) {
            this.props.updateProperty(event.target.value);
        }
        if (this.props.customAction) {
            this.props.customAction();
        } else {
            if (this.delayTimer) {
                clearTimeout(this.delayTimer);
            }
            const minChars = this.props.minChars;
            this.delayTimer = setTimeout(() => {
                if (event.target.value.length >= minChars) {
                    this.loadItems(event.target.value);
                }
            }, 500);
        }
    };

    loadItems = (value) => {
        this.setState({ isLoading: true });
        let params = new URLSearchParams();
        params.append('query', this.trim(value));

        if (this.props.urlParameterName) {
            params.append(this.props.urlParameterName, this.props.urlParameterValue);
        }

        axios
            .get(this.props.url, { params: params })
            .then((response) => {
                if (response.data) {
                    this.setState({ items: response.data, showNoResults: true });
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    };

    onSelect = (item) => {
        this.props.onSelectItem(item);

        if (!this.props.multiSelect || !this.props.multiSelect === 'multiselect') {
            this.props.onValueChanged('', this.props.name);
            this.setState({ items: [], showNoResults: false, visibleItemsNumber: 5 });
        }
        if (this.props.updateProperty) {
            this.props.onValueChanged(item.Email, this.props.name);
        }
    };

    loadMore = () => {
        this.setState({ visibleItemsNumber: this.state.visibleItemsNumber + 10 });
    };

    hideResults = () => {
        this.setState({ items: [], showNoResults: false, visibleItemsNumber: 5 });

        if (!this.props.keepSearchText) {
            this.props.onValueChanged('', this.props.name);
        }
    };

    formatResults = (item) => {
        if (this.props.formatResults) {
            return this.props.formatResults(item);
        } else {
            return (
                <div>
                    <div>
                        <strong>{item[this.props.titleField]}</strong>
                    </div>
                    <div>{item[this.props.descriptionField]}</div>
                </div>
            );
        }
    };

    handleBlur = (e) => {
        if(this.props.preventDefault) {
            e.preventDefault();
        }
        if (this.props.updateProperty) {
            if (!this.props.disableBlurEvent) {
                if (!this.props.onlyCustomAction && !this.props.fireOnImageBlur) {
                    this.props.onValueChanged(this.props.value, this.props.name);
                }
                if (this.props.customAction) {
                    this.props.customAction();
                }
            }
        }
        if (this.props.validate) {
            this.props.validate();
        }
    };

    render() {
        const items = this.state.items.slice(0, this.state.visibleItemsNumber).map((user, index) => {
            const key = this.props.name + user[this.props.titleField] + index;
            return (
                <div className='dropdown-item' onClick={this.onSelect.bind(this, user)} key={key}>
                    {this.formatResults(user)}
                </div>
            );
        });

        const icon = this.props.icon ? <i className={this.props.icon} /> : null;

        let itemsResult = (
            <div className='dropdown-item' onClick={this.hideResults}>
                <div className='search-no-results'>{this.props.translations.Phrases['SearchBoxNoResults_Label']}</div>
            </div>
        );

        const closeButton = this.props.noCloseButton ? (
            ''
        ) : (
            <div className='pull-right'>
                <span className='btn btn-link' onClick={this.hideResults}>
                    Close
                </span>
            </div>
        );

        if (items.length > 0) {
            itemsResult = this.props.noCloseButton ? (
                ''
            ) : (
                <div>
                    {items}
                    <div
                        className={
                            this.state.items.length > this.state.visibleItemsNumber && this.props.showList === true
                                ? 'd-block btn btn-link'
                                : 'd-none'
                        }
                        onClick={this.loadMore}
                    >
                        Load more
                    </div>
                </div>
            );
        }
        return (
            <div>
                <InputBase {...this.props}>
                    <div className={this.state.isLoading ? 'd-block searchBox-loader' : 'd-none'}></div>
                    {icon}
                    <input
                        type='text'
                        placeholder={this.props.placeholder}
                        name={this.props.name}
                        value={this.props.value}
                        className='form-control'
                        disabled={this.props.disabled}
                        readOnly={this.props.readonly}
                        onChange={this.handleChange}
                        label={this.props.label}
                        onBlur={this.handleBlur}
                    />
                    <div className={this.props.clearWhenSearching && this.props.value ? 'd-none' : 'd-block'}>{this.props.children}</div>
                    <div
                        className={
                            (items.length > 0 || this.state.showNoResults) && this.props.showList === true
                                ? 'd-block dropdown-content'
                                : 'd-none'
                        }
                    >
                        {closeButton}
                        {itemsResult}
                    </div>
                </InputBase>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default connect(mapStateToProps)(Searchbox);
