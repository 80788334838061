import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusFilter } from '../../common/constants/constants-clearances';
import { ShipmentStatusSlice } from '../../common/models/ReduxSlices';

const initialState: ShipmentStatusSlice = {
    statuses: StatusFilter,
    statusSelected: [StatusFilter[0], StatusFilter[1], StatusFilter[2]],
    selectedIndexes: [0, 1, 2],
    isClicked: false
};

const shipmentStatusSlice = createSlice({
    name: 'shipmentStatus',
    initialState,
    reducers: {
        setStatusSelected(state, action: PayloadAction<number[]>) {
            state.selectedIndexes = action.payload;
            state.statusSelected = state.selectedIndexes.map((item) => StatusFilter[item]);
            state.isClicked = true;
        },
        resetStatus(state) {
            state.selectedIndexes = initialState.selectedIndexes;
            state.statusSelected = initialState.statusSelected;
        }
    }
});

export const { setStatusSelected, resetStatus } = shipmentStatusSlice.actions;

export default shipmentStatusSlice.reducer;
