import produce from 'immer';
import * as actionTypes from '../actions/notices/noticeActionTypes';

const initialState = {
    model: {
        ChangedProperties: [],
        NoticeTypesList: [],
        NoticesList: [],
        ServiceProvidersList: [],
        LanguagesList: []
    },
    manageNoticesAreaVisible: false,
    isDeleteModalOpen: false,
    selectedNotice: null,
    selectedIndex: -1,
    isNoticePreviewOpen: false,
    action: ''
};

const setNotices = (state, action) => {
    return produce(state, (draft) => {
        if (action.model.ChangedProperties) {
            draft.model.ChangedProperties = action.model.ChangedProperties;
        }
        if (action.model.NoticeTypesList) {
            draft.model.NoticeTypesList = action.model.NoticeTypesList;
        }
        if (action.model.ServiceProvidersList) {
            draft.model.ServiceProvidersList = action.model.ServiceProvidersList;
        }
        if (action.model.LanguagesList) {
            draft.model.LanguagesList = action.model.LanguagesList;
        }
        draft.model.NoticesList = action.model.NoticesList;
    });
};

const addNotice = (state, action) => {
    return produce(state, (draft) => {
        draft.action = 'add';
        draft.manageNoticesAreaVisible = true;
        draft.selectedNotice = action.payload.notice;
        draft.selectedIndex = action.payload.index;
        setServiceProvidersList(draft.model.ServiceProvidersList, 'Select application');
        setNoticeTypesList(draft.model.NoticeTypesList, 'Select notice type');
        setLanguagesList(draft.model.LanguagesList, 'en');
    });
};

const editNotice = (state, action) => {
    return produce(state, (draft) => {
        draft.action = 'edit';
        draft.manageNoticesAreaVisible = true;
        draft.selectedNotice = action.payload.notice;
        draft.selectedIndex = action.payload.index;
        setServiceProvidersList(draft.model.ServiceProvidersList, action.payload.notice.ServiceProvider);
        setNoticeTypesList(draft.model.NoticeTypesList, action.payload.notice.NoticeType);
        setLanguagesList(draft.model.LanguagesList, action.payload.notice.Language);
    });
};

const setServiceProvidersList = (serviceProviders, serviceProviderText) => {
    serviceProviders.forEach((item) => {
        item.IsSelected = false;
    });
    let sp = serviceProviders.find((element) => element.Text === serviceProviderText);

    if(!sp){
        sp =serviceProviders.find((element) => element.Text === 'Select application');
        //sp =serviceProviders.find((element) => element.Text === 'CNTRL' || element.Text === 'Central');
    }

    sp.IsSelected = true;
};

const setNoticeTypesList = (noticeTypes, noticeTypeText) => {
    noticeTypes.forEach((item) => {
        item.IsSelected = false;
    });
    let nt = noticeTypes.find((element) => element.Text === noticeTypeText);
    if (nt) {
        nt.IsSelected = true;
    } else {
        noticeTypes[0].IsSelected = true; // select first notice type from list
    }
};

const setLanguagesList = (languages, languageText) => {
    languages.forEach((item) => {
        item.IsSelected = false;
    });
    let l = languages.find((element) => element.Value === languageText);
    
    if(!l){
        l = languages.find((element) => element.Value === 'en');
    }

    l.IsSelected = true;
};

const closeManageNoticesArea = (state) => {
    return produce(state, (draft) => {
        draft.manageNoticesAreaVisible = false;
        draft.selectedNotice = null;
        draft.selectedIndex = -1;
    });
};

const openDeleteNoticeModal = (state, action) => {
    return produce(state, (draft) => {
        draft.isDeleteModalOpen = true;
        draft.selectedNotice = action.payload.notice;
        draft.selectedIndex = action.payload.index;
    });
};

const closeDeleteNoticeModal = (state) => {
    return produce(state, (draft) => {
        draft.isDeleteModalOpen = false;
        draft.selectedNotice = null;
        draft.selectedIndex = -1;
    });
};

const deleteNotice = (state, action) => {
    return produce(state, (draft) => {
        draft.model.NoticesList[action.index].IsDeleted = true;
    });
};

const openNoticePreview = (state, {payload}) => {
    return produce(state, (draft) => {
        draft.isNoticePreviewOpen = true;
        draft.selectedNotice = payload;
    });
};

const closeNoticePreview = (state) => {
    return produce(state, (draft) => {
        draft.isNoticePreviewOpen = false;
    });
};

const selectServiceProvider = (state, action) => {
    return produce(state, (draft) => {
        draft.model.ServiceProvidersList[action.payload.oldIndex].IsSelected = false;
        draft.model.ServiceProvidersList[action.payload.newIndex].IsSelected = true;
        draft.selectedNotice.ServiceProvider = action.payload.newValue;
    });
};

const selectNoticeType = (state, action) => {
    return produce(state, (draft) => {
        draft.model.NoticeTypesList[action.payload.oldIndex].IsSelected = false;
        draft.model.NoticeTypesList[action.payload.newIndex].IsSelected = true;
        draft.selectedNotice.NoticeType = action.payload.newValue;
    });
};

const selectLanguage = (state, action) => {
    return produce(state, (draft) => {
        draft.model.LanguagesList[action.payload.oldIndex].IsSelected = false;
        draft.model.LanguagesList[action.payload.newIndex].IsSelected = true;
        draft.selectedNotice.Language = action.payload.newValue;
    });
};

const changeEffectiveDatePartFrom = (state, action) => {
    return produce(state, (draft) => {
        draft.selectedNotice.EffectiveDatePartFrom = action.date;
    });
};

const changeEffectiveDatePartTo = (state, action) => {
    return produce(state, (draft) => {
        draft.selectedNotice.EffectiveDatePartTo = action.date;
    });
};

const changeEffectiveTimePartFrom = (state, action) => {
    return produce(state, (draft) => {
        draft.selectedNotice.EffectiveTimePartFrom = action.time;
    });
};

const changeEffectiveTimePartTo = (state, action) => {
    return produce(state, (draft) => {
        draft.selectedNotice.EffectiveTimePartTo = action.time;
    });
};

const changeEffectiveTimePartFromAmPm = (state, action) => {
    return produce(state, (draft) => {
        draft.selectedNotice.EffectiveTimePartFromAmPm = action.amPm;
    });
};

const changeEffectiveTimePartToAmPm = (state, action) => {
    return produce(state, (draft) => {
        draft.selectedNotice.EffectiveTimePartToAmPm = action.amPm;
    });
};

const changeTitle = (state, action) => {
    return produce(state, (draft) => {
        draft.selectedNotice.Title = action.title;
    });
};

const changeContent = (state, action) => {
    return produce(state, (draft) => {
        draft.selectedNotice.Content = action.content;
    });
};

const saveNotice = (state, action) => {
    return produce(state, (draft) => {
        draft.model.NoticesList = action.notices;
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NOTICES:
            return setNotices(state, action);
        case actionTypes.ADD_NOTICE:
            return addNotice(state, action);
        case actionTypes.EDIT_NOTICE:
            return editNotice(state, action);
        case actionTypes.CLOSE_MANAGE_NOTICES_AREA:
            return closeManageNoticesArea(state);
        case actionTypes.OPEN_DELETE_NOTICE_MODAL:
            return openDeleteNoticeModal(state, action);
        case actionTypes.CLOSE_DELETE_NOTICE_MODAL:
            return closeDeleteNoticeModal(state);
        case actionTypes.DELETE_NOTICE:
            return deleteNotice(state, action);
        case actionTypes.OPEN_NOTICE_PREVIEW:
            return openNoticePreview(state, action);
        case actionTypes.CLOSE_NOTICE_PREVIEW:
            return closeNoticePreview(state);
        case actionTypes.SELECT_SERVICE_PROVIDER:
            return selectServiceProvider(state, action);
        case actionTypes.SELECT_NOTICE_TYPE:
            return selectNoticeType(state, action);
        case actionTypes.SELECT_LANGUAGE:
            return selectLanguage(state, action);
        case actionTypes.CHANGE_EFFECTIVE_DATE_PART_FROM:
            return changeEffectiveDatePartFrom(state, action);
        case actionTypes.CHANGE_EFFECTIVE_DATE_PART_TO:
            return changeEffectiveDatePartTo(state, action);
        case actionTypes.CHANGE_EFFECTIVE_TIME_PART_FROM:
            return changeEffectiveTimePartFrom(state, action);
        case actionTypes.CHANGE_EFFECTIVE_TIME_PART_TO:
            return changeEffectiveTimePartTo(state, action);
        case actionTypes.CHANGE_EFFECTIVE_TIME_PART_FROM_AM_PM:
            return changeEffectiveTimePartFromAmPm(state, action);
        case actionTypes.CHANGE_EFFECTIVE_TIME_PART_TO_AM_PM:
            return changeEffectiveTimePartToAmPm(state, action);
        case actionTypes.CHANGE_TITLE:
            return changeTitle(state, action);
        case actionTypes.CHANGE_CONTENT:
            return changeContent(state, action);
        case actionTypes.SAVE_NOTICE:
            return saveNotice(state, action);
        case actionTypes.RESET_NOTICES_STATE:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
