import $ from 'jquery';
import axios from '../../../axios-mlp';
import { accessManagement as constants } from '../../../constants/mlp-constants';
import { flattenObject, setValueByPath } from '../../../utils/mlp-utils';
import * as actionTypes from '../accessManagement/accessManagementActions';

export const setUserDetail = (viewModel) => {
    let validationMessages = {};
    const obj = flattenObject(viewModel);
    for (let i in obj) {
        setValueByPath(validationMessages, i, obj[i]);
    }
    return {
        type: actionTypes.SET_USER_DETAIL,
        model: viewModel,
        validationMessages: validationMessages
    };
};
export const getUserDetail = (userId) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .get(`${constants.GET_USER_DETAIL_URL}${userId}`)
            .then((response) => {
                dispatch(setUserDetail(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            });
    };
};

export const addClientServiceClientAdmin = (item, role) => {
    return (dispatch) => {
        $('#overlay').show();
        let params = new URLSearchParams();
        params.append('number', item.Number);
        params.append('role', role);

        axios
            .get('/ClientAdmin/GetParentClientService', { params: params })
            .then((response) => {
                dispatch(afterAddClientServiceClientAdmin(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const afterAddClientServiceClientAdmin = (item) => {
    return {
        type: actionTypes.AFTER_ADD_CLIENT_SERVICE_CLIENT_ADMIN,
        payload: { item: item }
    };
};

export const handleUpdate = (newValue, key) => {
    return {
        type: actionTypes.HANDLE_UPDATE,
        payload: { value: newValue, key: key }
    };
};

export const setValidationMessagesClientAdmin = (validationMessages) => {
    return {
        type: actionTypes.SET_VALIDATION_MESSAGES_CLIENT_ADMIN,
        payload: { validationMessages: validationMessages }
    };
};

export const cancelUserClientAdmin = (userId) => {
    let params = new URLSearchParams();
    params.append('userId', userId);
    return (dispatch) => {
        $('#overlay').show();
        axios
            .post('/AccessManagement/CancelUser?userId=' + userId)
            .then((response) => {
                dispatch(setUserStatusClientAdmin('Cancelled'));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const reactivateUserClientAdmin = (userId) => {
    let params = new URLSearchParams();
    params.append('userId', userId);
    return (dispatch) => {
        $('#overlay').show();
        axios
            .post('/AccessManagement/ReactivateUser?userId=' + userId)
            .then((response) => {
                dispatch(setUserStatusClientAdmin('Active'));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const setUserStatusClientAdmin = (status) => {
    return {
        type: actionTypes.SET_USER_STATUS_CLIENT_ADMIN,
        status: status
    };
};

export const resetStateAccessManagement = () => {
    return {
        type: actionTypes.RESET_STATE_ACCCESS_MANAGEMENT
    };
};

export const getClientStatus = (numbers) => {
    return (dispatch) => {
        $('#overlay').show();
        axios
            .post('/ClientAdmin/GetEDMClientAccountStatuses', numbers)
            .then((response) => {
                dispatch(afterGetClientStatus(response.data));
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    window.location.href = window.location.protocol + '//' + document.location.host + '/BasicError';
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };
};

export const afterGetClientStatus = (item) => {
    return {
        type: actionTypes.AFTER_GET_CLIENT_STATUS,
        payload: { item: item }
    };
};
