import $ from 'jquery';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import axios from '../../axios-mlp';
import { batchStatusType, batchType } from '../../constants/mlp-constants';
import { formatTranslated, sortBy, UTCtoLocalDateTime } from '../../utils/mlp-utils';

class BatchJobsGrid extends Component {
    state = {
        sortOrder: {
            property: '',
            order: undefined
        }
    };

    sort = (propertyName) => {
        const currentOrder = this.state.sortOrder;

        if (currentOrder.property !== propertyName) {
            this.setState({
                sortOrder: {
                    property: propertyName,
                    order: 'ASC'
                }
            });
        } else {
            this.setState({
                sortOrder: {
                    property: propertyName,
                    order: 'DESC'
                }
            });
        }
    };

    sortIconClass = (propertyName) => {
        if (this.state.sortOrder.order !== undefined && this.state.sortOrder.property === propertyName) {
            return this.state.sortOrder.order === 'ASC' ? 'fa fa-caret-up' : 'fa fa-caret-down';
        }
        return '';
    };

    statusColor = (statusCode) => {
        switch (statusCode) {
            case batchStatusType.New:
            case batchStatusType.Approved:
            case batchStatusType.Rejected:
                return 'bold black-text';
            case batchStatusType.FaultTerminated:
            case batchStatusType.ValidationTerminated:
            case batchStatusType.ExpirationTerminated:
                return 'red-text';
            case batchStatusType.PendingApproval:
                return 'blue-text';
            case batchStatusType.Cancelled:
                return 'light-grey-text';
            case batchStatusType.Completed:
                return 'black-text';
            case batchStatusType.InProgress:
            case batchStatusType.Completing:
            case batchStatusType.Cancelling:
                return 'orange-text';
            default:
                return '';
        }
    };

    getAnnotatedFile = (objectId, fileName) => {
        $('#overlay').show();
        let params = new URLSearchParams();
        params.append('s3ObjectId', objectId);
        axios
            .get('/BatchUser/GetAnnotatedFile', { params: params })
            .then((response) => {
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.style.cssText = 'display: none';
                a.download = fileName;
                if (window.navigator.msSaveOrOpenBlob) {
                    //IE support
                    const fileData = [response.data];
                    const blobObject = new Blob(fileData);
                    $(a).on(() => {
                        window.navigator.msSaveOrOpenBlob(blobObject, fileName);
                    });
                    a.click();
                } else {
                    const blob = new Blob([response.data], { type: 'octet/stream' }),
                        url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
                if (a.remove) {
                    a.remove();
                }
            })
            .catch((error) => {
                if (error.response && error.response.status !== 401 && error.response.status !== 403) {
                    this.props.history.push('/BasicError');
                }
            })
            .finally(() => {
                $('#overlay').hide();
            });
    };

    render() {
        const batchDetailsUrl =
            this.props.type === batchType.BatchEdit ? '/SystemAdmin/BatchUser/Edit?batchJobId=' : '/SystemAdmin/BatchUser?batchJobId=';

        const sortedModel = this.state.sortOrder
            ? sortBy(this.props.model, this.state.sortOrder.property, this.state.sortOrder.order)
            : this.props.model;

        const tableRows = sortedModel.map((batchJob) => {
            const fileCellComponent = batchJob.S3ObjectId ? (
                <button className='btn btn-link' onClick={() => this.getAnnotatedFile(batchJob.S3ObjectId, batchJob.OriginalFileName)}>
                    {batchJob.OriginalFileName}
                </button>
            ) : (
                <span>N/A</span>
            );

            return (
                <tr key={batchJob.BatchJobId}>
                    <td className='row-data'>{fileCellComponent}</td>
                    <td className='row-data'>{UTCtoLocalDateTime(batchJob.DateUploadedUTC)}</td>
                    <td className='row-data'>
                        <Link
                            className={'btn btn-link ' + this.statusColor(batchJob.BatchStatusCode)}
                            to={batchDetailsUrl + batchJob.BatchJobId}
                        >
                            {formatTranslated(batchJob.BatchStatusName, this.props.translations.Phrases)}
                        </Link>
                    </td>
                </tr>
            );
        }, this);

        const actionLink =
            this.props.type === batchType.BatchEdit ? (
                <tr>
                    <th>
                        <h3 className='pull-left bold table-title'>{this.props.title}</h3>
                    </th>
                </tr>
            ) : (
                <tr>
                    <th>
                        <h3 className='pull-left bold table-title'>{this.props.title}</h3>
                    </th>
                    <th>
                        <Link className='btn btn-link action-link' to={this.props.actionUrl}>
                            <i className='fa fa-plus'></i>
                            {this.props.actionText}
                        </Link>
                    </th>
                    <th>
                        <Link className='btn btn-link action-link pull-right' to={'/SystemAdmin/BatchUser/Edit'}>
                            <i className='fa fa-plus'></i>
                            {'Start new batch edit'}
                        </Link>
                    </th>
                </tr>
            );

        return (
            <div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <br />
                        <table className='table-borderless data-table'>
                            <thead>{actionLink}</thead>
                        </table>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <br />
                        <table className='table table-striped data-table'>
                            <thead>
                                <tr>
                                    <th onClick={this.sort.bind(this, 'OriginalFileName')}>
                                        {this.props.translations.Phrases['File_Label']}
                                        <i className={this.sortIconClass('OriginalFileName')}></i>
                                    </th>
                                    <th onClick={this.sort.bind(this, 'DateUploadedUTC')}>
                                        {this.props.translations.Phrases['DateUploaded_Label']}
                                        <i className={this.sortIconClass('DateUploadedUTC')}></i>
                                    </th>
                                    <th onClick={this.sort.bind(this, 'BatchStatusName')}>
                                        {this.props.translations.Phrases['Status_Label']}
                                        <i className={this.sortIconClass('BatchStatusName')}></i>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{tableRows}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        translations: state.translations.model
    };
};

export default withRouter(connect(mapStateToProps)(BatchJobsGrid));
