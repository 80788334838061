import { useEffect, useState } from 'react';

const usePageLoad = () => {
    const [pageIsLoading, setPageIsLoading] = useState(true);

    useEffect(() => {
        const onPageLoad = () => {
            setPageIsLoading(false);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
        }

        return () => {
            window.removeEventListener('load', onPageLoad);
        };
    }, []);

    return pageIsLoading;
};

export default usePageLoad;
